<mat-toolbar>
  <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <span class="title dialog-title">Update Enrollment Status</span>
    <button mat-icon-button (click)="close()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div class="content">
  <update-enrollment-status
    (cancel)="close()"
    (updateEnrollmentStatus)="updateEnrollmentStatus($event)"
    [enrollments]="enrollments$ | async"
    [allowedStatuses]="commonAllowedStatuses$ | async"
  ></update-enrollment-status>
</div>
