import {NgModule} from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as fromEnrollment from '@provider/enrollment/store';

@NgModule({
  imports: [
      StoreModule.forFeature('provider/enrollments', fromEnrollment.enrollmentsReducer),
      EffectsModule.forFeature([...fromEnrollment.EFFECTS])
  ],
  providers: [...fromEnrollment.RESOLVERS]
})
export class EnrollmentStoreModule {
}
