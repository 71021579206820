import {AfterViewInit, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {IAppState} from '../../../../store/state/app.state';
import {FuseConfigService} from '@fuse/services/config.service';
import {UserListItem} from '@shared/user/models';
import * as fromInstitution from '@institution/store';
import * as fromRefData from '@refData';
import * as fromAuth from '@auth/store';
import * as fromWizard from '@shared/wizard/store';
import * as fromSharedUsers from '@shared/user/components/user-list/store';
import * as fromUsers from '@shared/user/store';
import {InstitutionRole} from '@core/referenceData';
import {AddUserCommand, UpdateUserCommand} from '@shared/user/commands';
import {
  AddAcademicYearCommand,
  AddSemesterCommand,
  AddSessionCommand,
  EditAcademicYearCommand,
  EditSemesterCommand,
  EditSessionCommand
} from '@shared/calendar/commands';
import {AcademicYear, Semester, Session, SessionDateRules} from '@shared/calendar/models';
import {Checklist, EntityStatus} from '@core/models';
import {ChecklistItemType} from '@core/models/checklistItemType.enum';
import {IStepperConfig, IWizardChecklistForView} from '@shared/wizard/models';
import {map} from 'rxjs/operators';
import {Entity} from '@core/models/entity.model';

@Component({
  selector: 'wizard-scene',
  templateUrl: './wizard-scene.component.html',
  styleUrls: ['./wizard-scene.component.scss']
})
export class WizardSceneComponent implements AfterViewInit, OnChanges {
  @Input() checklist: IWizardChecklistForView;
  @Input() checklistOrder: string[];
  @Input() stepperConfig: IStepperConfig;
  @Input() checklistComplete: boolean;
  @Input() showSessions: boolean;
  @Input() entityStatus: EntityStatus;
  @Input() entity: Entity;
  @Output() completeSetup = new EventEmitter();
  EntityStatus = EntityStatus;
  public accountingUser$: Observable<UserListItem>;
  public accountingRole$: Observable<InstitutionRole>;
  public presidentUser$: Observable<UserListItem>;
  public presidentRole$: Observable<InstitutionRole>;
  public vicePresidentOrProvostUser$: Observable<UserListItem>;
  public provostRole$: Observable<InstitutionRole>;
  public vicePresidentRole$: Observable<InstitutionRole>;
  public institutionId$: Observable<string>;
  public institutionRoles$: Observable<InstitutionRole[]>;
  public firstAcademicYear$: Observable<AcademicYear>;
  public firstSemester$: Observable<Semester>;
  public firstSession$:Observable<Session>;
  public checklist$: Observable<IWizardChecklistForView>;
  public checklistComplete$: Observable<boolean>;
  public userName$: Observable<string>;
  public institutionName$: Observable<string>;
  public firstIncompleteItem$: Observable<Checklist>;
  public currentStepId$: Observable<string>;
  public progressPercent$: Observable<number>;
  public baseStep$: Observable<string>;
  public sessionDateRules$: Observable<SessionDateRules[]>;
  contactsStep = ChecklistItemType.ContactAndUserSetup;
  rootStep = ChecklistItemType.Root;
  profileStep = ChecklistItemType.EntityProfile;
  calendarStep = ChecklistItemType.Calendar;
  courseInformationStep = ChecklistItemType.CourseInformation;
  feesStep = ChecklistItemType.Fees;
  bankInformationStep = ChecklistItemType.BankInformation;
  policiesStep = ChecklistItemType.Policies;

  constructor(
    private fuseConfigService: FuseConfigService,
    private store: Store<IAppState>
  ) {
  }

  ngOnInit(): void {
    this.accountingUser$ = this.store.pipe(select(fromUsers.Selectors.AccountingUser));
    this.presidentUser$ = this.store.pipe(select(fromUsers.Selectors.PresidentUser));
    this.vicePresidentOrProvostUser$ = this.store.pipe(select(fromUsers.Selectors.ProvostOrVicePresidentUser));
    this.institutionId$ = this.store.pipe(select(fromInstitution.Selectors.InstitutionId));
    this.institutionRoles$ = this.store.pipe(select(fromRefData.Selectors.institutionRoles));
    this.firstAcademicYear$ = this.store.pipe(select(fromInstitution.Selectors.FirstAcademicYear));
    this.firstSemester$ = this.store.pipe(select(fromInstitution.Selectors.FirstSemester));
    this.firstSession$ = this.store.pipe(select(fromInstitution.Selectors.FirstSession));
    this.sessionDateRules$ = this.store.pipe(select(fromInstitution.Selectors.SessionDateRules));
    this.userName$ = this.store.pipe(select(fromAuth.Selectors.currentUserName));
    this.institutionName$ = this.store.pipe(select(fromInstitution.Selectors.InstitutionName));
    this.firstIncompleteItem$ = this.store.pipe(select(fromInstitution.Selectors.FirstIncompleteProviderChecklistItem));
    this.currentStepId$ = this.store.pipe(select(fromWizard.Selectors.CurrentStepId));
    this.progressPercent$ = this.store.pipe(select(fromWizard.Selectors.ProgressPercent));
    this.checklist$ = this.store.pipe(select(fromWizard.Selectors.CurrentChecklist));
    this.checklistComplete$ = this.store.pipe(select(fromWizard.Selectors.ChecklistComplete));
    this.sessionDateRules$ = this.store.pipe(select(fromInstitution.Selectors.SessionDateRules));
    this.baseStep$ = this.currentStepId$.pipe(
      map((step) => {
        const stepParts = step.split("_");
        return stepParts[0];
      })
    )
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.checklist && changes.checklist.currentValue) {
      const checklist: IWizardChecklistForView = changes.checklist.currentValue;
      this.store.dispatch(fromWizard.Actions.loadChecklist({checklist}))
    }
    if (changes.checklistOrder && changes.checklistOrder.currentValue) {
      const checklistOrder: string[] = changes.checklistOrder.currentValue;
      this.store.dispatch(fromWizard.Actions.loadChecklistOrder({checklistOrder}))
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.fuseConfigService.config = {
        layout: {
          navbar: {
            hidden: false,
            folded: true
          },
          toolbar: {
            hidden: false
          },
          footer: {
            hidden: true
          },
          sidepanel: {
            hidden: true
          }
        }
      };
    });
  }

  baseStep = (currentStepId: ChecklistItemType): string =>{
    const stepParts = currentStepId.split("_");
    return stepParts[0];
  }

  onAddUser(command: AddUserCommand): void{
    this.store.dispatch(fromSharedUsers.Actions.AddUser({command}))
  }

  onEditUser(command: UpdateUserCommand): void{
    this.store.dispatch(fromSharedUsers.Actions.UpdateUser({command}))
  }

  onAddAcademicYear(command: AddAcademicYearCommand): void{
    this.store.dispatch(fromInstitution.Actions.AddAcademicYear({command}));
  }

  onEditAcademicYear(command: EditAcademicYearCommand): void{
    this.store.dispatch(fromInstitution.Actions.UpdateAcademicYear({command}));
  }

  onAddSemester(command: AddSemesterCommand): void{
    this.store.dispatch(fromInstitution.Actions.AddSemester({command}));
  }

  onEditSemester(command: EditSemesterCommand): void{
    this.store.dispatch(fromInstitution.Actions.UpdateSemester({command}));
  }

  onAddSession(command: AddSessionCommand): void{
    this.store.dispatch(fromInstitution.Actions.AddSession({command}));
  }

  onEditSession(command: EditSessionCommand): void{
    this.store.dispatch(fromInstitution.Actions.UpdateSession({command}));
  }

  onNavigate(stepId: string): void{
    this.store.dispatch(fromWizard.Actions.changeCurrentStepId({stepId}));
  }

  isDisableCompleteSetup(): boolean {
    return !this.checklistComplete
  }
}
