import { InstitutionState } from "@institution/store"
import { AcademicYear } from "@shared/calendar/models"

export default (academicYear: AcademicYear, state: InstitutionState): InstitutionState => {
  const idx = state.academicYears.findIndex(x=>x.id===academicYear.id);
  
  if(idx!==-1)return state;
  const academicYears = [...state.academicYears, academicYear];
  
  let currentAcademicYear = state.currentAcademicYear;
  if(!!currentAcademicYear && currentAcademicYear.id === academicYear.id){
    currentAcademicYear = {...academicYear};
  }
  return{
    ...state,
    academicYears,
    currentAcademicYear
  }
}