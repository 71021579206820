import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {AccessRole} from '@auth/models/accessRole';

@Component({
  selector: 'toolbar-menu',
  templateUrl: './toolbar-menu.component.html',
  styleUrls: ['./toolbar-menu.component.scss', '../styles/common-toolbar.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ToolbarMenuComponent implements OnInit {
  constructor(
  ) {
  }

  ngOnInit(): void {

  }
}
