import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { first, finalize, filter, map } from "rxjs/operators";
import { IAppState } from 'app/store/state/app.state';
import * as fromMemberBilling from '@member/billing/store';
import { MemberMonthlyBill } from '@member/billing/models/memberMonthlyBill';

@Injectable()
export class MemberMonthlyStatementsResolver implements Resolve<MemberMonthlyBill>{
    isLoading = false;
    constructor(private store: Store<IAppState>){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
        const memberId = route.params['memberId'];
        return this.store 
            .pipe(
                map(() => {
                    if(!this.isLoading){
                        this.isLoading = true;
                        this.store.dispatch(fromMemberBilling.Actions.LoadMonthlyStatements({memberId}));
                    }
                    return true;
                }),
                filter(summariesLoaded => summariesLoaded),
                first(),
                finalize(()=>this.isLoading=false)
            )
    }
}