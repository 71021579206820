import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { CurrencyFormatter, } from 'app/shared/helpers/formatters';
import { EnrollmentListItem } from '@member/enrollment';
import { UpdateEnrollmentFeeCommand } from '@courseExchange/enrollment/models/updateEnrollmentFee.command';
import { IAppState } from 'app/store/state/app.state';
import { Store } from '@ngrx/store';
import * as fromEnrollment from '@courseExchange/enrollment/store';
import { takeNumber } from 'app/helpers/take-number';

export interface UpdateEnrollmentFeeData {
  enrollment: EnrollmentListItem
}

@Component({
  selector: 'app-update-enrollment-fee',
  templateUrl: './update-enrollment-fee.component.html',
  styleUrls: ['./update-enrollment-fee.component.scss']
})
export class UpdateEnrollmentFeeComponent implements OnInit {

  enrollmentForm: UntypedFormGroup;
  dialogTitle: string = '';
  isNew: boolean;
  enrollment:EnrollmentListItem = null;
  providerSplit: number;
  managementFeeSplit: number;
  constructor(private store: Store<IAppState>,
    private currencyFormatter: CurrencyFormatter,
    public dialogRef: MatDialogRef<UpdateEnrollmentFeeComponent>,
    private formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: UpdateEnrollmentFeeData) { }
  close(): void {
    this.dialogRef.close(null);
  }
  ngOnInit() {
    this.enrollment = this.data.enrollment;
    this.providerSplit = this.enrollment.providerFeeAmount / this.enrollment.memberFeeAmount;
    this.managementFeeSplit = 1-this.providerSplit;

    this.enrollmentForm = this.formBuilder.group({
      id: [this.enrollment.enrollmentId],
      memberFeeAmount: [this.currencyFormatter.format(this.enrollment.memberFeeAmount), [Validators.required,Validators.min(0)]],
      providerFeeAmount: [this.currencyFormatter.format(this.enrollment.providerFeeAmount), [Validators.required,Validators.min(0)]],
      managementFeeAmount: [this.currencyFormatter.format(this.enrollment.managementFeeAmount), [Validators.required,Validators.min(0)]],
      reason: ['']
    });

    this.enrollmentForm.get('managementFeeAmount').valueChanges.subscribe(this.changeManagementFeeAmount);
    this.enrollmentForm.get('providerFeeAmount').valueChanges.subscribe(this.changeProviderFeeAmount);
    this.enrollmentForm.get('memberFeeAmount').valueChanges.subscribe(this.changeMemberFeeAmount);
  }

  private changeManagementFeeAmount = () => {
    const controls = this.enrollmentForm.controls;
    const memberFeeAmountNumber = takeNumber(controls.memberFeeAmount.value);
    let managementFeeAmountNumber = takeNumber(controls.managementFeeAmount.value) >= memberFeeAmountNumber ? memberFeeAmountNumber : takeNumber(controls.managementFeeAmount.value);
    const providerFeeAmountNumber = memberFeeAmountNumber - managementFeeAmountNumber;
    const managementFeeAmount = this.currencyFormatter.format(managementFeeAmountNumber);
    const providerFeeAmount = this.currencyFormatter.format(providerFeeAmountNumber);
    this.providerSplit = providerFeeAmountNumber/memberFeeAmountNumber;
    this.managementFeeSplit = 1-this.providerSplit;

    this.enrollmentForm.patchValue({
      providerFeeAmount
    }, { emitEvent: false });
  }

  private changeProviderFeeAmount = () => {
    const controls = this.enrollmentForm.controls;
    const memberFeeAmountNumber = takeNumber(controls.memberFeeAmount.value);
    let providerFeeAmountNumber = takeNumber(controls.providerFeeAmount.value) >= memberFeeAmountNumber ? memberFeeAmountNumber : takeNumber(controls.providerFeeAmount.value);
    const managementFeeAmountNumber = memberFeeAmountNumber - providerFeeAmountNumber;
    const managementFeeAmount = this.currencyFormatter.format(managementFeeAmountNumber);
    const providerFeeAmount = this.currencyFormatter.format(providerFeeAmountNumber);
    this.providerSplit = providerFeeAmountNumber/memberFeeAmountNumber;
    this.managementFeeSplit = 1-this.providerSplit;

    this.enrollmentForm.patchValue({
      managementFeeAmount,
    }, { emitEvent: false });
  }

  private changeMemberFeeAmount = (value: string) => {
    const memberFee = takeNumber(value) <= 0 ? 0 : takeNumber(value);
    
    this.enrollmentForm.patchValue({
      providerFeeAmount: this.currencyFormatter.format(memberFee * this.providerSplit),
      managementFeeAmount: this.currencyFormatter.format(memberFee * this.managementFeeSplit)
    }, { emitEvent: false })
  }

  
  stripValue(value: string): number {
    return Number(value.replace(/[^0-9.-]+/g, ""));
  }
  saveEnrollmentFee(): void {
    var value = this.enrollmentForm.value;
    var newMemberFeeAmount = this.stripValue(value.memberFeeAmount);
    var newProviderFeeAmount = this.stripValue(value.providerFeeAmount);
    var newManagementFeeAmount = this.stripValue(value.managementFeeAmount);
    var command: UpdateEnrollmentFeeCommand = {
      enrollmentId: value.id,
      memberFeeAmountChange: (this.enrollment.memberFeeAmount - newMemberFeeAmount)*-1,
      providerFeeAmountChange: (this.enrollment.providerFeeAmount - newProviderFeeAmount)*-1,
      managementFeeAmountChange: (this.enrollment.managementFeeAmount - newManagementFeeAmount)*-1,
      reason: value.reason
    }
    this.dialogRef.close(command);
  }

  validForSave(): boolean{
    const {memberFeeAmount, providerFeeAmount, managementFeeAmount} = this.enrollmentForm.value;
    const memberFee = takeNumber(memberFeeAmount);
    const providerFee = takeNumber(providerFeeAmount);
    const managementFee = takeNumber(managementFeeAmount);
    return providerFee + managementFee === memberFee;
  }

}
