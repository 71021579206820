import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {IAppState} from '../../../../store/state/app.state';
import {Subject, Observable} from 'rxjs';
import {AcademicYear} from '../../../../shared/calendar/models';
import {ICourseSchedule} from '../../../models/course-schedule.models';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {IControlsConfig} from '../../../../models/shared';
import {hasErrorForm} from '../../../../helpers/has-error-form';
import * as fromProvider from '@provider/store';
import * as fromCourseSchedule from '@provider/course-schedule/store';

type IFormFromFields = Pick<ICourseSchedule.DataCopySchedule, 'fromAcademicYearId' | 'fromSemesterId'>;

type ILocalFromControlsConfig = IControlsConfig<IFormFromFields>;

type IFormToFields = Pick<ICourseSchedule.DataCopySchedule, 'toAcademicYearId' | 'toSemesterId'>;

type ILocalToControlsConfig = IControlsConfig<IFormToFields>;

@Component({
  selector: 'app-course-schedule-dialog-copy',
  templateUrl: './course-schedule-dialog-copy.component.html',
  styleUrls: ['./course-schedule-dialog-copy.component.scss']
})
export class CourseScheduleDialogCopyComponent implements OnInit {
  @Input() academicYears: AcademicYear[];
  @Input() selectedToAcademicYear: AcademicYear;
  @Input() selectedFromAcademicYear: AcademicYear;
  @Input() canTransformToSecondPart: boolean;
  @Input() canSubmit: boolean;
  @Output() onClose = new EventEmitter();

  isActiveFirstPart = true;

  formFrom: UntypedFormGroup;
  formTo: UntypedFormGroup;

  constructor(
    private store: Store<IAppState>,
    private formBuilder: UntypedFormBuilder  ) {
  }

  ngOnInit(): void {
    this.formFrom = this.formBuilder.group(this.shapeFormFromControlConfig());
    this.formTo = this.formBuilder.group(this.shapeFormToControlConfig());
  }

  private shapeFormToControlConfig(): ILocalToControlsConfig {
    return {
      toAcademicYearId: [null, Validators.required],
      toSemesterId: [null, Validators.required]
    }
  }

  private shapeFormFromControlConfig(): ILocalFromControlsConfig {
    return {
      fromAcademicYearId: [null, Validators.required],
      fromSemesterId: [null, Validators.required]
    }
  }

  hasErrorFormTo = (controlName: keyof IFormToFields, errorName: string) => hasErrorForm(controlName, errorName, this.formTo);

  transformToSecondPart(): void {
    this.isActiveFirstPart = false;
  }

  onSubmit(): void {
    this.store.dispatch(fromProvider.Navigate.CourseSchedule({}));
    this.onClose.emit();
  }

  onChangeSelectedFromAcademicYear(event: any): void {
    const id = event.value;

    this.store.dispatch(fromCourseSchedule.Actions.changeSelectedFromAcademicYear({
      payload: id
    }));
  }

  onChangeSelectedFromSemester(event: any): void {
    const id = event.value;

    this.store.dispatch(fromCourseSchedule.Actions.changeSelectedFromSemester({
      payload: id
    }));
  }

  onChangeSelectedToAcademicYear(event: any): void {
    const id = event.value;

    this.store.dispatch(fromCourseSchedule.Actions.changeSelectedToAcademicYear({
      payload: id
    }));
  }

  onChangeSelectedToSemester(event: any): void {
    const id = event.value;

    this.store.dispatch(fromCourseSchedule.Actions.changeSelectedToSemester({
      payload: id
    }));
  }
}
