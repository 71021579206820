<form [formGroup]="form" class="form" *ngIf="form">
  <h2 class="mat-title">
    <ng-container *ngIf="payload.typesActionsForm === 'add'">
      Add New Session
    </ng-container>
    <ng-container *ngIf="payload.typesActionsForm === 'edit'">
      Edit Session
    </ng-container>
    <ng-container *ngIf="payload.typesActionsForm === 'duplicate'">
      Duplicate Session
    </ng-container>
  </h2>

  <mat-form-field appearance="outline" class="mt-8" fxFill>
    <mat-label>Session Code</mat-label>
    <input
      matInput
      name="code"
      required
      formControlName="code">
    <mat-error *ngIf="hasError('code', 'required')">Code is required</mat-error>
  </mat-form-field>

  <div class="dates" fxFlexFill fxLayout="column" fxLayout.gt-xs="row">

    <mat-form-field appearance="outline" class="mr-sm-12" fxFlex>
      <mat-label>Start Date</mat-label>
      <input matInput [matDatepicker]="startDatePicker" formControlName="startDate" (dateChange)="startDateChanged($event.value)">
      <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #startDatePicker></mat-datepicker>
      <mat-error *ngIf="hasError('startDate', 'startDateNotWithin')">Start date needs to be between {{semesterStartDate | date: 'MM/dd/yyyy'}} and {{semesterEndDate | date: 'MM/dd/yyyy'}}</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" class="ml-sm-12" fxFlex>
      <mat-label>End Date</mat-label>
      <input matInput [matDatepicker]="endDatePicker" formControlName="endDate">
      <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #endDatePicker></mat-datepicker>
      <mat-error *ngIf="hasError('endDate', 'lessStartDate')">Is less or equal than the start date</mat-error>
      <mat-error *ngIf="hasError('endDate', 'endDateNotWithin')">Start date needs to be between {{semesterStartDate | date: 'MM/dd/yyyy'}} and {{semesterEndDate | date: 'MM/dd/yyyy'}}</mat-error>
    </mat-form-field>

  </div>

  <div class="dates" fxFlexFill fxLayout="column" fxLayout.gt-xs="row">

    <mat-form-field appearance="outline" class="mr-sm-12" fxFlex>
      <mat-label>Registration Start Date</mat-label>
      <input matInput [matDatepicker]="registrationStartPicker" formControlName="registrationStartDate">
      <mat-datepicker-toggle matSuffix [for]="registrationStartPicker"></mat-datepicker-toggle>
      <mat-datepicker #registrationStartPicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="outline" class="ml-sm-12" fxFlex>
      <mat-label>Registration End Date</mat-label>
      <input matInput [matDatepicker]="registrationDeadlinePicker" formControlName="registrationDeadlineDate">
      <mat-datepicker-toggle matSuffix [for]="registrationDeadlinePicker"></mat-datepicker-toggle>
      <mat-datepicker #registrationDeadlinePicker></mat-datepicker>
      <mat-error *ngIf="hasError('registrationDeadlineDate', 'lessStartRegistrationDate')">Is less or equal than the start date</mat-error>
    </mat-form-field>

  </div>

  <div class="dates" fxFlexFill fxLayout="column" fxLayout.gt-xs="row">

    <mat-form-field appearance="outline" class="mr-sm-12" fxFlex>
      <mat-label>Drop Deadline</mat-label>
      <input matInput [matDatepicker]="dropDeadlinePicker" formControlName="dropDeadlineDate">
      <mat-datepicker-toggle matSuffix [for]="dropDeadlinePicker"></mat-datepicker-toggle>
      <mat-datepicker #dropDeadlinePicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="outline" class="ml-sm-12" fxFlex>
      <mat-label>Fee Earned</mat-label>
      <input matInput [matDatepicker]="feeEarnedDatePicker" formControlName="feeEarnedDate">
      <mat-datepicker-toggle matSuffix [for]="feeEarnedDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #feeEarnedDatePicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="outline" class="ml-sm-12" fxFlex>
      <mat-label>Withdrawal deadline</mat-label>
      <input matInput [matDatepicker]="withdrawalDeadlinePicker" formControlName="withdrawalDeadlineDate">
      <mat-datepicker-toggle matSuffix [for]="withdrawalDeadlinePicker"></mat-datepicker-toggle>
      <mat-datepicker #withdrawalDeadlinePicker></mat-datepicker>
    </mat-form-field>

  </div>

  <div class="form-actions">
    <button mat-raised-button type="button" color="warn" (click)="onClose.emit()">
      Cancel
    </button>

    <ng-container *ngIf="payload.typesActionsForm === 'add' || payload.typesActionsForm === 'duplicate'">
      <button mat-raised-button
              (click)="onAddSession()"
              [disabled]="isSending || !form.valid"
              type="submit"
              class="btn-primary">
        Add Session
      </button>
    </ng-container>

    <ng-container *ngIf="payload.typesActionsForm === 'edit'">
      <button mat-raised-button
              (click)="onEditSession()"
              [disabled]="isSending"
              type="submit"
              class="btn-primary">
        Edit Session
      </button>
    </ng-container>
  </div>
</form>
