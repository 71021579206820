import {createFeatureSelector, createSelector} from '@ngrx/store';
import { ICourseScheduleState } from '..';
import { CourseCalendarViewModel, CourseCalendarItem } from '@shared/provider/models';
import { ICourseSchedule } from '@provider/models/course-schedule.models';
import { EAlphabetSort } from 'app/constants/shared';
import { searchInText } from 'app/helpers/search-in-text';
import { AcademicYear } from '@shared/calendar/models';
import { ECourseScheduleTypeCreateActions } from '@provider/constants';
import { sortBy } from 'lodash';
import { InstitutionState } from '@institution/store';

const selectCourseScheduleState = createFeatureSelector<ICourseScheduleState>('provider/courseSchedule');
const selectInstitutionState = createFeatureSelector<InstitutionState>('institution');

export const selectCourseCalendarViewModel = createSelector(
  selectCourseScheduleState,
  (state: ICourseScheduleState) => state.courseCalendarViewModel
);

export const selectCourseCalendarViewModelSorts = createSelector(
  selectCourseScheduleState,
  (state: ICourseScheduleState) => state.courseCalendarViewModelSorts
);

export const selectCourseCalendarViewModelSorted = createSelector(
  selectCourseCalendarViewModel,
  selectCourseCalendarViewModelSorts,
  (
    courseCalendarViewModel: CourseCalendarViewModel | null,
    courseCalendarViewModelSorts: ICourseSchedule.CourseCalendarViewModelSorts
  ) => {
    if (!courseCalendarViewModel) return courseCalendarViewModel;

    const {searchValue, sortByCode, sortByTitle, currentSortType} = courseCalendarViewModelSorts;

    let itemsSortedByCode: CourseCalendarItem[] = courseCalendarViewModel.items.slice();

    if (currentSortType === 'code') {
      switch (sortByCode) {
        case EAlphabetSort.ascending: {
          itemsSortedByCode = sortBy(itemsSortedByCode, 'code');

          break;
        }
        case EAlphabetSort.descending: {
          itemsSortedByCode = sortBy(itemsSortedByCode, 'code').reverse();

          break;
        }
      }
    }

    let itemsSortedByTitle: CourseCalendarItem[] = itemsSortedByCode;

    if (currentSortType === 'title') {
      switch (sortByTitle) {
        case EAlphabetSort.ascending: {
          itemsSortedByTitle = sortBy(itemsSortedByTitle, 'title');
          break;
        }
        case EAlphabetSort.descending: {
          itemsSortedByTitle = sortBy(itemsSortedByTitle, 'title').reverse();
          break;
        }
      }
    }

    let itemsSortedBySearch: CourseCalendarItem[] = itemsSortedByTitle;

    if (searchValue) {
      itemsSortedBySearch = searchInText(searchValue, itemsSortedBySearch, [
        'code'
      ]);
    }

    return {
      ...courseCalendarViewModel,
      items: itemsSortedBySearch
    };
  }
);

export const selectTypeCreateActions = createSelector(
  selectCourseScheduleState,
  (state: ICourseScheduleState) => state.typeCreateActions
);

export const selectWasEdit = createSelector(
  selectCourseScheduleState,
  (state: ICourseScheduleState) => state.wasEdit
);

export const selectDataNewSchedule = createSelector(
  selectCourseScheduleState,
  (state: ICourseScheduleState) => state.dataNewSchedule
);

export const AcademicYears = createSelector(
  selectInstitutionState,
  (state) => !!state ? state.academicYears : new Array<AcademicYear>()
)

export const selectSelectedAcademicYear = createSelector(
  AcademicYears,
  selectDataNewSchedule,
  (academicYears: AcademicYear[], dataNewSchedule: ICourseSchedule.DataNewSchedule) => {
    if (!academicYears || !dataNewSchedule.academicYearId) return null;

    return academicYears.find(item => item.id === dataNewSchedule.academicYearId);
  }
);

export const selectDataCopySchedule = createSelector(
  selectCourseScheduleState,
  (state: ICourseScheduleState) => state.dataCopySchedule
);

export const CanTransformToSecondPartCopy = createSelector(
  selectDataCopySchedule,
  (dataCopySchedule) => !!(dataCopySchedule.fromAcademicYearId && dataCopySchedule.fromSemesterId)
)

export const CanSubmitCopy = createSelector(
  selectDataCopySchedule,
  (dataCopySchedule) => !!(dataCopySchedule.toAcademicYearId && dataCopySchedule.toSemesterId && dataCopySchedule.fromAcademicYearId && dataCopySchedule.fromSemesterId)
)

export const CanSubmitNew = createSelector(
  selectDataNewSchedule,
  (dataNewSchedule) => !!(dataNewSchedule.academicYearId && dataNewSchedule.semesterId)
)

export const selectSelectedToAcademicYear = createSelector(
  AcademicYears,
  selectDataCopySchedule,
  (academicYears: AcademicYear[] | null, dataNewSchedule: ICourseSchedule.DataCopySchedule) => {
    if (!academicYears || !dataNewSchedule.toAcademicYearId) return null;

    return academicYears.find(item => item.id === dataNewSchedule.toAcademicYearId);
  }
);

export const selectSelectedFromAcademicYear = createSelector(
  AcademicYears,
  selectDataCopySchedule,
  (academicYears: AcademicYear[] | null, dataNewSchedule: ICourseSchedule.DataCopySchedule) => {
    if (!academicYears || !dataNewSchedule.fromAcademicYearId) return null;

    return academicYears.find(item => item.id === dataNewSchedule.fromAcademicYearId);
  }
);

export const selectDataChangeScheduleScheduleForView = createSelector(
  selectCourseScheduleState,
  (state: ICourseScheduleState): ICourseSchedule.DataChangeSchedule => {
    const {dataCopySchedule, dataNewSchedule, dataChangeSchedule, typeCreateActions} = state;

    if (dataChangeSchedule.academicYearId) return dataChangeSchedule;

    switch (typeCreateActions) {
      case ECourseScheduleTypeCreateActions.new: {
        return {
          academicYearId: dataNewSchedule.academicYearId,
          semesterId: dataNewSchedule.semesterId,
          providerCourseIds: dataNewSchedule.providerCourseIds
        }
      }
      case ECourseScheduleTypeCreateActions.copy: {
        return {
          academicYearId: dataCopySchedule.toAcademicYearId,
          semesterId: dataCopySchedule.toSemesterId,
          providerCourseIds: new Array<string>()
        }
      }
      default:
        return dataChangeSchedule;
    }
  }
);

export const selectSelectedChangeAcademicYear = createSelector(
  AcademicYears,
  selectDataChangeScheduleScheduleForView,
  (academicYears: AcademicYear[] | null, dataChangeSchedule: ICourseSchedule.DataChangeSchedule) => {
    if (!academicYears || !dataChangeSchedule.academicYearId) return null;

    return academicYears.find(item => item.id === dataChangeSchedule.academicYearId);
  }
);

export const isLoadingCourseCalendar = createSelector(
  selectCourseScheduleState,
  (state) => state.isLoadingCourseCalendar
)
