import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'elb-page-list-sidebar',
  templateUrl: './page-list-sidebar.component.html',
  styleUrls: ['./page-list-sidebar.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PageListSidebarComponent implements OnInit {
  isShow = true;

  constructor() {
  }

  ngOnInit(): void {
  }

  toggleSidebar(): void {
    this.isShow = !this.isShow;
  }

  hover(): void {
    if (!this.isShow) {
      this.isShow = true;
    }
  }
}
