import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {IAppState} from '../../../../store/state/app.state';
import {Store} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import {StudentListItem} from '@shared/student/models';
import {MemberCourseListItem} from '@member/memberCourse/models';
import * as fromEnrollment from '@member/enrollment/store';
import * as fromStore from '@member/memberCourse/store';

@Component({
  selector: 'enrollments-requested-dialog',
  templateUrl: './enrollments-requested-dialog.component.html',
  styleUrls: ['./enrollments-requested-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EnrollmentsRequestedDialogComponent implements OnInit {
  students$: Observable<StudentListItem[]>;
  courses$: Observable<MemberCourseListItem[]>;

  constructor(
    public matDialogRef: MatDialogRef<EnrollmentsRequestedDialogComponent>,
    private store: Store<IAppState>,
  ) {
    this.matDialogRef.addPanelClass('enrollments-requested-dialog');
  }

  ngOnInit(): void {
    this.students$ = of([]);
    this.courses$ = of([]);
  }

  close(): void {
    this.matDialogRef.close();
  }
}
