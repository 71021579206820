import { createFeatureSelector, createSelector } from "@ngrx/store";
import { FilterType } from '@shared/common/models';
import { searchInText } from 'app/helpers/search-in-text';
import { sortBy } from 'sort-by-typescript';
import { ProviderCoursesState } from '../reducers';
import * as fromFacultyCV from '@provider/facultyCV/store';
import * as fromRefData from '@refData';
import * as fromInstitution from '@institution/store';
import * as fromProvider from '@provider/store';
const selectProviderCoursesState = createFeatureSelector<ProviderCoursesState>('provider/courses');

export const CourseExchangeId = createSelector(
  selectProviderCoursesState,
  (state) => state.courseExchangeId
)

export const ProviderId = createSelector(
  selectProviderCoursesState,
  (state) => state.providerId
)

export const UploadedSyllabus = createSelector(
  selectProviderCoursesState,
  (state) => state.uploadedSyllabus
)

export const AddCourseArgs = createSelector(
  ProviderId,
  UploadedSyllabus,
  (providerId, uploadedSyllabus) => {
    return {
      providerId,
      uploadedSyllabus
    }
  }
)
export const InstitutionId = createSelector(
  selectProviderCoursesState,
  (state) => state.institutionId
)

export const Courses = createSelector(
  selectProviderCoursesState,
  (state) => state.courses
);

export const ProviderCoursesForEffects = createSelector(
  selectProviderCoursesState,
  (state) => {
    return {
      currentProviderId: state.providerId,
      areCoursesLoaded: state.areCoursesLoaded
    }
  }
)

export const MyProviderCourses = createSelector(
  Courses,
  ProviderId,
  (courses,providerId) => courses.filter(x=>x.providerId === providerId)
)

export const CurrentCourse = createSelector(
  selectProviderCoursesState,
  (state) => state.currentCourse
)

export const CurrentFacultyCVs = createSelector(
  selectProviderCoursesState,
  (state) => state.currentFacultyCVs
)

export const MyProviderSelectedCourses = createSelector(
  MyProviderCourses,
  (courses) => courses.filter(x=>x.selected)
)

export const CourseLevels = createSelector(
  selectProviderCoursesState,
  (state) => state.levelFilters
)

export const SelectedCourseLevels = createSelector(
  CourseLevels,
  (filters) => filters.filter(x=>x.selected)
)

export const CourseLevelFilterCount = createSelector(
  SelectedCourseLevels,
  (levels) => levels.length > 0 ? levels.length : null
)

export const CourseCategories = createSelector(
  selectProviderCoursesState,
  (state) => state.courseCategoryFilters
)

export const SelectedCourseCategories = createSelector(
  CourseCategories,
  (filters) => filters.filter(x=>x.selected)
)

export const CourseCategoryFilterCount = createSelector(
  SelectedCourseCategories,
  (filters) => filters.length > 0 ? filters.length : null
)

export const CourseSubCategories = createSelector(
  selectProviderCoursesState,
  (state) => state.courseSubCategoryFilters
)

export const SelectedCourseSubCategories = createSelector(
  CourseSubCategories,
  (filters) => filters.filter(x=>x.selected)
)

export const CourseSubCategoryFilterCount = createSelector(
  SelectedCourseSubCategories,
  (filters) => filters.length > 0 ? filters.length : null
)

export const CourseTags = createSelector(
  selectProviderCoursesState,
  (state) => state.tagFilters
)

export const SelectedCourseTags = createSelector(
  CourseTags,
  (filters) => filters.filter(x=>x.selected)
)

export const CourseTagFilterCount = createSelector(
  SelectedCourseTags,
  (filters) => filters.length > 0 ? filters.length : null
)

export const Providers = createSelector(
  selectProviderCoursesState,
  (state) => state.providerFilters
)

export const SelectedProviders = createSelector(
  Providers,
  (filters) => filters.filter(x=>x.selected)
)

export const ProviderFilterCount = createSelector(
  SelectedProviders,
  (filters) => filters.length > 0 ? filters.length : null
)

export const CourseStatuses = createSelector(
  selectProviderCoursesState,
  (state) => state.statusFilters
)

export const SelectedCourseStatuses = createSelector(
  CourseStatuses,
  (filters) => filters.filter(x=>x.selected)
)

export const CourseStatusFilterCount = createSelector(
  SelectedCourseStatuses,
  (filters) => filters.length > 0 ? filters.length : null
)
export const CourseLevelFiltersForView = createSelector(
  Courses,
  CourseLevels,
  (courses, courseLevels) =>{
    
    return courseLevels.filter(x=>courses.find(c=>!!c.level && c.level.id===x.id)).sort(sortBy('description')).map(filter => {
      const count = !!courses ? courses.filter(x=>x.level.id===filter.id).length : 0;
      return {
        ...filter,
        descriptionForView: `${filter.description} (${count})`
      }
    })
  }
)

export const CourseCategoriesForView = createSelector(
  Courses,
  CourseCategories,
  (courses, courseCategories) =>{
    
    return courseCategories.filter(x=>courses.find(c=>!!c.category && c.category.id==x.id)).sort(sortBy('description')).map(filter => {
      const count = !!courses ? courses.filter(x=>x.category.id===filter.id).length : 0;
      return {
        ...filter,
        descriptionForView: `${filter.description} (${count})`
      }
    })
  }
)

export const CourseSubCategoriesForView = createSelector(
  Courses,
  CourseSubCategories,
  (courses, courseSubCategories) =>{
    
    return courseSubCategories.filter(x=>courses.find(c=>!!c.subCategory && c.subCategory.id==x.id)).map(filter => {
      const count = !!courses ? courses.filter(x=>x.subCategory.id===filter.id).length : 0;
      return {
        ...filter,
        descriptionForView: `${filter.description} (${count})`
      }
    })
  }
)

export const CourseTagsForView = createSelector(
  Courses,
  CourseTags,
  (courses, courseTags) =>{
    return courseTags.filter(x=>courses.find(c=>c.tags.find(t=>t.id==x.id))).sort(sortBy('description')).map(filter => {
      const count = !!courses ? courses.filter(x=>x.tags.find(tag=>tag.id===filter.id)!=undefined).length : 0;
      return {
        ...filter,
        descriptionForView: `${filter.description} (${count})`
      }
    })
  }
)

export const ProviderFiltersForView = createSelector(
  Courses,
  Providers,
  (courses, providers) =>{
    
    return providers.filter(x=>courses.find(c=>c.providerId==x.id)).sort(sortBy('description')).map(filter => {
      const count = !!courses ? courses.filter(x=>x.providerId===filter.id).length : 0;
      return {
        ...filter,
        descriptionForView: `${filter.description} (${count})`
      }
    })
  }
)

export const CourseStatusFiltersForView = createSelector(
  Courses,
  CourseStatuses,
  (courses, statuses) =>{
    return statuses.sort(sortBy('description')).map(filter=>{
      const count = !!courses ? courses.filter(x=>x.status===filter.id).length : 0;
      return {
        ...filter,
        descriptionForView: `${filter.description} (${count})`
      }
    });
  }
)

export const SelectedFilters = createSelector(
  SelectedCourseLevels,
  SelectedCourseCategories,
  SelectedCourseSubCategories,
  SelectedCourseTags,
  SelectedProviders,
  SelectedCourseStatuses,
  (courseLevels, courseCategories, courseSubCategories, courseTags, providers, statuses) => 
    [...courseLevels, ...courseCategories, ...courseSubCategories, ...courseTags, ...providers, ...statuses]
)

export const SearchText = createSelector(
  selectProviderCoursesState,
  (state) => state.searchText
)
export const Sorts = createSelector(
  selectProviderCoursesState,
  (state) => state.sorts
)
export const CurrentSort = createSelector(
  selectProviderCoursesState,
  (state) => state.currentSort
)
export const Orders = createSelector(
  selectProviderCoursesState,
  (state) => state.orders
)
export const CurrentOrder = createSelector(
  selectProviderCoursesState,
  (state) => state.currentOrder
)

export const FilteredCourses = createSelector(
  Courses,
  SelectedFilters,
  SearchText,
  CurrentSort, 
  CurrentOrder,
  (courses, selectedFilters, searchText, currentSort, currentOrder) => {
      const levelFilters = [...selectedFilters.filter(x=>x.type===FilterType.CourseLevel)];
      const categoryFilters = [...selectedFilters.filter(x=>x.type===FilterType.CourseCategory)];
      const subCategoryFilters = [...selectedFilters.filter(x=>x.type===FilterType.CourseSubCategory)];
      const tagFilters = [...selectedFilters.filter(x=>x.type===FilterType.Tag)];
      const providerFilters = [...selectedFilters.filter(x=>x.type===FilterType.Provider)];
      const statusFilters = [...selectedFilters.filter(x=>x.type===FilterType.ProviderCourseStatus)];

      let result = [...courses];
      if(selectedFilters.length>0){
        if (levelFilters.length) {
          result = result.filter(course => {
            const index = levelFilters.findIndex(item => item.id===course.level.id);
            return (index !== -1);
          })
        }

        if (categoryFilters.length) {
          result = result.filter(course => {
            const index = categoryFilters.findIndex(item => item.id===course.category.id);
            return (index !== -1);
          })
        }

        if (subCategoryFilters.length) {
          result = result.filter(course => {
            const index = subCategoryFilters.findIndex(item => item.id===course.subCategory.id);
            return (index !== -1);
          })
        }

        if (tagFilters.length) {
          result = result.filter(course => {
            const index = tagFilters.findIndex(item => course.tags.find(tag=>tag.id === item.id) != undefined);
            return (index !== -1);
          })
        }

        if (providerFilters.length) {
          result = result.filter(course => {
            const index = providerFilters.findIndex(item => item.id===course.providerId);
            return (index !== -1);
          })
        }

        if (statusFilters.length) {
          result = result.filter(course => {
            const index = statusFilters.findIndex(item => item.id===course.status);
            return (index !== -1);
          })
        }
      }
      
      result = searchInText(searchText, result, [
        'title',
        'code',
        'institution.description',
        'level.description',
        'category.description',
        'subCategory.description',
        'status'
      ]);

      const sort = currentSort.value;
      const order = currentOrder.value;

      result = result.sort(sortBy(`${order}${sort}`));
      
      return result;
      
  }
);

export const SelectedCourses = createSelector(
  FilteredCourses,
  (courses) => courses.filter(x=>x.selected)
)

export const CanCompareCourses = createSelector(
  SelectedCourses,
  (selectedCourses) => 
    selectedCourses.length  >= 2
)

export const CanExportToExcel = createSelector(
  SelectedCourses,
  (selected) => {
    return !!selected  ? selected.length > 0 : false;
  }
)

export const CanApproveCourses = createSelector(
  SelectedCourses,
  (selected) => !!selected  ? selected.length > 0 : false
)

export const CanDisapproveCourses = createSelector(
  SelectedCourses,
  (selected) => !!selected  ? selected.length > 0 : false
)

export const SelectedStatus = createSelector(
  SelectedCourses,
  FilteredCourses,
  (selected, filtered) => !!filtered ? `${filtered.filter(x=>x.selected).length} of ${filtered.length}` : ""
)

export const IsAllSelected = createSelector(
  SelectedCourses,
  FilteredCourses,
  (selected, filtered) => !!filtered ? filtered.every(x=>x.selected) : false    
)

export const IndeterminateSelected = createSelector(
  IsAllSelected,
  FilteredCourses,
  (isAllSelected, filtered) => !!filtered ? isAllSelected ? false : filtered.some(x=>x.selected) : false
)

export const PresetFilterType = createSelector(
  selectProviderCoursesState,
  (state) => state.presetFilterType
)

export const CurrentProviderCourse = createSelector(
  selectProviderCoursesState,
  (state) => state.currentProviderCourse
)
export const CourseSessions = (id: string)=> createSelector(
  selectProviderCoursesState,
  (state) => {
    if(id == '') return state.currentProviderCourse.sessions;
    
    const course = state.courses.find(course =>course.courseId==id);
    return course.sessions;
  }
)

export const FacultyCVs = (facultyCVIds: string[])=> createSelector(
  fromFacultyCV.Selectors.FacultyCVs,
  (facultyCVs) =>{
    var result =facultyCVs.filter(x=>facultyCVIds.find(y=>x.id==y)!=undefined);
    return result;
  }
)

export const FacultyCVForSelectionList = (facultyCVIds: string[])=> createSelector(
  fromFacultyCV.Selectors.FacultyCVs,
  (facultyCVs) => facultyCVs.map(facultyCV =>{
    const selected = facultyCVIds.find(x=>x==facultyCV.id) != undefined;
    return {
      ...facultyCV,
      selected: false
    }
  })
)
export const IsCoursesLoading = createSelector(
  selectProviderCoursesState,
  (state) => state.isCoursesLoading
)

export const AddCourseForEffects = createSelector(
  fromRefData.courseLevels,
  fromRefData.courseCategories,
  fromRefData.courseSubCategories,
  fromRefData.courseTags,
  fromInstitution.Selectors.Institution,
  fromProvider.Selectors.Provider,
  Courses,
  (levels, categories, subCategories, courseTags, institution, provider, courses) =>{
    return{
      institution,
      levels,
      categories,
      subCategories,
      courseTags,
      provider,
      courses
    }
  }
)


