import {
    createReducer,
    on
  } from '@ngrx/store';
import * as fromAuth from '@auth/store';
import * as fromProvider from '@provider/store';
import { Provider } from '@shared/provider/models';
import { ProviderPolicy } from '@shared/institution/models';
import { CourseExchangeFeeListItem, CourseExchangeSummary } from '@shared/courseExchange/models';
import { ProviderCourseListItem } from '@provider/courses/models/providerCourseListItem.model';

  export interface ProviderState {
      provider: Provider,
      policies: ProviderPolicy[],
      arePoliciesLoaded: boolean,
      areFeesLoaded: boolean,
      fees: CourseExchangeFeeListItem[],
      isSendingPolicyIds: string[]
  };

  export const initialProviderState: ProviderState = {
      provider: undefined,
      policies: undefined,
      arePoliciesLoaded: false,
      fees: undefined,
      areFeesLoaded: false,
      isSendingPolicyIds: []
  };

  export const providerReducer = createReducer(
    initialProviderState,
    on(fromAuth.Actions.selectCourseExchangeId, (state,action)=>{
      return initialProviderState
    }),
    on(fromProvider.Actions.LoadProviderSuccess, (state, {provider}) =>{
    return {
        ...state,
        provider
      };
    }),
    on(fromProvider.Actions.LoadPoliciesSuccess, (state,action) =>{
      const policies = action.providerPolicies;
      return {
        ...state,
        policies,
        arePoliciesLoaded: true
      }
    }),
    on(fromProvider.Actions.uploadProviderPolicy, (state, {providerPolicyId})=>{
      return {
        ...state,
        isSendingPolicyIds: [...state.isSendingPolicyIds.slice(), providerPolicyId]
      }
    }),
    on(fromProvider.Actions.UploadProviderPolicySuccess, (state, action)=>{
      const idx = state.policies.findIndex(x=>x.id===action.providerPolicy.id);
      const policies = [...state.policies.slice(0,idx),action.providerPolicy,...state.policies.slice(idx+1)];
      return {
        ...state,
        policies,
        isSendingPolicyIds: [...state.isSendingPolicyIds.filter(item => item !== action.providerPolicy.id)]
      }
    }),
    on(fromProvider.Actions.uploadProviderPolicyError, (state, {providerPolicyId})=>{
      return {
        ...state,
        isSendingPolicyIds: [...state.isSendingPolicyIds.filter(item => item !== providerPolicyId)]
      }
    }),
    on(fromProvider.Actions.CompleteProviderSetupSuccess, (state, action) =>{
      const provider = action.provider;
      return {
        ...state,
        provider
      }
    }),
    on(fromProvider.Actions.ClearState, (state, action)=>{
      return initialProviderState
    }),
    on(fromProvider.Actions.ProviderUpdated, (state, {provider})=>{
      if(!state.provider || provider.id != state.provider.id) return state;
      return {
        ...state,
        provider
      }
    }),
    on(fromProvider.Actions.ProviderPolicyUpdated, (state, {providerPolicy})=>{
      const idx = state.policies.findIndex(x=>x.id===providerPolicy.id);
      if(idx===-1) return state;
      const policies = [...state.policies.slice(0,idx),providerPolicy, ...state.policies.slice(idx+1)];
      return{
        ...state,
        policies,
        isSendingPolicyIds: [...state.isSendingPolicyIds.filter(item => item !== providerPolicy.id)]
      }
    })
);


