import { PermissionType } from './permissionType.enum';
import { PermissionDisplay } from './permissionDisplay';

export class PermissionTypeSelectItem{
    constructor(private _permissionDisplay: PermissionDisplay, private _isSelected: boolean){

    }

    get permissionType(): PermissionType{
        return this._permissionDisplay.permission;
    }

    get permissionDescription(): string{
        return this._permissionDisplay.description;
    }

    get isSelected(): boolean{
        return this._isSelected;
    }
    
    toggleSelected(): void{
        this._isSelected = !this._isSelected;
    }
}