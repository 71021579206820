import { SemesterFilter } from "../models";

  export default  (semesterFilters: SemesterFilter[], semesterId: string): SemesterFilter[] => {
    return semesterFilters.map(semesterFilter=>{
        if(semesterFilter.id===semesterId){
          const selected = !semesterFilter.selected;
          return {
            ...semesterFilter,
            selected,
            sessionFilters: semesterFilter.sessionFilters.map(sessionFilter=>{
              return{
                ...sessionFilter,
                selected
              }
            })
          }
        }
        return semesterFilter
      });
  }