import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { Store, select } from "@ngrx/store";
import { tap, first, finalize, filter, map } from "rxjs/operators";
import { IAppState } from 'app/store/state/app.state';
import { CourseExchange } from 'app/shared/courseExchange/models';
import * as fromCourseExchange from '@courseExchange/store';
import { getCourseExchangeIdFromRoute } from 'app/helpers/routeParamHelper';
import { CourseExchangeCoursesWidgetViewModel } from '@courseExchange/dashboard/widgets/courseExchangeCourses-widget/courseExchangeCourses-widget.viewModel';

@Injectable()
export class CourseExchangeCoursesWidgetResolver implements Resolve<CourseExchangeCoursesWidgetViewModel>{
    isLoading = false;
    constructor(private store: Store<IAppState>){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
        const courseExchangeId = getCourseExchangeIdFromRoute(route);
        return this.store 
            .pipe(
                map(()=>{
                    this.store.dispatch(fromCourseExchange.Actions.LoadCourseExchangeCoursesWidget({courseExchangeId}));
                    return true;
                }),
                filter(courseExchangeLoaded => courseExchangeLoaded),
                first(),
                finalize(()=>this.isLoading=false)
            )
    }
}