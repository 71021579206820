import {createAction, props} from '@ngrx/store';
import { ProviderCourseListItem, ProviderCourseListItemForView } from '@provider/courses/models/providerCourseListItem.model';
import { Course, FacultyCV } from '@shared/institution/models';
import { Sort, Order, ElbertFile } from '@core/models';
import { Filter } from '@shared/common/models';
import { PresetFilterType } from '../enums';

export const LoadCourses = createAction(
  '[Course Exchange Courses Effects] - Load Courses',
  props<{
    courseExchangeId: string;
  }>()
)
export const LoadCoursesSuccess = createAction(
  '[Course Exchange Courses Resolver] - Load Courses Success',
  props<{
    courses: ProviderCourseListItemForView[];
  }>()
);

export const LoadCoursesError = createAction(
  '[Course Exchange Courses Resolver] - Load Courses Error',
  props<{
    message: string
  }>()
);

export const LoadCourse = createAction(
  '[Course Exchange Courses Effects] - Load Course',
  props<{
    courseExchangeId: string,
    courseId: string;
  }>()
)
export const LoadCourseSuccess = createAction(
  '[Course Exchange Course Resolver] - Load Course',
  props<{
    currentCourse: Course;
  }>()
);

export const LoadFacultyList = createAction(
  '[Course Exchange Courses Effects] - Load Faculty List',
  props<{
    institutionId: string,
    courseId: string;
  }>()
)
export const LoadFacultyListSuccess = createAction(
  '[Course Exchange Course Resolver] - Load Faculty List Success',
  props<{
    currentFacultyCVs: FacultyCV[];
  }>()
);

export const LoadCourseError = createAction(
  '[Course Exchange Course Resolver] - Load Course Error',
  props<{
    message: string
  }>()
);

export const LoadSorts = createAction(
  '[Course Exchange Courses List] - Load Sorts',
  props<{
      sorts: Sort[];
  }>()
)

export const ChangeSort = createAction(
  '[Course Exchange Courses List] - Change Sort',
  props<{
      currentSort: Sort
  }>()
)

export const LoadOrders = createAction(
  '[Course Exchange Courses List] - Load Orders',
  props<{
      orders: Order[];
  }>()
)

export const ChangeOrder = createAction(
  '[Course Exchange Courses List] - Change Order',
  props<{
      currentOrder: Order
  }>()
)

export const ChangeSearchText = createAction(
  '[Course Exchange Courses List] - Change Search Text',
  props<{
      searchText: string
  }>()
)

export const MasterToggle = createAction(
  '[Course Exchange Course List] - Master Toggle'
)
export const ToggleCourse = createAction(
  '[Course Exchange Course List] - Toggle Course',
  props<{
    id: string
  }>()
)

export const RemoveFilter = createAction(
  '[Course Exchange Course List] - Remove Filter',
  props<{
    filter: Filter
  }>()
)

export const ToggleStatusFilter = createAction(
  '[Course Exchange Courses List] - Toggle Status Filter',
  props<{
      id: string
  }>()
)
export const ToggleProviderFilter = createAction(
  '[Course Exchange Course List] - Toggle Provider Filter',
  props<{
      id: string
  }>()
)

export const ToggleCourseLevelFilter = createAction(
  '[Course Exchange Course List] - Toggle Course Level Filter',
  props<{
      id: string
  }>()
)


export const ToggleCategoryFilter = createAction(
  '[Course Exchange Course List] - Toggle Category Filter',
  props<{
      id: string
  }>()
)


export const ToggleSubCategoryFilter = createAction(
  '[Course Exchange Course List] - Toggle Sub Category Filter',
  props<{
      id: string
  }>()
)

export const ToggleTagFilter = createAction(
  '[Course Exchange Course List] - Toggle Tag Filter',
  props<{
      id: string
  }>()
)

export const ChangePresetFilterType = createAction(
  '[Course Exchange Course list] - Change Preset Filter Type',
  props<{
    presetFilterType: PresetFilterType
  }>()
)

export const LoadCourseLevelFilters = createAction(
  '[Course Exchange Summary Resolver] - Load Course Level Filters',
  props<{
    levelFilters: Filter[]
  }>()
)

export const LoadCourseCategoryFilters = createAction(
  '[Course Exchange Summary Resolver] - Load Course Category Filters',
  props<{
    courseCategoryFilters: Filter[]
  }>()
)

export const LoadCourseSubCategoryFilters = createAction(
  '[Course Exchange Summary Resolver] - Load Course Sub Category Filters',
  props<{
    courseSubCategoryFilters: Filter[]
  }>()
)

export const LoadTagFilters = createAction(
  '[Course Exchange Summary Resolver] - Load Course Tag Filters',
  props<{
    tagFilters: Filter[]
  }>()
)

export const LoadProviderFilters = createAction(
  '[Course Exchange Summary Resolver] - Load Provider Filters',
  props<{
    providerFilters: Filter[]
  }>()
)

export const LoadStatusFilters = createAction(
  '[Course Exchange Summary Resolver] - Load Course Status Filters',
  props<{
    statusFilters: Filter[]
  }>()
)

export const ExportToExcel = createAction(
  '[Course Exchange Course list] - Export to Excel',
  props<{
    courses?: ProviderCourseListItem[]
  }>()
)

export const ApproveSelectedCourses = createAction(
  '[Course Exchange Course List] - Approve Selected Courses'
)

export const DisapproveSelectedCourses = createAction(
  '[Course Exchange Course List] - Disapprove Selected Courses'
)

export const ApproveCourses = createAction(
  '[Course Exchange Course List] - Approve Courses',
  props<{
    courseIds: string[];
  }>()
)

export const DisapproveCourses = createAction(
  '[Course Exchange Course List] - Disapprove Courses',
  props<{
    courseIds: string[];
  }>()
)

export const ReadMore = createAction(
  '[Course Exchange Course Detail] - Read More'
)


export const DownloadCourseSyllabus = createAction(
  '[Courses] - Download Course Exchange Course Syllabus',
  props<{
    courseExchangeId?: string;
    courseId?: string;
    syllabus?: ElbertFile;
  }>()
)

export const ProviderCourseListItemAdded = createAction(
  '[Courses Course Exchange Effects] - ProviderCourseListItemAdded',
  props<{
    course: ProviderCourseListItem
  }>()
)

export const ProviderCourseListItemUpdated = createAction(
  '[Courses Course Exchange Realtime] - Provider Course List Item Updated',
  props<{
    course: ProviderCourseListItem
  }>()
)

export const MasterToggled = createAction(
  '[Courses Course Exchange] - Master Toggled',
  props<{
    courses: ProviderCourseListItemForView[]
  }>()
)






