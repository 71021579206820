import { InstitutionState } from "@institution/store"
import { AcademicYear } from "@shared/calendar/models"

export default (academicYear: AcademicYear, state: InstitutionState): InstitutionState => {
  const idx = state.academicYears.findIndex(x=>x.id===academicYear.id);
  if(idx===-1)return state;
  const {startDate, endDate, name}=academicYear;
  const editedAcademicYear = {...state.academicYears[idx],startDate, endDate, name };
  let currentAcademicYear = state.currentAcademicYear;
  if(currentAcademicYear.id===academicYear.id){
    currentAcademicYear = {...editedAcademicYear};
  }
  const academicYears = [...state.academicYears.slice(0,idx), editedAcademicYear, ...state.academicYears.slice(idx+1)];
  return{
    ...state,
    academicYears,
    currentAcademicYear
  }
}