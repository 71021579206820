import { AccessRoleSummary } from "@auth/models/accessRoleSummary";
import { createAction, props } from "@ngrx/store";
import { User, UserListItem } from "@shared/user/models";

export const LoadUsers = createAction(
    '[Institution User Resolver] - Load Users',
    props<{
        institutionId: string;
    }>()
);

export const LoadUsersSuccess = createAction(
    '[Institution User Effects] - Load Users Success',
    props<{
        users: UserListItem[]
    }>()
)

export const LoadUsersError = createAction(
    '[Institution User Effects] - Load Users Success'
)

export const LoadUser = createAction(
    '[Institution User Resolver] - Load User',
    props<{
        institutionId: string;
        userId: string;
    }>()
);

export const LoadUserSuccess = createAction(
    '[Institution User Effects] - Load User Success',
    props<{
        currentUser: User
    }>()
)

export const LoadUserError = createAction(
    '[Institution User Effects] - Load User Success'
)

export const UserUpdated = createAction(
    '[Institution User Effects] - User Updated',
    props<{
        user: UserListItem
    }>()
)

export const UserAdded = createAction(
    '[Institution User Effects] - User Added',
    props<{
        user: UserListItem
    }>()
)

export const UserDeleted = createAction(
    '[Institution User Effects] - User Deleted',
    props<{
        user: UserListItem
    }>()
)

export const AccessRoleUpdated = createAction(
    '[Institution User Effects] - Access Role Updated',
    props<{
        accessRole: AccessRoleSummary
    }>()
)

export const AccessRoleAdded = createAction(
    '[Institution User Effects] - Access Role Added',
    props<{
        accessRole: AccessRoleSummary
    }>()
)

export const AccessRoleDeleted = createAction(
    '[Institution User Effects] - Access Role Deleted',
    props<{
        accessRole: AccessRoleSummary
    }>()
)