import { createEffect, Actions, ofType } from "@ngrx/effects";
import { Injectable } from "@angular/core";
import {map, switchMap, tap, concatMap, withLatestFrom} from "rxjs/operators";
import { CourseExchangeService } from 'app/shared/courseExchange/services/course-exchange.service';
import * as fromCourseExchangeDashboard from '@courseExchange/dashboard/store';
import * as fromWizard from '@shared/wizard/store';
import * as fromCourseExchange from '@courseExchange/store';
import { of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { IAppState } from 'app/store/state/app.state';
import { CoursesDataListsService } from '@shared/courseExchange/courses-data/services/courses-data-lists.service';
import * as fromCore from '@core/store';
import { ConfirmDialogData, ConfirmDialogComponent } from '@core/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { RealTimeService } from "@core/services/realTime.service";
import { CourseExchange, CourseExchangeFeeListItem, CourseExchangePolicy, CourseExchangeSummary } from "@shared/courseExchange/models";
import { LogoType } from "@core/logoType.enum";
import { UploadCourseExchangeCommandLogo } from "@admin/course-exchange/commands/uploadCourseExchangeLogo.command";
import { UpdateCourseExchangeCommand } from "@admin/course-exchange/commands";

@Injectable()
export class CourseExchangeEffects{
    constructor(private actions$: Actions, private courseExchangeService: CourseExchangeService,
        private store: Store<IAppState>, private courseDataService: CoursesDataListsService, private matDialog:MatDialog,
        private realTimeService: RealTimeService){
        this.initSignalRMessages();
    }

    private initSignalRMessages(){
        this.realTimeService.hubConnection$.subscribe(hubConnection=>{
          if(!hubConnection) return;
          hubConnection.on('CourseExchangeSummaryUpdated', (courseExchangeSummary: CourseExchangeSummary) => {
            this.store.dispatch(fromCourseExchange.Actions.CourseExchangeSummaryUpdated({courseExchangeSummary}));
          });
          hubConnection.on('CourseExchangeUpdated', (courseExchange: CourseExchange) => {
            this.store.dispatch(fromCourseExchange.Actions.CourseExchangeUpdated({courseExchange}));
          });
          hubConnection.on('CourseExchangePolicyUpdated', (courseExchangePolicy: CourseExchangePolicy) => {
            this.store.dispatch(fromCourseExchange.Actions.CourseExchangePolicyUpdated({courseExchangePolicy}));
          });
          hubConnection.on('CourseExchangeFeesUpdated', (fees: CourseExchangeFeeListItem[])=>{
              this.store.dispatch(fromCourseExchange.Actions.CourseExchangeFeesUpdated({fees}));
          })
        })
      }

    loadCourseExchange$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromCourseExchange.Actions.LoadCourseExchange),
                switchMap((action)=> this.courseExchangeService.getCourseExchangeById(action.courseExchangeId)),
                map((courseExchange) =>
                    fromCourseExchange.Actions.LoadCourseExchangeSuccess({courseExchange}))
            );
    });

    loadCourseExchangeSuccess$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromCourseExchange.Actions.LoadCourseExchangeSuccess),
                map(({courseExchange}) =>
                    fromCourseExchange.Actions.LoadFees({courseExchangeId: courseExchange.id}))
            );
    });

    loadCourseExchangeSummary$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromCourseExchange.Actions.LoadCourseExchangeSummary),
                switchMap((action)=> this.courseExchangeService.getCourseExchangeSummaryById(action.courseExchangeId)),
                map((courseExchangeSummary) =>
                    fromCourseExchange.Actions.LoadCourseExchangeSummarySuccess({courseExchangeSummary}))
            );
    });

    loadCourseExchangeFees$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromCourseExchange.Actions.LoadFees),
                switchMap((action)=>{
                    const courseExchangeId = action.courseExchangeId==="" ? "empty" : action.courseExchangeId;
                    return this.courseExchangeService.getCourseExchangeFees(courseExchangeId)
                }),
                map((fees) =>
                    fromCourseExchange.Actions.LoadFeesSuccess({fees}))
            );
    });

    loadCourseExchangePolicies$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromCourseExchange.Actions.LoadPolicies),
                switchMap((action)=> this.courseExchangeService.getCourseExchangePolicies(action.courseExchangeId)),
                map((policies) =>
                    fromCourseExchange.Actions.LoadPoliciesSuccess({policies}))
            );
    });

    downloadCourseExchangePolicy$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromCourseExchange.Actions.DownloadCourseExchangePolicy),
                tap((action)=> this.courseExchangeService.downloadCourseExchangePolicy(action.courseExchangePolicy))
            );
    },{dispatch : false});

    uploadCourseExchangePolicy$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromCourseExchange.Actions.UploadCourseExchangePolicy),
                concatMap(action => of(action).pipe(
                        withLatestFrom(this.store.pipe(select(fromCourseExchange.Selectors.CourseExchangeId)))
                    )),
                switchMap(([action,courseExchangeId]) => {
                  return this.courseExchangeService.uploadCourseExchangePolicy(courseExchangeId, action.courseExchangePolicyId,action.file);
                }),
                map((response)=>fromCore.Actions.HandleResponse({response}))
            );
    });

    loadCourseExchangeInstitutions$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromCourseExchange.Actions.LoadCourseExchangeInstitutions),
                switchMap((action)=> this.courseExchangeService.getCourseExchangeInstitutions(action.courseExchangeId)),
                map((institutions) =>
                    fromCourseExchange.Actions.LoadCourseExchangeInstitutionsSuccess({institutions}))
            );
    });

    loadCourseExchangeInstitutionsWidget$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromCourseExchange.Actions.LoadCourseExchangeInstitutionsWidget),
                switchMap((action)=> this.courseExchangeService.getCourseExchangeInstitutionsWidgetData(action.courseExchangeId)),
                map((institutionsWidgetData) =>
                    fromCourseExchange.Actions.LoadCourseExchangeInstitutionsWidgetSuccess({institutionsWidgetData}))
            );
    });

    loadCourseExchangeCoursesWidget$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromCourseExchange.Actions.LoadCourseExchangeCoursesWidget),
                switchMap((action)=> this.courseExchangeService.getCourseExchangeCoursesWidgetData(action.courseExchangeId)),
                map((coursesWidgetData) =>
                    fromCourseExchange.Actions.LoadCourseExchangeCoursesWidgetSuccess({coursesWidgetData}))
            );
    });



    addCourseCategories$ = createEffect(() => {
        let courseCategoryIds = [];
        return this.actions$.pipe(
                ofType(fromCourseExchange.Actions.AddCourseCategories),
                tap((action)=>courseCategoryIds=action.courseCategoryIds),
                concatMap(action => of(action).pipe(
                    withLatestFrom(this.store.pipe(select(fromCourseExchange.Selectors.CourseExchangeId)))
                )),
                switchMap(([,courseExchangeId]) => this.courseDataService.addCourseCategories({courseExchangeId,courseCategoryIds})),
                map((response) => fromCore.Actions.HandleResponse({response}))
            );
    });

    removeCourseCategories$ = createEffect(() => {
        let courseCategoryIds = [];
        return this.actions$.pipe(
                ofType(fromCourseExchange.Actions.RemoveCourseCategories),
                tap((action)=>courseCategoryIds=action.courseCategoryIds),
                concatMap(action => of(action).pipe(
                    withLatestFrom(this.store.pipe(select(fromCourseExchange.Selectors.CourseExchangeId)))
                )),
                switchMap(([,courseExchangeId]) => this.courseDataService.removeCourseCategories({courseExchangeId,courseCategoryIds})),
                map((response) => response.isSuccess ?
                                fromCourseExchange.Actions.RemoveCourseCategoriesSuccess({courseCategoryIds}) :
                                fromCore.Actions.HandleResponse({response}))
            );
    });

    addCourseSubCategories$ = createEffect(() => {
        let courseSubCategoryIds = [];
        return this.actions$.pipe(
                ofType(fromCourseExchange.Actions.AddCourseSubCategories),
                tap((action)=>courseSubCategoryIds=action.courseSubCategoryIds),
                concatMap(action => of(action).pipe(
                    withLatestFrom(this.store.pipe(select(fromCourseExchange.Selectors.CourseExchangeId)))
                )),
                switchMap(([,courseExchangeId]) => this.courseDataService.addCourseSubCategories({courseExchangeId,courseSubCategoryIds})),
                map((response) => response.isSuccess ?
                                fromCourseExchange.Actions.AddCourseSubCategoriesSuccess({courseSubCategoryIds}) :
                                fromCore.Actions.HandleResponse({response}))
            );
    });

    removeCourseSubCategories$ = createEffect(() => {
        let courseSubCategoryIds = [];
        return this.actions$.pipe(
                ofType(fromCourseExchange.Actions.RemoveCourseSubCategories),
                tap((action)=>courseSubCategoryIds=action.courseSubCategoryIds),
                concatMap(action => of(action).pipe(
                    withLatestFrom(this.store.pipe(select(fromCourseExchange.Selectors.CourseExchangeId)))
                )),
                switchMap(([,courseExchangeId]) => this.courseDataService.removeCourseSubCategories({courseExchangeId,courseSubCategoryIds})),
                map((response) => response.isSuccess ?
                                fromCourseExchange.Actions.RemoveCourseSubCategoriesSuccess({courseSubCategoryIds}) :
                                fromCore.Actions.HandleResponse({response}))
            );
    });

    addCourseLevels$ = createEffect(() => {
        let courseLevelIds = [];
        return this.actions$.pipe(
                ofType(fromCourseExchange.Actions.AddCourseLevels),
                tap((action)=>courseLevelIds=action.courseLevelIds),
                concatMap(action => of(action).pipe(
                    withLatestFrom(this.store.pipe(select(fromCourseExchange.Selectors.CourseExchangeId)))
                )),
                switchMap(([,courseExchangeId]) => this.courseDataService.addCourseLevels({courseExchangeId,courseLevelIds})),
                map((response) => response.isSuccess ?
                                fromCourseExchange.Actions.AddCourseLevelsSuccess({courseLevelIds}) :
                                fromCore.Actions.HandleResponse({response}))
            );
    });

    removeCourseLevels$ = createEffect(() => {
        let courseLevelIds = [];
        return this.actions$.pipe(
                ofType(fromCourseExchange.Actions.RemoveCourseLevels),
                tap((action)=>courseLevelIds=action.courseLevelIds),
                concatMap(action => of(action).pipe(
                    withLatestFrom(this.store.pipe(select(fromCourseExchange.Selectors.CourseExchangeId)))
                )),
                switchMap(([,courseExchangeId]) => this.courseDataService.removeCourseLevels({courseExchangeId,courseLevelIds})),
                map((response) => response.isSuccess ?
                                fromCourseExchange.Actions.RemoveCourseLevelsSuccess({courseLevelIds}) :
                                fromCore.Actions.HandleResponse({response}))
            );
    });

    updateCourseLevels$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromCourseExchange.Actions.UpdateCourseLevels),
                concatMap(action => of(action).pipe(
                    withLatestFrom(this.store.pipe(select(fromCourseExchange.Selectors.CourseExchangeId)))
                )),
                switchMap(([action,courseExchangeId]) => 
                    this.courseDataService.updateCourseLevels(courseExchangeId, action.courseLevelIds, true)),
                map((response) => fromCore.Actions.HandleResponse({response}))
        )    
    });


    addCourseTags$ = createEffect(() => {
        let courseTagIds = [];
        return this.actions$.pipe(
                ofType(fromCourseExchange.Actions.AddCourseTags),
                tap((action)=>courseTagIds=action.courseTagIds),
                concatMap(action => of(action).pipe(
                    withLatestFrom(this.store.pipe(select(fromCourseExchange.Selectors.CourseExchangeId)))
                )),
                switchMap(([,courseExchangeId]) => this.courseDataService.addCourseTags({courseExchangeId,courseTagIds})),
                map((response) => response.isSuccess ?
                                fromCourseExchange.Actions.AddCourseTagsSuccess({courseTagIds}) :
                                fromCore.Actions.HandleResponse({response}))
            );
    });

    removeCourseTags$ = createEffect(() => {
        let courseTagIds = [];
        return this.actions$.pipe(
                ofType(fromCourseExchange.Actions.RemoveCourseTags),
                tap((action)=>courseTagIds=action.courseTagIds),
                concatMap(action => of(action).pipe(
                    withLatestFrom(this.store.pipe(select(fromCourseExchange.Selectors.CourseExchangeId)))
                )),
                switchMap(([,courseExchangeId]) => this.courseDataService.removeCourseTags({courseExchangeId,courseTagIds})),
                map((response) => response.isSuccess ?
                                fromCourseExchange.Actions.RemoveCourseTagsSuccess({courseTagIds}) :
                                fromCore.Actions.HandleResponse({response}))
            );
    });

    addCourseExchangeFee$ = createEffect(() => {
        let courseExchangeId ='';
        return this.actions$.pipe(
                ofType(fromCourseExchange.Actions.AddCourseExchangeFee),
                concatMap(action => of(action).pipe(
                    withLatestFrom(this.store.pipe(select(fromCourseExchange.Selectors.CourseExchangeId)))
                )),
                tap(([,id]) => courseExchangeId = id),
                switchMap(([{command},courseExchangeId]) => this.courseExchangeService.addCourseExchangeFee(courseExchangeId, command)),
                map((response) => fromCore.Actions.HandleResponse({response}))
            );
    });

    editCourseExchangeFee$ = createEffect(() => {
        let courseExchangeId ='';
        return this.actions$.pipe(
                ofType(fromCourseExchange.Actions.UpdateCourseExchangeFee),
                concatMap(action => of(action).pipe(
                    withLatestFrom(this.store.pipe(select(fromCourseExchange.Selectors.CourseExchangeId)))
                )),
                tap(([,id]) => courseExchangeId = id),
                switchMap(([action,courseExchangeId]) => this.courseExchangeService.updateCourseExchangeFee(courseExchangeId, action.command)),
                map((response) => fromCore.Actions.HandleResponse({response}))
            );
    });

    completeCourseExchangeSetup$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromCourseExchange.Actions.CompleteCourseExchangeSetup),
                concatMap((action)=>of(action).pipe(
                    withLatestFrom(this.store.pipe(select(fromCourseExchange.Selectors.CourseExchangeId)))
                )),
                switchMap(([action, courseExchangeId]) => this.courseExchangeService.completeCourseExchangeSetup(courseExchangeId)),
                map((response) => fromCore.Actions.HandleResponse({response}))
        )
    });

    uploadBankInfo$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromCourseExchange.Actions.UpdateBankInfo),
                concatMap(action => of(action).pipe(
                        withLatestFrom(this.store.pipe(select(fromCourseExchange.Selectors.CourseExchangeId)))
                    )),
                switchMap(([action,courseExchangeId]) => this.courseExchangeService.uploadBankInfo(action.file, courseExchangeId)),
                map((response) => fromCore.Actions.HandleResponse({response}))
        )
    });

    downloadBankInfo$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromCourseExchange.Actions.DownloadBankInfo),
                concatMap(action => of(action).pipe(
                        withLatestFrom(this.store.pipe(select(fromCourseExchange.Selectors.CourseExchangeId)))
                    )),
                tap(([action,courseExchangeId]) => this.courseExchangeService.downloadBankInfo(courseExchangeId, action.bankInfo))
        );
    }, { dispatch: false });

    approveMember$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromCourseExchange.Actions.ApproveMember),
                concatMap(action => of(action).pipe(
                    withLatestFrom(this.store.pipe(select(fromCourseExchange.Selectors.CourseExchangeId)))
                )),
                switchMap(([action,courseExchangeId]) =>this.courseExchangeService.approveMember(courseExchangeId, action.memberId)),
                map((response) => {
                    if(response.isSuccess) this.store.dispatch(fromCourseExchangeDashboard.Navigate.Dashboard({}));
                    return fromCore.Actions.HandleResponse({response})
                })
            );
    });

    approveMemberSuccess$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromCourseExchange.Actions.ApproveMemberSuccess),
                map(()=> fromCourseExchangeDashboard.Navigate.Dashboard({}))
            );
    });

    approveProvider$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromCourseExchange.Actions.ApproveProvider),
                concatMap(action => of(action).pipe(
                    withLatestFrom(this.store.pipe(select(fromCourseExchange.Selectors.CourseExchangeId)))
                )),
                switchMap(([action,courseExchangeId]) => this.courseExchangeService.approveProvider(courseExchangeId, action.providerId)),
                map((response) => {
                    if(response.isSuccess) this.store.dispatch(fromCourseExchangeDashboard.Navigate.Dashboard({}));
                    return fromCore.Actions.HandleResponse({response})
                })
            );
    });


    updateCourseExchange$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromCourseExchange.Actions.UpdateCourseExchange),
                switchMap((action) => this.courseExchangeService.updateCourseExchange(action.command)),
                map((response)=>{
                    if(!response.isSuccess){
                        return fromCore.Actions.HandleResponse({response})
                    }else{
                        return fromCore.Actions.EmptyResponse()
                    }
                })
        )
    });

    uploadCourseExchangeLogo$ = createEffect(() => {
        let logoType: LogoType;
        let uploadLogoCommand: UploadCourseExchangeCommandLogo;
        let updateCourseExchangeCommand: UpdateCourseExchangeCommand;
        return this.actions$.pipe(
                ofType(fromCourseExchange.Actions.UploadCourseExchangeLogo),
                tap((action)=>{
                    updateCourseExchangeCommand = action.command.UpdateCourseExchangeCommand;
                    uploadLogoCommand = action.command;
                    logoType = action.command.logoType;
                }),
                switchMap(()=>this.courseExchangeService.updateCourseExchange(updateCourseExchangeCommand)),
                switchMap(() => this.courseExchangeService.uploadLogo(uploadLogoCommand)),
                map((response)=>response.isSuccess ? 
                            fromCourseExchange.Actions.UploadCourseExchangeLogoSuccess({logoType}) :
                            fromCourseExchange.Actions.UploadCourseExchangeLogoError({logoType}))
            )
    });

    notifyNotComplete$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromCourseExchange.Actions.NotifyNotComplete),
                concatMap((action)=> of(action).pipe(
                    withLatestFrom(this.store.pipe(select(fromCourseExchange.Selectors.FirstIncompleteCourseExchangeChecklistItem)))
                )),
                /** An EMPTY observable only emits completion. Replace with your own observable stream */
                tap(([action, firstIncompleteType]) => {
                    const data = new ConfirmDialogData();
                    data.title = 'Setup Incomplete'
                    data.message = 'Your Course Exchange Wizard is incomplete.  An icon will appear on your wizard dashboard to indicate which items have not been completed.  Once all  required items of the Course Exchange Wizard are complete, the Administrator will be notified.'
                    data.okButtonText= 'Ok';
                    data.showCancelButton = false;


                    this.matDialog.open(ConfirmDialogComponent, {data})
                      .afterClosed()
                      .subscribe(() => this.store.dispatch(fromWizard.Actions.changeCurrentStepId({stepId: firstIncompleteType.itemType})));
                  }
                ));
    }, {dispatch: false});
}
