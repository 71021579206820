import { createAction, props } from "@ngrx/store"
import { CourseDataMergeCommand } from "@provider/courses/models/commands/courseDataMerge.command"
import { EnrollmentMigrationCommand } from "@shared/member/models/commands/enrollmentMigrationCommand"

export const SelectEnrollmentMigrationDataFile = createAction(
    'Various Places - Select Provider Enrollment Migration Data File',
    props<{
        providerId: string,
        institutionId: string
    }>()
)

export const GetEnrollmentMigrationData = createAction(
    'Various Places - Get Provider Enrollment Migration Data File',
    props<{
        providerId: string,
        institutionId: string
    }>()
)

export const EnrollmentMigrationData = createAction(
    'Enrollment Migration Data Dialog - Provider Enrollment Migration Data',
    props<{
        providerId: string,
        institutionId: string,
        enrollmentMigrationData: EnrollmentMigrationCommand[]
    }>()
)

export const SelectLetterGradeMigrationDataFile = createAction(
    'Various Places - Select Letter Grade Migration Data File',
    props<{
        providerId: string,
        institutionId: string
    }>()
)

export const GetLetterGradeMigrationData = createAction(
    'Various Places - Get Provider LetterGrade Migration Data File',
    props<{
        providerId: string,
        institutionId: string
    }>()
)

export const LetterGradeMigrationData = createAction(
    'Letter Grade Migration Data Dialog - Provider Letter Grade Migration Data',
    props<{
        providerId: string,
        institutionId: string,
        enrollmentMigrationData: EnrollmentMigrationCommand[]
    }>()
)

export const SelectCourseMergeDataFile = createAction(
  'Institution List - Select Course Merge Data File',
  props<{
      providerId: string,
      institutionId: string
  }>()
)

export const GetCourseMergeData = createAction(
  'Various Places - Get Course Merge Data',
  props<{
      providerId: string,
      institutionId: string
  }>()
)

export const CourseMergeData = createAction(
  'Course Merge Data Dialog - Course Merge Data',
  props<{
      providerId: string,
      institutionId: string,
      courseMergeData: CourseDataMergeCommand[]
  }>()
)

export const ShowMigrateData = createAction(
    'Various Places - Show Provider Migrate Data Dialog',
    props<{
      providerId: string,
      institutionId: string
  }>()
)