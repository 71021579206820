import { MemberCourseListItem } from "@member/memberCourse/models";

export default <T extends MemberCourseListItem>(item:T): T => {
    return {
      ...item,
      startDate: new Date(item.startDate2.year, item.startDate2.month-1, item.startDate2.day),
      endDate: new Date(item.endDate2.year, item.endDate2. month-1, item.endDate2.day),
      registrationStartDate: new Date(item.registrationStartDate2.year, item.registrationStartDate2.month-1, item.registrationStartDate2.day),
      registrationDeadlineDate: new Date(item.registrationDeadlineDate2.year, item.registrationDeadlineDate2.month-1, item.registrationDeadlineDate2.day),
      withdrawalDeadlineDate: new Date(item.withdrawalDeadlineDate2.year, item.withdrawalDeadlineDate2.month-1, item.withdrawalDeadlineDate2.day),
      feeEarnedDate: new Date(item.feeEarnedDate2.year, item.feeEarnedDate2.month-1, item.feeEarnedDate2.day),
      dropDeadlineDate: new Date(item.dropDeadlineDate2.year, item.dropDeadlineDate2.month-1, item.dropDeadlineDate2.day)
    };
  }
  