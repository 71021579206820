import {IControlsConfig} from '../models/shared';

export const fillControlsConfig = <T extends {}>(initialControlConfig: IControlsConfig, data: any): IControlsConfig<T> => {
  const resultControlConfig: IControlsConfig = {};

  for (const key in initialControlConfig) {
    if (key in data) {
      const [valueOptions, rules, asyncRules] = initialControlConfig[key];

      if (typeof valueOptions === 'object' && valueOptions !== null) {
        resultControlConfig[key] = [
          {
            ...valueOptions,
            value: data[key],
          },
          rules,
          asyncRules
        ];
      } else {
        resultControlConfig[key] = [
          data[key],
          rules,
          asyncRules
        ];
      }
    } else {
      resultControlConfig[key] = initialControlConfig[key];
    }
  }

  return resultControlConfig as IControlsConfig<T>;
};
