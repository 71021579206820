import {Component, Input, OnInit, Output,EventEmitter} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {
  selectSetValuesFromCourseCalendarState} from '../../store/selectors/calendar.selectors';
import {Observable, Subject} from 'rxjs';
import {ECourseCalendarEntity, ECourseCalendarTypesActionsForm} from '../../constants';
import {AcademicYear, Semester, Session,IEntityActionsPayload, SessionDateRules} from '@shared/calendar/models';
import {CalendarEntityCreatorDialogComponent} from '../calendar-entity-creator-dialog/calendar-entity-creator-dialog.component';
import {IAppState} from 'app/store/state/app.state';
import {Store, select} from '@ngrx/store';
import * as fromInstitution from '@institution/store';
@Component({
  selector: 'elb-calendar',
  templateUrl: './calendar-scene.component.html',
  styleUrls: ['./calendar-scene.component.scss']
})
export class CalendarSceneComponent implements OnInit {
  private ngDestroyed$: Subject<any>;

  academicYears$: Observable<AcademicYear[]>;
  currentAcademicYear$: Observable<AcademicYear>;

  @Input() pageTitle: string;
  @Input() pageSubTitle: string;
  @Input() showSessions: boolean = false;
  @Input() sessionDateRules: SessionDateRules;
  @Output() returnToDashboardClicked: EventEmitter<void> = new EventEmitter<void>(null);
  constructor(
    private dialog: MatDialog,
    private store: Store<IAppState>
  ) {
    this.ngDestroyed$ = new Subject();
  }

  ngOnInit(): void {
    this.academicYears$ = this.store.pipe(select(fromInstitution.Selectors.AcademicYears));
    this.currentAcademicYear$ = this.store.pipe(select(fromInstitution.Selectors.CurrentAcademicYear));
  }

  addAcademicYear(): void {
    this.openEntityCreatorDialog({
      entity: ECourseCalendarEntity.academicYear,
      typesActionsForm: ECourseCalendarTypesActionsForm.add,
    });
  }

  updateAcademicYear(academicYearToEdit: AcademicYear): void {
    this.openEntityCreatorDialog({
      entity: ECourseCalendarEntity.academicYear,
      typesActionsForm: ECourseCalendarTypesActionsForm.edit,
      academicYearToEdit
    });
  }

  duplicateAcademicYear(academicYearToEdit: AcademicYear): void {
    this.openEntityCreatorDialog({
      entity: ECourseCalendarEntity.academicYear,
      typesActionsForm: ECourseCalendarTypesActionsForm.duplicate,
      academicYearToEdit
    });
  }

  deleteAcademicYear(academicYearId: string): void{
    this.store.dispatch(fromInstitution.Actions.DeleteAcademicYear({academicYearId}))
  }

  addSemester(academicYear: AcademicYear): void {
    const academicYearId = academicYear.id;
    const academicYearStartDate = academicYear.startDate;
    const academicYearEndDate = academicYear.endDate;
    const showSessions = this.showSessions;
    this.openEntityCreatorDialog({
      entity: ECourseCalendarEntity.semester,
      typesActionsForm: ECourseCalendarTypesActionsForm.add,
      academicYearId,
      academicYearStartDate,
      academicYearEndDate,
      showSessions
    });
  }

  updateSemester(academicYear: AcademicYear, semesterToEdit: Semester): void {
    const academicYearId = academicYear.id;
    const academicYearStartDate = academicYear.startDate;
    const academicYearEndDate = academicYear.endDate;
    const showSessions = this.showSessions;
    this.openEntityCreatorDialog({
      entity: ECourseCalendarEntity.semester,
      typesActionsForm: ECourseCalendarTypesActionsForm.edit,
      academicYearId,
      academicYearStartDate,
      academicYearEndDate,
      semesterToEdit,
      showSessions,
    });
  }

  duplicateSemester(academicYear: AcademicYear, semesterToEdit: Semester): void {
    const academicYearId = academicYear.id;
    const academicYearStartDate = academicYear.startDate;
    const academicYearEndDate = academicYear.endDate;
    const showSessions = this.showSessions;
    this.openEntityCreatorDialog({
      entity: ECourseCalendarEntity.semester,
      typesActionsForm: ECourseCalendarTypesActionsForm.duplicate,
      academicYearId,
      academicYearStartDate,
      academicYearEndDate,
      semesterToEdit,
      showSessions
    });
  }

  deleteSemester(semester: Semester): void{
    this.store.dispatch(fromInstitution.Actions.DeleteSemester({semester}))
  }

  addSession(academicYearId: string, semester: Semester): void {
    const semesterId = semester.id;
    const semesterStartDate = semester.startDate
    const semesterEndDate = semester.endDate;
    this.openEntityCreatorDialog({
      entity: ECourseCalendarEntity.session,
      typesActionsForm: ECourseCalendarTypesActionsForm.add,
      academicYearId,
      semesterId,
      semesterStartDate,
      semesterEndDate,
    });
  }

  updateSession(academicYearId: string, semester: Semester, sessionToEdit: Session): void {
    const semesterId = semester.id;
    const semesterStartDate = semester.startDate
    const semesterEndDate = semester.endDate;
    const sessionDateRules = this.sessionDateRules;
    this.openEntityCreatorDialog({
      entity: ECourseCalendarEntity.session,
      typesActionsForm: ECourseCalendarTypesActionsForm.edit,
      academicYearId,
      semesterId,
      semesterStartDate,
      semesterEndDate,
      sessionToEdit,
    });
  }

  duplicateSession(academicYearId: string, semester: Semester, sessionToEdit:Session): void {
    const semesterId = semester.id;
    const semesterStartDate = semester.startDate
    const semesterEndDate = semester.endDate;
    this.openEntityCreatorDialog({
      entity: ECourseCalendarEntity.session,
      typesActionsForm: ECourseCalendarTypesActionsForm.duplicate,
      academicYearId,
      semesterId,
      semesterStartDate,
      semesterEndDate,
      sessionToEdit
    });
  }

  deleteSession(session: Session): void{
    this.store.dispatch(fromInstitution.Actions.DeleteSession({session}));
  }

  private openEntityCreatorDialog(payload: IEntityActionsPayload.Types): void {
    this.dialog.open(CalendarEntityCreatorDialogComponent, {
      data: payload,
      panelClass: ['calendar-entity-creator-dialog', payload.entity]
    });
  }

  onChangeAcademicYearsSelect(event): void {
    const academicYearId = event.value;
    this.store.dispatch(fromInstitution.Actions.ChangeCurrentAcademicYearId({academicYearId}));
  }

  ngOnDestroy(): void {
    selectSetValuesFromCourseCalendarState.release();

    this.ngDestroyed$.next();
  }
}
