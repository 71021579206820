import {AfterViewInit, Component, ElementRef, OnInit, ViewChild, ChangeDetectionStrategy, EventEmitter, Output} from '@angular/core';
import {IAppState} from '../../../../store/state/app.state';
import {select, Store} from '@ngrx/store';
import {fromEvent, Observable, combineLatest, of} from 'rxjs';
import {
  NewEnrollmentListOfSelectedTableScheme
} from '@member/enrollment/components';
import {MemberCourseListItem} from '@member/memberCourse/models';
import {Order, Sort} from '@core/models';
import {StudentListItem} from '@shared/student/models';
import * as fromEnrollment from '@member/enrollment/store';
import * as fromMemberCourse from '@member/memberCourse/store';
import * as fromStudent from '@member/students/store';
import {debounceTime, distinctUntilChanged, map, tap, concatMap, withLatestFrom} from 'rxjs/operators';

@Component({
  selector: 'new-enrollment-from-courses',
  templateUrl: './new-enrollment-from-courses.component.html',
  styleUrls: ['./new-enrollment-from-courses.component.scss', '../styles/new-enrollment-from-common.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewEnrollmentFromCoursesComponent implements OnInit, AfterViewInit {
  courses$: Observable<MemberCourseListItem[]>;
  tableScheme: NewEnrollmentListOfSelectedTableScheme[] = [
    {
      title: 'Provider',
      accessKey: 'institution.description'
    },
    {
      title: 'Code',
      accessKey: 'code'
    },
    {
      title: 'Title',
      accessKey: 'title'
    },
    {
      title: 'Start Date',
      accessKey: 'startDate',
      isDate: true
    },
    {
      title: 'End Date',
      accessKey: 'endDate',
      isDate: true
    }
  ];

  sorts: Sort[] = [
    {name: 'Last Name', icon: 'person_pin', value: 'lastName', isDefault: true},
    {name: 'First Name', icon: 'person_pin', value: 'firstName', isDefault: false},
    {name: 'Student Number', icon: 'import_export', value: 'studentNumber', isDefault: false}
  ];
  orders: Order[] = [
    {name: 'Ascending', icon: 'arrow_upward', value: '', isDefault: true},
    {name: 'Descending', icon: 'arrow_downward', value: '-', isDefault: false},
  ];

  currentOrder$: Observable<Order>;
  currentSort$: Observable<Sort>;
  filteredStudents$: Observable<StudentListItem[]>;
  selectedStudents$: Observable<StudentListItem[]>;
  sorts$: Observable<Sort[]>;
  orders$: Observable<Order[]>;
  canCreateEnrollment$: Observable<boolean>;

  @ViewChild('filter') filter: ElementRef;
  @Output() close: EventEmitter<void> = new EventEmitter<void>();


  constructor(
    private store: Store<IAppState>,
  ) {
  }

  ngOnInit(): void {
    this.courses$ = this.store.pipe(select(fromMemberCourse.Selectors.SelectedMemberCourses));
    this.filteredStudents$ = this.store.pipe(select(fromStudent.Selectors.FilteredStudents));
    this.selectedStudents$ = this.store.pipe(select(fromStudent.Selectors.SelectedStudents));
    this.currentSort$ = this.store.pipe(select(fromStudent.Selectors.CurrentSort));
    this.currentOrder$ = this.store.pipe(select(fromStudent.Selectors.CurrentOrder));
    this.sorts$ = this.store.pipe(select(fromStudent.Selectors.Sorts));
    this.orders$ = this.store.pipe(select(fromStudent.Selectors.Orders));
    this.canCreateEnrollment$ = this.store.pipe(select(fromEnrollment.Selectors.CanCreateEnrollment));

    this.store.dispatch(fromStudent.Actions.LoadSorts({sorts: this.sorts}));
    this.store.dispatch(fromStudent.Actions.LoadOrders({orders: this.orders}));
    this.store.dispatch(fromStudent.Actions.ChangeSearchText({searchText:""}));
  }

  ngAfterViewInit(): void {
    this.setupTextSearch();
  }

  setupTextSearch(): void{
    fromEvent(this.filter.nativeElement, 'keyup').pipe(
      map((event: any) => event.target.value),
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe((searchText: string) => this.store.dispatch(fromStudent.Actions.ChangeSearchText({searchText})))
  }

  onRemove(course: MemberCourseListItem): void {
    const id = course.id;
    this.store.dispatch(fromMemberCourse.Actions.ToggleMemberCourse({id}));
  }

  changeOrderBy(order: Order): void {
    this.store.dispatch(fromStudent.Actions.ChangeOrder({currentOrder: order}))
  }

  changeSort(sort: Sort): void {
    this.store.dispatch(fromStudent.Actions.ChangeSort({currentSort: sort}))
  }

  onToggleStudent(studentId: string): void{
    this.store.dispatch(fromStudent.Actions.ToggleStudent({studentId}));
  }

  trackById(item: StudentListItem): string {
    return item.id;
  }

  createEnrollment(): void{
    this.store.dispatch(fromEnrollment.Actions.SubmitEnrollments())
    this.close.emit();
  }
}
