import {
    createReducer,
    on
  } from '@ngrx/store';
import * as fromAdminUsers from '@admin/users/store';
import { UserListItemForView } from '@shared/user/models';
 
  export interface AdminUsersState {
      users: UserListItemForView[];
      isLoadingUsers: boolean;
      currentInstitutionId: string;
      isLoadingUser: boolean;
  };
  
  export const initialAdminUsersState: AdminUsersState = {
      users: [],
      isLoadingUsers: false,
      currentInstitutionId: undefined,
      isLoadingUser: false
  };
  
  export const adminUsersReducer = createReducer(
    initialAdminUsersState,
    on(fromAdminUsers.Actions.LoadUsers, (state, action)=>{
        return{
            ...state,
            isLoadingUsers: true
        }
    }),
    on(fromAdminUsers.Actions.LoadUsersSuccess, (state, action)=>{
        const users = action.users.map(user=>{
            return{
                ...user,
                selected: false,
                accordianFlag: false
            }
        })
        return {
            ...state,
            users,
            isLoadingUsers: false
        };
    }),
    on(fromAdminUsers.Actions.LoadUsersError, (state, action) =>{
        return {
            ...state,
            isLoadingUsers: false
        }
    }),
    on(fromAdminUsers.Actions.SetInstitutionId, (state, action)=>{
        const currentInstitutionId = action.institutionId;
        return {
            ...state,
            currentInstitutionId
        }
    }),
    on(fromAdminUsers.Actions.UserListItemAdded, (state, {userListItem})=>{
        const idx = state.users.findIndex(x=>x.id===userListItem.id);
        if(idx!=-1) return state;
        const userListItemForView = {...userListItem, selected: false, accordianFlag: false};
        const users = [...state.users, userListItemForView];

        return {
            ...state,
            users
        }
    }),
    on(fromAdminUsers.Actions.UserListItemUpdated, (state, {userListItem})=>{
        const idx = state.users.findIndex(x=>x.id==userListItem.id);
        if(idx===-1) return state;
        const userListItemForView = {...userListItem, selected: false, accordianFlag: false};
        const users = [...state.users.slice(0,idx), userListItemForView, ...state.users.slice(idx+1)];

        return {
            ...state,
            users
        }
    }),
    on(fromAdminUsers.Actions.AccessRoleAdded, (state, {accessRole})=>{
        const idx = state.users.findIndex(x=>x.id==accessRole.userId);
        if(idx===-1) return state;
        const accessRoleIdx = state.users[idx].accessRoles.findIndex(x=>x.id==accessRole.id);
        if(accessRoleIdx!=-1) return state;
        const accessRoles = [...state.users[idx].accessRoles, accessRole];
        const user = {...state.users[idx], accessRoles};
        const users = [...state.users.slice(0,idx), user , ...state.users.slice(idx+1)];
        return{
            ...state,
            users
        }
      }),
      on(fromAdminUsers.Actions.AccessRoleUpdated, (state, {accessRole})=>{
        const userIdx = state.users.findIndex(x=>x.id==accessRole.userId);
        if(userIdx===-1) return state;
        if(!accessRole) return state;
        const accessRoleIdx = state.users[userIdx].accessRoles.findIndex(x=>x.id==accessRole.id);
        const accessRoles = [...state.users[userIdx].accessRoles.slice(0,accessRoleIdx),accessRole, ...state.users[userIdx].accessRoles.slice(accessRoleIdx+1)];
        const user = {...state.users[userIdx], accessRoles};
        const users = [...state.users.slice(0,userIdx), user , ...state.users.slice(userIdx+1)];
        return{
            ...state,
            users
        }
      }),
      on(fromAdminUsers.Actions.AccessRoleDeleted, (state, {accessRole})=>{
        const userIdx = state.users.findIndex(x=>x.id==accessRole.userId);
        if(userIdx===-1) return state;
        const accessRoles = [...state.users[userIdx].accessRoles.filter(x=>x.id!=accessRole.id)];
        const user = {...state.users[userIdx], accessRoles};
        const users = [...state.users.slice(0,userIdx), user , ...state.users.slice(userIdx+1)];
        return{
            ...state,
            users
        }
      })
);
  
  
  