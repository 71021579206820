import { AccessRoleSummary } from '@auth/models/accessRoleSummary';
import { createAction, props } from '@ngrx/store';
import { UserListItem } from '@shared/user/models';

export const LoadUsers = createAction(
    '[Super Admin Users Resolver] - Load Users'
);

export const LoadUsersSuccess = createAction(
    '[Super Admin Users Effects] - Load Users Success',
    props<{
        users: UserListItem[]
    }>()
)

export const LoadUsersError = createAction(
    '[Super Admin Users Effects] - Load Users Error',
    props<{
        message: string
    }>()
)

export const SelectInstitution = createAction(
    '[Super Admin User List] - Select Institution'
)

export const SetInstitutionId = createAction(
    '[Super Admin Users] - Set InstitutionId',
    props<{
        institutionId: string
    }>()
)

export const UserListItemAdded = createAction(
    '[Admin Users] - User List Item Added',
    props<{
        userListItem: UserListItem
    }>()
)

export const UserListItemUpdated = createAction(
    '[Admin Users] - User List Item Updated',
    props<{
        userListItem: UserListItem
    }>()
)

export const AccessRoleUpdated = createAction(
    '[Admin Users] - Access Role Updated',
    props<{
        accessRole: AccessRoleSummary
    }>()
)

export const AccessRoleAdded = createAction(
    '[Admin Users] - Access Role Added',
    props<{
        accessRole: AccessRoleSummary
    }>()
)

export const AccessRoleDeleted = createAction(
    '[Admin Users] - Access Role Deleted',
    props<{
        accessRole: AccessRoleSummary
    }>()
)




