import {Component,Input} from '@angular/core';

@Component({
  selector: 'wizard-progress',
  templateUrl: './wizard-progress.component.html',
  styleUrls: ['./wizard-progress.component.scss']
})
export class WizardProgressComponent  {

  @Input() progressPercent: number;

  constructor() {
  }

  ngOnInit(): void {

  }

}
