import { Component, OnInit, ViewEncapsulation, Output, EventEmitter, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { UserListItem } from 'app/shared/user/models';
import { Sort } from 'app/core/models';
import { InstitutionService } from 'app/shared/institution/services/institution.service';
import { CourseExchangeListItem } from 'app/shared/courseExchange/models/courseExchangeListItem';
import { CourseExchangeService } from 'app/shared/courseExchange/services/course-exchange.service';
import { IAppState } from 'app/store/state/app.state';
import { Store, select } from '@ngrx/store';
import * as fromUsers from '@shared/user/store';
import * as fromUserList from '@shared/user/components/user-list/store';
import * as fromInstitution from '@institution/store';

@Component({
  selector: 'institution-user-list',
  templateUrl: './institution-user-list.container.html',
  styleUrls: ['./institution-user-list.container.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,

})
export class InstitutionUserListComponent implements OnInit {
  courseExchanges$: Observable<CourseExchangeListItem[]>;
  institutionId: string;
  pageTitle: string = "Users";
  pageSubTitle: string = '';
  panelOpenState = false;
  users$: Observable<UserListItem[]>;
  @Input() emitEvents: boolean;
  @Output() goToDashboard: EventEmitter<void> = new EventEmitter<void>()
  constructor(
    public courseExchangeService: CourseExchangeService,
    public dialog: MatDialog,
    public institutionService: InstitutionService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<IAppState>) { }
    
  ngOnInit(): void {
    this.institutionId = this.route.params['institutionId'];
    this.users$ = this.store.pipe(select(fromUsers.Selectors.Users));
    const sorts: Sort[] = [
      { name: 'Last Name', icon: 'person_pin', value:'lastName', isDefault: true},
      { name: 'Title', icon: 'title', value: 'title', isDefault: false },
      { name: 'Department', icon: 'bubble_chart', value: 'department', isDefault: false },
      { name: 'Role', icon: 'account_box', value: 'institutionRole', isDefault: false }
    ];
    this.store.dispatch(fromUserList.Actions.LoadSorts({sorts}));
  }

  addUser(): void {
    this.router.navigate([`/institution/${this.institutionId}/users/detail/new`]);  
  }

  goToUser(user: UserListItem): void {
    this.router.navigate([`/institution/${this.institutionId}/users/detail/${user.id}`]);
  }

  onGoToDashboard(): void{
    if(this.emitEvents)
      this.goToDashboard.emit();
    else{
      this.store.dispatch(fromInstitution.Actions.Dashboard({}))
    }
  }
}
