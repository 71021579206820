import {createAction, props} from '@ngrx/store';
import {CourseCalendarViewModel} from '@shared/provider/models';
import { ECourseScheduleTypeCreateActions } from '@provider/constants';
import { EAlphabetSort } from 'app/constants/shared';
import { CopyCourseCalendarCommand } from '@shared/provider/commands';


export const changeSelectedAcademicYear = createAction(
  '[CourseSchedule] changeSelectedAcademicYear',
  props<{
    payload: string
  }>()
);

export const changeSelectedSemester = createAction(
  '[CourseSchedule] changeSelectedSemester',
  props<{
    payload: string
  }>()
);

export const changeSelectedToAcademicYear = createAction(
  '[CourseSchedule] changeSelectedToAcademicYear',
  props<{
    payload: string | null
  }>()
);

export const changeSelectedToSemester = createAction(
  '[CourseSchedule] changeSelectedToSemester',
  props<{
    payload: string
  }>()
);

export const changeSelectedFromAcademicYear = createAction(
  '[CourseSchedule] changeSelectedFromAcademicYear',
  props<{
    payload: string
  }>()
);

export const changeSelectedFromSemester = createAction(
  '[CourseSchedule] changeSelectedFromSemester',
  props<{
    payload: string
  }>()
);

export const changeSelectedProviderCourses = createAction(
  '[CourseSchedule] changeSelectedProviderCourses',
  props<{
    payload: Array<string>
  }>()
);

export const changeTypeCreateAction = createAction(
  '[CourseSchedule] changeTypeCreateAction',
  props<{
    payload: ECourseScheduleTypeCreateActions
  }>()
);
export const CopyCourseCalendar = createAction(
  '[CourseSchedule] - Copy Course Calendar',
  props<{
  command: CopyCourseCalendarCommand
  }>()
)

export const GetCourseCalendar = createAction(
  '[CourseSchedule] - Get Course Calendar',
  props<{
    providerId: string,
    academicYearId: string,
    semesterId: string,
    providerCourseIds: Array<string>
  }>()
)

export const fetchCourseCalendarViewModelSuccess = createAction(
  '[CourseSchedule] fetchCourseCalendarViewModelSuccess',
  props<{
    payload: CourseCalendarViewModel
  }>()
);

export const changeSelectedChangeAcademicYear = createAction(
  '[CourseSchedule] changeSelectedChangeAcademicYear',
  props<{
    payload: string
  }>()
);

export const changeSelectedChangeSemester = createAction(
  '[CourseSchedule] changeSelectedChangeSemester',
  props<{
    payload: string
  }>()
);

export const selectAllInSession = createAction(
  '[CourseSchedule] selectAllInSession',
  props<{
    payload: {
      sessionId: string;
      checked: boolean;
    }
  }>()
);

export const selectSessionInProviderCourse = createAction(
  '[CourseSchedule] selectSessionInProviderCourse',
  props<{
    payload: {
      sessionId: string;
      providerCourseId: string;
      checked: boolean;
    }
  }>()
);

export const setSearchValue = createAction(
  '[CourseSchedule] setSearchValue',
  props<{
    payload: string
  }>()
);

export const setSortByCode = createAction(
  '[CourseSchedule] setSortByCode',
  props<{
    payload: EAlphabetSort
  }>()
);

export const setSortByTitle = createAction(
  '[CourseSchedule] setSortByTitle',
  props<{
    payload: EAlphabetSort
  }>()
);
