import {Injectable} from '@angular/core';
import {Actions, ofType, createEffect} from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import * as fromSuperAdminDashboard from '@admin/dashboard/store';
import { AdminDashboardService } from '@admin/dashboard/services/admin-dashboard.service';

@Injectable()
export class SuperAdminDashboardEffects {
  constructor(private actions$: Actions, private adminDashboardService: AdminDashboardService) 
  {
  }

  loadCourseExchangeDashboardData$ = createEffect(() => {
    return this.actions$.pipe(
        ofType(fromSuperAdminDashboard.Actions.LoadDashboardData),
        /** An EMPTY observable only emits completion. Replace with your own observable stream */
        switchMap((action) => {
          return this.adminDashboardService.getDashboardData()
        }),
        map((data) => {
          return fromSuperAdminDashboard.Actions.LoadDashboardDataSuccess({data});
        }),
        catchError((err) => of(fromSuperAdminDashboard.Actions.LoadDashboardDataError({message: err.message})))
    );
  });
}