import { Semester } from "@shared/calendar/models";
import convertSessionDates from "./convertSessionDates";

export default (semester:Semester): Semester => {
    return {
        ...semester,
        startDate: new Date(semester.startDate2.year, semester.startDate2.month-1, semester.startDate2.day),
        endDate: new Date(semester.endDate2.year, semester.endDate2.month-1, semester.endDate2.day),
        sessions: semester.sessions.map((session)=>{
            return convertSessionDates(session);
        })
    }
  }
