import { SemesterFilter } from "../models";

  export default  (semesterFilters: SemesterFilter[], semesterId: string): SemesterFilter[] => {
    return semesterFilters.map(semesterFilter=>{
        if(semesterFilter.id===semesterId){
          const expanded = !semesterFilter.expanded;
          return {
            ...semesterFilter,
            expanded
          }
        }
        return semesterFilter
      });
  }