import { Component, ViewEncapsulation, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BaseResponse, Order, Sort } from 'app/core/models';
import { BehaviorSubject } from 'rxjs';
import { CourseExchangeFee, CourseExchangeFeeListItem } from 'app/shared/courseExchange/models';
import { MatDialog } from '@angular/material/dialog';
import { FeesDetailComponent, FeeDetailData } from '../fees-detail/fees-detail.component';
import { UntypedFormControl } from '@angular/forms';
import { sortBy } from 'sort-by-typescript';
import { ManagementFeeSplit, ProviderFeeSplit } from 'app/course-exchange/fees/helpers/feeSplits';
import * as moment from 'moment';
import { defaultMaxDate, defaultMinDate, addDay } from 'app/shared/helpers/constants/dateConstants';
import { PercentFormatter, CurrencyFormatter } from 'app/shared/helpers/formatters';
import { CourseExchangeService } from 'app/shared/courseExchange/services/course-exchange.service';
import { tap } from 'rxjs/operators';
import { PopupService } from 'app/core/services/popup.service';

@Component({
  selector: 'elb-fees-list',
  templateUrl: './fees-list.component.html',
  styleUrls: ['./fees-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FeesListComponent implements OnChanges {
  @Input() courseExchangeFees: CourseExchangeFeeListItem[];
  courseExchangeFees$: BehaviorSubject<CourseExchangeFeeListItem[]> = new BehaviorSubject<CourseExchangeFeeListItem[]>([]);
  @Input() canEdit: boolean = false;
  @Input() courseExchangeId: string = '';
  filteredFees$: BehaviorSubject<CourseExchangeFeeListItem[]> = new BehaviorSubject<CourseExchangeFeeListItem[]>(new Array<CourseExchangeFeeListItem>());
  selectedOrder: Order = { name: 'Ascending', icon: 'arrow_upward', value: '', isDefault: true };
  selectedSort: Sort = { value: 'courseLevel.description', name: 'Course Level', icon: 'signal_cellular_alt', isDefault: true };
  sortControl = new UntypedFormControl(this.selectedSort);
  sorts: Sort[] = [
    this.selectedSort,
    { value: 'memberFeeAmount', name: 'Member Fee', icon: 'attach_money', isDefault: false },
    { value: 'providerFeeAmount', name: 'Provider Fee', icon: 'attach_money', isDefault: false },
    { value: 'managementFeeAmount', name: 'Management Fee', icon: 'attach_money', isDefault: false },
    { value: 'providerFeeSplit', name: 'Provider Fee Split', icon: 'menu', isDefault: false },
    { value: 'managementFeeSplit', name: 'Management Fee Split', icon: 'menu', isDefault: false },
  ];
  orders: Order[] = [
    this.selectedOrder,
    { name: 'Descending', icon: 'arrow_downward', value: '-', isDefault: false },
  ];
  orderByControl = new UntypedFormControl(this.selectedOrder);
  
  constructor(private dialog: MatDialog, public percentFormatter: PercentFormatter,
      private courseExchangeService: CourseExchangeService, public currencyFormatter: CurrencyFormatter,
      private popupService: PopupService) { }
  canRemove(courseExchangeFee: CourseExchangeFee): boolean{
    return moment(courseExchangeFee.effectiveDate).isAfter(moment().toDate());
  }
  changeOrderBy(orderBy: Order): void {
    this.selectedOrder = orderBy;
    if (this.selectedSort === null) return;
    let courses = this.filteredFees$.value;
    let order = this.selectedOrder === null ? "" : this.selectedOrder.value;
    let result = courses.sort(sortBy(order + this.selectedSort.value));
    this.filteredFees$.next(result);
  }
  changeSort(sort: Sort): void {
    this.selectedSort = sort;
    let courses = this.filteredFees$.value;
    let orderBy = this.selectedOrder === null ? "" : this.selectedOrder.value;
    let result = courses.sort(sortBy(orderBy + sort.value));
    this.filteredFees$.next(result);
  }
  createVersion(courseExchangeFee: CourseExchangeFee, fees:CourseExchangeFee[]): void{
    fees = fees.sort(sortBy('-effectiveDate'));
    courseExchangeFee = fees[0];
    var newVersion = this.getNewVersion(courseExchangeFee,fees);
    this.gotoDetail(newVersion);
  }
  editCourseExchangeFee(courseExchangeFee: CourseExchangeFee, fees: CourseExchangeFee[]): void{
    var edit = courseExchangeFee;
    if(!courseExchangeFee.canEdit) 
      edit = this.getNewVersion(courseExchangeFee,fees);
    
    this.gotoDetail(edit);
  }
  exportToExcel(): void{

  }
  filterChanged(filterValue: string) {
    var filterValueToLower = filterValue.toLowerCase().trim();
    if (filterValueToLower === "") this.filteredFees$.next(this.courseExchangeFees$.value);
    var filteredFees = this.filteredFees$.value;
    filteredFees = filteredFees.filter(x => x.courseLevel.description.toLowerCase().includes(filterValueToLower));
    this.filteredFees$.next(filteredFees);
  }
  getManagementFeeSplit(courseExchangeFee: CourseExchangeFee): string {
    return this.percentFormatter.format(ManagementFeeSplit(courseExchangeFee));
  }
  getNewVersion(courseExchangeFee :CourseExchangeFee, fees: CourseExchangeFee[]): CourseExchangeFee{
    var next:CourseExchangeFee = null;
    if(courseExchangeFee.nextVersionId != ""){
      next = fees.find(x=>x.id==courseExchangeFee.nextVersionId);
    }
    var newCourseExchangeFee: CourseExchangeFee = {
      ...courseExchangeFee,
      id: '',
      previousVersionId: courseExchangeFee.id,
      nextVersionId: courseExchangeFee.nextVersionId,
      effectiveDate: moment(courseExchangeFee.effectiveDate).isSame(defaultMinDate) ? addDay(moment().toDate()) : addDay(courseExchangeFee.effectiveDate),
      expiryDate:  defaultMaxDate
    };
    return newCourseExchangeFee;
  }
  getProviderFeeSplit(courseExchangeFee: CourseExchangeFee): string {
    return this.percentFormatter.format(ProviderFeeSplit(courseExchangeFee));
  }
  private gotoDetail(courseExchangeFee: CourseExchangeFee): void {
    var courseExchangeFees = this.courseExchangeFees$.value;
    var idx = courseExchangeFees.findIndex(x=>x.courseLevel.id==courseExchangeFee.courseLevelId);
    var listItem = courseExchangeFees[idx];
    var fees = [...listItem.versions];
    var previous: CourseExchangeFee = null;
    if(courseExchangeFee.previousVersionId != ''){
        previous = fees.find(x=>x.id===courseExchangeFee.previousVersionId)
    }
    var next: CourseExchangeFee = null;
    if(courseExchangeFee.nextVersionId != ''){
      next = fees.find(x=>x.id===courseExchangeFee.nextVersionId);
    }
    var minEffectiveDate = previous === null ? defaultMinDate: addDay(previous.expiryDate);
    var maxExpiryDate = next === null ? defaultMaxDate : addDay(next.effectiveDate,-1);
    var data: FeeDetailData = {
      courseExchangeId: this.courseExchangeId,
      courseLevel: listItem.courseLevel.description,
      courseExchangeFee,
      minEffectiveDate,
      maxExpiryDate
    };
    const dialogRef = this.dialog.open(FeesDetailComponent, {
      width: '750px',
      data
    });

    dialogRef.afterClosed().subscribe((savedFees: CourseExchangeFee[]) => {
      if(savedFees){
        this.recalibrateFees(courseExchangeFee.courseLevelId,savedFees, [])
    }});
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.courseExchangeFees && changes.courseExchangeFees.currentValue) {
      const courseExchangeFees = changes.courseExchangeFees.currentValue;
      this.courseExchangeFees$.next(courseExchangeFees);
      this.filteredFees$.next(courseExchangeFees);
    }
  }
  removeVersion(courseExchangeFee: CourseExchangeFee): void{
    this.courseExchangeService.deleteCourseExchangeFee(this.courseExchangeId, courseExchangeFee.id).subscribe();
  }
  recalibrateFees(courseLevelId: string, savedFees: CourseExchangeFee[], deletedFees: CourseExchangeFee[]): void{
    var courseExchangeFees = this.courseExchangeFees$.value;
    var idx = courseExchangeFees.findIndex(x=>x.courseLevel.id==courseLevelId);
    var listItem = courseExchangeFees[idx];
    var fees = [...listItem.versions];
    
    deletedFees.forEach(fee=>{
      fees = fees.filter(x=>x.id != fee.id);
    });
    
    savedFees.forEach(fee=>{
      var savedFeeIdx = fees.findIndex(x=>x.id==fee.id);
      if(savedFeeIdx === -1){
        fees = [...fees,fee];
      }else{
        fees = [...fees.slice(0,savedFeeIdx), fee, ...fees.slice(savedFeeIdx+1)];
      }
    });

    var currentFee = fees.find(x=>moment().isBetween(x.effectiveDate, x.expiryDate));
    fees = fees.sort(sortBy("effectiveDate"));
    var newListItem: CourseExchangeFeeListItem = {
      courseLevel : listItem.courseLevel,
      currentFee,
      memberFeeAmount: currentFee.memberFeeAmount,
      providerFeeAmount: currentFee.providerFeeAmount,
      managementFeeAmount: currentFee.managementFeeAmount,
      versions: fees
    };
    courseExchangeFees = [...courseExchangeFees.slice(0,idx),newListItem,...courseExchangeFees.slice(idx+1)];
    this.courseExchangeFees$.next(courseExchangeFees);
    this.filteredFees$.next(courseExchangeFees);
  }
}
