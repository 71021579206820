import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { Store, select } from "@ngrx/store";
import { tap, first, finalize, filter, map } from "rxjs/operators";
import { IAppState } from 'app/store/state/app.state';
import { AccreditationBody } from 'app/core/referenceData';
import * as fromRefData from '@refData';
@Injectable()
export class AccreditationBodiesResolver implements Resolve<AccreditationBody[]>{
    isLoading = false;
    constructor(private store: Store<IAppState>){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
        return this.store 
            .pipe(
                select(fromRefData.Selectors.accreditationBodies),
                map((accreditationBodies)=>{
                    if(!!accreditationBodies && accreditationBodies.length>0){
                        this.store.dispatch(fromRefData.Actions.LoadAccreditationBodiesSuccess({accreditationBodies}));
                    }else if(!this.isLoading){
                        this.isLoading = true;
                        this.store.dispatch(fromRefData.Actions.LoadAccreditationBodies({forceReload:false}));
                    }
                    return true;
                }),
                filter(accreditationBodies => !!accreditationBodies),
                first(),
                finalize(()=>this.isLoading=false)
            )
    }
}