import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import * as fromStore from '@courseExchange/institution';
import { EffectsModule } from '@ngrx/effects';
@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature('courseExchange/institutions', fromStore.courseExchangeInstitutionReducer),
    EffectsModule.forFeature([...fromStore.EFFECTS])
  ],
  providers: [...fromStore.RESOLVERS]
})
export class CourseExchangeInstitutionStoreModule { }
