import { createReducer, on } from '@ngrx/store';
import * as fromCourseExchangeBilling from '@courseExchange/billing/store';
import * as fromCourseExchange from '@courseExchange/store'
import { MonthlyEarningsStatement } from '@courseExchange/billing/models';

  export interface CourseExchangeBillingState {
    courseExchangeId: string;
    monthlyStatements: Array<MonthlyEarningsStatement>;
    isMonthlyStatementsLoading: boolean;
    areMonthlyStatementsLoaded: boolean;
    currentMonthlyStatement: MonthlyEarningsStatement;
    isMonthlyStatementLoading: boolean;
  };
  
  export const initialCourseExchangeBillingState: CourseExchangeBillingState = {
    courseExchangeId: '',
    monthlyStatements: [],
    isMonthlyStatementsLoading: false,
    areMonthlyStatementsLoaded: false,
    currentMonthlyStatement: undefined,
    isMonthlyStatementLoading: false
  };
  
  export const courseExchangeBillingReducer = createReducer(
    initialCourseExchangeBillingState,
    on(fromCourseExchange.Actions.LoadCourseExchangeSuccess, (state, {courseExchange})=>{
        return{
            ...initialCourseExchangeBillingState,
            courseExchangeId: courseExchange.id
        }
    }),
    on(fromCourseExchangeBilling.Actions.LoadMonthlyStatementsSuccess, (state, {monthlyStatements})=>{
      if(monthlyStatements.length>0){
        const currentMonthlyStatement = monthlyStatements[0];
        return {
          ...state,
          monthlyStatements,
          currentMonthlyStatement,
          areMonthlyStatementsLoaded: true,
          isMonthlyStatementsLoading: false
        }
      }else{
        return {
          ...state,
          areMonthlyStatementsLoaded: true,
          isMonthlyStatementsLoading: false
        }
      } 
    }),
    on(fromCourseExchangeBilling.Actions.LoadMonthlyStatementsError, (state, action)=>{
        return {
            ...state,
            monthlyStatements: [],
            areMonthlyStatementsLoaded: false,
            isMonthlyStatementsLoading: false
        }
    }),
    // on(fromCourseExchangeBilling.Actions.MonthlyEarningStatementUpdated, (state, {monthlyEarningsStatement})=>{
    //   const idx = state.monthlyStatements.findIndex(x=>x.id===monthlyEarningsStatement.id);
    //   if(idx===-1) return state;
    //   const monthlyStatements = [...state.monthlyStatements.slice(0,idx),monthlyEarningsStatement, ...state.monthlyStatements.slice(idx+1)];
    //   return {
    //     ...state,
    //     monthlyStatements
    //   }
    // }),
    // on(fromCourseExchangeBilling.Actions.MonthlyEarningStatementAdded, (state, {monthlyEarningsStatement})=>{
    //   const monthlyStatements = [...state.monthlyStatements,monthlyEarningsStatement];
    //   return {
    //     ...state,
    //     monthlyStatements
    //   }
    // })
  );
  
  
  