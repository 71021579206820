import { Component, OnInit, Inject } from '@angular/core';
import { CourseExchangeFee } from 'app/shared/courseExchange/models';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EditCourseExchangeFeeCommand } from 'app/shared/courseExchange/commands/updateCourseExchangeFee.command';
import { AddCourseExchangeFeeCommand } from 'app/shared/courseExchange/commands';
import { CurrencyFormatter, PercentFormatter, } from 'app/shared/helpers/formatters';
import { IAppState } from 'app/store';
import { Store } from '@ngrx/store';
import * as fromCourseExchange from '@courseExchange/store';
export interface FeeDetailData {
  courseExchangeId: string,
  courseLevel: string;
  courseExchangeFee: CourseExchangeFee,
  minEffectiveDate: Date,
  maxExpiryDate: Date,
}

@Component({
  selector: 'app-fees-detail',
  templateUrl: './fees-detail.component.html',
  styleUrls: ['./fees-detail.component.scss']
})
export class FeesDetailComponent implements OnInit {

  courseFeeForm: UntypedFormGroup;
  dialogTitle: string = '';
  isNew: boolean;
  constructor(private currencyFormatter: CurrencyFormatter,
    public dialogRef: MatDialogRef<FeesDetailComponent>,
    private formBuilder: UntypedFormBuilder,
    private percentFormatter: PercentFormatter,
    private store:Store<IAppState>,
    @Inject(MAT_DIALOG_DATA) public data: FeeDetailData) { }
  close(): void {
    this.dialogRef.close(null);
  }
  ngOnInit() {
    const courseFee: CourseExchangeFee = this.data.courseExchangeFee;
    this.isNew = courseFee.id == '';
    this.dialogTitle = this.isNew ? "Add " : "Edit " + this.data.courseLevel;
    var providerFeeSplit = courseFee.memberFeeAmount === 0 ? 0.75 : courseFee.providerFeeAmount / courseFee.memberFeeAmount;
    var managementFeeSplit = courseFee.memberFeeAmount === 0 ? 0.25 : courseFee.managementFeeAmount / courseFee.memberFeeAmount;

    this.courseFeeForm = this.formBuilder.group({
      id: [courseFee.id],
      memberFeeAmount: [this.currencyFormatter.format(courseFee.memberFeeAmount), Validators.required],
      providerFeeAmount: [this.currencyFormatter.format(courseFee.providerFeeAmount), Validators.required],
      providerFeeSplit: [this.percentFormatter.format(providerFeeSplit)],
      managementFeeAmount: [this.currencyFormatter.format(courseFee.managementFeeAmount), Validators.required],
      managementFeeSplit: [this.percentFormatter.format(managementFeeSplit)],
      effectiveDate: [courseFee.effectiveDate],
      expiryDate: [courseFee.expiryDate]
    }, { validator: Validators.compose([this.validateEffectiveExpiry('effectiveDate', 'expiryDate')]) });
  }
  addCourseFee(): void {
    const courseFee = this.data.courseExchangeFee;
    var value = this.courseFeeForm.value;

    var command: AddCourseExchangeFeeCommand = {
      courseExchangeId: this.data.courseExchangeId,
      courseLevelId: courseFee.courseLevelId,
      memberFeeAmount: this.stripValue(value.memberFeeAmount),
      providerFeeAmount: this.stripValue(value.providerFeeAmount),
      managementFeeAmount: this.stripValue(value.managementFeeAmount),
      effectiveDate: value.effectiveDate,
      expiryDate: value.expiryDate,
      previousVersionId: courseFee.previousVersionId,
      nextVersionId: courseFee.nextVersionId
    };

    this.store.dispatch(fromCourseExchange.Actions.AddCourseExchangeFee({command}));
    this.dialogRef.close();
  }
  changeMemberFee(value: string): void {
    var memberFee = this.stripValue(value);
    if (memberFee < 0) memberFee = 0;
    var providerFeeSplit: number = this.stripPercent(this.courseFeeForm.value.providerFeeSplit);
    var managementFeeSplit: number = this.stripPercent(this.courseFeeForm.value.managementFeeSplit);
    this.courseFeeForm.patchValue({
      providerFeeAmount: this.currencyFormatter.format(memberFee * providerFeeSplit),
      managementFeeAmount: this.currencyFormatter.format(memberFee * managementFeeSplit)
    }, { emitEvent: false })
  }
  formatMemberFee(value: string): void {
    var memberFee = Number(value.replace(/[^0-9.-]+/g, ""));;
    this.courseFeeForm.patchValue({
      memberFeeAmount: this.currencyFormatter.format(memberFee)
    }, { emitEvent: false })
  }
  changeManagementFee(value: string): void {
    var managementFeeAmount = this.stripValue(value);
    var memberFeeAmount: number = this.stripValue(this.courseFeeForm.value.memberFeeAmount);
    if (managementFeeAmount > memberFeeAmount) managementFeeAmount = memberFeeAmount;
    var providerFeeAmount = memberFeeAmount - managementFeeAmount;
    var providerFeeSplit = memberFeeAmount === 0 ? 0.75 : providerFeeAmount / memberFeeAmount;
    var managementFeeSplit = 1 - providerFeeSplit;
    this.courseFeeForm.patchValue({
      providerFeeAmount: this.currencyFormatter.format(providerFeeAmount),
      providerFeeSplit: this.percentFormatter.format(providerFeeSplit),
      managementFeeSplit: this.percentFormatter.format(managementFeeSplit)
    }, { emitEvent: false })
  }
  formatManagementFee(value: string): void {
    var managementFee = Number(value.replace(/[^0-9.-]+/g, ""));;
    this.courseFeeForm.patchValue({
      managementFeeAmount: this.currencyFormatter.format(managementFee)
    }, { emitEvent: false })
  }
  changeProviderFee(value: string): void {
    var providerFeeAmount = this.stripValue(value);
    var memberFeeAmount: number = this.stripValue(this.courseFeeForm.value.memberFeeAmount);
    if (providerFeeAmount > memberFeeAmount) providerFeeAmount = memberFeeAmount;
    var managementFeeAmount = memberFeeAmount - providerFeeAmount;
    var providerFeeSplit = memberFeeAmount === 0 ? 0.75 : providerFeeAmount / memberFeeAmount;
    var managementFeeSplit = 1 - providerFeeSplit;
    this.courseFeeForm.patchValue({
      memberFeeAmount: this.currencyFormatter.format(memberFeeAmount),
      managementFeeAmount: this.currencyFormatter.format(managementFeeAmount),
      providerFeeSplit: this.percentFormatter.format(providerFeeSplit),
      managementFeeSplit: this.percentFormatter.format(managementFeeSplit)
    }, { emitEvent: false })
  }
  formatProviderFee(value: string): void {
    var providerFee = Number(value.replace(/[^0-9.-]+/g, ""));;
    this.courseFeeForm.patchValue({
      providerFeeAmount: this.currencyFormatter.format(providerFee)
    }, { emitEvent: false })
  }
  changeProviderSplit(value: string): void {
    var providerSplit = this.stripPercent(value);
    if (providerSplit > 1) providerSplit = 1;
    var managmentSplit = 1 - providerSplit;
    var memberFeeAmount: number = this.stripValue(this.courseFeeForm.value.memberFeeAmount);
    var providerFeeAmount = memberFeeAmount * providerSplit;
    var managementFeeamount = memberFeeAmount * managmentSplit;
    this.courseFeeForm.patchValue({
      managementFeeAmount: this.currencyFormatter.format(managementFeeamount),
      providerFeeAmount: this.currencyFormatter.format(providerFeeAmount),
      managementFeeSplit: this.percentFormatter.format(managmentSplit)
    });
  }
  formatProviderSplit(value: string): void {
    var providerSplit = this.stripValue(value) / 100;
    if (providerSplit > 1) providerSplit = 1;
    this.courseFeeForm.patchValue({
      providerFeeSplit: this.percentFormatter.format(providerSplit)
    });
  }
  changeManagementSplit(value: string): void {
    var managementSplit = this.stripPercent(value);
    if (managementSplit > 1) managementSplit = 1;
    var providerSplit = 1 - managementSplit;
    var memberFeeAmount: number = this.stripValue(this.courseFeeForm.value.memberFeeAmount);
    var providerFeeAmount = memberFeeAmount * providerSplit;
    var managementFeeamount = memberFeeAmount * managementSplit;
    this.courseFeeForm.patchValue({
      managementFeeAmount: this.currencyFormatter.format(managementFeeamount),
      providerFeeAmount: this.currencyFormatter.format(providerFeeAmount),
      providerFeeSplit: this.percentFormatter.format(providerSplit)
    });
  }
  formatManagementSplit(value: string): void {
    var managementSplit = this.stripValue(value) / 100;
    if (managementSplit > 1) managementSplit = 1;
    this.courseFeeForm.patchValue({
      managementFeeSplit: this.percentFormatter.format(managementSplit)
    });
  }
  
  stripPercent(value: string): number {
    return Number(value.replace(/[^0-9.-]+/g, "")) / 100;
  }
  stripValue(value: string): number {
    return Number(value.replace(/[^0-9.-]+/g, ""));
  }
  saveCourseFee(): void {
    const courseFee = this.data.courseExchangeFee;
    var value = this.courseFeeForm.value;

    var command: EditCourseExchangeFeeCommand = {
      courseExchangeFeeId: courseFee.id,
      memberFeeAmount: this.stripValue(value.memberFeeAmount),
      providerFeeAmount: this.stripValue(value.providerFeeAmount),
      managementFeeAmount: this.stripValue(value.managementFeeAmount),
      effectiveDate: value.effectiveDate,
      expiryDate: value.expiryDate,
      previousVersionId: courseFee.previousVersionId,
      nextVersionId: courseFee.nextVersionId
    };
    this.store.dispatch(fromCourseExchange.Actions.UpdateCourseExchangeFee({command}));
    this.dialogRef.close();
  }

  setEffectiveDate(e: any) {
    var effectiveDate = new Date(e.target.value);
    var expiryDate = new Date(this.courseFeeForm.get('expiryDate').value);
    if (effectiveDate >= expiryDate) effectiveDate.setDate(expiryDate.getDate() - 1);
    var convertedDate = effectiveDate.toISOString().substring(0, 10);
    this.courseFeeForm.get('effectiveDate').setValue(convertedDate, { onlyself: true });
  }

  setExpiryDate(e: any) {
    var expiryDate = new Date(e.target.value);
    var effectiveDate = new Date(this.courseFeeForm.get('effectiveDate').value);
    if (expiryDate <= effectiveDate) expiryDate.setDate(effectiveDate.getDate() + 1);
    var convertedDate = expiryDate.toISOString().substring(0, 10);
    this.courseFeeForm.get('expiryDate').setValue(convertedDate, { onlyself: true });
  }

  get showEffectiveDate(): boolean {
    return this.data.courseExchangeFee.previousVersionId != '';
  }
  get showExpiryDate(): boolean {
    return this.data.courseExchangeFee.nextVersionId != '';
  }

  validateEffectiveExpiry(effectiveDate: string, expiryDate: string): any {
    var effective = Date.parse(effectiveDate);
    var expiry = Date.parse(expiryDate);
    if (effective >= expiry) {
      return { invalidDates: true }
    }
  }

}
