<div class="student-details-for-dialog">
  <div class="row">
    <div class="col">
      <div class="head-info-box">
        <div class="title">Student ID:</div>
        <div class="text">{{student.studentNumber}}</div>
      </div>
    </div>

    <div class="col">
      <div class="head-info-box">
        <div class="title">Student:</div>
        <div class="text">{{student.firstName}}, {{student.lastName}}</div>
      </div>
    </div>
  </div>

  <div class="accordion-action" (click)="isAccordionShow = !isAccordionShow">
    {{isAccordionShow ? 'Hide' : 'Show'}} Student Details
  </div>

  <div [ngClass]="['accordion-hidden-content', isAccordionShow ? 'is_show' : 'is_hidden']">
    <ng-template #notExist>
      <div class="text">-</div>
    </ng-template>

    <div class="row">
      <div class="col">
        <div class="info-box">
          <div class="title">Address:</div>
          <div class="text" *ngIf="student.address; else notExist">
            <span>{{student.address.addressLine1}}</span>
            <span>{{student.address.addressLine2}}</span>
            <span>
              {{student.address.city}}, {{student.address.region.shortDescription}}, {{student.address.postalCode}}
            </span>
          </div>
        </div>
      </div>

      <div class="col">
        <div class="info-boxes">
          <div class="info-box info-box-with-icon">
            <div class="title">
              <div class="icon">
                <mat-icon>calendar_today</mat-icon>
              </div>
              <span>DOB:</span>
            </div>
            <div class="text">{{student.dateOfBirth | date : 'shortDate'}}</div>
          </div>

          <div class="info-box">
            <div class="title">Gender:</div>
            <div class="text">{{student.gender}}</div>
          </div>
        </div>

        <div class="info-box info-box-with-icon">
          <div class="title">
            <div class="icon">
              <mat-icon>phone</mat-icon>
            </div>
            <span>Phone:</span>
          </div>
          <div class="text">
            <a [href]="'tel:' + student.phoneNumber" class="box-info">{{student.phoneNumber}}</a>
          </div>
        </div>
      </div>
    </div>

    <div class="email" *ngIf="student.emailAddress">
      <div class="icon">
        <mat-icon>mail_outline</mat-icon>
      </div>

      <div class="title">Email 1:</div>

      <div class="link">
        <a [href]="'mailto:' + student.emailAddress">{{student.emailAddress}}</a>
      </div>
    </div>

    <div class="email" *ngIf="student.emailAddress2">
      <div class="icon">
        <mat-icon>mail_outline</mat-icon>
      </div>

      <div class="title">Email 2:</div>

      <div class="link">
        <a [href]="'mailto:' + student.emailAddress2">{{student.emailAddress2}}</a>
      </div>
    </div>
  </div>
</div>
