import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import * as fromStore from '.';
import { InstitutionStoreModule } from '@institution/store/institutionStore.module';
@NgModule({
  declarations: [],
  imports: [
    InstitutionStoreModule,
    StoreModule.forFeature('member/courses', fromStore.memberCourseReducer),
    EffectsModule.forFeature([...fromStore.EFFECTS])
  ],
  providers: [...fromStore.RESOLVERS]
})
export class MemberCourseStoreModule {
}
