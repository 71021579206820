import { createAction, props } from "@ngrx/store"
import { EnrollmentMigrationCommand } from "@shared/member/models/commands/enrollmentMigrationCommand"
import { StudentDataMergeCommand } from "@shared/student/commands/StudentDataMergeCommand"

export const SelectStudentMergeDataFile = createAction(
    'Various Places - Select Student Merge Data File',
    props<{
        memberId: string,
        institutionId: string
    }>()
  )
  
  export const GetStudentMergeData = createAction(
    'Various Places - Get Student Merge Data File',
    props<{
        memberId: string,
        institutionId: string
    }>()
  )
  
  export const StudentMergeData = createAction(
    'Select Student Merge Data File - Student Merge Data',
    props<{
        memberId: string,
        institutionId: string,
        studentMergeData: StudentDataMergeCommand[]
    }>()
  )
  export const BulkMergeData = createAction(
    'Various Places - Bulk Merge Data Dialog',
    props<{
      typename: string
    }>()
  )

  export const GetMemberCourseMergeData = createAction(
    'Various Places - Get Member Course Merge Data',
    props<{
        memberId: string,
        institutionId: string
    }>()
  )
  
  export const SelectEnrollmentMigrationDataFile = createAction(
    'Various Places - Select Member Enrollment Migration Data File',
    props<{
        memberId: string,
        institutionId: string
    }>()
  )
  
  export const GetEnrollmentMigrationData = createAction(
    'Various Places - Get Member Enrollment Migration Data File',
    props<{
        memberId: string,
        institutionId: string
    }>()
  )
  
  export const EnrollmentMigrationData = createAction(
    'Enrollment Migration Data Dialog - Member Enrollment Migration Data',
    props<{
        memberId: string,
        institutionId: string
        enrollmentMigrationData: EnrollmentMigrationCommand[]
    }>()
  )
  
  export const EnrollmentMigrationDataSuccess = createAction(
    'Member Effects - Member Enrollment Migration Data Success'
  )
  
  export const GetGradeData = createAction(
    'Various Places - Get Member Grade Data File',
    props<{
        memberId: string,
        institutionId: string
    }>()
  )

  export const ShowMigrateData = createAction(
    'Various Places - Show Migrate Data Dialog',
    props<{
      memberId: string,
      institutionId: string
  }>()
  )
  
