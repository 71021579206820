import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetLoadingSpinnerComponent } from './widget-loading-spinner/widget-loading-spinner.component';



@NgModule({
  declarations: [WidgetLoadingSpinnerComponent],
  imports: [
    CommonModule
  ],
  exports     : [
    WidgetLoadingSpinnerComponent
]
})
export class WidgetLoadingSpinnerModule { }
