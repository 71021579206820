import {
    createReducer,
    on
  } from '@ngrx/store';
import { AccreditationBody, CourseCategory, CourseExchangePolicyTemplate, CourseExchangeRole, ProviderPolicyTemplate, Country, Region, InstitutionRole, Actions, CourseSubCategory, EnrollmentStatus } from '@refData';
import { BaseListItem } from 'app/core/models';
import { PermissionDisplay } from '@auth/models/permissionDisplay';
import { InstitutionSummary } from '@shared/institution/models';
import { CourseExchangeListItem } from '@shared/courseExchange/models';


  
  export interface RefDataState {
    accreditationBodies: AccreditationBody[],
    areAccreditationBodiesLoaded: boolean,
    countries: Country[],
    areCountriesLoaded: boolean,
    courseCategories: CourseCategory[],
    areCourseCategoriesLoaded: boolean,
    courseExchanges: CourseExchangeListItem[],
    areCourseExchangesLoaded: boolean,
    institutions: InstitutionSummary[],
    areInstitutionsLoaded: boolean,
    courseLevels: BaseListItem[],
    areCourseLevelsLoaded: boolean,
    courseSubCategories: CourseSubCategory[],
    areCourseSubCategoriesLoaded: boolean,
    courseTags: BaseListItem[],
    areCourseTagsLoaded: boolean,
    studentTypes: BaseListItem[],
    areStudentTypesLoaded: boolean,
    courseExchangePolicyTemplates: CourseExchangePolicyTemplate[],
    areCourseExchangePolicyTemplatesLoaded:boolean,
    courseExchangeRoles: CourseExchangeRole[],
    areCourseExchangeRolesLoaded: boolean,
    genders: BaseListItem[],
    areGendersLoaded: boolean,
    institutionRoles: InstitutionRole[],
    areInstitutionRolesLoaded: boolean,
    providerPolicyTemplates: ProviderPolicyTemplate[],
    areProviderPoliciesTemplatesLoaded: boolean,
    regions: Region[],
    areRegionsLoaded: boolean,
    permissionDisplays: PermissionDisplay[],
    arePermissionDisplaysLoaded: boolean,
    enrollmentStatuses: EnrollmentStatus[],
    areEnrollmentStatusesLoaded: boolean,
    letterGrades: BaseListItem[],
    areLetterGradesLoaded: boolean
  };
  
  export const initialRefDataState: RefDataState = {
    accreditationBodies: new Array<AccreditationBody>(),
    areAccreditationBodiesLoaded: false,
    countries: new Array<Country>(),
    areCountriesLoaded: false,
    courseCategories: new Array<CourseCategory>(),
    areCourseCategoriesLoaded: false,
    courseLevels: new Array<BaseListItem>(),
    areCourseLevelsLoaded: false,
    courseSubCategories: new Array<CourseSubCategory>(),
    areCourseSubCategoriesLoaded: false,
    courseTags: new Array<BaseListItem>(),
    areCourseTagsLoaded: false,
    studentTypes: new Array<BaseListItem>(),
    areStudentTypesLoaded: false,
    courseExchangePolicyTemplates: new Array<CourseExchangePolicyTemplate>(),
    areCourseExchangePolicyTemplatesLoaded: false,
    courseExchangeRoles: new Array<CourseExchangeRole>(),
    areCourseExchangeRolesLoaded: false,
    genders: new Array<BaseListItem>(),
    areGendersLoaded: false,
    institutionRoles: new Array<InstitutionRole>(),
    areInstitutionRolesLoaded: false,
    providerPolicyTemplates: new Array<ProviderPolicyTemplate>(),
    areProviderPoliciesTemplatesLoaded: false,
    regions: new Array<Region>(),
    areRegionsLoaded: false,
    permissionDisplays: new Array<PermissionDisplay>(),
    arePermissionDisplaysLoaded: false,
    enrollmentStatuses: new Array<EnrollmentStatus>(),
    areEnrollmentStatusesLoaded: false,
    letterGrades: new Array<BaseListItem>(),
    areLetterGradesLoaded: false,
    courseExchanges: new Array<CourseExchangeListItem>(),
    areCourseExchangesLoaded: false,
    institutions: new Array<InstitutionSummary>(),
    areInstitutionsLoaded: false
  };
  
  export const refDataReducer = createReducer(
    initialRefDataState,
    on(Actions.LoadAccreditationBodies, (state, action) =>{
      if(!action.forceReload) return state;

      return {
        ...state,
        areAccreditationBodiesLoaded: false,
        accreditationBodies: new Array<AccreditationBody>()
      };
    }),
    on(Actions.LoadAccreditationBodiesSuccess, (state, action) =>{
      return {
        ...state,
        accreditationBodies: action.accreditationBodies,
        areAccreditationBodiesLoaded: true
      };
    }),
    on(Actions.LoadCountries,(state, action) => {
      if(!action.forceReload) return state;

      return {
        ...state,
        areCountriesLoaded: false,
        countries: new Array<Country>()
      }
    }),
    on(Actions.LoadCountriesSuccess, (state, action) => {
        return {
            ...state,
            countries: action.countries,
            areCountriesLoaded: true
        }
    }),
    on(Actions.LoadCourseCategories, (state, action) => {
      if(!action.forceReload) return state;
      return {
        ...state,
        courseCategories: new Array<CourseCategory>(),
        areCourseCategoriesLoaded: false
      };
    }),
    on(Actions.loadCourseCategoriesSuccess, (state, action) =>{
      return {
        ...state,
        courseCategories: action.courseCategories,
        areCourseCategoriesLoaded: true
      }
    }),
    on(Actions.LoadCourseLevels, (state, action) => {
      if(!action.forceReload) return state;
      return {
        ...state,
        courseLevels: new Array<BaseListItem>(),
        areCourseLevelsLoaded: false
      };
    }),
    on(Actions.LoadCourseLevelsSuccess, (state, action) =>{
      return {
        ...state,
        courseLevels: action.courseLevels,
        areCourseLevelsLoaded: true
      }
    }),
    on(Actions.LoadCourseSubCategories, (state, action) => {
      if(!action.forceReload) return state;
      return {
        ...state,
        courseSubCategories: new Array<CourseSubCategory>(),
        areCourseSubCategoriesLoaded: false
      };
    }),
    on(Actions.LoadCourseSubCategoriesSuccess, (state, action) =>{
      return {
        ...state,
        courseSubCategories: action.courseSubCategories,
        areCourseSubCategoriesLoaded: true
      }
    }),
    on(Actions.LoadCourseTags, (state, action) => {
      if(!action.forceReload) return state;
      return {
        ...state,
        courseTags: new Array<BaseListItem>(),
        areCourseTagsLoaded: false
      };
    }),
    on(Actions.LoadCourseTagsSuccess, (state, action) =>{
      return {
        ...state,
        courseTags: action.courseTags,
        areCourseTagsLoaded: true
      }
    }),
    on(Actions.LoadStudentTypes, (state, action) => {
      if(!action.forceReload) return state;
      return {
        ...state,
        studentTypes: new Array<BaseListItem>(),
        areStudentTypesLoaded: false
      };
    }),
    on(Actions.AddStudentType, (state, {command}) => {
      const studentType = {id: command.id, description: command.description};
      const studentTypes = [...state.studentTypes, studentType];
      return {
        ...state,
        studentTypes
      };
    }),
    on(Actions.EditStudentType, (state, {command}) => {
      const idx = state.studentTypes.findIndex(x=>x.id==command.studentTypeId);
        if(idx===-1) return state;
        const studentType = {...state.studentTypes[idx], description: command.description};
        const studentTypes = [...state.studentTypes.slice(0,idx), studentType, ...state.studentTypes.slice(idx+1)];
      return {
        ...state,
        studentTypes
      };
    }),
    on(Actions.DeleteStudentType, (state, {id}) => {
      const studentTypes = state.studentTypes.filter(x=>x.id != id);
      return {
        ...state,
        studentTypes
      };
    }),
    on(Actions.LoadStudentTypesSuccess, (state, action) =>{
      return {
        ...state,
        studentTypes: action.studentTypes,
        areStudentTypesLoaded: true
      }
    }),
    on(Actions.LoadCourseExchangeRoles, (state, action) => {
      if(!action.forceReload) return state;
      return {
        ...state,
        courseExchangeRoles: new Array<CourseExchangeRole>(),
        areCourseExchangeRolesLoaded: false
      };
    }),
    on(Actions.LoadCourseExchangeRolesSuccess, (state, action) =>{
      return {
        ...state,
        courseExchangeRoles: action.courseExchangeRoles,
        areCourseExchangeRolesLoaded: true
      }
    }),
    on(Actions.LoadGenders, (state, action) => {
      if(!action.forceReload) return state;
      return {
        ...state,
        genders: new Array<BaseListItem>(),
        areGendersLoaded: false
      };
    }),
    on(Actions.LoadGendersSuccess, (state, action) =>{
      return {
        ...state,
        genders: action.genders,
        areGendersLoaded: true
      }
    }),
    on(Actions.LoadInstitutionRoles, (state, action) => {
      if(!action.forceReload) return state;
      return {
        ...state,
        institutionRoles: new Array<InstitutionRole>(),
        areInstitutionRolesLoaded: false
      };
    }),
    on(Actions.LoadInstitutionRolesSuccess, (state, action) =>{
      return {
        ...state,
        institutionRoles: action.institutionRoles,
        areInstitutionRolesLoaded: true
      }
    }),
    on(Actions.LoadRegions,(state, action) => {
      if(!action.forceReload) return state;

      return {
        ...state,
        areRegionsLoaded: false,
        regions: new Array<Region>()
      }
    }),
    on(Actions.LoadRegionsSuccess, (state, action) => {
        return {
            ...state,
            regions: action.regions,
            areRegionsLoaded: true
        }
    }),
    on(Actions.LoadProviderPolicyTempaltesSuccess, (state, action) =>{
      return {
        ...state,
        providerPolicyTemplates: action.providerPolicyTemplates,
        areProviderPoliciesTemplatesLoaded: true
      }
    }),
    on(Actions.LoadCourseExchangePolicyTemplatesSuccess, (state, action) =>{
      return {
        ...state,
        courseExchangePolicyTemplates: action.courseExchangePolicyTemplates,
        areCourseExchangePolicyTemplatesLoaded: true
      }
    }),
    on(Actions.LoadPermissionDisplaysSuccess, (state, action)=>{
      return {
        ...state,
        permissionDisplays: action.permissionDisplays,
        arePermissionDisplaysLoaded: true
      }
    }),
    on(Actions.LoadEnrollmentStatusesSuccess, (state, action)=>{
      return {
        ...state,
        enrollmentStatuses: action.enrollmentStatuses,
        areEnrollmentStatusesLoaded: true
      }
    }),
    on(Actions.LoadLetterGradesSuccess, (state, action)=>{
      return {
        ...state,
        letterGrades: action.letterGrades,
        areLetterGradesLoaded: true
      }
    }),
    on(Actions.LoadCourseExchangesSuccess, (state, action)=>{
      return {
        ...state,
        courseExchanges: action.courseExchanges,
        areCourseExchangesLoaded: true
      }
    }),
    on(Actions.CourseExchangeListItemUpdated, (state, {courseExchangeListItem})=>{
      const idx = state.courseExchanges.findIndex(x=>x.courseExchangeId===courseExchangeListItem.courseExchangeId);
      if(idx===-1) return state;
      const courseExchanges = [...state.courseExchanges.slice(0,idx),courseExchangeListItem,...state.courseExchanges.slice(idx+1)];
      return {
        ...state,
        courseExchanges
      }
    }),
    on(Actions.CourseExchangeListItemAdded, (state, {courseExchangeListItem})=>{
      const idx = state.courseExchanges.findIndex(x=>x.courseExchangeId==courseExchangeListItem.courseExchangeId);
      let courseExchanges = [];
      if(idx===-1){
        courseExchanges = [...state.courseExchanges,courseExchangeListItem];
      }else{
        courseExchanges = [...state.courseExchanges.slice(0,idx), courseExchangeListItem, ...state.courseExchanges.slice(idx+1)];
      }
      return {
        ...state,
        courseExchanges
      }
    }),
    on(Actions.CourseExchangeDeleted, (state, {courseExchangeId})=>{
      const courseExchanges = state.courseExchanges.filter(x=>x.courseExchangeId!==courseExchangeId);
      return{
        ...state,
        courseExchanges
      }
    }),
    on(Actions.LoadInstitutionsSuccess, (state, action)=>{
      return {
        ...state,
        institutions: action.institutions,
        areInstitutionsLoaded: true
      }
    })

);
  
  
  