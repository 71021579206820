<div class="page-layout carded fullwidth inner-scroll list-page wizard-scene">
  <wizard-entity-status-notifications [entity]="entity" [entityStatus]="entityStatus"></wizard-entity-status-notifications>

  <div class="wizard-scene-content">
    <div class="wizard-scene-top-bar">
      <div class="wizard-scene-steps-wrap">
        <wizard-steps [currentBaseStep]="baseStep$ | async"
                      [checklist]="checklist$ | async"
                      [stepperConfig]="stepperConfig"
                      (navigate)="onNavigate($event)"></wizard-steps>
      </div>

      <div class="wizard-scene-complete-setup">
        <button mat-raised-button
                (click)="completeSetup.emit()"
                [disabled]="isDisableCompleteSetup()"
                type="submit">

          <ng-container [ngSwitch]="entityStatus">
            <ng-container *ngSwitchCase="EntityStatus.Setup">
              Complete Set Up
            </ng-container>

            <ng-container *ngSwitchCase="EntityStatus.Pending">
              Submit Changes
            </ng-container>
          </ng-container>
        </button>
      </div>
    </div>


    <div class="wizard-progress-wrap">
      <div class="wizard-progress">
        <wizard-progress [progressPercent]="progressPercent$ | async">
        </wizard-progress>
      </div>
    </div>

    <div class="steps-content-wrap">
      <ng-container>
        <div [ngSwitch]="baseStep$ | async" class="steps-content">
          <ng-container *ngSwitchCase="rootStep">
            <wizard-home-step
              [checklist]="checklist$ | async"
              [userName]="userName$ | async"
              [institutionName]="institutionName$ | async"
              (navigate)="onNavigate($event)">
            </wizard-home-step>
          </ng-container>
          <ng-container *ngSwitchCase="contactsStep">
            <wizard-contacts-step
              [accountingUser]="accountingUser$ | async"
              [presidentUser]="presidentUser$ | async"
              [vicePresidentOrProvostUser]="vicePresidentOrProvostUser$ | async"
              [institutionId]="institutionId$ | async"
              [institutionRoles]="institutionRoles$ | async"
              [currentStepId]="currentStepId$ | async"
              (addUser)="onAddUser($event)"
              (editUser)="onEditUser($event)"
              (navigate)="onNavigate($event)">
            </wizard-contacts-step>
          </ng-container>
          <ng-container *ngSwitchCase="calendarStep">
            <wizard-calendar-step
              [academicYear]="firstAcademicYear$ | async"
              [semester]="firstSemester$ | async"
              [session]="firstSession$ | async"
              [showSessions]="showSessions"
              [sessionDateRules]="sessionDateRules$ | async"
              [currentStepId]="currentStepId$ | async"
              (addAcademicYear)="onAddAcademicYear($event)"
              (editAcademicYear)="onEditAcademicYear($event)"
              (addSemester)="onAddSemester($event)"
              (editSemester)="onEditSemester($event)"
              (addSession)="onAddSession($event)"
              (editSession)="onEditSession($event)"
              (navigate)="onNavigate($event)">
            </wizard-calendar-step>
          </ng-container>
          <ng-container *ngSwitchCase="profileStep">
            <ng-content select="[profile]"></ng-content>
          </ng-container>
          <ng-container *ngSwitchCase="courseInformationStep">
            <ng-content select="[courseInformation]"></ng-content>
          </ng-container>
          <ng-container *ngSwitchCase="feesStep">
            <ng-content select="[fees]"></ng-content>
          </ng-container>
          <ng-container *ngSwitchCase="bankInformationStep">
            <ng-content select="[bankInfo]"></ng-content>
          </ng-container>
          <ng-container *ngSwitchCase="policiesStep">
            <ng-content select="[policies]"></ng-content>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</div>
