import { createReducer, on } from '@ngrx/store';
import * as fromMemberDashboard from '@member/dashboard/store';
import * as fromAuth from '@auth/store';
import * as fromInstitution from "@institution/store";

export interface MemberDashboardState {
    memberId: string;
    startDate: Date;
    endDate: Date;
    availableCourses: number;
    totalEnrollments: number;
    updatedEnrollments: number;    
    totalStudents: number;
    isLoadDashboardData: boolean;
}

export const initialDashboardState: MemberDashboardState = {
    memberId: '',
    startDate: undefined,
    endDate: undefined,
    availableCourses: 0,
    totalEnrollments: 0,
    updatedEnrollments: 0,
    totalStudents:0,
    isLoadDashboardData:false

};

export const memberDashboardReducer = createReducer(
    initialDashboardState,
    on(fromAuth.Actions.selectCourseExchangeId, (state,action)=>{
        return initialDashboardState
    }),
    on(fromMemberDashboard.Actions.LoadDashboardData, (state, action) => {
        const {memberId,startDate,endDate} = action;
        return {
            ...state,
            memberId,
            startDate,
            endDate,
            isLoadDashboardData: true
        }
    }),
    on(fromMemberDashboard.Actions.LoadDashboardDataSuccess, (state, action) => {
        const {availableCourses,totalEnrollments,updatedEnrollments,totalStudents} = action.data;
        return {
            ...state,
            availableCourses,
            totalEnrollments,
            updatedEnrollments,
            totalStudents,
            isLoadDashboardData: false
        }
    }),
    on(fromMemberDashboard.Actions.LoadDashboardDataError, (state, { message }) => {
        return {
            ...state,
            isLoadDashboardData: false
        }
    })
)
