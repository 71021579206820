import {Component, ViewEncapsulation, Input, Output, EventEmitter} from '@angular/core';
import {MemberCourseListItem} from '@member/memberCourse/models';
import { Store } from '@ngrx/store';
import { IAppState } from 'app/store';
import * as fromMemberCourse from '@member/memberCourse/store';
import { MatDialog } from '@angular/material/dialog';
import { DeSelectCourseDialogConfirmComponent } from '@member/memberCourse/components';
@Component({
  selector: 'member-course-item',
  templateUrl: './member-course-item.component.html',
  styleUrls: ['./member-course-item.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MemberCourseItemComponent {
  @Input() course: MemberCourseListItem;
  @Input() canEnrollStudent: boolean;
  @Output() toggleMemberCourse: EventEmitter<string> = new EventEmitter<string>();
  @Output() goToCourseDetail: EventEmitter<MemberCourseListItem> = new EventEmitter<MemberCourseListItem>();
  @Output() exportCourseToExcel: EventEmitter<MemberCourseListItem> = new EventEmitter<MemberCourseListItem>();
  @Output() enroll: EventEmitter<string> = new EventEmitter<string>();
  constructor(private store: Store<IAppState>,private dialog:MatDialog){
  }
  getCourseDescription(course: MemberCourseListItem): string{
    return `${course.code}, ${course.title}`;
  }

  getInstitutionalCourseCodes(course: MemberCourseListItem): string{
    return course.institutionalCourseCode=="" || course.institutionalCourseCode == null ? "" : 
      `(${course.institutionalCourseCode})`;
  }

  preSelectCourse(memberCourse: MemberCourseListItem): void {
    this.store.dispatch(fromMemberCourse.Actions.PreSelectSingleCourse({
      providerCourseId: memberCourse.providerCourseId
    }));
  }

  confirmDeselectCourse(memberCourse: MemberCourseListItem): void {
    const confirmDialogRef = this.dialog.open(DeSelectCourseDialogConfirmComponent);

    confirmDialogRef.afterClosed().subscribe(isConfirmed => {
      if (isConfirmed) {
        this.deSelectCourses(memberCourse);
      }
    });
  }

  deSelectCourses(memberCourse: MemberCourseListItem): void {
    const memberCourseIds = [memberCourse.memberCourseId];
    this.store.dispatch(fromMemberCourse.Actions.DeselectCourses({memberCourseIds}));
  }

  manageMemberCourseCodes(memberCourse: MemberCourseListItem): void{
    const providerCourseId = memberCourse.providerCourseId;
    const memberCourseId = memberCourse.memberCourseId;
    this.store.dispatch(fromMemberCourse.Actions.ManageMemberCourseCodes({providerCourseId, memberCourseId}));
  }
}
