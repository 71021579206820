import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'elb-page-list-container',
  templateUrl: './page-list-container.component.html',
  styleUrls: ['./page-list-container.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PageListContainerComponent implements OnInit {

  constructor() {
  }

  @Input() kind: string;

  ngOnInit(): void {
  }
}
