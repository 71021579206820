import {Moment} from 'moment';
import * as moment from 'moment';

export const validateStartEndDates = (startDate: Moment | Date | null, endDate: Moment | Date | null) => {

  if (startDate === null || endDate === null) {
    return true;
  }

  return (moment(startDate) < moment(endDate));
};
