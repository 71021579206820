<mat-toolbar>
  <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <span class="title dialog-title">New enrollment</span>
    <button mat-icon-button (click)="close()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div class="content">
  <div [ngSwitch]="matDialogData.typeFrom">
    <new-enrollment-from-students *ngSwitchCase="'fromStudents'" (close)="close()"></new-enrollment-from-students>
    <new-enrollment-from-courses *ngSwitchCase="'fromCourses'" (close)="close()"></new-enrollment-from-courses>
  </div>
</div>
