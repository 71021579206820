import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { IAppState } from 'app/store/state/app.state';
import { Store } from '@ngrx/store';
import * as fromMemberShared from '@shared/member/store';
export interface EnrollmentMigrationDialogData {
  memberId: string;
  institutionId: string;
}

@Component({
  selector: 'enrollment-migration-data-dialog',
  templateUrl: './enrollment-migration-dialog.component.html',
  styleUrls: ['./enrollment-migration-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EnrollmentMigrationDialogComponent {
  constructor(
    public matDialogRef: MatDialogRef<EnrollmentMigrationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public matDialogData: EnrollmentMigrationDialogData,
    public store: Store<IAppState>
  ) {
    this.matDialogRef.addPanelClass('enrollment-migration-data-dialog');
  }

  close(): void {
    this.matDialogRef.close();
  }

  fileInputChange(event: Event): void{
    var file = (event.target as HTMLInputElement).files[0];
    const fileReader = new FileReader();
    fileReader.readAsText(file);
    fileReader.onload = (e) => {
      var result = e.target.result as string;
      const enrollmentMigrationData = JSON.parse(result);
      const {memberId, institutionId} = this.matDialogData;
      this.store.dispatch(fromMemberShared.Actions.EnrollmentMigrationData({memberId, institutionId, enrollmentMigrationData}));
      this.close();
    }
  }
}
