import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { first, finalize, filter, map } from "rxjs/operators";
import { IAppState } from 'app/store/state/app.state';
import * as fromProviderBilling from '@provider/billing/store';
import { ProviderMonthlyPayoutStatement } from '@provider/billing/models';

@Injectable()
export class ProviderMonthlyStatementsResolver implements Resolve<ProviderMonthlyPayoutStatement>{
    isLoading = false;
    constructor(private store: Store<IAppState>){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
        const providerId = route.params['providerId'];
        return this.store 
            .pipe(
                map(() => {
                    if(!this.isLoading){
                        this.isLoading = true;
                        this.store.dispatch(fromProviderBilling.Actions.LoadMonthlyStatements({providerId}));
                    }
                    return true;
                }),
                filter(statementLoaded => statementLoaded),
                first(),
                finalize(()=>this.isLoading=false)
            )
    }
}