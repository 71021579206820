import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { Store, select } from "@ngrx/store";
import { tap, first, finalize, filter, map, concatMap, withLatestFrom, switchMap } from "rxjs/operators";
import { IAppState } from 'app/store/state/app.state';
import { CourseExchange, CourseExchangePolicy } from 'app/shared/courseExchange/models';
import * as fromProvider from '@provider/store';
import { CourseExchangePolicyTemplate } from '@core/referenceData';
import { ProviderPolicy } from '@shared/institution/models';
import { getInstitutionIdFromRoute } from "app/helpers/routeParamHelper";

@Injectable()
export class ProviderPoliciesResolver implements Resolve<ProviderPolicy[]>{
    isLoading = false;
    constructor(private store: Store<IAppState>){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
        const institutionId = getInstitutionIdFromRoute(route);
        return this.store 
            .pipe(
                map(()=>{
                    if(!this.isLoading){
                        this.isLoading = true;
                        this.store.dispatch(fromProvider.Actions.LoadPolicies({institutionId}));
                    }
                    return true;
                }),
                filter(policyTemplatesLoaded => policyTemplatesLoaded),
                first(),
                finalize(()=>this.isLoading=false)
            )
    }
}