import { NgModule } from '@angular/core';
import {LimitedCounterPipe} from './limited-counter.pipe';

@NgModule({
  declarations: [
    LimitedCounterPipe
  ],
  imports: [],
  exports: [
    LimitedCounterPipe
  ]
})
export class PipesModule {}
