import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as fromMemberCourse from '@member/memberCourse/store';
import * as fromMember from '@member/store';
import * as fromCore from '@core/store';
import * as fromRefData from '@refData';
import * as fromInstitution from '@institution/store';
import * as fromCourseExchange from '@courseExchange/store';
import * as fromAuth from '@auth/store';
import * as moment from 'moment';
import {switchMap, map, concatMap, withLatestFrom, tap, catchError} from 'rxjs/operators';
import {MemberService} from '@shared/member/services';
import {Observable, of} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {IAppState} from 'app/store/state/app.state';
import {
  PreSelectCoursesCommand,
  AddInstitutionalCourseCodeCommand,
  RemoveInstitutionalCourseCodeCommand,
  DeSelectCoursesCommand,
  MemberCourse
} from '@shared/member/models';
import {AddedToPreSelectCourseDialogComponent} from '../../components';
import {MatDialog} from '@angular/material/dialog';
import {FilterType} from '@shared/common/models';
import { ExcelColumn, ExcelService } from '@core/services/excel.service';
import { MemberCourseListItem } from '@member/memberCourse/models';
import { RealTimeService } from '@core/services/realTime.service';
import { AcademicYear } from '@shared/calendar/models';

@Injectable()
export class MemberCourseRealtime {
  currentAcademicYear$: Observable<AcademicYear>;
  constructor(
    private actions$: Actions,
    private store: Store<IAppState>,
    private memberService: MemberService,
    private dialog: MatDialog,
    private excelService: ExcelService,
    private realTimeService: RealTimeService
  ) {
    this.initSignalRMessages();
    this.currentAcademicYear$ = this.store.pipe(select(fromInstitution.Selectors.CurrentAcademicYear));
  }

  private initSignalRMessages(){
    this.realTimeService.hubConnection$.subscribe(hubConnection=>{
      if(!hubConnection) return;
      hubConnection.on('MemberCourseListItemEdited', (memberCourseListItem:MemberCourseListItem) => {
        this.store.dispatch(fromMemberCourse.Actions.MemberCourseListItemEdited({memberCourseListItem}));
      });
      hubConnection.on('MemberCourseListItemAdded', (memberCourseListItem: MemberCourseListItem)=>{
        of(memberCourseListItem).pipe(
          withLatestFrom(this.currentAcademicYear$),
          map(([memberCourseListItem, academicYear])=>{
            if(moment(memberCourseListItem.startDate).startOf('day')
              .isBetween(moment(academicYear.startDate).startOf('day'), 
                         moment(academicYear.endDate).endOf('day'))){
              this.store.dispatch(fromMemberCourse.Actions.MemberCourseListItemAdded({memberCourseListItem}));
            }
          })
        ).subscribe();
      });
      hubConnection.on('MemberCourseListItemsDeleted', (memberCourseListItemIds: Array<string>)=>{
        this.store.dispatch(fromMemberCourse.Actions.MemberCourseListItemsDeleted({memberCourseListItemIds}));
      });
      hubConnection.on('MemberCourseAdded', (memberCourse: MemberCourse)=>{
        this.store.dispatch(fromMemberCourse.Actions.MemberCourseAdded({memberCourse}));
      });
      hubConnection.on('MemberCourseUpdated', (memberCourse: MemberCourse)=>{
        this.store.dispatch(fromMemberCourse.Actions.MemberCourseUpdated({memberCourse}));
      });
      hubConnection.on('MemberCourseDeleted', (memberCourse: MemberCourse)=>{
        this.store.dispatch(fromMemberCourse.Actions.MemberCourseDeleted({memberCourse}));
      });
    })
  }

}
