import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { switchMap, map, tap } from 'rxjs/operators';
import * as fromStore from '@shared/provider/store';
import * as fromCore from '@core/store';
import { MatDialog } from '@angular/material/dialog';
import * as FileSaver from 'file-saver';
import { ProviderService } from '@shared/provider/services';
import { CourseMergeDataDialogComponent, CourseMergeDataDialogData } from '@shared/provider/components/dialogs/course-merge-data-dialog/course-merge-data-dialog.component';
import { CourseService } from '@shared/provider/services/course.service';
import { EnrollmentMigrationDialogComponent, EnrollmentMigrationDialogData } from '@shared/provider/components/dialogs/enrollment-migration-dialog/enrollment-migration-dialog.component';
import { LetterGradeMigrationDialogComponent, LetterGradeMigrationDialogData } from '@shared/provider/components/dialogs/letter-grade-migration-dialog/letter-grade-migration-dialog.component';
import { DataMigrationDialogComponent, DataMigrationDialogData } from '@shared/provider/components/dialogs/data-migration-dialog/data-migration-dialog.component';
@Injectable()
export class ProviderSharedEffects {
    constructor(private actions$: Actions, private dialog: MatDialog, private providerService: ProviderService,
        private courseService: CourseService) {
    }

    showMigrateData$ = createEffect(() => {
        return this.actions$.pipe(
          ofType(fromStore.Actions.ShowMigrateData),
          tap((action) => this.dialog.open<DataMigrationDialogComponent, DataMigrationDialogData>(DataMigrationDialogComponent, {
            data: {
              providerId: action.providerId,
              institutionId: action.institutionId
            }
          })));
      }, { dispatch: false });

    selectEnrollmentMigrationData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromStore.Actions.SelectEnrollmentMigrationDataFile),
            tap((action) => this.dialog.open<EnrollmentMigrationDialogComponent, EnrollmentMigrationDialogData>(EnrollmentMigrationDialogComponent, {
                data: {
                    providerId: action.providerId,
                    institutionId: action.institutionId
                }
            })));
    }, { dispatch: false });

    enrollmentMigrationData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromStore.Actions.EnrollmentMigrationData),
            switchMap(({ providerId,institutionId, enrollmentMigrationData }) => {
                return this.providerService.enrollmentMigrationData(providerId,institutionId, enrollmentMigrationData);
            }),
            map(({message}) => {
                return fromCore.Actions.ShowMessage({ message })
            })
        )
    })

    getEnrollmentMigrationData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromStore.Actions.GetEnrollmentMigrationData),
            switchMap(({ providerId, institutionId }) => {
                return this.providerService.getEnrollmentMigrationData(providerId,institutionId);
            }),
            map((data: any) => {
                var json = JSON.stringify(data);
                var blob = new Blob([json], { type: "application/json" });
                FileSaver.saveAs(blob, `Enrollment Data - ${Date.now().toString()}.json`)
            })
        )
    }, { dispatch: false });

    getGradeData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromStore.Actions.GetLetterGradeMigrationData),
            switchMap(({ providerId, institutionId }) => {
                return this.providerService.getGradeData(providerId, institutionId);
            }),
            map((data: any) => {
                var json = JSON.stringify(data);
                var blob = new Blob([json], { type: "application/json" });
                FileSaver.saveAs(blob, `Grade Data - ${Date.now().toString()}.json`)
            })
        )
    }, { dispatch: false });

    selectLetterGradeMigrationData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromStore.Actions.SelectLetterGradeMigrationDataFile),
            tap((action) => this.dialog.open<LetterGradeMigrationDialogComponent, LetterGradeMigrationDialogData>(LetterGradeMigrationDialogComponent, {
                data: {
                    providerId: action.providerId,
                    institutionId: action.institutionId
                }
            })));
    }, { dispatch: false });

    letterGradeMigrationData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromStore.Actions.LetterGradeMigrationData),
            switchMap(({ providerId,institutionId, enrollmentMigrationData }) => {
                return this.providerService.letterGradeMigrationData(providerId,institutionId, enrollmentMigrationData);
            }),
            map(({message}) => {
                return fromCore.Actions.ShowMessage({ message })
            })
        )
    })


    selectCourseMergeData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromStore.Actions.SelectCourseMergeDataFile),
            tap((action) => this.dialog.open<CourseMergeDataDialogComponent, CourseMergeDataDialogData>(CourseMergeDataDialogComponent, {
                data: {
                    providerId: action.providerId,
                    institutionId: action.institutionId
                }
            })));
    }, { dispatch: false });

    courseMergeData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromStore.Actions.CourseMergeData),
            switchMap(({ providerId, courseMergeData, institutionId }) => {
                return this.courseService.mergeCourseData(providerId,institutionId, courseMergeData);
            }),
            map(({message}) => 
                fromCore.Actions.ShowMessage({message})
            )
        )
    })

    getCourseMergeData$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromStore.Actions.GetCourseMergeData),
            switchMap(({ providerId, institutionId }) => {
                return this.courseService.getCourseData(providerId, institutionId);
            }),
            map((data: any) => {
                var json = JSON.stringify(data);
                var blob = new Blob([json], { type: "application/json" });
                FileSaver.saveAs(blob, `Course Data - ${Date.now().toString()}`)
            })
        )
    }, { dispatch: false })
   
}