import {ICalendarState as ICalendarState} from '../../state/calendar.state';
import { Semester, AcademicYear } from 'app/shared/calendar/models';

interface IAddNewSemester {
  state: ICalendarState;
  newSemester: Semester;
}

export const addNewSemester = (data: IAddNewSemester): ICalendarState => {
  const {state, newSemester} = data;

  const {academicYearId} = newSemester;

  const newCalendar = state.calendar.map((academicYear: AcademicYear) => {
    if (academicYear.id === academicYearId) {
      return {
        ...academicYear,
        semesters: [
          ...academicYear.semesters.slice(),
          newSemester
        ]
      };
    }

    return academicYear;
  });

  return {
    ...state,
    calendar: newCalendar
  };
}
