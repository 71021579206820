import { createEffect, Actions, ofType } from "@ngrx/effects";
import { Injectable } from "@angular/core";
import { map, switchMap, withLatestFrom, concatMap, tap } from "rxjs/operators";
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { IAppState } from 'app/store/state/app.state';
import { PopupService } from 'app/core/services/popup.service';
import * as fromCore from '@core/store';
import * as fromFacultyCV from '@provider/facultyCV/store';
import * as fromProvider from '@provider/store';
import { MatDialog } from '@angular/material/dialog';
import { InstitutionService } from '@shared/institution/services/institution.service';
import { FacultyCV } from "@shared/institution/models";
import { ElbertFile, FileType } from "@core/models";
import { FileStatus } from "@core/models/fileStatus.enum";
import * as moment from "moment";

@Injectable()
export class FacultyCVEffects{
    constructor(private actions$: Actions,private institutionService: InstitutionService, private store: Store<IAppState>, private dialog: MatDialog, private popupService: PopupService){
    }

    loadFacultyCVs$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromFacultyCV.Actions.LoadFacultyCVs),
                switchMap((action)=> 
                    this.institutionService.getInstitutionFacultyCVs(action.institutionId)),
                map((facultyCVs) => 
                    fromFacultyCV.Actions.LoadFacultyCVsSuccess({facultyCVs}))    
            );
    });

    addFacultyCV$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromFacultyCV.Actions.AddFacultyCV),
                concatMap(action => of(action).pipe(
                    withLatestFrom(this.store.pipe(select(fromProvider.Selectors.InstitutionId)))
                )),
                switchMap(([action, institutionId]) => {
                    const command = {...action.command, institutionId};
                    return this.institutionService.addFacultyCV(institutionId,command);
                }),
                map((response) =>fromCore.Actions.HandleResponse({response}))
        )
    });

    addFacultyCVToStore$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromFacultyCV.Actions.AddFacultyCV),
                concatMap(action => of(action).pipe(
                    withLatestFrom(this.store.pipe(select(fromProvider.Selectors.InstitutionId)))
                )),
                map(([{command}, institutionId]) => {
                    const file: ElbertFile = {fileType: FileType.FacultyCV, status: FileStatus.Uploaded, fileName: command.file.name};
                    const {id, description} = command;
                    const lastUpdatedDt = moment().toDate();
                    const facultyCV: FacultyCV = {id, institutionId, description, file, lastUpdatedDt, isActive:true};
                    return fromFacultyCV.Actions.FacultyCVAdded({facultyCV});
                })
        )
    });

    updateFacultyCV$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromFacultyCV.Actions.UpdateFacultyCV),
                concatMap(action => of(action).pipe(
                    withLatestFrom(this.store.pipe(select(fromProvider.Selectors.InstitutionId)))
                )),
                switchMap(([action, institutionId]) => 
                    this.institutionService.updateFacultyCV(institutionId,action.command)),
                map((response) =>fromCore.Actions.HandleResponse({response}))
        )
    });

    activateFacultyCV$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromFacultyCV.Actions.ActivateFacultyCV),
                concatMap(action => of(action).pipe(
                    withLatestFrom(this.store.pipe(select(fromProvider.Selectors.InstitutionId)))
                )),
                switchMap(([action, institutionId]) => 
                    this.institutionService.activateFacultyCV(institutionId,action.command)),
                map((response) =>fromCore.Actions.HandleResponse({response}))
        )
    });

    deactivateFacultyCV$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromFacultyCV.Actions.DeactivateFacultyCV),
                concatMap(action => of(action).pipe(
                    withLatestFrom(this.store.pipe(select(fromProvider.Selectors.InstitutionId)))
                )),
                switchMap(([action, institutionId]) => 
                    this.institutionService.deactivateFacultyCV(institutionId,action.command)),
                map((response) =>fromCore.Actions.HandleResponse({response}))
        )
    });

    downloadFacultyCV$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromFacultyCV.Actions.DownloadFacultyCV),
                tap((action) => this.institutionService.downloadFacultyCV(action.facultyCV.institutionId, action.facultyCV))
        );
    }, { dispatch: false });

}