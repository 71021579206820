import {EnrollmentListItem, EnrollmentListItemForView} from '@member/enrollment/models/enrollmentListItem.viewModel';
import {Sort, Order} from '@core/models';
import {Filter, FilterType, SemesterFilter} from '@shared/common/models';
import {on, createReducer} from '@ngrx/store';
import toggleItem from '@shared/common/helpers/toggleItem';
import * as fromAuth from '@auth/store';
import * as fromEnrollment from '@provider/enrollment/store';
import * as fromRefData from '@refData';
import * as fromInstitution from '@institution/store';
import {EnrollmentListMode} from '../enrollmentListMode.enum';
import { Student } from '@shared/student/models';
import { StudentDetailsViewModel } from '@shared/student/models/studentDetails.viewModel';
import convertEnrollmentDates from '@shared/common/helpers/convertEnrollmentDates';
import getCurrentAcademicYear from '@shared/common/helpers/getCurrentAcademicYear';
import getSemesterFilters from '@shared/common/helpers/getSemesterFilters';
import toggleSessionFilter from '@shared/common/helpers/toggleSessionFilter';
import toggleSemesterFilter from '@shared/common/helpers/toggleSemesterFilter';
import toggleSemesterFilterExpanded from '@shared/common/helpers/toggleSemesterFilterExpanded';
import convertEnrollmentsDates from '@shared/common/helpers/convertEnrollmentsDates';

export interface EnrollmentsState {
  providerId: string;
  enrollments: EnrollmentListItemForView[];
  isEnrollmentsLoading: boolean;
  sorts: Sort[];
  currentSort: Sort;
  orders: Order[];
  currentOrder: Order;
  statusFilters: Filter[];
  courseLevelFilters: Filter[];
  providerFilters: Filter[];
  searchText: string;
  isSubmitting: boolean;
  enrollmentListMode: EnrollmentListMode;
  currentStudent: Student;
  isCurrentStudentLoading: boolean;
  currentStudentDetails: StudentDetailsViewModel;
  isCurrentStudentDetailsLoading: boolean;
  semesterFilters: SemesterFilter[],
  currentAcademicYearId: string,
  memberFilters: Filter[];
  tagFilters: Filter[];
  courseCategoryFilters: Filter[];
  courseSubCategoryFilters: Filter[];
};

export const initialEnrollmentsState: EnrollmentsState = {
  providerId: undefined,
  enrollments: new Array<EnrollmentListItemForView>(),
  isEnrollmentsLoading: false,
  sorts: new Array<Sort>(),
  currentSort: new Sort(),
  orders: new Array<Order>(),
  currentOrder: new Order(),
  statusFilters: new Array<Filter>(),
  courseLevelFilters: new Array<Filter>(),
  providerFilters: new Array<Filter>(),
  searchText: '',
  isSubmitting: false,
  enrollmentListMode: EnrollmentListMode.Enrollment,
  currentStudent: undefined,
  isCurrentStudentLoading: false,
  currentStudentDetails: undefined,
  isCurrentStudentDetailsLoading: false,
  semesterFilters : new Array<SemesterFilter>(),
  currentAcademicYearId: "empty",
  memberFilters: [],
  tagFilters: [],
  courseCategoryFilters: [],
  courseSubCategoryFilters: [],
};

export const enrollmentsReducer = createReducer(
  initialEnrollmentsState,
  on(fromAuth.Actions.selectCourseExchangeId, (state,action)=>{
    return initialEnrollmentsState
  }),
  on(fromEnrollment.Actions.LoadEnrollmentsByAcademicYear, (state, action)=>{
    const currentAcademicYearId = action.academicYearId;
    return {
      ...state,
      currentAcademicYearId,
      isEnrollmentsLoading: true
    }
  }),
  on(fromEnrollment.Actions.LoadActiveEnrollmentsSuccess, (state, action) => {
    const enrollments = convertEnrollmentsDates(action.enrollments);
    return {
      ...state,
      enrollments,
      isEnrollmentsLoading: false
    }
  }),
  on(fromEnrollment.Actions.LoadActiveEnrollmentsError, (state) => {
    return {
      ...state,
      isEnrollmentsLoading: false
    }
  }),
  on(fromEnrollment.Actions.EnrollmentListItemUpdated, (state, action) => {
    const item = convertEnrollmentDates(action.enrollment);
    var idx = state.enrollments.findIndex(x => x.id === item.id);
    const enrollmentForView = {...item, selected: false, requestedStatus: null, requestedLetterGrade: null, letterGradeDescription: !!item.letterGrade ? item.letterGrade.description : "zz"};
    const enrollments = [...state. enrollments.slice(0, idx), enrollmentForView, ...state.enrollments.slice(idx + 1)];

    return {
      ...state,
      enrollments
    };
  }),
  on(fromEnrollment.Actions.MasterToggled, (state, action) => {
    const enrollments = state.enrollments.map((enrollment)=>{
      const idx = action.enrollments.findIndex(x=>x.id===enrollment.id);
      if(idx===-1){
        return enrollment;
      }else{
        return action.enrollments[idx];
      }
    })

    return {
      ...state,
      enrollments
    };
  }),
  on(fromEnrollment.Actions.ChangeStatuses, (state, action)=>{
    const commands = action.command.commands;
    const enrollmentStatuses = action.enrollmentStatuses;
    const enrollments = state.enrollments.map((enrollment=>{
      const command = commands.find(x=>x.enrollmentId===enrollment.enrollmentId);
      if(!command) return enrollment;
      var status = enrollmentStatuses.find(x=>x.id===command.requestedStatus.id);
      return {
        ...enrollment,
        status,
        requestedStatus: null
      }
    }));

    return {
      ...state,
      enrollments
    }
  }),
  on(fromEnrollment.Actions.ChangeLetterGrades, (state, action)=>{
    const commands = action.command.commands;
    const enrollmentStatuses = action.enrollmentStatuses;
    const enrollments = state.enrollments.map((enrollment=>{
      const command = commands.find(x=>x.enrollmentId===enrollment.enrollmentId);
      if(!command) return enrollment;
      var graded = enrollmentStatuses.find(x=>x.description==="Graded");
      var incomplete = enrollmentStatuses.find(x=>x.description==="Incomplete");
      var letterGrade = command.requestedLetterGrade;
      var status = letterGrade.description==="Incomplete"?incomplete : graded;
      return {
        ...enrollment,
        status,
        letterGrade,
        requestedStatus: null,
        requestedLetterGrade: null
      }
    }));

    return {
      ...state,
      enrollments
    }
  }),
  on(fromEnrollment.Actions.EnrollmentListItemAdded, (state, action) => {
    const item = convertEnrollmentDates(action.enrollment);
    if(state.providerId != item.providerId) return state;
    const idx = state.enrollments.findIndex(x=>x.id===item.id);
    let enrollments = new Array<EnrollmentListItemForView>();
    const enrollmentForView:EnrollmentListItemForView = 
    {...item, selected: false, requestedStatus: null, requestedLetterGrade: null, letterGradeDescription: !!item.letterGrade ? item.letterGrade.description : "zz"};
    if(idx===-1){
      enrollments = [...state.enrollments, enrollmentForView];
    }else{
      enrollments = [...state. enrollments.slice(0, idx), enrollmentForView, ...state.enrollments.slice(idx + 1)];
    }

    return {
      ...state,
      enrollments
    };
  }),
  on(fromEnrollment.Actions.SubmitStatusChangesSuccess, (state, action)=>{
    return{
      ...state,
      isSubmitting: false
    }
  }),
  on(fromEnrollment.Actions.SubmitStatusChangesError, (state, action) => {
    return {
      ...state,
      isSubmitting: false
    }
  }),
  on(fromEnrollment.Actions.SubmitGradingSuccess, (state, action) => {
    return{
      ...state,
      isSubmitting: false
    }
  }),
  on(fromEnrollment.Actions.SubmitGradingError, (state, action) => {
    return {
      ...state,
      isSubmittingGrading: false
    }
  }),
  on(fromEnrollment.Actions.EditStatus, (state, {enrollmentId, requestedStatus}) => {
    const idx = state.enrollments.findIndex(x => x.id === enrollmentId);
    const enrollment = {...state.enrollments[idx], requestedStatus};
    const enrollments = [...state.enrollments.slice(0, idx), enrollment, ...state.enrollments.slice(idx + 1)];
    return {
      ...state,
      enrollments
    };
  }),
  on(fromEnrollment.Actions.EditLetterGrade, (state, {enrollmentId, requestedLetterGrade}) => {
    const idx = state.enrollments.findIndex(x => x.id === enrollmentId);
    const enrollment = {...state.enrollments[idx], requestedLetterGrade};
    const enrollments = [...state.enrollments.slice(0, idx), enrollment, ...state.enrollments.slice(idx + 1)];
    return {
      ...state,
      enrollments
    };
  }),
  on(fromEnrollment.Actions.LoadSorts, (state, {sorts}) => {
    const currentSort = sorts.find(x => x.isDefault);
    return {
      ...state,
      sorts,
      currentSort
    }
  }),
  on(fromEnrollment.Actions.ChangeSort, (state, {currentSort}) => {
    return {
      ...state,
      currentSort
    }
  }),
  on(fromEnrollment.Actions.LoadOrders, (state, {orders}) => {
    const currentOrder = orders.find(x => x.isDefault);
    return {
      ...state,
      orders,
      currentOrder
    }
  }),
  on(fromEnrollment.Actions.ChangeOrder, (state, {currentOrder}) => {
    return {
      ...state,
      currentOrder
    }
  }),
  on(fromEnrollment.Actions.ChangeSearchText, (state, {searchText}) => {
    return {
      ...state,
      searchText
    }
  }),
  on(fromEnrollment.Actions.ChangeEnrollmentListMode, (state, {enrollmentListMode}) => {
    return {
      ...state,
      enrollmentListMode
    }
  }),
  on(fromEnrollment.Actions.ToggleEnrollment, (state, {enrollmentId}) => {
    const enrollments = toggleItem(state.enrollments, enrollmentId);
    return {
      ...state,
      enrollments
    }
  }),
  on(fromEnrollment.Actions.ToggleSemester, (state, {semesterId}) => {
    const semesterFilters = toggleSemesterFilter(state.semesterFilters, semesterId);

    return {
      ...state,
      semesterFilters
    }
  }),
  on(fromEnrollment.Actions.ToggleSemesterExpanded, (state, {semesterFilterId}) => {
    const semesterFilters = toggleSemesterFilterExpanded(state.semesterFilters, semesterFilterId);

    return {
      ...state,
      semesterFilters
    }
  }),
  on(fromEnrollment.Actions.ToggleSession, (state, {sessionId})=>{
    
    const semesterFilters = toggleSessionFilter(state.semesterFilters, sessionId);
    
    return {
      ...state,
      semesterFilters
    }
  }),
  on(fromEnrollment.Actions.ToggleStatusFilter, (state, {id}) => {
    const statusFilters = toggleItem(state.statusFilters, id);
    return {
      ...state,
      statusFilters
    }
  }),
  on(fromRefData.Actions.LoadEnrollmentStatusesSuccess, (state, action) => {
    const statusFilters = action.enrollmentStatuses.map(x => {
      return {
        id: x.id,
        description: x.description,
        descriptionForView: x.description,
        type: FilterType.EnrollmentStatus,
        selected: false
      }
    });

    return {
      ...state,
      statusFilters
    }
  }),
  on(fromEnrollment.Actions.LoadMemberFilters, (state, { memberFilters }) => {
    return {
      ...state,
      memberFilters
    }
  }) ,
  on(fromEnrollment.Actions.ToggleMemberFilter, (state, { id }) => {
  const memberFilters = toggleItem(state.memberFilters, id);
  return {
    ...state,
    memberFilters
  }
  }),
  on(fromEnrollment.Actions.LoadTagFilters, (state, { tagFilters }) => {
    return {
      ...state,
      tagFilters
    }
  }),
  on(fromEnrollment.Actions.ToggleTagFilter, (state, { id }) => {
    const tagFilters = toggleItem(state.tagFilters, id);
    return {
      ...state,
      tagFilters
    }
  }),
  on(fromEnrollment.Actions.LoadCategoryFilters, (state, { courseCategoryFilters }) => {
    return {
      ...state,
      courseCategoryFilters
    }
  }),
  on(fromEnrollment.Actions.LoadSubCategoryFilters, (state, { courseSubCategoryFilters }) => {
    return {
      ...state,
      courseSubCategoryFilters
    }
  }),
  on(fromEnrollment.Actions.ToggleCategoryFilter, (state, { id }) => {
    const courseCategoryFilters = toggleItem(state.courseCategoryFilters, id);
    return {
      ...state,
      courseCategoryFilters
    }
  }),
  on(fromEnrollment.Actions.ToggleSubCategoryFilter, (state, { id }) => {
    const courseSubCategoryFilters = toggleItem(state.courseSubCategoryFilters, id);
    return {
      ...state,
      courseSubCategoryFilters
    }
  }),
  on(fromEnrollment.Actions.LoadStudentSuccess, (state, {currentStudent}) =>{
    return{
      ...state,
      isCurrentStudentLoading:false,
      currentStudent
    }
  }),
  on(fromEnrollment.Actions.LoadStudentDetailsSuccess, (state, {currentStudentDetails}) =>{
    return{
      ...state,
      currentStudentDetails
    }
  }),
  on(fromInstitution.Actions.LoadCalendarSuccess, (state, action)=>{
    const academicYears = action.academicYears;
    const currentAcademicYear = getCurrentAcademicYear(academicYears);
    const semesterFilters = getSemesterFilters(currentAcademicYear);
    return{
      ...state,
      academicYears,
      currentAcademicYear,
      semesterFilters
    }
  }),
  on(fromInstitution.Actions.ChangeCurrentAcademicYear, (state, {academicYear})=>{
    const semesterFilters = getSemesterFilters(academicYear);
    return{
      ...state,
      semesterFilters
    }
  }),
  on(fromEnrollment.Navigate.ProviderEnrollments, (state,action)=>{
    const searchText = '';
    const statusFilters = state.statusFilters.map((filter)=> {return {...filter, selected:false}});
    return {
      ...state,
      searchText,
      statusFilters
    }
  }),
)


