<mat-toolbar>
  <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <span class="title dialog-title">Invite {{currentInstitution?.institutionName}} to Course Exchange</span>
    <button mat-icon-button (click)="close()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div class="content">
  <form [formGroup]="form">
    <div>
      <mat-checkbox formControlName="isMember">Member</mat-checkbox>
  </div>
  <div>
      <mat-checkbox formControlName="isProvider">Provider</mat-checkbox>
  </div>
  <div class="actions">
    <button mat-raised-button
            color="warn"
            (click)="close()">
      <mat-icon>not_interested</mat-icon>
      Cancel
    </button>

    <button mat-raised-button
            [disabled]="!form.valid"
            (click)="inviteInstitution()"
            type="button">
      <mat-icon>mail</mat-icon>
      Send Invite
    </button>
  </div>
  </form>
</div>
