<div class="content">
  <div class="title">{{matDialogData.title}}</div>
  <div class="description">{{matDialogData.description}}</div>

  <div class="actions">
    <div class="action" *ngFor="let action of matDialogData.actions">
      <button mat-raised-button (click)="action.cb ? action.cb() : close()">
        {{action.name}}
      </button>
    </div>
  </div>
</div>
