<div class="file-uploader">
  <input hidden type="file" #fileInput/>
  <button mat-fab
          color="warn"
          class="add-file-button"
          (click)="fileInput.click()"
          (change)="onFileSelected($event.target.file)"
          aria-label="Add file"
          [@animate]="{value:'*', params:{delay:'300ms',scale:'0.2'}}">
      <mat-icon>add</mat-icon>
  </button>
</div>
