<mat-toolbar>
  <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <span class="title dialog-title">Invite New Institution to Course Exchange</span>
    <button mat-icon-button (click)="close()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div class="content">
  <form [formGroup]="form">
    <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
      <mat-label>Institution Name</mat-label>
      <input matInput placeholder="Institution Name" name="name" formControlName="institutionName">
    </mat-form-field>
    <mat-error *ngIf="form.get('institutionName').hasError('institutionExists')">
      Institution Name Exists
    </mat-error>
    <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
      <mat-label>Primary Contact</mat-label>
      <input matInput placeholder="Primary Contact" name="primaryContact" formControlName="primaryContact">
    </mat-form-field>
    <mat-form-field appearance="outline" floatLabel="always" class="w-100-p ">
      <mat-label>Email</mat-label>
      <input matInput
             type="email"
             placeholder="Email"
             name="emailAddress"
             uniqueEmail
             [userId]="getUserId()"
             formControlName="emailAddress">
    </mat-form-field>
    <mat-error *ngIf="form.get('emailAddress').hasError('emailExists')">
      Email already Exists
    </mat-error>
    <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
      <mat-label>Re-Enter email</mat-label>
      <input matInput type="email" placeholder="Re-Enter Email" name="confirmEmailAddress"
        formControlName="confirmEmailAddress">
    </mat-form-field>
    <mat-error *ngIf="form.get('confirmEmailAddress').hasError('emailsNotMatching') || form.get('emailAddress').hasError('emailsNotMatching')">
      Emails need to match
    </mat-error>
    <div>
      <mat-checkbox formControlName="isMember">Member</mat-checkbox>
    </div>
    <div>
      <mat-checkbox formControlName="isProvider">Provider</mat-checkbox>
    </div>
    <div class="actions">
      <button mat-raised-button color="warn" (click)="cancel()">
        <mat-icon>not_interested</mat-icon>
        Cancel
      </button>

      <button mat-raised-button [disabled]="!form.valid" (click)="inviteInstitution()" type="button">
        <mat-icon>mail</mat-icon>
        Send Invite
      </button>
    </div>
</form>
</div>
