import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'limitedCounter'
})
export class LimitedCounterPipe implements PipeTransform {
  transform(value: number, max = 99): string {
    if (value > max) {
      return max + '+';
    }

    return value.toString();
  }
}
