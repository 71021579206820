import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import { ChecklistItemType } from '@core/models/checklistItemType.enum';
import { IAppState } from 'app/store/state/app.state';
import * as fromWizard from '@shared/wizard/store';
export interface IWizardSkipDialogData {
  itemType: ChecklistItemType;
  toNextStepCb: () => void;
  description?: string;
}
@Component({
  selector: 'wizard-skip-dialog-container',
  templateUrl: './wizard-skip-dialog-container.component.html',
  styleUrls: ['./wizard-skip-dialog-container.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WizardSkipDialogContainerComponent {
  closeButtonName = 'No';
  translateButtonName = 'Yes';

  constructor(
    public matDialogRef: MatDialogRef<WizardSkipDialogContainerComponent>,
    @Inject(MAT_DIALOG_DATA) public matDialogData: IWizardSkipDialogData,
    private store: Store<IAppState>
  ) {
    this.matDialogRef.addPanelClass('wizard-skip-dialog-container');
    this.shapeButtonsText();
  }

  shapeButtonsText(): void {
    switch (this.matDialogData.itemType) {
      case ChecklistItemType.BankInformation:
      case ChecklistItemType.Calendar: {
        this.closeButtonName = 'Return to complete';
        this.translateButtonName = 'Proceed without adding';
      }
    }
  }

  toNextStep(): void {
    this.matDialogData.toNextStepCb();
    this.close();
  }

  close(): void {
    this.matDialogRef.close();
  }

  goToHome(): void {
    this.store.dispatch(fromWizard.Actions.changeCurrentStepId({stepId:ChecklistItemType.Root}))

    this.close();
  }
}
