import { createEffect, Actions, ofType } from "@ngrx/effects";
import { Injectable } from "@angular/core";
import {map, switchMap, withLatestFrom, concatMap, tap, catchError} from "rxjs/operators";
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { IAppState } from 'app/store/state/app.state';
import * as fromCore from '@core/store';
import * as fromProvider from '@provider/store';
import * as fromEnrollment from '@provider/enrollment/store';
import * as fromCourseExchange from '@courseExchange/store';
import * as fromRefData from '@refData';
import * as fromInstitution from '@institution/store';
import { MatDialog } from '@angular/material/dialog';
import { ProviderService } from '@shared/provider/services';
import { ExcelService, ExcelColumn } from '@core/services/excel.service';
import * as moment from 'moment';
import { StudentService } from '@shared/student/services';
import { EnrollmentListItem } from "@member/enrollment";
import { RealTimeService } from "@core/services/realTime.service";
import { FilterType } from "@shared/common/models";
import { AcademicYear } from "@shared/calendar/models";
import { HubConnection } from "@microsoft/signalr";
import { Student } from "@shared/student/models";

@Injectable()
export class EnrollmentRealtime {
  private currentAcademicYear$: Observable<AcademicYear>;
  constructor(private store: Store<IAppState>, private realTimeService: RealTimeService) {
    this.initSignalRMessages();
    this.currentAcademicYear$ = this.store.pipe(select(fromInstitution.Selectors.CurrentAcademicYear));
  }

  private initSignalRMessages(){
    this.realTimeService.hubConnection$.subscribe(hubConnection=>{
      if(!hubConnection) return;
      this.providerEnrollmentListItemUpdated(hubConnection);
      this.providerEnrollmentListItemAdded(hubConnection);
      this.studentUpdated(hubConnection);
    });
  }
  private providerEnrollmentListItemUpdated(hubConnection:HubConnection){
    hubConnection.on('ProviderEnrollmentListItemUpdated', (enrollment: EnrollmentListItem) => {
      this.enrollmentListEdited(enrollment);
    });
  }
  private providerEnrollmentListItemAdded(hubConnection:HubConnection){
    hubConnection.on('ProviderEnrollmentListItemAdded', (enrollment: EnrollmentListItem)=>{
      this.enrollmentListAdded(enrollment);
    })
  }
  private studentUpdated(hubConnection:HubConnection){
    hubConnection.on('StudentUpdated', (currentStudent:Student)=>{
      this.store.dispatch(fromEnrollment.Actions.LoadStudentSuccess({currentStudent}));
    })
  }

  private enrollmentListAdded(enrollment:EnrollmentListItem){
    of(enrollment)
    .pipe(
      withLatestFrom(this.currentAcademicYear$),
      map(([enrollment, academicYear])=>{
        if(moment(enrollment.sessionStartDate).isBetween(academicYear.startDate,academicYear.endDate,undefined,"[]")){
          this.store.dispatch(fromEnrollment.Actions.EnrollmentListItemAdded({enrollment}));
        }
      })
    ).subscribe();
  }
  private enrollmentListEdited(enrollment:EnrollmentListItem){
    this.store.dispatch(fromEnrollment.Actions.EnrollmentListItemUpdated({enrollment}));
  }
}
