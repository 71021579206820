import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Region } from 'app/core/referenceData/region';
import { UntypedFormGroup } from '@angular/forms';
import { AccreditationBody } from 'app/core/referenceData';
@Component({
  selector: 'app-institution-add-edit-form',
  templateUrl: './institution-add-edit-form.component.html',
  styleUrls: ['./institution-add-edit-form.component.scss']
})
export class InstitutionAddEditFormComponent implements OnInit {



  @Input() logoUrl: string;
  @Input() accreditationBodies: Array<AccreditationBody>;
  @Input() regions: Array<Region>;
  @Input() institutionForm: UntypedFormGroup;
  //test
  @Input() item: string;
  @Output() fileSelected: EventEmitter<any> = new EventEmitter<any>(null);

  constructor() {
  }

  ngOnInit() {

  }

  onFileSelected(file: any): void {
    this.fileSelected.emit(file);
  }

}
