import { createEffect, Actions, ofType } from "@ngrx/effects";
import { Injectable } from "@angular/core";
import { tap, map, switchMap } from "rxjs/operators";
import * as fromRefData from '@refData';
import * as fromCore from '@core/store';
import { RefDataService } from '../../services/ref-data.service';
import { RealTimeService } from "@core/services/realTime.service";
import { CourseExchangeListItem } from "@shared/courseExchange/models";
import { Store } from "@ngrx/store";
import { IAppState } from "app/store";

@Injectable()
export class RefDataEffects{
    constructor(private actions$: Actions, private refDataService: RefDataService, private realTimeService: RealTimeService,
        private store: Store<IAppState>){
        this.initSignalRMessages();
    }

    private initSignalRMessages(){
        this.realTimeService.hubConnection$.subscribe(hubConnection=>{
          if(!hubConnection) return;
          hubConnection.on('CourseExchangeListItemUpdated', (courseExchangeListItem: CourseExchangeListItem) => {
            this.store.dispatch(fromRefData.Actions.CourseExchangeListItemUpdated({courseExchangeListItem}));
          });
          hubConnection.on('CourseExchangeListItemAdded', (courseExchangeListItem: CourseExchangeListItem) => {
            this.store.dispatch(fromRefData.Actions.CourseExchangeListItemAdded({courseExchangeListItem}));
          });
        })
      }

    loadAccreditationBodies$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromRefData.Actions.LoadAccreditationBodies),
                switchMap(()=> this.refDataService.getAccreditationBodies()),
                map((accreditationBodies) => fromRefData.Actions.LoadAccreditationBodiesSuccess({accreditationBodies}))    
            );
    });

    loadCountries$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromRefData.Actions.LoadCountries),
                switchMap(()=> this.refDataService.getCountries()),
                map((countries) => fromRefData.Actions.LoadCountriesSuccess({countries}))    
            );
    });
    
    loadCourseCategories$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromRefData.Actions.LoadCourseCategories),
                switchMap(()=> this.refDataService.getCourseCategories$()),
                map((courseCategories) => fromRefData.Actions.loadCourseCategoriesSuccess({courseCategories}))    
            );
    });

    loadCourseLevels$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromRefData.Actions.LoadCourseLevels),
                switchMap(()=> this.refDataService.getCourseLevels()),
                map((courseLevels) => fromRefData.Actions.LoadCourseLevelsSuccess({courseLevels}))    
            );
    });

    loadCourseSubCategories$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromRefData.Actions.LoadCourseSubCategories),
                switchMap(()=> this.refDataService.getCourseSubCategories()),
                map((courseSubCategories) => fromRefData.Actions.LoadCourseSubCategoriesSuccess({courseSubCategories}))    
            );
    });

    loadCourseTags$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromRefData.Actions.LoadCourseTags),
                switchMap(()=> this.refDataService.getCourseTags()),
                map((courseTags) => fromRefData.Actions.LoadCourseTagsSuccess({courseTags}))    
            );
    });

    loadStudentTypes$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromRefData.Actions.LoadStudentTypes),
                switchMap(()=> this.refDataService.getStudentTypes()),
                map((studentTypes) => fromRefData.Actions.LoadStudentTypesSuccess({studentTypes}))    
            );
    });

    toggleStudentType$ = createEffect(() => {
        let studentTypeId = "";
        return this.actions$.pipe(
                ofType(fromRefData.Actions.ToggleStudentType),
                switchMap(({id})=> {
                    studentTypeId = id;
                    return this.refDataService.toggleActiveStudentType(studentTypeId)
                }),
                map((response) => fromRefData.Actions.ToggleStudentTypeSuccess({studentTypeId}))    
            );
    });

    addStudentType$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromRefData.Actions.AddStudentType),
                switchMap(({command})=> {
                    return this.refDataService.addStudentType(command)
                }),
                map((response)=>fromCore.Actions.HandleResponse({response}))   
            );
    });

    editStudentType$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromRefData.Actions.EditStudentType),
                switchMap(({command})=> {
                    return this.refDataService.editStudentType(command)
                }),
                map((response)=>fromCore.Actions.HandleResponse({response}))   
            );
    });

    deleteStudentType$ = createEffect(() => {
        let studentTypeId = "";
        return this.actions$.pipe(
                ofType(fromRefData.Actions.DeleteStudentType),
                switchMap(({id})=> {
                    studentTypeId = id;
                    return this.refDataService.deleteStudentType(studentTypeId)
                }),
                map((response) => fromRefData.Actions.DeleteStudentTypeSuccess({studentTypeId}))    
            );
    });

    loadCourseExchangeRoles$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromRefData.Actions.LoadCourseExchangeRoles),
                switchMap(()=> this.refDataService.getCourseExchangeRoles()),
                map((courseExchangeRoles) => fromRefData.Actions.LoadCourseExchangeRolesSuccess({courseExchangeRoles}))    
            );
    });

    loadGenders$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromRefData.Actions.LoadGenders),
                switchMap(()=> this.refDataService.getGenders()),
                map((genders) => fromRefData.Actions.LoadGendersSuccess({genders}))    
            );
    });

    loadCourseExchanges$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromRefData.Actions.LoadCourseExchanges),
                switchMap(()=> this.refDataService.getCourseExchanges()),
                map((courseExchanges) => fromRefData.Actions.LoadCourseExchangesSuccess({courseExchanges}))    
            );
    });

    loadInstitutions$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromRefData.Actions.LoadInstitutions),
                switchMap(()=> this.refDataService.getInstitutionSummaries()),
                map((institutions) => fromRefData.Actions.LoadInstitutionsSuccess({institutions}))    
            );
    });

    loadInstitutionRoles$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromRefData.Actions.LoadInstitutionRoles),
                switchMap(()=> this.refDataService.getInstitutionRoles()),
                map((institutionRoles) => fromRefData.Actions.LoadInstitutionRolesSuccess({institutionRoles}))    
            );
    });

    loadRegions$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromRefData.Actions.LoadRegions),
                switchMap(()=> this.refDataService.getRegions()),
                map((regions) => fromRefData.Actions.LoadRegionsSuccess({regions}))    
            );
    });

    loadProviderPolicyTemplates$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromRefData.Actions.LoadProviderPolicyTemplates),
                switchMap(()=> this.refDataService.getProviderPolicyTemplates()),
                map((providerPolicyTemplates) => fromRefData.Actions.LoadProviderPolicyTempaltesSuccess({providerPolicyTemplates}))    
            );
    });

    
    loadCourseExchangePolicyTemplates$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromRefData.Actions.LoadCourseExchangePolicyTemplates),
                switchMap(()=> this.refDataService.getCourseExchangePolicyTemplates()),
                map((courseExchangePolicyTemplates) => fromRefData.Actions.LoadCourseExchangePolicyTemplatesSuccess({courseExchangePolicyTemplates}))    
            );
    });

    loadPermissionDisplays$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromRefData.Actions.LoadPermissionDisplays),
                switchMap(()=> this.refDataService.getPermissionDisplays()),
                map((permissionDisplays) => fromRefData.Actions.LoadPermissionDisplaysSuccess({permissionDisplays}))    
            );
    });

    loadEnrollmentStatuses$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromRefData.Actions.LoadEnrollmentStatuses),
                switchMap(()=> this.refDataService.getEnrollmentStatuses()),
                map((enrollmentStatuses) => 
                fromRefData.Actions.LoadEnrollmentStatusesSuccess({enrollmentStatuses}))    
            );
    });

    loadLetterGrades$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromRefData.Actions.LoadLetterGrades),
                switchMap(()=> this.refDataService.getLetterGrades()),
                map((letterGrades) => 
                fromRefData.Actions.LoadLetterGradesSuccess({letterGrades}))    
            );
    });

    downloadBankInfoTemplate$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromRefData.Actions.DownloadBankInfoTemplate),
                tap(()=> this.refDataService.downloadBankInfoTemplate())   
            );
    },{dispatch: false});

    downloadCourseExchangePolicyTemplate$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromRefData.Actions.DownloadCourseExchangePolicyTemplate),
                tap((action)=> this.refDataService.downloadCourseExchangePolicyTemplate(action.courseExchangePolicyTemplate))   
            );
    },{dispatch: false});

    downloadProviderPolicyTemplate$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromRefData.Actions.DownloadProviderPolicyTemplate),
                tap((action)=> this.refDataService.downloadProviderPolicyTemplate(action.providerPolicyTemplate))   
            );
    },{dispatch: false});
    

    
}