import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { Store, select } from "@ngrx/store";
import { first, finalize, filter, map, concatMap, withLatestFrom, switchMap } from "rxjs/operators";
import { IAppState } from 'app/store/state/app.state';
import * as fromCourseExchangeInstitution from '@courseExchange/institution';
import { InstitutionListItem } from '@admin/institution';
import { InstitutionService } from '@shared/institution/services/institution.service';

@Injectable()
export class AllInstitutionsResolver implements Resolve<InstitutionListItem>{
    isLoading = false;
    constructor(private store: Store<IAppState>, private institutionService: InstitutionService){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
        return this.store 
            .pipe(
                select(fromCourseExchangeInstitution.Selectors.CourseExchangeId),
                switchMap((action) => this.institutionService.getInstitutionList()),
                map((allInstitutions)=> {
                    this.store.dispatch(fromCourseExchangeInstitution.Actions.LoadAllInstitutions({allInstitutions}));
                    return true;
                }),
                filter(areInstitutionsLoaded => areInstitutionsLoaded),
                first(),
                finalize(()=>this.isLoading=false)
            )
    }
}