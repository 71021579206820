<div class="toolbar" [ngClass]="currentAccessRole.viewContext">
  <div class="toolbar-part logo-wrap">
    <toolbar-logo></toolbar-logo>
  </div>

  <div class="toolbar-part center-icon-menu">
    <div class="toolbar-icons-group">
      <toolbar-action (onClick)="goToDashboard()"
                      [icon]="'dashboard'"
                      [tooltipTitle]="'Dashboard'"></toolbar-action>

      <toolbar-action (onClick)="goToUserList()"
                      [icon]="'people'"
                      [tooltipTitle]="'User List'"></toolbar-action>
                      
    <div class="toolbar-icon">
      <button class="toolbar-icon-button" mat-icon-button matTooltip="Courses Data" routerLink="/admin/referenceData/coursesData" aria-label="Course Data">
        <mat-icon>bar_chart</mat-icon>
      </button>
    </div>
    <div class="toolbar-icon">
      <button class="toolbar-icon-button" mat-icon-button matTooltip="Policy Templates" routerLink="/admin/referenceData/policyTemplates" aria-label="Policy Templates">
        <mat-icon>rule_folder</mat-icon>
      </button>
    </div>
    
                     

    </div>
  </div>

  <div class="toolbar-part user-info-and-menu">
    <toolbar-user-info></toolbar-user-info>

    <div class="toolbar-icons-group">
      <toolbar-notifications [currentAccessRole]="currentAccessRole"></toolbar-notifications>
      <toolbar-menu>
        <div class="toolbar-menu">
          <toolbar-secondary-user-info
          [actionName]="'View Member Profile'"
          [logoUrl]="(currentCourseExchange$ | async)?.secondaryLogoUrl"></toolbar-secondary-user-info>
          
          <div class="toolbar-menu-links">
            <div *ngIf="(courseExchanges$ | async)?.length>1">
              <ng-container *ngFor="let courseExchange of courseExchanges$ | async">
                <a (click)="changeCourseExchange(courseExchange.courseExchangeId)" class="toolbar-menu-link">
                  <span class="icon">
                    <img [src]="courseExchange.secondaryLogoUrl">
                  </span>
                  <span class="text">{{courseExchange.name}}</span>
                </a>
              </ng-container>
            </div>
          </div>
          
          <div class="toolbar-menu-links">
            <div class="toolbar-menu-link" *ngIf="showSystemDate">
              <mat-form-field>
                <mat-label>Set System Date</mat-label>
                <input matInput [matDatepicker]="picker" [value]="currentSystemDate$ | async"
                       (dateChange)="setSystemDate($event)">
                <mat-datepicker-toggle matSuffix [for]="picker">
                  <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
                </mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
            <a class="toolbar-menu-link" (click)="goToUserList()">
              <span class="icon">
                <mat-icon>people_alt</mat-icon>
              </span>
              <span class="text">Users</span>
            </a>

            <a (click)="logout()" class="toolbar-menu-link">
              <span class="icon">
                <mat-icon>exit_to_app</mat-icon>
              </span>
              <span class="text">Log out</span>
            </a>
          </div>
        </div>
      </toolbar-menu>
    </div>
  </div>
</div>
