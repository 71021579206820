import {Injectable} from '@angular/core';
import {Actions, ofType, createEffect} from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { IAppState } from 'app/store/state/app.state';
import { concatMap, withLatestFrom, switchMap, map, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as fromCourseExchangeCourses from '@courseExchange/courses/store';
import * as fromCourseExchange from '@courseExchange/store';
import * as fromCore from '@core/store';
import * as fromRefData from '@refData';
import { FilterType } from '@shared/common/models';
import {CourseService} from '@shared/courseExchange/services/course.service';
import { CourseDescriptionDialogComponent, ICourseDescriptionDialogData } from '@member/memberCourse/components';
import { MatDialog } from '@angular/material/dialog';
import { ProviderCourseStatus } from '@provider/courses/models/providerCourseStatus.enum';
import { ExcelColumn, ExcelService } from '@core/services/excel.service';
import { CreditHourType } from '@core/models';
import { InstitutionService } from '@shared/institution/services/institution.service';

@Injectable()
export class CoursesEffects {
  constructor(private actions$: Actions, private store: Store<IAppState>,private dialog: MatDialog, 
    private courseService: CourseService, private excelService: ExcelService, private institutionService: InstitutionService) 
  {
  }

  loadCourses$ = createEffect(() => {
    return this.actions$.pipe(
        ofType(fromCourseExchangeCourses.Actions.LoadCourses),
        /** An EMPTY observable only emits completion. Replace with your own observable stream */
        switchMap(({courseExchangeId}) => this.courseService.getCourses(courseExchangeId)),
        map((result) => {
          const courses = result.map(course => {return {...course, selected:false, canModify: true}});
          return fromCourseExchangeCourses.Actions.LoadCoursesSuccess({courses})
        }),
        catchError((err) => of(fromCourseExchangeCourses.Actions.LoadCoursesError({message: err.message})))
    );
  });

  loadCoursesError$ = createEffect(() => {
    return this.actions$.pipe(
        ofType(fromCourseExchangeCourses.Actions.LoadCoursesError),
        /** An EMPTY observable only emits completion. Replace with your own observable stream */
        map(({message}) => fromCore.Actions.ShowMessage({message})));
  });

  loadCourse$ = createEffect(() => {
    return this.actions$.pipe(
        ofType(fromCourseExchangeCourses.Actions.LoadCourse),
        /** An EMPTY observable only emits completion. Replace with your own observable stream */
        switchMap(({courseExchangeId, courseId}) => this.courseService.getCourseById(courseExchangeId, courseId)),
        map((currentCourse) => {
          return fromCourseExchangeCourses.Actions.LoadCourseSuccess({currentCourse})
        }),
        catchError((err) => of(fromCourseExchangeCourses.Actions.LoadCourseError({message: err.message})))
    );
  });

  loadCourseError$ = createEffect(() => {
    return this.actions$.pipe(
        ofType(fromCourseExchangeCourses.Actions.LoadCourseError),
        map(({message}) => fromCore.Actions.ShowMessage({message})));
  });

  loadFacultyCVs$ = createEffect(() => {
    return this.actions$.pipe(
        ofType(fromCourseExchangeCourses.Actions.LoadFacultyList),
        /** An EMPTY observable only emits completion. Replace with your own observable stream */
        switchMap(({institutionId, courseId}) => this.institutionService.getCourseFacultyCVs(institutionId, courseId)),
        map((currentFacultyCVs) => {
          return fromCourseExchangeCourses.Actions.LoadFacultyListSuccess({currentFacultyCVs})
        }),
        catchError((err) => of(fromCourseExchangeCourses.Actions.LoadCourseError({message: err.message})))
    );
  });
  
  loadLevelFilters$ = createEffect(() => {
    return this.actions$.pipe(
        ofType(fromCourseExchange.Actions.LoadCourseExchangeSummarySuccess),
        concatMap((action) => of(action).pipe(
          withLatestFrom(this.store.pipe(select(fromRefData.courseLevels)))
        )),
        /** An EMPTY observable only emits completion. Replace with your own observable stream */
        map(([{courseExchangeSummary}, courseLevels]) => {
          const levelFilters = courseExchangeSummary.courseLevels.map(id => {
            const courseLevel = courseLevels.find(x => x.id === id);
            return {
              id: id,
              type: FilterType.CourseLevel,
              description: !!courseLevel ? courseLevel.description : '',
              descriptionForView: !!courseLevel ? courseLevel.description : '',
              selected: false
            }
          });
          return fromCourseExchangeCourses.Actions.LoadCourseLevelFilters({levelFilters});
        })
    );
  });

  loadCategoryFilters$ = createEffect(() => {
    return this.actions$.pipe(
        ofType(fromCourseExchange.Actions.LoadCourseExchangeSummarySuccess),
        concatMap((action) => of(action).pipe(
          withLatestFrom(this.store.pipe(select(fromRefData.courseCategories)))
        )),
        /** An EMPTY observable only emits completion. Replace with your own observable stream */
        map(([{courseExchangeSummary}, categories]) => {
          const courseCategoryFilters = courseExchangeSummary.courseCategories.map(id => {
            const category = categories.find(x => x.id === id);
            return {
              id: id,
              type: FilterType.CourseCategory,
              description: !!category ? category.description : '',
              descriptionForView: !!category ? category.description : '',
              selected: false
            }
          });
          return fromCourseExchangeCourses.Actions.LoadCourseCategoryFilters({courseCategoryFilters});
        })
    );
  });

  loadSubCategoryFilters$ = createEffect(() => {
    return this.actions$.pipe(
        ofType(fromCourseExchange.Actions.LoadCourseExchangeSummarySuccess),
        concatMap((action) => of(action).pipe(
          withLatestFrom(this.store.pipe(select(fromRefData.courseSubCategories)))
        )),
        /** An EMPTY observable only emits completion. Replace with your own observable stream */
        map(([{courseExchangeSummary}, subCategories]) => {
          const courseSubCategoryFilters = courseExchangeSummary.courseSubCategories.map(id => {
            const subCategory = subCategories.find(x => x.id === id);
            return {
              id: id,
              type: FilterType.CourseSubCategory,
              description: !!subCategory ? subCategory.description : '',
              descriptionForView: !!subCategory ? subCategory.description : '',
              selected: false
            }
          });
          return fromCourseExchangeCourses.Actions.LoadCourseSubCategoryFilters({courseSubCategoryFilters});
        })
    );
  });

  loadTagFilters$ = createEffect(() => {
    return this.actions$.pipe(
        ofType(fromCourseExchange.Actions.LoadCourseExchangeSummarySuccess),
        concatMap((action) => of(action).pipe(
          withLatestFrom(this.store.pipe(select(fromRefData.courseTags)))
        )),
        /** An EMPTY observable only emits completion. Replace with your own observable stream */
        map(([{courseExchangeSummary}, tags]) => {
          const tagFilters = courseExchangeSummary.courseTags.map(id => {
            const tag = tags.find(x => x.id === id);
            return {
              id: id,
              type: FilterType.Tag,
              description: !!tag ? tag.description : '',
              descriptionForView: !!tag ? tag.description : '',
              selected: false
            }
          });
          return fromCourseExchangeCourses.Actions.LoadTagFilters({tagFilters});
        })
    );
  });

  exportToExcel$ = createEffect(() => {
    return this.actions$.pipe(
        ofType(fromCourseExchangeCourses.Actions.ExportToExcel),
        concatMap((action) => of(action).pipe(
          withLatestFrom(this.store.pipe(select(fromCourseExchangeCourses.Selectors.SelectedCourses)))
        )),
        tap(([action, courses]) => {
          const excelColumns: ExcelColumn[] = new Array<ExcelColumn>(
            {header: 'Provider Name', fieldName: 'institutionName'},
            {header: 'Course Code', fieldName: 'code'},
            {header: 'Course Title', fieldName: 'title'},
            {header: 'Course Level', fieldName: 'courseLevel'},
            {header: 'Course Credits', fieldName: 'creditHours'},
            {header: 'Category', fieldName: 'category'},
            {header: 'Sub Category', fieldName: 'subCategory'},
            {header: 'Course Requirements', fieldName: 'requirements'},
            {header: 'Course Description', fieldName: 'description'},
            {header: 'Notes', fieldName: 'notes'},
            {header: 'Course Tags', fieldName: 'tags'}
          );
      
          const result = [];
      
          courses.forEach(course => {
            let tags = '';
            course.tags.forEach(x=>{
              tags = tags == '' ? x.description : tags + ', ' + x.description;
            });
            const excelObject = {
              institutionName: course.institution.description,
              code: course.code,
              title: course.title,
              courseLevel: course.level.description,
              creditHours: `${course.creditHours2} ${this.getCreditHourType(course.creditHourType)} Credit Hours`,
              category: course.category.description,
              subCategory: course.subCategory.description,
              requirements: course.requirements,
              description: course.description,
              notes: course.notes,
              tags
            };
      
            result.push(excelObject);
          });
      
          this.excelService.exportAsExcelFile(result, excelColumns, 'Courses');
        }));
  },{dispatch:false});

  loadProviderFilters$ = createEffect(() => {
    return this.actions$.pipe(
        ofType(fromCourseExchange.Actions.LoadCourseExchangeSummarySuccess),
        /** An EMPTY observable only emits completion. Replace with your own observable stream */
        map(({courseExchangeSummary}) => {
          const providerFilters = courseExchangeSummary.providers.map(provider => {
            return {
              id: provider.id,
              type: FilterType.Provider,
              description: !!provider ? provider.description : '',
              descriptionForView: !!provider ? provider.description : '',
              selected: false
            }
          });
          return fromCourseExchangeCourses.Actions.LoadProviderFilters({providerFilters});
        })
    );
  });

  masterToggle$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromCourseExchangeCourses.Actions.MasterToggle),
      concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromCourseExchangeCourses.Selectors.FilteredCourses)))
      )),
      map(([action, courses]) => {
        const isAllSelected = courses.every(x => x.selected);
        return courses.map(x => {
          return {
            ...x,
            selected: !isAllSelected
          }
        })
      }),
      map((courses) => fromCourseExchangeCourses.Actions.MasterToggled({ courses }))
    )
  });

  approveSelectedCourses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromCourseExchangeCourses.Actions.ApproveSelectedCourses),
      concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromCourseExchangeCourses.Selectors.SelectedCourses)))
      )),
      map(([action, courses]) => courses.filter(x=>x.status != ProviderCourseStatus.Approved).map(x=>x.providerCourseId)),
      map((courseIds) => fromCourseExchangeCourses.Actions.ApproveCourses({ courseIds }))
    )
  });

  disapproveSelectedCourses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromCourseExchangeCourses.Actions.DisapproveSelectedCourses),
      concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromCourseExchangeCourses.Selectors.SelectedCourses)))
      )),
      map(([action, courses]) => courses.map(x=>x.providerCourseId)),
      map((courseIds) => fromCourseExchangeCourses.Actions.DisapproveCourses({ courseIds }))
    )
  });

  approveCourses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromCourseExchangeCourses.Actions.ApproveCourses),
      concatMap((action) => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromCourseExchange.Selectors.CourseExchangeId)))
      )),
      switchMap(([{courseIds},courseExchangeId]) => this.courseService.approveCourses(courseExchangeId, courseIds)),
      map((response) =>fromCore.Actions.HandleResponse({response}))
    )
  });

  disapproveCourses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromCourseExchangeCourses.Actions.DisapproveCourses),
      concatMap((action) => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromCourseExchange.Selectors.CourseExchangeId)))
      )),
      switchMap(([{courseIds},courseExchangeId]) => this.courseService.disapproveCourses(courseExchangeId, courseIds)),
      map((response) =>fromCore.Actions.HandleResponse({response}))
    )
  });

  readMore$ = createEffect(() => {
    return this.actions$.pipe(
        ofType(fromCourseExchangeCourses.Actions.ReadMore),
        concatMap((action) => of(action).pipe(
          withLatestFrom(this.store.pipe(select(fromCourseExchangeCourses.Selectors.CurrentCourse)))
        )),
        tap(([action, course]) =>{
          this.dialog.open<CourseDescriptionDialogComponent,ICourseDescriptionDialogData>
          (CourseDescriptionDialogComponent, {
            data: {
              description: course.description,
              title: course.title
            }
          })     
        })
    )
  },{dispatch: false});

  downloadCourseSyllabus$ = createEffect(() => {
    return this.actions$.pipe(
            ofType(fromCourseExchangeCourses.Actions.DownloadCourseSyllabus),
            concatMap(action => of(action).pipe(
                    withLatestFrom(this.store.pipe(select(fromCourseExchangeCourses.Selectors.CurrentProviderCourse)))
            )),
            tap(([action, currentProviderCourse]) => {
              const courseExchangeId = !!action.courseExchangeId ? action.courseExchangeId : currentProviderCourse.courseExchangeId;
              const courseId = !!action.courseId ? action.courseId : currentProviderCourse.courseId;
              const syllabus = !!action.syllabus ? action.syllabus : currentProviderCourse.syllabus;
              this.courseService.downloadSyllabus(courseExchangeId,courseId,syllabus)}
            )
    );
}, { dispatch: false });

  getCreditHourType(creditHourType: CreditHourType): string{
    if(creditHourType == CreditHourType.SEM) return "SEM";
    if(creditHourType == CreditHourType.QR) return "QR";
    return "";
  }
}
