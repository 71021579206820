import { AcademicYear } from '@shared/calendar/models';
import * as moment from 'moment';

export default (academicYears: AcademicYear[]): AcademicYear => {
  if(academicYears.length==0) return undefined;

  var result = academicYears.find(x=>moment(Date.now()).isBetween(x.startDate,x.endDate));
  
  if(result==undefined) result = academicYears[0];

  return result;
}
