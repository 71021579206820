import {
    createReducer,
    on
  } from '@ngrx/store';
import * as fromInstitution from '@institution/store';
import { Institution } from '@admin/institution/models';
import { AcademicYear, Semester, Session, SessionDateRules } from '@shared/calendar/models';
import { FacultyCV, InstitutionSummary } from '@shared/institution/models';
import convertAcademicYearDates from '@shared/common/helpers/convertAcademicYearsDates';
import { DateParts } from '@core/models/dateParts';
import * as moment from 'moment';
import addAcademicYear from './helpers/addAcademicYear';
import addSemester from './helpers/addSemester';
import addSession from './helpers/addSession';
import updateAcademicYear from './helpers/updateAcademicYear';
import updateSemester from './helpers/updateSemester';
import updateSession from './helpers/updateSession';
import deleteSemester from './helpers/deleteSemester';
import deleteSession from './helpers/deleteSession';
import deleteAcademicYear from './helpers/deleteAcademicYear';

  export interface InstitutionState {
    institution: Institution,
    institutionSummary:InstitutionSummary,
    academicYears: AcademicYear[],
    addingAcademicYearId: string,
    sessionDateRules: SessionDateRules[],
    currentAcademicYearId: string,
    currentAcademicYear: AcademicYear,
    calendarLoaded: boolean
    facultyCVs: FacultyCV[],
    areFacultyCVsLoaded: boolean,
    isSendingLogo: boolean
  }

  export const initialInstitutionState: InstitutionState = {
    institution: undefined,
    institutionSummary: undefined,
    academicYears: new Array<AcademicYear>(),
    addingAcademicYearId: '',
    sessionDateRules: [],
    currentAcademicYearId: "empty",
    currentAcademicYear: undefined,
    calendarLoaded: false,
    facultyCVs: [],
    areFacultyCVsLoaded: false,
    isSendingLogo: false
  };

  export const institutionReducer = createReducer(
    initialInstitutionState,
    on(fromInstitution.Actions.LoadInstitutionSuccess, (state, action) =>{
    const institution = action.institution;
    return {
        ...state,
        institution,
        academicYears: [],
        currentAcademicYear: undefined,
        calendarLoaded: false,
        facultyCVs: [],
        areFacultyCVsLoaded: false
      };
    }),
    on(fromInstitution.Actions.UpdateInstitutionSuccess, (state, action)=>{
      const institution = action.institution;
      return {
        ...state,
        institution,
        isSendingLogo: false
      }
    }),
    on(fromInstitution.Actions.LoadInstitutionSummarySuccess,(state, action)=>{
      const institutionSummary = action.institutionSummary;

      return {
        ...state,
        institutionSummary
      }
    }),
    on(fromInstitution.Actions.LoadCalendarSuccess, (state, action) =>{
      const academicYears = convertAcademicYearDates(action.academicYears);
      const today = moment().toDate();
      const currentAcademicYear = academicYears.find(x=>x.startDate <= today && x.endDate >= today);
      const currentAcademicYearId = !!currentAcademicYear ? currentAcademicYear.id : "empty";
      return{
        ...state,
        academicYears,
        currentAcademicYear,
        currentAcademicYearId,
        calendarLoaded: true
      }
    }),
    on(fromInstitution.Actions.LoadSessionDateRulesSuccess, (state, {sessionDateRules})=>{
      return {
        ...state,
        sessionDateRules
      }
    }),
    on(fromInstitution.Actions.ChangeCurrentAcademicYearId, (state, {academicYearId})=>{
      const currentAcademicYearId = academicYearId;
      const currentAcademicYear = state.academicYears.find(x=>x.id===academicYearId);
      return {
        ...state,
        currentAcademicYearId,
        currentAcademicYear
      }
    }),
    on(fromInstitution.Actions.ChangeCurrentAcademicYear, (state, {academicYear})=>{
      const currentAcademicYear = academicYear;
      const currentAcademicYearId = !!academicYear ? academicYear.id : "empty";

      return{
        ...state,
        currentAcademicYear,
        currentAcademicYearId
      }
    }),
    on(fromInstitution.Actions.UploadInstitutionLogo, (state, action)=>{
      return {
        ...state,
        isSendingLogo: true
      };
    }),
    on(fromInstitution.Actions.UploadInstitutionLogoSuccess, (state, action)=>{
      return {
        ...state,
        isSendingLogo: false
      };
    }),
    on(fromInstitution.Actions.UploadInstitutionLogoError, (state, action)=>{
      return {
        ...state,
        isSendingLogo: false
      };
    }),
    on(fromInstitution.Actions.InstitutionUpdated, (state, {institution})=>{
      return{
        ...state,
        institution
      }
    }),
    on(fromInstitution.Actions.InstitutionSummaryUpdated, (state, action)=>{
      const institutionSummary = action.institutionSummary;
      return{
        ...state,
        institutionSummary
      }
    }),
    on(fromInstitution.Actions.AcademicYearUpdated, (oldState, {academicYear})=>{
      const state = updateAcademicYear(academicYear,oldState);
      return{
        ...state
      }
    }),
    on(fromInstitution.Actions.UpdateAcademicYear, (oldState, {command})=>{
      const {id, startDate, endDate, name} = command;
      const startDate2: DateParts = {year: moment(startDate).year(), month: moment(startDate).month(), day: moment(startDate).date()};
      const endDate2: DateParts = {year: moment(endDate).year(), month: moment(endDate).month(), day: moment(endDate).date()};

      const academicYear: AcademicYear = {id,startDate,startDate2, endDate,endDate2,name,canDelete:true,semesters:[]};
      const state = updateAcademicYear(academicYear,oldState);
      return{
        ...state
      }
    }),
    on(fromInstitution.Actions.AddAcademicYear, (oldState, {command})=>{
      const id = command.id;
      const {name, startDate, endDate} = command;
      const startDate2: DateParts = {year: moment(startDate).year(), month: moment(startDate).month()+1, day: moment(startDate).date()};
      const endDate2: DateParts = {year: moment(endDate).year(), month: moment(endDate).month()+1, day: moment(endDate).date()};
      const academicYear: AcademicYear = {id, name, startDate,startDate2,endDate,endDate2,canDelete:true,semesters:[]};
      const currentAcademicYear = academicYear;
      const currentAcademicYearId = academicYear.id;
      
      const state = addAcademicYear(academicYear,oldState);
      return {
        ...state,
        currentAcademicYear,
        currentAcademicYearId
      }
    }),
    on(fromInstitution.Actions.AcademicYearAdded, (oldState, {academicYear})=>{
      const state = addAcademicYear(academicYear,oldState);
      return{
        ...state
      }
    }),
    on(fromInstitution.Actions.DeleteAcademicYear, (oldState, {academicYearId})=>{
      const state = deleteAcademicYear(academicYearId, oldState);
      return {
        ...state
      }
    }),
    on(fromInstitution.Actions.AcademicYearDeleted, (oldState, {academicYear})=>{
      const state = deleteAcademicYear(academicYear.id, oldState);
      return {
        ...state
      }
    }),
    on(fromInstitution.Actions.SemesterUpdated, (oldState, {semester})=>{
      const state = updateSemester(semester, oldState);
      return{
        ...state
      }
    }),
    on(fromInstitution.Actions.UpdateSemester, (oldState, {command})=>{
      const {id, startDate, endDate, code} = command;
      const academicYearId = oldState.currentAcademicYearId;
      const startDate2:DateParts = {year: moment(startDate).year(), month:moment(startDate).month(), day:moment(startDate).date()};
      const endDate2:DateParts = {year: moment(endDate).year(), month:moment(endDate).month(), day:moment(endDate).date()};
      const semester:Semester = {id,academicYearId, startDate,startDate2,endDate,endDate2,code,canDelete:true, sessions:[]};
      const state = updateSemester(semester, oldState);
      return {
        ...state
      }
    }),
    on(fromInstitution.Actions.SemesterAdded, (oldState, {semester})=>{
      const state = addSemester(semester, oldState);
      return{
        ...state
      }
    }),
    on(fromInstitution.Actions.AddSemester, (oldState, {command})=>{
      const {id, startDate, endDate, code, academicYearId} = command;
      const startDate2: DateParts = {year: moment(startDate).year(), month: moment(startDate).month(), day: moment(startDate).date()};
      const endDate2: DateParts = {year: moment(endDate).year(), month: moment(endDate).month(), day: moment(endDate).date()};
      const semester:Semester = {id, startDate,startDate2,endDate,endDate2,code,academicYearId, canDelete: true, sessions:[]};
      const state = addSemester(semester,oldState);
      return{
        ...state
      }
    }),
    on(fromInstitution.Actions.DeleteSemester, (oldState, {semester})=>{
      const state = deleteSemester(semester, oldState);
      return{
        ...state
      }
    }),
    on(fromInstitution.Actions.SemesterDeleted, (oldState, {semester})=>{
      const state = deleteSemester(semester, oldState);
      return{
        ...state
      }
    }),
    on(fromInstitution.Actions.SessionUpdated, (oldState, {session})=>{
      const state = updateSession(session, oldState);
      return {
        ...state
      }
    }),
    on(fromInstitution.Actions.UpdateSession, (oldState, {command})=>{
      const institutionId = oldState.institution.id;
      const academicYearId = oldState.currentAcademicYearId;
      const {id, semesterId, code, startDate, endDate, registrationStartDate, registrationDeadlineDate, dropDeadlineDate, withdrawalDeadlineDate, feeEarnedDate} = command;
      const startDate2: DateParts = {year: moment(startDate).year(), month: moment(startDate).month(), day: moment(startDate).date()};
      const endDate2: DateParts = {year: moment(endDate).year(), month: moment(endDate).month(), day: moment(endDate).date()};
      const registrationStartDate2: DateParts = {year: moment(registrationStartDate).year(), month: moment(registrationStartDate).month(), day: moment(registrationStartDate).date()};
      const registrationDeadlineDate2: DateParts = {year: moment(registrationDeadlineDate).year(), month: moment(registrationDeadlineDate).month(), day: moment(registrationDeadlineDate).date()};
      const dropDeadlineDate2: DateParts = {year: moment(dropDeadlineDate).year(), month: moment(dropDeadlineDate).month(), day: moment(dropDeadlineDate).date()};
      const withdrawalDeadlineDate2: DateParts = {year: moment(withdrawalDeadlineDate).year(), month: moment(withdrawalDeadlineDate).month(), day: moment(withdrawalDeadlineDate).date()};
      const feeEarnedDate2: DateParts = {year: moment(feeEarnedDate).year(), month: moment(feeEarnedDate).month(), day: moment(feeEarnedDate).date()};
      const session: Session = {id,institutionId, academicYearId,semesterId, code, startDate, startDate2,endDate,endDate2, 
        registrationStartDate, registrationStartDate2, registrationDeadlineDate,registrationDeadlineDate2, dropDeadlineDate, dropDeadlineDate2,
        withdrawalDeadlineDate,withdrawalDeadlineDate2, feeEarnedDate, feeEarnedDate2, canDelete: true, accordionFlag:false};
      
      const state = updateSession(session, oldState);
      return{
        ...state
      }
    }),
    on(fromInstitution.Actions.AddSession, (oldState, {command})=>{
      const institutionId = oldState.institution.id;
      const {id, academicYearId, semesterId, code, startDate, endDate, registrationStartDate, registrationDeadlineDate, dropDeadlineDate, withdrawalDeadlineDate, feeEarnedDate} = command;
      const startDate2: DateParts = {year: moment(startDate).year(), month: moment(startDate).month(), day: moment(startDate).date()};
      const endDate2: DateParts = {year: moment(endDate).year(), month: moment(endDate).month(), day: moment(endDate).date()};
      const registrationStartDate2: DateParts = {year: moment(registrationStartDate).year(), month: moment(registrationStartDate).month(), day: moment(registrationStartDate).date()};
      const registrationDeadlineDate2: DateParts = {year: moment(registrationDeadlineDate).year(), month: moment(registrationDeadlineDate).month(), day: moment(registrationDeadlineDate).date()};
      const dropDeadlineDate2: DateParts = {year: moment(dropDeadlineDate).year(), month: moment(dropDeadlineDate).month(), day: moment(dropDeadlineDate).date()};
      const withdrawalDeadlineDate2: DateParts = {year: moment(withdrawalDeadlineDate).year(), month: moment(withdrawalDeadlineDate).month(), day: moment(withdrawalDeadlineDate).date()};
      const feeEarnedDate2: DateParts = {year: moment(feeEarnedDate).year(), month: moment(feeEarnedDate).month(), day: moment(feeEarnedDate).date()};

      const session: Session = {id,institutionId, academicYearId,semesterId, code, startDate, startDate2,endDate,endDate2, 
          registrationStartDate, registrationStartDate2, registrationDeadlineDate,registrationDeadlineDate2, dropDeadlineDate, dropDeadlineDate2,
          withdrawalDeadlineDate,withdrawalDeadlineDate2, feeEarnedDate, feeEarnedDate2, canDelete: true, accordionFlag:false};
      
      const state = addSession(session, oldState);
      return{
        ...state
      }
    }),
    on(fromInstitution.Actions.SessionAdded, (oldState, {session})=>{
      const state = addSession(session, oldState);
      return {
        ...state
      }
    }),
    on(fromInstitution.Actions.DeleteSession, (oldState, {session})=>{
      const state = deleteSession(session,oldState);
      return{
        ...state
      }
    }),
    on(fromInstitution.Actions.SessionDeleted, (oldState, {session})=>{
      const state = deleteSession(session,oldState);
      return{
        ...state
      }
    })
    
);


