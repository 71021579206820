import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { Store, select } from "@ngrx/store";
import { first, finalize, filter, map, concatMap, withLatestFrom, tap } from "rxjs/operators";
import { IAppState } from 'app/store/state/app.state';
import * as fromInstitution from '@institution/store';
import { AcademicYear } from '@shared/calendar/models';
import { getInstitutionIdFromRoute } from 'app/helpers/routeParamHelper';
@Injectable()
export class CalendarResolver implements Resolve<AcademicYear[]>{
    isLoading = false;
    constructor(private store: Store<IAppState>){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
        const institutionId = getInstitutionIdFromRoute(route);
        return this.store 
            .pipe(
                select(fromInstitution.Selectors.CalendarForResolver),
                map((selector)=>{
                    const currentInstitutionId = selector.institutionId;
                    const calendarsLoaded = selector.calendarsLoaded;
                    return !calendarsLoaded || currentInstitutionId != institutionId;
                }),
                map((loadCalendars)=>{
                    if(!this.isLoading && loadCalendars){
                        this.isLoading = true;
                        this.store.dispatch(fromInstitution.Actions.LoadCalendar({institutionId}));
                        this.store.dispatch(fromInstitution.Actions.LoadSessionDateRules({institutionId}));
                    }
                    return true;
                }),
                filter(loadCalendars => loadCalendars),
                first(),
                finalize(()=>this.isLoading=false)
            )
    }
}