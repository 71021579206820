

import {createFeatureSelector, createSelector} from '@ngrx/store';
import { StudentsState } from '../reducers';
import { Status } from '@shared/common/models';
import { StudentListItem } from '@shared/student/models';
import { searchInText } from 'app/helpers/search-in-text';
import { sortBy } from 'sort-by-typescript';
import * as fromMember from '@member/store';
import * as fromRefData from '@refData';

export const selectStudentsState = createFeatureSelector<StudentsState>('member/students');

export const Students = createSelector(
    selectStudentsState,
    (state) => state.students
);
export const CurrentStudent = createSelector(
    selectStudentsState,
    (state) => state.currentStudent
)
export const CurrentStudentDetails = createSelector(
    selectStudentsState,
    (state) => state.currentStudentDetails
)
export const Enrollments = createSelector(
    selectStudentsState,
    (state) => state.enrollments
)

export const RequestedChanges = createSelector(
    Enrollments,
    (enrollments) => {
        var result = enrollments.filter(x=>x.requestedStatus != null);
        return result;
    }
)

export const IsSubmittingRequestChanges = createSelector(
    selectStudentsState,
    ({isSubmittingChanges}) => isSubmittingChanges
)
export const CanSubmitChanges = createSelector(
    RequestedChanges,
    IsSubmittingRequestChanges,
    (requestedChanges, isSubmittingChanges) => requestedChanges.length>0 && !isSubmittingChanges
)

export const RequestedChangesForEffects = createSelector(
    RequestedChanges,
    fromMember.Selectors.MemberId,
    (requestedChanges, memberId) => {
        return {
            memberId,
            command: {
                commands: requestedChanges.map(x=>{
                return {
                    enrollmentId: x.enrollmentId,
                    requestedStatus: x.requestedStatus,
                    fromMember: true,
                    fromProvider: false
                }
            })}
        }
    }
)

export const CurrentStudentForEffects = createSelector(
    fromMember.Selectors.Member,
    selectStudentsState,
    (member,state) =>  {
        return {
            currentStudent: state.currentStudent, 
            memberId: member.id, 
            institutionId: member.institutionId
        }
    }
)
export const StatusFilters = createSelector(
    selectStudentsState,
    (state) => state.statusFilters
)

export const SearchText = createSelector(
    selectStudentsState,
    (state) => state.searchText
)


export const CurrentSort = createSelector(
    selectStudentsState,
    (state) => state.currentSort
)
export const CurrentOrder = createSelector(
    selectStudentsState,
    (state) => state.currentOrder
)

export const FilteredStudents = createSelector(
    Students,
    StatusFilters,
    SearchText,
    CurrentSort, 
    CurrentOrder,
    (students, statusFilters, searchText, currentSort, currentOrder) => {
        const selectedFilters = [...statusFilters.filter(x=>x.selected===true)];
        let result = new Array<StudentListItem>();
        if(selectedFilters.length===0) 
            result = [...students];
        else{
            selectedFilters.forEach(filter =>{
                switch(filter.id){
                    case Status.Active:
                        result.push(...students.filter(x=>x.isActive));
                        break;
                    case Status.Inactive:
                        result.push(...students.filter(x=>!x.isActive));
                        break;
                }
            })
        }
        
        result = searchInText<StudentListItem>(searchText, result, [
            'firstName',
            'middleName',
            'lastName',
            'studentNumber',
            'emailAddress',
            'phoneNumber',
            'institutionName'
          ]);

        const sort = currentSort.value;
        const order = currentOrder.value;

        result = result.sort(sortBy(`${order}${sort}`));

        
        return result;
        
    }
);

export const SelectedStudents = createSelector(
    FilteredStudents,
    (students) => {
        var result = students.filter(x=>x.selected===true); 
        return result;
    }
)

export const CanExportToExcel = createSelector(
    SelectedStudents,
    (selected) => selected.length > 0
);

export const SelectedStatus = createSelector(
    FilteredStudents,
    (filtered) => `${filtered.filter(x=>x.selected).length} of ${filtered.length}`
)

export const IsAllSelected = createSelector(
    FilteredStudents,
    (filtered) => filtered.length==0 ? false : filtered.every(x=>x.selected)
);

export const IndeterminateSelected = createSelector(
    IsAllSelected,
    FilteredStudents,
    (isAllSelected, students) => isAllSelected ? false : students.some(x=>x.selected)
)

export const InstitutionId = createSelector(
    selectStudentsState,
    (state) => state.institutionId
);

export const AddStudentForEffects = createSelector(
    fromRefData.genders,
    fromRefData.regions,
    fromRefData.countries,
    InstitutionId,
    (genders,regions,countries, institutionId) => {
        return {
            genders,
            regions,
            countries,
            institutionId
        }
    }
)

export const Sorts = createSelector(
    selectStudentsState,
    (state) => state.sorts
)
export const Orders = createSelector(
    selectStudentsState,
    (state) => state.orders
)





export const StatusFiltersWithCounts = createSelector(
    Students,
    StatusFilters,
    (students, statusFilters) => statusFilters.map(x=>{
        return {
            ...x,
            description: `${x.description} (${students.filter(student => x.id==Status.Active ? student.isActive : !student.isActive).length})`
        }
    })
)
export const SelectedStatusFilters = createSelector(
    StatusFilters,
    (statusFilters) => statusFilters.filter(x=>x.selected)
)

export const StatusFiltersCount = createSelector(
    SelectedStatusFilters,
    (statusFilters) => statusFilters.length > 0 ? statusFilters.length : null
);
export const IsStudentsLoading = createSelector(
    selectStudentsState,
    (state) => state.isStudentsLoading
)