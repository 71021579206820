import {Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'elb-page-list-main-header',
  templateUrl: './page-list-main-header.component.html',
  styleUrls: ['./page-list-main-header.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PageListMainHeaderComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }
}
