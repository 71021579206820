import {
    createReducer,
    on
  } from '@ngrx/store';
import * as fromCourseExchangeUsers from '@courseExchange/users/store';
import * as fromCourseExchangeInstitutions from '@courseExchange/institution/store';
import * as fromUserList from '@shared/user/components/user-list';
import { UserListItemForView } from '@shared/user/models';
import { User } from '@shared/user/models';
import { CourseExchangeInstitutionListItem } from '@shared/courseExchange/models';
import { InstitutionSummary } from '@shared/institution/models';
 
  export interface CourseExchangeUsersState {
      users: UserListItemForView[];
      isLoadingUsers: boolean;
      currentUser: User;
      currentInstitutionId: string;
      isLoadingUser: boolean;
      institutions: InstitutionSummary[];
      courseExchangeId: string;
  };
  
  export const initialCourseExchangeUsersState: CourseExchangeUsersState = {
      users: [],
      isLoadingUsers: false,
      currentUser: undefined,
      currentInstitutionId: undefined,
      isLoadingUser: false,
      institutions: new Array<InstitutionSummary>(),
      courseExchangeId: ''
  };
  
  export const courseExchangeUsersReducer = createReducer(
    initialCourseExchangeUsersState,
    on(fromCourseExchangeUsers.Actions.LoadUsers, (state, {courseExchangeId})=>{
        return{
            ...state,
            isLoadingUsers: true,
            courseExchangeId
        }
    }),
    on(fromCourseExchangeUsers.Actions.LoadUsersSuccess, (state, action)=>{
        const users = action.users.map(user=>{
            return{
                ...user,
                selected: false,
                accordianFlag: false
            }
        })
        return {
            ...state,
            users,
            isLoadingUsers: false
        };
    }),
    on(fromCourseExchangeInstitutions.Actions.LoadCourseExchangeInstitutionsSuccess, (state, action)=>{
        const institutions = action.institutions.filter(x=>x.isMember || x.isProvider).map(item =>{
            var result: InstitutionSummary = {
                id: item.institutionId,
                institutionName: item.institutionName,
                institutionLogoUrl: item.institutionLogo
            };
            return result;
        })
        return {
            ...state,
            institutions
        }
    }),
    on(fromCourseExchangeUsers.Actions.SetInstitutionId, (state, action)=>{
        const currentInstitutionId = action.institutionId;
        return {
            ...state,
            currentInstitutionId
        }
    }),
    on(fromCourseExchangeUsers.Actions.UserListItemAdded, (state, {userListItem})=>{
        const idx = state.users.findIndex(x=>x.id===userListItem.id);
        if(idx !=-1) return state;
        const userListItemForView = {...userListItem, selected: false, accordianFlag: false};
        const users = [...state.users, userListItemForView];

        return {
            ...state,
            users
        }
    }),
    on(fromCourseExchangeUsers.Actions.UserListItemUpdated, (state, {userListItem})=>{
        const idx = state.users.findIndex(x=>x.id==userListItem.id);
        if(idx===-1) return state;
        const userListItemForView = {...userListItem, selected: false, accordianFlag: false};
        const users = [...state.users.slice(0,idx), userListItemForView, ...state.users.slice(idx+1)];

        return {
            ...state,
            users
        }
    }),
    on(fromCourseExchangeUsers.Actions.AccessRoleAdded, (state, {accessRole})=>{
        if(state.courseExchangeId != accessRole.courseExchangeId) return state;
        const idx = state.users.findIndex(x=>x.id==accessRole.userId);
        if(idx===-1) return state;
        const accessRoleIdx = state.users[idx].accessRoles.findIndex(x=>x.id==accessRole.id);
        if(accessRoleIdx!=-1) return state;
        const accessRoles = [...state.users[idx].accessRoles, accessRole];
        const user = {...state.users[idx], accessRoles};
        const users = [...state.users.slice(0,idx), user , ...state.users.slice(idx+1)];
        return{
            ...state,
            users
        }
      }),
      on(fromCourseExchangeUsers.Actions.AccessRoleUpdated, (state, {accessRole})=>{
        if(state.courseExchangeId != accessRole.courseExchangeId) return state;
        const userIdx = state.users.findIndex(x=>x.id==accessRole.userId);
        if(userIdx===-1) return state;
        const accessRoleIdx = state.users[userIdx].accessRoles.findIndex(x=>x.id==accessRole.id);
        const accessRoles = [...state.users[userIdx].accessRoles.slice(0,accessRoleIdx),accessRole, ...state.users[userIdx].accessRoles.slice(accessRoleIdx+1)];
        const user = {...state.users[userIdx], accessRoles};
        const users = [...state.users.slice(0,userIdx), user , ...state.users.slice(userIdx+1)];
        return{
            ...state,
            users
        }
      }),
      on(fromCourseExchangeUsers.Actions.AccessRoleDeleted, (state, {accessRole})=>{
        if(state.courseExchangeId != accessRole.courseExchangeId) return state;
        const userIdx = state.users.findIndex(x=>x.id==accessRole.userId);
        if(userIdx===-1) return state;
        const accessRoles = [...state.users[userIdx].accessRoles.filter(x=>x.id!=accessRole.id)];
        const user = {...state.users[userIdx], accessRoles};
        const users = [...state.users.slice(0,userIdx), user , ...state.users.slice(userIdx+1)];
        return{
            ...state,
            users
        }
      })
);
  
  
  