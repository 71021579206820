import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { Store, select } from "@ngrx/store";
import { tap, first, finalize, filter, map, concatMap, withLatestFrom } from "rxjs/operators";
import { IAppState } from 'app/store/state/app.state';
import { CourseExchange, CourseExchangeFeeListItem } from 'app/shared/courseExchange/models';
import * as fromCourseExchange from '@courseExchange/store';
import { getCourseExchangeIdFromRoute } from 'app/helpers/routeParamHelper';
import { Fees } from "@member/store/actions/member.navigate";

@Injectable()
export class CourseExchangeFeesResolver implements Resolve<CourseExchangeFeeListItem[]>{
    isLoading = false;
    constructor(private store: Store<IAppState>){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
        let courseExchangeId = getCourseExchangeIdFromRoute(route);
        return this.store 
            .pipe(
                select(fromCourseExchange.Selectors.Fees),
                concatMap(action => of(action).pipe(
                    withLatestFrom(this.store.pipe(select(fromCourseExchange.Selectors.CourseExchangeSummary)))
                )),
                map(([fees, summary]) => {
                    if(courseExchangeId ==='' && !!summary) courseExchangeId=summary.id;
                    var courseExchangeFeesLoaded = !!fees && fees.length>0;
                    if(courseExchangeFeesLoaded)this.store.dispatch(fromCourseExchange.Actions.LoadFeesSuccess({fees}));
                    return courseExchangeFeesLoaded && !!summary && summary.id===courseExchangeId;
                }), 
                map((feesLoaded)=>{
                    if(!this.isLoading && !feesLoaded){
                        this.isLoading = true;
                        this.store.dispatch(fromCourseExchange.Actions.LoadFees({courseExchangeId}));
                    }
                    return true;
                }),
                filter(feesLoaded => feesLoaded),
                first(),
                finalize(()=>this.isLoading=false)
            )
    }
}