import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'de-select-course-dialog-confirm',
  templateUrl: './de-select-course-dialog-confirm.component.html',
  styleUrls: ['./de-select-course-dialog-confirm.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DeSelectCourseDialogConfirmComponent {
  constructor(
    public matDialogRef: MatDialogRef<DeSelectCourseDialogConfirmComponent>,
  ) {
    this.matDialogRef.addPanelClass('de-select-course-dialog-confirm');
  }

  onConfirm(): void {
    this.matDialogRef.close(true);
  }

  onCancel(): void {
    this.matDialogRef.close(false);
  }
}
