import {ICalendarState, initialCalendarState,} from '../state/calendar.state';
import {Action, createReducer, on} from '@ngrx/store';
import {Actions} from '../actions';
import {addNewSemester} from './helpers/add-new-semester';
import {addNewSession} from './helpers/add-new-session';
import {AcademicYear, Semester, Session} from '../../models';
import convertAcademicYearDates from '@shared/common/helpers/convertAcademicYearsDates';

const _courseCalendarReducer = createReducer(
  initialCalendarState,
  on(Actions.loadedCalendarSuccess, (state, action) => {
    const parentId = action.parentId;
    const academicYears = convertAcademicYearDates(action.academicYears);
    const lngth = academicYears.length;
    const selectedAcademicYearId = academicYears.length > 0 ? academicYears[0].id : state.selectedAcademicYearId;
    
    return {
      ...state,
      calendar: academicYears,
      loadedCourseCalendarsError: false,
      selectedAcademicYearId,
      parentId
    };
  }),
  on(Actions.loadedCalendarError, state => {
    return {
      ...state,
      loadedCalendarsError: true
    };
  }),
  on(Actions.addedNewAcademicYearSuccess, (state, {payload}) => {
    const newAcademicYear = payload;

    const selectedAcademicYearId = state.selectedAcademicYearId === 'none' ? newAcademicYear.id : state.selectedAcademicYearId;

    return {
      ...state,
      calendar: [
        ...state.calendar.slice(),
        newAcademicYear
      ],
      selectedAcademicYearId
    };
  }),
  on(Actions.duplicateAcademicYear, (state, {payload}) => {
    return {
      ...state,
      calendar: [
        ...state.calendar.slice(),
        payload
      ]
    };
  }),
  on(Actions.updatedAcademicYearSuccess, (state, {payload}) => {
    const newCourseCalendar = state.calendar.map((academicYear: AcademicYear) => {
      if (academicYear.id === payload.id) {
        return payload;
      }

      return academicYear;
    });

    return {
      ...state,
      calendar: newCourseCalendar
    };
  }),
  on(Actions.addedNewSemesterSuccess, (state, {payload}) => {
    return addNewSemester({
      state, newSemester: payload
    });
  }),
  on(Actions.duplicateSemesterSuccess, (state, {payload}) => {
    return addNewSemester({
      state, newSemester: payload
    });
  }),
  on(Actions.updatedSemesterSuccess, (state, {payload}) => {
    const {academicYearId, id} = payload;
    
    const newCourseCalendar = state.calendar.map((academicYear: AcademicYear) => {
      if (academicYear.id === academicYearId) {
        const newSemesters = academicYear.semesters.map((semester: Semester) => {
          if (semester.id === id) {
            return payload;
          }

          return semester;
        });

        return {
          ...academicYear,
          semesters: newSemesters
        };
      }

      return academicYear;
    });

    return {
      ...state,
      calendar: newCourseCalendar
    };
  }),
  on(Actions.changeCurrentAcademicYear, (state, {payload}) => {
    return {
      ...state,
      selectedAcademicYearId: payload
    };
  }),
  on(Actions.setParentIdForCalendar, (state, {payload}) => {
    return {
      ...state,
      parentId: payload
    };
  }),
  on(Actions.setShowSessions, (state, {showSessions}) => {
    return {
      ...state,
      showSessions
    };
  }),
  on(Actions.loadedSessionDateRulesSuccess, (state, {payload}) => {
    return {
      ...state,
      sessionDateRules: payload
    };
  })
);

export function courseCalendarReducer(state: ICalendarState, action: Action): ICalendarState {
  return _courseCalendarReducer(state, action);
}
