<div class="course-schedule-dialog-copy">
  <ng-container *ngIf="isActiveFirstPart">
    <div class="title">Select Academic Year and Semester you would like to copy:</div>

    <form class="form" [formGroup]="formFrom">
      <div class="field">
        <mat-form-field appearance="outline" fxFill>
          <mat-label>Select Academic Year</mat-label>
          <mat-select (selectionChange)="onChangeSelectedFromAcademicYear($event)" formControlName="fromAcademicYearId">
            <mat-option *ngFor="let academicYear of academicYears"
                        [value]="academicYear.id">
              {{academicYear.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="field">
        <mat-form-field appearance="outline" fxFill>
          <mat-label>Select Semester</mat-label>
          <mat-select
            (selectionChange)="onChangeSelectedFromSemester($event)"
            formControlName="fromSemesterId"
            [disabled]="!selectedFromAcademicYear || !selectedFromAcademicYear.semesters.length">
            <ng-container *ngIf="selectedFromAcademicYear">
              <mat-option *ngFor="let semester of selectedFromAcademicYear.semesters"
                          [value]="semester.id">
                {{semester.code}}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="submit">
        <button mat-raised-button
                [disabled]="!canTransformToSecondPart"
                (click)="transformToSecondPart()">
          <mat-icon>arrow_forward</mat-icon>
          Next
        </button>
      </div>
    </form>
  </ng-container>

  <ng-container *ngIf="!isActiveFirstPart">
    <div class="title">Select Academic Year and Semester to copy to:</div>

    <form class="form" [formGroup]="formTo">
      <div class="field">
        <mat-form-field appearance="outline" fxFill>
          <mat-label>Select Academic Year</mat-label>
          <mat-select (selectionChange)="onChangeSelectedToAcademicYear($event)" formControlName="toAcademicYearId">
            <mat-option *ngFor="let academicYear of academicYears"
                        [value]="academicYear.id">
              {{academicYear.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <div class="field">
        <mat-form-field appearance="outline" fxFill>
          <mat-label>Select Semester</mat-label>
          <mat-select
            (selectionChange)="onChangeSelectedToSemester($event)"
            formControlName="toSemesterId"
            [disabled]="!selectedToAcademicYear || !selectedToAcademicYear.semesters.length">
            <ng-container *ngIf="selectedToAcademicYear">
              <mat-option *ngFor="let semester of selectedToAcademicYear.semesters"
                          [value]="semester.id">
                {{semester.code}}
              </mat-option>
            </ng-container>
          </mat-select>
          <mat-error *ngIf="hasErrorFormTo('toSemesterId', 'sameSemester')">You chose the same semester</mat-error>
        </mat-form-field>
      </div>

      <div class="submit">
        <button mat-raised-button
                [disabled]="!canSubmit"
                (click)="onSubmit()">
          <mat-icon>arrow_forward</mat-icon>
          Next
        </button>
      </div>
    </form>
  </ng-container>
</div>
