<div class="wizard-step wizard-contacts-step">
  <ng-container>
    <div class="title">
      Add New User: Accounting
    </div>

    <div class="description">Each institution must have primary contacts for accounting, president, and provost or vice president.
    </div>

    <form class="form" [formGroup]="form" (keydown)="onKeyDownForm($event)">
      <div class="fields">
        <div class="field">
          <mat-form-field appearance="outline" class="mt-8" fxFill>
            <mat-label>Dept</mat-label>
            <input
              matInput
              name="department"
              required
              formControlName="department">
            <mat-error *ngIf="hasError('department', 'required')">This field is required</mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field appearance="outline" class="mt-8" fxFill>
            <mat-label>Title</mat-label>
            <input
              matInput
              name="title"
              formControlName="title">
            <mat-error *ngIf="hasError('title', 'required')">This field is required</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="fields">
        <div class="field">
          <mat-form-field appearance="outline" class="mt-8" fxFill>
            <mat-label>First Name</mat-label>
            <input
              matInput
              name="firstName"
              required
              formControlName="firstName">
            <mat-error *ngIf="hasError('firstName', 'required')">This field is required</mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field appearance="outline" class="mt-8" fxFill>
            <mat-label>Last Name</mat-label>
            <input
              matInput
              name="lastName"
              required
              formControlName="lastName">
            <mat-error *ngIf="hasError('lastName', 'required')">This field is required</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="fields">
        <div class="field">
          <mat-form-field appearance="outline" class="mt-8" fxFill>
            <mat-label>Phone</mat-label>
            <input
              matInput
              name="phoneNumber"
              required
              formControlName="phoneNumber">
            <mat-error *ngIf="hasError('phoneNumber', 'required')">This field is required</mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field appearance="outline" class="mt-8" fxFill>
            <mat-label>Email</mat-label>
            <input
              matInput
              name="emailAddress"
              uniqueEmail
              [userId]="getUserId()"
              [initialValue]="getInitialEmail()"
              required
              formControlName="emailAddress">
            <mat-error *ngIf="hasError('emailAddress', 'required')">This field is required</mat-error>
            <mat-error *ngIf="hasError('emailAddress', 'emailExist')">Email already exists</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="form-actions-continue">
        <span class="skip" (click)="skip()">skip this step</span>
      </div>

      <div class="form-actions">
        <button mat-stroked-button
                (click)="goBack()">
          Back
        </button>

        <button mat-flat-button
                (click)="onSubmit()"
                [disabled]="!form.valid"
                type="submit">
          Next
        </button>
      </div>
    </form>
  </ng-container>

  <ng-template #loading>
    ...loading
  </ng-template>
</div>
