import {Injectable} from '@angular/core';
import {map, withLatestFrom} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import {IAppState} from 'app/store/state/app.state';
import * as fromEnrollment from '@member/enrollment/store';
import * as fromInstitution from '@institution/store';
import * as moment from 'moment';
import { RealTimeService } from '@core/services/realTime.service';
import { AcademicYear } from '@shared/calendar/models';
import { HubConnection } from '@microsoft/signalr';
import { EnrollmentListItem } from '@member/enrollment';

@Injectable()
export class EnrollmentRealtime {
  private currentAcademicYear$:Observable<AcademicYear>;
  constructor(private store: Store<IAppState>, private realTimeService: RealTimeService) {
    this.initSignalRMessages();
    this.currentAcademicYear$ = this.store.pipe(select(fromInstitution.Selectors.CurrentAcademicYear));
  }
  
  private initSignalRMessages(){
    this.realTimeService.hubConnection$.subscribe(hubConnection=>{
      if(!hubConnection) return;
      this.enrollmentListItemUpdated(hubConnection);
     this.enrollmentListItemAdded(hubConnection);
    });
  }

  private enrollmentListItemAdded(hubConnection: HubConnection){
    hubConnection.on('MemberEnrollmentListItemAdded', (enrollment: EnrollmentListItem)=>{
      of(enrollment)
      .pipe(
        withLatestFrom(this.currentAcademicYear$),
        map(([enrollment, academicYear])=>{
          if(moment(enrollment.sessionStartDate).isBetween(academicYear.startDate,academicYear.endDate,undefined,"[]")){
            this.store.dispatch(fromEnrollment.Actions.EnrollmentListItemAdded({enrollment}));
          }
        })
      ).subscribe();
    });
  }
  private enrollmentListItemUpdated(hubConnection:HubConnection){
    hubConnection.on('MemberEnrollmentListItemUpdated', (enrollment: EnrollmentListItem)=>{
      this.store.dispatch(fromEnrollment.Actions.EnrollmentListItemUpdated({enrollment}));
    })
  }
 }
