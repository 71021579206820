import { createReducer, on } from '@ngrx/store';
import * as fromMemberBilling from '@member/billing/store';
import * as fromMember from '@member/store'
import { MemberMonthlyBill } from '@member/billing/models/memberMonthlyBill';

  export interface MemberBillingState {
    memberId: string;
    monthlyStatements: Array<MemberMonthlyBill>;
    isMonthlyStatementsLoading: boolean;
    areMonthlyStatementsLoaded: boolean;
    currentMemberMonthlyBill: MemberMonthlyBill;
    isMemberMonthlyBillLoading: boolean;
  };
  
  export const initialMemberBillingState: MemberBillingState = {
    memberId: '',
    monthlyStatements: [],
    isMonthlyStatementsLoading: false,
    areMonthlyStatementsLoaded: false,
    currentMemberMonthlyBill: undefined,
    isMemberMonthlyBillLoading: false
  };
  
  export const memberBillingReducer = createReducer(
    initialMemberBillingState,
    on(fromMember.Actions.LoadMemberSuccess, (state, {member})=>{
        return{
            ...initialMemberBillingState,
            memberId: member.id
        }
    }),
    on(fromMemberBilling.Actions.LoadMonthlyStatementsSuccess, (state, {monthlyStatements})=>{
        const currentMemberMonthlyBill = !!monthlyStatements[0] ? monthlyStatements[0]: undefined;
        return {
            ...state,
            monthlyStatements,
            currentMemberMonthlyBill,
            areMonthlyStatementsLoaded: true,
            isMonthlyStatementsLoading: false
        }
    }),
    on(fromMemberBilling.Actions.LoadMonthlyStatementsError, (state, action)=>{
        return {
            ...state,
            monthlyStatements: [],
            currentMemberMonthlyBill: undefined,
            areMonthlyStatementsLoaded: false,
            isMonthlyStatementsLoading: false
        }
    }),
    on(fromMemberBilling.Actions.MemberMonthlyBillUpdated, (state, {memberMonthlyBill})=>{
      const idx = state.monthlyStatements.findIndex(x=>x.id==memberMonthlyBill.id);
      if(idx===-1) return state;
      const monthlyStatements = [...state.monthlyStatements.slice(0,idx),memberMonthlyBill,...state.monthlyStatements.slice(idx+1)];
      return{
        ...state,
        monthlyStatements
      }
    }),
    on(fromMemberBilling.Actions.MemberMonthlyBillUpdated, (state, {memberMonthlyBill})=>{
      if(!!state.currentMemberMonthlyBill && state.currentMemberMonthlyBill.id != memberMonthlyBill.id) return state;
      const currentMemberMonthlyBill = state.currentMemberMonthlyBill;
      return{
        ...state,
        currentMemberMonthlyBill
      }
    }),
    on(fromMemberBilling.Actions.MemberMonthlyBillAdded, (state, {memberMonthlyBill})=>{
      const idx = state.monthlyStatements.findIndex(x=>x.id==memberMonthlyBill.id);
      let monthlyStatements =[];
      if(idx===-1){
        monthlyStatements = [...state.monthlyStatements,memberMonthlyBill];
      }else{
        monthlyStatements = [...state.monthlyStatements.slice(0,idx),memberMonthlyBill,...state.monthlyStatements.slice(idx+1)];
      }
      return{
        ...state,
        monthlyStatements
      }
    }),
  );
  
  
  