import { createEffect, Actions, ofType } from "@ngrx/effects";
import { Injectable } from "@angular/core";
import { map, switchMap, withLatestFrom, concatMap, tap } from "rxjs/operators";
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { IAppState } from 'app/store/state/app.state';
import { Router } from '@angular/router';
import * as fromProvider from '@provider/store';
import * as fromProviderBilling from '@provider/billing/store';

@Injectable()
export class ProviderBillingNavigateEffects{
    constructor(private actions$: Actions, private store: Store<IAppState>,
        private router: Router){
    }

    ProviderBillingSummary$ = createEffect(() => {
        return this.actions$.pipe(
          ofType(fromProviderBilling.Navigate.ProviderBillingSummary),
          concatMap(action => of(action).pipe(
            withLatestFrom(this.store.pipe(select(fromProvider.Selectors.Provider)))
          )),
          map(([action, provider]) => {
            const providerId = !!action.providerId ? action.providerId : provider.id;
            const institutionId = !!action.providerId ? action.providerId : provider.institutionId;
    
            return this.router.navigate(['provider',
              providerId,
              institutionId,
              'billing',
              'summary']);
          }))
      }, { dispatch: false });
}