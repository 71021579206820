import { EnrollmentListItemForView } from '@member/enrollment/models/enrollmentListItem.viewModel';
import { Sort, Order } from '@core/models';
import { Filter, FilterType } from '@shared/common/models';
import { on, createReducer } from '@ngrx/store';
import toggleItem from '@shared/common/helpers/toggleItem';
import * as fromAuth from '@auth/store';
import * as fromEnrollment from '@member/enrollment/store';
import * as fromRefData from '@refData';
import convertEnrollmentDates from '@shared/common/helpers/convertEnrollmentDates';
import convertEnrollmentsDates from '@shared/common/helpers/convertEnrollmentsDates';

export interface EnrollmentsState {
  memberId: string;
  enrollments: EnrollmentListItemForView[];
  isEnrollmentsLoading: boolean;
  isCreateEnrollmentSending: boolean;
  sorts: Sort[],
  currentSort: Sort,
  orders: Order[],
  currentOrder: Order,
  statusFilters: Filter[],
  courseLevelFilters: Filter[],
  providerFilters: Filter[],
  searchText: string;
  isSubmittingChanges: boolean;
  tagFilters: Filter[],
  categoryFilters: Filter[],
  subCategoryFilters: Filter[],
  startDate: Date,
  endDate: Date
}

export const initialEnrollmentsState: EnrollmentsState = {
  memberId: undefined,
  enrollments: new Array<EnrollmentListItemForView>(),
  isEnrollmentsLoading: false,
  isCreateEnrollmentSending: false,
  sorts: new Array<Sort>(),
  currentSort: new Sort(),
  orders: new Array<Order>(),
  currentOrder: new Order(),
  statusFilters: new Array<Filter>(),
  courseLevelFilters: new Array<Filter>(),
  providerFilters: new Array<Filter>(),
  searchText: '',
  isSubmittingChanges: false,
  tagFilters: [],
  categoryFilters: [],
  subCategoryFilters: [],
  startDate: undefined,
  endDate: undefined
};
export const enrollmentsReducer = createReducer(
  initialEnrollmentsState,
  on(fromAuth.Actions.selectCourseExchangeId, (state, action) => {
    return initialEnrollmentsState
  }),
  on(fromEnrollment.Navigate.Enrollments, (state, action) => {
    const searchText = '';
    const statusFilters = state.statusFilters.map((filter) => { return { ...filter, selected: false } });
    return {
      ...state,
      searchText,
      statusFilters
    }
  }),
  on(fromEnrollment.Actions.LoadEnrollmentByDateRange, (state, {memberId, startDate,endDate}) => {
    return {
      ...state,
      isEnrollmentsLoading: true,
      memberId,
      startDate,
      endDate
    }
  }),
  on(fromEnrollment.Actions.LoadActiveEnrollmentsSuccess, (state, action) => {
    const enrollments = convertEnrollmentsDates(action.enrollments);
    return {
      ...state,
      enrollments,
      isEnrollmentsLoading: false
    }
  }),
  on(fromEnrollment.Actions.LoadActiveEnrollmentsError, (state) => {
    return {
      ...state,
      isEnrollmentsLoading: false
    }
  }),
  on(fromEnrollment.Actions.EnrollmentListItemUpdated, (state, action) => {
    const item = convertEnrollmentDates(action.enrollment);
    var idx = state.enrollments.findIndex(x => x.id === item.id);
    const enrollmentForView = { ...item, selected: false, requestedStatus: null, requestedLetterGrade: null, letterGradeDescription: !!item.letterGrade ? item.letterGrade.description : "zz" };
    const enrollments = [...state.enrollments.slice(0, idx), enrollmentForView, ...state.enrollments.slice(idx + 1)];

    return {
      ...state,
      enrollments
    };
  }),
  on(fromEnrollment.Actions.MasterToggled, (state, action) => {
    const enrollments = state.enrollments.map((enrollment)=>{
      const idx = action.enrollments.findIndex(x=>x.id===enrollment.id);
      if(idx===-1){
        return enrollment;
      }else{
        return action.enrollments[idx];
      }
    })

    return {
      ...state,
      enrollments
    };
  }),
  on(fromEnrollment.Actions.ChangeStatuses, (state, action) => {
    const commands = action.command.commands;
    const enrollmentStatuses = action.enrollmentStatuses;
    const enrollments = state.enrollments.map((enrollment => {
      const command = commands.find(x => x.enrollmentId === enrollment.enrollmentId);
      if (!command) return enrollment;
      var status = enrollmentStatuses.find(x => x.id === command.requestedStatus.id);
      return {
        ...enrollment,
        status,
        requestedStatus: null
      }
    }));

    return {
      ...state,
      enrollments
    }
  }),
  on(fromEnrollment.Actions.SubmitStatusChangesError, (state, action) => {
    return {
      ...state,
      isSubmittingChanges: false
    }
  }),
  on(fromEnrollment.Actions.CreateEnrollments, (state, action) => {
    return {
      ...state,
      isCreateEnrollmentSending: true
    };
  }),
  on(fromEnrollment.Actions.EnrollmentListItemAdded, (state, action) => {
    let item = convertEnrollmentDates(action.enrollment);
    if(state.memberId != item.memberId) return state;
    const idx = state.enrollments.findIndex(x => x.id === item.id);
    const enrollmentForView = { ...item, selected: false, requestedStatus: null, letterGradeDescription: !!item.letterGrade ? item.letterGrade.description : "zz" };
    let enrollments = new Array<EnrollmentListItemForView>();
    if(idx===-1){
      enrollments = [...state.enrollments, enrollmentForView];
    }else{
      enrollments = [...state.enrollments.slice(0, idx), enrollmentForView, ...state.enrollments.slice(idx + 1)];
    }
    return {
      ...state,
      enrollments
    };
  }),
  on(fromEnrollment.Actions.CreateEnrollmentSuccess, (state, action) => {
    return {
      ...state,
      isCreateEnrollmentSending: false
    };
  }),
  on(fromEnrollment.Actions.CreateEnrollmentError, (state, action) => {
    return {
      ...state,
      isCreateEnrollmentSending: false
    };
  }),
  on(fromEnrollment.Actions.EditStatus, (state, { enrollmentId, requestedStatus }) => {
    const idx = state.enrollments.findIndex(x => x.id === enrollmentId);
    const enrollment = { ...state.enrollments[idx], requestedStatus };
    const enrollments = [...state.enrollments.slice(0, idx), enrollment, ...state.enrollments.slice(idx + 1)];
    return {
      ...state,
      enrollments
    };
  }),
  on(fromEnrollment.Actions.LoadSorts, (state, { sorts }) => {
    const currentSort = sorts.find(x => x.isDefault);
    return {
      ...state,
      sorts,
      currentSort
    }
  }),
  on(fromEnrollment.Actions.ChangeSort, (state, { currentSort }) => {
    return {
      ...state,
      currentSort
    }
  }),
  on(fromEnrollment.Actions.LoadOrders, (state, { orders }) => {
    const currentOrder = orders.find(x => x.isDefault);
    return {
      ...state,
      orders,
      currentOrder
    }
  }),
  on(fromEnrollment.Actions.ChangeOrder, (state, { currentOrder }) => {
    return {
      ...state,
      currentOrder
    }
  }),
  on(fromEnrollment.Actions.ChangeSearchText, (state, { searchText }) => {
    return {
      ...state,
      searchText
    }
  }),
  on(fromEnrollment.Actions.ToggleEnrollment, (state, { enrollmentId }) => {
    const enrollments = toggleItem(state.enrollments, enrollmentId);
    return {
      ...state,
      enrollments
    }
  }),
  on(fromEnrollment.Actions.ToggleStatusFilter, (state, { id }) => {
    const statusFilters = toggleItem(state.statusFilters, id);
    return {
      ...state,
      statusFilters
    }
  }),
  on(fromRefData.Actions.LoadEnrollmentStatusesSuccess, (state, action) => {
    const statusFilters = action.enrollmentStatuses.map(x => {
      return {
        id: x.id,
        description: x.description,
        descriptionForView: x.description,
        type: FilterType.EnrollmentStatus,
        selected: false
      }
    });

    return {
      ...state,
      statusFilters
    }
  }),
  on(fromEnrollment.Actions.LoadProviderFilters, (state, { providerFilters }) => {
    return {
      ...state,
      providerFilters
    }
  }),

  on(fromEnrollment.Actions.ToggleProviderFilter, (state, { id }) => {
    const providerFilters = toggleItem(state.providerFilters, id);
    return {
      ...state,
      providerFilters
    }
  }),
  on(fromEnrollment.Actions.LoadTagFilters, (state, { tagFilters }) => {
    return {
      ...state,
      tagFilters
    }
  }),
  on(fromEnrollment.Actions.ToggleTagFilter, (state, { id }) => {
    const tagFilters = toggleItem(state.tagFilters, id);
    return {
      ...state,
      tagFilters
    }
  }),


  on(fromEnrollment.Actions.LoadCategoryFilters, (state, { categoryFilters }) => {
    return {
      ...state,
      categoryFilters
    }
  }),
  on(fromEnrollment.Actions.LoadSubCategoryFilters, (state, { subCategoryFilters }) => {
    return {
      ...state,
      subCategoryFilters
    }
  }),
  on(fromEnrollment.Actions.ToggleCategoryFilter, (state, { id }) => {
    const categoryFilters = toggleItem(state.categoryFilters, id);
    return {
      ...state,
      categoryFilters
    }
  }),
  on(fromEnrollment.Actions.ToggleSubCategoryFilter, (state, { id }) => {
    const subCategoryFilters = toggleItem(state.subCategoryFilters, id);
    return {
      ...state,
      subCategoryFilters
    }
  })
)
