import {createFeatureSelector, createSelector} from '@ngrx/store';
import {wizardStateFeatureKey} from '../feature-keys';
import { IWizardState } from '../reducers/wizard.reducers';
import { Checklist } from '@core/models';

const selectWizardState = createFeatureSelector<IWizardState>(wizardStateFeatureKey);

export const CurrentStepId = createSelector(
  selectWizardState,
  (state) => state.currentStepId
);

export const ProgressPercent = createSelector(
  selectWizardState,
  (state) => {
    if(!state.checklist) return 0; 
    
    let count = 0;
    let countCompleted = 0;

    state.checklist.items.forEach((item: Checklist) => {
      
      if (item && item.items.length) {
        count += item.items.length;

        countCompleted += item.items.reduce((prev: number, subChecklist: Checklist) => {
          return subChecklist.isComplete ? prev + 1 : prev;
        }, 0);
      } else if (item) {
        count++;

        if (item.isComplete) {
          countCompleted++;
        }
      }
    });

    return Math.ceil(countCompleted * (100 / count));
  }
)

export const CurrentChecklist = createSelector(
  selectWizardState,
  (state) => state.checklist
)

export const ChecklistComplete = createSelector(
  CurrentChecklist,
  (checklist) => checklist.isComplete
)
