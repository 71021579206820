import {createEffect, Actions, ofType} from "@ngrx/effects";
import {Injectable} from "@angular/core";
import {map, switchMap, withLatestFrom, concatMap, tap, catchError} from "rxjs/operators";
import {StudentService} from '@shared/student/services';
import {select, Store} from '@ngrx/store';
import {of} from 'rxjs';
import {IAppState} from 'app/store/state/app.state';
import {ExcelService} from 'app/core/services/excel.service';
import {
  StudentAddedDialogComponent,
  StudentAddedDialogData,
  StudentDeleteDialogComponent,
  StudentDeleteDialogData} from '@member/students/components';
import {MatDialog} from '@angular/material/dialog';
import * as fromCore from '@core/store';
import * as fromStudent from '@member/students';
import * as fromMember from '@member/store';
import * as fromEnrollment from '@member/enrollment/store';
import * as fromRefData from '@refData';
import {ENewEnrollmentTypeFrom} from '@member/enrollment/constants/shared';
import { MemberService } from "@shared/member/services";
import * as moment from "moment";
import { DateParts } from "@core/models/dateParts";
import { Student, StudentListItem } from "@shared/student/models";
import { Address } from "@core/models";

@Injectable()
export class StudentsEffects {
  constructor(private actions$: Actions, private excelService: ExcelService,
              private store: Store<IAppState>, private studentService: StudentService,
              private dialog: MatDialog, private memberService: MemberService) {
  }
  loadStudents$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromStudent.Actions.LoadStudents),
      switchMap((action) =>
        this.studentService.getStudentList(action.institutionId)),
      map((result) => {
        const students = result.map(x => {
          return {
            ...x,
            selected: false
          }
        });
        return fromStudent.Actions.LoadStudentsSuccess({students});
      }),
      catchError(() =>
        of(fromStudent.Actions.LoadStudentsError(
          {message: 'Problem retrieving Students'}
        )))
    )
  });

  loadStudentsError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromStudent.Actions.LoadStudentsError),
      /** An EMPTY observable only emits completion. Replace with your own observable stream */
      map(({message}) => fromCore.Actions.ShowMessage({message})));
  });

  loadStudent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromStudent.Actions.LoadStudent),
      concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromMember.Selectors.InstitutionId)))
      )),
      switchMap(([{studentId}, institutionId]) =>
        this.studentService.getStudentById(institutionId, studentId)),
      map((currentStudent) =>
        fromStudent.Actions.LoadStudentSuccess({currentStudent})),
      catchError(() =>
        of(fromStudent.Actions.LoadStudentError(
          {message: 'Problem retrieving Student'}
        )))
    );
  });

  loadStudentError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromStudent.Actions.LoadStudentError),
      /** An EMPTY observable only emits completion. Replace with your own observable stream */
      map(({message}) => fromCore.Actions.ShowMessage({message})));
  });

  loadStudentDetails$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromStudent.Actions.LoadStudentDetails),
      concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromMember.Selectors.InstitutionId)))
      )),
      switchMap(([{studentId}, institutionId]) =>
        this.studentService.getStudentDetailsById(institutionId, studentId)),
      map((currentStudentDetails) =>
        fromStudent.Actions.LoadStudentDetailsSuccess({currentStudentDetails})),
      catchError(() =>
        of(fromStudent.Actions.LoadStudentDetailsError(
          {message: 'Problem retrieving Student Details'}
        )))
    );
  });

  loadStudentDetailsError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromStudent.Actions.LoadStudentDetailsError),
      /** An EMPTY observable only emits completion. Replace with your own observable stream */
      map(({message}) => fromCore.Actions.ShowMessage({message})));
  });

  submitChanges$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromStudent.Actions.SubmitStatusChanges),
      concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromStudent.Selectors.RequestedChangesForEffects)))
      )),
      switchMap(([action, {memberId, command}]) => this.memberService.submitStatusChanges(memberId, command)),
      map((response) => {
        const message = response.message;
        if (!response.isSuccess) {
          this.store.dispatch(fromStudent.Actions.SubmitStatusChangesError({message}));
        } 
        return fromCore.Actions.HandleResponse({response});
      })
    )
  });

  exportToExcel$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromStudent.Actions.ExportToExcel),
      concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromStudent.Selectors.Students)))
      )),
      tap(([action, students]) => {
        if (students.some(x => x.selected)) {
          students = [...students.filter(x => x.selected)];
        }
        var result = students.map(student => {
          return {
            studentId: student.studentNumber,
            firstName: student.firstName,
            lastName: student.lastName,
            emailAddress: student.emailAddress,
            phoneNumber: student.phoneNumber
          };
        });

        this.excelService.exportAsExcelFile(result, action.columns, action.fileName);
      })
    )
  }, {dispatch: false});

  importStudents$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromStudent.Actions.ImportStudents),
      switchMap((action) =>
        this.studentService.importStudents(action.institutionId, action.commands)
      ),
      map((response) => fromCore.Actions.HandleResponse({response})))
  });

  addStudent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromStudent.Actions.AddStudent),
      concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromMember.Selectors.InstitutionId)))
      )),
      map(([{command}, institutionId]) => {
        return {
          ...command,
          institutionId
        }
      }),
      tap((command)=>this.store.dispatch(fromStudent.Actions.ShowStudentAddedDialog({command}))),
      switchMap((command) => this.studentService.addStudent(command.institutionId, command)),
      map((response) =>{
        const message = response.message;
        if(!response.isSuccess){
          this.store.dispatch(fromStudent.Actions.AddStudentError({message}));
        }
        return fromCore.Actions.ShowMessage({message});
      })
    )
  });

  addStudentError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromStudent.Actions.AddStudentError),
      /** An EMPTY observable only emits completion. Replace with your own observable stream */
      map(({message}) => fromCore.Actions.ShowMessage({message})));
  });

  addStudentShowDialog$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromStudent.Actions.ShowStudentAddedDialog),
      concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromStudent.Selectors.AddStudentForEffects)))
      )),
      map(([{command},{genders,regions,countries, institutionId}]) =>{ 
        const genderItem = genders.find(x=>x.id===command.genderId);
        const gender = genderItem ? genderItem.description : '';
        const region = regions.find(x=>x.id===command.regionId);
        const country = countries.find(x=>x.id===region.countryId);
        const {id, firstName, middleName, lastName, studentNumber, emailAddress, emailAddress2, phoneNumber, addressLine1, addressLine2, postalCode, city, dateOfBirth} = command;
        const dateOfBirth2: DateParts = {year: moment(dateOfBirth).year(), month: moment(dateOfBirth).month(), day: moment(dateOfBirth).date()};
        const address:Address = {addressLine1, addressLine2,city, region, country, postalCode}
        const student:StudentListItem = {id, institutionId, firstName, middleName, lastName, studentNumber, emailAddress, emailAddress2, phoneNumber, institutionName:'',institutionLogoUrl:'', gender, dateOfBirth, dateOfBirth2, address, enrollments:[],isActive:true,selected:false };
        this.dialog.open<StudentAddedDialogComponent, StudentAddedDialogData>(StudentAddedDialogComponent, {
          data: {
            student
          }
        });
        this.store.dispatch(fromStudent.Actions.StudentAdded({student}));
      })
    );
  },{dispatch:false});

  editStudent$ = createEffect(() => {
    let revert: Student;
    return this.actions$.pipe(
      ofType(fromStudent.Actions.EditStudent),
      concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromMember.Selectors.InstitutionId)))
      )),
      map(([{command, oldStudent}, institutionId]) => {
        revert = oldStudent
        return {
          ...command,
          institutionId
        }
      }),
      switchMap((command) => this.studentService.editStudent(command.institutionId, command)),
      map((response) =>{
        const message = response.message;
        if(!response.isSuccess){
          this.store.dispatch(fromStudent.Actions.EditStudentError({message: response.message, oldStudent: revert}));
        }
        this.store.dispatch(fromCore.Actions.ShowMessage({message}));
        return fromStudent.Navigate.StudentList({});
      }))
  });

  confirmStudentDelete$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromStudent.Actions.ConfirmDelete),
      tap(({student}) => this.dialog.open<StudentDeleteDialogComponent, StudentDeleteDialogData>(StudentDeleteDialogComponent, {
        data: {
          student
        }
      }))
    )
  }, {dispatch: false});

  deleteStudent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromStudent.Actions.DeleteStudent),
      concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromMember.Selectors.InstitutionId)))
      )),
      switchMap(([{command}, institutionId]) => this.studentService.deleteStudent(institutionId, command)),
      map((response) => fromCore.Actions.HandleResponse({response})
    ))
  });

  deleteStudentError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromStudent.Actions.DeleteStudentError),
      /** An EMPTY observable only emits completion. Replace with your own observable stream */
      map(({message}) => fromCore.Actions.ShowMessage({message})));
  });

  deleteSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromStudent.Actions.DeleteStudentSuccess),
      tap(() =>{ 
        this.store.dispatch(fromCore.Actions.ShowMessage({message: 'Student Deleted'}));
        this.store.dispatch(fromStudent.Navigate.StudentList({}));  
      })
    );
  }, {dispatch: false});

  deactivateStudent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromStudent.Actions.DeactivateStudent),
      concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromMember.Selectors.InstitutionId)))
      )),
      switchMap(([{command}, institutionId]) => this.studentService.deactivateStudent(institutionId, command)),
      map((response) => fromCore.Actions.HandleResponse({response})))
  });
  deactivateStudentError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromStudent.Actions.DeactivateStudentError),
      /** An EMPTY observable only emits completion. Replace with your own observable stream */
      map(({message}) => fromCore.Actions.ShowMessage({message})));
  });

  activateStudent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromStudent.Actions.ActivateStudent),
      concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromMember.Selectors.InstitutionId)))
      )),
      switchMap(([{command}, institutionId]) => this.studentService.activateStudent(institutionId, command)),
      map((response) => fromCore.Actions.HandleResponse({response}))
    )
  });
  activateStudentError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromStudent.Actions.ActivateStudentError),
      /** An EMPTY observable only emits completion. Replace with your own observable stream */
      map(({message}) => fromCore.Actions.ShowMessage({message})));
  });

  enrollStudent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromStudent.Actions.EnrollStudent),
      map(() => fromEnrollment.Actions.EnrollStudent({fromType: ENewEnrollmentTypeFrom.fromStudents}))
    )
  });

  masterToggle$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromStudent.Actions.MasterToggle),
      concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromStudent.Selectors.FilteredStudents)))
      )),
      map(([action, students]) => {
        const isAllSelected = action.toggle != undefined ? !action.toggle : students.every(x => x.selected);
        return students.map(x => {
          return {
            ...x,
            selected: !isAllSelected
          }
        })
      }),
      map((students) => fromStudent.Actions.MasterToggled({students}))
    )
  });
}
function concatWithLatestFrom(Genders: any): import("rxjs").OperatorFunction<{ command: import("../../../../shared/student/commands").AddStudentCommand; } & import("@ngrx/store/src/models").TypedAction<"[Student List] - Show Student Added Dialog">, unknown> {
  throw new Error("Function not implemented.");
}

