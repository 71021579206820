import { BaseResponse } from '@core/models';
import { props, createAction } from '@ngrx/store';

export const HandleResponse = createAction(
    '[Various Places] - HandleResponse',
    props<{
        response: BaseResponse
    }>()
)

export const ShowMessage = createAction(
    '[Various Places] - Show Message',
    props<{
        message: string
    }>()
)

export const EmptyResponse = createAction(
    '[Various Places] - Empty Response'
)