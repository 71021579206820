import { InstitutionState } from "@institution/store"
import { Session } from "@shared/calendar/models"
import getSession from './getSession';
import getSemester from './getSemester';
import getAcademicYear from './getAcademicYear';
export default (session:Session, state: InstitutionState): InstitutionState => {
  let sessionExists = getSession(state.academicYears, session.id);
  if(!!sessionExists) return state;
  let semester = getSemester(state.academicYears, session.semesterId);
  const sessions = [...semester.sessions, session];
  semester = {...semester, sessions};
  let academicYear = getAcademicYear(state.academicYears, session.academicYearId);
  const semesterIdx = academicYear.semesters.findIndex(x=>x.id===semester.id);
  const semesters = [...academicYear.semesters.slice(0,semesterIdx), semester, ...academicYear.semesters.slice(semesterIdx+1)];
  academicYear = { ...academicYear, semesters };
  const academicYearIdx = state.academicYears.findIndex(x=>x.id===academicYear.id);
  const academicYears = [...state.academicYears.slice(0, academicYearIdx), academicYear, ...state.academicYears.slice(academicYearIdx + 1)];
  let currentAcademicYear = state.currentAcademicYear;
  if(!!currentAcademicYear && currentAcademicYear.id==academicYear.id){
    currentAcademicYear = {...academicYear};
  }
  return {
    ...state,
    academicYears,
    currentAcademicYear
  }
  }