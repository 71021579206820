import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {EnrollmentListItem} from '@member/enrollment';
import { Store } from '@ngrx/store';
import {EnrollmentStatus} from '@refData';
import { IAppState } from 'app/store';
import * as fromEnrollment from '@member/enrollment/store';
export interface EnrollmentListItemDetailDialogData {
  enrollment: EnrollmentListItem
}

@Component({
  selector: 'enrollment-list-item-detail-dialog',
  templateUrl: './enrollment-list-item-detail-dialog.component.html',
  styleUrls: ['./enrollment-list-item-detail-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EnrollmentListItemDetailDialogComponent {
  enrollment: EnrollmentListItem;
  requestedStatus: EnrollmentStatus;
  constructor(
    public matDialogRef: MatDialogRef<EnrollmentListItemDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public matDialogData: EnrollmentListItemDetailDialogData,
    private store: Store<IAppState>
  ) {
    this.matDialogRef.addPanelClass('enrollment-list-item-detail-dialog');
    this.enrollment = this.matDialogData.enrollment;
  }

  close(): void {
    this.matDialogRef.close();
  }

  changeRequestedStatus(requestedStatus: EnrollmentStatus): void {
    const enrollmentId = this.enrollment.id;
    this.store.dispatch(fromEnrollment.Actions.EditStatus({ enrollmentId, requestedStatus }));
    this.close();
    
  }
}
