<mat-toolbar>
  <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <span class="title dialog-title">Student Added - Enroll now?</span>
    <button mat-icon-button (click)="close()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div class="content">
  <div class="sub-title sub-title-top">Student successfully added!</div>

  <student-details-for-dialog [student]="matDialogData.student"></student-details-for-dialog>

  <div class="sub-title sub-title-bottom">Would you like to enroll this student in a course?</div>

  <div class="actions">
    <button mat-raised-button color="warn" (click)="close()">
      <mat-icon>close</mat-icon>
      No, Cancel
    </button>

    <button mat-raised-button (click)="enrollStudent()">
      <mat-icon>call_made</mat-icon>
      Yes, New Enrollment
    </button>
  </div>
</div>
