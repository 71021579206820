import { props, createAction } from '@ngrx/store';
import { Institution, InstitutionListItem } from '@admin/institution';
import { UserListItem } from '@shared/user/models';
import { AcademicYear, Semester, Session, SessionDateRules } from '@shared/calendar/models';
import { ElbertFile } from '@core/models';
import { AddUserCommand, UpdateUserCommand } from '@shared/user/commands';
import { AddAcademicYearCommand, EditAcademicYearCommand, AddSemesterCommand, EditSemesterCommand, EditSessionCommand, AddSessionCommand } from '@shared/calendar/commands';
import { FacultyCV, InstitutionSummary } from '@shared/institution/models';
import { AddFacultyCVCommand, UpdateFacultyCVCommand } from '@shared/provider/commands';
import { UpdateInstitutionCommand } from '@admin/institution/models/commands/UpdateInstitutionCommand';
import { UploadInstitutionLogoCommand } from '@admin/institution/models/commands/UploadInstitutionLogoCommand';

export const LoadInstitution = createAction(
    '[Institution Resolver] - Load Institution',
    props<{
        institutionId: string;
    }>()
);

export const LoadInstitutionSuccess = createAction(
    '[Institution Effect] - Load Institution Success',
    props<{
        institution: Institution
    }>()
)

export const UpdateInstitution = createAction(
    'Institution Profile - Update Institution',
    props<{
        command: UpdateInstitutionCommand;
    }>()
)

export const InstitutionUpdated = createAction(
    '[Institution Effects] - Institution Updated',
    props<{
        institution: Institution;
    }>()
)

export const InstitutionSummaryUpdated = createAction(
    '[Institution Effects] - Institution Summary Updated',
    props<{
        institutionSummary: InstitutionSummary;
    }>()
)

export const UpdateInstitutionSuccess = createAction(
    '[Institution Effects] - Update Institution Success',
    props<{
        institution: Institution
    }>()
)

export const UploadInstitutionLogo = createAction(
    '[Institution Profile] - Upload Institution Logo',
    props<{
        command: UploadInstitutionLogoCommand
    }>()
)
export const UploadInstitutionLogoSuccess = createAction(
    '[Institution Profile] - Upload Institution Logo Success'
)

export const UploadInstitutionLogoError = createAction(
    '[Institution Profile] - Upload Institution Logo Error'
)

export const LoadInstitutionSummary = createAction(
    '[Institution Resolver] - Load Institution Summary',
    props<{
        institutionId: string;
    }>()
);

export const LoadInstitutionSummarySuccess = createAction(
    '[Institution Effect] - Load Institution Summary Success',
    props<{
        institutionSummary: InstitutionSummary
    }>()
)

export const LoadCalendar = createAction(
    '[Calendar Resolver] - Load Calendar',
    props<{
        institutionId: string;
    }>()
);

export const LoadCalendarSuccess = createAction(
    '[Institution Effect] - Load Calendar Success',
    props<{
        academicYears: AcademicYear[]
    }>()
)

export const LoadFacultyCVs = createAction(
    '[Institution Faculty CV Resolver] - Load Institution Faculty CV',
    props<{
        institutionId: string;
    }>()
);

export const LoadFacultyCVsSuccess = createAction(
    '[Institution Effect] - Load Faculty CVs Success',
    props<{
        facultyCVs: FacultyCV[]
    }>()
)

export const DownloadBankInfo = createAction(
    '[Various Places] - Download Bank Info',
    props<{
        bankInfo: ElbertFile
    }>()
);

export const UpdateBankInfo = createAction(
    '[Calendar Resolver] - Update Bank Info',
    props<{
        file: File;
    }>()
);

export const UpdateBankInfoSuccess = createAction(
    '[Institution Effects] - Update Bank Info Success',
    props<{
        bankInfo: ElbertFile
    }>()
);

export const AddAcademicYear = createAction(
    '[Calendar List] - Add AcademicYear',
    props<{
        command: AddAcademicYearCommand
    }>()
)

export const AddAcademicYearSuccess = createAction(
    '[Institution Effects] - Add AcademicYear Success',
    props<{
        academicYear: AcademicYear
    }>()
)

export const UpdateAcademicYear = createAction(
    '[Calendar List] - Edit AcademicYear',
    props<{
        command: EditAcademicYearCommand
    }>()
)

export const EditAcademicYearSuccess = createAction(
    '[Institution Effects] - Edit AcademicYear Success',
    props<{
        academicYear: AcademicYear
    }>()
)

export const DeleteAcademicYear = createAction(
    '[Calendar List] - Delete AcademicYear',
    props<{
        academicYearId: string
    }>()
)

export const AddSemester = createAction(
    '[Calendar List] - Add Semester',
    props<{
        command: AddSemesterCommand
    }>()
)

export const AddSemesterSuccess = createAction(
    '[Institution Effects] - Add Semester Success',
    props<{
        semester: Semester
    }>()
)

export const UpdateSemester = createAction(
    '[Calendar List] - Edit Semester',
    props<{
        command: EditSemesterCommand
    }>()
)

export const EditSemesterSuccess = createAction(
    '[Institution Effects] - Edit Semester Success',
    props<{
        semester: Semester
    }>()
)
export const DeleteSemester = createAction(
    '[Calendar List] - Delete Semester',
    props<{
        semester: Semester
    }>()
)

export const AddSession = createAction(
    '[Calendar List] - Add Session',
    props<{
        command: AddSessionCommand
    }>()
)

export const AddSessionSuccess = createAction(
    '[Institution Effects] - Add Session Success',
    props<{
        session: Session
    }>()
)

export const UpdateSession = createAction(
    '[Calendar List] - Edit Session',
    props<{
        command: EditSessionCommand
    }>()
)

export const EditSessionSuccess = createAction(
    '[Institution Effects] - Edit Session Success',
    props<{
        session: Session
    }>()
)
export const DeleteSession = createAction(
    '[Calendar List] - Delete Session',
    props<{
        session: Session
    }>()
)

export const AddFacultyCV = createAction(
    '[Faculty CV List] - Add Faculty CV',
    props<{
        command: AddFacultyCVCommand
    }>()
)

export const AddFacultyCVSuccess = createAction(
    '[Institution Effect] - Add Faculty CV Success',
    props<{
        facultyCV: FacultyCV
    }>()
)


export const UpdateFacultyCV = createAction(
    '[Faculty CV List] - Update Faculty CV',
    props<{
        command: UpdateFacultyCVCommand
    }>()
)

export const UpdateFacultyCVSuccess = createAction(
    '[Institution Effect] - Update Faculty CV Success',
    props<{
        facultyCV: FacultyCV
    }>()
)

export const Dashboard = createAction(
    '[Various] - Go to Dashboard',
    props<{
        institutionId?: string
    }>()
)

export const GoToUserList = createAction(
    '[Institution Nav Bar] - Go to User List'
)

export const ChangeCurrentAcademicYearId = createAction(
    'Various Places - Change Current Academic Year',
    props<{
        academicYearId: string
    }>()
)

export const ChangeCurrentAcademicYear = createAction(
    '[Institution Effects] - Change Current Academic Year',
    props<{
        academicYear: AcademicYear
    }>()
)

export const LoadSessionDateRules = createAction(
    '[Institution] LoadSessionDateRules',
    props<{
        institutionId: string
    }>()
);

export const LoadSessionDateRulesSuccess = createAction(
    '[Institution] loadedSessionDateRulesSuccess',
    props<{
        sessionDateRules: SessionDateRules[]
    }>()
);

export const LoadSessionDateRulesError = createAction(
    '[Institution] loadedSessionDateRulesError'
);

export const AcademicYearUpdated = createAction(
    '[Institution Effects] - Academic Year Updated',
    props<{
        academicYear: AcademicYear
    }>()
)

export const AcademicYearAdded = createAction(
    '[Institution Effects] - Academic Year Added',
    props<{
        academicYear: AcademicYear
    }>()
)

export const AcademicYearDeleted = createAction(
    '[Institution Effects] - Academic Year Deleted',
    props<{
        academicYear: AcademicYear
    }>()
)

export const SemesterUpdated = createAction(
    '[Institution Effects] - Semester Updated',
    props<{
        semester: Semester
    }>()
)

export const SemesterAdded = createAction(
    '[Institution Effects] - Semester Added',
    props<{
        semester: Semester
    }>()
)

export const SemesterDeleted = createAction(
    '[Institution Effects] - Semester Deleted',
    props<{
        semester: Semester
    }>()
)

export const SessionUpdated = createAction(
    '[Institution Effects] - Session Updated',
    props<{
        session: Session
    }>()
)

export const SessionAdded = createAction(
    '[Institution Effects] - Session Added',
    props<{
        session: Session
    }>()
)
export const SessionDeleted = createAction(
    '[Institution Effects] - Session Deleted',
    props<{
        session: Session
    }>()
)



