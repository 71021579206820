import { Injectable } from "@angular/core";
import * as fromInstitution from '@institution/store';
import { IAppState } from 'app/store/state/app.state';
import { Store } from '@ngrx/store';
import { RealTimeService } from "@core/services/realTime.service";
import { Institution } from "@admin/institution";
import { InstitutionSummary } from "@shared/institution/models";
import { AcademicYear, Semester, Session } from "@shared/calendar/models";
import convertAcademicYearDates from "@shared/common/helpers/convertAcademicYearDates";
import convertSemesterDates from "@shared/common/helpers/convertSemesterDates";
import convertSessionDates from "@shared/common/helpers/convertSessionDates";
import { HubConnection } from "@microsoft/signalr";


@Injectable()
export class InstitutionRealtime {
        constructor(private store: Store<IAppState>, private realTimeService: RealTimeService) {
                this.initSignalRMessages();
        }

        private initSignalRMessages() {
                this.realTimeService.hubConnection$.subscribe(hubConnection => {
                        if (!hubConnection) return;
                        this.institutionUpdated(hubConnection);
                        this.institutionSummaryUpdated(hubConnection);
                        this.academicYearAdded(hubConnection);
                        this.academicYearUpdated(hubConnection);
                        this.academicYearDeleted(hubConnection);
                        this.semesterAdded(hubConnection);
                        this.semesterUpdated(hubConnection);
                        this.sessionAdded(hubConnection);
                        this.sessionUpdated(hubConnection);
                })
        }
    
    private institutionUpdated(hubConnection:HubConnection){
        hubConnection.on('InstitutionUpdated', (institution: Institution) => {
            this.store.dispatch(fromInstitution.Actions.InstitutionUpdated({ institution }));
        });
    }
    private institutionSummaryUpdated(hubConnection:HubConnection){
        hubConnection.on('InstitutionSummaryUpdated', (institutionSummary: InstitutionSummary) => {
            this.store.dispatch(fromInstitution.Actions.InstitutionSummaryUpdated({ institutionSummary }));
        });
    }
    private academicYearAdded(hubConnection:HubConnection){
        hubConnection.on('AcademicYearAdded', (addedAcademicYear: AcademicYear) => {
            const academicYear = convertAcademicYearDates(addedAcademicYear);
            this.store.dispatch(fromInstitution.Actions.AcademicYearAdded({ academicYear }));
        });
    }
    private academicYearUpdated(hubConnection:HubConnection){
        hubConnection.on('AcademicYearUpdated', (updatedAcademicYear: AcademicYear) => {
            const academicYear = convertAcademicYearDates(updatedAcademicYear);
            this.store.dispatch(fromInstitution.Actions.AcademicYearUpdated({ academicYear }));
        });
    }
    private academicYearDeleted(hubConnection:HubConnection){
        hubConnection.on('AcademicYearDeleted', (academicYear: AcademicYear) => {
            this.store.dispatch(fromInstitution.Actions.AcademicYearUpdated({ academicYear }));
        });
    }
    private semesterAdded(hubConnection:HubConnection){
        hubConnection.on('SemesterAdded', (addedSemester: Semester) => {
            const semester = convertSemesterDates(addedSemester);
            this.store.dispatch(fromInstitution.Actions.SemesterAdded({ semester }));
        });
    }
    private semesterUpdated(hubConnection:HubConnection){
        hubConnection.on('SemesterUpdated', (addedSemester: Semester) => {
            const semester = convertSemesterDates(addedSemester);
            this.store.dispatch(fromInstitution.Actions.SemesterUpdated({ semester }));
        });
    }
    private sessionAdded(hubConnection:HubConnection){  
        hubConnection.on('SessionAdded', (addedSession: Session) => {
            const session = convertSessionDates(addedSession);
            this.store.dispatch(fromInstitution.Actions.SessionAdded({ session }));
        });
    }
    private sessionUpdated(hubConnection:HubConnection){
        hubConnection.on('SessionUpdated', (updatedSession: Session) => {
            const session = convertSessionDates(updatedSession);
            this.store.dispatch(fromInstitution.Actions.SessionUpdated({ session }));
        });
    }
}
