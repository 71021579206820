import { InstitutionState } from "@institution/store"
import { Semester } from "@shared/calendar/models"

export default (semester: Semester, state: InstitutionState): InstitutionState => {
  const academicYearIdx = state.academicYears.findIndex(x => x.id === semester.academicYearId);
  if (academicYearIdx === -1) return state;
  const semesterIdx = state.academicYears[academicYearIdx].semesters.findIndex(x => x.id == semester.id);
  if (semesterIdx !== -1) return state;
  const semesters = [...state.academicYears[academicYearIdx].semesters, semester];
  const academicYear = { ...state.academicYears[academicYearIdx], semesters };
  const academicYears = [...state.academicYears.slice(0, academicYearIdx), academicYear, ...state.academicYears.slice(academicYearIdx + 1)];
  let currentAcademicYear = state.currentAcademicYear;
  if(!!currentAcademicYear && currentAcademicYear.id==semester.academicYearId){
    currentAcademicYear = {...academicYear};
  }
  return {
    ...state,
    academicYears,
    currentAcademicYear
  }
}