import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { PermissionType } from '../models/permissionType.enum';
import { AuthenticationService } from '../services/authentication.service';
import isSuperAdmin from '../permissions/isSuperAdmin';
import { ViewContext } from '../models/viewContext.enum';
import hasInstitutionPermission from '../permissions/hasInstitutionPermission';
import hasMemberPermission from '../permissions/hasMemberPermission';
import hasProviderPermission from '../permissions/hasProviderPermission';
import hasCourseExchangeAdminPermission from '../permissions/hasCourseExchangeAdminPermission';
import { access } from 'fs';
import { AccessRole } from '../models/accessRole';
import { IAppState } from 'app/store/state/app.state';
import { select, Store } from '@ngrx/store';
import { CurrentUser } from '../store/auth.selectors';
import { UserSummary } from '../models/userSummary.model';
import { AuthActions } from '../store/action-types';
import * as fromAuth from '@auth/store';

@Injectable({
  providedIn: 'root'
})
export class HasPermissionGuard implements CanActivate {
  constructor(private store: Store<IAppState>,private activatedRoute: ActivatedRoute, private router: Router) {
  }
  canActivate(
    _next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      const requestedPermission: PermissionType = _next.data.permission;
      let entityId: string = '';
      const viewContext: ViewContext = _next.data.viewContext;
      const params = this.activatedRoute.snapshot.params;
      const currentState = state;
      return this.store.pipe(
          select(CurrentUser),
          map((userSummary) => {
            if (userSummary == null) { return false; }
            var hasPermission = false;
            var entityId = '';
          var institutionId = '';
            let accessRole: AccessRole = null;
            var pathParts = state.url.split('/');
            if(pathParts.length>=3) entityId = pathParts[2];
            if(pathParts.length>=4) institutionId = pathParts[3];
            switch(viewContext){
              case ViewContext.Institution:
                accessRole = userSummary.accessRoles.find(x=>x.viewContext===ViewContext.Institution&&x.institutionId===institutionId);
                hasPermission = hasInstitutionPermission(userSummary, institutionId, requestedPermission);
                break;
              case ViewContext.Member:
                accessRole = userSummary.accessRoles.find(x=>x.viewContext===ViewContext.Member&&x.entityId===entityId);
                hasPermission = hasMemberPermission(userSummary, entityId, requestedPermission);
                break;
              case ViewContext.Provider:
                accessRole = userSummary.accessRoles.find(x=>x.viewContext===ViewContext.Provider&&x.entityId===entityId);
                hasPermission = hasProviderPermission(userSummary, entityId, requestedPermission);
                break;
              case ViewContext.CourseExchangeAdmin:
                accessRole = userSummary.accessRoles.find(x=>x.viewContext===ViewContext.CourseExchangeAdmin&&x.courseExchangeId===entityId);
                hasPermission = hasCourseExchangeAdminPermission(userSummary, entityId, requestedPermission);
                break;
              case ViewContext.SuperAdmin:
                accessRole = userSummary.accessRoles.find(x=>x.viewContext===ViewContext.SuperAdmin);
                hasPermission = isSuperAdmin(userSummary);
                break;
            }
            //if(accessRole) this.store.dispatch(AuthActions.selectAccessRole({accessRoleId: accessRole.id}));
            return hasPermission;
          }),
          tap(isAllowed => this.handleNotAllowed(isAllowed,currentState))
        );
  }

  private handleNotAllowed(isAllowed:boolean, state: RouterStateSnapshot):void{
    if(!isAllowed){
      this.store.dispatch(fromAuth.Actions.navigateToLogin({returnUrl: state.url}));
    }
  }
}
