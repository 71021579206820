import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Observable} from 'rxjs';
import {IAppState} from 'app/store/state/app.state';
import {Store, select} from '@ngrx/store';
import * as fromAuth from '@auth/store';

@Component({
  selector: 'toolbar-logo',
  templateUrl: './toolbar-logo.component.html',
  styleUrls: ['./toolbar-logo.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ToolbarLogoComponent implements OnInit {
  primaryLogo$: Observable<string>;

  constructor(
    private store: Store<IAppState>
  ) {
  }

  ngOnInit(): void {
    this.primaryLogo$ = this.store.pipe(select(fromAuth.Selectors.CourseExchangePrimaryLogo));
  }
}
