<div class="wizard-step wizard-contacts-step">
  <div [ngSwitch]="currentStepId" class="steps-content">
    <ng-container *ngSwitchCase="'ContactAndUserSetup_Accounting'">
      <wizard-contacts-step-add-accounting
        [institutionId]="institutionId"
        [accountingUser]="accountingUser"
        [role]="'Accounting'"
        (addUser)="addUser.emit($event)"
        (editUser)="editUser.emit($event)"
        (navigate)="navigate.emit($event)">
      </wizard-contacts-step-add-accounting>
    </ng-container>
    <ng-container *ngSwitchCase="'ContactAndUserSetup_President'">
      <wizard-contacts-step-add-president
        [institutionId]="institutionId"
        [user]="presidentUser"
        [role]="'President'"
        (addUser)="addUser.emit($event)"
        (editUser)="editUser.emit($event)"
        (navigate)="navigate.emit($event)"></wizard-contacts-step-add-president>
    </ng-container>
    <ng-container *ngSwitchCase="'ContactAndUserSetup_ProvostOrVicePresident'">
      <wizard-contacts-step-add-provost
        [user]="vicePresidentOrProvostUser"
        [institutionId]="institutionId"
        [institutionRoles]="TwoRoles"
        (addUser)="addUser.emit($event)"
        (editUser)="editUser.emit($event)"
        (navigate)="navigate.emit($event)"></wizard-contacts-step-add-provost>
    </ng-container>
    <ng-container *ngSwitchCase="'ContactAndUserSetup_AdditionalUsers'">
      <wizard-contacts-step-add-user
        [institutionId]="institutionId"
        [institutionRoles]="institutionRoles"
        (addUser)="addUser.emit($event)"
        (navigate)="navigate.emit($event)">
      </wizard-contacts-step-add-user>
    </ng-container>
  </div>
</div>
