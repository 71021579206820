<elb-page-list-container [kind]="'member'" class="page-list-member-enrollment-list">
  <!-- <elb-page-header [pageTitle]="pageTitle"
                   [pageSubTitle]="pageSubTitle"
                   (returnToDashboardClicked)="goToDashboard()">
  </elb-page-header> -->

  <elb-page-list-content>

    <elb-page-list-sidebar>
      <mat-accordion>
        <mat-form-field class="dateRange">
          <mat-label>Date Range</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate [value]="startDate$ |async" placeholder="Start date" (dateChange)="changeStartDate($event)" #startDate>
            <input matEndDate [value]="endDate$ | async" placeholder="End date" (dateChange)="changeEndDate($event)" #endDate>
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
        <mat-date-range-picker #picker></mat-date-range-picker>
        <mat-expansion-panel *ngIf="(statusFilters$ | async)?.length>0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 [matBadge]="statusFiltersCount$ | async" matBadgeOverlap="false">Status</h3>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="side-panel-list">
            <mat-selection-list (selectionChange)="onToggleStatusFilter($event)">
              <mat-list-option [value]="status.id" [selected]="status.selected"
                *ngFor="let status of statusFilters$ | async; trackBy: trackById">
                {{ status.descriptionForView }}
              </mat-list-option>
            </mat-selection-list>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="(providerFilters$ | async)?.length>0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 [matBadge]="providerFilterCount$ | async" matBadgeOverlap="false">Provider</h3>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="side-panel-list">
            <mat-selection-list (selectionChange)="onToggleProviderFilter($event)">
              <mat-list-option *ngFor="let provider of providerFilters$ | async; trackBy: trackById"
                [value]="provider.id" [selected]="provider.selected">
                {{ provider.descriptionForView }}
              </mat-list-option>
            </mat-selection-list>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="(tagFilters$ | async)?.length>0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 [matBadge]="tagFilterCount$ | async" matBadgeOverlap="false">Tags</h3>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="side-panel-list">
            <mat-selection-list (selectionChange)="onToggleTagFilter($event)">
              <mat-list-option *ngFor="let tag of tagFilters$ | async; trackBy: trackById" [value]="tag.id"
                [selected]="tag.selected">
                {{ tag.descriptionForView }}
              </mat-list-option>
            </mat-selection-list>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="(categoryFilters$ | async)?.length>0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 [matBadge]="categoryFilterCount$ | async" matBadgeOverlap="false">Category</h3>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="side-panel-list">
            <mat-selection-list (selectionChange)="onToggleCategoryFilter($event)">
              <mat-list-option *ngFor="let category of categoryFilters$ | async; trackBy: trackById"
                [value]="category.id" [selected]="category.selected">
                {{ category.descriptionForView }}
              </mat-list-option>
            </mat-selection-list>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="(subCategoryFilters$ | async)?.length>0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 [matBadge]="subCategoryFilterCount$ | async" matBadgeOverlap="false">Sub Category</h3>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="side-panel-list">
            <mat-selection-list (selectionChange)="onToggleSubCategoryFilter($event)">
              <mat-list-option *ngFor="let subCategory of subCategoryFilters$ | async; trackBy: trackById"
                [value]="subCategory.id" [selected]="subCategory.selected">
                {{ subCategory.descriptionForView }}
              </mat-list-option>
            </mat-selection-list>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </elb-page-list-sidebar>

    <elb-page-list-main>
      <elb-page-header [pageTitle]="pageTitle" [pageSubTitle]="pageSubTitle"
        (returnToDashboardClicked)="goToDashboard()">
      </elb-page-header>
      <elb-page-list-main-header>
        <div class="search-and-sort-list" fxLayout="row" fxLayoutAlign="space-between center">
          <!-- SEARCH -->
          <div class="search-wrapper">
            <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
              <mat-icon>search</mat-icon>
              <input #filter placeholder="Search Enrollments">
            </div>
          </div>
          <!-- / END SEARCH -->

          <!-- SORT MENU -->
          <div class="sort-wrapper" *ngIf="!(IsEnrollmentsLoading$ | async)">
            <mat-form-field class="full-list-sort">
              <mat-select placeholder="Sort By" [value]="currentSort$ | async"
                (selectionChange)="changeSort($event.value)">
                <mat-select-trigger>
                  <mat-icon>{{(currentSort$ | async)?.icon}}</mat-icon>&nbsp;{{(currentSort$ | async)?.name}}
                </mat-select-trigger>
                <mat-option *ngFor="let sort of sorts$ | async" [value]="sort">
                  <mat-icon>{{sort.icon}}</mat-icon>{{sort.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="full-list-sort">
              <mat-select placeholder="Order By" [value]="currentOrder$ | async"
                (selectionChange)="changeOrderBy($event.value)">
                <mat-select-trigger>
                  <mat-icon>{{(currentOrder$ | async)?.icon}}</mat-icon>&nbsp;{{(currentOrder$ | async)?.name}}
                </mat-select-trigger>
                <mat-option *ngFor="let order of orders$ | async" [value]="order">
                  <mat-icon>{{order.icon}}</mat-icon>{{order.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <!-- /END SORT -->
        </div>
        <div class="filters-wrapper" fxLayout="row" fxLayoutAlign="space-between center">
          <div class="list-select-all">
            <mat-checkbox (change)="masterToggle()" [checked]="isAllSelected$ | async"
              [indeterminate]="indeterminateSelected$ | async">
              Select All Enrollments ({{selectedStatus$ | async}})
            </mat-checkbox>
          </div>
          <div class="full-filters-chosen">
            <mat-chip-list>
              <mat-chip *ngFor="let filter of selectedFilters$ | async" [selectable]="false" [removable]="true"
                (removed)="removeFilter(filter)">
                {{filter.description}}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
            </mat-chip-list>
          </div>
        </div>
      </elb-page-list-main-header>

      <elb-page-list-main-body>
        <div class="enrollment-list" *ngIf="filteredEnrollments$ | async; let enrollments">
          <div class="list-item--custom" [ngClass]="['enrollment-item', enrollment.isActive ? 'is_active' : '']"
            *ngFor="let enrollment of enrollments; trackBy: trackById">
            <div class="enrollment-checkbox">
              <mat-checkbox [checked]="enrollment.selected" (change)="onToggleEnrollment(enrollment.id)"></mat-checkbox>
            </div>

            <div class="enrollment-menu">
              <button mat-icon-button [matMenuTriggerFor]="selectMenu">
                <mat-icon>more_horiz</mat-icon>
              </button>

              <mat-menu #selectMenu="matMenu">
                <button mat-menu-item (click)="enrollmentDetails(enrollment)">Enrollment Details</button>
              </mat-menu>
            </div>

            <div class="enrollment-content">
              <div class="student-info row">
                <div class="column-50">
                  <div class="student-name">
                    {{enrollment.studentFullName.lastName}},&nbsp;{{enrollment.studentFullName.firstName}}</div>
                  <div class="student-id"><strong>Student ID:</strong>&nbsp;{{enrollment.studentNumber}}</div>
                </div>
                <div class="column-50">
                  <div class="student-email">
                    <a href="mailto:{{enrollment.studentEmailAddress}}">
                      <mat-icon>mail</mat-icon><span class="link--email">{{enrollment.studentEmailAddress}}</span>
                    </a>
                  </div>
                  <div class="student-phone">
                    <a href="" class="link--phone">
                      <mat-icon>phone</mat-icon><span>{{enrollment.studentPhoneNumber}}</span>
                    </a>
                  </div>
                </div>
              </div>
              <div class="enrollment-title row">
                <div class="title--small">Enrollment Information:</div>
              </div>
              <div class="enrollment-info row">
                <div class="column-80ab">
                  <div class="provider-logo"><img src="{{enrollment.providerLogoUrl}}"></div>
                </div>
                <div class="column-calc">
                  <div class="course-title">{{getCourseTitle(enrollment)}}</div>
                  <div class="provider-institution ellipsis"><span class="text--smaller">Offered by
                      {{enrollment.providerName}} ({{enrollment.providerCourseCode}})</span></div>

                </div>
                <div class="column-30">

                  <div class="session-info">{{enrollment.sessionCode}}: <span
                      class="session-date">{{enrollment.sessionStartDate | date}}-{{enrollment.sessionEndDate |
                      date}}</span></div>
                  <div class="current-enrollment-status">Status: <b>{{getEnrollmentStatusDescription$(enrollment) |
                      async}}</b></div>
                </div>
                <div class="column-20">
                  <mat-form-field class="full-list-sort" *ngIf="showEditStatus(enrollment)">
                    <mat-select placeholder="Edit Status" [value]="enrollment.requestedStatus" [compareWith]="compare"
                      (selectionChange)="editStatus($event.value,enrollment.id)">
                      <mat-select-trigger>
                        <mat-icon>edit</mat-icon>&nbsp;{{enrollment.requestedStatus?.description}}
                      </mat-select-trigger>
                      <mat-option *ngFor="let edit of enrollment.status.memberAllowedStatuses" [value]="edit">
                        {{edit.description}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div *ngIf="canViewGrades$ | async">
                    <button color="primary" mat-raised-button *ngIf="showAppealGrade$(enrollment) | async">Appeal
                      Grade</button>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </div>
      </elb-page-list-main-body>
      <app-loading-spinner *ngIf="(IsEnrollmentsLoading$ | async)"></app-loading-spinner>
      <elb-page-list-main-footer>
        <ng-content></ng-content>
        <button type="button" class="btn--member-admin btn-footer" mat-button [disabled]="!(canExportToExcel$ | async)"
          (click)="exportToExcel()">
          <mat-icon>save_alt</mat-icon>Export to Excel
        </button>
        <button type="button" class="btn--member-admin btn-footer" mat-button (click)="submitStatusChanges()"
          [disabled]="!(canSubmitChanges$ | async)">
          <mat-icon>save</mat-icon>Submit Changes
        </button>

        <button type="button" class="btn--member-admin btn-footer" mat-button (click)="updateEnrollments()"
          [disabled]="!(commonAllowedStatuses$ | async).length || !(selectedEnrollments$ | async).length">
          <mat-icon>check</mat-icon>Update Enrollments
        </button>
      </elb-page-list-main-footer>

    </elb-page-list-main>
  </elb-page-list-content>
</elb-page-list-container>