import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {IAppState} from '../../../../../store/state/app.state';
import {Store} from '@ngrx/store';
import {UntypedFormBuilder, UntypedFormGroup, ValidatorFn, AbstractControl} from '@angular/forms';
import {IControlsConfig} from '../../../../../models/shared';
import {hasErrorForm} from '../../../../../helpers/has-error-form';
import { CourseExchangeInstitutionListItem } from '@shared/courseExchange/models';
import { AddMemberCommand } from '@shared/member/models';
import * as fromCourseExchangeInstitutions from '@courseExchange/institution';
import { AddProviderCommand } from '@shared/provider/commands';
export interface IInviteExistingInstitutionDialogData{
  currentInstitution: CourseExchangeInstitutionListItem;
  courseExchangeId: string;
}
type IFormFields = {
  isMember: boolean;
  isProvider: boolean;
};

type ILocalControlsConfig = IControlsConfig<IFormFields>;

@Component({
  selector: 'invite-existing-institution-dialog',
  templateUrl: './invite-existing-institution-dialog.component.html',
  styleUrls: ['./invite-existing-institution-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InviteExistingInstitutionDialogComponent implements OnInit {

  currentInstitution: CourseExchangeInstitutionListItem;
  form: UntypedFormGroup;

  constructor(
    public matDialogRef: MatDialogRef<InviteExistingInstitutionDialogComponent>,
    private store: Store<IAppState>,
    @Inject(MAT_DIALOG_DATA) public matDialogData: IInviteExistingInstitutionDialogData,
    private formBuilder: UntypedFormBuilder,
  ) {
    this.currentInstitution = matDialogData.currentInstitution;
  }

  ngOnInit(): void {
    this.matDialogRef.addPanelClass([
      'invite-existing-institutin-dialog'
    ]);

    this.initForm();
  }

  initForm(): void {
    this.form = this.formBuilder.group(
      this.shapeControlsConfig(),
      {
        validator: pickOne("isMember","isProvider")
      });
  }

  private shapeControlsConfig(): ILocalControlsConfig {
    const initialControlsConfig: ILocalControlsConfig = {
      isMember: [false],
      isProvider: [false]
    };

    return initialControlsConfig;
  }

  hasError = (controlName: keyof IFormFields, errorName: string) => hasErrorForm(controlName, errorName, this.form);

  close(): void {
    this.matDialogRef.close();
  }

  inviteInstitution(): void{
    if(this.form.valid){
      const {isMember, isProvider} = this.form.value;
      
      const {institutionId, institutionName, institutionLogo} = this.matDialogData.currentInstitution;
      const courseExchangeId = this.matDialogData.courseExchangeId;
        if(isMember && !this.matDialogData.currentInstitution.isMember){
          var addMemberCommand: AddMemberCommand ={courseExchangeId,institutionId,institutionLogo, institutionName};
          this.store.dispatch(fromCourseExchangeInstitutions.Actions.AddMember({command:addMemberCommand}));
        }
        if(isProvider && !this.matDialogData.currentInstitution.isProvider){
          var addProviderCommand: AddProviderCommand ={courseExchangeId,institutionId,institutionLogo, institutionName};
          this.store.dispatch(fromCourseExchangeInstitutions.Actions.AddProvider({command:addProviderCommand}));
        }
      }
      this.close();
    }
}

export const pickOne = (isMember, isProvider): ValidatorFn => (control: AbstractControl) => {
	if(control.get(isMember).value || control.get(isProvider).value) {
		return null;
	}
	return  { pickOne: { valid: false } };
}
