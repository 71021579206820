<div class="toolbar-icon">
  <button mat-icon-button
          [matMenuTriggerFor]="toolbarMenu"
          matTooltip="Menu"
          class="toolbar-icon-button">
    <mat-icon>menu_open</mat-icon>
  </button>
</div>

<mat-menu #toolbarMenu="matMenu" class="mat-menu-toolbar-menu">
  <ng-content></ng-content>
</mat-menu>
