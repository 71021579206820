import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation, Input} from '@angular/core';
import {NavigationEnd, Router, ActivatedRoute} from '@angular/router';
import {Subject, Observable, BehaviorSubject} from 'rxjs';
import {delay, filter, take, takeUntil, map, withLatestFrom, tap} from 'rxjs/operators';

import {FuseConfigService} from '@fuse/services/config.service';
import {FuseNavigationService} from '@fuse/components/navigation/navigation.service';
import {FusePerfectScrollbarDirective} from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import {FuseSidebarService} from '@fuse/components/sidebar/sidebar.service';
import {faCog, faQuestionCircle, faIdCard, faComments} from '@fortawesome/free-solid-svg-icons';
import {UserSummary} from 'app/authentication/models/userSummary.model';
import {AuthenticationService} from 'app/authentication/services/authentication.service';
import hasCourseExchangeAdminPermission from 'app/authentication/permissions/hasCourseExchangeAdminPermission';
import {PermissionType} from 'app/authentication/models/permissionType.enum';
import hasInstitutionPermission from 'app/authentication/permissions/hasInstitutionPermission';
import {ViewContext} from 'app/authentication/models/viewContext.enum';
import {CourseExchangeService} from 'app/shared/courseExchange/services/course-exchange.service';
import {IAppState} from 'app/store/state/app.state';
import {Store, select} from '@ngrx/store';
import {AccessRolesWithAccess, CurrentUser} from 'app/authentication/store/auth.selectors';
import {AuthActions} from 'app/authentication/store/action-types';
import * as fromCourseExchange from '@courseExchange/store';
import * as fromCourseExchangeUsers from '@courseExchange/users/store';
import * as fromAuth from '@auth/store';
import {AccessRoleForView} from '@auth/models/accessRole';

@Component({
  selector: 'app-exchange-admin-dashboard-nav',
  templateUrl: './exchange-admin-dashboard-nav.component.html',
  styleUrls: ['./exchange-admin-dashboard-nav.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ExchangeAdminDashboardNavComponent implements OnInit, OnDestroy {
  fuseConfig: any;
  navigation: any;
  faCog = faCog;
  faQuestionCircle = faQuestionCircle;
  faComments = faComments;
  faIdCard = faIdCard;
  managingInstitutionId$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  showCourseExchangeFees$: Observable<boolean>;
  showCourseCategories$: Observable<boolean>;
  showBankingInfo$: Observable<boolean>;
  showCourseExchangeCalendar$: Observable<boolean>;
  showCourseExchangeUsers$: Observable<boolean>;
  checklistCompleted$: Observable<boolean>;
  @Input() courseExchangeId: string;
  @Input() user: UserSummary;


  // Private
  private _fusePerfectScrollbar: FusePerfectScrollbarDirective;
  private _unsubscribeAll: Subject<any>;
  currentUser$: Observable<UserSummary>;
  accessRoles$: Observable<AccessRoleForView[]>;
  currentAccessRoleId$: Observable<string>;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseNavigationService} _fuseNavigationService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {Router} _router
   */
  constructor(
    private courseExchangeService: CourseExchangeService,
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    private _router: Router,
    private store: Store<IAppState>
  ) {
    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  // Directive
  @ViewChild(FusePerfectScrollbarDirective, {static: true})
  set directive(theDirective: FusePerfectScrollbarDirective) {
    if (!theDirective) {
      return;
    }

    this._fusePerfectScrollbar = theDirective;

    // Update the scrollbar on collapsable item toggle
    this._fuseNavigationService.onItemCollapseToggled
      .pipe(
        delay(500),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(() => {
        this._fusePerfectScrollbar.update();
      });

    // Scroll to the active item position
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        take(1)
      )
      .subscribe(() => {
          setTimeout(() => {
            this._fusePerfectScrollbar.scrollToElement('navbar .nav-link.active', -120);
          });
        }
      );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void
  {
    this.currentUser$ = this.store.pipe(select(fromAuth.Selectors.CurrentUser));
    this.accessRoles$ = this.store.pipe(select(fromAuth.Selectors.CurrentAccessRoles));
    this.currentAccessRoleId$ = this.store.pipe(select(fromAuth.Selectors.currentAccessRoleId));

      this._router.events
          .pipe(
              filter((event) => event instanceof NavigationEnd),
              takeUntil(this._unsubscribeAll)
          )
          .subscribe(() => {
                  if ( this._fuseSidebarService.getSidebar('navbar') )
                  {
                      this._fuseSidebarService.getSidebar('navbar').close();
                  }
              }
          );

      // Subscribe to the config changes
      this._fuseConfigService.config
          .pipe(takeUntil(this._unsubscribeAll))
          .subscribe((config) => {
              this.fuseConfig = config;
          });

      this.setupPermissions();
      this.setManagingInstitutionId();
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  toggleSidebarOpened(): void {
    this._fuseSidebarService.getSidebar('navbar').toggleOpen();
  }

  toggleSidebarFolded(): void {
    this._fuseSidebarService.getSidebar('navbar').toggleFold();
  }

  logout(): void {
    this.store.dispatch(fromAuth.Actions.logout());
  }

  setManagingInstitutionId(): void {
    this.store.pipe(
      select(AccessRolesWithAccess),
      tap((accessRoles) => {
        var accessRole = accessRoles.find(x => x.viewContext === ViewContext.CourseExchangeAdmin && x.courseExchangeId === this.courseExchangeId)
        if (!accessRole) return '';
        this.managingInstitutionId$.next(accessRole.institutionId);
      })).subscribe();
  }

  setupPermissions(): void {
    this.showCourseExchangeFees$ = this.store
      .pipe(
        select(CurrentUser),
        map(currentUser =>
          hasCourseExchangeAdminPermission(currentUser, this.courseExchangeId, PermissionType.SetCourseExchangeFees))
      );
    this.showCourseCategories$ = this.store
      .pipe(
        select(CurrentUser),
        map(currentUser =>
          hasCourseExchangeAdminPermission(currentUser, this.courseExchangeId, PermissionType.ReadCourseCategory))
      );
    this.showBankingInfo$ = this.store
      .pipe(
        select(CurrentUser),
        map(currentUser =>
          hasCourseExchangeAdminPermission(currentUser, this.courseExchangeId, PermissionType.ManageCourseExchangeBankingInformation))
      );
    this.showCourseExchangeCalendar$ = this.store
      .pipe(
        select(CurrentUser),
        withLatestFrom(this.managingInstitutionId$),
        map(([currentUser, managingInstitutionId]) => {
          return hasInstitutionPermission(currentUser, managingInstitutionId, PermissionType.ReadCalendar);
        })
      )
    this.showCourseExchangeUsers$ = this.store
      .pipe(
        select(CurrentUser),
        map(currentUser =>
          hasCourseExchangeAdminPermission(currentUser, this.courseExchangeId, PermissionType.ReadCourseExchangeContacts))
      );
    this.checklistCompleted$ = this.courseExchangeService.getCourseExchangeById(this.courseExchangeId)
      .pipe(
        map(courseExchange => courseExchange.courseExchangeChecklist.isComplete)
      );
  }

  goToFees(): boolean {
    this.store.dispatch(fromCourseExchange.Actions.ViewFees({}));
    return false;
  }

  goToCalendar(): boolean {
    this.store.dispatch(fromCourseExchange.Actions.ViewCalendars({}));
    return false;
  }

  goToUserList(): boolean {
    this.store.dispatch(fromCourseExchangeUsers.Navigate.UserList());
    return false;
  }

  changeAccessRole(accessRoleId: string): void{
    this.store.dispatch(fromAuth.Actions.selectAccessRole({accessRoleId}));
    this.store.dispatch(fromAuth.Actions.navigateToDashboard({accessRoleId}));
  }
}

