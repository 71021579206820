import { NgModule } from '@angular/core';
import * as fromInstitution from '@institution/store';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { InstitutionSharedModule } from '@shared/institution/institution-shared.module';
import { UserSharedModule } from '@shared/user/user-shared.module';
import { CalendarSharedModule } from '@shared/calendar/calendar.module';
import { UsersStoreModule } from '@shared/user/store/usersStore.module';

@NgModule({
  declarations: [],
  imports: [
    InstitutionSharedModule,
    UserSharedModule,
    CalendarSharedModule,
    UsersStoreModule,
    StoreModule.forFeature('institution', fromInstitution.institutionReducer),
    EffectsModule.forFeature([...fromInstitution.EFFECTS])
  ],
  providers: [...fromInstitution.RESOLVERS]
})
export class InstitutionStoreModule { }
