import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { Store, select } from "@ngrx/store";
import { first, finalize, filter, map, concatMap, withLatestFrom } from "rxjs/operators";
import { IAppState } from 'app/store/state/app.state';
import { CourseExchangeInstitutionListItem } from 'app/shared/courseExchange/models';
import * as fromCourseExchangeInstitution from '@courseExchange/institution';


@Injectable()
export class CourseExchangeInstitutionResolver implements Resolve<CourseExchangeInstitutionListItem>{
    isLoading = false;
    constructor(private store: Store<IAppState>){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
        const courseExchangeId = route.params["courseExchangeId"];
        const institutionId = route.params['institutionId'];
        return this.store 
            .pipe(
                select(fromCourseExchangeInstitution.Selectors.CurrentInstitution),
                map((currentInstitution)=> !!currentInstitution ? (!!currentInstitution.courseExchangeId == courseExchangeId &&
                                                                  !!currentInstitution.institutionId === institutionId) : false),
                map((institutionLoaded)=>{
                    if(!this.isLoading && !institutionLoaded){
                        this.isLoading = true;
                        this.store.dispatch(fromCourseExchangeInstitution.Actions.LoadCurrentInstitution({courseExchangeId, institutionId}));
                    }
                    return true;
                }),
                filter(institutionLoaded => institutionLoaded),
                first(),
                finalize(()=>this.isLoading=false)
            )
    }
}