import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { Store, select } from "@ngrx/store";
import { tap, first, finalize, filter } from "rxjs/operators";
import { IAppState } from 'app/store/state/app.state';
import * as fromRefData from '@refData';
import { EnrollmentStatus } from '@refData';

@Injectable()
export class EnrollmentStatusesResolver implements Resolve<EnrollmentStatus[]>{
    isLoading = false;
    constructor(private store: Store<IAppState>){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
        return this.store 
            .pipe(
                select(fromRefData.Selectors.enrollmentStatuses),
                tap((enrollmentStatuses)=>{
                    if(!!enrollmentStatuses && enrollmentStatuses.length>0){
                        this.store.dispatch(fromRefData.Actions.LoadEnrollmentStatusesSuccess({enrollmentStatuses}));
                    }else if(!this.isLoading){
                        this.isLoading = true;
                        this.store.dispatch(fromRefData.Actions.LoadEnrollmentStatuses({forceReload:false}));
                    }
                    return true;
                }),
                filter(enrollmentStatusesLoaded => !!enrollmentStatusesLoaded),
                first(),
                finalize(()=>this.isLoading=false)
            )
    }
}