<mat-toolbar>
  <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <span class="title dialog-title">Data Migration</span>
    <button mat-icon-button (click)="close()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div class="mat-dialog-container">
  <div class="content">
    <div>
      <div class="migration">
        <button mat-icon-button fxFlex (click)="getStudentMergeData()" class="migration-item">
          <mat-icon>get_app</mat-icon>GET Students
        </button>
        <button mat-icon-button fxFlex (click)="studentMergeData()" class="migration-item">
          <mat-icon>save</mat-icon>POST Students
        </button>
      </div>
      <div class="migration">
        <button mat-icon-button fxFlex (click)="getMemberEnrollmentMigrationData()" class="migration-item">
          <mat-icon>get_app</mat-icon>GET Enrollments
        </button>
        <button mat-icon-button fxFlex (click)="memberEnrollmentMergeData()" class="migration-item">
          <mat-icon>save</mat-icon>POST Enrollments
        </button>
      </div>
      <div class="migration">
        <button mat-icon-button fxFlex (click)="getMemberCourseMergeData()" class="migration-item">
          <mat-icon>get_app</mat-icon>GET Courses
        </button>
      </div>
      <div class="migration">
        <button mat-icon-button fxFlex (click)="getGradeMigrationData()" class="migration-item">
          <mat-icon>get_app</mat-icon>GET Grades
        </button>
      </div>
    </div>
  </div>
</div>
<div class="actions">
  <button mat-raised-button color="warn" (click)="close()">
    <mat-icon>close</mat-icon>
    No, Cancel
  </button>
</div>