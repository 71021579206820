import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { Store, select } from "@ngrx/store";
import { tap, first, finalize, filter, map } from "rxjs/operators";
import { IAppState } from 'app/store/state/app.state';
import * as fromRefData from '@refData';

@Injectable()
export class CourseExchangeRolesResolver implements Resolve<fromRefData.CourseExchangeRole[]>{
    isLoading = false;
    constructor(private store: Store<IAppState>){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
        return this.store 
            .pipe(
                select(fromRefData.Selectors.courseExchangeRoles),
                map((courseExchangeRoles)=>{
                    if(!!courseExchangeRoles && courseExchangeRoles.length>0){
                        this.store.dispatch(fromRefData.Actions.LoadCourseExchangeRolesSuccess({courseExchangeRoles}));
                    }else if(!this.isLoading ){
                        this.isLoading = true;
                        this.store.dispatch(fromRefData.Actions.LoadCourseExchangeRoles({forceReload:false}));
                    }
                    return true;
                }),
                filter(courseExchangeRoles => !!courseExchangeRoles),
                first(),
                finalize(()=>this.isLoading=false)
            )
    }
}