<div [ngClass]="['member-course-item','list-item--custom', course.isActive ? 'is_active' : '']">
  <div class="select">
    <mat-checkbox (change)="toggleMemberCourse.emit(course.id)"
                  [checked]="course.selected"></mat-checkbox>
  </div>

  <div class="logo">
    <img src="{{course.logoUrl}}">
  </div>

  <div class="menu">
    <mat-menu #coursesListMenu="matMenu">
      <button mat-menu-item (click)="goToCourseDetail.emit(course)">View Course Details</button>
      <button mat-menu-item (click)="enroll.emit(course.id)" *ngIf="canEnrollStudent">Enroll a Student</button>
      <button mat-menu-item *ngIf="course.isFavourited"(click)="confirmDeselectCourse(course)">Remove From Favorites</button>
      <button mat-menu-item *ngIf="course.isFavourited"(click)="manageMemberCourseCodes(course)">Manage Course Codes</button>
      <button mat-menu-item *ngIf="!course.isFavourited"(click)="preSelectCourse(course)">Pre-select Course</button>
    </mat-menu>
    <button mat-icon-button
            [matMenuTriggerFor]="coursesListMenu">
      <mat-icon>more_horiz</mat-icon>
    </button>
  </div>

  <div class="content-row">
    <div class="col">
      <div class="box-info">
        <div class="text text-title">
          <div class="icon" (click)="course.isFavourited ? confirmDeselectCourse(course) : preSelectCourse(course)">
            <mat-icon *ngIf="course.isFavourited">star</mat-icon>
            <mat-icon *ngIf="!course.isFavourited">star_border</mat-icon>
          </div>
          {{getCourseDescription(course)}}
        </div>
        <div class="text">{{getInstitutionalCourseCodes(course)}}</div>
        <div class="text">Offered by: {{course.institution.description}}</div>
      </div>
    </div>

    <div class="col">
      <div class="box-info">
        <div class="text">{{course.level.description}}</div>
        <div class="text">{{course.category.description}}, {{course.subCategory.description}}</div>
      </div>
    </div>

    <div class="col">
      <div class="box-info">
        <div class="text">
          {{course.sessionCode}} {{course.startDate | date: 'MM/dd/yyyy'}} - {{course.endDate | date: 'MM/dd/yyyy'}}
        </div>

        <div class="text">
          Registration Start: {{course.registrationStartDate | date: 'MM/dd/yyyy'}}
        </div>

        <div class="text">
          Registration End: {{course.registrationDeadlineDate | date: 'MM/dd/yyyy'}}
        </div>
      </div>
    </div>

    <div class="col">
      <div class="box-info">
        <div class="text">
          Fee Earned: {{course.feeEarnedDate | date: 'MM/dd/yyyy'}}
        </div>

        <div class="text">
          Drop Deadline: {{course.dropDeadlineDate | date: 'MM/dd/yyyy'}}
        </div>

        <div class="text">
          Withdrawal Deadline: {{course.withdrawalDeadlineDate | date: 'MM/dd/yyyy'}}
        </div>
      </div>
    </div>
  </div>
</div>
