import { FacultyCV } from 'app/shared/institution/models';
import { createReducer, on } from '@ngrx/store';

import { Sort, Order } from '@core/models';
import { Filter, SingleFilter, SemesterFilter, FilterType } from '@shared/common/models';
import { MemberCourseType } from '@member/store/enums/memberCourseType';
import { MemberCourseListItem } from '@member/memberCourse/models';
import toggleItem from '@shared/common/helpers/toggleItem';
import * as fromAuth from '@auth/store';
import * as fromStore from '@member/memberCourse/store'
import * as fromMember from '@member/store';
import * as fromInstitution from '@institution/store';
import * as fromCourseExchange from '@courseExchange/store';
import getSemesterFilters from '@shared/common/helpers/getSemesterFilters';
import getCurrentAcademicYear from '@shared/common/helpers/getCurrentAcademicYear';
import { MemberCourse } from '@shared/member/models';
import getMemberCourseListItems from './helpers/getMemberCourseListItems';
import convertMemberCourseListItemDates from '@shared/common/helpers/convertMemberCourseListItemDates';
export interface MemberCourseState {
  memberId: string,
  memberCourseListItems: MemberCourseListItem[],
  isMemberCourseListItemsLoading: boolean,
  currentMemberCourseListItem: MemberCourseListItem,
  isCurrentMemberCourseLoading: boolean,
  isPreSelectSingleCourseSending: boolean,
  isPreSelectCourseSending: boolean,
  isDeselectCoursesSending: boolean,
  isRemoveInstitutionalCodeSending: boolean,
  isAddInstitutionalCodeSending: boolean,
  presetFilterType: SingleFilter
  semesterFilters: SemesterFilter[],
  courseLevelFilters: Filter[],
  providerFilters: Filter[],
  categoryFilters: Filter[],
  subCategoryFilters: Filter[],
  tagFilters: Filter[],
  sorts: Sort[],
  currentSort: Sort,
  orders: Order[],
  currentOrder: Order,
  searchText: string,
  startDate: Date,
  endDate: Date,
  memberCourses: MemberCourse[],
  areMemberCoursesLoaded: boolean,
  currentMemberCourse: MemberCourse,
  combinedMemberCourseListItems: MemberCourseListItem[],
  currentFacultyCVs: FacultyCV[]
}
export const initialMemberCourseState: MemberCourseState = {
  memberId: '',
  memberCourseListItems: new Array<MemberCourseListItem>(),
  isMemberCourseListItemsLoading: false,
  currentMemberCourseListItem: undefined,
  isCurrentMemberCourseLoading: false,
  isPreSelectSingleCourseSending: false,
  isPreSelectCourseSending: false,
  isDeselectCoursesSending: false,
  isRemoveInstitutionalCodeSending: false,
  isAddInstitutionalCodeSending: false,
  presetFilterType: { id: MemberCourseType.MyCourses, description: 'My Courses' },
  semesterFilters: [],
  courseLevelFilters: [],
  providerFilters: [],
  categoryFilters: [],
  subCategoryFilters: [],
  tagFilters: [],
  sorts: [],
  currentSort: undefined,
  orders:[],
  currentOrder: undefined,
  searchText: '',
  startDate: undefined,
  endDate: undefined,
  memberCourses: [],
  areMemberCoursesLoaded: false,
  currentMemberCourse: undefined,
  combinedMemberCourseListItems: [],
  currentFacultyCVs: new Array<FacultyCV>()
};

export const memberCourseReducer = createReducer(
  initialMemberCourseState,
  on(fromAuth.Actions.selectCourseExchangeId, (state,action)=>{
    return initialMemberCourseState
  }),
  on(fromStore.Actions.LoadMemberCourseListItems, (state, action)=>{
    const memberId = !!action.memberId ? action.memberId : state.memberId;
    const startDate = !!action.startDate ? action.startDate : state.startDate;
    const endDate = !!action.endDate ? action.endDate : state.endDate;

    return {
      ...state,
      isMemberCourseListItemsLoading: true,
      memberId,
      startDate,
      endDate
    }
  }),
  on(fromStore.Actions.LoadMemberCourseListItemsSuccess, (state, action) => {
    const memberCourseListItems = action.memberCourseListItems;
    const combinedMemberCourseListItems = getMemberCourseListItems(action.memberCourseListItems, state.memberCourses);
    return {
      ...state,
      memberCourseListItems,
      combinedMemberCourseListItems,
      isMemberCourseListItemsLoading: false
    }
  }),
  on(fromStore.Actions.LoadMemberCoursesSuccess, (state, {memberCourses}) => {
    const combinedMemberCourseListItems = getMemberCourseListItems(state.memberCourseListItems, memberCourses);
    return {
      ...state,
      combinedMemberCourseListItems,
      memberCourses,
      areMemberCoursesLoaded: true
    }
  }),
  on(fromStore.Actions.LoadMemberCourseListItemsError, (state,action)=>{
    return {
      ...state,
      isMemberCourseListItemsLoading : false
    }
  }),
  on(fromStore.Actions.LoadCurrentMemberCourseListItem, (state, action)=>{
    return {
      ...state,
      isCurrentMemberCourseLoading: true
    }
  }),
  on(fromStore.Actions.LoadCurrentMemberCourseListItemSuccess, (state, {currentMemberCourseListItem}) => {
    currentMemberCourseListItem = convertMemberCourseListItemDates(currentMemberCourseListItem);
    return {
      ...state,
      currentMemberCourseListItem,
      isCurrentMemberCourseLoading: false
    }
  }),
  on(fromStore.Actions.LoadCurrentMemberCourseListItemError, (state,action)=>{
    return {
      ...state,
      isCurrentMemberCourseLoading : false
    }
  }),
  on(fromStore.Actions.LoadSorts, (state, {sorts})=>{
    return {
      ...state,
      sorts
    }
  }),
  on(fromStore.Actions.ToggleSemesterFilter, (state, { id }) => {
    const semesterFilters = toggleItem(state.semesterFilters, id);
    return {
      ...state,
      semesterFilters
    }
  }),
  on(fromStore.Actions.ToggleCourseLevelFilter, (state, { id }) => {
    const courseLevelFilters = toggleItem(state.courseLevelFilters, id);
    return {
      ...state,
      courseLevelFilters
    }
  }),
  on(fromStore.Actions.ToggleProviderFilter, (state, { id }) => {
    const providerFilters = toggleItem(state.providerFilters, id);
    return {
      ...state,
      providerFilters
    }
  }),
  on(fromStore.Actions.ToggleCategoryFilter, (state, { id }) => {
    const categoryFilters = toggleItem(state.categoryFilters, id);
    return {
      ...state,
      categoryFilters
    }
  }),
  on(fromStore.Actions.ToggleSubCategoryFilter, (state, { id }) => {
    const subCategoryFilters = toggleItem(state.subCategoryFilters, id);
    return {
      ...state,
      subCategoryFilters
    }
  }),
  on(fromStore.Actions.ToggleTagFilter, (state, { id }) => {
    const tagFilters = toggleItem(state.tagFilters, id);
    return {
      ...state,
      tagFilters
    }
  }),
  on(fromStore.Actions.ChangePresetFilterType, (state, {presetFilterType})=>{
    return {
      ...state,
      presetFilterType
    }
  }),
  on(fromStore.Actions.ChangeSearchText, (state, { searchText }) => {
    return {
      ...state,
      searchText
    }
  }),
  on(fromStore.Actions.ChangeSort, (state, { currentSort }) => {
    return {
      ...state,
      currentSort
    }
  }),
  on(fromStore.Actions.ChangeOrder, (state, { currentOrder }) => {
    return {
      ...state,
      currentOrder
    }
  }),
  on(fromStore.Actions.RemoveFilter, (state, { filter }) => {
    let semesterFilters = state.semesterFilters;
    let courseLevelFilters = state.courseLevelFilters;
    let providerFilters = state.providerFilters;
    let categoryFilters = state.categoryFilters;
    let subCategoryFilters = state.subCategoryFilters;
    let tagFilters = state.tagFilters
    switch (filter.type) {
      case FilterType.Semester:
        semesterFilters = toggleItem(semesterFilters, filter.id);
        break;
      case FilterType.CourseLevel:
        courseLevelFilters = toggleItem(courseLevelFilters, filter.id);
        break;
      case FilterType.Provider:
        providerFilters = toggleItem(providerFilters, filter.id);
        break;
      case FilterType.CourseCategory:
        categoryFilters = toggleItem(categoryFilters, filter.id);
        break;
      case FilterType.CourseSubCategory:
        subCategoryFilters = toggleItem(subCategoryFilters, filter.id);
        break;
      case FilterType.Tag:
        tagFilters = toggleItem(tagFilters, filter.id);
        break;
    }
    return {
      ...state,
      semesterFilters,
      courseLevelFilters,
      providerFilters,
      categoryFilters,
      subCategoryFilters,
      tagFilters
    }
  }),
  on(fromStore.Actions.ToggleMemberCourse, (state, { id }) => {
    const combinedMemberCourseListItems = toggleItem(state.combinedMemberCourseListItems, id);

    return {
      ...state,
      combinedMemberCourseListItems
    }
  }),
  on(fromStore.Actions.ClearSelected, (state, action) => {
    const combinedMemberCourseListItems = state.combinedMemberCourseListItems.map((course)=>{
      return {...course, selected: false}
    });
    const searchText = '';
    return {
      ...state,
      combinedMemberCourseListItems,
      searchText
    };
  }),
  on(fromStore.Actions.PreSelectSingleCourse, (state,action)=>{
    return {
      ...state,
      isPreSelectSingleCourseSending: true
    }
  }),
  on(fromStore.Actions.PreSelectCourses, (state,action)=>{
    return {
      ...state,
      isPreSelectCourseSending : true
    }
  }),
  on(fromStore.Actions.PreSelectCoursesSuccess, (state, action)=>{
    const combinedMemberCourseListItems = state.combinedMemberCourseListItems.map((item)=>{
      const idx = action.memberCourseListItems.findIndex(x=>x.id===item.id);
      if(idx!=-1){
        return {...item, isFavourited: true};
      }
      return item;
    });
    return {
      ...state,
      isPreSelectCourseSending: false,
      combinedMemberCourseListItems
    }
  }),
  on(fromStore.Actions.PreSelectCoursesError, (state,action)=>{
    return {
      ...state,
      isPreSelectCourseSending : false
    }
  }),
  on(fromStore.Actions.DeselectCourses, (state,action)=>{
    const memberCourses = state.memberCourses.filter(x=>action.memberCourseIds.findIndex(y=>x.id)===-1);
    const combinedMemberCourseListItems = getMemberCourseListItems(state.memberCourseListItems,memberCourses);
    return {
      ...state,
      memberCourses,
      combinedMemberCourseListItems,
      isDeselectCoursesSending: true
    }
  }),
  on(fromStore.Actions.DeselectCoursesSuccess, (state, action)=>{
    const combinedMemberCourseListItems = state.combinedMemberCourseListItems.map((item)=>{
      const idx = action.memberCourseListItems.findIndex(x=>x.providerCourseId==item.providerCourseId);
      if(idx!==-1){
        return {...item, isFavourited:false, memberCourseId: "", institutionalCourseCode: ""};
      }
      return item;
    });
    
    return{
      ...state,
      combinedMemberCourseListItems,
      isDeselectCoursesSending: false
    }
  }),
  on(fromStore.Actions.DeselectCoursesError, (state,action)=>{
    return {
      ...state,
      isDeselectCoursesSending: false
    }
  }),
  on(fromStore.Actions.AddInstitutionalCode, (state,action)=>{
    return {
      ...state,
      isAddInstitutionalCodeSending: true
    }
  }),
  on(fromStore.Actions.RemoveInstitutionalCode, (state,action)=>{
    return {
      ...state,
      isRemoveInstitutionalCodeSending: true
    }
  }),
  on(fromStore.Actions.LoadCourseLevelFilters, (state, {courseLevelFilters})=>{
    return{
      ...state,
      courseLevelFilters
    }
  }),
  on(fromCourseExchange.Actions.LoadCourseExchangeSummarySuccess, (state, action)=>{
    const providerFilters = action.courseExchangeSummary.providers.map(provider=>{
      return {
        id: provider.id,
        type: FilterType.Provider,
        description: provider.description,
        descriptionForView: provider.description,
        selected: false
      }
    });
    return {
      ...state,
      providerFilters
    }
  }),
  on(fromStore.Actions.LoadCourseCategoryFilters, (state, action)=>{
    const categoryFilters = action.courseCategoryFilters;
    return{
      ...state,
      categoryFilters
    }
  }),
  on(fromStore.Actions.LoadCourseSubCategoryFilters, (state, {subCategoryFilters})=>{
    return{
      ...state,
      subCategoryFilters
    }
  }),
  on(fromStore.Actions.LoadCourseTagFilters, (state, {courseTagFilters})=>{
    return{
      ...state,
      courseTagFilters
    }
  }),
  on(fromInstitution.Actions.ChangeCurrentAcademicYear, (state, {academicYear})=>{
    const semesterFilters = getSemesterFilters(academicYear);

    return {
      ...state,
      semesterFilters
    }
  }),
  on(fromStore.Navigate.MemberCourseDetail, (state, {currentMemberCourseListItem})=>{
    currentMemberCourseListItem = convertMemberCourseListItemDates(currentMemberCourseListItem);
    return {
      ...state,
      currentMemberCourseListItem
    }
  }),
  on(fromMember.Navigate.MemberCourses, (state, action)=>{
    const presetFilterType = !!action.memberCourseType ? fromStore.MemberCourseTypes.find(x=>x.id==action.memberCourseType) : state.presetFilterType
    return {
      ...state,
      presetFilterType,
      searchText: ''
    }
  }),
  on(fromInstitution.Actions.LoadCalendarSuccess, (state, action)=>{
    const academicYears = action.academicYears;
    const currentAcademicYear = getCurrentAcademicYear(academicYears);
    const semesterFilters = getSemesterFilters(currentAcademicYear);
    return{
      ...state,
      academicYears,
      currentAcademicYear,
      semesterFilters
    }
  }),
  on(fromStore.Actions.MemberCourseListItemAdded, (state, {memberCourseListItem})=>{
    let memberCourseListItems = new Array<MemberCourseListItem>();
    const idx = state.memberCourseListItems.findIndex(x=>x.id === memberCourseListItem.id);
    if(idx===-1){
      memberCourseListItems = [...state.memberCourseListItems, memberCourseListItem];
    }else{
      memberCourseListItems = [...state.memberCourseListItems.slice(0,idx), memberCourseListItem, ...state.memberCourseListItems.slice(idx+1)];
    }

    const combinedMemberCourseListItems = getMemberCourseListItems(memberCourseListItems, state.memberCourses);

    return {
      ...state,
      memberCourseListItems,
      combinedMemberCourseListItems
    }
  }),
  on(fromStore.Actions.MemberCourseListItemEdited, (state, {memberCourseListItem})=>{
    const idx = state.memberCourseListItems.findIndex(x=>x.id===memberCourseListItem.id);
    if(idx===-1) return state;
    const selected = state.memberCourseListItems[idx].selected;
    const newMemberCourse = {...memberCourseListItem, selected};
    const memberCourseListItems = [...state.memberCourseListItems.slice(0,idx),newMemberCourse,...state.memberCourseListItems.slice(idx+1)];
    const currentMemberCourseListItem = !!state.currentMemberCourseListItem && state.currentMemberCourseListItem.id==memberCourseListItem.id ? newMemberCourse : state.currentMemberCourseListItem;
    const combinedMemberCourseListItems = getMemberCourseListItems(memberCourseListItems, state.memberCourses);
    return {
      ...state,
      memberCourseListItems,
      combinedMemberCourseListItems,
      currentMemberCourseListItem
    }
  }),
  on(fromStore.Actions.MasterToggled, (state, action)=>{
    const combinedMemberCourseListItems = state.combinedMemberCourseListItems.map((item)=>{
      const idx = action.memberCourseListItems.findIndex(x=>x.id===item.id);
      if(idx===-1){
        return item;
      }else{
        return action.memberCourseListItems[idx];
      }
    })
    return {
      ...state,
      combinedMemberCourseListItems
    }
  }),
  on(fromStore.Actions.MemberCourseListItemsDeleted, (state, {memberCourseListItemIds})=>{
    const memberCourseListItems = state.memberCourseListItems.filter(x=>memberCourseListItemIds.findIndex(y=>y==x.id)!==-1);
    const combinedMemberCourseListItems = getMemberCourseListItems(memberCourseListItems, state.memberCourses);
    return {
      ...state,
      memberCourseListItems,
      combinedMemberCourseListItems
    }
  }),
  on(fromStore.Actions.ManageMemberCourseCodes, (state, {providerCourseId, memberCourseId})=>{
    let currentMemberCourseListItem = state.combinedMemberCourseListItems.find(x=>x.providerCourseId===providerCourseId);
    currentMemberCourseListItem.memberCourseId = memberCourseId;
    return{
      ...state,
      currentMemberCourseListItem
    }
  }),
  on(fromStore.Actions.MemberCourseAdded, (state, {memberCourse})=>{
    const idx = state.memberCourses.findIndex(x=> x.id==memberCourse.id);
    let memberCourses = state.memberCourses;
    if(idx !== -1){
      memberCourses = [...memberCourses.slice(0,idx), memberCourse, ...memberCourses.slice(idx+1)];
    }else{
      memberCourses = [...memberCourses, memberCourse];
    }
    const combinedMemberCourseListItems = getMemberCourseListItems(state.memberCourseListItems, memberCourses);
    return {
      ...state,
      memberCourses,
      combinedMemberCourseListItems
    }
  }),
  on(fromStore.Actions.MemberCourseUpdated, (state, {memberCourse})=>{
    const idx = state.memberCourses.findIndex(x=> x.id==memberCourse.id);
    if(idx === -1) return state;

    const memberCourses = [...state.memberCourses.slice(0,idx), memberCourse, ...state.memberCourses.slice(idx+1)];
    const combinedMemberCourseListItems = getMemberCourseListItems(state.memberCourseListItems,memberCourses);
    return {
      ...state,
      memberCourses,
      combinedMemberCourseListItems
    }
  }),
  on(fromStore.Actions.MemberCourseDeleted, (state, {memberCourse})=>{
    const memberCourses = state.memberCourses.filter(x=>x.id != memberCourse.id);
    const combinedMemberCourseListItems = getMemberCourseListItems(state.memberCourseListItems, memberCourses);
    return{
      ...state,
      memberCourses,
      combinedMemberCourseListItems
    }
  }),
  on(fromStore.Actions.LoadFacultyCVListSuccess, (state, {currentFacultyCVs})=>{
    
    return{
      ...state,
      currentFacultyCVs
    }
  }),
)
