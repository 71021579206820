import { createReducer, on } from '@ngrx/store';
import * as fromSuperAdminDashboard from '@admin/dashboard/store';

export interface SuperAdminDashboardState {
    totalCourseExchanges: number;
    pendingCourseExchanges: number;
    totalInstitutions: number;
    isLoadDashboardData: boolean;
}

export const initialDashboardState: SuperAdminDashboardState = {
    totalCourseExchanges: 0,
    pendingCourseExchanges: 0,
    totalInstitutions:0,
    isLoadDashboardData: true
};

export const superAdminDashboardReducer = createReducer(
    initialDashboardState,
    on(fromSuperAdminDashboard.Actions.LoadDashboardDataSuccess, (state, action) => {
        const {totalCourseExchanges,pendingCourseExchanges,totalInstitutions} = action.data;
        return {
            ...state,
            totalCourseExchanges,
            pendingCourseExchanges,
            totalInstitutions,
            isLoadDashboardData: false
        }
    }),
    on(fromSuperAdminDashboard.Actions.LoadDashboardDataError, (state) => {
        return {
            ...state,
            isLoadDashboardData: false
        }
    })
)
