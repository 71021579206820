import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as fromStore from '@member/memberCourse/store';
import * as fromMember from '@member/store';

import { map, concatMap, withLatestFrom, tap } from 'rxjs/operators';
import { MemberService } from '@shared/member/services';
import { of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { IAppState } from 'app/store/state/app.state';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CompareCoursesDialogContainerComponent } from '@member/memberCourse/components';
@Injectable()
export class MemberCourseNavigateEffects {
  constructor(private actions$: Actions, private store: Store<IAppState>, private dialog: MatDialog, private router: Router) {
  }

  goToMemberCourseDetail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromStore.Navigate.MemberCourseDetail),
      concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromMember.Selectors.Member)))
      )),
      map(([action, member]) => {
        const memberId = member.id;
        const institutionId = member.institutionId;

        return this.router.navigate(['member',
          memberId,
          institutionId,
          'memberCourses',
          'detail',
          action.currentMemberCourseListItem.id]);
      }
      ))
  }, { dispatch: false });

  compareCourses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromStore.Navigate.CompareCourses),
      tap(() => this.dialog.open(CompareCoursesDialogContainerComponent)));
  }, { dispatch: false });

}
