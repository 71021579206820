import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Observable} from 'rxjs';
import {UserSummary} from 'app/authentication/models/userSummary.model';
import {IAppState} from 'app/store/state/app.state';
import {Store, select} from '@ngrx/store';
import * as fromAuth from '@auth/store';

@Component({
  selector: 'toolbar-secondary-user-info',
  templateUrl: './toolbar-secondary-user-info.component.html',
  styleUrls: ['./toolbar-secondary-user-info.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ToolbarSecondaryUserInfoComponent implements OnInit {
  @Input() actionName: string;
  @Input() logoUrl: string;
  
  currentUser$: Observable<UserSummary>;

  constructor(
    private store: Store<IAppState>
  ) {
  }

  ngOnInit(): void {
    this.currentUser$ = this.store.pipe(select(fromAuth.Selectors.CurrentUser));
  }
}
