<mat-toolbar class="mat-elevation-z1" [ngClass]="viewContextColor$ | async">
  <ng-container *ngIf="!(isAuthenticated$ | async)">
    <toolbar-not-authenticated
      [currentAccessRole]="currentAccessRole$ | async"></toolbar-not-authenticated>
  </ng-container>

  <ng-container *ngIf="(isAuthenticated$ | async)" [ngSwitch]="(currentAccessRole$ | async)?.viewContext">
    <super-admin-toolbar
      *ngSwitchCase="ViewContext.SuperAdmin"
      [currentAccessRole]="currentAccessRole$ | async"></super-admin-toolbar>

    <exchange-admin-toolbar
      *ngSwitchCase="ViewContext.CourseExchangeAdmin"
      [currentAccessRole]="currentAccessRole$ | async"
      [courseExchangeId]="courseExchangeId$ | async"></exchange-admin-toolbar>

    <provider-admin-toolbar
      *ngSwitchCase="ViewContext.Provider"
      [currentAccessRole]="currentAccessRole$ | async"></provider-admin-toolbar>

    <member-admin-toolbar
      *ngSwitchCase="ViewContext.Member"
      [currentAccessRole]="currentAccessRole$ | async"></member-admin-toolbar>
  </ng-container>
</mat-toolbar>
