<div class="toolbar" [ngClass]="currentAccessRole.viewContext">
  <div class="toolbar-part logo-wrap">
    <toolbar-logo></toolbar-logo>
  </div>

  <div class="toolbar-part center-icon-menu" *ngIf="showToolbarButtons$ | async">
    <div class="toolbar-icons-group">
      <toolbar-action (onClick)="goToDashboard()"
                      [icon]="'dashboard'"
                      [tooltipTitle]="'Dashboard'"></toolbar-action>

      <toolbar-action (onClick)="goToInstitutionsList()"
                      [icon]="'school'"
                      [tooltipTitle]="'Institution List'"></toolbar-action>

      <toolbar-action (onClick)="goToUserList()"
                      [icon]="'people'"
                      [tooltipTitle]="'User List'"></toolbar-action>
                      
      <toolbar-action (onClick)="goToCourseList()"
      [icon]="'import_contacts'"
      [tooltipTitle]="'Course List'"></toolbar-action>
      <toolbar-action (onClick)="goToEnrollments()"
                      [icon]="'how_to_reg'"
                      [tooltipTitle]="'Enrollment List'"></toolbar-action>
    </div>
  </div>

  <div class="toolbar-part user-info-and-menu">
    <toolbar-user-info></toolbar-user-info>

    <div class="toolbar-icons-group">
      <toolbar-notifications [currentAccessRole]="currentAccessRole"></toolbar-notifications>

      <toolbar-menu>
        <div class="toolbar-menu dropdown-menu">
          <toolbar-secondary-user-info
          [actionName]="'View Member Profile'"
          [logoUrl]="(currentCourseExchange$ | async)?.secondaryLogoUrl"></toolbar-secondary-user-info>

          <div class="toolbar-menu-links">
            <div *ngIf="(courseExchanges$ | async)?.length>1">
              <ng-container *ngFor="let courseExchange of courseExchanges$ | async">
                <a (click)="changeCourseExchange(courseExchange.courseExchangeId)" class="toolbar-menu-link">
                  <span class="icon">
                    <img [src]="courseExchange.secondaryLogoUrl">
                  </span>
                  <span class="text">{{courseExchange.name}}</span>
                </a>
              </ng-container>
            </div>
          </div>
          <div class="toolbar-menu-links">
            <ng-container *ngIf="showToolbarButtons$ | async">
              <ng-container *ngIf="showBilling$ | async">
                <a (click)="goToBilling()" class="toolbar-menu-link">
              <span class="icon">
                <mat-icon>receipt_long</mat-icon>
              </span>
                  <span class="text">Billing</span>
                </a>
              </ng-container>
              
              <ng-container *ngIf="showCourseExchangeCalendar$ | async">
                <a (click)="goToCalendar()" class="toolbar-menu-link">
              <span class="icon">
                <mat-icon>calendar_today</mat-icon>
              </span>
                  <span class="text">Calendar</span>
                </a>
              </ng-container>
            
              <ng-container *ngIf="showCourseCategories$ | async">
                <a (click)="goToCoursesData()" class="toolbar-menu-link">
              <span class="icon">
                <mat-icon>list</mat-icon>
              </span>
                  <span class="text">Course Categories</span>
                </a>
              </ng-container>

              <ng-container *ngIf="showCourseExchangeFees$ | async">
                <a (click)="goToFees()" class="toolbar-menu-link">
              <span class="icon">
                <mat-icon>attach_money</mat-icon>
              </span>
                  <span class="text">Fees by Level</span>
                </a>
              </ng-container>
            </ng-container>

            <a (click)="logout()" class="toolbar-menu-link">
              <span class="icon">
                <mat-icon>exit_to_app</mat-icon>
              </span>
              <span class="text">Log out</span>
            </a>
          </div>
        </div>
      </toolbar-menu>
    </div>
  </div>
</div>
