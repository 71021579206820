import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {IAppState} from '../../../../store/state/app.state';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {IControlsConfig} from '../../../../models/shared';
import {hasErrorForm} from '../../../../helpers/has-error-form';
import { MemberCourseListItem } from '@member/memberCourse/models';
import * as fromMemberCourse from '@member/memberCourse/store';

type IFormFields = {
  code: string;
};

type ILocalControlsConfig = IControlsConfig<IFormFields>;

@Component({
  selector: 'added-to-pre-select-course-dialog',
  templateUrl: './added-to-pre-select-course-dialog.component.html',
  styleUrls: ['./added-to-pre-select-course-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddedToPreSelectCourseDialogComponent implements OnInit, OnDestroy {

  form: UntypedFormGroup;
  currentMemberCourse$: Observable<MemberCourseListItem>;
  institutionCourseCodes$: Observable<Array<string>>;
  hasInstitutionCourseCodes$: Observable<boolean>;
  maxLengthForCode = 30;

  constructor(
    public matDialogRef: MatDialogRef<AddedToPreSelectCourseDialogComponent>,
    private store: Store<IAppState>,
    private formBuilder: UntypedFormBuilder,
  ) {
  }

  ngOnInit(): void {
    this.currentMemberCourse$ = this.store.pipe(select(fromMemberCourse.Selectors.CurrentMemberCourseListItem));
    this.institutionCourseCodes$ = this.store.pipe(select(fromMemberCourse.Selectors.InstitutionCourseCodes));
    this.hasInstitutionCourseCodes$ = this.store.pipe(select(fromMemberCourse.Selectors.HasInstitutionCourseCodes));
    this.matDialogRef.addPanelClass([
      'added-to-pre-select-course-dialog'
    ]);

    this.initForm();
  }

  ngOnDestroy(): void {
  }

  initForm(): void {
    this.form = this.formBuilder.group(this.shapeControlsConfig());
  }

  private shapeControlsConfig(): ILocalControlsConfig {
    const initialControlsConfig: ILocalControlsConfig = {
      code: ['', [Validators.required, Validators.maxLength(this.maxLengthForCode)]]
    };

    return initialControlsConfig;
  }

  hasError = (controlName: keyof IFormFields, errorName: string) => hasErrorForm(controlName, errorName, this.form);

  close(): void {
    this.matDialogRef.close();
  }

  onSave(memberCourseId: string): void {
    this.addInstitutionalCode(memberCourseId);
    this.close();
  }

  onCancel(): void {
    this.close();
  }

  onAddAnother(memberCourseId: string): void {
    this.addInstitutionalCode(memberCourseId);
  }

  addInstitutionalCode(memberCourseId: string): void {
    this.store.dispatch(fromMemberCourse.Actions.AddInstitutionalCode({
      memberCourseId,
      institutionalCourseCode: this.form.value.code
    }));
    this.form.patchValue({code: ''});
    this.form.markAsPristine();
    this.form.updateValueAndValidity();
  }

  removeInstitutionalCode(memberCourseId: string, institutionCourseCode: string): void {
    this.store.dispatch(fromMemberCourse.Actions.RemoveInstitutionalCode({
      memberCourseId,
      institutionCourseCode
    }));
  }
}
