import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialModule} from 'app/core/material/material.module';
import * as fromComponents from './components';
import {MatToolbarModule} from '@angular/material/toolbar';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import {courseCalendarReducer} from './store/reducers/calendar.reducers';
import * as fromServices from './services';
import * as fromEffects from './store/effects';
import {calendarStateFeatureKey} from './store/feature-keys';
import { CoreModule } from 'app/core/core.module';
import { InstitutionSharedModule } from '../institution/institution-shared.module';

@NgModule({
    declarations: [...fromComponents.COMPONENTS, ...fromComponents.ENTRY_COMPONENTS],
    imports: [
        CommonModule,
        CoreModule,
        MaterialModule,
        StoreModule.forFeature(calendarStateFeatureKey, courseCalendarReducer),
        EffectsModule.forFeature([...fromEffects.EFFECTS]),
        InstitutionSharedModule
    ],
    exports: [...fromComponents.COMPONENTS],
    providers: [
        ...fromServices.SERVICES
    ]
})
export class CalendarSharedModule {
}
