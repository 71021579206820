import { createEffect, Actions, ofType } from "@ngrx/effects";
import { Injectable } from "@angular/core";
import { map, switchMap, withLatestFrom, concatMap, tap } from "rxjs/operators";
import { ProviderService } from '@shared/provider/services';
import { of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { IAppState } from 'app/store/state/app.state';
import { Router } from '@angular/router';
import { RemoveFacultyCVFromCourseCommand, AddNewFacultyCVToCourseCommand } from '@shared/provider/commands';
import * as fromCore from '@core/store';
import * as fromProvider from '@provider/store';

@Injectable()
export class ProviderNavigationEffects {
    constructor(private actions$: Actions, private providerService: ProviderService,
        private store: Store<IAppState>, private router: Router) {
    }

    goToFees$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromProvider.Navigate.Fees),
            concatMap(action => of(action).pipe(
                withLatestFrom(this.store.pipe(select(fromProvider.Selectors.Provider)))
            )),
            tap(([action, provider]) => {
                const providerId = !!action.providerId ? action.providerId : provider.id;
                const institutionId = !!action.institutionId ? action.institutionId : provider.institutionId;
                this.router.navigate([`/provider/${providerId}/${institutionId}/fees`])
            })
        )
    }, { dispatch: false });

    goToCalendar$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromProvider.Navigate.Calendar),
            concatMap(action => of(action).pipe(
                withLatestFrom(this.store.pipe(select(fromProvider.Selectors.Provider)))
            )),
            tap(([action, provider]) => {
                const providerId = !!action.providerId ? action.providerId : provider.id;
                const institutionId = !!action.institutionId ? action.institutionId : provider.institutionId;
                this.router.navigate([`/provider/${providerId}/${institutionId}/calendars`])
            })
        )
    }, { dispatch: false });

    
    goToPolicies$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromProvider.Navigate.Policies),
            concatMap(action => of(action).pipe(
                withLatestFrom(this.store.pipe(select(fromProvider.Selectors.Provider)))
            )),
            tap(([action, provider]) => {
                const providerId = !!action.providerId ? action.providerId : provider.id;
                const institutionId = !!action.institutionId ? action.institutionId : provider.institutionId;
                this.router.navigate([`/provider/${providerId}/${institutionId}/policies`])
            })
        )
    }, { dispatch: false });

    goToProviderCourses$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromProvider.Navigate.ProviderCourses),
            concatMap(action => of(action).pipe(
                withLatestFrom(this.store.pipe(select(fromProvider.Selectors.Provider)))
            )),
            tap(([action, provider]) => {
                const providerId = !!action.providerId ? action.providerId : provider.id;
                const institutionId = !!action.institutionId ? action.institutionId : provider.institutionId;
                this.router.navigate([`/provider/${providerId}/${institutionId}/courses`])
            })
        )
    }, { dispatch: false });

    goToSchedules$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromProvider.Navigate.CourseSchedule),
            concatMap(action => of(action).pipe(
                withLatestFrom(this.store.pipe(select(fromProvider.Selectors.Provider)))
            )),
            tap(([action, provider]) => {
                const providerId = !!action.providerId ? action.providerId : provider.id;
                const institutionId = !!action.institutionId ? action.institutionId : provider.institutionId;
                this.router.navigate([`/provider/${providerId}/${institutionId}/schedule`])
            })
        )},{dispatch:false});

        addNewProviderCourse$ = createEffect(() => {
            return this.actions$.pipe(
                ofType(fromProvider.Navigate.ProviderCourses),
                concatMap(action => of(action).pipe(
                    withLatestFrom(this.store.pipe(select(fromProvider.Selectors.Provider)))
                )),
                tap(([action, provider]) => {
                    const providerId = !!action.providerId ? action.providerId : provider.id;
                    const institutionId = !!action.institutionId ? action.institutionId : provider.institutionId;
                    this.router.navigate([`/provider/${providerId}/${institutionId}/courses/detail/new`])
                })
            )},{dispatch:false});
        
        goToUsers$ = createEffect(() => {
                return this.actions$.pipe(
                    ofType(fromProvider.Navigate.Users),
                    concatMap(action => of(action).pipe(
                        withLatestFrom(this.store.pipe(select(fromProvider.Selectors.Provider)))
                    )),
                    tap(([action, provider]) => {
                        const providerId = !!action.providerId ? action.providerId : provider.id;
                        const institutionId = !!action.institutionId ? action.institutionId : provider.institutionId;
                        this.router.navigate([`/provider/${providerId}/${institutionId}/users`])
                    })
                )
            }, { dispatch: false });   
}