import { Injectable } from "@angular/core";
import * as fromMember from '..';
import { IAppState } from 'app/store/state/app.state';
import { Store } from '@ngrx/store';
import { RealTimeService } from "@core/services/realTime.service";
import { HubConnection } from "@microsoft/signalr";
import { Member } from "@shared/member/models";

@Injectable()
export class MemberRealtime{
    constructor(private store:Store<IAppState>, private realTimeService: RealTimeService){
        this.initSignalRMessages();
    }
    private initSignalRMessages() {
        this.realTimeService.hubConnection$.subscribe(hubConnection => {
                if (!hubConnection) return;
                this.memberUpdated(hubConnection);
        });
    }
    private memberUpdated(hubConnection: HubConnection) {
        hubConnection.on('MemberUpdated', (member: Member) => {
                this.store.dispatch(fromMember.Actions.MemberUpdated({ member }));
        });
    }
}