import { AcademicYear } from "@shared/calendar/models";
import convertSemesterDates from "./convertSemesterDates";

export default (academicYear:AcademicYear): AcademicYear => {
        return {
          ...academicYear,
          startDate: new Date(academicYear.startDate2.year,academicYear.startDate2.month-1, academicYear.startDate2.day),
          endDate: new Date(academicYear.endDate2.year, academicYear.endDate2.month-1, academicYear.endDate2.day),
          semesters : academicYear.semesters.map((semester)=>{
            return convertSemesterDates(semester);
          })
        };
  }
