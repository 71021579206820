import {AVATAR_COLORS} from '@shared/avatar/constants/colors';

export interface IInitials {
  firstInitial: string;
  secondInitial?: string;
}

export const getInitials = (name: string, twoLetters = true): IInitials => {
  const [firstPart, secondPart] = name.toLocaleUpperCase().split(' ');

  const result: IInitials = {
    firstInitial: firstPart.charAt(0)
  };

  if (twoLetters && secondPart) {
    result.secondInitial = secondPart.charAt(0);
  }

  return result;
}

export const getDefaultInitials = (): IInitials => ({
  firstInitial: 'A'
});

export const getBgColor = (initial: string): string => {
  return initial in AVATAR_COLORS.alphabets ? AVATAR_COLORS.alphabets[initial] : getDefaultBgColor();
}

export const getDefaultBgColor = () => (AVATAR_COLORS.default);

