import { CourseExchangeFee } from 'app/shared/courseExchange/models';

export function ProviderFeeSplit(courseExchangeFee: CourseExchangeFee):number {
    if(courseExchangeFee.memberFeeAmount== 0) return 0;

    return courseExchangeFee.providerFeeAmount/courseExchangeFee.memberFeeAmount;
}

export function ManagementFeeSplit(courseExchangeFee: CourseExchangeFee):number {
    if(courseExchangeFee.memberFeeAmount==0) return 0;

    return courseExchangeFee.managementFeeAmount / courseExchangeFee.memberFeeAmount;
}