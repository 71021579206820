import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromProviderDashboard from '@provider/dashboard/store';
@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature('provider/dashboard', fromProviderDashboard.providerDashboardReducer),
    EffectsModule.forFeature([...fromProviderDashboard.EFFECTS])
  ],
  providers: [...fromProviderDashboard.RESOLVERS]
})
export class ProviderDashboardStoreModule { }
