<elb-page-list-container [kind]="'provider'" class="page-list-provider-enrollment-list">

  <elb-page-list-content>

    <elb-page-list-sidebar>
      <div class="switch-container" *ngIf="canViewGrades$ | async">
        <mat-button-toggle-group name="mode" [value]="enrollmentListMode$ | async"
          (change)="changeEnrollmentMode($event)">
          <mat-button-toggle value="Enrollment">Enrollment</mat-button-toggle>
          <mat-button-toggle value="Grading">Grading</mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <mat-accordion>
        <mat-form-field class="academicYearFilter">
          <mat-select placeholder="Academic Year" [value]="currentAcademicYearId$ | async"
            (selectionChange)="changeAcademicYear($event.value)">
            <mat-select-trigger>
              {{(currentAcademicYear$ | async)?.name}}
            </mat-select-trigger>
            <mat-option *ngFor="let academicYear of academicYears$ | async" [value]="academicYear.id">
              {{academicYear.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-expansion-panel *ngFor="let semesterFilter of semesterFilters$ | async"
          (click)="toggleExpanded(semesterFilter.id)" [expanded]="semesterFilter.expanded">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3>
                <mat-checkbox [checked]="semesterFilter.selected" (click)="$event.stopPropagation();"
                  (change)="onToggleSemesterFilter(semesterFilter.id)"></mat-checkbox>
                {{semesterFilter.descriptionForView}}
              </h3>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="side-panel-list">
            <mat-selection-list (selectionChange)="onToggleSessionFilter($event); $event.stopPropagation();">
              <mat-list-option [value]="session.id" [selected]="session.selected"
                *ngFor="let session of semesterFilter.sessionFilters" (click)="$event.stopPropagation()">
                {{ session.descriptionForView }}
              </mat-list-option>
            </mat-selection-list>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="(statusFilters$ | async)?.length>0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 [matBadge]="statusFiltersCount$ | async" matBadgeOverlap="false">Status</h3>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="side-panel-list">
            <mat-selection-list (selectionChange)="onToggleStatusFilter($event)">
              <mat-list-option [value]="status.id" [selected]="status.selected"
                *ngFor="let status of statusFilters$ | async; trackBy: trackBy">
                {{ status.descriptionForView }}
              </mat-list-option>
            </mat-selection-list>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="(memberFilters$ | async)?.length>0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 [matBadge]="memberFilterCount$ | async" matBadgeOverlap="false">Member</h3>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="side-panel-list">
            <mat-selection-list (selectionChange)="onToggleMembersFilter($event)">
              <mat-list-option *ngFor="let member of memberFilters$ | async; trackBy: trackById" [value]="member.id"
                [selected]="member.selected">
                {{ member.descriptionForView }}
              </mat-list-option>
            </mat-selection-list>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="(tagFilters$ | async)?.length>0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 [matBadge]="tagFilterCount$ | async" matBadgeOverlap="false">Tags</h3>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="side-panel-list">
            <mat-selection-list (selectionChange)="onToggleTagFilter($event)">
              <mat-list-option *ngFor="let tag of tagFilters$ | async; trackBy: trackById" [value]="tag.id"
                [selected]="tag.selected">
                {{ tag.descriptionForView }}
              </mat-list-option>
            </mat-selection-list>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="(categoryFilters$ | async)?.length>0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 [matBadge]="categoryFilterCount$ | async" matBadgeOverlap="false">Category</h3>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="side-panel-list">
            <mat-selection-list (selectionChange)="onToggleCategoryFilter($event)">
              <mat-list-option *ngFor="let category of categoryFilters$ | async; trackBy: trackById" [value]="category.id"
                [selected]="category.selected">
                {{ category.descriptionForView }}
              </mat-list-option>
            </mat-selection-list>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="(subCategoryFilters$ | async)?.length>0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 [matBadge]="subCategoryFilterCount$ | async" matBadgeOverlap="false">Sub Category</h3>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="side-panel-list">
            <mat-selection-list (selectionChange)="onToggleSubCategoryFilter($event)">
              <mat-list-option *ngFor="let subCategory of subCategoryFilters$ | async; trackBy: trackById"
                [value]="subCategory.id" [selected]="subCategory.selected">
                {{ subCategory.descriptionForView }}
              </mat-list-option>
            </mat-selection-list>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </elb-page-list-sidebar>

    <elb-page-list-main>
      <elb-page-header [pageTitle]="pageTitle" [pageSubTitle]="pageSubTitle"
        (returnToDashboardClicked)="goToDashboard()">
      </elb-page-header>

      <elb-page-list-main-header>
        <div class="search-and-sort-list" fxLayout="row" fxLayoutAlign="space-between center">
          <!-- SEARCH -->
          <div class="search-wrapper">
            <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
              <mat-icon>search</mat-icon>
              <input #filter placeholder="Search Enrollments">
            </div>
          </div>
          <!-- / END SEARCH -->
          <!-- SORT MENU -->
          <div class="sort-wrapper" *ngIf="!(IsEnrollmentsLoading$ | async)">

            <mat-form-field class="full-list-sort">
              <mat-select placeholder="Sort By" [value]="currentSort$ | async"
                (selectionChange)="changeSort($event.value)">
                <mat-select-trigger>
                  <mat-icon>{{(currentSort$ | async)?.icon}}</mat-icon>&nbsp;{{(currentSort$ | async)?.name}}
                </mat-select-trigger>
                <mat-option *ngFor="let sort of sorts$ | async" [value]="sort">
                  <mat-icon>{{sort.icon}}</mat-icon>{{sort.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="full-list-sort">
              <mat-select placeholder="Order By" [value]="currentOrder$ | async"
                (selectionChange)="changeOrderBy($event.value)">
                <mat-select-trigger>
                  <mat-icon>{{(currentOrder$ | async)?.icon}}</mat-icon>&nbsp;{{(currentOrder$ | async)?.name}}
                </mat-select-trigger>
                <mat-option *ngFor="let order of orders$ | async" [value]="order">
                  <mat-icon>{{order.icon}}</mat-icon>{{order.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <!-- /END SORT -->
        </div>
        <!-- FILTERS  -->
        <div class="filters-wrapper" fxLayout="row" fxLayoutAlign="space-between center">
          <div class="list-select-all">
            <mat-checkbox (change)="masterToggle()" [checked]="isAllSelected$ | async"
              [indeterminate]="indeterminateSelected$ | async">
              Select All Enrollments ({{selectedStatus$ | async}})
            </mat-checkbox>
          </div>
          <div class="full-filters-chosen">
            <mat-chip-list>
              <mat-chip *ngFor="let filter of selectedFilters$ | async" [selectable]="false" [removable]="true"
                (removed)="removeFilter(filter)">
                {{filter.description}}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
            </mat-chip-list>
          </div>
        </div>
      </elb-page-list-main-header>

      <elb-page-list-main-body>
        <div class="enrollment-list" *ngIf="filteredEnrollments$ | async; let enrollments">
          <div class="enrollment-item list-item--custom" *ngFor="let enrollment of enrollments; trackBy: trackById">
            <div class="enrollment-checkbox">
              <mat-checkbox [checked]="enrollment.selected" (change)="onToggleEnrollment(enrollment.id)"></mat-checkbox>
            </div>

            <div class="enrollment-menu">
              <button mat-icon-button [matMenuTriggerFor]="selectMenu">
                <mat-icon>more_horiz</mat-icon>
              </button>

              <mat-menu #selectMenu="matMenu">
                <!-- <button mat-menu-item (click)="viewStudentDetails(enrollment.studentId)">View Student Details</button> -->
                <button mat-menu-item (click)="exportToExcel(enrollment)">Export to Excel</button>
                <button mat-menu-item (click)="goToStudentDetail(enrollment.studentId)">View Student Details</button>
              </mat-menu>
            </div>

            <div class="enrollment-content">
              <div class="student-info row">
                <div class="column-80ab">
                  <div class="member-logo"><img src="{{enrollment.memberLogoUrl}}"></div>
                </div>
                <div class="column-50">
                  <div class="student-name">
                    {{enrollment.studentFullName.lastName}},&nbsp;{{enrollment.studentFullName.firstName}}</div>
                  <div class="student-id"><strong>Student ID:</strong>&nbsp;{{enrollment.studentNumber}}</div>
                </div>
                <div class="column-50">
                  <div class="student-email">
                    <a href="mailto:{{enrollment.studentEmailAddress}}">
                      <mat-icon>mail</mat-icon><span class="link--email">{{enrollment.studentEmailAddress}}</span>
                    </a>
                  </div>
                  <div class="student-phone">
                    <a href="" class="link--phone">
                      <mat-icon>phone</mat-icon><span>{{enrollment.studentPhoneNumber}}</span>
                    </a>
                  </div>
                </div>
              </div>
              <div class="enrollment-title row">
                <div class="title--small">Enrollment Information:</div>
              </div>
              <div class="enrollment-info row">
                <div class="column-calc column-50">
                  <div class="course-title">{{getCourseTitle(enrollment)}} ({{enrollment.providerCourseCode}})</div>
                </div>
                <div class="column-30">

                  <div class="session-info">{{enrollment.sessionCode}}: <span
                      class="session-date">{{enrollment.sessionStartDate | date:
                      'MM/dd/yyyy'}}-{{enrollment.sessionEndDate | date: 'MM/dd/yyyy'}}</span></div>
                  <div class="current-enrollment-status">Status: <b>{{getEnrollmentStatusDescription$(enrollment) |
                      async}}</b></div>
                </div>
                <div class="column-20" *ngIf="(showEditStatus$(enrollment) | async)">
                  <mat-form-field class="full-list-sort">
                    <mat-select placeholder="Edit Status" [compareWith]="compare" [value]="enrollment.requestedStatus"
                      (selectionChange)="editStatus($event.value,enrollment.id)">
                      <mat-select-trigger>
                        <mat-icon>edit</mat-icon>&nbsp;{{enrollment.requestedStatus?.description}}
                      </mat-select-trigger>
                      <mat-option *ngFor="let edit of enrollment.status.providerAllowedStatuses" [value]="edit">
                        {{edit.description}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="column-20" *ngIf="(showEditLetterGrade$() | async)">
                  <mat-form-field class="full-list-sort">
                    <mat-select placeholder="Letter Grade" [value]="enrollment.requestedLetterGrade"
                      (selectionChange)="editLetterGrade($event.value,enrollment.id)">
                      <mat-select-trigger>
                        <mat-icon>edit</mat-icon>&nbsp;{{enrollment.requestedLetterGrade?.description}}
                      </mat-select-trigger>
                      <mat-option *ngFor="let grade of letterGrades$ | async" [value]="grade">
                        {{grade.description}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>

          </div>

        </div>
      </elb-page-list-main-body>
      <app-loading-spinner *ngIf="(IsEnrollmentsLoading$ | async)"></app-loading-spinner>
      <elb-page-list-main-footer>
        <ng-content></ng-content>
        <button type="button" class="submit-button icon-btn" mat-button [disabled]="!(canExportToExcel$ | async)"
          (click)="exportToExcel()">
          <mat-icon>save_alt</mat-icon>Export to Excel
        </button>
        <button type="button" class="submit-button icon-btn" mat-button (click)="submitStatusChanges()"
          *ngIf="((enrollmentListMode$ | async)==='Enrollment')" [disabled]="!(canSubmitChanges$ | async)">
          <mat-icon>save</mat-icon>Submit Changes
        </button>
        <button type="button" class="submit-button icon-btn" mat-button (click)="submitGrading()"
          *ngIf="((enrollmentListMode$ | async)==='Grading')" [disabled]="!(canSubmitGrading$ | async)">
          <mat-icon>save</mat-icon>Submit Grading
        </button>

        <button type="button" class="submit-button icon-btn" mat-button (click)="updateEnrollments()"
          *ngIf="((enrollmentListMode$ | async)==='Enrollment')"
          [disabled]="!(commonAllowedStatuses$ | async).length || !(selectedEnrollments$ | async).length">
          <mat-icon>check</mat-icon>Update Enrollment Statuses
        </button>
        <button type="button" class="submit-button icon-btn" mat-button (click)="updateLetterGrades()"
          *ngIf="((enrollmentListMode$ | async)==='Grading')"
          [disabled]="!(selectedEnrollmentsForGrading$ | async).length">
          <mat-icon>check</mat-icon>Update Letter Grades
        </button>
      </elb-page-list-main-footer>

    </elb-page-list-main>
  </elb-page-list-content>
</elb-page-list-container>