import { MemberCourseListItem } from '@member/memberCourse/models/memberCourseListItem.model';
import { MemberCourse } from './../../../../shared/member/models/memberCourse.model';
import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface PastStartDateDialogData {
  pastStartDateCourse: MemberCourseListItem;
  nextScheduledCourse: MemberCourseListItem;
}

export interface PastStartDateDialogPayload{
  selectedCourse: MemberCourseListItem;
}

@Component({
  selector: 'past-start-date-dialog',
  templateUrl: './past-start-date-dialog.component.html',
  styleUrls: ['./past-start-date-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PastStartDateDialogComponent implements OnInit {
  memberCourse: MemberCourseListItem;
  nextMemberCourse: MemberCourseListItem;
  constructor(
    public matDialogRef: MatDialogRef<PastStartDateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PastStartDateDialogData,
  ) {
    this.matDialogRef.addPanelClass('past-start-date-dialog');
    this.memberCourse = data.pastStartDateCourse;
    this.nextMemberCourse = data.nextScheduledCourse;
  }

  ngOnInit(): void {
  }

  getFormattedDate(memberCourse: MemberCourseListItem): string{
    return `${memberCourse.startDate2.month}/${memberCourse.startDate2.day}/${memberCourse.startDate2.year}-${memberCourse.endDate2.month}/${memberCourse.endDate2.day}/${memberCourse.endDate2.year}`;
  }

  getCourseDescription(course: MemberCourseListItem): string{
    return `${course.code}, ${course.title}`;
  }

  selectMemberCourse(memberCourse: MemberCourseListItem): void{
    const providerCourseSessionId = !!memberCourse ? memberCourse.providerCourseSessionId : '';
    this.matDialogRef.close(providerCourseSessionId);
  }

  close(): void {
    this.matDialogRef.close();
  }
}
