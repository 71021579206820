import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {StudentListItem} from '@shared/student/models';
import { IAppState } from 'app/store/state/app.state';
import { Store } from '@ngrx/store';
import * as fromStudent from '@member/students/store';
export interface StudentAddedDialogData {
  student: StudentListItem;
}

@Component({
  selector: 'student-added-dialog',
  templateUrl: './student-added-dialog.component.html',
  styleUrls: ['./student-added-dialog.component.scss', '../styles/student-added-delete-common.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StudentAddedDialogComponent {
  constructor(
    public matDialogRef: MatDialogRef<StudentAddedDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public matDialogData: StudentAddedDialogData,
    public store: Store<IAppState>
  ) {
    this.matDialogRef.addPanelClass('student-added-dialog');
  }

  close(): void {
    this.matDialogRef.close();
  }

  enrollStudent(): void{
    const studentId = this.matDialogData.student.id;
    this.store.dispatch(fromStudent.Actions.MasterToggle({toggle: false}));
    this.store.dispatch(fromStudent.Actions.ToggleStudent({studentId}));
    this.store.dispatch(fromStudent.Actions.EnrollStudent());
  }
}
