import {Component, EventEmitter, OnDestroy, OnInit, Output, Input} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {IAppState} from '../../../../store/state/app.state';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {AcademicYear} from '../../../../shared/calendar/models';
import {ICourseSchedule} from '../../../models/course-schedule.models';
import {IControlsConfig} from '../../../../models/shared';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {Provider} from '../../../../shared/provider/models';
import * as fromProvider from '@provider/store';
import * as fromCourseSchedule from '@provider/course-schedule/store';

type IFormFields = Pick<ICourseSchedule.DataNewSchedule, 'academicYearId' | 'semesterId'>;

type ILocalControlsConfig = IControlsConfig<IFormFields>;

@Component({
  selector: 'app-course-schedule-dialog-new',
  templateUrl: './course-schedule-dialog-new.component.html',
  styleUrls: ['./course-schedule-dialog-new.component.scss']
})
export class CourseScheduleDialogNewComponent implements OnInit {
  @Input() academicYears: AcademicYear[];
  @Input() selectedAcademicYear: AcademicYear;
  @Input() canSubmit: boolean;
  @Output() onClose = new EventEmitter();
  form: UntypedFormGroup;

  constructor(
    private store: Store<IAppState>,
    private formBuilder: UntypedFormBuilder,
  ) {
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group(this.shapeFormControlConfig());
  }

  private shapeFormControlConfig(): ILocalControlsConfig {
    return {
      academicYearId: [null, Validators.required],
      semesterId: [null, Validators.required]
    }
  }

  onSubmit(): void {
    this.store.dispatch(fromProvider.Navigate.CourseSchedule({}))
    this.onClose.emit();
  }

  onChangeSelectedAcademicYear(event: any): void {
    const id = event.value;
    this.store.dispatch(fromCourseSchedule.Actions.changeSelectedAcademicYear({
      payload: id
    }));
  }

  onChangeSelectedSemester(event: any): void {
    const id = event.value;

    this.store.dispatch(fromCourseSchedule.Actions.changeSelectedSemester({
      payload: id
    }));
  }
}
