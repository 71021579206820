import { createSelector, createFeatureSelector } from "@ngrx/store";
import { AuthState } from "./reducers";
import * as fromRefData from '@refData';
import { ViewContext } from '@auth/models/viewContext.enum';

export const selectAuthState = createFeatureSelector<AuthState>("auth");

export const isLoggedIn = createSelector(
    selectAuthState,
    (auth) => !!auth.user
);

export const isLoggedOut = createSelector(
    isLoggedIn,
    (loggedIn) => !loggedIn
);

export const CurrentUser = createSelector(
    selectAuthState,
    (auth) => auth.user
);

export const CurrentAccessRole = createSelector(
    selectAuthState,
    (auth) =>  auth.currentAccessRole
);

export const currentUserName = createSelector(
    CurrentUser,
    (user) => !!user ? user.fullName: ""
);

export const userAccessRoles = createSelector(
    CurrentUser,
    (user) => !!user ? user.accessRoles : []
);

export const currentAccessRoleId = createSelector(
    CurrentAccessRole,
    (accessRole) => accessRole === undefined ? null : accessRole.id
);

export const AccessRolesWithAccess = createSelector(
    userAccessRoles,
    (accessRoles) => 
        !!accessRoles ? accessRoles.filter(x=>x.hasAccess) : []
);

export const CourseExchanges = createSelector(
    AccessRolesWithAccess,
    fromRefData.courseExchanges,
    (accessRoles, courseExchanges) => 
        courseExchanges.filter(x=>accessRoles.find(accessRole=>accessRole.courseExchangeId==x.courseExchangeId) != undefined)
)
export const CurrentCourseExchangeId = createSelector(
    selectAuthState,
    (state) => state.currentCourseExchangeId
)
export const CurrentCourseExchange = createSelector(
    CurrentCourseExchangeId,
    CourseExchanges,
    (currentCourseExchangeId, courseExchanges) => courseExchanges.find(x=>x.courseExchangeId==currentCourseExchangeId)
)

export const CourseExchangePrimaryLogo = createSelector(
    CurrentCourseExchange,
    CurrentAccessRole,
    fromRefData.courseExchanges,
    (courseExchange,currentAccessRole) => {
        const taawanLogo = '\\assets\\images\\logos\\taawun_logo_plain_text_v1.png';
        if(!!currentAccessRole && currentAccessRole.viewContext===ViewContext.SuperAdmin) return taawanLogo;
        return !!courseExchange ? courseExchange.primaryLogoUrl : taawanLogo
    }
)

export const CourseExchangeSecondaryLogo = createSelector(
    CurrentCourseExchange,
    CurrentAccessRole,
    (courseExchange,currentAccessRole) => {
        return !!courseExchange ? courseExchange.secondaryLogoUrl : ''
    }
)

export const CurrentAccessRoles = createSelector(
    AccessRolesWithAccess,
    CurrentCourseExchangeId,
    (accessRoles, courseExchangeId) => {
        if(!courseExchangeId){
            var accessRole = accessRoles.find(x=>x.courseExchangeId != '');
            courseExchangeId = !!accessRole ? accessRole.courseExchangeId : '';
        }
        return accessRoles.filter(x=>x.courseExchangeId===courseExchangeId || x.viewContext===ViewContext.SuperAdmin)
                        .map(accessRole => {
                            return {
                                ...accessRole,
                                descriptionForView: accessRole.viewContext===ViewContext.CourseExchangeAdmin ? 'Admin':
                                                    accessRole.viewContext===ViewContext.SuperAdmin ? 'Super Admin':
                                                    accessRole.viewContext
                            }
                        });
    }
)

export const AccessRolesForEffects = createSelector(
    CurrentUser,
    CurrentAccessRole,
    CurrentAccessRoles,
    AccessRolesWithAccess,
    (currentUser,currentAccessRole, courseExchangeAccessRoles, allAccessRoles) =>{
        return {
            currentUser,
            currentAccessRole,
            courseExchangeAccessRoles,
            allAccessRoles
        }
    }
)
export const IsSuperAdmin = createSelector(
    CurrentUser,
    (user) => !!user && user.accessRoles.find(x=>x.viewContext===ViewContext.SuperAdmin) !== undefined
)

export const StartDate = createSelector(
    selectAuthState,
    (state) => state.startDate
)

export const EndDate = createSelector(
    selectAuthState,
    (state) => state.endDate
)

export const DateRange = createSelector(
    StartDate,
    EndDate,
    (startDate, endDate) =>{
        return {
            startDate,
            endDate
        }
    }
)

