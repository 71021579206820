import {createAction, props} from '@ngrx/store';
import {
  MemberCourse
} from '@shared/member/models';
import {MemberCourseListItem} from '../../models/memberCourseListItem.model';
import {Sort, Order, ElbertFile} from '../../../../core/models';
import {FacultyCV} from '../../../../shared/institution/models';
import {RemoveFacultyCVFromCourseCommand} from '../../../../shared/provider/commands';
import { SingleFilter, Filter } from '@shared/common/models';
import { AcademicYear } from '@shared/calendar/models';

export const LoadMemberCourseListItems = createAction(
  '[Member Courses Resolver] - Load Member Course List Items',
  props<{
    memberId?: string,
    startDate?: Date,
    endDate?: Date
  }>()
)

export const LoadMemberCourseListItemsSuccess = createAction(
  '[Member Course Effects] Load Member Course List Items Success',
  props<
  {
    memberCourseListItems: MemberCourseListItem[]
  }>()
);

export const LoadMemberCourseListItemsError = createAction(
  '[Member Course Effects] - Load Member Course List Items Error',
  props<{
    message: string
  }>()
)

export const LoadMemberCourses = createAction(
  '[Member Courses Resolver] - Load Member Courses',
  props<{
    memberId?: string
  }>()
)

export const LoadMemberCoursesSuccess = createAction(
  '[Member Course Effects] Load Member Courses Success',
  props<
  {
    memberCourses: MemberCourse[]
  }>()
);

export const LoadMemberCourseError = createAction(
  '[Member Course Effects] - Load Member Course Error',
  props<{
    message: string
  }>()
)

export const ChangePresetFilterType = createAction(
  '[Member Course List] - Change PreSelect Filter Type',
  props<{
    presetFilterType: SingleFilter
  }>()
)
export const ToggleSemesterFilter = createAction(
  '[Member Course List] - Toggle Semester Filter',
  props<{
      id: string
  }>()
)
export const ToggleCourseLevelFilter = createAction(
  '[Member Course List] - Toggle Course Level Filter',
  props<{
      id: string
  }>()
)
export const ToggleProviderFilter = createAction(
  '[Member Course List] - Toggle Provider Filter',
  props<{
      id: string
  }>()
)
export const ToggleCategoryFilter = createAction(
  '[Member Course List] - Toggle Category Filter',
  props<{
      id: string
  }>()
)
export const ToggleSubCategoryFilter = createAction(
  '[Member Course List] - Toggle Sub Category Filter',
  props<{
      id: string
  }>()
)
export const ToggleTagFilter = createAction(
  '[Member Course List] - Toggle Tag Filter',
  props<{
      id: string
  }>()
)
export const ChangeSearchText = createAction(
  '[Member Course List] - Change Search Text',
  props<{
    searchText: string
  }>()
)

export const LoadSorts = createAction(
  '[Member Course List] - Load Sorts',
  props<{
    sorts: Sort[]
  }>()
)

export const ChangeSort = createAction(
  '[Member Course List] - Change Sort',
  props<{
    currentSort: Sort
  }>()
)

export const ChangeOrder = createAction(
  '[Member Course List] - Change Order',
  props<{
    currentOrder: Order
  }>()
)

export const MasterToggle = createAction(
  '[Member Course List] - Master Toggle',
  props<{
    selected?: boolean
  }>()
)

export const MasterToggled = createAction(
  '[Member Course List] - MasterToggled',
  props<{
    memberCourseListItems: MemberCourseListItem[]
  }>()
)
export const ToggleMemberCourse = createAction(
  '[Member Course List] - Toggle Member Course',
  props<{
    id: string
  }>()
)

export const ClearSelected = createAction(
  '[Member Course List] - Clear Selected'
)

export const RemoveFilter = createAction(
  '[Member Course List] - Remove Filter',
  props<{
    filter: Filter
  }>()
)

export const LoadCurrentMemberCourseListItem = createAction(
  '[Member Course Resolver] - Load Current Member Course List Item',
  props<{
    memberId: string,
    memberCourseListItemId: string
  }>()
)

export const LoadCurrentMemberCourseListItemSuccess = createAction(
  '[Member Course Effect] - Load Current Member Course List Item Success',
  props<{
    currentMemberCourseListItem: MemberCourseListItem
  }>()
)

export const LoadCurrentMemberCourseListItemError = createAction(
  '[Member Course Effects] - Load Current Member Course Error',
  props<{
    message: string
  }>()
)

export const PreSelectSingleCourse = createAction(
  '[Member Course Detail] - Preselect Single Course',
  props<{
    providerCourseId: string
  }>()
);

export const PreSelectSingleCourseSuccess = createAction(
  '[Member Course Effects] - Preselect Single Course Success',
  props<{
    memberCourseListItems: MemberCourseListItem[]
  }>()
);

export const ManageMemberCourseCodes = createAction(
  '[Member Course List] - Manage Member Course Codes',
  props<{
    providerCourseId: string,
    memberCourseId: string
  }>()
)

export const PreSelectSingleCourseError = createAction(
  '[Member Course Effects] - Preselect Single Course Error',
  props<{
    message: string
  }>()
)

export const PreSelectCourses = createAction(
  '[Member Course List] - Preselect Courses',
  props<{
    providerCourseIds?: string[]
  }>()
)

export const PreSelectCoursesSuccess = createAction(
  '[Member Course Effect] - Preselect Courses Success',
  props<{
    memberCourseListItems: MemberCourseListItem[]
  }>()
)

export const PreSelectCoursesError = createAction(
  '[Member Course Effects] - Preselect Course Error',
  props<{
    message: string
  }>()
)

export const DeselectCourses = createAction(
  '[Member Course List] - Deselect Courses',
  props<{
    memberCourseIds?: string[]
  }>()
)

export const DeselectCoursesSuccess = createAction(
  '[Member Course Effects] - Deselect Courses Success',
  props<{
    memberCourseListItems: MemberCourseListItem[]
  }>()
)

export const DeselectCoursesError = createAction(
  '[Member Course Effects] - Deselect Course Error',
  props<{
    message: string
  }>()
)

export const RemoveInstitutionalCode = createAction(
  '[Member Course] Remove Institutional Code',
  props<{
    memberCourseId,
    institutionCourseCode: string
  }>()
);

export const RemoveInstitutionalCodeSuccess = createAction(
  '[Member Course] Remove Institutional Code Success',
  props<{
    memberCourseListItems: MemberCourseListItem[]
  }>()
);

export const RemoveInstitutionalCodeError = createAction(
  '[Member Course Effects] - Remove Institutional Code Error',
  props<{
    message: string
  }>()
)

export const AddInstitutionalCode = createAction(
  '[Member Course] Add Institution Course Code',
  props<{
    memberCourseId: string,
    institutionalCourseCode: string
  }>()
);

export const AddInstituionCourseCodeSuccess = createAction(
  '[Member Course] Add Institution Course Code Success',
  props<{
    memberCourseListItems: MemberCourseListItem[]
  }>()
)

export const AddInstitutionalCodeError = createAction(
  '[Member Course Effects] - Add Institution Course Code Error',
  props<{
    message: string
  }>()
)

export const LoadCourseLevelFilters = createAction(
  '[Member Course Effects] - Load Course Level Filters',
  props<{
    courseLevelFilters: Filter[]
  }>()
)
export const LoadProviderFilters = createAction(
  '[Member Course Effects] - Load Provider Filters',
  props<
  {
    providerFilters: Filter[]
  }>()
)
export const LoadCourseCategoryFilters = createAction(
  '[Member Course Effects] - Load Course Category Filters',
  props<{
    courseCategoryFilters: Filter[]
  }>()
)
export const LoadCourseSubCategoryFilters = createAction(
  '[Member Course Effects] - Load Course Sub Category Filters',
  props<{
    subCategoryFilters: Filter[]
  }>()
)
export const LoadCourseTagFilters = createAction(
  '[Member Course Exchange Ref Data] - Load Course Tag Filters',
  props<{
    courseTagFilters: Filter[]
  }>()
)

export const addedFacultyCVSuccess = createAction(
  '[Courses] addedFacultyCVSuccess',
  props<{
    payload: {
      facultyCV: FacultyCV,
      courseId: string
    }
  }>()
);

export const removeCourseFacultyCVSuccess = createAction(
  '[Courses] removeCourseFacultyCVSuccess',
  props<{
    payload: RemoveFacultyCVFromCourseCommand
  }>()
);

export const addExistingFacultyCVs = createAction(
  '[Courses] addExistingFacultyCVs',
  props<{
    payload: {
      facultyCVs: FacultyCV[];
      courseId: string
    }
  }>()
);

export const LoadFacultyCVList = createAction(
  '[Member Courses Effects] - Load Faculty CVs'
)
export const LoadFacultyCVListSuccess = createAction(
  '[Member Course Resolver] - Load Faculty CVs Success',
  props<{
    currentFacultyCVs: FacultyCV[];
  }>()
);


export const DownloadCourseSyllabus = createAction(
  '[Courses] - Download Member Course Syllabus',
  props<{
    courseId: string;
    syllabus: ElbertFile;
  }>()
)

export const ExportToExcel = createAction(
  '[Member Course list] - Export to Excel'
)

export const MemberCourseListItemAdded = createAction(
  '[Courses Effects] - Member Course ListItem Added',
  props<{
    memberCourseListItem: MemberCourseListItem
  }>()
)

export const MemberCourseListItemEdited = createAction(
  '[Courses Effects] - Member Course ListItem Edited',
  props<{
    memberCourseListItem: MemberCourseListItem
  }>()
)

export const MemberCourseListItemsDeleted = createAction(
  '[Courses Effects] - Member Course ListItems Deleted',
  props<{
    memberCourseListItemIds: Array<string>
  }>()
)

export const MemberCourseAdded = createAction(
  '[Courses Effects] - Member Course Added',
  props<{
    memberCourse: MemberCourse
  }>()
)

export const MemberCourseUpdated = createAction(
  '[Courses Effects] - Member Course Updated',
  props<{
    memberCourse: MemberCourse
  }>()
)

export const MemberCourseDeleted = createAction(
  '[Courses Effects] - Member Course Deleted',
  props<{
    memberCourse: MemberCourse  
  }>()
)





