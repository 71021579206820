import { AcademicYear } from '@shared/calendar/models';
import { SessionDateFilter } from '@shared/institution/models';
import * as moment from 'moment';
import { FilterType, SemesterFilter, SessionFilter } from '../models';

export default (academicYear: AcademicYear): SemesterFilter[] => {
  if(academicYear==undefined) return undefined;
  
  var result = academicYear.semesters.map(semester=>{
      var filter: SemesterFilter = {
          type: FilterType.Semester,
          id: semester.id,
          description: semester.code,
          descriptionForView: semester.code,
          selected: false,
          academicYearId: academicYear.id,
          startDate: new Date(semester.startDate2.year, semester.startDate2.month,semester.startDate2.day),
          endDate: new Date(semester.endDate2.year,semester.endDate2.month,semester.endDate2.day),
          expanded: false,
          sessionFilters: semester.sessions.map(session=>{
              var sessionFilter: SessionFilter ={
                type: FilterType.Session,
                id: session.id,
                description: session.code,
                descriptionForView: session.code,
                selected: false,
                semesterId: semester.id,
                academicYearId: academicYear.id,
                startDate: new Date(session.startDate2.year,session.startDate2.month,session.startDate2.day),
                endDate: new Date(session.endDate2.year,session.endDate2.month,session.endDate2.day)                  
              };
              return sessionFilter;
          })
      };
      return filter;
  })

  return result;
}
