import { createSelector, createFeatureSelector } from '@ngrx/store'
import * as fromCourseExchange from '@courseExchange/store';
import * as fromRefData from '@refData';
import { searchInText } from 'app/helpers/search-in-text';
import { EnrollmentListItemForView } from '@member/enrollment';
import { sortBy } from 'sort-by-typescript';
import { EnrollmentsState } from '../reducers/enrollment.reducer';
import * as moment from 'moment';

export const selectEnrollmentsState = createFeatureSelector<EnrollmentsState>('courseExchange/enrollments');

export const Enrollments = createSelector(
    selectEnrollmentsState,
    (state) => state.enrollments
)
export const CourseExchangeId = createSelector(
    selectEnrollmentsState,
    (state) => state.courseExchangeId
)

export const Orders = createSelector(
    selectEnrollmentsState,
    (state) => state.orders
)
export const CurrentOrder = createSelector(
    selectEnrollmentsState,
    (state) => state.currentOrder
)
export const Sorts = createSelector(
    selectEnrollmentsState,
    (state) => state.sorts
)
export const CurrentSort = createSelector(
    selectEnrollmentsState,
    (state) => state.currentSort
)

export const SearchText = createSelector(
    selectEnrollmentsState,
    (state) => state.searchText
)
export const StatusFilters = createSelector(
    selectEnrollmentsState,
    ({enrollments, statusFilters})=>{
        return statusFilters.filter(x=>enrollments.find(e=>e.status.id==x.id)).sort(sortBy('description')).map(statusFilter => {
            const count = !!enrollments ? enrollments.filter(enrollment=> enrollment.status.id===statusFilter.id).length : 0;
            return {
              ...statusFilter,
              descriptionForView: `${statusFilter.description} (${count})`
            }
          })
    }
)
export const SemesterFilters = createSelector(
    selectEnrollmentsState,
    Enrollments,
    ({semesterFilters}, enrollments) => {
        const result = semesterFilters.filter(x=>enrollments.find(e=>e.semesterId==x.id)).map(semesterFilter=>{
            const semesterCount = enrollments.filter(x=>moment(x.sessionStartDate)
                                             .isBetween(semesterFilter.startDate,semesterFilter.endDate,undefined,'[]')).length;
            return{
                ...semesterFilter,
                descriptionForView : `${semesterFilter.description} (${semesterCount})`
            }
        });

        return result;
    }
)
export const SelectedStatusFilters = createSelector(
    StatusFilters,
    (filters) => !!filters ? filters.filter(x=>x.selected): []
)
export const SelectedSemesterFilters = createSelector(
    SemesterFilters,
    (filters) => !!filters ? filters.filter(x=>x.selected): [] 
)
export const ProviderFilters = createSelector(
    selectEnrollmentsState,
    (state) => state.providerFilters
  )
  export const SelectedProviderFilters = createSelector(
    ProviderFilters,
    (filters) => filters.filter(x=>x.selected)
  )
  
  export const ProviderFilterCount = createSelector(
    SelectedProviderFilters,
    (filters) => filters.length > 0 ? filters.length : null
  )
  export const ProviderFiltersForView = createSelector(
    Enrollments,
    ProviderFilters,
    (enrollments, providers) =>{
      return providers.filter(x=>enrollments.find(e=>e.providerId==x.id)).sort(sortBy('description')).map(filter => {
        const count = !!enrollments ? enrollments.filter(x=>x.providerId===filter.id).length : 0;
        return {
          ...filter,
          descriptionForView: `${filter.description} (${count})`
        }
      })
    }
  )

  export const MemberFilters = createSelector(
    selectEnrollmentsState,
    (state) => state.memberFilters
  )
  export const SelectedMemberFilters = createSelector(
    MemberFilters,
    (filters) => filters.filter(x=>x.selected)
  )
  
  export const MemberFilterCount = createSelector(
    SelectedMemberFilters,
    (filters) => filters.length > 0 ? filters.length : null
  )
  export const MemberFiltersForView = createSelector(
    Enrollments,
    MemberFilters,
    (enrollments, members) =>{
      return members.filter(m=>enrollments.find(x=>x.memberId==m.id)).sort(sortBy('description')).map(filter => {
        const count = !!enrollments ? enrollments.filter(x=>x.memberId===filter.id).length : 0;
        return {
          ...filter,
          descriptionForView: `${filter.description} (${count})`
        }
      })
    }
  )
  export const TagFilters = createSelector(
    selectEnrollmentsState,
    (state) => state.tagFilters
  )
  export const SelectedTagFilters = createSelector(
    TagFilters,
    (filters) => filters.filter(x=>x.selected)
  )
  export const TagFilterCount = createSelector(
    SelectedTagFilters,
    (filters) => filters.length > 0 ? filters.length : null
  )
  export const TagFiltersForView = createSelector(
    Enrollments,
    TagFilters,
    (enrollments, enrollmentTags) =>{
      return enrollmentTags.filter(x=>enrollments.find(y=>!!y.tags && y.tags.find(t=>t.id===x.id))).sort(sortBy('description')).map(filter => {
        const count = !!enrollments ? enrollments.filter(x=>!!x.tags && x.tags.find(tag=>tag.id===filter.id)!=undefined).length : 0;
        return {
          ...filter,
          descriptionForView: `${filter.description} (${count})`
        }
      })
    }
  )
  export const CategoryFilters = createSelector(
    selectEnrollmentsState,
    (state) => state.courseCategoryFilters
  )
  
  export const SelectedCategoryFilters = createSelector(
    CategoryFilters,
    (filters) => filters.filter(x=>x.selected)
  )
  
  export const CategoryFilterCount = createSelector(
    SelectedCategoryFilters,
    (filters) => filters.length > 0 ? filters.length : null
  )
  
  export const SubCategoryFilters = createSelector(
    selectEnrollmentsState,
    (state) => state.courseSubCategoryFilters
  )
  
  export const SelectedSubCategoryFilters = createSelector(
    SubCategoryFilters,
    (filters) => filters.filter(x=>x.selected)
  )
  
  export const SubCategoryFilterCount = createSelector(
    SelectedSubCategoryFilters,
    (filters) => filters.length > 0 ? filters.length : null
  )

  export const CategoryFiltersForView = createSelector(
    Enrollments,
    CategoryFilters,
    (enrollments, enrollmentsCategories) =>{
      
      return enrollmentsCategories.filter(x=>enrollments.find(e=>!!e.category && e.category.id==x.id)).sort(sortBy('description')).map(filter => {
        const count = !!enrollments ? enrollments.filter(x=>!!x.category && x.category.id===filter.id).length : 0;
        return {
          ...filter,
          descriptionForView: `${filter.description} (${count})`
        }
      })
    }
  )
  
  export const SubCategoryFiltersForView = createSelector(
    Enrollments,
    SubCategoryFilters,
    (enrollments, enrollmentsSubCategories) =>{
      
      return enrollmentsSubCategories.filter(x=>enrollments.find(e=>!!e.subCategory && e.subCategory.id==x.id)).sort(sortBy('description')).map(filter => {
        const count = !!enrollments ? enrollments.filter(x=>!!x.subCategory && x.subCategory.id===filter.id).length : 0;
        return {
          ...filter,
          descriptionForView: `${filter.description} (${count})`
        }
      })
    }
  )

export const SelectedFilters = createSelector(
    SelectedStatusFilters,
    SelectedSemesterFilters,
    SelectedProviderFilters,
    SelectedMemberFilters,
    SelectedTagFilters,
    SelectedCategoryFilters,
    SelectedSubCategoryFilters,
    (statusFilters, semesterFilters,providerFilters,memberFilters,tagFilters,categoryFilters,subCategoryFilters) => 
    [...statusFilters, ...semesterFilters, ...providerFilters,...memberFilters,...tagFilters,...categoryFilters,...subCategoryFilters]
)
export const SelectedFilterList = createSelector(
    SelectedStatusFilters,
    SelectedSemesterFilters,
    SelectedProviderFilters,
    SelectedMemberFilters,
    SelectedTagFilters,
    SelectedCategoryFilters,
    SelectedSubCategoryFilters,
    (statusFilters, semesterFilters,providerFilters,memberFilters, tagFilters, categoryFilters, subCategoryFilters) => {
        return {
            statusFilters,
            semesterFilters,
            providerFilters,
            memberFilters,
            tagFilters,
            categoryFilters,
            subCategoryFilters
        }
    }
)

export const FilteredEnrollments = createSelector(
    Enrollments,
    CurrentOrder,
    CurrentSort,
    SearchText,
    SelectedFilterList,
    (enrollments, currentOrder, currentSort, searchText, selectedFilters) => {
        let result = [...enrollments];

        const {statusFilters, semesterFilters, providerFilters,memberFilters, tagFilters, categoryFilters, subCategoryFilters} = selectedFilters;        
          
        if (statusFilters.length) {
            result = result.filter(enrollment => {
              const index = statusFilters.findIndex(item => enrollment.status.id===item.id);
              return (index !== -1);
            })
        }

        if(semesterFilters.length){
            result = result.filter(enrollment => {
                const index = semesterFilters.findIndex(item => moment(enrollment.sessionStartDate).isBetween(item.startDate,item.endDate,undefined,"[]"));
                return (index !== -1);
            })
        }
        if (providerFilters.length) {
            result = result.filter(enrollment => {
              const index = providerFilters.findIndex(item => item.id===enrollment.providerId);
              return (index !== -1);
            })
          }
        if (memberFilters.length) {
            result = result.filter(enrollment => {
              const index = memberFilters.findIndex(item => item.id===enrollment.memberId);
              return (index !== -1);
            })
        }
          
        if (tagFilters.length) {
            result = result.filter(enrollment => {
              const index = tagFilters.findIndex(item =>!!enrollment.tags && enrollment.tags.find(tag=>tag.id === item.id) != undefined);
              return (index !== -1);
            })
          }
        if (categoryFilters.length) {
          result = result.filter(enrollment => {
            const index = categoryFilters.findIndex(item =>!!enrollment.category && item.id===enrollment.category.id);
            return (index !== -1);
          })
        }

        if (subCategoryFilters.length) {
          result = result.filter(enrollment => {
            const index = subCategoryFilters.findIndex(item =>!!enrollment.subCategory && item.id===enrollment.subCategory.id);
            return (index !== -1);
          })
        }
        result = searchInText<EnrollmentListItemForView>(searchText, result, [
            'studentFullName.firstName',
            'studentFullName.middleName',
            'studentFullName.lastName',
            'studentNumber',
            'studentEmailAddress',
            'studentPhoneNumber',
            'memberName',
            'memberCourseCode',
            'providerName',
            'providerCourseCode',
            'courseTitle',
            'sessionCode',
            'status.description',
            'category.description',
            'subCategory.description',
          ]);

        const sort = currentSort.value;
        const order = currentOrder.value;

        result = result.sort(sortBy(`${order}${sort}`));

        return result;
    }
)

export const SelectedEnrollments = createSelector(
  FilteredEnrollments,
  (enrollments) => !!enrollments ? enrollments.filter(x=>x.selected): []
)

export const CanExportToExcel = createSelector(
  FilteredEnrollments,
  (filtered) => filtered.length > 0
)


export const SelectedStatus = createSelector(
    FilteredEnrollments,
    (filtered) => `${filtered.filter(x=>x.selected).length} of ${filtered.length}`
)

export const IsAllSelected = createSelector(
    FilteredEnrollments,
    (filtered) => filtered.length==0 ? false : filtered.every(x=>x.selected)
);

export const IndeterminateSelected = createSelector(
    IsAllSelected,
    FilteredEnrollments,
    (isAllSelected, students) => isAllSelected ? false : students.some(x=>x.selected)
)

export const EnrollmentsForResolver = createSelector(
    Enrollments,
    CourseExchangeId,
    (enrollments, courseExchangeId) =>{
        return{
            enrollments,
            courseExchangeId
        }
    }
)

export const SelectorForEnrollments = createSelector(
  fromCourseExchange.Selectors.CourseExchangeId,
  selectEnrollmentsState,
  (courseExchangeId, state) => {
      return {
          currentCourseExchangeId: courseExchangeId,
          currentStartDate: state.startDate,
          currentEndDate: state.endDate
      }
  }
)

export const isEnrollmentsLoading = createSelector(
    selectEnrollmentsState,
    (state) => state.IsEnrollmentsLoading
)

export const RequestedChanges = createSelector(
  Enrollments,
  (enrollments) => enrollments.filter(x=>x.requestedStatus != null)
)

export const CanSubmitChanges = createSelector(
  RequestedChanges,
  (requestedChanges) => {
    return requestedChanges.length>0;
  }
)

export const RequestedChangesForEffects = createSelector(
  RequestedChanges,
  fromCourseExchange.Selectors.CourseExchangeId,
  fromRefData.enrollmentStatuses,
  (requestedChanges,courseExchangeId, enrollmentStatuses) => {
      return {
          courseExchangeId,
          command: {
              commands: requestedChanges.map(x=>{
              return {
                  enrollmentId: x.enrollmentId,
                  requestedStatus: x.requestedStatus,
                  fromMember: true,
                  fromProvider: true
              }
          })},
          enrollmentStatuses
      }
  }
)
