<mat-toolbar>
  <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <span class="title dialog-title">Update Enrollment Status: Drop Course</span>
    <button mat-icon-button (click)="close()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div class="content">
  <enrollment-short-student-info></enrollment-short-student-info>

  <div class="sub-title sub-title-top">You have chosen to drop the following course:</div>

  <div class="accordion-action-wrap">
    <enrollment-short-course-info></enrollment-short-course-info>
    <div class="accordion-action" (click)="isAccordionShow = !isAccordionShow">{{isAccordionShow ? 'Hide' : 'Show'}} Enrollment Details</div>
  </div>

  <div [ngClass]="['accordion-hidden-content', isAccordionShow ? 'is_show' : 'is_hidden']">
    <div class="accordion-hidden-content-title">Enrollment Details</div>

    <div class="accordion-hidden-content-info">
      <enrollment-short-details-info></enrollment-short-details-info>
    </div>
  </div>

  <div class="sub-title sub-title-bottom">Is this correct?</div>

  <div class="actions">
    <button mat-raised-button color="warn">
      <mat-icon>close</mat-icon>
      No, Cancel
    </button>

    <button mat-raised-button>
      <mat-icon>save</mat-icon>
      Yes, drop this course
    </button>
  </div>
</div>
