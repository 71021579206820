import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import * as XLSX from 'xlsx';
import { AddCourseCommand } from 'app/provider/courses/models/commands';
import { BaseListItem, CreditHourType } from '../models';
import { PopupService } from './popup.service';
import * as FileSaver from 'file-saver';
import { AddStudentCommand } from 'app/shared/student/commands';
import { IAppState } from 'app/store/state/app.state';
import { Store, select } from '@ngrx/store';
import * as fromRefData from '@refData';
import { ConfigService } from './config.service';
import * as fromProviderCourses from '@provider/courses/store';
import {v4 as uuidv4} from 'uuid';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

export interface ExcelColumn{
  header: string;
  fieldName: string;
}
@Injectable({
  providedIn: 'root'
})
export class ExcelService {
  private apiUrl: string = '';
  private courseLevels: BaseListItem[];
  private courseCategories: fromRefData.CourseCategory[];
  private courseSubCategories: fromRefData.CourseSubCategory[];
  private regions: fromRefData.Region[];
  private genders: BaseListItem[];
  private studentTypes: BaseListItem[];
  constructor(private http:HttpClient,
            private store: Store<IAppState>,
            private config:ConfigService) {
    this.config.apiUrl$.subscribe(result=>this.apiUrl=result);

    this.store.dispatch(fromRefData.Actions.LoadCourseCategories({forceReload: false}));
    this.store.dispatch(fromRefData.Actions.LoadCourseLevels({forceReload: false}));
    this.store.dispatch(fromRefData.Actions.LoadCourseSubCategories({forceReload:false}));
    this.store.dispatch(fromRefData.Actions.LoadGenders({forceReload:false}));
    this.store.dispatch(fromRefData.Actions.LoadStudentTypes({forceReload:false}));
    this.store.dispatch(fromRefData.Actions.LoadRegions({forceReload:false}));
    
    this.store.pipe(select(fromRefData.Selectors.courseCategories)).subscribe(result=>this.courseCategories=result);
    this.store.pipe(select(fromRefData.Selectors.courseLevels)).subscribe(result=>this.courseLevels=result);
    this.store.pipe(select(fromRefData.Selectors.courseSubCategories)).subscribe(result=>this.courseSubCategories=result);
    this.store.pipe(select(fromRefData.Selectors.regions)).subscribe(result=>this.regions=result);
    this.store.pipe(select(fromRefData.Selectors.genders)).subscribe(result=>this.genders=result);
    this.store.pipe(select(fromRefData.Selectors.studentTypes)).subscribe(result=>this.studentTypes=result);
  }
  
  public createCourseBulkImportTemplate(institutionId: string, providerId: string): Observable<any>{
    let url = `${this.apiUrl}/provider/${institutionId}/createBulkCourseImport/${providerId}`;

    return this.http.post(url, {},{responseType:'arraybuffer'});
  }

  public exportCourseExchangeBillingSummary(courseExchangeId: string, year:number, month:number): Observable<any>{
    let url = `${this.apiUrl}/courseExchange/${courseExchangeId}/billing/courseExchangeBillingSummary/${year}/${month}`;

    return this.http.post(url, {},{responseType:'arraybuffer'});
  }

  public exportCourseExchangeMemberDetailedReport(courseExchangeId: string, year:number, month:number): Observable<any>{
    let url = `${this.apiUrl}/courseExchange/${courseExchangeId}/billing/memberDetailedReport/${year}/${month}`;

    return this.http.post(url, {},{responseType:'arraybuffer'});
  }

  public exportCourseExchangeProviderDetailedReport(courseExchangeId: string, year:number, month:number): Observable<any>{
    let url = `${this.apiUrl}/courseExchange/${courseExchangeId}/billing/providerDetailedReport/${year}/${month}`;

    return this.http.post(url, {},{responseType:'arraybuffer'});
  }

  public exportMemberDetailedReport(memberId: string, year:number, month:number): Observable<any>{
    let url = `${this.apiUrl}/member/${memberId}/billing/memberDetailedReport/${year}/${month}`;

    return this.http.post(url, {},{responseType:'arraybuffer'});
  }

  public exportProviderDetailedReport(providerId: string, year:number, month:number): Observable<any>{
    let url = `${this.apiUrl}/provider/${providerId}/billing/providerDetailedReport/${year}/${month}`;

    return this.http.post(url, {},{responseType:'arraybuffer'});
  }

  public importCourses(institutionId: string,providerId: string, file:any): void
  {
    let arrayBuffer: any;
    let fileReader: FileReader = new FileReader();
    fileReader.onload = () =>{
      arrayBuffer = fileReader.result;
      var data = new Uint8Array(arrayBuffer);
      var arr = new Array();
      for(var i=0; i!= data.length;i++){
        arr[i] = String.fromCharCode(data[i]);
      }
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, {type: 'binary'});
      var courseSheet = workbook.Sheets[workbook.SheetNames[0]];
      var coursesLineXL = XLSX.utils.sheet_to_json(courseSheet, {raw: true});
      var commands: Array<AddCourseCommand> = new Array<AddCourseCommand>();
      coursesLineXL.forEach((courseLine) => {
        var command = this.getAddCourseCommandFromExcel(institutionId,providerId, courseLine);
        commands.push(command);
      })

      this.store.dispatch(fromProviderCourses.Actions.AddCourses({commands}));
    }
    fileReader.readAsArrayBuffer(file);
  }
  public createStudentBulkImportTemplate(): Observable<any>{
    let url = `${this.apiUrl}/institution/createBulkStudentImport`;

    return this.http.post(url, {},{responseType:'arraybuffer'});
  }
  public importStudents(institutionId: string, file:any, callback:any): void
  {
    let arrayBuffer: any;
    let fileReader: FileReader = new FileReader();
    fileReader.onload = () =>{
      arrayBuffer = fileReader.result;
      var data = new Uint8Array(arrayBuffer);
      var arr = new Array();
      for(var i=0; i!= data.length;i++){
        arr[i] = String.fromCharCode(data[i]);
      }
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, {type: 'binary'});
      var studentSheet = workbook.Sheets[workbook.SheetNames[0]];
      var studentsLineXL = XLSX.utils.sheet_to_json(studentSheet, {raw: true});
      var commands: Array<AddStudentCommand> = new Array<AddStudentCommand>();
      studentsLineXL.forEach((studentLine) => {
        var command = this.getAddStudentCommandFromExcel(institutionId, studentLine);
        commands.push(command);
      })
      callback(commands);
    }
    fileReader.readAsArrayBuffer(file);
  }

  public exportAsExcelFile(json: any[],excelColumns: ExcelColumn[], excelFileName: string): void{
    var data = [];
    json.forEach(value=>{
      var newValue = {};
      excelColumns.forEach(column =>{
        newValue[column.header]=value[column.fieldName];
      });
      data.push(newValue);
    });
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const workbook: XLSX.WorkBook = {Sheets: { 'data' : worksheet}, SheetNames: ['data'] };
    
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array'});

    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {type: EXCEL_TYPE});

    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

  private getAddCourseCommandFromExcel(institutionId:string,providerId: string, courseLine: any): AddCourseCommand
  {
    var courseCategorySubCategory: string = courseLine['Category/SubCategory'];
    var pieces = courseCategorySubCategory.split(' -- ');
    var courseCategory = this.courseCategories.find(x=>x.description===pieces[0]);
    var courseLevel = this.courseLevels.find(x=>x.description === courseLine['Level']);
    var courseSubCategory = this.courseSubCategories.find(x=>x.courseCategoryId===courseCategory.id && x.description===pieces[1]);
    var availableSeats = courseLine['Available Seats'];
    var command: AddCourseCommand = {
      id: uuidv4(),
      institutionId,
      categoryId: courseCategory && courseCategory.id,
      subCategoryId: courseSubCategory && courseSubCategory.id,
      levelId: courseLevel && courseLevel.id,
      availableSeats: !!availableSeats ? parseInt(availableSeats): 0,
      code: courseLine['Code'],
      creditHours: courseLine['Credit Hours'],
      creditHourType: courseLine['Credit Hour Type'] || CreditHourType.SEM,
      description: courseLine['Description'],
      notes: courseLine['Notes'],
      requirements: courseLine['Requirements'],
      title: courseLine['Title'],
    };

    return command;
  }

  public getAddStudentCommandFromExcel(institutionId:string, studentLine: any): AddStudentCommand
  {
    var regionDescription: string = studentLine['State*'];
    var region = this.regions.find(x=>x.description===regionDescription);
    var genderDescription: string = studentLine['Gender*'];
    var studentTypeDescription: string = studentLine['StudentType*'];
    var gender = this.genders.find(x=>x.description===genderDescription);  
    var studentType = this.studentTypes.find(x=>x.description===studentTypeDescription);  
    var jsonDateOfBirth =  this.ExcelDateToJSDate(studentLine['Date of Birth(YYYY-MM-DD)*']);
    var command: AddStudentCommand = {
      id: uuidv4(),
      institutionId,
      firstName: studentLine['First Name*'],
      middleName: studentLine['Middle Name'],
      lastName: studentLine['Last Name*'],
      emailAddress: studentLine['Email Address*'],
      emailAddress2: studentLine['Email Address 2'] || "",
      phoneNumber: studentLine['Phone Number'],
      studentNumber: studentLine['Student Number*'],
      addressLine1: studentLine['Address Line 1*'],
      addressLine2: studentLine['Address Line 2'] || "",
      city: studentLine['City*'],
      regionId: region && region.id,
      genderId: gender && gender.id,
      studentTypeId: studentType && studentType.id,
      postalCode: studentLine['Postal Code*'],
      dateOfBirth: jsonDateOfBirth
    };
    return command;
  }
  private ExcelDateToJSDate(date) {
    return new Date(Math.round((date - 25569)*86400*1000));
  }
}
