import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {IAppState} from '../../../../store/state/app.state';
import {select, Store} from '@ngrx/store';
import {Observable, Subscription, pipe} from 'rxjs';
import {
  DeSelectCourseDialogConfirmComponent} from '..';
import { MemberCourseListItem } from '@member/memberCourse/models';
import * as fromMemberCourse from '@member/memberCourse/store';
import { CourseSessionDateItem } from '@shared/provider/models/courseSessionDateItem';

@Component({
  selector: 'compare-courses-dialog-container',
  templateUrl: './compare-courses-dialog-container.component.html',
  styleUrls: ['./compare-courses-dialog-container.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CompareCoursesDialogContainerComponent implements OnInit, OnDestroy {

  isSendingPreSelectedCourse = false;
  isSendingRemovePreSelectedCourse = false;
  selectedMemberCourses$: Observable<MemberCourseListItem[]>;
  selectedSubscription$: Subscription;
  constructor(
    public matDialogRef: MatDialogRef<CompareCoursesDialogContainerComponent>,
    private store: Store<IAppState>,
    public dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.selectedMemberCourses$ = this.store.pipe(select(fromMemberCourse.Selectors.SelectedMemberCourses));

    this.matDialogRef.addPanelClass([
      'compare-courses-dialog-container'
    ]);

    this.updatePanelClass();

  }

  trackById(memberCourse: MemberCourseListItem): string {
    return memberCourse.id;
  }

  close(): void {
    this.matDialogRef.close();
  }

  removeFromCompare(id: string): void {
    this.store.dispatch(fromMemberCourse.Actions.ToggleMemberCourse({id}));
  }

  updatePanelClass(): void {
    this.matDialogRef.removePanelClass([
      'two-cols', 'three-cols'
    ])

  this.selectedSubscription$ = this.selectedMemberCourses$.subscribe(compareCourses=>{
      this.matDialogRef.addPanelClass([
        compareCourses.length === 2 ? 'two-cols' : 'three-cols'
      ]);
    })
  }

  selectCourse(memberCourse: MemberCourseListItem): void {

    if (!memberCourse.isFavourited) {
      this.preSelectCourses(memberCourse)
    } else {
      this.confirmDeselectCourse(memberCourse)
    }
  }

  preSelectCourses(memberCourse: MemberCourseListItem): void {
    this.store.dispatch(fromMemberCourse.Actions.PreSelectSingleCourse({
      providerCourseId: memberCourse.providerCourseId
    }));
  }

  confirmDeselectCourse(memberCourse: MemberCourseListItem): void {
    
    const confirmDialogRef = this.dialog.open(DeSelectCourseDialogConfirmComponent);

    confirmDialogRef.afterClosed().subscribe(isConfirmed => {
      if (isConfirmed) {
        this.deSelectCourses(memberCourse);
      }
    });
  }

  deSelectCourses(memberCourse: MemberCourseListItem): void {
    this.store.dispatch(fromMemberCourse.Actions.DeselectCourses({
    memberCourseIds: [memberCourse.memberCourseId]
    }));
  }

  goToCourseDetail(currentMemberCourse: MemberCourseListItem): void {
    this.store.dispatch(fromMemberCourse.Navigate.MemberCourseDetail({currentMemberCourseListItem: currentMemberCourse}));
  }


  ngOnDestroy(): void {
    this.selectedSubscription$.unsubscribe();
  }

  memberCourseSessions$(providerCourseId: string): Observable<CourseSessionDateItem[]>{
    return this.store.select(pipe(fromMemberCourse.Selectors.MemberCourseSessions(providerCourseId)));
  }

  onFacultyCV(courseId: string){
    
  }

  onSyllabus(memberCourse: MemberCourseListItem){
    this.store.dispatch(fromMemberCourse.Actions.DownloadCourseSyllabus({courseId: memberCourse.courseId, syllabus: memberCourse.syllabus}));
  }
}
