
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { Injectable } from "@angular/core";
import { map, switchMap, withLatestFrom, concatMap, tap } from "rxjs/operators";
import { select, Store } from '@ngrx/store';
import { of, fromEvent } from 'rxjs';
import { IAppState } from 'app/store/state/app.state';
import * as fromCore from '@core/store';
import * as fromMember from '@member/store';
import * as fromEnrollment from '@member/enrollment/store';
import { StudentAddedDialogComponent, StudentAddedDialogData, StudentDeleteDialogComponent, StudentDeleteDialogData, StudentResultActionDialogComponent, StudentResultActionDialogData } from '@member/students/components';
import { MatDialog } from '@angular/material/dialog';
import { NewEnrollmentDialogContainerComponent, NewEnrollmentDialogContainerData } from '@member/enrollment/components';
import { ENewEnrollmentTypeFrom } from '@member/enrollment/constants/shared';
import { MemberService } from '@shared/member/services';
import * as moment from 'moment';
import { Router } from '@angular/router';

@Injectable()
export class EnrollmentNavigateEffects{
    constructor(private actions$: Actions, private store: Store<IAppState>, private router: Router){
    }

  Enrollments$ = createEffect(() => {
    return this.actions$.pipe(
            ofType(fromEnrollment.Navigate.Enrollments),
            concatMap(action => of(action).pipe(
                    withLatestFrom(this.store.pipe(select(fromMember.Selectors.Member)))
                )),
            tap(([action,member]) => {  
                const memberId = !!action.memberId ? action.memberId : member.id;
                const institutionId = !!action.institutionId ? action.institutionId: member.institutionId;
               this.router.navigate([`/member/${memberId}/${institutionId}/enrollments/list`])
            })
        )
    },{dispatch : false});
}

