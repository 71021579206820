import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { Store, select } from "@ngrx/store";
import { first, finalize, filter, map, tap } from "rxjs/operators";
import { IAppState } from 'app/store/state/app.state';
import { CourseExchangeListItem } from '@shared/courseExchange/models';
import * as fromCourseExchange from '@courseExchange/store';

@Injectable()
export class CourseExchangeSummaryResolver implements Resolve<CourseExchangeListItem>{
    isLoading = false;
    constructor(private store: Store<IAppState>){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
        const courseExchangeId = route.params["courseExchangeId"];
        return this.store 
            .pipe(
                select(fromCourseExchange.Selectors.CourseExchangeSummary),
                map((courseExchangeSummary) => {
                    var result = !!courseExchangeSummary && courseExchangeSummary.id === courseExchangeId;
                    if(result) this.store.dispatch(fromCourseExchange.Actions.LoadCourseExchangeSummarySuccess({courseExchangeSummary}));
                    return result;
                }),
                map((loadCourseExchangeSummary)=>{
                    if(!this.isLoading && !loadCourseExchangeSummary){
                        this.isLoading = true;
                        this.store.dispatch(fromCourseExchange.Actions.LoadCourseExchangeSummary({courseExchangeId}));
                    }
                    return true;
                }),
                filter(summaryLoaded => summaryLoaded),
                first(),
                finalize(()=>this.isLoading=false)
            )
    }
}