import {Action, createReducer, on} from '@ngrx/store';
import { UserNotification } from '@shared/common/models';
import * as fromNotification from '@core/notifications/store';

export interface INotificationState {
  notifications: UserNotification[];
  isNotificationsLoading: boolean;
  isNotificationsOpen: boolean;
}

export const initialNotificationState: INotificationState = {
  notifications: [],
  isNotificationsLoading: false,
  isNotificationsOpen: false
};

export const notificationsReducer = createReducer(
  initialNotificationState,
  on(fromNotification.Actions.LoadNotificationsSuccess, (state, {notifications}) => {
    return {
      ...state,
      notifications,
      isNotificationsLoading: false
    };
  }),
  on(fromNotification.Actions.LoadNotificationsError, (state, action) => {
    return {
      ...state,
      isNotificationsLoading: false
    };
  }),
  on(fromNotification.Actions.LoadNotifications, (state, action) => {
    return {
      ...state,
      isNotificationsLoading: true
    };
  }),
  on(fromNotification.Actions.ToggleNotificationBar, (state, action) => {
    const isNotificationsOpen = !!action.toggle ? action.toggle : !state.isNotificationsOpen;
    return {
      ...state,
      isNotificationsOpen
    };
  }),
  on(fromNotification.Actions.NotificationAdded, (state, {notification}) => {
    const notifications = [...state.notifications, notification];

    return {
      ...state,
      notifications
    }
  }),
  on(fromNotification.Actions.NotificationEdited, (state, {notification}) => {
    const idx = state.notifications.findIndex(x=>x.id===notification.id);
    
    const notifications = idx != -1 ? [...state.notifications.slice(0,idx),notification,...state.notifications.slice(idx+1)] : state.notifications;

    return {
      ...state,
      notifications
    }
  }),
  on(fromNotification.Actions.MarkNotificationsAsRead, (state, action) => {
    const notifications = state.notifications.filter(x=>x.id != action.notification.id);

    return {
      ...state,
      notifications
    };
  }),
  on(fromNotification.Actions.NotificationMarkedAsRead, (state,action) => {
    const notifications = state.notifications.filter(x=>x.id != action.notification.id);

    return {
      ...state,
      notifications
    };
  }),
  on(fromNotification.Actions.MarkAllNotificationsAsRead, (state, action)=>{
    const notifications = [];

    return {
      ...state,
      notifications
    }
  })
);
