import { createEffect, Actions, ofType } from "@ngrx/effects";
import { Injectable } from "@angular/core";
import { map, concatMap, withLatestFrom, tap } from "rxjs/operators";
import { of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { IAppState } from 'app/store/state/app.state';
import { Router } from '@angular/router';
import * as fromAdmin from '@admin/store';

@Injectable()
export class AdminNavigateEffects{
    constructor(private actions$: Actions, private store:Store<IAppState>, private router: Router){
            }
    
}