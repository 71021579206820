import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SingleValueResult } from 'app/core/models/singleValueResult';
import { map } from 'rxjs/operators';
import { ConfigService } from '@core/services/config.service';
import {SuperAdminDashboardViewModel} from '@admin/dashboard/models';

@Injectable()
export class AdminDashboardService {
  private apiUrl: string ='';
  constructor(private http:HttpClient,private config:ConfigService) { 
    this.config.apiUrl$.subscribe(result=>this.apiUrl=result);
  }

  public getInstitutionCount() : Observable<number> {
    return this.http.get<SingleValueResult<number>>(`${this.apiUrl}/institution/adminDashboard`)
              .pipe(
                map(data =>{
                  return data.result;
                })
              );
  }

  public getCourseExchangeCount() : Observable<number> {
    return this.http.get<SingleValueResult<number>>(`${this.apiUrl}/courseExchange/count`)
              .pipe(
                map(data =>{
                  return data.result;
                })
              );
  }

  public getDashboardData(): Observable<SuperAdminDashboardViewModel>{
    return this.http.get<SuperAdminDashboardViewModel>(`${this.apiUrl}/user/superAdminDashboard`);
  }
}
