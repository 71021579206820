<!-- FORM -->
<form name="institutionForm" [formGroup]="institutionForm" class="two-col-form content-form" fxLayout="column" fxFlex>
  
    <div class="form-interior-content w-100-p" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start start" fxFlex>
        <!-- Column 1 -->
        <div class="form-column form-column-1 w-50-p" >
            <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                <mat-label>Institution Name</mat-label>
                <input matInput placeholder="Institution Name"
                    name="name"
                    formControlName="institutionName">
            </mat-form-field>

            <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                <mat-label>URL</mat-label>
                <input matInput placeholder="URL"
                        name="url"
                        formControlName="websiteUrl">
            </mat-form-field>

            <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                <mat-label>Address Line 1</mat-label>
                <input matInput placeholder="Address line 1"
                        name="addressLine1"
                        formControlName="addressLine1">
            </mat-form-field>

            <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                <mat-label>Address Line 2</mat-label>
                <input matInput placeholder="Address line 2"
                        name="addressLine2"
                        formControlName="addressLine2">
            
            </mat-form-field>

            <mat-form-field appearance="outline" floatLabel="always" class="w-50-p ">
                <mat-label>City</mat-label>
                <input matInput placeholder="City"
                        name="city"
                        formControlName="city">
                
            </mat-form-field>

            <mat-form-field appearance="outline" floatLabel="always" class="w-30-p ">
                <mat-label>State</mat-label>
                <mat-select formControlName="regionId">
                    <mat-option *ngFor="let region of regions" [value]="region.id">
                        {{region.shortDescription}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field appearance="outline" floatLabel="always" class="w-50-p ">
                <mat-label>Postal Code</mat-label>
                <input matInput placeholder="Postal Code"
                            name="postalCode"
                            formControlName="postalCode">
                
            </mat-form-field>

            <mat-form-field appearance="outline" floatLabel="always" class="w-30-p ">
                <mat-label>Accreditation Body</mat-label>
                <mat-select formControlName="accreditationBodyId">
                    <mat-option *ngFor="let accreditationBody of accreditationBodies" [value]="accreditationBody.id">
                        {{accreditationBody.shortDescription}}
                    </mat-option>
                </mat-select>
            </mat-form-field>                
        </div>
        <!-- / END Column 1 -->
        <mat-divider [vertical]="true"></mat-divider>
        <!-- Column 2 -->
        <div class="form-column form-column-2 w-50-p">
            
            <div class="file-uploader w-100-p">
                <mat-label>Logo</mat-label>
                <img class="file-upload-logo" [src]="logoUrl"/>
                <input hidden type="file"
                    (change)="onFileSelected($event)"
                    #fileInput/>
                <button mat-fab
                        color="warn"
                        class="add-file-button"
                        (click)="fileInput.click()"
                        aria-label="Add file">
                    <mat-icon>add</mat-icon>
                </button>
            </div>

            <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                <mat-label>Primary Contact</mat-label>
                <input matInput placeholder="Primary Contact" name="primaryContact" formControlName="primaryContact">
            </mat-form-field>
        
            <mat-form-field appearance="outline" floatLabel="always" class="w-70-p">
                <mat-label>Phone</mat-label>
                <input matInput type="tel" placeholder="Phone" name="phoneNumber" formControlName="phoneNumber">
            </mat-form-field>

            <mat-form-field appearance="outline" floatLabel="always" class="w-30-p ">
                <mat-label>Ext.</mat-label>
                <input matInput placeholder="Ext." name="extension"  formControlName="extension">
            </mat-form-field>

            <mat-form-field appearance="outline" floatLabel="always" class="w-100-p ">
                <mat-label>Email</mat-label>
                <input matInput type="email" placeholder="Email" name="emailAddress" formControlName="emailAddress">
            </mat-form-field>

            <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                <mat-label>Re-Enter email</mat-label>
                <input matInput type="email" placeholder="Re-Enter Email" name="confirmEmailAddress" formControlName="confirmEmailAddress">
            </mat-form-field>

            <mat-error *ngIf="institutionForm.get('confirmEmailAddress').hasError('emailsNotMatching')">Emails need to match</mat-error>

        </div>
    </div>

    
</form>
<ng-content></ng-content>