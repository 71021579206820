import { InstitutionState } from "@institution/store"

export default (academicYearId: string, state: InstitutionState): InstitutionState => {
    const academicYears = state.academicYears.filter(x=>x.id != academicYearId);
    let currentAcademicYear = state.currentAcademicYear;
    let currentAcademicYearId = state.currentAcademicYearId;
    if(currentAcademicYear.id==academicYearId){
      currentAcademicYear = academicYears.length > 0 ?{...academicYears[0]} : undefined;
      currentAcademicYearId =  academicYears.length > 0 ?academicYears[0].id : undefined;
    }
    return {
      ...state,
      academicYears,
      currentAcademicYear,
      currentAcademicYearId
    }
}