import { createAction, props } from '@ngrx/store';

export const StudentNew = createAction(
    '[Navigation] - New Student',
    props<{
        memberId?: string,
        institutionId?: string
    }>()
)

export const StudentDetail = createAction(
    '[Navigation] - Student Detail',
    props<{
        studentId?: string
    }>()
)

export const StudentEdit = createAction(
    '[Navigation] - Student Edit',
    props<{
        studentId?: string
    }>()
)

export const StudentList = createAction(
    '[Navigation] - Student List',
    props<{
        memberId?: string,
        institutionId?: string
    }>()
)