import { Injectable, Provider } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { Store, select } from "@ngrx/store";
import { first, finalize, filter, map, switchMap } from "rxjs/operators";
import { IAppState } from 'app/store/state/app.state';
import * as fromProvider from '@provider/store';
import { ProviderService } from '@shared/provider/services';
@Injectable()
export class ProviderResolver implements Resolve<Provider>{
    isLoading = false;
    constructor(private store: Store<IAppState>, private providerService: ProviderService){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
        const providerId = route.params['providerId'];
        return this.store 
            .pipe(
                select(fromProvider.Selectors.Provider),
                map((provider) => {
                    var result = !!provider && provider.id === providerId;
                    if(result) this.store.dispatch(fromProvider.Actions.LoadProviderSuccess({provider}));
                    return result;
                }),
                map((currentProviderLoaded)=>{
                    if(!this.isLoading && !currentProviderLoaded){
                        this.isLoading = true;
                        this.store.dispatch(fromProvider.Actions.LoadProvider({providerId}));
                    }
                    return true;
                }),
                filter(providerLoaded => providerLoaded),
                first(),
                finalize(()=>this.isLoading=false)
            )
    }
}