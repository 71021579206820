

import {createFeatureSelector, createSelector} from '@ngrx/store';

import * as fromRefData from './reducers/refData.reducer';
import {refDataFeatureKey} from './featureKey';

export const selectRefDataState = createFeatureSelector<fromRefData.RefDataState>(refDataFeatureKey);
export const accreditationBodies = createSelector(
    selectRefDataState,
    (state) => state.accreditationBodies
);

export const areAccreditationBodiesLoaded = createSelector(
    selectRefDataState,
    (state) => state.areAccreditationBodiesLoaded
);

export const countries = createSelector(
    selectRefDataState,
    (state) => state.countries
);

export const areCountriesLoaded = createSelector(
    selectRefDataState,
    (state) => state.areCountriesLoaded
);

export const courseCategories = createSelector(
    selectRefDataState,
    (state) => state.courseCategories
);

export const areCourseExchangesLoaded = createSelector(
    selectRefDataState,
    (state) => state.areCourseExchangesLoaded
);

export const courseExchanges = createSelector(
    selectRefDataState,
    (state) => state.courseExchanges
);

export const areInstitutionsLoaded = createSelector(
    selectRefDataState,
    (state) => state.areInstitutionsLoaded
);

export const institutions = createSelector(
    selectRefDataState,
    (state) => state.institutions
);

export const areCourseCategoriesLoaded = createSelector(
    selectRefDataState,
    (state) => state.areCourseCategoriesLoaded
);

export const courseLevels = createSelector(
    selectRefDataState,
    (state) => state.courseLevels
);

export const areCourseLevelsLoaded = createSelector(
    selectRefDataState,
    (state) => state.areCourseLevelsLoaded
);

export const courseSubCategories = createSelector(
    selectRefDataState,
    (state) => state.courseSubCategories
);

export const areCourseSubCategoriesLoaded = createSelector(
    selectRefDataState,
    (state) => state.areCourseSubCategoriesLoaded
);

export const courseTags = createSelector(
    selectRefDataState,
    (state) => state.courseTags
);

export const areCourseTagsLoaded = createSelector(
    selectRefDataState,
    (state) => state.areCourseTagsLoaded
);

export const studentTypes = createSelector(
    selectRefDataState,
    (state) => state.studentTypes
);

export const areStudentTypesLoaded = createSelector(
    selectRefDataState,
    (state) => state.areStudentTypesLoaded
);

export const courseExchangeRoles = createSelector(
    selectRefDataState,
    (state) => state.courseExchangeRoles
);

export const areCourseExchangeRolesLoaded = createSelector(
    selectRefDataState,
    (state) => state.areCourseExchangeRolesLoaded
);

export const genders = createSelector(
    selectRefDataState,
    (state) => state.genders
);

export const areGendersLoaded = createSelector(
    selectRefDataState,
    (state) => state.areGendersLoaded
);

export const institutionRoles = createSelector(
    selectRefDataState,
    (state) => state.institutionRoles
);

export const accountUserRole = createSelector(
    institutionRoles,
    (roles) => !!roles ? roles.find(x=>x.description==='Accounting') : undefined
)

export const presidentUserRole = createSelector(
    institutionRoles,
    (roles) => !!roles ? roles.find(x=>x.description==='President') : undefined
)

export const vicePresidentUserRole = createSelector(
    institutionRoles,
    (roles) => !!roles ? roles.find(x=>x.description==='Vice President') : undefined
)

export const provostUserRole = createSelector(
    institutionRoles,
    (roles) => !!roles ? roles.find(x=>x.description==="Provost") : undefined
)
export const areInstitutionRolesLoaded = createSelector(
    selectRefDataState,
    (state) => state.areInstitutionRolesLoaded
);

export const regions = createSelector(
    selectRefDataState,
    (state) => state.regions
);

export const areRegionsLoaded = createSelector(
    selectRefDataState,
    (state) => state.areRegionsLoaded
);

export const providerPolicyTemplates = createSelector(
    selectRefDataState,
    (state) => state.providerPolicyTemplates
)

export const areProviderPolicyTemplatesLoaded = createSelector(
    selectRefDataState,
    (state) => state.areProviderPoliciesTemplatesLoaded
)

export const courseExchangePolicyTemplates = createSelector(
    selectRefDataState,
    (state) => state.courseExchangePolicyTemplates
)

export const areCourseExchangePolicyTemplatesLoaded = createSelector(
    selectRefDataState,
    (state) => state.areCourseExchangePolicyTemplatesLoaded
)

export const permissionDisplays = createSelector(
    selectRefDataState,
    (state) => state.permissionDisplays
)

export const arePermissionDisplaysLoaded = createSelector(
    selectRefDataState,
    (state) => state.arePermissionDisplaysLoaded
)

export const enrollmentStatuses = createSelector(
    selectRefDataState,
    (state) => state.enrollmentStatuses
)
export const areEnrollmentStatusesLoaded = createSelector(
    selectRefDataState,
    (state) => state.areEnrollmentStatusesLoaded
)

export const letterGrades = createSelector(
    selectRefDataState,
    (state) => state.letterGrades
)
export const areLetterGradesLoaded = createSelector(
    selectRefDataState,
    (state) => state.areLetterGradesLoaded
)

