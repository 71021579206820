import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {UntypedFormBuilder, UntypedFormGroup, Validators, ValidatorFn, AbstractControl, ValidationErrors} from '@angular/forms';
import { AsyncValidatorsService } from '@shared/async-validators/services/async-validators.service';
import { ConfirmDialogComponent, ConfirmDialogData } from 'app/core/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { IControlsConfig } from 'app/models/shared';
import { hasErrorForm } from 'app/helpers/has-error-form';
import { IAppState } from 'app/store';
import { Store } from '@ngrx/store';
import {v4 as uuidv4} from 'uuid';
import { AddInstitutionCommand } from '@admin/institution';
import * as fromCourseExchangeInstitutions from '@courseExchange/institution';
import { AddMemberCommand } from '@shared/member/models';
import { AddProviderCommand } from '@shared/provider/commands';
export interface IInviteNewInstitutionDialogData{
  courseExchangeId: string;
}
type IFormFields = {
  isMember: boolean;
  isProvider: boolean;
  institutionName: string;
  primaryContact: string;
  emailAddress: string;
  confirmEmailAddress: string;
};

type ILocalControlsConfig = IControlsConfig<IFormFields>;

@Component({
  selector: 'invite-new-institution-dialog',
  templateUrl: './invite-new-institution-dialog.component.html',
  styleUrls: ['./invite-new-institution-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InviteNewInstitutionDialogComponent implements OnInit {

  form: UntypedFormGroup;
  courseExchangeId: string;
  constructor(
    public matDialogRef: MatDialogRef<InviteNewInstitutionDialogComponent>,
    private asyncValidatorService: AsyncValidatorsService,
    @Inject(MAT_DIALOG_DATA) public matDialogData: IInviteNewInstitutionDialogData,
    private formBuilder: UntypedFormBuilder,
    private dialog: MatDialog,
    private store: Store<IAppState>
  ) {
    this.courseExchangeId = matDialogData.courseExchangeId;
  }

  ngOnInit(): void {
    this.matDialogRef.addPanelClass([
      'invite-new-institution-dialog'
    ]);

    this.initForm();
  }

  initForm(): void {
    this.form = this.formBuilder.group(
      this.shapeControlsConfig(),
      {
        validator: pickOne("isMember","isProvider")
      });
  }

  private shapeControlsConfig(): ILocalControlsConfig {
    const initialControlsConfig: ILocalControlsConfig = {
      isMember: [false],
      isProvider: [false],
      institutionName: ['', [Validators.required], [this.asyncValidatorService.validateExistInstitutionName('new')]],
      primaryContact: ['', Validators.required],
      emailAddress: ['', [Validators.required,confirmEmailAddressValidator], [this.asyncValidatorService.validateExistEmail('new')]],
      confirmEmailAddress: ['', [Validators.required, confirmEmailAddressValidator]]
    };

    return initialControlsConfig;
  }

  getUserId(): string {
    return '';
  }

  hasError = (controlName: keyof IFormFields, errorName: string) => hasErrorForm(controlName, errorName, this.form);

  cancel(): void {
    const dialogData: ConfirmDialogData = {
      title: "Do you want to leave this page without saving?",
      message: ``,
      cancelButtonText: "No",
      okButtonText: "Yes"
    };
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px;",
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.matDialogRef.close(); 
      }
    })
  }
  close(): void{
    this.matDialogRef.close();
  }

  inviteInstitution(): void{
    if(this.form.valid){
      const {isMember, isProvider, institutionName, primaryContact, emailAddress, confirmEmailAddress } = this.form.value;
      var institutionId = uuidv4();
      const courseExchangeId = this.matDialogData.courseExchangeId;
      var addInstitutionCommand: AddInstitutionCommand = {institutionId, institutionName,primaryContact, emailAddress, confirmEmailAddress};
      this.store.dispatch(fromCourseExchangeInstitutions.Actions.AddInstitution({command:addInstitutionCommand}));
      if(isMember){
        var addMemberCommand: AddMemberCommand = {institutionId, institutionName, institutionLogo:'',courseExchangeId};
        this.store.dispatch(fromCourseExchangeInstitutions.Actions.AddMember({command: addMemberCommand}));
      }
      if(isProvider){
        var addProviderCommand: AddProviderCommand = {institutionId, institutionName, institutionLogo:'',courseExchangeId};
        this.store.dispatch(fromCourseExchangeInstitutions.Actions.AddProvider({command: addProviderCommand}));
      }
      this.close();
    }
  }
}

export const pickOne = (isMember, isProvider): ValidatorFn => (control: AbstractControl) => {
	if(control.get(isMember).value || control.get(isProvider).value) {
		return null;
	}
	return  { pickOne: { valid: false } };
}

export const confirmEmailAddressValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {

  if ( !control.parent || !control )
  {
      return null;
  }

  const emailAddress = control.parent.get('emailAddress');
  const emailAddressConfirm = control.parent.get('confirmEmailAddress');

  if ( !emailAddress || !emailAddressConfirm )
  {
      return null;
  }

  if ( emailAddressConfirm.value === '' )
  {
      return null;
  }

  if ( emailAddress.value === emailAddressConfirm.value )
  {
      return null;
  }

  return {emailsNotMatching: true};
};
