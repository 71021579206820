export enum EnrollmentStatusType{
    NewRequest="NewRequest",
    NewRequestWaitlisted="NewRequestWaitlisted",
    RequestDrop="RequestDrop",
    RequestLateDrop="RequestLateDrop",
    RequestWithdraw="RequestWithdraw",
    RequestReEnroll="RequestReEnroll",
    Registered="Registered",
    Waitlisted="Waitlisted",
    CourseCancelled="CourseCancelled",
    CourseFull="CourseFull",
    Dropped="Dropped",
    LateDropped="LateDropped",
    Denied="Denied",
    NPDropped="NPDropped",
    Withdrawn="Withdrawn",
    Graded="Graded",
    Incomplete="Incomplete",
    AppealGrade="AppealGrade"
}