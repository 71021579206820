import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { select, Store } from "@ngrx/store";
import { first, finalize, filter, map, concatMap, withLatestFrom } from "rxjs/operators";
import { IAppState } from 'app/store/state/app.state';
import * as fromMemberDashboard from '@member/dashboard/store';
import * as fromMember from '@member/store';
import * as fromInstitution from '@institution/store';
import { MemberDashboardViewModel } from '@shared/member/models';
import * as moment from "moment";

@Injectable()
export class MemberDashboardResolver implements Resolve<MemberDashboardViewModel>{
    isLoading = false;
    constructor(private store: Store<IAppState>){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
        const memberId = route.params["memberId"];
        let startDate = moment().toDate();
        let endDate = moment().toDate();
        var academicYearId = "";
        return this.store 
            .pipe(
                select(fromMember.Selectors.DateRangeArgs),
                concatMap((action) => of(action).pipe(
                    withLatestFrom(this.store.pipe(select(fromMemberDashboard.Selectors.SelectorForMemberDashboard)))
                )),
                map(([dateRange, {currentMemberId, currentStartDate,currentEndDate}])=>{
                    startDate = dateRange.startDate;
                    endDate = dateRange.endDate;
                    var result = !!dateRange && currentMemberId === memberId && 
                                currentStartDate===dateRange.startDate &&
                                currentEndDate===dateRange.endDate;
                    return result;
                }),
                map((dashboardLoaded) => {
                    if(!this.isLoading && !dashboardLoaded){
                        this.isLoading = true;
                        this.store.dispatch(fromMemberDashboard.Actions.LoadDashboardData({memberId,startDate,endDate}));
                    }
                    return true;
                }),
                filter(dashboardLoaded => dashboardLoaded),
                first(),
                finalize(()=>this.isLoading=false)
            )
    }
}