<div class="wizard-steps">
  <div class="steps">
    <div *ngFor="let step of stepperConfig.steps" [ngClass]="['step',
      isStepActive(step) ? 'is_active' : 'is_disabled',
      isStepCurrent(step) ? 'is_current' : '',
      isStepComplete(step) ? 'is_complete' : ''
    ]">
      <div class="icon" (click)="onNavigate(step.key)">
        <mat-icon>{{step.icon}}</mat-icon>
      </div>
      <div class="title">{{step.title}}</div>
    </div>
  </div>
</div>
