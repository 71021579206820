import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT, Location } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Observable } from 'rxjs';
import { takeUntil, withLatestFrom } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseNavigationService } from '@fuse/components/navigation/navigation.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';
import { FuseSplashScreenService } from '@fuse/services/splash-screen.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { navigation } from 'app/navigation/navigation';
import { locale as navigationEnglish } from 'app/navigation/i18n/en';
import { locale as navigationTurkish } from 'app/navigation/i18n/tr';
import { Router, NavigationStart } from '@angular/router';
import { AuthenticationService } from './authentication/services/authentication.service';
import { ViewContext } from './authentication/models/viewContext.enum';
import {Store, select} from '@ngrx/store';
import {IAppState} from './store/state/app.state';
import { isLoggedIn, CurrentUser } from './authentication/store/auth.selectors';
import { AuthActions } from './authentication/store/action-types';
import { validateBasis } from '@angular/flex-layout';
import { User } from '@shared/user/models';
import { UserSummary } from '@auth/models/userSummary.model';
import { ConfigService } from '@core/services/config.service';
import { SelectedProviderFilters } from '@member/memberCourse/store/memberCourse.selectors';
import * as fromAuth from '@auth/store';
import { RealTimeService } from '@core/services/realTime.service';
@Component({
    selector   : 'app',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy
{
    fuseConfig: any;
    navigation: any;
    isAuthenticated$:Observable<boolean>;
    isAuthenticated: boolean;
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {DOCUMENT} document
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseNavigationService} _fuseNavigationService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {FuseSplashScreenService} _fuseSplashScreenService
     * @param {FuseTranslationLoaderService} _fuseTranslationLoaderService
     * @param {Platform} _platform
     * @param {TranslateService} _translateService
     */
    constructor(
        @Inject(DOCUMENT) private document: any,
        private config: ConfigService,
        private _fuseConfigService: FuseConfigService,
        private _fuseNavigationService: FuseNavigationService,
        private _fuseSidebarService: FuseSidebarService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private _fuseSplashScreenService: FuseSplashScreenService,
        private location: Location,
        private _platform: Platform,
        private router:Router,
        private store: Store<IAppState>,
        private _translateService: TranslateService,
        private realTimeService: RealTimeService
    )
    {
        
        // Get default navigation
        this.navigation = navigation;

        // Register the navigation to the service
        this._fuseNavigationService.register('main', this.navigation);

        // Set the main navigation as our current navigation
        this._fuseNavigationService.setCurrentNavigation('main');

        // Add languages
        this._translateService.addLangs(['en', 'tr']);

        // Set the default language
        this._translateService.setDefaultLang('en');

        // Set the navigation translations
        this._fuseTranslationLoaderService.loadTranslations(navigationEnglish, navigationTurkish);

        // Use a language
        this._translateService.use('en');
        
        this._fuseSplashScreenService.hide();

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix Start
         * ----------------------------------------------------------------------------------------------------
         */

        /**
         * If you are using a language other than the default one, i.e. Turkish in this case,
         * you may encounter an issue where some of the components are not actually being
         * translated when your app first initialized.
         *
         * This is related to ngxTranslate module and below there is a temporary fix while we
         * are moving the multi language implementation over to the Angular's core language
         * service.
         **/

        // Set the default language to 'en' and then back to 'tr'.
        // '.use' cannot be used here as ngxTranslate won't switch to a language that's already
        // been selected and there is no way to force it, so we overcome the issue by switching
        // the default language back and forth.
        /**
         setTimeout(() => {
            this._translateService.setDefaultLang('en');
            this._translateService.setDefaultLang('tr');
         });
         */

        /**
         * ----------------------------------------------------------------------------------------------------
         * ngxTranslate Fix End
         * ----------------------------------------------------------------------------------------------------
         */

        // Add is-mobile class to the body if the platform is mobile
        if ( this._platform.ANDROID || this._platform.IOS )
        {
            this.document.body.classList.add('is-mobile');
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();

        this.isAuthenticated$ = this.store.pipe(
                                    select(isLoggedIn));
        this.isAuthenticated$.subscribe(value=>this.isAuthenticated=value);
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        const user:UserSummary = JSON.parse(localStorage.getItem('user'));
        const accessRoleFromStorage = localStorage.getItem('accessRole');
        if(user){
            this.store.dispatch(AuthActions.login({user, returnUrl: ''}));
        }
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {

                this.fuseConfig = config;

                // Boxed
                if ( this.fuseConfig.layout.width === 'boxed' )
                {
                    this.document.body.classList.add('boxed');
                }
                else
                {
                    this.document.body.classList.remove('boxed');
                }

                // Color theme - Use normal for loop for IE11 compatibility
                for ( let i = 0; i < this.document.body.classList.length; i++ )
                {
                    const className = this.document.body.classList[i];

                    if ( className.startsWith('theme-') )
                    {
                        this.document.body.classList.remove(className);
                    }
                }

                this.document.body.classList.add(this.fuseConfig.colorTheme);
            });
        this.router.events
            .pipe(
                withLatestFrom(this.store.select(fromAuth.Selectors.AccessRolesForEffects), (e, {currentAccessRole, allAccessRoles, currentUser})=>{
                    if(e instanceof NavigationStart){
                        if(!this.isAuthenticated) return;
                        let accessRoles = allAccessRoles;
                        let pathParts = e.url.split('/');
                        if(pathParts.length>=1){
                            let module = pathParts[1];
                            let entityId = pathParts[2];
                            var accessRole;
                            switch(module.toLowerCase()){
                                case "admin":
                                    accessRole = accessRoles.find(x=>x.viewContext===ViewContext.SuperAdmin);
                                    break;
                                case "course-exchange":
                                    accessRole = accessRoles.find(x=>x.viewContext===ViewContext.CourseExchangeAdmin&&x.courseExchangeId===entityId);
                                    break;
                                case "institution":
                                    accessRole = accessRoles.find(x=>x.viewContext===ViewContext.Institution&&x.institutionId===entityId);
                                    break;
                                case "provider":
                                    accessRole = accessRoles.find(x=>x.viewContext===ViewContext.Provider&&x.entityId===entityId);
                                    break;
                                case "member":
                                    accessRole = accessRoles.find(x=>x.viewContext===ViewContext.Member&&x.entityId===entityId);
                                    break;
                                case "student":
                                    accessRole = accessRoles.find(x=>x.viewContext===ViewContext.Student&&x.entityId===entityId);
                                    break;
                                default:
                                    var lastAccessRoleId = currentUser.lastAccessRoleId;
                                    if(lastAccessRoleId != ''){
                                        accessRole = accessRoles.find(x=>x.id==lastAccessRoleId);
                                    }else{
                                        accessRole = accessRoles[0];
                                    }
                                    break;
                            }
                            if((!currentAccessRole && accessRole) || (!!accessRole && accessRole.id != currentAccessRole.id)) {
                                this.store.dispatch(AuthActions.selectAccessRole({accessRoleId: accessRole.id}));
                                let courseExchangeId = '';
                                if(accessRole.courseExchangeId != ''){
                                    courseExchangeId = accessRole.courseExchangeId;
                                }else{
                                    var firstAccessRole = accessRoles.find(x=>x.courseExchangeId != '');
                                    courseExchangeId = !!firstAccessRole ? firstAccessRole.courseExchangeId : '';
                                }
                                this.store.dispatch(AuthActions.selectCourseExchangeId({courseExchangeId}));
                                   
                            }
                            
                        }
                    }
                })
            ).subscribe();
        
        
        if(this.isAuthenticated && this.location.path() === ''){
            const accessRole = JSON.parse(accessRoleFromStorage);
            if(accessRole){
                const accessRoleId = accessRole.id;
                this.store.dispatch(AuthActions.selectAccessRole({accessRoleId}));
                this.store.dispatch(AuthActions.navigateToDashboard({accessRoleId}));
            }
        }
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void
    {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }
}
