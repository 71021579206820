import { createReducer, on } from '@ngrx/store';
import * as fromProviderDashboard from '@provider/dashboard/store';

export interface ProviderDashboardState {
    providerId: string;
    totalCoursesOffered: number;
    pendingCourses: number;
    approvedCourses: number;
    totalEnrollments: number;
    newEnrollments: number;
    currentAcademicYearId: string;
    isLoadDashboardData: boolean;
}

export const initialDashboardState: ProviderDashboardState = {
    providerId: '',
    totalCoursesOffered: 0,
    pendingCourses: 0,
    approvedCourses: 0,
    totalEnrollments: 0,
    newEnrollments: 0,
    currentAcademicYearId: "empty",
    isLoadDashboardData:false
};

export const providerDashboardReducer = createReducer(
    initialDashboardState,
    on(fromProviderDashboard.Actions.LoadDashboardData, (state, action) => {
        const providerId = action.providerId;
        const currentAcademicYearId = action.academicYearId;
        return {
            ...state,
            providerId,
            currentAcademicYearId,
            isLoadDashboardData:true
        }
    }),
    on(fromProviderDashboard.Actions.LoadDashboardDataSuccess, (state, action) => {
        const {totalCoursesOffered,pendingCourses,approvedCourses,totalEnrollments,newEnrollments} = action.data;
        return {
            ...state,
            totalCoursesOffered,
            pendingCourses,
            approvedCourses,
            totalEnrollments,
            newEnrollments,
            isLoadDashboardData:false
        }
    }),
    on(fromProviderDashboard.Actions.LoadDashboardDataError, (state, { message }) => {
        return {
            ...state,
            isLoadDashboardData:false
        }
    })
)
