<div class="navbar-container">
  <div class="navbar-user" fxLayout="column">
    <div class="c_sidebar-buttons buttons">
      <button mat-icon-button class="toggle-sidebar-folded"
              (click)="toggleSidebarFolded()" fxHide.lt-lg>
        <mat-icon class="secondary-text">arrow_forward_ios</mat-icon>
      </button>
      <button mat-icon-button class="toggle-sidebar-opened"
              (click)="toggleSidebarOpened()" fxHide.gt-md>
        <mat-icon class="secondary-text">close</mat-icon>
      </button>
    </div>

    <div class="avatar-container">
      <elb-avatar [name]="(currentUser$ | async)?.fullName"></elb-avatar>
    </div>

    <div class="user-role h3">Exchange Admin</div>
    <button mat-button [matMenuTriggerFor]="userLogout"
            class="user-button">
      <div fxLayout="row" fxLayoutAlign="center center">
        <span class="user-name mr-12" fxHide fxShow.gt-sm>{{ user.fullName }}</span>
        <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
      </div>
    </button>

    <mat-menu class="user-account-menu" #userLogout="matMenu" [overlapTrigger]="false">
      <button mat-menu-item [routerLink]="['/auth/profile']" routerLinkActive="router-link-active">
        <mat-icon>account_circle</mat-icon>
        <span>My Profile</span>
      </button>
      <button mat-menu-item class="" (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>
        <span>Logout</span>
      </button>
    </mat-menu>

    <div class="user-email h5 mt-8">{{user.emailAddress}}</div>

    <div class="navbar-user-links mt-16">
      <div class="navbar-edit-profile-link"><a [routerLink]="['/auth/profile']" routerLinkActive="router-link-active">
        <fa-icon [icon]="faCog" class="nav-icon"></fa-icon>
        Edit Profile</a></div>
      <div class="navbar-quick-help-link mt-8"><a href="#">
        <mat-icon class="s-20 nav-icon">help_outline</mat-icon>
        Quick Help</a></div>
    </div>
  </div>

  <div class="navbar-main-container">
    <div class="navbar-ws"></div>
    <div class="navbar-scroll-container" fusePerfectScrollbar
         [fusePerfectScrollbarOptions]="{'suppressScrollX': true, 'updateOnRouteChange': true}">

      <div class="navbar-content">
        <div class="navbar-member-provider-dropdown">
          <mat-form-field appearance="outline">
            <mat-select placeholder="Admin"
                        (selectionChange)="changeAccessRole($event.value)"
                        class="w-216" mat-select [value]="currentAccessRoleId$ | async">
              <mat-option *ngFor="let role of accessRoles$ | async" [value]="role.id">
                {{role.descriptionForView}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div id="exchangeAdminDashboardNavigation" class="navbar-nav navigation">
          <div class="nav-item" *ngIf="showCourseCategories$ | async">
            <a class="nav-link " [routerLink]="['course-exchange', courseExchangeId, 'courses-data']"
               [routerLinkActive]="['active', 'accent']">
              <mat-icon class="nav-link-icon mat-icon material-icons mat-icon-no-color ng-star-inserted" role="img"
                        aria-hidden="true">list
              </mat-icon>
              <span class="nav-link-title ng-star-inserted">Course Categories</span>
              <span class="nav-link-badge item-alert">
                                        <mat-icon class="mat-icon material-icons mat-icon-no-color nav-link-badge-icon"
                                                  role="img" aria-hidden="true">error_outline</mat-icon>
                                    </span>
            </a>
          </div>
          <div class="nav-item" *ngIf="showCourseExchangeFees$ | async">
            <a class="nav-link" (click)="goToFees()" [routerLinkActive]="['active', 'accent']">
              <mat-icon class="nav-link-icon mat-icon material-icons mat-icon-no-color ng-star-inserted" role="img"
                        aria-hidden="true">attach_money
              </mat-icon>
              <span class="nav-link-title ng-star-inserted">View Fees</span>
              <span class="nav-link-badge item-alert">
                                        <mat-icon class="mat-icon material-icons mat-icon-no-color nav-link-badge-icon"
                                                  role="img" aria-hidden="true">error_outline</mat-icon>
                                    </span>
            </a>
          </div>
          <div class="nav-item" *ngIf="showBankingInfo$ | async">
            <a class="nav-link" [routerLink]="['course-exchange', courseExchangeId, 'bankingInfo']"
               [routerLinkActive]="['active', 'accent']">
              <mat-icon class="nav-link-icon mat-icon material-icons mat-icon-no-color ng-star-inserted" role="img"
                        aria-hidden="true">account_balance
              </mat-icon>
              <span class="nav-link-title ng-star-inserted">Banking Info</span>
              <span class="nav-link-badge item-alert">
                                        <mat-icon class="mat-icon material-icons mat-icon-no-color nav-link-badge-icon"
                                                  role="img" aria-hidden="true">error_outline</mat-icon>
                                    </span>
            </a>
          </div>
          <div class="nav-item" *ngIf="showCourseExchangeCalendar$ | async">
            <a class="nav-link" (click)="goToCalendar()" [routerLinkActive]="['active', 'accent']">
              <mat-icon class="nav-link-icon mat-icon material-icons mat-icon-no-color ng-star-inserted" role="img"
                        aria-hidden="true">calendar_today
              </mat-icon>
              <span class="nav-link-title ng-star-inserted">Course Calendar</span>
              <span class="nav-link-badge item-valid">
                                        <mat-icon class="mat-icon material-icons mat-icon-no-color nav-link-badge-icon"
                                                  role="img" aria-hidden="true">check_circle</mat-icon>
                                    </span>
            </a>
          </div>
        </div>
      </div>
    </div>

  </div>


</div>


