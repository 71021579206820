import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';



@NgModule({
  declarations: [LoadingSpinnerComponent],
  imports: [
    CommonModule,
    RouterModule,
  ],
  exports     : [
    LoadingSpinnerComponent
]
})
export class LoadingSpinnerModule { }
