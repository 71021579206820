import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import * as prod from 'assets/config/production.json';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private baseUrl$: BehaviorSubject<string> = new BehaviorSubject<string>("");
  private populatePassword$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  constructor(private http:HttpClient) {
  }

  public init(){
    return new Promise((resolve, reject)=>{
      var fileName = environment.production ? 'assets/config/production.json' : 'assets/config/development.json';
      this.http.get(`${fileName}`)
        .subscribe((response:any) => {
          this.baseUrl$.next(response.App.apiUrl);  
          this.populatePassword$.next(response.App.populate_password);
          resolve(true);
        });
    })
    
  }

  get apiUrl$(): Observable<string>{
    return this.baseUrl$.asObservable();
  }


  get populatePassword(): boolean{
    return this.populatePassword$.value;
  }
}
