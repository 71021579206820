import {Component, OnInit, Input, Output, EventEmitter, SimpleChanges} from '@angular/core';
import {Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import {IAppState} from '../../../../store/state/app.state';
import {IControlsConfig} from '../../../../models/shared';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {hasErrorForm} from '../../../../helpers/has-error-form';
import {validateStartEndDates} from '../../../../helpers/validate-start-end-dates';
import {fillControlsConfig} from '../../../../helpers/fill-controls-config';
import {AcademicYear} from '../../../calendar/models';
import {MatDialog} from '@angular/material/dialog';
import {ChecklistItemType} from '../../../../core/models/checklistItemType.enum';
import { AddAcademicYearCommand, EditAcademicYearCommand } from '@shared/calendar/commands';
import { WizardSkipDialogContainerComponent, IWizardSkipDialogData } from '../wizard-skip-dialog-container/wizard-skip-dialog-container.component';
import { NavigationType } from '@shared/wizard/constants/navigateType.enum';
import {v4 as uuidv4} from 'uuid';

type IFormFields = Pick<AcademicYear, 'name' | 'startDate' | 'endDate'>;

type ILocalControlsConfig = IControlsConfig<IFormFields>;

@Component({
  selector: 'wizard-calendar-step-add-year',
  templateUrl: './wizard-calendar-step-add-year.component.html',
  styleUrls: ['../../styles/common-wizard-step.scss', './wizard-calendar-step-add-year.component.scss']
})
export class WizardCalendarStepAddYearComponent implements OnInit {

  form: UntypedFormGroup;
  isSending = false;
  @Input()institutionId: string;
  @Input()academicYear: AcademicYear;
  @Output()addAcademicYear: EventEmitter<AddAcademicYearCommand> = new EventEmitter<AddAcademicYearCommand>();
  @Output()editAcademicYear: EventEmitter<EditAcademicYearCommand> = new EventEmitter<EditAcademicYearCommand>();
  @Output()navigate: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog
  ) {
  }

  ngOnInit(): void {

    this.refreshForm();
  }

  refreshForm(){
    this.form = this.formBuilder.group(this.shapeControlsConfig(), {
      validators: this.checkDates
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.academicYear && changes.academicYear.currentValue) {
      this.refreshForm();
    }
  }

  private checkDates = (group: UntypedFormGroup): void => {
    const {endDate, startDate} = group.controls;

    const checkResult = validateStartEndDates(startDate.value, endDate.value);

    if (this.form) {
      const errorsForEndDate = checkResult ? null : {lessStartDate: true};

      this.form.get('endDate').setErrors(errorsForEndDate);
    }
  }

  private shapeControlsConfig(): ILocalControlsConfig {
    const initialControlsConfig: ILocalControlsConfig = {
      name: ['', [Validators.required, Validators.maxLength(30)]],
      startDate: [null],
      endDate: [null]
    };

    if (this.academicYear) {
      return fillControlsConfig<IFormFields>(initialControlsConfig, this.academicYear);
    } else {
      return initialControlsConfig;
    }
  }

  hasError = (controlName: keyof IFormFields, errorName: string) => hasErrorForm(controlName, errorName, this.form);

  onKeyDownForm(event): void {
    if(event.keyCode === 13) {
      this.form.valid ? this.onSubmit() : event.preventDefault();
    }
  }

  onSubmit = () => {
    if (this.form.valid) {
      const {name, startDate, endDate} = this.form.value;
      const id = this.academicYear ? this.academicYear.id : '';
      const institutionId = this.institutionId;
      
      if(id===''){
        var addCommand: AddAcademicYearCommand = {
          id: uuidv4(),
          institutionId,
          name,
          startDate,
          endDate
        }
        this.addAcademicYear.emit(addCommand);
      }else{
        var editCommand: EditAcademicYearCommand =
        {
          id,
          name,
          startDate,
          endDate
        }
        this.editAcademicYear.emit(editCommand);
      }
      this.navigate.emit(NavigationType.Forward);
    }
  }

  goBack = () => {
    this.navigate.emit(NavigationType.Back);
  }

  skip = () => {
    this.dialog.open<WizardSkipDialogContainerComponent, IWizardSkipDialogData>(WizardSkipDialogContainerComponent, {
      data: {
        itemType: ChecklistItemType.Calendar_AddYear,
        toNextStepCb: this.toNextStep
      }
    });
  }

  toNextStep = () => {
    this.navigate.emit(NavigationType.Skip);
  }
}
