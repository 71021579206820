<div class="wizard-logo-uploader" [ngClass]="[isSending ? 'is_sending' : '']">
  <input hidden type="file"
         (change)="fileInputChange($event)"
         #fileInput/>

  <div class="button" (click)="fileInput.click();">
    <div class="icon">
      <mat-icon>add</mat-icon>
    </div>

    <div class="text">{{title}}</div>
  </div>

  <div class="img-container"
       [ngStyle]="{'width' : xSize + 'px', 'height' : ySize + 'px'}"
       (click)="fileInput.click()">
    <div class="img"
         *ngIf="logoUrl"
         [ngStyle]="{'background-image' : 'url(' + logoUrl + ')'}"></div>
    <div class="img-stub" *ngIf="!logoUrl">
      {{xSize}}x{{ySize}}
    </div>
  </div>
</div>
