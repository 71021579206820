<div class="course-sessions">
  <div class="table">
    <div class="table-head">
      <div class="table-row">
        <div class="table-cell table-cell-sorts table-cell-date" *ngIf="showCode">
          <div class="table-cell-sorts-title">Code</div>
        </div>
        <div class="table-cell table-cell-sorts table-cell-date">
          <div class="table-cell-sorts-title">Start Date</div>
        </div>

        <div class="table-cell table-cell-sorts table-cell-date">
          <div class="table-cell-sorts-title">End Date</div>
        </div>

        <div class="table-cell table-cell-seats" *ngIf="showSeats">Seats</div>
        <div class="table-cell table-cell-fee" *ngIf="showMemberFee">Member Fee</div>
      </div>
    </div>

    <div class="table-body">
      <div class="table-row" *ngFor="let session of sessions">
        <div class="table-cell table-cell-date" *ngIf="showCode">{{session.code}}</div>
        <div class="table-cell table-cell-date">{{getStartDate(session)}}</div>
        <div class="table-cell table-cell-date">{{getEndDate(session)}}</div>
        <div class="table-cell table-cell-seats" *ngIf="showSeats">{{session.availableSeats}}</div>
        <div class="table-cell table-cell-fee" *ngIf="showMemberFee">{{session.memberFeeAmount}}</div>
      </div>
    </div>
  </div>
</div>
