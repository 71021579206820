import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation, Input} from '@angular/core';

import {NavigationEnd, Router} from '@angular/router';
import {Subject, Observable} from 'rxjs';
import {delay, filter, take, takeUntil, map} from 'rxjs/operators';

import {FuseConfigService} from '@fuse/services/config.service';
import {FuseNavigationService} from '@fuse/components/navigation/navigation.service';
import {FusePerfectScrollbarDirective} from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import {FuseSidebarService} from '@fuse/components/sidebar/sidebar.service';
import {faCog, faQuestionCircle} from '@fortawesome/free-solid-svg-icons';
import {UserSummary} from 'app/authentication/models/userSummary.model';
import {AuthenticationService} from 'app/authentication/services/authentication.service';
import hasInstitutionPermission from 'app/authentication/permissions/hasInstitutionPermission';
import {PermissionType} from 'app/authentication/models/permissionType.enum';
import {IAppState} from 'app/store/state/app.state';
import {Store, select} from '@ngrx/store';
import * as fromAuth from '@auth/store';
import * as fromUserList from '@shared/user/components/user-list';

@Component({
  selector: 'app-institution-admin-dashboard-nav',
  templateUrl: './institution-admin-dashboard-nav.component.html',
  styleUrls: ['./institution-admin-dashboard-nav.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class InstitutionAdminDashboardNavComponent implements OnInit, OnDestroy {
  fuseConfig: any;
  navigation: any;

  faCog = faCog;
  faQuestionCircle = faQuestionCircle;
  showInstitutionalPolicies$: Observable<boolean>;
  showCalendars$: Observable<boolean>;
  showUsers$: Observable<boolean>;
  @Input() institutionId: string;
  @Input() user: UserSummary;
  // Private
  private _fusePerfectScrollbar: FusePerfectScrollbarDirective;
  private _unsubscribeAll: Subject<any>;
  currentUser$: Observable<UserSummary>;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseNavigationService} _fuseNavigationService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {Router} _router
   */
  constructor(
    private authService: AuthenticationService,
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    private _router: Router,
    private store: Store<IAppState>
  ) {

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  // Directive
  @ViewChild(FusePerfectScrollbarDirective, {static: true})
  set directive(theDirective: FusePerfectScrollbarDirective) {
    if (!theDirective) {
      return;
    }

    this._fusePerfectScrollbar = theDirective;

    // Update the scrollbar on collapsable item toggle
    this._fuseNavigationService.onItemCollapseToggled
      .pipe(
        delay(500),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(() => {
        this._fusePerfectScrollbar.update();
      });

    // Scroll to the active item position
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        take(1)
      )
      .subscribe(() => {
          setTimeout(() => {
            this._fusePerfectScrollbar.scrollToElement('navbar .nav-link.active', -120);
          });
        }
      );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.currentUser$ = this.store.pipe(select(fromAuth.Selectors.CurrentUser));

    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(() => {
          if (this._fuseSidebarService.getSidebar('navbar')) {
            this._fuseSidebarService.getSidebar('navbar').close();
          }
        }
      );

    // Subscribe to the config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.fuseConfig = config;
      });

    // Get current navigation
    this._fuseNavigationService.onNavigationChanged
      .pipe(
        filter(value => value !== null),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(() => {
        this.navigation = this._fuseNavigationService.getCurrentNavigation();
      });
    this.setupPermissions();
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar opened status
   */
  toggleSidebarOpened(): void {
    this._fuseSidebarService.getSidebar('navbar').toggleOpen();
  }

  /**
   * Toggle sidebar folded status
   */
  toggleSidebarFolded(): void {
    this._fuseSidebarService.getSidebar('navbar').toggleFold();
  }

  logout(): void {
    this.authService.logout();
  }

  // goToUserList(): void {
  //   const institutionId = this.institutionId;
  //   const navigationArray = ['institution', institutionId, 'users', 'list'];
  //   this.store.dispatch(fromUserList.Actions.Navigate({
  //     institutionId,
  //     navigationArray
  //   }));
  // }

  setupPermissions(): void {
    this.showCalendars$ = this.store
      .pipe(
        select(fromAuth.Selectors.CurrentUser),
        map(user =>
          hasInstitutionPermission(user, this.institutionId, PermissionType.ReadCalendar))
      );
    this.showInstitutionalPolicies$ = this.store
      .pipe(
        select(fromAuth.Selectors.CurrentUser),
        map(user =>
          hasInstitutionPermission(user, this.institutionId, PermissionType.ReadInstitutionalPolicy))
      );
    this.showUsers$ = this.store
      .pipe(
        select(fromAuth.Selectors.CurrentUser),
        map(user =>
          hasInstitutionPermission(user, this.institutionId, PermissionType.ReadUser))
      );
  }
}
