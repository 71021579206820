import { createAction, props } from '@ngrx/store';
import { Provider } from '@shared/provider/models';
import { ProviderPolicy, Course } from '@shared/institution/models';
import { ProviderCourseListItem } from '@provider/courses/models/providerCourseListItem.model';

export const LoadProvider = createAction(
    '[Provider Resolver] - Load Provider',
    props<{
        providerId: string;
    }>()
);

export const LoadProviderSuccess = createAction(
    '[Provider Resolver] - Load Provider Success',
    props<{
        provider: Provider;
    }>()
);

export const LoadCourseExchangeSummary = createAction(
    '[Provider Course Exchange Summary Resolver] - Load Course Exchange Summary',
    props<{
        providerId: string
    }>()
)

export const LoadPolicies = createAction(
    '[Provider Policies Resolver] - Load Provider Policies',
    props<{
        institutionId: string;
    }>()
);

export const LoadPoliciesSuccess = createAction(
    '[Provider Effect] - Load Provider Policies Success',
    props<{
        providerPolicies: ProviderPolicy[]
    }>()
)

export const downloadProviderPolicy = createAction(
    '[Provider Policies List] - Download Provider Policy',
    props<{
        providerPolicy: ProviderPolicy
    }>()
)

export const uploadProviderPolicy = createAction(
    '[Provider Policies List] - Upload Provider Policy',
    props<{
        providerPolicyId: string,
        policyFile: File
    }>()
)

export const UploadProviderPolicySuccess = createAction(
    '[Provider Effects] - Upload Provider Policy Success',
    props<{
        providerPolicy: ProviderPolicy
    }>()
)

export const uploadProviderPolicyError = createAction(
  '[Provider Policies List] - Upload Provider Policy Error',
  props<{
    providerPolicyId: string,
    message: string;
  }>()
)

export const completeProviderSetup = createAction(
    '[Provider Wizard Policies Step] - Complete Provider Setup'
);

export const CompleteProviderSetupSuccess = createAction(
    '[Provider Effect] - CompleteProviderSetupSuccess',
    props<{
        provider: Provider
    }>()
)

export const RemoveFacultyCVFromCourse = createAction(
    '[Various Places] - Remove Faculty CV From Course',
    props<{
        facultyCVId: string,
        courseId: string
    }>()
);

export const RemoveFacultyCVFromCourseSuccess = createAction(
    '[Provider Effect] - Remove FacultyCV Success',
    props<{
        course: Course
    }>()
)

export const AddExistingFacultyCVToCourse = createAction(
    '[Various Places] - Add Existing Faculty CV to Course',
    props<{
        facultyCVIds: string[],
        courseId: string
    }>()
)

export const AddExistingFacultyCVToCourseSuccess = createAction(
    '[Various Places] - Add Existing Faculty CV to Course Success',
    props<{
        facultyCVIds: string[],
        courseId: string
    }>()
)


export const AddNewFacultyCVToCourse = createAction(
    '[Various Places] - Add New Faculty CV to Course',
    props<{
        courseId: string,
        description: string,
        file: File
    }>()
)

export const AddNewFacultyCVToCourseSuccess = createAction(
    '[Various Places] - Add New Faculty CV to Course Success',
    props<{
        course: Course
    }>()
)

export const LoadProviderCourses = createAction(
    '[Provider Courses Resolver] - Load Provider Courses',
    props<{
        providerId: string
    }>()
)

export const ClearState = createAction(
    '[Provider Reducer] - Clear State'
)

export const NotifyNotComplete = createAction(
    '[Provider Wizard] - Notify Not Complete'
)

export const ProviderUpdated = createAction(
    '[Provider Realtime] - Provider Updated',
    props<{
        provider: Provider
    }>()
)

export const ProviderCourseListItemUpdated = createAction(
    '[Provider Realtime] - Provider Course List Item Updated',
    props<{
        providerCourseListItem: ProviderCourseListItem
    }>()
)

export const ProviderCourseListItemAdded = createAction(
    '[Provider Realtime] - Provider Course List Item Added',
    props<{
        providerCourseListItem: ProviderCourseListItem
    }>()
)

export const ProviderPolicyUpdated = createAction(
    '[Provider Realtime] - Provider Policy Updated',
    props<{
        providerPolicy: ProviderPolicy
    }>()
)


