import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fromServices from './services';
import * as fromResolvers from './resolvers';
import * as fromStore from './store';
import { MaterialModule } from 'app/core/material/material.module';
import * as fromComponents from './components';
import { EffectsModule } from '@ngrx/effects';
@NgModule({
    declarations: [...fromComponents.COMPONENTS],
    exports: [...fromComponents.COMPONENTS],
    imports: [
        CommonModule,
        MaterialModule,
        EffectsModule.forFeature([...fromStore.EFFECTS])
    ],
    providers: [...fromServices.SERVICES, ...fromResolvers.RESOLVERS]
})
export class ProviderSharedModule { }
