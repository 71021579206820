import { institutionRoles } from './../../../../../../core/referenceData/store/refData.selectors';
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { Injectable } from "@angular/core";
import { map, switchMap, concatMap, withLatestFrom, tap } from "rxjs/operators";
import { UserService } from '@shared/user/services';
import { ViewContext } from '@auth/models/viewContext.enum';
import { SendAccountSetupEmailCommand } from '@shared/user/commands/SendAccountSetupEmailCommand';
import { ActivateUserCommand, DeactivateUserCommand, DeleteUserCommand, UpdateAccessRoleCommand, UpdateInstitutionAccessRoleCommand } from '@shared/user/commands';
import { of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as fromCore from '@core/store';
import * as fromUserList from '@shared/user/components/user-list/store';
import * as fromUsers from '@shared/user/store';
import * as fromRefData from '@refData';
import { IAppState } from 'app/store/state/app.state';
import { PermissionDialogData, PermissionsDialogComponent } from '@shared/user/components/permissions/dialogs/user-permissions/permissions.dialog';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmDialogData } from "@core/components/confirm-dialog/confirm-dialog.component";
import { UserListItem } from "@shared/user/models";

@Injectable()
export class UserListEffects {
        constructor(private actions$: Actions, private userService: UserService, private store: Store<IAppState>,
            private dialog: MatDialog) {
        }
        sendInviteEmail$ = createEffect(() => {
            return this.actions$.pipe(
                    ofType(fromUserList.Actions.SendInviteEmail),
                    /** An EMPTY observable only emits completion. Replace with your own observable stream */
                    switchMap(({user}) => {
                        var courseExchangeRole = user.accessRoles.find(x=>x.viewContext === ViewContext.Member || x.viewContext === ViewContext.Provider || x.viewContext === ViewContext.CourseExchangeAdmin);
                        var courseExchangeId = !!courseExchangeRole ? courseExchangeRole.courseExchangeId: "";
                        const command: SendAccountSetupEmailCommand = { userId: user.id, courseExchangeId: courseExchangeId };
                        return this.userService.sendInvite(user.institutionId, command);
                    }),
                    map((response)=>fromCore.Actions.HandleResponse({response}))
            );
        });
        confirmActivateUser$ = createEffect(() => {
            return this.actions$.pipe(
                    ofType(fromUserList.Actions.ConfirmActivateUser),
                    tap(({user}) => {
                        const dialogData: ConfirmDialogData = {
                            title: "Confirm Activation",
                            message: `You are activating this User.  Are you sure you want to continue?`,
                            cancelButtonText: "No",
                            okButtonText: "Yes"
                          };
                      
                          const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                            maxWidth: "400px;",
                            data: dialogData
                          });
                      
                          dialogRef.afterClosed().subscribe(dialogResult => {
                            if(dialogResult){
                              const userId = user.id;
                              const institutionId = user.institutionId;
                              this.store.dispatch(fromUserList.Actions.ActivateUser({userId, institutionId}))
                            }
                          })
                    })
            );
        },{dispatch:false});

        activateUser$ = createEffect(() => {
            return this.actions$.pipe(
                    ofType(fromUserList.Actions.ActivateUser),
                    /** An EMPTY observable only emits completion. Replace with your own observable stream */
                    switchMap(({userId,institutionId}) => {
                        var command: ActivateUserCommand = {userId};
                        return this.userService.activateUser(institutionId, command);
                    }),
                    map((response) =>fromCore.Actions.HandleResponse({response}))
                )
        });

        addUser$ = createEffect(() => {
            return this.actions$.pipe(
                    ofType(fromUserList.Actions.AddUser),
                    /** An EMPTY observable only emits completion. Replace with your own observable stream */
                    switchMap(({command}) => {
                        const institutionId = command.institutionId;
                        return this.userService.addUser(institutionId, command);
                    }),
                    map((response) =>fromCore.Actions.HandleResponse({response}))
                )
        });

        addUserToStore$ = createEffect(() => {
            return this.actions$.pipe(
                    ofType(fromUserList.Actions.AddUser),
                    concatMap((action)=> of(action).pipe(
                        withLatestFrom(this.store.pipe(select(fromRefData.institutionRoles)))
                    )),
                    /** An EMPTY observable only emits completion. Replace with your own observable stream */
                    map(([{command},institutionRoles]) => {
                        const {id, institutionId, department, title, firstName, middleName, lastName, emailAddress, phoneNumber, institutionRole} = command;
                        const institutionRoleItem = institutionRoles.find(x=>x.description==institutionRole);
                        const institutionRoleId = institutionRoleItem.id;
                        const user:UserListItem={id, institutionId, department, title, firstName, lastName, emailAddress, phoneNumber, institutionRole, institutionRoleId,institutionName:'', institutionLogoUrl:'',accessRoles:[],isActive:true };
                        
                        return fromUsers.Actions.UserAdded({user})
                    })
                )
        });

        

        updateUser$ = createEffect(() => {
            return this.actions$.pipe(
                    ofType(fromUserList.Actions.UpdateUser),
                    /** An EMPTY observable only emits completion. Replace with your own observable stream */
                    switchMap(({command}) => {
                        const institutionId = command.institutionId;
                        return this.userService.updateUser(institutionId, command);
                    }),
                    map((response) =>fromCore.Actions.HandleResponse({response}))
                )
        });

        confirmDeactivateUser$ = createEffect(() => {
            return this.actions$.pipe(
                    ofType(fromUserList.Actions.ConfirmDeactivateUser),
                    tap(({user}) => {
                        const dialogData: ConfirmDialogData = {
                            title: "Confirm Deactivation",
                            message: `You are deactivating this User.  Are you sure you want to continue?`,
                            cancelButtonText: "No",
                            okButtonText: "Yes"
                          };
                      
                          const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                            maxWidth: "400px;",
                            data: dialogData
                          });
                      
                          dialogRef.afterClosed().subscribe(dialogResult => {
                            if(dialogResult){
                              const userId = user.id;
                              const institutionId = user.institutionId;
                              this.store.dispatch(fromUserList.Actions.DeactivateUser({userId, institutionId}))
                            }
                          })
                    })
            );
        },{dispatch:false});

        deactivateUser$ = createEffect(() => {
            return this.actions$.pipe(
                    ofType(fromUserList.Actions.DeactivateUser),
                    /** An EMPTY observable only emits completion. Replace with your own observable stream */
                    switchMap(({userId, institutionId}) => {
                        var command: DeactivateUserCommand = {userId};
                        return this.userService.deactivateUser(institutionId, command);
                    }),
                    map((response) =>fromCore.Actions.HandleResponse({response}))
                )
        });

        confirmDeleteUser$ = createEffect(() => {
            return this.actions$.pipe(
                    ofType(fromUserList.Actions.ConfirmDeleteUser),
                    tap(({user}) => {
                        const dialogData: ConfirmDialogData = {
                            title: "Confirm Delete",
                            message: `You are permanently deleting this User.  Are you sure you want to continue?`,
                            cancelButtonText: "No",
                            okButtonText: "Yes"
                          };
                      
                          const dialogRef = this.dialog.open(ConfirmDialogComponent, {
                            maxWidth: "400px;",
                            data: dialogData
                          });
                      
                          dialogRef.afterClosed().subscribe(dialogResult => {
                            if(dialogResult){
                              this.store.dispatch(fromUserList.Actions.DeleteUser({user}))
                            }
                          })
                    })
            );
        },{dispatch:false});

        deleteUser$ = createEffect(() => {
            return this.actions$.pipe(
                    ofType(fromUserList.Actions.DeleteUser),
                    switchMap(({user}) => {
                        const userId = user.id;
                        const institutionId = user.institutionId;
                        const command: DeleteUserCommand = {userId};
                        return this.userService.deleteUser(institutionId,command);
                    }),
                    map((response) => fromCore.Actions.HandleResponse({response}))
            );
        });

        deleteUserFromStore$ = createEffect(() => {
            return this.actions$.pipe(
                    ofType(fromUserList.Actions.DeleteUser),
                    map(({user}) => {
                        return fromUsers.Actions.UserDeleted({user})
                    })
            );
        });

        managePermissions$ = createEffect(() => {
            return this.actions$.pipe(
                    ofType(fromUserList.Actions.ManagePermissions),
                    /** An EMPTY observable only emits completion. Replace with your own observable stream */
                    switchMap((action) => {
                        const user = action.user;
                        const accessRole = action.accessRoleSummary;
                        return this.userService.getUserAccessRole(user.institutionId,user.id, accessRole.id);
                    }),
                    concatMap((accessRole) => of(accessRole).pipe(
                        withLatestFrom(this.store.pipe(select(fromUserList.Selectors.RefDataForManagePermissions))),
                    )),
                    tap(([accessRole, refData])=>{
                        const {permissionDisplays, courseExchangeRoles, institutionRoles} = refData;
                        var baseInstitutionPermissions = permissionDisplays.filter(x => x.viewContext === accessRole.viewContext);
    
                        const dialogData: PermissionDialogData = {
                            accessRole: accessRole,
                            basePermissions: baseInstitutionPermissions,
                            viewContext: accessRole.viewContext
                        };
        
                        const dialogRef = this.dialog.open(PermissionsDialogComponent, {
                            maxWidth: "400px;",
                            data: dialogData
                        });
        
                        dialogRef.afterClosed().subscribe(dialogResult => {
                            if (!dialogResult) return;
                            if (dialogResult.accessRole.viewContext === ViewContext.Institution) {
                                this.store.dispatch(fromUserList.Actions.SaveInstitutionAccessRole({accessRole:dialogResult.accessRole, changeAllRoles: dialogResult.changeAllRoles, courseExchangeRoles, institutionRoles}));
                            } else {
                                this.store.dispatch(fromUserList.Actions.SaveAccessRole({accessRole: dialogResult.accessRole}));
                            }
                        });
                    })
            );
        },{dispatch:false});

        saveAccessRole$ = createEffect(() => {
            let accessRole = undefined;
            return this.actions$.pipe(
                    ofType(fromUserList.Actions.SaveAccessRole),
                    concatMap((action) => of(action).pipe(
                        withLatestFrom(this.store.pipe(select(fromRefData.courseExchangeRoles)))
                    )),
                    switchMap(([action,courseExchangeRoles]) => {
                        accessRole = action.accessRole;
                        var role = courseExchangeRoles.find(x => x.id === accessRole.roleId);
                        var roleDescription = `${accessRole.viewContext} - ${role.shortDescription}`;
                        const command: UpdateAccessRoleCommand =
                        {
                            userId: accessRole.userId,
                            accessRoleId: accessRole.id,
                            accessRoleDescription: `${accessRole.entityDescription} ${roleDescription}`,
                            roleId: accessRole.roleId,
                            updatedPermissions: accessRole.permissions
                        };

                        return this.userService.updateAccessRole(accessRole.institutionId, command);
                    }),
                    map((response) =>fromCore.Actions.HandleResponse({response}))
            )
        });

        saveInstitutionAccessRole$ = createEffect(() => {
            let accessRole = undefined;
            return this.actions$.pipe(
                    ofType(fromUserList.Actions.SaveInstitutionAccessRole),
                    concatMap((action) => of(action).pipe(
                        withLatestFrom(this.store.pipe(select(fromRefData.institutionRoles)))
                    )),
                    switchMap(([action,institutionRoles]) => {
                        accessRole = action.accessRole;
                        const changeAllRoles = action.changeAllRoles;
                        var role = institutionRoles.find(x => x.id === accessRole.roleId);
                        var roleDescription = `${accessRole.viewContext} - ${role.description}`;
                        const command: UpdateInstitutionAccessRoleCommand =
                        {
                            userId: accessRole.userId,
                            accessRoleId: accessRole.id,
                            accessRoleDescription: `${accessRole.entityDescription} ${roleDescription}`,
                            roleId: accessRole.roleId,
                            updatedPermissions: accessRole.permissions,
                            changeAllRoles
                        };
                        return this.userService.updateInstitutionAccessRole(accessRole.institutionId, command);
                    }),
                    map((response) =>fromCore.Actions.HandleResponse({response}))
            )
        });

}