<!-- CONTENT CARD -->
<div class="content-card">

  <!-- CONTENT -->
  <div class="content full-content-without-sidebar">
    <div class="list-page-content full-content-main-container fees-list-content">
      <!-- OPTIONS AREA is area that contains the interior list controls, i.e, sort by, order by,  -->
      <div class="full-content-main-options" fxLayout="row" fxLayoutAlign="start center">
        <div class="search-and-sort-list" fxLayout="row" fxLayoutAlign="space-between center">
          <!-- SEARCH -->
          <div class="search-wrapper">
            <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
              <mat-icon>search</mat-icon>
              <input #filter placeholder="Search Fees" (keyup)="filterChanged(filter.value)">
            </div>
          </div>
          <!-- / END SEARCH -->

          <!-- SORT MENU -->
          <div class="sort-wrapper" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
            <mat-form-field class="tab-list-sort">
              <mat-select [formControl]="sortControl" placeholder="Sort By"
                (selectionChange)="changeSort($event.value)">
                <mat-select-trigger>
                  <mat-icon>{{sortControl.value?.icon}}</mat-icon>&nbsp;{{sortControl.value?.name}}
                </mat-select-trigger>
                <mat-option *ngFor="let sort of sorts" [value]="sort">
                  <mat-icon>{{sort.icon}}</mat-icon>{{sort.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="tab-list-sort">
              <mat-select [formControl]="orderByControl" placeholder="Order By"
                (selectionChange)="changeOrderBy($event.value)">
                <mat-select-trigger>
                  <mat-icon>{{orderByControl.value?.icon}}</mat-icon>&nbsp;{{orderByControl.value?.name}}
                </mat-select-trigger>
                <mat-option *ngFor="let order of orders" [value]="order">
                  <mat-icon>{{order.icon}}</mat-icon>{{order.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <!-- /END SORT-->
        </div>
      </div>

      <!-- FULL PAGE NO SIDEBAR CONTENT MAIN AREA  -->
      <div class="full-content-main-content fees-list-main" ><!-- fusePerfectScrollbar -->
        <div class="full-content-main-wrap">
          <div> <button class="submit-button icon-btn" mat-button (click)="exportToExcel()">
            <mat-icon>save_alt</mat-icon>Export Fees to Excel
          </button>
          </div>
          <!-- FEES LIST -->
          <div class="full-content-list-wrap fees-list-wrap">
            <mat-list>
              <mat-list-item class="fees-list-item" *ngFor="let item of filteredFees$ | async">

                <div class="listing fees-listing" fxLayout="column" fxLayoutAlign="start start" >
                <div class="current-fee" fxLayout="row" fxLayoutAlign="start start" >
                  <div class="fee-course-level fee-column fee-column-heading" >{{item.courseLevel.description}}</div>
                  <div class="fee-member fee-column">
                    <div class="item">Member Fee: <span>{{currencyFormatter.format(item.memberFeeAmount)}}</span></div>
                  </div>
                  <div class="fee-member fee-column">
                    <div class="item">Provider Fee: <span>{{currencyFormatter.format(item.providerFeeAmount)}}</span></div>
                    <div class="item">Provider Fee Split: <span>{{getProviderFeeSplit(item.currentFee)}}</span></div>
                  </div>
                  <div class="fee-member fee-column" >
                    <div class="item">Management Fee: <span>{{currencyFormatter.format(item.managementFeeAmount)}}</span></div>
                    <div class="item">Management Fee Split: <span>{{getManagementFeeSplit(item.currentFee)}}</span></div>
                  </div>
                  <div class="fee-column menu-column" *ngIf="canEdit" >
                    <mat-menu #feesListMenu="matMenu">
                      <button mat-menu-item (click)="editCourseExchangeFee(item.currentFee,item.versions)" *ngIf="item.currentFee.canEdit">
                        Edit
                      </button>
                      <button mat-menu-item (click)="createVersion(item.currentFee,item.versions)">
                        Create Version
                      </button>
                    </mat-menu>
                    <button mat-icon-button [matMenuTriggerFor]="feesListMenu">
                      <mat-icon>more_horiz</mat-icon>
                    </button>
                  </div>
                </div>
                <div class="fees-list-expansion" *ngIf="item.versions.length>1" >

                  <div class="fee-accordion-panel" (click)="item.accordionFlag = !item.accordionFlag">
                    <div class="accordion-btn-wrap">
                      <div *ngIf="!item.accordionFlag" class="fee-accordion-panel-title"><button class="outline-btn" mat-stroked-button>Show Past and Future Versions <mat-icon>keyboard_arrow_down</mat-icon></button></div>
                      <div *ngIf="item.accordionFlag" class="semester-accordion-panel-title"><button class="outline-btn" mat-stroked-button>Hide Past and Future Versions <mat-icon>keyboard_arrow_up</mat-icon></button></div>
                      <!-- <button mat-icon-button class="expand-button">
                        <mat-icon>keyboard_arrow_up</mat-icon>
                        <mat-icon >keyboard_arrow_down</mat-icon>
                      </button> -->
                    </div>
                  </div>
  
                  <div *ngIf="item.accordionFlag">
                    <mat-list class="fees-list-expansion-panel-item-content">
                      <mat-list-item *ngFor="let version of item.versions">
                        <div class="past-or-future-fee seperator" fxLayout="row" fxLayoutAlign="start start">
                          <div class="fee-member fee-column">
                            <div class="item">
                              Member Fee: <span>{{currencyFormatter.format(version.memberFeeAmount)}}</span>
                            </div>
                          </div>
                          <div class="fee-member fee-column">
                            <div class="item">Provider Fee: <span>{{currencyFormatter.format(version.providerFeeAmount)}}</span></div>
                            <div class="item">Provider Fee Split: <span>{{getProviderFeeSplit(version)}}</span></div>
                          </div>
                          <div class="fee-member fee-column">
                            <div class="item">Management Fee: <span>{{currencyFormatter.format(version.managementFeeAmount)}}</span></div>
                            <div class="item">Management Fee Split: <span>{{getManagementFeeSplit(version)}}</span></div>
                          </div>
                          <div class="fee-member fee-column">
                            <div class="item">Effective Date: <span>{{version.effectiveDate | date}}</span></div>
                            <div class="item">Expiry Date: <span>{{version.expiryDate | date}}</span></div>
                          </div>
                          <div class="fee-column menu-column" *ngIf="canEdit" >
                            <mat-menu #versionListMenu="matMenu">
                              <button mat-menu-item (click)="editCourseExchangeFee(version,item.versions)" *ngIf="version.canEdit">
                                Edit Fee
                              </button>
                              <button mat-menu-item (click)="createVersion(version,item.versions)">
                                Create Version
                              </button>
                              <button mat-menu-item (click)="removeVersion(version)" *ngIf="canRemove(version)">
                                Remove Version
                              </button>
                            </mat-menu>
                            <button mat-icon-button [matMenuTriggerFor]="versionListMenu">
                              <mat-icon>more_horiz</mat-icon>
                            </button>
                          </div>
                        </div>
                      </mat-list-item>
                    </mat-list>

                  </div>  

                </div>
                </div>

              </mat-list-item>
            </mat-list>
          </div>
        </div>
      </div>
      <!-- <div class="full-content-main-buttons" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
       
        <ng-content></ng-content>
      </div> -->
    </div>
  </div>
  <!-- / CONTENT -->
</div>