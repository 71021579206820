import { createAction, props } from '@ngrx/store';
import {StudentListItem, Student} from '@shared/student/models';
import { Sort, Order, BaseListItem } from 'app/core/models';
import { Filter } from '@shared/common/models';
import { ExcelColumn } from 'app/core/services/excel.service';
import { AddStudentCommand, EditStudentCommand, DeleteStudentCommand, DeactivateStudentCommand, ActivateStudentCommand } from '@shared/student/commands';
import { StudentDetailsViewModel } from '@shared/student/models/studentDetails.viewModel';

export const LoadStudents = createAction(
    '[Student List Resolver] - Load Member',
    props<{
        institutionId: string;
    }>()
);

export const LoadStudentsSuccess = createAction(
    '[Student Effect] - Load Students Success',
    props<{
        students: StudentListItem[]
    }>()
)

export const LoadStudentsError = createAction(
  '[Student Effect] - Load Students Error',
  props<{
    message: string
  }>()
)

export const LoadStudent = createAction(
    '[Student Resolver] - Load Student',
    props<{
        studentId: string
    }>()
)

export const LoadStudentSuccess = createAction(
    '[Student Effect] - Load Student Success',
    props<{
        currentStudent: Student
    }>()
)

export const LoadStudentError = createAction(
  '[Student Effect] - Load Student Error',
  props<{
    message: string
  }>()
)

export const LoadStudentDetails = createAction(
    '[Student Details Resolver] - Load Student Details',
    props<{
        studentId: string
    }>()
)

export const LoadStudentDetailsSuccess = createAction(
    '[Student Effect] - Load Student Details Success',
    props<{
        currentStudentDetails: StudentDetailsViewModel
    }>()
)

export const LoadStudentDetailsError = createAction(
  '[Student Effect] - Load Student Details Error',
  props<{
    message: string
  }>()
)

export const CreateEnrollments = createAction(
    '[New Enrollment Container] - Create Enrollments',
    props<{
        studentIds?: string[],
        providerCourseSessionIds: string[];
    }>()
)

export const LoadSorts = createAction(
    '[Student List] - Load Sorts',
    props<{
        sorts: Sort[];
    }>()
)

export const ChangeSort = createAction(
    '[Student List] - Change Sort',
    props<{
        currentSort: Sort
    }>()
)

export const LoadOrders = createAction(
    '[Student List] - Load Orders',
    props<{
        orders: Order[];
    }>()
)

export const ChangeOrder = createAction(
    '[Student List] - Change Order',
    props<{
        currentOrder: Order
    }>()
)

export const MasterToggle = createAction(
    '[Student List] Master Toggle',
    props<{
        toggle?: boolean
    }>()
);

export const MasterToggled = createAction(
    '[Student List] MasterToggled',
    props<{
        students: StudentListItem[]
    }>()
);

export const ToggleStudent = createAction(
    '[Student List] - Toggle Student',
    props<{
        studentId: string
    }>()
);

export const ClearSelected = createAction(
    '[Student List] - Clear All Selected'
)

export const ToggleStatusFilter = createAction(
    '[User List] - Toggle Status Filter',
    props<{
        id: string
    }>()
)

export const RemoveFilter = createAction(
    '[Student list] - Remove Filter',
    props<{
        filter: Filter
    }>()
);

export const AddFilter = createAction(
    '[Student list] - Add Filter',
    props<{
        filter: Filter
    }>()
);

export const ChangeSearchText = createAction(
    '[Student List] - Change Search Text',
    props<{
        searchText: string
    }>()
)

export const ExportToExcel = createAction(
    '[Student List] - Export to Excel',
    props<{
        columns: ExcelColumn[],
        fileName: string;
    }>()
)

export const ImportStudents = createAction(
    '[Student List] - Import Students',
    props<{
        institutionId: string;
        commands: AddStudentCommand[]
    }>()
)

export const AddStudent = createAction(
    '[Student List] - Add Student',
    props<{
        command: AddStudentCommand
    }>()
);

export const ShowStudentAddedDialog = createAction(
    '[Student List] - Show Student Added Dialog',
    props<{
        command:AddStudentCommand
    }>()
);

export const StudentAdded = createAction(
    '[Student Effects] - Student Added',
    props<{
        student: StudentListItem
    }>()
)

export const AddStudentError = createAction(
  '[Student Confirm Delete] - Add Student Error',
  props<{
    message: string
  }>()
)

export const EnrollStudent = createAction(
    '[Student Added Dialog] - Enroll Student'
)

export const EditStudent = createAction(
    '[Student Detail] - Edit Student',
    props<{
        oldStudent: Student,
        command: EditStudentCommand
    }>()
)

export const EditStudentSuccess = createAction(
    '[Student Effects] - Edit Student Success'
)
export const StudentUpdated = createAction(
    '[Student Realtime] - Student Edited',
    props<{
        student: StudentListItem
    }>()
)

export const EditStudentError = createAction(
  '[Student Confirm Delete] - Edit Student Error',
  props<{
    oldStudent: Student,
    message: string
  }>()
)

export const ConfirmDelete = createAction(
    '[Student List] - Confirm Student Delete',
    props<{
        student: StudentListItem
    }>()
)

export const DeleteStudent = createAction(
    '[Student Confirm Delete] - Delete Student',
    props<{
        command: DeleteStudentCommand
    }>()
)

export const DeleteStudentSuccess = createAction(
    '[Student Effects] - Delete Student Success',
    props<{
        id: string
    }>()
)

export const DeleteStudentError = createAction(
  '[Student Confirm Delete] - Delete Student Error',
  props<{
    message: string
  }>()
)

export const ActivateStudent = createAction(
    '[Student List] - Activate Student',
    props<{
        command: ActivateStudentCommand
    }>()
)

export const ActivateStudentSuccess = createAction(
    '[Student Effects] - Activate Student Success',
    props<{
        id: string
    }>()
)

export const ActivateStudentError = createAction(
  '[Student Effects] - Activate Student',
  props<{
    message: string
  }>()
)

export const DeactivateStudent = createAction(
    '[Student List] - Deactivate Student',
    props<{
        command: DeactivateStudentCommand
    }>()
)

export const DeactivateStudentSuccess = createAction(
    '[Student Effects] - Deactivate Student Success',
    props<{
        id: string
    }>()
)

export const DeactivateStudentError = createAction(
  '[Student Effects] - Deactivate Student',
  props<{
    message: string
  }>()
)

export const EditStatus = createAction(
    '[Student Detail] - Edit Status',
    props<{
        enrollmentId: string,
        requestedStatus: BaseListItem
    }>()
)

export const SubmitStatusChanges = createAction(
    '[Student Detail] - Submit Status Changes'
)

export const SubmitStatusChangesSuccess = createAction(
    '[Student Effects] - Submit Status Changes Success'
)
export const SubmitStatusChangesError = createAction(
    '[Student Effects] - Submit Status Changes Error',
    props<{
        message: string
    }>()
)
