import {Directive, Input, ElementRef, AfterViewInit, OnChanges, OnDestroy, OnInit} from '@angular/core';

@Directive({
  selector: '[readMore]'
})

export class ReadMoreDirective implements AfterViewInit, OnChanges, OnDestroy, OnInit {
  @Input() private maxHeight: number;

  constructor(private el: ElementRef) {
  }

  ngOnInit(): void {
    window.addEventListener('resize', this.onResize);
  }

  ngOnDestroy(): void {
    window.removeEventListener('resize', this.onResize);
  }

  ngAfterViewInit(): void {
    this.check();
  }

  ngOnChanges(): void {
    this.check();
  }

  private onResize = (): void => {
    this.check();
  }

  private check(): void {
    const elemNode = this.el.nativeElement;
    const textNode = elemNode.querySelector('.read-more-text');

    if (textNode.offsetHeight > this.maxHeight) {
      elemNode.classList.add('no-fit');
      elemNode.classList.remove('is-fit');
      elemNode.style.maxHeight = this.maxHeight + 'px';
    } else {
      elemNode.classList.add('is-fit');
      elemNode.classList.remove('no-fit');
      elemNode.style.maxHeight = 'auto';
    }
  }
}
