import { props, createAction } from '@ngrx/store';
import { MemberMonthlyBill } from '@member/billing/models/memberMonthlyBill';
import { MonthlyStatement } from '@core/models/monthlyStatement.viewModel';

export const LoadMonthlyStatements = createAction(
    '[Member Fee Monthly Summaries Resolver] - Load Member Monthly Statements',
    props<{
        memberId?: string;
    }>()
);

export const LoadMonthlyStatementsSuccess = createAction(
    '[Member Billing Effects] - Load Member Monthly Statements Success',
    props<{
        monthlyStatements: MemberMonthlyBill[]
    }>()
)

export const LoadMonthlyStatementsError = createAction(
    '[Member Billing Effects] - Load Member Monthly Statements Error',
    props<{
        message: string;
    }>()
)

export const LoadCurrentMonthlyStatement = createAction(
    '[Member Billing Effects] - Load Member Current Monthly Statement',
    props<{
        currentMonthlyStatement: MemberMonthlyBill}
    >()
)

export const DownloadMonthlyStatement = createAction(
    '[Member Monthly Billing Summary] - Download Monthly Statement',
    props<{
        monthlyStatement: MonthlyStatement;
    }>()
)

export const MemberMonthlyBillUpdated = createAction(
    '[Member Monthly Billing Realtime] - Member Monthly Bill Updated',
    props<{
        memberMonthlyBill: MemberMonthlyBill
    }>()
)

export const MemberMonthlyBillAdded = createAction(
    '[Member Monthly Billing Realtime] - Member Monthly Bill Added',
    props<{
        memberMonthlyBill: MemberMonthlyBill
    }>()
)

export const ExportMemberDetailedReport = createAction(
    '[Member Billing] - Member Detailed Report',
    props<{
        year: number,
        month: number
    }>()
)