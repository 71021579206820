import { createAction, props } from '@ngrx/store';
import { EnrollmentListItem, EnrollmentListItemForView } from '@member/enrollment/models/enrollmentListItem.viewModel';
import { Sort, Order, BaseListItem } from '@core/models';
import { Filter } from '@shared/common/models';
import { EnrollmentListMode } from '../enrollmentListMode.enum';
import { UpdateEnrollmentFeeCommand } from '@courseExchange/enrollment/models/updateEnrollmentFee.command';
import { AcademicYear } from '@shared/calendar/models';
import { ChangeEnrollmentStatusBulkCommand } from '@shared/member/models/commands/changeEnrollmentStatus.command';
import { EnrollmentStatus } from '@core/referenceData';

export const LoadActiveEnrollments = createAction(
    '[Enrollments for CourseExchange Resolver] - Load Active Enrollments',
    props<{
        courseExchangeId?: string
    }>()
)
export const LoadActiveEnrollmentsSuccess = createAction(
    '[CourseExchange Enrollments Effects] - Load Active Enrollments Success',
    props<{
        enrollments: EnrollmentListItemForView[]
    }>()
)

export const LoadActiveEnrollmentsError = createAction(
    '[CourseExchange Enrollments Effects] - Load Active Enrollments Error',
    props<{
        message: string
    }>()
)

export const EditEnrollmentFee = createAction(
    '[CourseExchange Enrollment List] - Edit Enrollment Fee',
    props<{
        enrollment: EnrollmentListItem
    }>()
)

export const SubmitEnrollmentFeeChanges = createAction(
    '[CourseExchange Enrollment List] - Submit Enrollment Fee Change',
    props<{
        command: UpdateEnrollmentFeeCommand
    }>()

)

export const SubmitEnrollmentFeeChangesSuccess = createAction(
    '[CourseExchange Enrollment Effects] - Submit Enrollment Fee Success',
    props<{
        enrollment: EnrollmentListItem
    }>()
)

export const SubmitEnrollmentFeeChangesError = createAction(
    '[CourseExchange Enrollment Effects] - Submit Enrollment Fee Error',
    props<{
        message: string
    }>()
)

export const LoadOrders = createAction(
    '[CourseExchange Enrollment List] - Load Orders',
    props<{
        orders: Order[]
    }>()
)
export const ChangeOrder = createAction(
    '[CourseExchange Enrollment List] - Change Order',
    props<{
        currentOrder: Order
    }>()
)

export const LoadSorts = createAction(
    '[CourseExchange Enrollment List] - Load Sorts',
    props<{
        sorts: Sort[]
    }>()
)
export const ChangeSort = createAction(
    '[CourseExchange Enrollment List] - Change Sort',
    props<{
        currentSort: Sort
    }>()
)
export const ChangeSearchText = createAction(
    '[CourseExchange Enrollment List] - Change Search Text',
    props<{
        searchText: string
    }>()
)
export const MasterToggle = createAction(
    '[CourseExchange Enrollment List] - Master Toggle'
)
export const ToggleEnrollment = createAction(
    '[CourseExchange Enrollment List] - Toggle Enrollment',
    props<{
        enrollmentId: string
    }>()
)
export const ToggleStatusFilter = createAction(
    '[CourseExchange Enrollment List] - Toggle Status Filter',
    props<{
        id: string
    }>()
  )
export const ToggleSemesterFilter = createAction(
    '[CourseExchange Enrollment List] - Toggle Semester Filter',
    props<{
        semesterId: string
    }>()
)
export const RemoveFilter = createAction(
    '[CourseExchange Enrollment List] - Remove Filter',
    props<{
        filter: Filter
    }>()
)

export const LoadEnrollmentByDateRange = createAction(
    '[Course Exchange Enrollment Effects] - Load Enrollments By Date Range',
    props<{
        courseExchangeId?: string,
        startDate?: Date,
        endDate?: Date
    }>()
)

export const LoadProviderFilters = createAction(
    '[CourseExchange Enrollment List] - Load Provider Filters',
    props<{
      providerFilters: Filter[]
    }>()
  )
  export const ToggleProviderFilter = createAction(
    '[CourseExchange Enrollment List] - Toggle Provider Filter',
    props<{
        id: string
    }>()
  )
  export const LoadMemberFilters = createAction(
    '[CourseExchange Enrollment List] - Load Member Filters',
    props<{
        memberFilters: Filter[]
    }>()
  )
  export const ToggleMemberFilter = createAction(
    '[CourseExchange Enrollment List] - Toggle Member Filter',
    props<{
        id: string
    }>()
  )
  export const LoadTagFilters = createAction(
    '[Course Exchange Summary Resolver] - Load Tag Filters',
    props<{
      tagFilters: Filter[]
    }>()
  )
  export const ToggleTagFilter = createAction(
    '[Course Exchange Enrollment List] - Toggle Tag Filter',
    props<{
        id: string
    }>()
  )
  export const LoadCategoryFilters = createAction(
    '[Course Exchange Summary Resolver] - Load Category Filters',
    props<{
        courseCategoryFilters: Filter[]
    }>()
  )
  
  export const LoadSubCategoryFilters = createAction(
    '[Course Exchange Summary Resolver] - Load Sub Category Filters',
    props<{
        courseSubCategoryFilters: Filter[]
    }>()
  )

  export const ToggleCategoryFilter = createAction(
    '[Course Exchange Enrollment List] - Toggle Category Filter',
    props<{
        id: string
    }>()
  )
  
  
  export const ToggleSubCategoryFilter = createAction(
    '[Course Exchange Enrollment List] - Toggle Sub Category Filter',
    props<{
        id: string
    }>()
  )

  
export const EnrollmentListItemUpdated = createAction(
    '[CourseExchnge Enrollment Realtime] - Enrollment List Item Updated',
    props<{
        enrollment: EnrollmentListItem
    }>()
)

export const MasterToggled = createAction(
    '[CourseExchnge Enrollment] - Enrollment List MasterToggled',
    props<{
        enrollments: EnrollmentListItemForView[]
    }>()
)

export const EnrollmentListItemAdded = createAction(
    '[CourseExchange Enrollment Realtime] - Enrollment List Item Added',
    props<{
        enrollment: EnrollmentListItem
    }>()
)

export const EditStatus = createAction(
    '[Course Exchange Enrollment List] - Edit Status',
    props<{
        enrollmentId: string,
        requestedStatus: BaseListItem
    }>()
)


export const SubmitStatusChanges = createAction(
    '[Course Exchange Enrollment List] - Submit Status Changes'
)

export const SubmitStatusChangesSuccess = createAction(
    '[Course Exchange Enrollment List] - Submit Status Changes Success'
)

export const SubmitStatusChangesError = createAction(
    '[Course Exchange Enrollment Effects] - Submit Status Changes Error',
    props<{
        message: string
    }>()
)

export const ChangeStatuses = createAction(
    '[Provider Enrollment Effects] - Change Statuses',
    props<{
        command: ChangeEnrollmentStatusBulkCommand,
        enrollmentStatuses: EnrollmentStatus[]
    }>()
)

export const ExportToExcel = createAction(
    '[Course Exchange Enrollment list] - Export to Excel',
    props<{
      enrollments?: EnrollmentListItem[]
    }>()
  )
