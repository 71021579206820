import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from '@core/services/config.service';
import { UserNotification } from '@shared/common/models';
import { Observable } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class NotificationService {
  private apiUrl:string='';
  constructor(private http: HttpClient, private config:ConfigService) { 
    this.config.apiUrl$.subscribe(result=>{
        this.apiUrl=`${result}/notification`;
    });
  }

  public getUserNotifications(): Observable<UserNotification[]>{
    return this.http.get<UserNotification[]>(`${this.apiUrl}/getUserNotifications`);
  }

  public markAsRead(notification:UserNotification): Observable<any>{
      return this.http.put<any>(`${this.apiUrl}/markAsViewed`,{id: notification.id});
  }

  public markAllAsRead(): Observable<any>{
      return this.http.put<any>(`${this.apiUrl}/markAllAsViewed`,{userId: ''});
  }
}
