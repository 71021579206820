import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { hasErrorForm } from 'app/helpers/has-error-form';
import { Store } from '@ngrx/store';
import { IAppState } from '../../../../store/state/app.state';
import { IEntityActionsPayload } from '../../models/shared';
import { IControlsConfig } from '../../../../models/shared';
import { Session, SessionDateRules } from '../../models';
import { map } from 'rxjs/operators';
import { fillControlsConfig } from '../../../../helpers/fill-controls-config';
import { EditSessionCommand, AddSessionCommand } from '../../commands';
import { validateStartEndDates } from '../../../../helpers/validate-start-end-dates';
import { DateRule } from '../../models/dateRule.model';
import * as moment from 'moment';
import { BeforeAfter } from '../../models/beforeAfter.enum';
import * as fromInstitution from '@institution/store';
import {v4 as uuidv4} from 'uuid';

type IFormFields = Pick<Session,
  'id' |
  'code' |
  'registrationStartDate' |
  'registrationDeadlineDate' |
  'startDate' |
  'endDate' |
  'dropDeadlineDate' |
  'feeEarnedDate' |
  'withdrawalDeadlineDate'>;

type ILocalControlsConfig = IControlsConfig<IFormFields>;

@Component({
  selector: 'app-session-form',
  templateUrl: './session-form.component.html',
  styleUrls: ['./session-form.component.scss', '../styles/calendar-entity-creator-forms.scss']
})
export class SessionFormComponent implements OnInit {

  @Input() payload: IEntityActionsPayload.SessionPayload;
  @Input() sessionDateRules: SessionDateRules;
  @Output() onClose = new EventEmitter();
  semesterStartDate: Date;
  semesterEndDate: Date;
  form: UntypedFormGroup;
  isSending = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store<IAppState>
  ) {
  }

  ngOnInit(): void {
    this.createForm();
    this.semesterStartDate = this.payload.semesterStartDate;
    this.semesterEndDate = this.payload.semesterEndDate;
  }

  private createForm(): void {
    this.form = this.formBuilder.group(this.shapeControlsConfig(this.payload.sessionToEdit), {
      validators: this.checkDates
    });
  
  }

  public startDateChanged(startDate: Date): void {
    
    var registrationDeadline = this.calculateDate(startDate, this.sessionDateRules.registrationDeadline);
    var dropDeadline = this.calculateDate(startDate, this.sessionDateRules.dropDeadline);
    var feeEarned = this.calculateDate(startDate, this.sessionDateRules.feeEarned);

    this.form.patchValue({
      registrationDeadlineDate: registrationDeadline,
      dropDeadlineDate: dropDeadline,
      feeEarnedDate: feeEarned,
    });
  }

  private calculateDate(dateToCompare: Date, dateRule: DateRule): Date {
    var daysToChange = dateRule.beforeAfter === BeforeAfter.Before ? -1 * dateRule.numberOfDays : dateRule.numberOfDays;

    return moment(dateToCompare).add("days", daysToChange).toDate();
  }

  private checkDates = (group: UntypedFormGroup): void => {
    const { endDate, startDate, registrationStartDate, registrationDeadlineDate } = group.controls;

    const checkResultForDate = validateStartEndDates(startDate.value, endDate.value);
    const checkResultForRegistrationDate = validateStartEndDates(registrationStartDate.value, registrationDeadlineDate.value);

    if (this.form) {
      const {semesterStartDate, semesterEndDate} = this.payload;
      const errorsForEndDate = checkResultForDate ? null : { lessStartDate: true };
      const endDateNotWithin = !!moment(endDate.value).isSameOrAfter(semesterStartDate) &&
                               !!moment(endDate.value).isSameOrBefore(semesterEndDate) ? null : {endDateNotWithin: true};
      const startDateNotWithin = !!moment(startDate.value).isSameOrAfter(semesterStartDate) &&
        !!moment(startDate.value).isSameOrBefore(semesterEndDate) ? null : {startDateNotWithin: true};

      this.form.get('endDate').setErrors(errorsForEndDate);
      this.form.get('endDate').setErrors(endDateNotWithin);
      this.form.get('startDate').setErrors(startDateNotWithin);
      const errorsForRegistrationDeadline = checkResultForRegistrationDate ? null : { lessStartRegistrationDate: true };

      this.form.get('registrationDeadlineDate').setErrors(errorsForRegistrationDeadline);
    }
  }

  private shapeControlsConfig(dataForFill?: Session): ILocalControlsConfig {
    const initialControlsConfig: ILocalControlsConfig = {
      id: [''],
      code: ['', Validators.required],
      startDate: [null, Validators.required],
      endDate: [null, Validators.required],
      registrationStartDate: [null, Validators.required],
      registrationDeadlineDate: [null, Validators.required],
      dropDeadlineDate: [null, Validators.required],
      feeEarnedDate: [null, Validators.required],
      withdrawalDeadlineDate: [null, Validators.required]
    };

    if (dataForFill) {
      return fillControlsConfig<IFormFields>(initialControlsConfig, dataForFill);
    } else {
      return initialControlsConfig;
    }

  }

  hasError = (controlName: string, errorName: string) => hasErrorForm(controlName, errorName, this.form);

  onEditSession = (): void => {
    if (this.form.valid) {
      this.isSending = true;
      const {semesterId} = this.payload;
      const {id, code, startDate, endDate, registrationStartDate, registrationDeadlineDate, dropDeadlineDate, feeEarnedDate, withdrawalDeadlineDate} = this.form.value;
      const command: EditSessionCommand = {
        id, 
        semesterId,
        code,
        startDate,
        endDate,
        registrationStartDate,
        registrationDeadlineDate,
        dropDeadlineDate,
        feeEarnedDate,
        withdrawalDeadlineDate
      }
      
      this.store.dispatch(fromInstitution.Actions.UpdateSession({command}));

        this.isSending = false;
      this.onClose.emit();
    }
  }

  onAddSession = (): void => {
    if (this.form.valid) {
      this.isSending = true;

      const {academicYearId, semesterId} = this.payload;
      const {code, startDate, endDate, registrationStartDate, registrationDeadlineDate, dropDeadlineDate, feeEarnedDate, withdrawalDeadlineDate} = this.form.value;
      const id = uuidv4();
      const command: AddSessionCommand = {
        id, 
        semesterId,
        academicYearId,
        code,
        startDate,
        endDate,
        registrationStartDate,
        registrationDeadlineDate,
        dropDeadlineDate,
        feeEarnedDate,
        withdrawalDeadlineDate
      };
      this.store.dispatch(fromInstitution.Actions.AddSession({command}));
      this.isSending = false;
      this.onClose.emit();
    }
  }
}
