import {createAction, props} from '@ngrx/store';
import { CourseExchangeDashboardViewModel } from '@shared/courseExchange/models';

export const LoadDashboardData = createAction(
  '[Course Exchange Dashboard Effects] - Load Dashboard Data',
  props<{
    courseExchangeId: string;
    startDate: Date;
    endDate: Date;
  }>()
)
export const LoadDashboardDataSuccess = createAction(
  '[Course Exchange Dashboard Resolver] - Load Dashboard Data Success',
  props<{
    data: CourseExchangeDashboardViewModel;
  }>()
);

export const LoadDashboardDataError = createAction(
  '[Course Exchange Dashboard Resolver] - Load Dashboard Data Error',
  props<{
    message: string
  }>()
);