import { BaseResponse } from './../../../../core/models/baseResponse.model';
import {Injectable} from '@angular/core';
import { RealTimeService } from '@core/services/realTime.service';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import { CourseCalendarViewModel } from '@shared/provider/models';
import * as fromCourseSchedule from '@provider/course-schedule/store';
import * as fromCore from '@core/store';
import { Store } from '@ngrx/store';
import { IAppState } from 'app/store';
import { ProviderService } from '@shared/provider/services';
import { map, switchMap } from 'rxjs/operators';
import { of} from 'rxjs';
@Injectable()
export class CourseScheduleEffects {
  constructor(
    private actions$: Actions, private realTimeService:RealTimeService, private store: Store<IAppState>,
    private providerService: ProviderService) {
    this.initSignalRMessages();
  }

  private initSignalRMessages(){
    this.realTimeService.hubConnection$.subscribe(hubConnection=>{
      if(!hubConnection) return;
      hubConnection.on('CourseCalendarCopied', (courseCalendar: CourseCalendarViewModel) => {
        this.store.dispatch(fromCourseSchedule.Actions.fetchCourseCalendarViewModelSuccess({payload:courseCalendar}));
      });
    });
  }

  copyCalendar$ = createEffect(() => {
    var providerId = '';
    var academicYearId = '';
    var semesterId = '';
    var providerCourseIds = [];
    return this.actions$.pipe(
        ofType(fromCourseSchedule.Actions.CopyCourseCalendar),
        /** An EMPTY observable only emits completion. Replace with your own observable stream */
        switchMap((action) => {
          providerId = action.command.providerId;
          academicYearId = action.command.toAcademicYearId;
          semesterId = action.command.toSemesterId;
          if(action.command.toAcademicYearId == action.command.fromAcademicYearId && action.command.toSemesterId == action.command.fromSemesterId){
            var response: BaseResponse = {isSuccess: true, message: '', validationIssues: []};
            return of(response);
          }else{
            return this.providerService.copyCourseCalendar(action.command)
          }
        }),
        map((response)=>{
          this.store.dispatch(fromCore.Actions.HandleResponse({response}));
          if(response.isSuccess){
            return fromCourseSchedule.Actions.GetCourseCalendar({
              providerId,
              academicYearId,
              semesterId,
              providerCourseIds
            })
          }else{
            return fromCore.Actions.EmptyResponse;
          }
        }));
  });

  getCalendar$ = createEffect(() => {
    return this.actions$.pipe(
        ofType(fromCourseSchedule.Actions.GetCourseCalendar),
        /** An EMPTY observable only emits completion. Replace with your own observable stream */
        switchMap((action) => this.providerService.getCourseCalendar(
          action.providerId,
          action.academicYearId,
          action.semesterId,
          action.providerCourseIds
        )),
        map((payload)=>fromCourseSchedule.Actions.fetchCourseCalendarViewModelSuccess({payload})));
  });
}
