import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { IAppState } from 'app/store/state/app.state';
import { Store } from '@ngrx/store';
import * as fromMemberShared from '@shared/member/store';
export interface DataMigrationDialogData {
  memberId: string;
  institutionId: string;
}

@Component({
  selector: 'data-migration-dialog',
  templateUrl: './data-migration-dialog.component.html',
  styleUrls: ['./data-migration-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {'class': 'data-migration-dialog'}
})
export class DataMigrationDialogComponent {
  memberId: string;
  constructor(
    public matDialogRef: MatDialogRef<DataMigrationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public matDialogData: DataMigrationDialogData,
    public store: Store<IAppState>
  ) {
    this.matDialogRef.addPanelClass('data-migration-dialog');
    this.memberId = matDialogData.memberId;
  }

  close(): void {
    this.matDialogRef.close();
  }

  studentMergeData(): void{
    const {memberId, institutionId} = this.matDialogData;
    this.store.dispatch(fromMemberShared.Actions.SelectStudentMergeDataFile({memberId,institutionId}));
  }
  getStudentMergeData(): void{
    const {memberId, institutionId} = this.matDialogData;
    this.store.dispatch(fromMemberShared.Actions.GetStudentMergeData({memberId,institutionId}));
  }
  getMemberCourseMergeData(): void{
    const {memberId, institutionId} = this.matDialogData;
    this.store.dispatch(fromMemberShared.Actions.GetMemberCourseMergeData({memberId,institutionId}));
  }
  memberEnrollmentMergeData(): void{
    const {memberId, institutionId} = this.matDialogData;
    this.store.dispatch(fromMemberShared.Actions.SelectEnrollmentMigrationDataFile({memberId,institutionId}));
  }
  getMemberEnrollmentMigrationData(): void{
    const {memberId, institutionId} = this.matDialogData;
    this.store.dispatch(fromMemberShared.Actions.GetEnrollmentMigrationData({memberId,institutionId}));
  }
  getGradeMigrationData(): void{
    const {memberId, institutionId} = this.matDialogData;
    this.store.dispatch(fromMemberShared.Actions.GetGradeData({memberId,institutionId}));
  }
}
