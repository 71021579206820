import {Component, OnInit, ViewEncapsulation, Input} from '@angular/core';
import {AccessRole} from 'app/authentication/models/accessRole';
import {IAppState} from 'app/store/state/app.state';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {Provider} from '@shared/provider/models';
import * as fromAuth from '@auth/store';
import * as fromEnrollment from '@provider/enrollment/store';
import * as fromProvider from '@provider/store';
import * as fromProviderDashboard from '@provider/dashboard/store';
import * as fromFacultyCV from '@provider/facultyCV/store';
import * as fromProviderBilling from '@provider/billing/store';
import * as fromProviderCourses from '@provider/courses/store';
import * as fromProviderShared from '@shared/provider/store';
import {PermissionType} from '@auth/models/permissionType.enum';
import { CourseExchangeListItem } from '@shared/courseExchange/models';

@Component({
  selector: 'provider-admin-toolbar',
  templateUrl: './provider-admin-toolbar.component.html',
  styleUrls: ['./provider-admin-toolbar.component.scss', '../styles/common-toolbar.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ProviderAdminToolbarComponent implements OnInit {
  @Input() currentAccessRole: AccessRole;

  showInstitutionalPolicies$: Observable<boolean>;
  showCalendars$: Observable<boolean>;
  showUsers$: Observable<boolean>;
  showBilling$: Observable<boolean>;
  showFees$: Observable<boolean>;
  showEnrollments$: Observable<boolean>;
  showCourses$: Observable<boolean>;
  showDataMigration$: Observable<boolean>;
  provider$: Observable<Provider>;
  currentCourseExchange$: Observable<CourseExchangeListItem>;
  courseExchanges$: Observable<CourseExchangeListItem[]>;
  showToolbarButtons$: Observable<boolean>;

  constructor(
    private store: Store<IAppState>,
  ) {

  }

  ngOnInit(): void {
    this.provider$ = this.store.pipe(select(fromProvider.Selectors.Provider));
    this.showCalendars$ = this.store.pipe(select(fromProvider.Selectors.HasInstitutionPermission(PermissionType.ReadCalendar)));
    this.showInstitutionalPolicies$ = this.store.pipe(select(fromProvider.Selectors.HasInstitutionPermission(PermissionType.ReadInstitutionalPolicy)));
    this.showFees$ = this.store.pipe(select(fromProvider.Selectors.HasInstitutionPermission(PermissionType.ReadFee)));
    this.showUsers$ = this.store.pipe(select(fromProvider.Selectors.HasInstitutionPermission(PermissionType.ReadUser)));
    this.showBilling$ = this.store.pipe(select(fromProvider.Selectors.HasProviderPermission(PermissionType.ViewProviderBilling)));
    this.showEnrollments$ = this.store.pipe(select(fromProvider.Selectors.HasProviderPermission(PermissionType.CanReadProviderEnrollmentRequests)));
    this.showDataMigration$ = this.store.pipe(select(fromProvider.Selectors.HasProviderPermission(PermissionType.CanReadProviderEnrollmentRequests)));
    this.currentCourseExchange$ = this.store.pipe(select(fromAuth.Selectors.CurrentCourseExchange));
    this.courseExchanges$ = this.store.pipe(select(fromAuth.Selectors.CourseExchanges));
    this.showToolbarButtons$ = this.store.pipe(select(fromProvider.Selectors.ShowToolbarButtons));
    this.showCourses$ = this.store.pipe(select(fromProvider.Selectors.HasInstitutionPermission(PermissionType.ReadCourse)));
  }

  logout(): boolean {
    this.store.dispatch(fromAuth.Actions.logout());
    return false;
  }

  goToUserList(): boolean {
    this.store.dispatch(fromProvider.Navigate.Users({}));
    return false;
  }

  goToFees(): boolean {
    this.store.dispatch(fromProvider.Navigate.Fees({}));
    return false;
  }

  goToCalendar(): boolean {
    this.store.dispatch(fromProvider.Navigate.Calendar({}));
    return false;
  }

  goToPolicies(): boolean {
    this.store.dispatch(fromProvider.Navigate.Policies({}));
    return false;
  }

  goToDashboard(): boolean {
    this.store.dispatch(fromProviderDashboard.Navigate.Dashboard({}));
    return false;
  }

  goToFacultyCVs(): boolean{
    this.store.dispatch(fromFacultyCV.Navigate.FacultyCVList({}));
    return false;
  }

  goToEnrollments(): boolean {
    this.store.dispatch(fromEnrollment.Navigate.ProviderEnrollments({}));
    return false;
  }

  goToBillingSummary(): void{
    this.store.dispatch(fromProviderBilling.Navigate.ProviderBillingSummary({}));
  }

  goToCourseList(): void {
    this.store.dispatch(fromProviderCourses.Navigate.CourseList({}));
  }
  changeCourseExchange(courseExchangeId: string): void{
    if(this.currentAccessRole.courseExchangeId === courseExchangeId) return;
    this.store.dispatch(fromAuth.Actions.selectCourseExchangeId({courseExchangeId}));
  }

  openDataMigration(): void{
    const providerId = this.currentAccessRole.entityId;
    const institutionId = this.currentAccessRole.institutionId;
    this.store.dispatch(fromProviderShared.Actions.ShowMigrateData({providerId,institutionId}))
  }
}
