import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable, BehaviorSubject} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {AcademicYear, Semester, Session} from '../models';
import {
  EditSemesterCommand,
  AddAcademicYearCommand,
  EditAcademicYearCommand,
  AddSessionCommand,
  EditSessionCommand,
  AddSemesterCommand} from '../commands';
import {BaseResponse} from 'app/core/models';
import * as moment from 'moment';
import { ConfigService } from '@core/services/config.service';
import { SingleValueResult } from '@core/models/singleValueResult';

@Injectable()
export class CalendarService {
  private currentSystemDate: BehaviorSubject<Date> = new BehaviorSubject<Date>(moment().toDate());
  private apiUrl: string='';
  constructor(private http: HttpClient,private config:ConfigService) {
    this.config.apiUrl$.subscribe(result=>this.apiUrl=`${result}/calendar`);
  }

  public getAcademicYears(parentId: string): Observable<AcademicYear[]> {
    // return of(mockCalendar).pipe(delay(1000));

    return this.http.get<AcademicYear[]>(`${this.apiUrl}/${parentId}/academicYear`);
  }

  public addAcademicYear(institutionId: string, command: AddAcademicYearCommand): Observable<BaseResponse> {
    return this.http.post<BaseResponse>(`${this.apiUrl}/${institutionId}/academicYear`, command)
  }

  public editAcademicYear(institutionId: string, command: EditAcademicYearCommand): Observable<BaseResponse> {

    return this.http.put<BaseResponse>(`${this.apiUrl}/${institutionId}/academicYear`, command);
  }

  public deleteAcademicYear(parentId: string, academicYearId: string): Observable<BaseResponse>{
    return this.http.delete<BaseResponse>(`${this.apiUrl}/${parentId}/academicYear/${academicYearId}`)
  }

  public addSemester(institutionId: string, command: AddSemesterCommand): Observable<BaseResponse> {
    return this.http.post<BaseResponse>(`${this.apiUrl}/${institutionId}/semester`, command);
  }

  public editSemester(institutionId: string, command: EditSemesterCommand): Observable<BaseResponse> {
    return this.http.put<BaseResponse>(`${this.apiUrl}/${institutionId}/semester`, command);
  }

  public deleteSemester(parentId: string, semesterId: string): Observable<BaseResponse>{
    return this.http.delete<BaseResponse>(`${this.apiUrl}/${parentId}/semester/${semesterId}`)
  }

  public addSession(parentId: string, command: AddSessionCommand): Observable<BaseResponse> {
    return this.http.post<BaseResponse>(`${this.apiUrl}/${parentId}/session`, command);
  }

  public editSession(parentId: string, command: EditSessionCommand): Observable<BaseResponse> {
    return this.http.put<BaseResponse>(`${this.apiUrl}/${parentId}/session`, command);
  }

  public deleteSession(parentId: string, sessionId: string): Observable<BaseResponse>{
    return this.http.delete<BaseResponse>(`${this.apiUrl}/${parentId}/session/${sessionId}`)
  }

  public setDate(dateTime: Date): void{
    this.http.post<SingleValueResult<Date>>(`${this.apiUrl}/clock/setDate`,{newDate: dateTime}).subscribe();
  }

  public getCurrentSystemDate(): Observable<Date>{
    return this.http.get<SingleValueResult<Date>>(`${this.apiUrl}/clock/currentDate`)
      .pipe(
        map((response)=>response.result)
      );
  }


}
