import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { Store, select } from "@ngrx/store";
import { first, finalize, filter, map, withLatestFrom, concatMap } from "rxjs/operators";
import { IAppState } from 'app/store/state/app.state';
import * as fromEnrollment from '@provider/enrollment/store';
import * as fromInstitution from '@institution/store';
import { EnrollmentListItem } from '@member/enrollment/models/enrollmentListItem.viewModel';
@Injectable()
export class EnrollmentsForProviderResolver implements Resolve<EnrollmentListItem[]>{
    isLoading = false;
    constructor(private store: Store<IAppState>){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
        const providerId = route.params["providerId"];
        let academicYearId: string;
        return this.store 
            .pipe(
                select(fromInstitution.Selectors.CurrentAcademicYear),
                concatMap((action) => of(action).pipe(
                    withLatestFrom(this.store.pipe(select(fromEnrollment.Selectors.SelectorForEnrollments))
                ))),
                map(([currentAcademicYear, {currentProviderId, currentAcademicYearId, enrollmentCount}])=>{
                    var result = !!currentAcademicYear && 
                                  currentProviderId === providerId &&
                                  currentAcademicYear.id===currentAcademicYearId &&
                                  enrollmentCount > 0;
                    academicYearId = !!currentAcademicYear ? currentAcademicYear.id : "empty";
                    return result;
                }),
                map((enrollmentsLoaded)=>{
                    if(!this.isLoading && !enrollmentsLoaded){
                        this.isLoading = true;
                        this.store.dispatch(fromEnrollment.Actions.LoadEnrollmentsByAcademicYear({academicYearId,providerId}));
                    }
                    return true;
                }),
                filter(enrollmentsLoaded => enrollmentsLoaded),
                first(),
                finalize(()=>this.isLoading=false)
            )
    }
}