import {Injectable} from '@angular/core';
import {Actions, ofType, createEffect} from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { IAppState } from 'app/store/state/app.state';
import { concatMap, withLatestFrom, switchMap, map, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as fromCourseExchangeCourses from '@courseExchange/courses/store';
import * as fromCourseExchange from '@courseExchange/store';
import { Router } from '@angular/router';

@Injectable()
export class CoursesNavigateEffects {
  constructor(private actions$: Actions, private store: Store<IAppState>, private router: Router) 
  {
  }

  goToCourseList$ = createEffect(() => {
      return this.actions$.pipe(
              ofType(fromCourseExchangeCourses.Navigate.CourseList),
              concatMap((action) => of(action).pipe(
                withLatestFrom(this.store.pipe(select(fromCourseExchange.Selectors.CourseExchange)))
              )),
              /** An EMPTY observable only emits completion. Replace with your own observable stream */
              tap(([action,currentCourseExchange]) => {
                const courseExchangeId = !!action.courseExchangeId ? action.courseExchangeId : currentCourseExchange.id;
                const managingInstitutionId = !!action.managingInstitutionId ? action.managingInstitutionId : currentCourseExchange.managingInstitutionId;
                this.router.navigate([`/course-exchange/${courseExchangeId}/${managingInstitutionId}/courses`]);
              })
        );
  },{dispatch:false});

  goToCourseDetail$ = createEffect(() => {
    return this.actions$.pipe(
            ofType(fromCourseExchangeCourses.Navigate.CourseDetail),
            concatMap((action) => of(action).pipe(
              withLatestFrom(this.store.pipe(select(fromCourseExchange.Selectors.CourseExchange)))
            )),
            /** An EMPTY observable only emits completion. Replace with your own observable stream */
            tap(([action,currentCourseExchange]) => {
              const courseId = action.providerCourse.courseId;
              const courseExchangeId = !!action.courseExchangeId ? action.courseExchangeId : currentCourseExchange.id;
              const managingInstitutionId = !!action.managingInstitutionId ? action.managingInstitutionId : currentCourseExchange.managingInstitutionId;
              this.router.navigate([`/course-exchange/${courseExchangeId}/${managingInstitutionId}/courses/detail/${courseId}`]);
            })
      );
},{dispatch:false});
}
