import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Student, StudentListItem } from '../models';
import { AddStudentCommand, EditStudentCommand, DeleteStudentCommand, ActivateStudentCommand, DeactivateStudentCommand } from '../commands';
import { map } from 'rxjs/operators';
import { StudentDetailsViewModel } from '../models/studentDetails.viewModel';
import { ConfigService } from '@core/services/config.service';
import { BaseResponse } from '@core/models';
@Injectable()
export class StudentService {
  private apiUrl:string='';
  constructor(private http: HttpClient, private config:ConfigService) { 
    this.config.apiUrl$.subscribe(result=>this.apiUrl=`${result}/student`);
  }

  public getStudentList(institutionId: string): Observable<StudentListItem[]>{
    return this.http.get<StudentListItem[]>(`${this.apiUrl}/getStudentsByInstitution/${institutionId}`);
  }

  public getAllStudents(): Observable<StudentListItem[]>
  {
    return this.http.get<StudentListItem[]>(`${this.apiUrl}/getAllStudents`);
  }

  public getStudentById(institutionId: string, studentId: string): Observable<Student>{
    return this.http.get<Student>(`${this.apiUrl}/${institutionId}/${studentId}`);
  }

  public getStudentDetailsById(institutionId: string, studentId: string): Observable<StudentDetailsViewModel>{
    return this.http.get<StudentDetailsViewModel>(`${this.apiUrl}/${institutionId}/${studentId}/details`);
  }

  public addStudent(institutionId: string, command: AddStudentCommand): Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.apiUrl}/${institutionId}`, command);
  }

  public editStudent(institutionId: string, command: EditStudentCommand): Observable<BaseResponse>{
    return this.http.put<BaseResponse>(`${this.apiUrl}/${institutionId}`, command);
  }

  public deleteStudent(institutionId: string,command: DeleteStudentCommand): Observable<BaseResponse>{
    return this.http.put<BaseResponse>(`${this.apiUrl}/${institutionId}/delete`,command)
  }

  public activateStudent(institutionId: string, command: ActivateStudentCommand): Observable<BaseResponse>
  {
    return this.http.put<BaseResponse>(`${this.apiUrl}/${institutionId}/activate`,command);
  }

  public deactivateStudent(institutionId: string, command: DeactivateStudentCommand): Observable<BaseResponse>
  {
    return this.http.put<BaseResponse>(`${this.apiUrl}/${institutionId}/deactivate`,command);
  }

  public emailExists(userId: string, emailAddress: string): Observable<boolean>{
    return this.http.get<boolean>(`${this.apiUrl}/${userId}/emailExists/${emailAddress}`);
  }

  public importStudents(institutionId: string, commands: AddStudentCommand[]):Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.apiUrl}/${institutionId}/importStudents`,commands);
  }

  public studentNumberExists(institutionId: string, studentNumber: string): Observable<boolean>{
    return this.http.get<boolean>(`${this.apiUrl}/${institutionId}/studentNumberExists/${studentNumber}`);
  }
}
