import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromMember from '@member/store';
import { MemberCourseStoreModule } from '@member/memberCourse/store/memberCourseStore.module';
import { StudentStoreModule } from '@member/students/store/students.store.module';
import { EnrollmentStoreModule } from '@member/enrollment/store/enrollmentStore.module';
import { MemberBillingStoreModule } from '@member/billing/store/memberBillingStore.module';
import {MemberDashboardStoreModule} from '@member/dashboard/store/memberDashboardStore.module';
import { InstitutionStoreModule } from '@institution/store/institutionStore.module';
@NgModule({
  declarations: [],
  imports: [
    EnrollmentStoreModule,
    MemberBillingStoreModule,
    MemberCourseStoreModule,
    InstitutionStoreModule,
    MemberDashboardStoreModule,
    StudentStoreModule,
    StoreModule.forFeature('member', fromMember.memberReducer),
    EffectsModule.forFeature([...fromMember.EFFECTS])
  ],
  providers: [...fromMember.RESOLVERS]
})
export class MemberStoreModule { }
