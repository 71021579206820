import {Action, createReducer, on} from '@ngrx/store';
import {WizardActions} from '../actions';
import { ChecklistItemType } from '@core/models/checklistItemType.enum';
import { IWizardChecklistForView } from '@shared/wizard/models';
export interface IWizardState {
  currentStepId: string;
  checklist: IWizardChecklistForView,
  checklistOrder: string[];
}

export const initialWizardState: IWizardState = {
  currentStepId: ChecklistItemType.Root,
  checklist: undefined,
  checklistOrder: []
};
const _wizardReducer = createReducer(
  initialWizardState,
  on(WizardActions.changeCurrentStepId, (state, action) => {
    const checklistOrder = state.checklistOrder;
    const currentIdx = state.checklistOrder.findIndex(x=>x===state.currentStepId);
    let currentStepId= '';
    const basePart = state.currentStepId.split("_")[0]; 
    switch(action.stepId){
      case "Forward":
        if(basePart == ChecklistItemType.EntityProfile){
          currentStepId = checklistOrder.slice(currentIdx+1).find(x=>x.split("_")[0] != basePart);
        }else{
          currentStepId = checklistOrder[currentIdx+1] ? checklistOrder[currentIdx+1] : state.currentStepId;
        }
        break;
      case "Back":
        currentStepId = checklistOrder[currentIdx-1] ? checklistOrder[currentIdx-1] : ChecklistItemType.Root;
        break;
      case "Skip":
        currentStepId = checklistOrder.slice(currentIdx+1).find(x=>x.split("_")[0] != basePart);
        if(!currentStepId) currentStepId = state.currentStepId;
        break;
      default:
        currentStepId = action.stepId
    }
    return {
      ...state,
      currentStepId
    };
  }),
  on(WizardActions.loadChecklist, (state, action)=>{
    const checklist = action.checklist;
    return {
      ...state,
      checklist
    }
  }),
  on(WizardActions.loadChecklistOrder, (state, action) =>{
    const checklistOrder = action.checklistOrder;
    return {
      ...state,
      checklistOrder
    }
  })
)

export function wizardReducer(state: IWizardState, action: Action): IWizardState {
  return _wizardReducer(state, action);
}
