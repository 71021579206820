import {AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation, Output, EventEmitter} from '@angular/core';
import {IAppState} from '../../../../store/state/app.state';
import {select, Store} from '@ngrx/store';
import {fromEvent, Observable, combineLatest} from 'rxjs';
import {StudentListItem} from '@shared/student/models';
import {NewEnrollmentListOfSelectedTableScheme} from '@member/enrollment/components';
import {Filter, SemesterFilter, SingleFilter} from '@shared/common/models';
import {MemberCourseListItem} from '@member/memberCourse/models';
import {Order, Sort} from '@core/models';
import DefaultOrderList from '@shared/common/helpers/DefaultOrderList';
import {MatSelectionListChange} from '@angular/material/list';
import {debounceTime, distinctUntilChanged, map, tap} from 'rxjs/operators';

import * as fromEnrollment from '@member/enrollment/store';
import * as fromMemberCourse from '@member/memberCourse/store';
import * as fromStudent from '@member/students/store';
import * as fromInstitution from '@institution/store';
import * as fromAuth from '@auth/store';
import { MatRadioChange } from '@angular/material/radio';
import { AcademicYear } from '@shared/calendar/models';
@Component({
  selector: 'new-enrollment-from-students',
  templateUrl: './new-enrollment-from-students.component.html',
  styleUrls: ['./new-enrollment-from-students.component.scss', '../styles/new-enrollment-from-common.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NewEnrollmentFromStudentsComponent implements OnInit, AfterViewInit {
  students$: Observable<StudentListItem[]>;
  tableScheme: NewEnrollmentListOfSelectedTableScheme[] = [
    {
      title: 'Student ID',
      accessKey: 'studentNumber'
    },
    {
      title: 'Last Name',
      accessKey: 'lastName'
    },
    {
      title: 'First Name',
      accessKey: 'firstName'
    }
  ];
  academicYears$: Observable<AcademicYear[]>;
  currentAcademicYear$: Observable<AcademicYear>;
  currentAcademicYearId$: Observable<string>;
  semesterFilters$: Observable<SemesterFilter[]>;
  levelFilters$: Observable<Filter[]>;
  levelFilterCount$: Observable<number>;
  providerFilters$: Observable<Filter[]>;
  providerFilterCount$: Observable<number>;
  categoryFilters$: Observable<Filter[]>;
  categoryFilterCount$: Observable<number>;
  subCategoryFilters$: Observable<Filter[]>;
  subCategoryFilterCount$: Observable<number>;
  tagFilters$: Observable<Filter[]>;
  tagFilterCount$: Observable<number>;
  selectedFilters$: Observable<Filter[]>;
  memberCourses$: Observable<MemberCourseListItem[]>;
  presetFilterType$: Observable<SingleFilter>;
  selectedCourses$: Observable<MemberCourseListItem[]>;
  currentSort$: Observable<Sort>;
  currentOrder$: Observable<Order>;
  orders: Order[] = DefaultOrderList();
  sorts: Sort[] = fromMemberCourse.Sorts;
  isAllSelected$: Observable<boolean>
  isAllSelected: boolean;
  indeterminateSelected$: Observable<boolean>;
  selectedStatus$: Observable<string>;
  sorts$: Observable<Sort[]>;
  canCreateEnrollment$: Observable<boolean>;
  startDate$: Observable<Date>;
  endDate$:Observable<Date>;
  presetFilterTypes: SingleFilter[] = fromMemberCourse.MemberCourseTypes;

  @ViewChild('filter') filter: ElementRef;
  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private store: Store<IAppState>,
  ) {
  }

  ngOnInit(): void {
    this.students$ = this.store.pipe(select(fromStudent.Selectors.SelectedStudents));

    this.academicYears$ = this.store.pipe(select(fromInstitution.Selectors.AcademicYears));
    this.currentAcademicYear$ = this.store.pipe(select(fromInstitution.Selectors.CurrentAcademicYear));
    this.currentAcademicYearId$ = this.store.pipe(select(fromInstitution.Selectors.CurrentAcademicYearId));
    this.semesterFilters$ = this.store.pipe(select(fromMemberCourse.Selectors.SemesterFilters));
    this.levelFilters$ = this.store.pipe(select(fromMemberCourse.Selectors.CourseLevelFiltersForView));
    this.levelFilterCount$ = this.store.pipe(select(fromMemberCourse.Selectors.CourseLevelFilterCount));
    this.providerFilters$ = this.store.pipe(select(fromMemberCourse.Selectors.ProviderFilters));
    this.providerFilterCount$ = this.store.pipe(select(fromMemberCourse.Selectors.ProviderFilterCount));
    this.categoryFilters$ = this.store.pipe(select(fromMemberCourse.Selectors.CategoryFilters));
    this.categoryFilterCount$ = this.store.pipe(select(fromMemberCourse.Selectors.CategoryFilterCount));
    this.subCategoryFilters$ = this.store.pipe(select(fromMemberCourse.Selectors.SubCategoryFilters));
    this.subCategoryFilterCount$ = this.store.pipe(select(fromMemberCourse.Selectors.SubCategoryFilterCount));
    this.tagFilters$ = this.store.pipe(select(fromMemberCourse.Selectors.TagFilters));
    this.tagFilterCount$ = this.store.pipe(select(fromMemberCourse.Selectors.TagFilterCount));
    this.selectedFilters$ = this.store.pipe(select(fromMemberCourse.Selectors.SelectedFilters));
    this.memberCourses$ = this.store.pipe(select(fromMemberCourse.Selectors.FilteredMemberCourses));
    this.selectedCourses$ = this.store.pipe(select(fromMemberCourse.Selectors.SelectedMemberCourses));
    this.presetFilterType$ = this.store.pipe(select(fromMemberCourse.Selectors.PresetFilterType));
    this.currentOrder$ = this.store.pipe(select(fromMemberCourse.Selectors.CurrentOrder));
    this.currentSort$ = this.store.pipe(select(fromMemberCourse.Selectors.CurrentSort));
    this.isAllSelected$ = this.store.pipe(select(fromMemberCourse.Selectors.IsAllSelected));
    this.isAllSelected$.subscribe(result => this.isAllSelected = result);
    this.indeterminateSelected$ = this.store.pipe(select(fromMemberCourse.Selectors.IndeterminateSelected));
    this.selectedStatus$ = this.store.pipe(select(fromMemberCourse.Selectors.SelectedStatus));
    this.sorts$ = this.store.pipe(select(fromMemberCourse.Selectors.Sorts));
    this.canCreateEnrollment$ = this.store.pipe(select(fromEnrollment.Selectors.CanCreateEnrollment));
    this.startDate$ = this.store.pipe(select(fromAuth.Selectors.StartDate));
    this.endDate$ = this.store.pipe(select(fromAuth.Selectors.EndDate));
    this.store.dispatch(fromMemberCourse.Actions.LoadSorts({sorts: this.sorts}));
    this.store.dispatch(fromMemberCourse.Actions.ChangeOrder({currentOrder: this.orders.find(x=>x.isDefault)}));
    this.store.dispatch(fromMemberCourse.Actions.ChangeSort({currentSort: this.sorts.find(x=>x.isDefault)}));
    this.store.dispatch(fromMemberCourse.Actions.ChangeSearchText({searchText:''}));
  }

  ngAfterViewInit(): void {
    this.setupTextSearch();
  }

  setupTextSearch(): void{
    fromEvent(this.filter.nativeElement, 'keyup').pipe(
      map((event: any) => event.target.value),
      debounceTime(300),
      distinctUntilChanged(),
      tap((searchText: string) =>
        this.store.dispatch(fromMemberCourse.Actions.ChangeSearchText({searchText})))
    ).subscribe()
  }

  onRemove(student: StudentListItem): void {
    const studentId = student.id;
    this.store.dispatch(fromStudent.Actions.ToggleStudent({studentId}));
  }

  trackById(item): string {
    return item.id;
  }
  onToggleSemesterFilter(event: MatSelectionListChange): void {
    const id = event.options[0].value
    this.store.dispatch(fromMemberCourse.Actions.ToggleSemesterFilter({id}));
  }

  onToggleCourseLevelFilter(event: MatSelectionListChange): void {
    const id = event.options[0].value
    this.store.dispatch(fromMemberCourse.Actions.ToggleCourseLevelFilter({id}));
  }

  onToggleProviderFilter(event: MatSelectionListChange): void {
    const id = event.options[0].value
    this.store.dispatch(fromMemberCourse.Actions.ToggleProviderFilter({id}));
  }

  onToggleCategoryFilter(event: MatSelectionListChange): void {
    const id = event.options[0].value
    this.store.dispatch(fromMemberCourse.Actions.ToggleCategoryFilter({id}));
  }

  onToggleSubCategoryFilter(event: MatSelectionListChange): void {
    const id = event.options[0].value
    this.store.dispatch(fromMemberCourse.Actions.ToggleSubCategoryFilter({id}));
  }

  onToggleTagFilter(event: MatSelectionListChange): void {
    const id = event.options[0].value
    this.store.dispatch(fromMemberCourse.Actions.ToggleTagFilter({id}));
  }

  changeOrderBy(currentOrder: Order): void {
    this.store.dispatch(fromMemberCourse.Actions.ChangeOrder({currentOrder}));
  }

  changeSort(currentSort: Sort): void {
    this.store.dispatch(fromMemberCourse.Actions.ChangeSort({currentSort}));
  }

  onToggleMemberCourse(id:string): void{
    this.store.dispatch(fromMemberCourse.Actions.ToggleMemberCourse({id}));
  }

  goToMemberCourseDetail(memberCourse: MemberCourseListItem): void{
    this.store.dispatch(fromMemberCourse.Navigate.MemberCourseDetail({currentMemberCourseListItem: memberCourse}));
  }

  exportSelectedToExcel(): void{

  }

  createEnrollments(): void{
    this.store.dispatch(fromEnrollment.Actions.SubmitEnrollments());
    this.close.emit();
  }

  goToStudentList(): void{
    this.store.dispatch(fromStudent.Navigate.StudentList({}));
  }

  onChangePresetFilter(change: MatRadioChange){
    const presetFilterType = change.value;
    this.store.dispatch(fromMemberCourse.Actions.ChangePresetFilterType({presetFilterType}));
  }

  changeStartDate(e: any){
    if(e.target.value===null)return;
    const startDate =e.target.value.toDate();
    this.store.dispatch(fromAuth.Actions.ChangeStartDate({startDate}));
    this.store.dispatch(fromMemberCourse.Actions.LoadMemberCourseListItems({}));
  }

  changeEndDate(e: any){
    if(e.target.value===null) return;
    const endDate =e.target.value.toDate();
    this.store.dispatch(fromAuth.Actions.ChangeEndDate({endDate}));
    this.store.dispatch(fromMemberCourse.Actions.LoadMemberCourseListItems({}));
  }
}
