import { FileType } from './fileType.enum';
import { FileStatus } from './fileStatus.enum';

export class ElbertFile
{
    fileType: FileType;
    status: FileStatus;
    fileName: string;
}

export const getDefaultElbertFile = () =>{
    var result: ElbertFile = {
        fileType: FileType.Syllabus,
        status: FileStatus.Empty,
        fileName: ''
    };
    return result;
}