<mat-toolbar>
  <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <span class="title dialog-title">Copy or New</span>
    <button mat-icon-button (click)="close()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div class="content">
  <ng-container>
    <ng-container *ngIf="isShowStart">
      <app-course-schedule-dialog-start
        (onChangeCreateAction)="changeCreateAction($event)"
      ></app-course-schedule-dialog-start>
    </ng-container>

    <ng-container *ngIf="!isShowStart && typeCreateAction === 'new'">
      <app-course-schedule-dialog-new 
      [academicYears]="academicYears$ | async"
      [selectedAcademicYear]="selectedAcademicYear$ | async"
      [canSubmit]="canSubmitNew$ | async"
      (onClose)="close()"></app-course-schedule-dialog-new>
    </ng-container>

    <ng-container *ngIf="!isShowStart && typeCreateAction === 'copy'">
      <app-course-schedule-dialog-copy 
        [academicYears]="academicYears$ | async"
        [selectedToAcademicYear]="selectedToAcademicYear$ | async"
        [selectedFromAcademicYear]="selectedFromAcademicYear$ | async"
        [canTransformToSecondPart]="canTransformToSecondPart$ | async"
        [canSubmit]="canSubmitCopy$ | async"
        (onClose)="close()"></app-course-schedule-dialog-copy>
    </ng-container>
  </ng-container>
</div>
