<elb-page-list-container [kind]="kind" class="page-list-user-list">
  <!-- <elb-page-header [pageTitle]="'Users'"
                   [pageSubTitle]="''"
                   (returnToDashboardClicked)="goToDashboardClicked.emit()">
    <button class="btn--icon btn--institution-admin submit-button" mat-button (click)="addClicked.emit()">
      <mat-icon>add</mat-icon>Add New User
    </button>
  </elb-page-header> -->

  <elb-page-list-content>

    <elb-page-list-sidebar>
      <mat-accordion>
        <mat-expansion-panel *ngIf="(institutionFilters$ | async)?.length>0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 [matBadge]="institutionFiltersCount$ | async" matBadgeOverlap="false">Institution</h3>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="side-panel-list">
            <mat-selection-list (selectionChange)="onToggleInstitutionFilter($event)">
              <mat-list-option
                [value]="institution.id" [selected]="institution.selected"
                *ngFor="let institution of institutionFilters$ | async; trackBy: trackBy">
                {{ institution.descriptionForView }}
              </mat-list-option>
            </mat-selection-list>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="(courseExchangeFilters$ | async)?.length>0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 [matBadge]="courseExchangeFiltersCount$ | async" matBadgeOverlap="false">Course Exchange</h3>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="side-panel-list">
            <mat-selection-list (selectionChange)="onToggleCourseExchangeFilter($event)">
              <mat-list-option [value]="courseExchange.id" [selected]="courseExchange.selected"
                               *ngFor="let courseExchange of courseExchangeFilters$ | async; trackBy: trackBy">
                {{ courseExchange.descriptionForView }}
              </mat-list-option>
            </mat-selection-list>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="(institutionRoleFilters$ | async)?.length>0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 [matBadge]="institutionRoleFiltersCount$ | async" matBadgeOverlap="false">User Role</h3>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="side-panel-list">
            <mat-selection-list (selectionChange)="onToggleInstitutionRoleFilter($event)">
              <mat-list-option [value]="role.id" [selected]="role.selected"
                               *ngFor="let role of institutionRoleFilters$ | async; trackBy: trackBy">
                {{ role.descriptionForView }}
              </mat-list-option>
            </mat-selection-list>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel *ngIf="(statusFilters$ | async)?.length>0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 [matBadge]="statusFiltersCount$ | async" matBadgeOverlap="false">Status</h3>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="side-panel-list">
            <mat-selection-list (selectionChange)="onToggleStatusFilter($event)">
              <mat-list-option [value]="status.id" [selected]="status.selected"
                               *ngFor="let status of statusFilters$ | async; trackBy: trackBy">
                {{ status.descriptionForView }}
              </mat-list-option>
            </mat-selection-list>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </elb-page-list-sidebar>

    <elb-page-list-main>
      <elb-page-header [pageTitle]="'Users'"
        [pageSubTitle]="''"
        (returnToDashboardClicked)="goToDashboardClicked.emit()">
        <button class="btn--icon btn--institution-admin submit-button" mat-button (click)="addClicked.emit()">
          <mat-icon>add</mat-icon>Add New User
        </button>
      </elb-page-header>

      <elb-page-list-main-header>
        <div class="search-and-sort-list" fxLayout="row" fxLayoutAlign="space-between center">
          <!-- SEARCH -->
          <div class="search-wrapper" >
            <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
              <mat-icon>search</mat-icon>
              <input #filter placeholder="Search Users">
            </div>
          </div>
          <!-- / END SEARCH -->

          <!-- SORT MENU -->
          <div class="sort-wrapper">
            <mat-form-field class="full-list-sort">
              <mat-select placeholder="Sort By" [value]="currentSort$ | async"
                          (selectionChange)="changeSort($event.value)">
                <mat-select-trigger>
                  <mat-icon>{{(currentSort$ | async)?.icon}}</mat-icon>&nbsp;{{(currentSort$ | async)?.name}}
                </mat-select-trigger>
                <mat-option *ngFor="let sort of sorts$ | async" [value]="sort">
                  <mat-icon>{{sort.icon}}</mat-icon>{{sort.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="full-list-sort">
              <mat-select placeholder="Order By" [value]="currentOrder$ | async"
                          (selectionChange)="changeOrderBy($event.value)">
                <mat-select-trigger>
                  <mat-icon>{{(currentOrder$ | async)?.icon}}</mat-icon>&nbsp;{{(currentOrder$ | async)?.name}}
                </mat-select-trigger>
                <mat-option *ngFor="let order of orders" [value]="order">
                  <mat-icon>{{order.icon}}</mat-icon>{{order.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <!-- /END SORT -->
        </div>

        <div class="filters-wrapper" fxLayout="row" fxLayoutAlign="space-between center">
          <div  class="list-select-all">
            <mat-checkbox (change)="masterToggle(isAllSelected)"
                          [checked]="isAllSelected$ | async"
                          [indeterminate]="indeterminateSelected$ | async">
              Select All Users ({{selectedStatus$ | async}})
            </mat-checkbox>
          </div>
          <div class="full-filters-chosen">
            <mat-chip-list>
              <mat-chip *ngFor="let filter of selectedFilters$ | async" [selectable]="false" [removable]="true"
                        (removed)="removeFilter(filter)">
                {{filter.description}}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
            </mat-chip-list>
          </div>
        </div>
      </elb-page-list-main-header>

      <elb-page-list-main-body *ngIf="(currentUser$ | async) as currentUser">
        <cdk-virtual-scroll-viewport itemSize="50" class="list">
          <mat-list *ngIf="(users$ | async) as users">
            <mat-list-item class="list-item--custom"
              [ngClass]="['users-list-item', 'list-item', user.isActive ? 'is_active' : '']"
              *cdkVirtualFor="let user of users; trackBy: trackBy">
              <div class="user-listing" fxLayout="column" fxLayoutAlign="start start">

                <!--  LIST USER ACTIONS  -->
                <div class="users-list-actions" fxLayout="row" fxLayoutAlign="start space-between">
                  <div class="users-list-select-user">
                    <mat-checkbox (change)="toggle(user)" [checked]="user.selected"></mat-checkbox>
                  </div>
                  <div class="users-list-info-menu">
                    <mat-menu #usersListMenu="matMenu">
                      <button mat-menu-item (click)="editClicked.emit(user);" *ngIf="canEditUser(currentUser,user)">Edit User</button>
                      <button mat-menu-item (click)="deleteUser(user)" *ngIf="canDelete(currentUser,user)">Delete User</button>
                      <button mat-menu-item (click)="deactivateUser(user)" *ngIf="canDeactivate(currentUser,user)">Deactivate</button>
                      <button mat-menu-item (click)="activateUser(user)" *ngIf="canActivate(currentUser,user)">Activate</button>
                      <button mat-menu-item (click)="sendInviteEmail(user)" *ngIf="canSendInvite(currentUser,user)">Resend Invite</button>
                      <button mat-menu-item (click)="exportUserToExcel(user)">Export To Excel</button>
                    </mat-menu>
                    <button mat-icon-button [matMenuTriggerFor]="usersListMenu" *ngIf="showEllipsis(currentUser,user)">
                      <mat-icon>more_horiz</mat-icon>
                    </button>

                  </div>
                </div>
                <!-- LIST ITEM USER INFO  -->
                <div class="users-list-user-info users-list-user-detail" fxLayout="column" fxLayoutAlign="start start">
                  <div class="user-list-visible" fxLayout="row">
                    <!-- THIS WILL BE WHERE THE USER LIST TITLE INFO WILL GO -->
                    <div class="users-list-title-content" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
                      <div class="users-list-logo-container" fxLayout="row" fxLayoutAlign="start center">
                        <img class="users-list-title-logo" fxFlexAlign="center" src="{{user.institutionLogoUrl}}">
                      </div>
                      <div class="users-list-title-container" fxLayout="column" fxLayoutAlign="start center">
                        <div>
                          <span class="users-list-title">{{user.title}}&nbsp;{{user.firstName}}&nbsp;{{user.lastName}}</span>
                          <br>
                          <span class="users-list-subtitle">{{user.institutionName}}</span>
                        </div>

                      </div>
                    </div>
                    <!-- THIS WILL BE WHERE THE USER LIST ITEM INFO WILL GO -->
                    <div class="users-list-info-content" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="64px">
                      <div class="column">
                        <div class="user-info">Role:&nbsp;{{user.institutionRole}}</div>
                        <div class="user-info">Department:&nbsp;{{user.department}}</div>

                      </div>
                      <div class="column">
                        <div class="user-info">
                          <a href="mailto:{{user.emailAddress}}"><mat-icon>mail</mat-icon>{{user.emailAddress}}</a>
                        </div>
                        <div class="user-info">
                          <mat-icon>phone</mat-icon><span>{{user.phoneNumber}}</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="users-list-expansion">
                    <mat-accordion>
                      <mat-expansion-panel>
                        <mat-expansion-panel-header>
                          <mat-panel-title>
                            User's Course Exchanges &amp; Institution
                          </mat-panel-title>
                          <mat-panel-description>
                            View &amp; Edit Affiliated Course Exchanges, Roles and Permissions
                          </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div class="users-list-expansion-panel-content">
                          <mat-list>
                            <mat-list-item
                              *ngFor="let accessRole of user.accessRoles">
                              <div class="user-course-exchanges" fxLayout="row" fxLayoutAlign="start start">
                                <div class="column-35">{{accessRole.entityDescription}}
                                </div>
                                <div class="column-35"><strong>Role:</strong> {{accessRole.roleDescription}}</div>
                                <div class="column-30">
                                  <button
                                    class="submit-button icon-btn provider-admin-btn"
                                    mat-button
                                    (click)="managePermissions(user,accessRole)" *ngIf="canEditUser(currentUser,user)">
                                    <mat-icon>edit</mat-icon>Edit Permissions
                                  </button>
                                </div>
                              </div>
                            </mat-list-item>
                          </mat-list>


                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </div>
                </div>

              </div>

            </mat-list-item>
          </mat-list>
        </cdk-virtual-scroll-viewport>
      </elb-page-list-main-body>

      <elb-page-list-main-footer>
        <ng-content></ng-content>
        <button class="submit-button icon-btn" mat-button (click)="exportSelectedToExcel($event)" [disabled]="!(canExportToExcel$ | async)">
          <mat-icon>save_alt</mat-icon>Export to Excel
      </button>
      </elb-page-list-main-footer>

    </elb-page-list-main>
  </elb-page-list-content>
</elb-page-list-container>
