import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { switchMap, map, tap, concatMap, withLatestFrom } from 'rxjs/operators';
import { CourseExchangeService } from '@shared/courseExchange/services/course-exchange.service';
import * as fromStore from '@courseExchange/institution';
import * as fromCore from '@core/store';
import { MatDialog } from '@angular/material/dialog';
import { InviteExistingInstitutionDialogComponent } from '@courseExchange/institution/components/dialogs/invite-existing-institution-dialog/invite-existing-institution-dialog.component';
import { IAppState } from 'app/store/state/app.state';
import { Store, select } from '@ngrx/store';
import { InstitutionService } from '@courseExchange/institution/services/institution.service';
import { of } from 'rxjs';
import { UserService } from '@shared/user/services';
import { InviteNewInstitutionDialogComponent } from '@courseExchange/institution/components/dialogs/invite-new-institution-dialog/invite-new-institution-dialog.component';
import { RealTimeService } from '@core/services/realTime.service';
import { CourseExchangeInstitutionListItem } from '@shared/courseExchange/models';
import { ConfirmDialogComponent, ConfirmDialogData } from '@core/components/confirm-dialog/confirm-dialog.component';
@Injectable()
export class CourseExchangeInstitutionsEffects {
        constructor(private actions$: Actions, private courseExchangeService: CourseExchangeService,
                private matDialog: MatDialog, private institutionService: InstitutionService, 
                private store:Store<IAppState>, private userService: UserService, private realTimeService: RealTimeService) {
            this.initSignalRMessages();
        }
        
        private initSignalRMessages() {
            this.realTimeService.hubConnection$.subscribe(hubConnection => {
                if (!hubConnection) return;
                hubConnection.on('CourseExchangeInstitutionListItemUpdated', (courseExchangeInstitutionListItem: CourseExchangeInstitutionListItem) => {
                    this.store.dispatch(fromStore.Actions.CourseExchangeInstitutionListItemUpdated({ courseExchangeInstitutionListItem }));
                });
                hubConnection.on('CourseExchangeInstitutionListItemAdded', (courseExchangeInstitutionListItem: CourseExchangeInstitutionListItem) => {
                    this.store.dispatch(fromStore.Actions.CourseExchangeInstitutionListItemAdded({ courseExchangeInstitutionListItem }));
                }); 
                hubConnection.on('CourseExchangeInstitutionListItemDeleted', (courseExchangeInstitutionListItem: CourseExchangeInstitutionListItem) => {
                    this.store.dispatch(fromStore.Actions.CourseExchangeInstitutionListItemDeleted({ courseExchangeInstitutionListItem }));
                }); 
            })
        }

        loadCourseExchangeInstitution$ = createEffect(() => {
                return this.actions$.pipe(
                        ofType(fromStore.Actions.LoadCourseExchangeInstitutions),
                        switchMap(({courseExchangeId}) => this.courseExchangeService.getCourseExchangeInstitutions(courseExchangeId)),
                        map((institutions) =>
                            fromStore.Actions.LoadCourseExchangeInstitutionsSuccess({ institutions }))
                );
        });

        loadCurrentInstitution$ = createEffect(() => {
                return this.actions$.pipe(
                        ofType(fromStore.Actions.LoadCurrentInstitution),
                        switchMap(({courseExchangeId, institutionId}) => this.courseExchangeService.getCourseExchangeInstitution(courseExchangeId,institutionId)),
                        map((currentInstitution) =>
                                fromStore.Actions.LoadCurrentInstitutionSuccess({ currentInstitution }))
                );
        });

        inviteExistingDialog$ = createEffect(() => {
                return this.actions$.pipe(
                    ofType(fromStore.Actions.InviteToCourseExchange),
                    concatMap((action) => of(action).pipe(
                        withLatestFrom(this.store.pipe(select(fromStore.Selectors.CourseExchangeId)))
                    )),
                    /** An EMPTY observable only emits completion. Replace with your own observable stream */
                    tap(([action,courseExchangeId]) => {
                        const currentInstitution = action.institution;
                        const dialogRef = this.matDialog.open(InviteExistingInstitutionDialogComponent,
                        {
                            disableClose: true,
                            data: {
                                currentInstitution,
                                courseExchangeId
                            }
                        });
                    })
                );
            }, { dispatch: false });

        inviteNewDialog$ = createEffect(() => {
                return this.actions$.pipe(
                    ofType(fromStore.Actions.BeginAddNewInstitutionWizard),
                    concatMap((action) => of(action).pipe(
                        withLatestFrom(this.store.pipe(select(fromStore.Selectors.CourseExchangeId)))
                    )),
                    /** An EMPTY observable only emits completion. Replace with your own observable stream */
                    tap(([, courseExchangeId]) => {
                        const dialogRef = this.matDialog.open(InviteNewInstitutionDialogComponent,
                        {
                            disableClose: true,
                            data: {
                                courseExchangeId
                            }
                        });
                    })
                );
            }, { dispatch: false });

        loadUsers$ = createEffect(() => {
            return this.actions$.pipe(
                    ofType(fromStore.Actions.LoadInstitutionUsers),
                    switchMap((action) => this.userService.getUserList(action.institutionId)),
                    map((currentInstitutionUsers) =>
                            fromStore.Actions.LoadInstitutionUsersSuccess({ currentInstitutionUsers }))
            );
        });

        confirmActivateCourseExchange$ = createEffect(() => {
            return this.actions$.pipe(
                ofType(fromStore.Actions.ConfirmActivateCourseExchangeInstitution),
                tap(({courseExchangeInstitutionListItem}) => {
                  const data = new ConfirmDialogData();
                  data.title = 'Activate Institution?'
                  data.message = 'Are you sure you want to Activate this Institution in this Course Exchange?';
                  data.okButtonText= 'Yes';
                  data.showCancelButton = true;
        
        
                  this.matDialog.open(ConfirmDialogComponent, {data})
                    .afterClosed()
                    .subscribe((result) => {
                      if(result){
                        this.store.dispatch(fromStore.Actions.ActivateCourseExchangeInstitution({courseExchangeInstitutionListItem}));
                      }
                    });
                })
            )
          },{dispatch:false});

        activateCourseExchangeInstitution$ = createEffect(() => {
            return this.actions$.pipe(
                    ofType(fromStore.Actions.ActivateCourseExchangeInstitution),
                    /** An EMPTY observable only emits completion. Replace with your own observable stream */
                    switchMap(({courseExchangeInstitutionListItem}) => {
                        const {courseExchangeId, institutionId} = courseExchangeInstitutionListItem;
                        return this.courseExchangeService.activateInstitution(courseExchangeId,institutionId)}),
                    map((response)=>fromCore.Actions.HandleResponse({response})));
        });

        confirmDeactivateCourseExchange$ = createEffect(() => {
            return this.actions$.pipe(
                ofType(fromStore.Actions.ConfirmDeactivateCourseExchangeInstitution),
                tap(({courseExchangeInstitutionListItem}) => {
                  const data = new ConfirmDialogData();
                  data.title = 'Deactivate Institution?'
                  data.message = 'Are you sure you want to Deactivate this Institution from Course Exchange?';
                  data.okButtonText= 'Yes';
                  data.showCancelButton = true;
        
        
                  this.matDialog.open(ConfirmDialogComponent, {data})
                    .afterClosed()
                    .subscribe((result) => {
                      if(result){
                        this.store.dispatch(fromStore.Actions.DeactivateCourseExchangeInstitution({courseExchangeInstitutionListItem}));
                      }
                    });
                })
            )
          },{dispatch:false});

        deactivateCourseExchangeInstitution$ = createEffect(() => {
            return this.actions$.pipe(
                    ofType(fromStore.Actions.DeactivateCourseExchangeInstitution),
                    /** An EMPTY observable only emits completion. Replace with your own observable stream */
                    switchMap(({courseExchangeInstitutionListItem}) => {
                        const {courseExchangeId, institutionId} = courseExchangeInstitutionListItem;
                        return this.courseExchangeService.deactivateInstitution(courseExchangeId,institutionId)}),
                    map((response)=>fromCore.Actions.HandleResponse({response})));
        });

        confirmDeleteCourseExchange$ = createEffect(() => {
            return this.actions$.pipe(
                ofType(fromStore.Actions.ConfirmDeleteCourseExchangeInstitution),
                tap(({courseExchangeInstitutionListItem}) => {
                  const data = new ConfirmDialogData();
                  data.title = 'Delete Institution?'
                  data.message = 'Are you sure you want to Delete this Institution from this Course Exchange?';
                  data.okButtonText= 'Yes';
                  data.showCancelButton = true;
        
        
                  this.matDialog.open(ConfirmDialogComponent, {data})
                    .afterClosed()
                    .subscribe((result) => {
                      if(result){
                        this.store.dispatch(fromStore.Actions.DeleteCourseExchangeInstitution({courseExchangeInstitutionListItem}));
                      }
                    });
                })
            )
          },{dispatch:false});

        deleteCourseExchangeInstitution$ = createEffect(() => {
            return this.actions$.pipe(
                    ofType(fromStore.Actions.DeleteCourseExchangeInstitution),
                    /** An EMPTY observable only emits completion. Replace with your own observable stream */
                    switchMap(({courseExchangeInstitutionListItem}) => {
                        const {courseExchangeId, institutionId} = courseExchangeInstitutionListItem;
                        return this.courseExchangeService.deleteInstitution(courseExchangeId,institutionId)}),
                    map((response)=>fromCore.Actions.HandleResponse({response})));
        });

        addInstitution$ = createEffect(() => {
            return this.actions$.pipe(
                    ofType(fromStore.Actions.AddInstitution),
                    concatMap((action) => of(action).pipe(
                        withLatestFrom(this.store.pipe(select(fromStore.Selectors.CourseExchangeId)))
                    )),
                    /** An EMPTY observable only emits completion. Replace with your own observable stream */
                    switchMap(([{command}, courseExchangeId]) => {
                        return this.courseExchangeService.addInstitution(courseExchangeId,command)}),
                    map((response)=>fromCore.Actions.HandleResponse({response})));
        });

        addMember$ = createEffect(() => {
            return this.actions$.pipe(
                    ofType(fromStore.Actions.AddMember),
                    concatMap((action) => of(action).pipe(
                        withLatestFrom(this.store.pipe(select(fromStore.Selectors.CourseExchangeId)))
                    )),
                    /** An EMPTY observable only emits completion. Replace with your own observable stream */
                    switchMap(([{command}, courseExchangeId]) => {
                        return this.courseExchangeService.addMember(courseExchangeId,command)}),
                    map((response)=>fromCore.Actions.HandleResponse({response})));
        });

        addProvider$ = createEffect(() => {
            return this.actions$.pipe(
                    ofType(fromStore.Actions.AddProvider),
                    concatMap((action) => of(action).pipe(
                        withLatestFrom(this.store.pipe(select(fromStore.Selectors.CourseExchangeId)))
                    )),
                    /** An EMPTY observable only emits completion. Replace with your own observable stream */
                    switchMap(([{command}, courseExchangeId]) => {
                        return this.courseExchangeService.addProvider(courseExchangeId,command)}),
                    map((response)=>fromCore.Actions.HandleResponse({response})));
        });
}