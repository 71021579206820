<mat-toolbar>
  <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <span class="title dialog-title">Compare Courses</span>
    <button mat-icon-button (click)="close()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div class="content">
  <div class="compare-courses-list">
    <div class="compare-courses-item" *ngFor="let memberCourse of selectedMemberCourses$ | async; trackBy: trackById">

      <div class="top-panel">
        <div class="select-all">
          <mat-checkbox (change)="selectCourse(memberCourse)"
                        [checked]="memberCourse.isFavourited">Select this course</mat-checkbox>
        </div>
        <div class="menu">
          <div class="dropdown-menu">
            <mat-menu #coursesListMenu="matMenu">
              <button mat-menu-item>Enroll Student</button>
              <button mat-menu-item
                      *ngIf="!memberCourse.isFavourited"
                      (click)="preSelectCourses(memberCourse)"
              >Add to Selected Courses</button>
              <button mat-menu-item
                      (click)="confirmDeselectCourse(memberCourse)"
                      *ngIf="memberCourse.isFavourited"
              >Remove from Pre-Selected Courses</button>
              <button mat-menu-item (click)="goToCourseDetail(memberCourse)">Go to Course Detail</button>
            </mat-menu>
            <button mat-icon-button
                    [matMenuTriggerFor]="coursesListMenu">
              <mat-icon>more_horiz</mat-icon>
            </button>
          </div>
          <div class="remove">
            <button mat-icon-button
                    (click)="removeFromCompare(memberCourse.id)">
              <mat-icon>close</mat-icon>
            </button>
          </div>
        </div>
      </div>

      <div class="header">
        <div class="header-logo">
          <img src="{{memberCourse.logoUrl}}">
        </div>
        <div class="header-content">
          <div class="title-box">
            <div class="title-box-text">{{memberCourse.code}}: {{memberCourse.title}}</div>

            <div class="title-box-icon">
              <mat-icon *ngIf="memberCourse.isFavourited">star</mat-icon>
              <mat-icon *ngIf="!memberCourse.isFavourited">star_border</mat-icon>
            </div>
          </div>
          <div class="description">
            Offered by: {{memberCourse.institution.description}}
          </div>
        </div>
      </div>

      <div class="body">
        <div class="additional-info">
          <div class="additional-info-col">
            <div class="additional-info-item">
              <div class="icon">
                <mat-icon>account_balance</mat-icon>
              </div>
              <div class="text">
                {{memberCourse.level.description}}
              </div>
            </div>

            <div class="additional-info-item">
              <div class="icon">
                <mat-icon>widgets</mat-icon>
              </div>
              <div class="text">
                {{memberCourse.category.description}}
              </div>
            </div>
          </div>

          <div class="additional-info-col">
            <div class="additional-info-item">
              <div class="icon">
                <mat-icon>hourglass_full</mat-icon>
              </div>
              <div class="text">
                {{memberCourse.creditHours2}} Credit Hours
              </div>
            </div>

            <div class="additional-info-item">
              <div class="icon">
                <mat-icon>view_comfy</mat-icon>
              </div>
              <div class="text">
                {{memberCourse.subCategory.description}}
              </div>
            </div>
          </div>
        </div>

        <div class="requirements">
          <div class="title">Course Requirements:</div>
          <div class="info">{{memberCourse.requirements ? memberCourse.requirements : 'None'}}</div>
        </div>

        <div class="sessions">
          <div class="title">
            <mat-icon>calendar_today</mat-icon> <span>Session Dates:</span>
          </div>

          <ng-container *ngIf="(memberCourseSessions$(memberCourse.providerCourseId) | async) as memberCourseSessions; else stubSession">
            <app-course-sessions [sessions]="memberCourseSessions"></app-course-sessions>
          </ng-container>

          <ng-template #stubSession>
            <div class="stub">
              None
            </div>
          </ng-template>
        </div>

        <div class="files">
          <!-- <div class="file-item faculty-cv" (click)="onFacultyCV(memberCourse.courseId)">
            <div class="icon">
              <mat-icon>person</mat-icon>
            </div>
            <div class="text">Faculty CV</div>
          </div> -->
          <ng-container *ngIf="memberCourse.syllabus; else stubSyllabus">
            <div class="file-item syllabus" (click)="onSyllabus(memberCourse)">
              <div class="icon">
                <mat-icon>insert_drive_file</mat-icon>
              </div>
              <div class="text">Example Syllabus</div>
            </div>
          </ng-container>
          <ng-template #stubSyllabus>
            <div class="text">No Syllabus</div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
