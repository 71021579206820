import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { Store, select } from "@ngrx/store";
import { first, finalize, filter, map, tap, concatMap, withLatestFrom } from "rxjs/operators";
import { IAppState } from 'app/store/state/app.state';
import * as fromProvider from '@provider/store';
import * as fromCourseExchange from '@courseExchange/store';
import { CourseExchangeSummary } from '@shared/courseExchange/models';
import { ProviderService } from '@shared/provider/services';

@Injectable()
export class CourseExchangeSummaryResolver implements Resolve<CourseExchangeSummary>{
    isLoading = false;
    constructor(private store: Store<IAppState>, private providerService: ProviderService){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
        const providerId = route.params["providerId"];
        let courseExchangeId = '';
        return this.store 
            .pipe(
                select(fromCourseExchange.Selectors.CourseExchangeSummary),
                concatMap(action => of(action).pipe(
                    withLatestFrom(this.store.pipe(select(fromProvider.Selectors.Provider)))
                )),
                map(([summary, provider]) => {
                    courseExchangeId = !!provider ? provider.courseExchangeId : '';
                    return !!summary && !!provider && summary.id == provider.courseExchangeId
                }),
                map((courseExchangeSummaryLoaded)=>{
                    if(!this.isLoading && !courseExchangeSummaryLoaded){
                        this.store.dispatch(fromProvider.Actions.LoadCourseExchangeSummary({providerId}));
                    }
                    return true;
                }),
                filter(courseExchangeSummary => !!courseExchangeSummary),
                first(),
                finalize(()=>this.isLoading=false)
            )
    }
}