import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fromServices from './services';
import * as fromComponents from './components';
import * as fromStore from '@shared/member/store';
import { MaterialModule } from 'app/core/material/material.module';
import { StudentSharedModule } from '@shared/student/student-shared.module';
import { EffectsModule } from '@ngrx/effects';
import { BulkDataMigrationDialogComponent } from './components/dialogs/bulk-data-migration-dialog/bulk-data-migration-dialog.component';

@NgModule({
    declarations: [...fromComponents.COMPONENTS, BulkDataMigrationDialogComponent],
    exports: [...fromComponents.COMPONENTS],
    imports: [
        CommonModule,
        MaterialModule,
        StudentSharedModule,
        EffectsModule.forFeature([...fromStore.EFFECTS])
    ],
    providers: [...fromServices.SERVICES, ...fromStore.RESOLVERS]
})
export class MemberSharedModule { }
