export enum ChecklistItemType {
    Root="Root",
    CourseExchange = "CourseExchange",
    Provider = "Provider",
    Member = "Member",
    ContactAndUserSetup = "ContactAndUserSetup",
    ContactAndUserSetup_Accounting = "ContactAndUserSetup_Accounting",
    ContactAndUserSetup_President = "ContactAndUserSetup_President",
    ContactAndUserSetup_ProvostOrVicePresident = "ContactAndUserSetup_ProvostOrVicePresident",
    ContactAndUserSetup_AdditionalUsers = "ContactAndUserSetup_AdditionalUsers",

    Calendar = "Calendar",
    Calendar_AddYear = "Calendar_AddYear",
    Calendar_AddSemesters = "Calendar_AddSemesters",
    Calendar_AddSessions = "Calendar_AddSessions",

    CourseInformation = "CourseInformation",
    CourseInformation_Levels = "CourseInformation_Levels",
    CourseInformation_Categories = "CourseInformation_Categories",
    CourseInformation_SubCategories = "CourseInformation_SubCategories",
    CourseInformation_Tags = "CourseInformation_Tags",

    Fees = "Fees",
    Fees_Item = "Fees_Item",
    BankInformation = "BankInformation",
    Policies = "Policies",

    EntityProfile = "EntityProfile",
    EntityProfile_CourseExchangeName = "EntityProfile_CourseExchangeName",
    EntityProfile_InstitutionName ="EntityProfile_InstitutionName",
    EntityProfile_Address = "EntityProfile_Address",
    EntityProfile_PrimaryLogo = "EntityProfile_PrimaryLogo",
    EntityProfile_SecondaryLogo ="EntityProfile_SecondaryLogo",
    EntityProfile_Url="EntityProfile_Url",
    EntityProfile_AccreditationBody="EntityProfile_AccreditationBody"
}