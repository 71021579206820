import { NgModule } from '@angular/core';
import {NotificationBarComponent} from './notification-bar.component';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {MaterialModule} from '../../../core/material/material.module';

@NgModule({
  declarations: [
    NotificationBarComponent
  ],
  imports: [
    FuseSharedModule,
    MaterialModule,
  ],
  exports: [
    NotificationBarComponent
  ]
})
export class NotificationBarModule {}
