import { createEffect, Actions, ofType } from "@ngrx/effects";
import { Injectable } from "@angular/core";
import { tap, concatMap, withLatestFrom } from "rxjs/operators";
import * as fromCourseExchange from '..';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { IAppState } from 'app/store/state/app.state';

@Injectable()
export class CourseExchangeNavigationEffects{
    constructor(private actions$: Actions, private router: Router, private store: Store<IAppState>){
    }

    goToWizard$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromCourseExchange.Actions.Wizard),
                tap((action) => {
                    const {courseExchangeId,institutionId} = action;
                    this.router.navigate([`/course-exchange/${courseExchangeId}/${institutionId}/wizard`])
                })
        )
    },{dispatch : false});

    goToCoursesData$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromCourseExchange.Actions.ViewCoursesData),
                concatMap(action => of(action).pipe(
                    withLatestFrom(this.store.pipe(select(fromCourseExchange.Selectors.CourseExchange)))
                )),
                tap(([action,courseExchange]) => {
                    const courseExchangeId = !!action.courseExchangeId ? action.courseExchangeId : courseExchange.id;
                    const managingInstitutionId = !!action.institutionId ? action.institutionId: courseExchange.managingInstitutionId;
                    this.router.navigate([`/course-exchange/${courseExchangeId}/${managingInstitutionId}/courses-data`])
                })
        )
    },{dispatch : false});

    goToFees$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromCourseExchange.Actions.ViewFees),
                concatMap(action => of(action).pipe(
                    withLatestFrom(this.store.pipe(select(fromCourseExchange.Selectors.CourseExchange)))
                )),
                tap(([action,courseExchange]) => {
                    const courseExchangeId = !!action.courseExchangeId ? action.courseExchangeId : courseExchange.id;
                    const managingInstitutionId = !!action.institutionId ? action.institutionId: courseExchange.managingInstitutionId;
                    this.router.navigate([`/course-exchange/${courseExchangeId}/${managingInstitutionId}/fees`])
                })
        )
    },{dispatch : false});

    
    goToCalendars$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromCourseExchange.Actions.ViewCalendars),
                concatMap(action => of(action).pipe(
                    withLatestFrom(this.store.pipe(select(fromCourseExchange.Selectors.CourseExchange)))
                )),
                tap(([action,courseExchange]) => {
                    const courseExchangeId = !!action.courseExchangeId ? action.courseExchangeId : courseExchange.id;
                    const managingInstitutionId = !!action.institutionId ? action.institutionId: courseExchange.managingInstitutionId;
                    this.router.navigate([`/course-exchange/${courseExchangeId}/${managingInstitutionId}/calendar`]);
                })
        )
    },{dispatch : false});

    goToEnrollments$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromCourseExchange.Actions.ViewEnrollments),
                concatMap(action => of(action).pipe(
                    withLatestFrom(this.store.pipe(select(fromCourseExchange.Selectors.CourseExchange)))
                )),
                tap(([action,courseExchange]) => {
                    const courseExchangeId = !!action.courseExchangeId ? action.courseExchangeId : courseExchange.id;
                    const managingInstitutionId = !!action.institutionId ? action.institutionId: courseExchange.managingInstitutionId;
                    this.router.navigate([`/course-exchange/${courseExchangeId}/${managingInstitutionId}/enrollments/list`]);
                })
        )
    },{dispatch : false});

    goToDashboard$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromCourseExchange.Actions.ViewDashboard),
                concatMap(action => of(action).pipe(
                    withLatestFrom(this.store.pipe(select(fromCourseExchange.Selectors.CourseExchange)))
                )),
                tap(([action,courseExchange]) => {
                    const courseExchangeId = !!action.courseExchangeId ? action.courseExchangeId : courseExchange.id;
                    const managingInstitutionId = !!action.institutionId ? action.institutionId: courseExchange.managingInstitutionId;
                    this.router.navigate([`/course-exchange/${courseExchangeId}/${managingInstitutionId}/dashboard`]);
                })
        )
    },{dispatch : false});

    


}