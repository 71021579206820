import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import {Location} from '@angular/common';
@Component({
  selector: 'elb-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {

  constructor(private location: Location) { }
  @Input() pageTitle: string;
  @Input() pageSubTitle: string;
  @Output() returnToDashboardClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() goBackClicked : EventEmitter<void> = new EventEmitter<void>();
  ngOnInit() {
  }

  goBack(): void
  {
    this.location.back();
  }

}
