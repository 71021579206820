import {createEffect, Actions, ofType} from "@ngrx/effects";
import {Injectable} from "@angular/core";
import {map, switchMap, withLatestFrom, concatMap, tap, catchError} from "rxjs/operators";
import {select, Store} from '@ngrx/store';
import {of} from 'rxjs';
import {IAppState} from 'app/store/state/app.state';
import {ExcelService} from 'app/core/services/excel.service';
import {PopupService} from 'app/core/services/popup.service';
import {MatDialog} from '@angular/material/dialog';
import * as fromCore from '@core/store';
import * as fromMemberBilling from '@member/billing/store';
import * as fromMember from '@member/store';
import { MemberService } from '@shared/member/services';
import { CalendarService } from '@shared/calendar/services';

@Injectable()
export class MemberBillingEffects {
  constructor(private actions$: Actions, private store: Store<IAppState>, private memberService: MemberService,
              private dialog: MatDialog, private popupService: PopupService, private calendarService: CalendarService,
              private excelService: ExcelService) {
  }

  loadMemberFeeMonthSummaries$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromMemberBilling.Actions.LoadMonthlyStatements),
      concatMap((action)=> of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromMember.Selectors.Member)))
      )),
      switchMap(([action,member]) =>{
        const memberId = !!action.memberId ? action.memberId : member.id;
        return this.memberService.getMonthlyStatements(memberId)
      }),
      map((monthlyStatements) => fromMemberBilling.Actions.LoadMonthlyStatementsSuccess({monthlyStatements})),
      catchError(() =>
        of(fromMemberBilling.Actions.LoadMonthlyStatementsError(
          {message: 'Problem retrieving Member Fee Monthly Summaries'}
        )))
    )
  });

  loadMemberFeeMonthlySummariesError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromMemberBilling.Actions.LoadMonthlyStatementsError),
      /** An EMPTY observable only emits completion. Replace with your own observable stream */
      map(({message}) => fromCore.Actions.ShowMessage({message})));
  });

  
  downloadMonthlyStatement$ = createEffect(() => {
    return this.actions$.pipe(
            ofType(fromMemberBilling.Actions.DownloadMonthlyStatement),
            tap(({monthlyStatement})=> this.memberService.downloadMonthlyStatement(monthlyStatement))
            );
    },{dispatch : false});

    exportMemberDetailedReport$ = createEffect(() => {
      let year = 0;
      let month = 0;
      return this.actions$.pipe(
          ofType(fromMemberBilling.Actions.ExportMemberDetailedReport),
          concatMap((action)=> of(action).pipe(
            withLatestFrom(this.store.pipe(select(fromMember.Selectors.Member)))
          )),
          /** An EMPTY observable only emits completion. Replace with your own observable stream */
          switchMap(([action, member]) => {
            year = action.year;
            month = action.month;
            return this.excelService.exportMemberDetailedReport(member.id,year,month);
          }),
          tap((data) => {
            const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const linkDomObject: HTMLAnchorElement = document.createElement('a');
            linkDomObject.href = URL.createObjectURL(blob);
            linkDomObject.download = `MemberDetailedReport-${year}-${month}.xlsx`;
            document.body.appendChild(linkDomObject);
            linkDomObject.click();
            linkDomObject.remove();
          })
      )
    },{dispatch:false});
}
