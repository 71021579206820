export class BaseListItem{
    id: string;
    description: string;

    public toString():string {
        return this.description;
    }
}

export class BaseListItemForView extends BaseListItem{
    selected: boolean;
}