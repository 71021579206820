import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ChecklistItemType} from '../../../../core/models/checklistItemType.enum';
import {ElbertFile} from 'app/core/models/elbertFile.model';
import {
  WizardSkipDialogContainerComponent,
  IWizardSkipDialogData
} from '../wizard-skip-dialog-container/wizard-skip-dialog-container.component';
import {ConfirmDialogData, ConfirmDialogComponent} from '@core/components/confirm-dialog/confirm-dialog.component';
import {NavigationType} from '@shared/wizard/constants/navigateType.enum';
import {FileStatus} from '@core/models/fileStatus.enum';

@Component({
  selector: 'wizard-bank-info-step',
  templateUrl: './wizard-bank-info-step.component.html',
  styleUrls: ['../../styles/common-wizard-step.scss', './wizard-bank-info-step.component.scss']
})
export class WizardBankInfoStepComponent implements OnInit {

  @Input() bankInfo: ElbertFile;
  @Input() currentStepId: string;
  @Input() showNextStep = true;
  @Output() downloadBankInfoTemplate: EventEmitter<void> = new EventEmitter<void>();
  @Output() downloadBankInfo: EventEmitter<ElbertFile> = new EventEmitter<ElbertFile>();
  @Output() uploadBankInfo: EventEmitter<File> = new EventEmitter<File>();
  @Output() navigate: EventEmitter<string> = new EventEmitter<string>();

  FileStatus = FileStatus;
  file: File;

  constructor(
    public dialog: MatDialog
  ) {
  }

  ngOnInit(): void {

  }

  goBack = () => {
    this.navigate.emit(NavigationType.Back);
  }

  onDownloadBankInfo = () => {
    const bankInfo = this.bankInfo;
    this.downloadBankInfo.emit(bankInfo);
  }

  onSkip = () => {
    this.dialog.open<WizardSkipDialogContainerComponent, IWizardSkipDialogData>(WizardSkipDialogContainerComponent, {
      data: {
        itemType: ChecklistItemType.BankInformation,
        toNextStepCb: () => this.navigate.emit(NavigationType.Skip)
      }
    });
  }

  fileInputChange = (event: Event) => {
    this.file = (event.target as HTMLInputElement).files[0];
    this.uploadBankInfo.emit(this.file);
  }

  onNextStep = () => {
    this.navigate.emit(NavigationType.Forward);
  }
}
