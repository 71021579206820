import { EnrollmentListItem, EnrollmentListItemForView } from "@member/enrollment";

export default <T extends EnrollmentListItem>(enrollment:T): T => {
    return {
      ...enrollment,
      studentDateOfBirth: new Date(enrollment.studentDateOfBirth2.year, enrollment.studentDateOfBirth2.month-1, enrollment.studentDateOfBirth2.day),
      sessionStartDate: new Date(enrollment.sessionStartDate2.year, enrollment.sessionStartDate2. month-1, enrollment.sessionStartDate2.day),
      sessionEndDate: new Date(enrollment.sessionEndDate2.year, enrollment.sessionEndDate2.month-1, enrollment.sessionEndDate2.day),
      dropDeadlineDate: new Date(enrollment.dropDeadlineDate2.year, enrollment.dropDeadlineDate2.month-1, enrollment.dropDeadlineDate2.day),
      withdrawalDeadlineDate: new Date(enrollment.withdrawalDeadlineDate2.year, enrollment.withdrawalDeadlineDate2.month-1, enrollment.withdrawalDeadlineDate2.day),
      registrationDeadlineDate: new Date(enrollment.registrationDeadlineDate2.year, enrollment.registrationDeadlineDate2.month-1, enrollment.registrationDeadlineDate2.day),
      registrationStartDate: new Date(enrollment.registrationStartDate2.year, enrollment.registrationStartDate2.month-1, enrollment.registrationStartDate2.day),
      feeEarnedDate: new Date(enrollment.feeEarnedDate2.year, enrollment.feeEarnedDate2.month-1, enrollment.feeEarnedDate2.day)
    };
  }
  