import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { ImageStorageService } from './core/services/image-storage.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { CoreModule } from './core/core.module';
import { MainComponent } from './main/main.component';
import { JwtInterceptor } from './authentication/jwt.interceptor';
import { PermissionType } from './authentication/models/permissionType.enum';
import { HasPermissionGuard } from './authentication/guards/hasPermission.guard';
import { AuthorizeGuard } from './authentication/authorize.guard';
import { MatPasswordStrengthModule } from '@angular-material-extensions/password-strength';
import { ViewContext } from './authentication/models/viewContext.enum';
import {AppStoreModule} from './store/store.module';
import { PercentPipe, CurrencyPipe } from '@angular/common';
import {NgxDatatableModule} from '@swimlane/ngx-datatable';
import { ConfigService } from '@core/services/config.service';

export function configProviderFactory(config: ConfigService) {
    return () => config.init();
}
const appRoutes: Routes = [
    {
        path: '',
        redirectTo: 'auth',
        pathMatch: 'full'
    },
    {
        path: 'auth',
        loadChildren: () => import('./authentication/authentication.module').then(mod => mod.AuthenticationModule)
    },
    {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then(mod => mod.AdminModule),
        canActivate: [AuthorizeGuard, HasPermissionGuard],
        data: {permission: PermissionType.ViewSuperAdminDashboard, viewContext: ViewContext.SuperAdmin}
    },
    {
        path: 'course-exchange',
        loadChildren: () => import('./course-exchange/course-exchange.module').then(mod=>mod.CourseExchangeModule),
        canActivate: [AuthorizeGuard, HasPermissionGuard],
        data: {permission: PermissionType.ViewCourseExchangeAdminDashboard, viewContext: ViewContext.CourseExchangeAdmin}
    },
    {
        path: 'institution',
        loadChildren: () => import('./institution/institution.module').then(mod => mod.InstitutionModule),
        canActivate: [HasPermissionGuard],
        data: {permission: PermissionType.ViewInstitutionDashboard, viewContext: ViewContext.Institution}
    },
    {
        path: 'provider',
        loadChildren: () => import('./provider/provider.module').then(mod => mod.ProviderModule),
        canActivate: [AuthorizeGuard, HasPermissionGuard],
        data: {permission: PermissionType.ViewProviderDashboard, viewContext: ViewContext.Provider}
    },
    {
        path: 'member',
        loadChildren: () => import('./member/member.module').then(mod => mod.MemberModule),
        canActivate: [AuthorizeGuard, HasPermissionGuard],
        data: {permission: PermissionType.ViewMemberDashboard, viewContext: ViewContext.Member}
    }

];

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        
    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        CoreModule,
        HttpClientModule,
        AppStoreModule,
        RouterModule.forRoot(appRoutes, { useHash: true, relativeLinkResolution: 'legacy' }),

        TranslateModule.forRoot(),

        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatSnackBarModule,

        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,

        MatPasswordStrengthModule,
        NgxDatatableModule
    ],
    bootstrap   : [
        AppComponent
    ],
    providers: [
      ImageStorageService, PercentPipe, CurrencyPipe, 
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    ConfigService,
    {
        provide: APP_INITIALIZER,
        useFactory: (config: ConfigService) => () => config.init(),
        deps: [ConfigService],
        multi: true
    }
    ]
})
export class AppModule
{
}
