<div class="page-layout carded fullwidth inner-scroll list-page member-admin-page student-detail">
  <div class="top-bg member-admin-top-bg"></div>

  <div class="center">
    <elb-page-header [pageTitle]="'Student Details'" [pageSubTitle]="''" (returnToDashboardClicked)="goToMemberDashboard()">
      <button class="btn--icon btn--member-admin" mat-button (click)="editStudent()">
        <mat-icon>edit</mat-icon>Edit Student
      </button>
    </elb-page-header>

    <div class="content-card" *ngIf="(student$ | async) as student">
      <ng-container>
        <div class="main-table">
          <div class="row">
            <div class="col col-50">
              <div class="box">
                <div class="box-title">Student ID:</div>
                <ng-container *ngIf="student.studentNumber; else notExist">
                  <div class="box-info">{{student.studentNumber}}</div>
                </ng-container>
              </div>
            </div>

            <div class="col col-100">
              <div class="box" *ngIf="(studentDetail$ | async) as studentDetail">
                <div class="box-title">Student Type:</div>
                <ng-container *ngIf="studentDetail.studentType; else notExist">
                  <div class="box-info">{{studentDetail.studentType}}</div>
                </ng-container>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col col-33">
              <div class="box">
                <div class="box-title">Last Name:</div>
                <ng-container *ngIf="student.fullName.lastName; else notExist">
                  <div class="box-info">{{student.fullName.lastName}}</div>
                </ng-container>
              </div>
            </div>

            <div class="col col-33">
              <div class="box">
                <div class="box-title">First Name:</div>
                <ng-container *ngIf="student.fullName.firstName; else notExist">
                  <div class="box-info">{{student.fullName.firstName}}</div>
                </ng-container>
              </div>
            </div>

            <div class="col col-33">
              <div class="box">
                <div class="box-title">Middle Initial or Name:</div>
                <ng-container *ngIf="student.fullName.middleName; else notExist">
                  <div class="box-info">{{student.fullName.middleName}}</div>
                </ng-container>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col col-100">
              <div class="box">
                <div class="box-title">Address 1:</div>
                <ng-container *ngIf="student.address.addressLine1; else notExist">
                  <div class="box-info">{{student.address.addressLine1}}</div>
                </ng-container>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col col-33">
              <div class="box">
                <div class="box-title">Address 2:</div>
                <ng-container *ngIf="student.address.addressLine2; else notExist">
                  <div class="box-info">{{student.address.addressLine2}}</div>
                </ng-container>
              </div>
            </div>

            <div class="col col-33">
              <div class="box">
                <div class="box-title">City:</div>
                <ng-container *ngIf="student.address.city; else notExist">
                  <div class="box-info">{{student.address.city}}</div>
                </ng-container>
              </div>
            </div>

            <div class="col col-33">
              <div class="row">
                <div class="col col-50">
                  <div class="box">
                    <div class="box-title">State:</div>
                    <ng-container *ngIf="student.address.region; else notExist">
                      <div class="box-info">{{student.address.region.description}}</div>
                    </ng-container>
                  </div>
                </div>
                <div class="col col-50">
                  <div class="box">
                    <div class="box-title">Zip Code:</div>
                    <ng-container *ngIf="student.address.postalCode; else notExist">
                      <div class="box-info">{{student.address.postalCode}}</div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col col-66">
              <div class="box">
                <div class="box-title box-title-with-icon">
                <span class="box-icon">
                  <mat-icon>mail_outline</mat-icon>
                </span>
                  Email 1:
                </div>
                <ng-container *ngIf="student.emailAddress; else notExist">
                  <a [href]="'mailto:' + student.emailAddress.value" class="box-info">{{student.emailAddress.value}}</a>
                </ng-container>
              </div>
            </div>
            <div class="col col-33">
              <div class="box">
                <div class="box-title box-title-with-icon">
                <span class="box-icon">
                  <mat-icon>phone</mat-icon>
                </span>
                  Phone:
                </div>
                <ng-container *ngIf="student.phoneNumber; else notExist">
                  <a [href]="'tel:' + student.phoneNumber.value" class="box-info">{{student.phoneNumber.value}}</a>
                </ng-container>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col col-66">
              <div class="box">
                <div class="box-title box-title-with-icon">
                <span class="box-icon">
                  <mat-icon>mail_outline</mat-icon>
                </span>
                  Email 2:
                </div>
                <ng-container *ngIf="student.emailAddress2; else notExist">
                    <a [href]="'mailto:' + student.emailAddress2.value" class="box-info">{{student.emailAddress2.value}}</a>
                </ng-container>
              </div>
            </div>
            <div class="col col-33">
              <div class="row">
                <div class="col col-50">
                  <div class="box">
                    <div class="box-title box-title-with-icon">
                      <span class="box-icon">
                        <mat-icon>calendar_today</mat-icon>
                      </span>
                      DOB:
                    </div>
                    <ng-container *ngIf="student.dateOfBirth; else notExist">
                      <div class="box-info">{{student.dateOfBirth | date : 'shortDate'}}</div>
                    </ng-container>
                  </div>
                </div>
                <div class="col col-50">
                  <div class="box" *ngIf="(studentDetail$ | async) as studentDetail">
                    <div class="box-title">Gender:</div>
                    <ng-container *ngIf="studentDetail.gender; else notExist">
                      <div class="box-info">{{studentDetail.gender}}</div>
                    </ng-container>
                  </div>
                </div> 
              </div>
            </div>
          </div>
        </div>

        <ng-template #notExist>
          <div class="box-info">-</div>
        </ng-template>

        <div class="dropdown" *ngIf="(studentDetail$ | async) as studentDetail">
          <div class="dropdown-header" (click)="isOpenStudentProfile = !isOpenStudentProfile">
            <div class="icon">
              <mat-icon>add</mat-icon>
            </div>

            <div class="title">Student Profile History</div>
          </div>

          <ng-container *ngIf="isOpenStudentProfile">
            <div class="dropdown-body">
              <div class="main-table" *ngFor="let profile of studentDetail.profileHistory">
                <div class="row">
                  <div class="col col-100">
                    <div class="box">
                      <div class="box-title">Last Updated:</div>
                      <div class="box-info">{{profile.updateDt | date: 'shortDate'}}</div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col col-50">
                    <div class="box">
                      <div class="box-title">Student ID:</div>
                      <ng-container *ngIf="profile.studentNumber; else notExist">
                        <div class="box-info">{{profile.studentNumber}}</div>
                      </ng-container>
                    </div>
                  </div>

                  <div class="col col-50">
                    <div class="box">
                      <div class="box-title">Student Type:</div>
                      <ng-container *ngIf="profile.studentType; else notExist">
                        <div class="box-info">{{profile.studentType}}</div>
                      </ng-container>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col col-33">
                    <div class="box">
                      <div class="box-title">Last Name:</div>
                      <ng-container *ngIf="profile.lastName; else notExist">
                        <div class="box-info">{{profile.lastName}}</div>
                      </ng-container>
                    </div>
                  </div>

                  <div class="col col-33">
                    <div class="box">
                      <div class="box-title">First Name:</div>
                      <ng-container *ngIf="profile.firstName; else notExist">
                        <div class="box-info">{{profile.firstName}}</div>
                      </ng-container>
                    </div>
                  </div>

                  <div class="col col-33">
                    <div class="box">
                      <div class="box-title">Middle Initial or Name:</div>
                      <ng-container *ngIf="profile.middleName; else notExist">
                        <div class="box-info">{{profile.middleName}}</div>
                      </ng-container>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col col-100">
                    <div class="box">
                      <div class="box-title">Address 1:</div>
                      <ng-container *ngIf="profile.addressLine1; else notExist">
                        <div class="box-info">{{profile.addressLine1}}</div>
                      </ng-container>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col col-33">
                    <div class="box">
                      <div class="box-title">Address 2:</div>
                      <ng-container *ngIf="profile.addressLine2; else notExist">
                        <div class="box-info">{{profile.addressLine2}}</div>
                      </ng-container>
                    </div>
                  </div>

                  <div class="col col-33">
                    <div class="box">
                      <div class="box-title">City:</div>
                      <ng-container *ngIf="profile.city; else notExist">
                        <div class="box-info">{{profile.city}}</div>
                      </ng-container>
                    </div>
                  </div>

                  <div class="col col-33">
                    <div class="row">
                      <div class="col col-50">
                        <div class="box">
                          <div class="box-title">State:</div>
                          <ng-container *ngIf="profile.region; else notExist">
                            <div class="box-info">{{profile.region}}</div>
                          </ng-container>
                        </div>
                      </div>
                      <div class="col col-50">
                        <div class="box">
                          <div class="box-title">Zip Code:</div>
                          <ng-container *ngIf="profile.postalCode; else notExist">
                            <div class="box-info">{{profile.postalCode}}</div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col col-66">
                    <div class="box">
                      <div class="box-title box-title-with-icon">
                      <span class="box-icon">
                        <mat-icon>mail_outline</mat-icon>
                      </span>
                        Email 1:
                      </div>
                      <ng-container *ngIf="profile.emailAddress; else notExist">
                        <div class="box-info">{{profile.emailAddress}}</div>
                      </ng-container>
                    </div>
                  </div>
                  <div class="col col-33">
                    <div class="box">
                      <div class="box-title box-title-with-icon">
                <span class="box-icon">
                  <mat-icon>phone</mat-icon>
                </span>
                        Phone:
                      </div>
                      <ng-container *ngIf="profile.phoneNumber; else notExist">
                        <div class="box-info">{{profile.phoneNumber}}</div>
                      </ng-container>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col col-66">
                    <div class="box">
                      <div class="box-title box-title-with-icon">
                <span class="box-icon">
                  <mat-icon>mail_outline</mat-icon>
                </span>
                        Email 2:
                      </div>
                      <ng-container *ngIf="profile.emailAddress2; else notExist">
                        <div class="box-info">{{profile.emailAddress2}}</div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>

        <div class="dropdown" *ngIf="(studentDetail$ | async) as studentDetail">
          <div class="dropdown-header" (click)="isOpenEnrollment = !isOpenEnrollment">
            <div class="icon">
              <mat-icon>add</mat-icon>
            </div>

            <div class="title">Enrollment History</div>
          </div>

          <ng-container *ngIf="isOpenEnrollment">
            <div class="dropdown-body">
              <table class="enrollment-table">
                <tr *ngFor="let enrollment of enrollments$ | async">
                  <th>Session: {{formatSessionDates(enrollment)}}</th>
                  <th>{{enrollment.providerCourseCode}}</th>
                  <th>{{enrollment.courseTitle}}</th>
                  <th><b>Status: </b>{{enrollment.status.description}}</th>
                  <th>
                    <mat-form-field class="full-list-sort" *ngIf="enrollment.status.memberAllowedStatuses.length>0"> 
                      <mat-select id="mat-select" placeholder="Edit Status" [compareWith]="compare" [value]="enrollment.requestedStatus"
                                  (selectionChange)="editStatus($event.value,enrollment.id)">
                        <mat-select-trigger *ngFor="let edit of enrollment.status.memberAllowedStatuses">
                          <mat-icon>edit</mat-icon>&nbsp;{{edit.description}}
                        </mat-select-trigger>
                        <mat-option  *ngFor="let edit of enrollment.status.memberAllowedStatuses" [value]="edit">
                          {{edit.description}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </th>
                </tr>
              </table>
            </div>
            <button type="button" class="submit-button icon-btn" mat-button (click)="submitStatusChanges()"
                  [disabled]="!(canSubmitChanges$ | async)">
              <mat-icon>save</mat-icon>Submit Changes
            </button>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</div>
