import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { Store, select } from "@ngrx/store";
import { first, finalize, filter, map } from "rxjs/operators";
import { IAppState } from 'app/store/state/app.state';
import { Institution } from '@admin/institution';
import * as fromInstitution from '@institution/store';
import { getInstitutionIdFromRoute } from 'app/helpers/routeParamHelper';
@Injectable()
export class InstitutionResolver implements Resolve<Institution>{
    isLoading = false;
    constructor(private store: Store<IAppState>){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
        const institutionId = getInstitutionIdFromRoute(route);
        
        return this.store 
            .pipe(
                select(fromInstitution.Selectors.Institution),
                map((institution) => {
                    return !!institution ? institution.id === institutionId: false
                }),
                map((institutionLoaded)=>{
                    if(!this.isLoading && !institutionLoaded){
                        this.isLoading = true;
                        this.store.dispatch(fromInstitution.Actions.LoadInstitution({institutionId}));
                    }
                    return true;
                }),
                filter(institutionLoaded => institutionLoaded),
                first(),
                finalize(()=>this.isLoading=false)
            )
    }
}