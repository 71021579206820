<div class="toolbar" [ngClass]="currentAccessRole.viewContext">
  <div class="toolbar-part logo-wrap">
    <toolbar-logo></toolbar-logo>
  </div>

  <div class="toolbar-part center-icon-menu" *ngIf="showToolbarButtons$ | async">
    <div class="toolbar-icons-group">
      <toolbar-action (onClick)="goToDashboard()"
                      [icon]="'dashboard'"
                      [tooltipTitle]="'Dashboard'"></toolbar-action>

      <toolbar-action *ngIf="showStudents$ | async" (onClick)="goToEnrollments()" 
                      [icon]="'how_to_reg'"
                      [tooltipTitle]="'Enrollment List'"></toolbar-action>

      <toolbar-action *ngIf="showMemberCourses$ | async" (onClick)="goToCourseList()" 
                      [icon]="'import_contacts'"
                      [tooltipTitle]="'Courses List'"></toolbar-action>

      <toolbar-action *ngIf="showStudents$ | async" (onClick)="goToStudentList()"
                      [icon]="'person'"
                      [tooltipTitle]="'Student List'"></toolbar-action>
    </div>
  </div>

  <div class="toolbar-part user-info-and-menu">
    <toolbar-user-info></toolbar-user-info>

    <div class="toolbar-icons-group">
      <toolbar-notifications [currentAccessRole]="currentAccessRole"></toolbar-notifications>

      <toolbar-menu>
        <div class="toolbar-menu">
          <toolbar-secondary-user-info
            [actionName]="'View Member Profile'"
            [logoUrl]="(currentCourseExchange$ | async)?.secondaryLogoUrl"></toolbar-secondary-user-info>

            <div class="toolbar-menu-links">
              <div *ngIf="(courseExchanges$ | async)?.length>1">
                <ng-container *ngFor="let courseExchange of courseExchanges$ | async">
                  <a (click)="changeCourseExchange(courseExchange.courseExchangeId)" class="toolbar-menu-link">
                    <span class="icon">
                      <img [src]="courseExchange.secondaryLogoUrl">
                    </span>
                    <span class="text">{{courseExchange.name}}</span>
                  </a>
                </ng-container>
              </div>
            </div>
          <div class="toolbar-menu-links">
            <ng-container *ngIf="showToolbarButtons$ | async">
              <ng-container *ngIf="showFees$ | async">
                <a (click)="goToFees()" class="toolbar-menu-link">
                <span class="icon">
                  <mat-icon>attach_money</mat-icon>
                </span>
                  <span class="text">View Fees</span>
                </a>
              </ng-container>

              <ng-container *ngIf="showCalendar$ | async">
                <a (click)="goToCalendar()" class="toolbar-menu-link">
                <span class="icon">
                  <mat-icon>calendar_today</mat-icon>
                </span>
                  <span class="text">Calendar</span>
                </a>
              </ng-container>

              <ng-container *ngIf="showStudents$ | async">
                <a (click)="goToEnrollments()" class="toolbar-menu-link">
                  <span class="icon">
                    <mat-icon>assignment_turned_in</mat-icon>
                  </span>
                  <span class="text">Enrollments</span>
                </a>
              </ng-container>

              <ng-container *ngIf="showBilling$ | async">
                <a (click)="goToBillingSummary()" class="toolbar-menu-link">
                <span class="icon">
                  <mat-icon>attach_money</mat-icon>
                </span>
                  <span class="text">Billing</span>
                </a>
              </ng-container>

              <ng-container *ngIf="showUsers$ | async">
                <a (click)="goToUserList()" class="toolbar-menu-link">
                <span class="icon">
                  <mat-icon>people_alt</mat-icon>
                </span>
                  <span class="text">Users</span>
                </a>
              </ng-container>

              <ng-container *ngIf="showDataMigration$ | async">
                <a (click)="openDataMigration()" class="toolbar-menu-link">
                <span class="icon">
                  <mat-icon>perm_data_setting</mat-icon>
                </span>
                  <span class="text">Data Migration</span>
                </a>
              </ng-container>
            </ng-container>

            <a (click)="logout()" class="toolbar-menu-link">
              <span class="icon">
                <mat-icon>exit_to_app</mat-icon>
              </span>
              <span class="text">Log out</span>
            </a>
          </div>
        </div>
      </toolbar-menu>
    </div>
  </div>
</div>
