import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {get, sortBy} from 'lodash';
import {ColumnMode} from '@swimlane/ngx-datatable';
import {compareFnToSortDates} from '../../../../helpers/compare-fn-to-sort-dates';

export interface NewEnrollmentListOfSelectedTableScheme {
  title: string;
  accessKey: string;
  isDate?: boolean;
  sortFlag?: boolean;
}

type TableScheme = NewEnrollmentListOfSelectedTableScheme;

@Component({
  selector: 'new-enrollment-list-of-selected',
  templateUrl: './new-enrollment-list-of-selected.component.html',
  styleUrls: ['./new-enrollment-list-of-selected.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NewEnrollmentListOfSelectedComponent implements OnInit, OnDestroy {
  @Input() list: any[];
  @Input() tableScheme: TableScheme[];
  @Output() remove: EventEmitter<any> = new EventEmitter<any>();

  sortedList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  filledTableScheme$: BehaviorSubject<TableScheme[]> = new BehaviorSubject<TableScheme[]>([]);

  ColumnMode = ColumnMode;

  constructor() {
  }

  ngOnInit(): void {
    this.sortedList$.next(this.list);
    this.filledTableScheme$.next(this.getFilledTableScheme());
  }

  ngOnDestroy(): void {
    this.sortedList$.complete();
    this.filledTableScheme$.complete();
  }

  private getFilledTableScheme(): TableScheme[] {
    return this.tableScheme.map((item) => {
      const {title, accessKey, isDate} = item;

      return {
        title: title ? title : 'Test',
        accessKey: accessKey ? accessKey : '',
        isDate: isDate ? isDate : false,
        sortFlag: false
      }
    })
  }

  extractValue(item: any, path: string): any {
    return get(item, path);
  }

  onRemove(removedItem: any): void {
    const newSortedList = this.sortedList$.value.filter(item => item !== removedItem);

    this.sortedList$.next(newSortedList);

    this.remove.emit(removedItem);
  }

  sortByText(item: TableScheme): void {
    const newSortedList = sortBy(this.sortedList$.value, item.accessKey)

    this.sortedList$.next(
      !item.sortFlag ? newSortedList : newSortedList.reverse()
    )

    this.updateSortFlag(item);
  }

  sortByDate = (item: TableScheme): void => {
    const newSortedList = this.sortedList$.value.sort((firstItem, secondItem) => {
      return compareFnToSortDates(firstItem[item.accessKey], secondItem[item.accessKey]);
    }).slice();

    this.sortedList$.next(
      !item.sortFlag ? newSortedList : newSortedList.reverse()
    );

    this.updateSortFlag(item);
  }

  private updateSortFlag(item: TableScheme): void {
    const newFilledTableScheme = this.filledTableScheme$.value.map(scheme => {
      if (item.title === scheme.title) {
        return {
          ...scheme,
          sortFlag: !scheme.sortFlag
        }
      }

      return {
        ...scheme,
        sortFlag: false
      };
    });

    this.filledTableScheme$.next(newFilledTableScheme);
  }
}
