import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {Member, DeSelectCoursesCommand, PreSelectCoursesCommand, AddInstitutionalCourseCodeCommand, RemoveInstitutionalCourseCodeCommand, MemberCourse} from '../models';
import { Institution } from 'app/admin/institution';
import { PopupService } from 'app/core/services/popup.service';
import { ElbertFile, BaseResponse } from 'app/core/models';
import { MemberCourseListItem } from '@member/memberCourse/models/memberCourseListItem.model';
import { CourseExchangeSummary, CourseExchangeFeeListItem } from '@shared/courseExchange/models';
import { InstitutionSummary } from '@shared/institution/models';
import { CreateEnrollmentsCommand } from '../models/commands/createEnrollment.command';
import { EnrollmentListItem } from '@member/enrollment/models/enrollmentListItem.viewModel';
import { ChangeEnrollmentStatusBulkCommand } from '../models/commands/changeEnrollmentStatus.command';
import { MemberMonthlyBill } from '@member/billing/models/memberMonthlyBill';
import { ConfigService } from '@core/services/config.service';
import { saveAs } from 'file-saver';
import {MemberDashboardViewModel} from '@shared/member/models';
import { MonthlyStatement } from '@core/models/monthlyStatement.viewModel';
import { StudentDataMergeCommand } from '@shared/student/commands/StudentDataMergeCommand';
import { EnrollmentMigrationCommand } from '../models/commands/enrollmentMigrationCommand';
import * as moment from 'moment';
@Injectable()
export class MemberService {
  private apiUrl:string='';
  private migrationUrl:string='';
  constructor(private http: HttpClient, private popupService: PopupService, private config:ConfigService) {
    this.config.apiUrl$.subscribe(result=>{
      this.apiUrl=`${result}/member`;
      this.migrationUrl=`${result}/migrationMember`;
    });
  }

  public getMemberById(memberId: string): Observable<Member>{
    return this.http.get<Member>(`${this.apiUrl}/${memberId}`);
  }

  public getInstitution(memberId: string): Observable<Institution>{
    return this.http.get<Institution>(`${this.apiUrl}/${memberId}/institution`);
  }
  public completeMemberSetup(memberId: string): Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.apiUrl}/${memberId}/completeMemberSetup`,{memberId});
  }
  public getMemberCourseListItems(memberId: string, startDate:Date, endDate:Date): Observable<MemberCourseListItem[]>{
    var startDateString = moment(startDate).format('YYYY-MM-DD');
    var endDateString = moment(endDate).format('YYYY-MM-DD');
    return this.http.get<MemberCourseListItem[]>(`${this.apiUrl}/${memberId}/coursesByDateRange/${startDateString}/${endDateString}`);
  }
  public getMemberCourses(memberId: string): Observable<MemberCourse[]>{
    return this.http.get<MemberCourse[]>(`${this.apiUrl}/${memberId}/memberCourses`);
  }
  public downloadSyllabus(memberId: string, courseId: string, file: ElbertFile) {    
    return this.http.get(`${this.apiUrl}/${memberId}/courses/syllabus/download/${courseId}`, { responseType: "blob" })  
      .subscribe((result: any) => {  
        if (result.type != 'text/plain') {  
          var blob = new Blob([result]);  
            
          saveAs(blob, file.fileName);  
        }  
      }  
      );  
  }  
  public getMemberCourseListItem(memberId: string, memberCourseListItemId: string): Observable<MemberCourseListItem>{
    return this.http.get<MemberCourseListItem>(`${this.apiUrl}/${memberId}/courses/${memberCourseListItemId}`);
  }
  public getFavouriteCoursesCount(memberId: string): Observable<number>{
    return this.http.get<number>(`${this.apiUrl}/${memberId}/courses/count`);
  }

  public preSelectCourses(memberId: string, command: PreSelectCoursesCommand ): Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.apiUrl}/${memberId}/courses/select`, command)
  }

  public deSelectCourses(memberId: string, command: DeSelectCoursesCommand): Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.apiUrl}/${memberId}/courses/deselect`,command);
  }

  public addInstitutionalCode(memberId: string, command: AddInstitutionalCourseCodeCommand): Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.apiUrl}/${memberId}/courses/addCode`, command);
  }

  public removeInstitutionalCode(memberId: string, command: RemoveInstitutionalCourseCodeCommand): Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.apiUrl}/${memberId}/courses/removeCode`, command);
  }

  public getCourseExchangeSummary(memberId: string): Observable<CourseExchangeSummary>{
    return this.http.get<CourseExchangeSummary>(`${this.apiUrl}/${memberId}/courseExchange`);
  }

  public getInstitutionSummary(institutionId: string): Observable<InstitutionSummary>{
    return this.http.get<InstitutionSummary>(`${this.apiUrl}/institution/${institutionId}`);
  }

  public getCourseExchangeFees(memberId: string): Observable<CourseExchangeFeeListItem[]>{
    return this.http.get<CourseExchangeFeeListItem[]>(`${this.apiUrl}/${memberId}/courseExchangeFee`);
  }

  public createEnrollments(memberId:string, command:CreateEnrollmentsCommand): Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.apiUrl}/${memberId}/enrollments`,command);
  }

  public getActiveEnrollments(memberId: string): Observable<EnrollmentListItem[]>{
    return this.http.get<EnrollmentListItem[]>(`${this.apiUrl}/${memberId}/enrollments`);
  }

  public getEnrollmentByDateRange(memberId: string, startDate: Date, endDate:Date): Observable<EnrollmentListItem[]>{
    var startDateString = moment(startDate).format('YYYY-MM-DD');
    var endDateString = moment(endDate).format('YYYY-MM-DD');
    return this.http.get<EnrollmentListItem[]>(`${this.apiUrl}/${memberId}/enrollmentsByDateRange/${startDateString}/${endDateString}`);
  }

  public getActiveEnrollmentsCount(memberId: string): Observable<number>{
    return this.http.get<number>(`${this.apiUrl}/${memberId}/enrollments/count`);
  }

  public submitStatusChanges(memberId: string, command: ChangeEnrollmentStatusBulkCommand): Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.apiUrl}/${memberId}/enrollments/changeStatuses`,command);
  }

  public getMonthlyStatements(memberId: string): Observable<MemberMonthlyBill[]>{
    return this.http.get<MemberMonthlyBill[]>(`${this.apiUrl}/${memberId}/billing/monthlyStatements/12`);
  }

  public getDashboardData(memberId: string, startDate:Date, endDate:Date): Observable<MemberDashboardViewModel>{
    var startDateString = moment(startDate).format('YYYY-MM-DD');
    var endDateString = moment(endDate).format('YYYY-MM-DD');
    return this.http.get<MemberDashboardViewModel>(`${this.apiUrl}/${memberId}/dashboard/${startDateString}/${endDateString}`);
  }

  public downloadMonthlyStatement(monthlyStatement: MonthlyStatement) {
    return this.http.get(`${this.apiUrl}/${monthlyStatement.ledgerId}/monthlyStatement/download/${monthlyStatement.id}`, { responseType: "blob" })  
      .subscribe((result: any) => {  
        if (result.type != 'text/plain') {  
          var blob = new Blob([result]);  
            
          saveAs(blob, monthlyStatement.statement.fileName);  
        }  
      }  
      );  
  }

  public mergeStudentData(memberId: string,institutionId: string, commands: StudentDataMergeCommand[]): Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.migrationUrl}/${memberId}/${institutionId}/students`,commands);
  }

  public getStudentData(memberId: string, institutionId: string): Observable<any>{
    return this.http.get<any>(`${this.migrationUrl}/${memberId}/${institutionId}/students`);
  }

  public getMemberCourseData(memberId: string, institutionId: string):Observable<any>{
    return this.http.get<any>(`${this.migrationUrl}/${memberId}/${institutionId}/memberCourses`);
  }

  public enrollmentMigrationData(memberId: string, institutionId: string, commands: EnrollmentMigrationCommand[]): Observable<string>{
    return this.http.post<string>(`${this.migrationUrl}/${memberId}/${institutionId}/enrollments`,commands);
  }

  public getEnrollmentMigrationData(memberId: string, institutionId: string): Observable<any>{
    return this.http.get<any>(`${this.migrationUrl}/${memberId}/${institutionId}/enrollments`);
  }

  public getGradeData(memberId: string, institutionId: string): Observable<any>{
    return this.http.get<any>(`${this.migrationUrl}/${memberId}/${institutionId}/grades`);
  }

  public ImportBulkStudentJson(path: string): Observable<any>{
    return this.http.get<any>(`${this.migrationUrl}/ImportBulkStudentJson?path=`+path);
  }
}
