import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { Store, select } from "@ngrx/store";
import { tap, first, finalize, filter, map } from "rxjs/operators";
import { IAppState } from 'app/store/state/app.state';
import * as fromRefData from '@refData';
import { CourseCategory } from '@refData';
@Injectable()
export class CourseCategoriesResolver implements Resolve<CourseCategory[]>{
    isLoading = false;
    constructor(private store: Store<IAppState>){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
        return this.store 
            .pipe(
                select(fromRefData.Selectors.courseCategories),
                map((courseCategories)=>{
                    if(!!courseCategories && courseCategories.length>0){
                        this.store.dispatch(fromRefData.Actions.loadCourseCategoriesSuccess({courseCategories}));
                    }else if(!this.isLoading){
                        this.isLoading = true;
                        this.store.dispatch(fromRefData.Actions.LoadCourseCategories({forceReload:false}));
                    }
                    return true;
                }),
                filter(courseCategoriesLoaded => courseCategoriesLoaded),
                first(),
                finalize(()=>this.isLoading=false)
            )
    }
}