import {NgModule} from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import * as fromEnrollment from '@courseExchange/enrollment/store';

@NgModule({
  imports: [
      StoreModule.forFeature('courseExchange/enrollments', fromEnrollment.enrollmentsReducer),
      EffectsModule.forFeature([...fromEnrollment.EFFECTS])
  ],
  providers: [...fromEnrollment.RESOLVERS]
})
export class CourseExchangeEnrollmentStoreModule {
}
