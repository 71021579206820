import {Injectable} from '@angular/core';
import {Actions, ofType, createEffect} from '@ngrx/effects';
import { switchMap, map, catchError, concatMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import * as fromCourseExchangeDashboard from '@courseExchange/dashboard/store';
import { CourseExchangeService } from '@shared/courseExchange/services/course-exchange.service';
import * as fromInstitution from '@institution/store';
import * as fromCourseExchange from '@courseExchange/store';
import { Store, select } from '@ngrx/store';
import { IAppState } from 'app/store/state/app.state';
@Injectable()
export class CourseExchangeDashboardEffects {
  constructor(private actions$: Actions, private courseExchangeService: CourseExchangeService,private store: Store<IAppState>) 
  {
  }

  loadCourseExchangeDashboardData$ = createEffect(() => {
    let courseExchangeId ='';
    return this.actions$.pipe(
        ofType(fromCourseExchangeDashboard.Actions.LoadDashboardData),
        /** An EMPTY observable only emits completion. Replace with your own observable stream */
        switchMap((action) => {
          const {courseExchangeId,startDate,endDate} = action;
          if(courseExchangeId===''){
            return of({
              courseExchangeId,
              pendingInstitutions: 0,
              memberInstitutions: 0,
              providerInstitutions: 0,
              totalCourses: 0,
              newCourses: 0,
              totalEnrollments: 0,
              newEnrollments: 0
            })
          }
          return this.courseExchangeService.getDashboardData(courseExchangeId,startDate,endDate);
        }),
        map((data) => {
          return fromCourseExchangeDashboard.Actions.LoadDashboardDataSuccess({data});
        }),
        catchError((err) => of(fromCourseExchangeDashboard.Actions.LoadDashboardDataError({message: err.message})))
    );
  });
}
