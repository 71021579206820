import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ViewChild
} from '@angular/core';
import {IControlsConfig} from '../../../../models/shared';
import {UntypedFormBuilder, UntypedFormGroup, FormGroupDirective, Validators} from '@angular/forms';
import {hasErrorForm} from '../../../../helpers/has-error-form';
import {validateStartEndDates} from '../../../../helpers/validate-start-end-dates';
import {fillControlsConfig} from '../../../../helpers/fill-controls-config';
import {AcademicYear, Semester} from '../../../calendar/models';
import {ChecklistItemType} from '../../../../core/models/checklistItemType.enum';
import {MatDialog} from '@angular/material/dialog';
import {AddSemesterCommand, EditSemesterCommand as UpdateSemesterCommand} from '@shared/calendar/commands';
import {
  WizardSkipDialogContainerComponent,
  IWizardSkipDialogData
} from '../wizard-skip-dialog-container/wizard-skip-dialog-container.component';
import {NavigationType} from '@shared/wizard/constants/navigateType.enum';
import {Moment} from 'moment';
import * as moment from 'moment';
import {checkDateInRange} from 'app/helpers/check-date-in-range';
import {v4 as uuidv4} from 'uuid';

type IFormFields = Pick<Semester, 'code' | 'startDate' | 'endDate'>;

type ILocalControlsConfig = IControlsConfig<IFormFields>;

@Component({
  selector: 'wizard-calendar-step-add-semester',
  templateUrl: './wizard-calendar-step-add-semester.component.html',
  styleUrls: ['../../styles/common-wizard-step.scss', './wizard-calendar-step-add-semester.component.scss']
})
export class WizardCalendarStepAddSemesterComponent implements OnInit {
  @ViewChild(FormGroupDirective) formDirective;
  form: UntypedFormGroup;
  @Input() semester: Semester;
  @Input() institutionId: string;
  @Input() academicYear: AcademicYear;
  @Input() showSessions: boolean;
  @Output() addSemester: EventEmitter<AddSemesterCommand> = new EventEmitter<AddSemesterCommand>();
  @Output() editSemester: EventEmitter<UpdateSemesterCommand> = new EventEmitter<UpdateSemesterCommand>();
  @Output() navigate: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.refreshForm();
  }

  refreshForm(): void {
    this.form = this.formBuilder.group(this.shapeControlsConfig(), {
      validators: this.checkDates
    });
  }

  private checkDates = (group: UntypedFormGroup): void => {
    if (!this.form) return;

    const {endDate: {value: endDate}, startDate: {value: startDate}} = group.controls;

    if (this.academicYear) {
      const {startDate: minDate, endDate: maxDate} = this.academicYear;

      const checkResultStartDateInRangeAcademicYear = this.validateStartDateInRangeAcademicYear(minDate, maxDate, startDate);
      const checkResultEndDateInRangeAcademicYear = this.validateEndDateInRangeAcademicYear(minDate, maxDate, endDate);

      if (checkResultStartDateInRangeAcademicYear && checkResultEndDateInRangeAcademicYear) {
        this.validateStartEndDates(startDate, endDate);
      }
    } else {
      this.validateStartEndDates(startDate, endDate);
    }
  }

  private validateStartDateInRangeAcademicYear(minDate: Date, maxDate: Date, date: Moment | null): boolean {
    const checkResult = checkDateInRange(minDate, maxDate, date);

    const errors = checkResult ? null : {startDateNotWithin: true};
    this.form.get('startDate').setErrors(errors);

    return checkResult;
  }

  private validateEndDateInRangeAcademicYear(minDate: Date, maxDate: Date, date: Moment | null): boolean {
    const checkResult = checkDateInRange(minDate, maxDate, date);

    const errors = checkResult ? null : {endDateNotWithin: true};

    this.form.get('endDate').setErrors(errors);

    return checkResult;
  }

  private validateStartEndDates(startDate: Moment | null, endDate: Moment | null): void {
    const checkResult = validateStartEndDates(startDate, endDate);

    const errors = checkResult ? null : {lessStartDate: true};

    this.form.get('endDate').setErrors(errors);
  }

  errorMessageValidateDatesInRangeAcademicYear(prefix: string): string {
    const startDate = moment(this.academicYear.startDate).format('MM/DD/YYYY');
    const endDate = moment(this.academicYear.endDate).format('MM/DD/YYYY');

    return `The ${prefix} is not in the range of academic year (${startDate} - ${endDate})`;
  }

  private shapeControlsConfig(): ILocalControlsConfig {
    const initialControlsConfig: ILocalControlsConfig = {
      code: ['', Validators.required],
      startDate: [null],
      endDate: [null]
    };

    if (this.semester) {
      return fillControlsConfig<IFormFields>(initialControlsConfig, this.semester);
    }

    return initialControlsConfig;
  }

  hasError = (controlName: keyof IFormFields, errorName: string) => hasErrorForm(controlName, errorName, this.form);

  onKeyDownForm(event): void {
    if (event.keyCode === 13) {
      event.preventDefault();
      this.onSubmit(true);
    }
  }

  onSubmit = (toNextStep: boolean) => {
    if (this.form.valid) {
      const {code, startDate, endDate} = this.form.value;
      const id = this.semester ? this.semester.id : '';
      const academicYearId = this.academicYear.id;

      if (id === '') {
        const addCommand: AddSemesterCommand = {
          id: uuidv4(),
          academicYearId,
          code,
          startDate,
          endDate
        }

        this.addSemester.emit(addCommand);
      } else {
        const editCommand: UpdateSemesterCommand = {
          id,
          code,
          startDate,
          endDate
        }

        this.editSemester.emit(editCommand);
      }

      if (toNextStep) {
        this.navigate.emit(NavigationType.Forward)
      } else {
        this.reset()
      }
    }
  }

  reset(): void {
    this.formDirective.resetForm();
  }

  goBack = () => {
    this.navigate.emit(NavigationType.Back);
  }

  skip = () => {
    this.dialog.open<WizardSkipDialogContainerComponent, IWizardSkipDialogData>(WizardSkipDialogContainerComponent, {
      data: {
        itemType: ChecklistItemType.Calendar_AddSemesters,
        toNextStepCb: this.toNextStep
      }
    });
  }

  toNextStep = () => {
    this.navigate.emit(NavigationType.Skip);
  }
}
