<div class="wizard-step wizard-contacts-step">
  <ng-container>
    <div class="title">
      Add New User: Provost or Vice President
    </div>

    <div class="description">Each institution must have primary contacts for accounting, president, and provost or vice president.</div>

    <form class="form" [formGroup]="form" (keydown)="onKeyDownForm($event)">
      <div class="fields">
        <div class="field">
          <mat-form-field appearance="outline" class="mt-8" fxFill>
            <mat-label>Dept</mat-label>
            <input
              matInput
              name="department"
              formControlName="department">
            <mat-error *ngIf="hasError('department', 'required')">This field is required</mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field appearance="outline" class="mt-8" fxFill>
            <mat-label>Title</mat-label>
            <input
              matInput
              name="title"
              formControlName="title">
            <mat-error *ngIf="hasError('title', 'required')">This field is required</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="fields">
        <div class="field">
          <mat-form-field appearance="outline" class="mt-8" fxFill>
            <mat-label>First Name</mat-label>
            <input
              matInput
              name="firstName"
              formControlName="firstName">
            <mat-error *ngIf="hasError('firstName', 'required')">This field is required</mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field appearance="outline" class="mt-8" fxFill>
            <mat-label>Last Name</mat-label>
            <input
              matInput
              name="lastName"
              formControlName="lastName">
            <mat-error *ngIf="hasError('lastName', 'required')">This field is required</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="fields">
        <div class="field">
          <mat-form-field appearance="outline" class="mt-8" fxFill>
            <mat-label>Phone</mat-label>
            <input
              matInput
              name="phoneNumber"
              formControlName="phoneNumber">
            <mat-error *ngIf="hasError('phoneNumber', 'required')">This field is required</mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field appearance="outline" class="mt-8" fxFill>
            <mat-label>Email</mat-label>
            <input
              matInput
              uniqueEmail
              [userId]="getUserId()"
              [initialValue]="getInitialEmail()"
              name="emailAddress"
              formControlName="emailAddress">
            <mat-error *ngIf="hasError('emailAddress', 'emailExist')">Email already exists</mat-error>
            <mat-error *ngIf="hasError('emailAddress', 'required')">This field is required</mat-error>
          </mat-form-field>
        </div>

        <div class="field">
          <mat-form-field appearance="outline" class="mt-8" fxFill>
            <mat-label>User Role</mat-label>
            <mat-select [value]="'Provost'" formControlName="institutionRole">
              <mat-option *ngFor="let role of institutionRoles" [value]="role.description">
                {{role.description}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="hasError('institutionRole', 'required')">This field is required</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="form-actions-continue">
        <span class="skip" (click)="skip()">skip this step</span>

        <!-- <button mat-raised-button
                (click)="onSubmit(true)"
                [disabled]="isSending"
                type="submit">
          Add Additional User
        </button> -->
      </div>

      <div class="form-actions">
        <button mat-stroked-button
                (click)="goBack()">
          Back
        </button>

        <button mat-flat-button
                (click)="onSubmit(false)"
                [disabled]="isSending"
                type="submit">
          Next
        </button>
      </div>
    </form>
  </ng-container>

  <ng-template #loading>
    ...loading
  </ng-template>
</div>

