    import { Injectable, Provider } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { Store, select } from "@ngrx/store";
import { first, finalize, filter, map, switchMap } from "rxjs/operators";
import { IAppState } from 'app/store/state/app.state';
import * as fromMember from '@member/store';
import { MemberService } from '@shared/member/services';
import { Member } from '@shared/member/models';
@Injectable()
export class MemberResolver implements Resolve<Member>{
    isLoading = false;
    constructor(private store: Store<IAppState>, private memberService: MemberService){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
        const memberId = route.params['memberId'];
        return this.store 
            .pipe(
                select(fromMember.Selectors.Member),
                map((member) => {
                    var result = !!member && member.id === memberId;
                    if(result) this.store.dispatch(fromMember.Actions.LoadMemberSuccess({member}));
                    return result; 
                }),
                map((memberIsLoaded)=>{
                    if(!this.isLoading && !memberIsLoaded){
                        this.isLoading = true;
                        this.store.dispatch(fromMember.Actions.LoadMember({memberId}));
                    }
                    return true;
                }),
                filter(memberLoaded => memberLoaded),
                first(),
                finalize(()=>this.isLoading=false)
            )
    }
}