import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Student} from '../../../../shared/student/models';
import { StudentDetailsViewModel } from 'app/shared/student/models/studentDetails.viewModel';
import { Observable } from 'rxjs';
import { IAppState } from 'app/store/state/app.state';
import { Store, select } from '@ngrx/store';
import * as fromStudent from '@member/students/store';
import * as fromMemberDashboard from '@member/dashboard/store';
import { BaseListItem } from '@core/models';
import { EnrollmentListItemForView } from '@member/enrollment';
import * as moment from 'moment';

@Component({
  selector: 'app-student-detail',
  templateUrl: './student-detail.component.html',
  styleUrls: ['./student-detail.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StudentDetailComponent implements OnInit {
  student$: Observable<Student>;
  studentDetail$: Observable<StudentDetailsViewModel>;
  canSubmitChanges$: Observable<boolean>;
  enrollments$: Observable<EnrollmentListItemForView[]>;

  isOpenEnrollment = false;
  isOpenStudentProfile = false;

  constructor(
    private store: Store<IAppState>
  ) {
  }

  ngOnInit(): void {
    this.student$ = this.store.pipe(select(fromStudent.Selectors.CurrentStudent));
    this.studentDetail$ = this.store.pipe(select(fromStudent.Selectors.CurrentStudentDetails));
    this.enrollments$ = this.store.pipe(select(fromStudent.Selectors.Enrollments));
    this.canSubmitChanges$ = this.store.pipe(select(fromStudent.Selectors.CanSubmitChanges));
  }

  goToMemberDashboard(): void {
    this.store.dispatch(fromMemberDashboard.Navigate.Dashboard({}));
  }

  editStudent(): void {
    this.store.dispatch(fromStudent.Navigate.StudentEdit({}));
  }

  compare(o1: any, o2: any): boolean {
    return o1.id === o2.id;
  }
  
  editStatus(requestedStatus: BaseListItem, enrollmentId: string): void{
    this.store.dispatch(fromStudent.Actions.EditStatus({enrollmentId, requestedStatus}));
  }

  submitStatusChanges(): void{
    this.store.dispatch(fromStudent.Actions.SubmitStatusChanges());
  }

  formatSessionDates(enrollment: EnrollmentListItemForView): string{
    var startDate = new Date(enrollment.sessionStartDate2.year, enrollment.sessionStartDate2.month-1, enrollment.sessionStartDate2.day);
    var endDate = new Date(enrollment.sessionEndDate2.year, enrollment.sessionEndDate2.month-1, enrollment.sessionEndDate2.day);
    return `${moment(startDate).format('MM/DD/YYYY')} - ${moment(endDate).format('MM/DD/YYYY')}`;
  }
}
