import { Component,Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { MemberService } from '@shared/member/services';
import { CourseService } from '@shared/provider/services/course.service';
export interface BulkMergeDataDialogData {
  typename: string;
}
@Component({
  selector: 'app-bulk-data-migration-dialog',
  templateUrl: './bulk-data-migration-dialog.component.html',
  styleUrls: ['./bulk-data-migration-dialog.component.scss']
})
export class BulkDataMigrationDialogComponent implements OnInit {
  
  dialogTitle:string;
  
  form: UntypedFormGroup = this.formBuilder.group({
    path: ['', Validators.required]
  });
  constructor(public matDialogRef: MatDialogRef<BulkDataMigrationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public matDialogData: BulkMergeDataDialogData,
    private formBuilder: UntypedFormBuilder,
    private memberService: MemberService,
    private courseService: CourseService) { 
    this.matDialogRef.addPanelClass('app-bulk-data-migration-dialog');
  }

  ngOnInit(): void {
    this.dialogTitle = this.matDialogData.typename;
  }
  close(): void {
    this.matDialogRef.close();
  }
  save():void{
    if (this.form.valid){
      const data = this.form.value;
      if(this.dialogTitle == "Students")
      {
        this.memberService.ImportBulkStudentJson(data.path).pipe().subscribe();
      }
      else if(this.dialogTitle == "Course")
      {
        this.courseService.ImportBulkCourseJson(data.path).pipe().subscribe();
      }
      this.matDialogRef.close();
     }
  }
}
