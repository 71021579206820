import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SummaryItem } from 'app/core/models/summaryItem';
import { CourseExchangeListItem, CourseExchange, CourseExchangePolicy, CourseExchangeInstitutionListItem, CourseExchangeSummary, CourseExchangeDashboardViewModel } from '../models';
import { map, tap } from 'rxjs/operators';
import { UserListItem } from 'app/shared/user/models';
import { BaseResponse, ElbertFile } from 'app/core/models';
import { EditCourseExchangeFeeCommand } from '../commands/updateCourseExchangeFee.command';
import { CourseExchangeFeeListItem } from '../models/courseExchangeFeeListItem';
import { AddCourseExchangeFeeCommand } from '../commands';
import { PopupService } from 'app/core/services/popup.service';
import { saveAs } from 'file-saver';
import { ApproveProviderCourseCommand } from '../commands/approveProviderCourse.command';
import { CourseExchangeInstitutionsWidgetViewModel } from '@courseExchange/dashboard/widgets/courseExchangeInstitutions-widget/courseExchangeInstitutions-widget.viewModel';
import { UpdateCourseExchangeCommand } from '@admin/course-exchange/commands';
import { UploadCourseExchangeCommandLogo } from '@admin/course-exchange/commands/uploadCourseExchangeLogo.command';
import { CourseExchangeCoursesWidgetViewModel } from '@courseExchange/dashboard/widgets/courseExchangeCourses-widget/courseExchangeCourses-widget.viewModel';
import { ConfigService } from '@core/services/config.service';
import { EnrollmentListItem } from '@member/enrollment';
import { UpdateEnrollmentFeeCommand } from '@courseExchange/enrollment/models/updateEnrollmentFee.command';
import { MonthlyEarningsStatement } from '@courseExchange/billing/models';
import { MonthlyStatement } from '@core/models/monthlyStatement.viewModel';
import { AddInstitutionCommand } from '@admin/institution';
import { AddMemberCommand } from '@shared/member/models';
import { AddProviderCommand } from '@shared/provider/commands';
import * as moment from 'moment';
import { ChangeEnrollmentStatusBulkCommand } from '@shared/member/models/commands/changeEnrollmentStatus.command';

@Injectable()
export class CourseExchangeService {
  private apiUrl:string='';
  constructor(private http:HttpClient, private popupService: PopupService, private config:ConfigService){
    this.config.apiUrl$.subscribe(result=>this.apiUrl=`${result}/courseExchange`);
  }

  public getCourseExchangeList(): Observable<Array<CourseExchangeListItem>>{
    return this.http.get<any>(`${this.apiUrl}`)
      .pipe(
        map(data => data)
      );
  }

  public getCourseExchangeById(courseExchangeId: string): Observable<CourseExchange>{
    return this.http.get<CourseExchange>(`${this.apiUrl}/${courseExchangeId}`);
  }

  public getCourseExchangeSummaryById(courseExchangeId: string): Observable<CourseExchangeSummary>{
    return this.http.get<CourseExchangeSummary>(`${this.apiUrl}/${courseExchangeId}/summary`);
  }

  public approveProviderCourses(courseExchangeId: string, command: ApproveProviderCourseCommand): Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.apiUrl}/${courseExchangeId}/courses/approve`,command)
      .pipe(
        tap(response => this.popupService.handleBaseResponse(response))
      );
  }

  public getCourseExchangePolicies(courseExchangeId: string): Observable<CourseExchangePolicy[]>{
    return this.http.get<CourseExchangePolicy[]>(`${this.apiUrl}/${courseExchangeId}/courseExchangePolicies`);
  }

  public uploadCourseExchangePolicy(courseExchangeId: string, courseExchangePolicyId: string, policyFile: File):Observable<BaseResponse>{
    const formData = new FormData();
    formData.append('policyFile', policyFile, policyFile.name);
    formData.append('courseExchangePolicyId', courseExchangePolicyId);
    return this.http.post<BaseResponse>(`${this.apiUrl}/${courseExchangeId}/courseExchangePolicy`, formData);
  }

  public downloadCourseExchangePolicy(courseExchangePolicy: CourseExchangePolicy) {
    return this.http.get(`${this.apiUrl}/courseExchangePolicy/download/${courseExchangePolicy.id}`, { responseType: "blob" })
      .subscribe((result: any) => {
        if (result.type != 'text/plain') {
          var blob = new Blob([result]);

          saveAs(blob, courseExchangePolicy.policyFile.fileName);
        }
      }
      );
  }
  public getCourseExchangeInstitutions(courseExchangeId: string): Observable<CourseExchangeInstitutionListItem[]>{
    return this.http.get<CourseExchangeInstitutionListItem[]>(`${this.apiUrl}/${courseExchangeId}/institutions`);
  }
  public getCourseExchangeInstitution(courseExchangeId: string, institutionId: string): Observable<CourseExchangeInstitutionListItem>{
    return this.http.get<CourseExchangeInstitutionListItem>(`${this.apiUrl}/${courseExchangeId}/institutions/${institutionId}`);
  }

  public getProviderSummaries(courseExhangeId: string): Observable<SummaryItem[]>{
    return this.http.get<SummaryItem[]>(`${this.apiUrl}/${courseExhangeId}/providerSummaries`);
  }

  public getUserList(courseExchangeId: string): Observable<UserListItem[]>{
    return this.http.get<UserListItem[]>(`${this.apiUrl}/${courseExchangeId}/users`);
  }

  public getCourseExchangeFees(courseExchangeId: string): Observable<CourseExchangeFeeListItem[]>{
    return this.http.get<CourseExchangeFeeListItem[]>(`${this.apiUrl}/${courseExchangeId}/courseExchangeFee`);
  }
  public addCourseExchangeFee(courseExchangeId: string, command: AddCourseExchangeFeeCommand): Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.apiUrl}/${courseExchangeId}/courseExchangeFee`, command);
  }
  public updateCourseExchangeFee(courseExchangeId: string, command: EditCourseExchangeFeeCommand): Observable<BaseResponse>{
    return this.http.put<BaseResponse>(`${this.apiUrl}/${courseExchangeId}/courseExchangeFee`, command);
}
  public deleteCourseExchangeFee(courseExchangeId: string, courseExchangeFeeId: string): Observable<BaseResponse>{
    return this.http.delete<BaseResponse>(`${this.apiUrl}/${courseExchangeId}/courseExchangeFee/${courseExchangeFeeId}`);
  }
  public completeCourseExchangeSetup(courseExchangeId: string): Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.apiUrl}/${courseExchangeId}/completeCourseExchangeSetup`,{courseExchangeId});
  }
  public approveProvider(courseExchangeId: string, providerId: string): Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.apiUrl}/${courseExchangeId}/approveProvider`,{providerId});
  }
  public approveMember(courseExchangeId: string, memberId: string): Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.apiUrl}/${courseExchangeId}/approveMember`,{memberId});
  }
  uploadBankInfo(bankInfo: File, courseExchangeId: string): Observable<BaseResponse> {
    const formData = new FormData();
    formData.append('bankInfo', bankInfo, bankInfo.name);
    formData.append('courseExchangeId', courseExchangeId);
    return this.http.post<BaseResponse>(`${this.apiUrl}/${courseExchangeId}/uploadBankInfo`, formData);
  }

  public downloadBankInfo(courseExchangeId: string, bankIfo:ElbertFile) {
    return this.http.get(`${this.apiUrl}/${courseExchangeId}/downloadBankInfo`, { responseType: "blob" })
      .subscribe((result: any) => {
        if (result.type != 'text/plain') {
          var blob = new Blob([result]);

          saveAs(blob, bankIfo.fileName);
        }
    });
  }

  public uploadLogo(command: UploadCourseExchangeCommandLogo): Observable<BaseResponse> {
    const {image, courseExchangeId, logoType, UpdateCourseExchangeCommand: updateCourseExchangeCommand} = command;
    const formData = new FormData();
    formData.append('logo', image, image.name);
    formData.append('courseExchangeId', courseExchangeId);
    formData.append("logoType", logoType);
    return this.http.post<BaseResponse>(`${this.apiUrl}/${courseExchangeId}/uploadLogo`, formData);
  }
  public getCourseExchangeInstitutionsWidgetData(courseExchangeId: string): Observable<CourseExchangeInstitutionsWidgetViewModel>{
    return this.http.get<CourseExchangeInstitutionsWidgetViewModel>(`${this.apiUrl}/${courseExchangeId}/institutionsWidgetData`)
  }

  public getCourseExchangeCoursesWidgetData(courseExchangeId: string): Observable<CourseExchangeCoursesWidgetViewModel>{
    return this.http.get<CourseExchangeCoursesWidgetViewModel>(`${this.apiUrl}/${courseExchangeId}/coursesWidgetData`)
  }

  public updateCourseExchange(command: UpdateCourseExchangeCommand): Observable<BaseResponse>{
    return this.http.put<BaseResponse>(`${this.apiUrl}/${command.id}`, command);
  }

  public getActiveEnrollments(courseExchangeId: string): Observable<EnrollmentListItem[]>{
    return this.http.get<EnrollmentListItem[]>(`${this.apiUrl}/${courseExchangeId}/enrollments`);
  }
  
  public getEnrollmentByAcademicYear(courseExchangeId: string, academicYearId: string): Observable<EnrollmentListItem[]>{
    return this.http.get<EnrollmentListItem[]>(`${this.apiUrl}/${courseExchangeId}/enrollmentsByAcademicYear/${academicYearId}`);
  }

  public getEnrollmentByDateRange(courseExchangeId: string, startDate: Date, endDate:Date): Observable<EnrollmentListItem[]>{
    var startDateString = moment(startDate).format('YYYY-MM-DD');
    var endDateString = moment(endDate).format('YYYY-MM-DD');
    return this.http.get<EnrollmentListItem[]>(`${this.apiUrl}/${courseExchangeId}/enrollmentsByDateRange/${startDateString}/${endDateString}`);
  }

  public updateEnrollmentFee(courseExchangeId: string, command: UpdateEnrollmentFeeCommand): Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.apiUrl}/${courseExchangeId}/enrollments/updateFee`,command);
  }

  public getDashboardData(courseExchangeId: string, startDate:Date, endDate:Date): Observable<CourseExchangeDashboardViewModel>{
    var startDateString = moment(startDate).format('YYYY-MM-DD');
    var endDateString = moment(endDate).format('YYYY-MM-DD');
    return this.http.get<CourseExchangeDashboardViewModel>(`${this.apiUrl}/${courseExchangeId}/dashboard/${startDateString}/${endDateString}`);
  }

  public getMonthlyStatements(courseExchangeId: string): Observable<MonthlyEarningsStatement[]>{
    return this.http.get<MonthlyEarningsStatement[]>(`${this.apiUrl}/${courseExchangeId}/billing/monthlyStatements`);
  }

  public getMonthlyStatement(courseExchangeId: string, year: number, month: number): Observable<MonthlyEarningsStatement>{
    return this.http.get<MonthlyEarningsStatement>(`${this.apiUrl}/${courseExchangeId}/billing/monthlyPayout/${year}/${month}`);
  }

  public downloadMonthlyStatement(monthlyStatement: MonthlyStatement) {
    return this.http.get(`${this.apiUrl}/${monthlyStatement.ledgerId}/monthlyStatement/download/${monthlyStatement.id}`, { responseType: "blob" })  
      .subscribe((result: any) => {  
        if (result.type != 'text/plain') {  
          var blob = new Blob([result]);  
            
          saveAs(blob, monthlyStatement.statement.fileName);  
        }  
      });  
  }
  public activateInstitution(courseExchangeId: string, institutionId:string): Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.apiUrl}/${courseExchangeId}/institution/activate`,{courseExchangeId,institutionId});
  }
  public deactivateInstitution(courseExchangeId: string, institutionId:string): Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.apiUrl}/${courseExchangeId}/institution/deactivate`,{courseExchangeId,institutionId});
  }
  public deleteInstitution(courseExchangeId: string, institutionId:string): Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.apiUrl}/${courseExchangeId}/institution/delete`,{courseExchangeId,institutionId});
  }
  public addInstitution(courseExchangeId: string, command:AddInstitutionCommand): Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.apiUrl}/${courseExchangeId}/institution/add`,command);
  }
  public addMember(courseExchangeId: string, command:AddMemberCommand): Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.apiUrl}/${courseExchangeId}/member/add`,command);
  }
  public addProvider(courseExchangeId: string, command:AddProviderCommand): Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.apiUrl}/${courseExchangeId}/provider/add`,command);
  }
  public submitStatusChanges(courseExchangeId: string, command: ChangeEnrollmentStatusBulkCommand): Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.apiUrl}/${courseExchangeId}/enrollments/changeStatuses`,command);
  }
}
