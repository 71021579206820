import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { Store, select } from "@ngrx/store";
import { first, finalize, filter, map, concatMap, withLatestFrom } from "rxjs/operators";
import { IAppState } from 'app/store/state/app.state';
import * as fromProvider from '@provider/store';
import * as fromProviderCourses from '@provider/courses/store';
import { ProviderCourseListItem } from '@provider/courses/models/providerCourseListItem.model';

@Injectable()
export class ProviderCoursesResolver implements Resolve<ProviderCourseListItem[]>{
    isLoading = false;
    constructor(private store: Store<IAppState>){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
        const providerId = route.params["providerId"];
        return this.store 
            .pipe(
                select(fromProviderCourses.Selectors.ProviderCoursesForEffects),
                map((args)=> args.currentProviderId === providerId && args.areCoursesLoaded),
                map((coursesLoaded) => {
                    if(!this.isLoading && !coursesLoaded){
                        this.isLoading = true;
                        this.store.dispatch(fromProviderCourses.Actions.LoadCourses({providerId}));
                    }
                    return true;
                }),
                filter(policyTemplatesLoaded => policyTemplatesLoaded),
                first(),
                finalize(()=>this.isLoading=false)
            )
    }
}