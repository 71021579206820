<div class="toolbar-user-info">
  <div class="toolbar-avatar">
    <elb-avatar [name]="(currentUser$ | async)?.fullName"></elb-avatar>
  </div>
  <div class="toolbar-user-info-content">
    <div class="toolbar-username">{{(currentUser$ | async)?.fullName}}</div>
    <div class="toolbar-select">
      <mat-form-field appearance="outline">
        <mat-select placeholder="Admin"
                    (selectionChange)="changeAccessRole($event.value)"
                    mat-select [value]="currentAccessRoleId$ | async">
          <mat-option *ngFor="let role of accessRoles$ | async" [value]="role.id">
            {{role.descriptionForView}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>
