import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromMember from '@member/store';
import { MemberCourseState } from '.';
import { MemberCourseListItem } from '../models';
import { searchInText } from 'app/helpers/search-in-text';
import { sortBy } from 'sort-by-typescript';
import { MemberCourseType } from '@member/store/enums/memberCourseType';
import { CourseSessionDateItem } from '@shared/provider/models/courseSessionDateItem';
import * as moment from 'moment';
import {v4 as uuidv4} from 'uuid';

const selectMemberCourseState = createFeatureSelector<MemberCourseState>('member/courses');

export const MemberCourseListItems = createSelector(
  selectMemberCourseState,
  (state) => state.memberCourseListItems
)

export const CurrentFacultyCVs = createSelector(
  selectMemberCourseState,
  (state) => state.currentFacultyCVs
)

export const CombinedMemberCourseListItems = createSelector(
  selectMemberCourseState,
  (state) => state.combinedMemberCourseListItems
)

export const AreMemberCoursesLoaded = createSelector(
  selectMemberCourseState,
  (state) => state.areMemberCoursesLoaded
)

export const MemberCoursesForExport = createSelector(
  selectMemberCourseState,
  (state) => {
    return{
      memberCourses: state.combinedMemberCourseListItems,
      semesters: state.semesterFilters,
      academicYears: [],
      memberName: ''
    }
  }
)

export const SelectorForMemberCourses = createSelector(
  fromMember.Selectors.MemberId,
  selectMemberCourseState,
  (memberId, state)=>{
    return{
      currentMemberId: memberId,
      currentStartDate: state.startDate,
      currentEndDate: state.endDate
    }
  }
)

export const CurrentMemberCourseListItem = createSelector(
  selectMemberCourseState,
  (state) => state.currentMemberCourseListItem
)

export const InstitutionCourseCodes = createSelector(
  selectMemberCourseState,
  (state) => {
    const providerCourseId = !!state.currentMemberCourseListItem ? state.currentMemberCourseListItem.providerCourseId: "";
    const memberCourse =  state.memberCourses.find(x=>x.providerCourseId==providerCourseId && x.institutionalCourseCodes.length);
    if(memberCourse==null) return [];
    return memberCourse.institutionalCourseCodes.filter((n,i)=>memberCourse.institutionalCourseCodes.indexOf(n)===i);
  }
)

export const HasInstitutionCourseCodes = createSelector(
  InstitutionCourseCodes,
  (codes) => !!codes.length
)

export const MemberCourseSessions = (courseId: string) => createSelector(
  MemberCourseListItems,
  CurrentMemberCourseListItem,
  (memberCourseListItems, currentMemberCourse):CourseSessionDateItem[] => {
    const providerCourseId = courseId === '' ? currentMemberCourse.providerCourseId : courseId;
    return memberCourseListItems.filter(x=>x.providerCourseId===providerCourseId).map(memberCourse =>{
        return{
          code: memberCourse.sessionCode,
          startDate: memberCourse.startDate,
          startDate2: memberCourse.startDate2,
          endDate: memberCourse.endDate,
          endDate2: memberCourse.endDate2,
          availableSeats: memberCourse.availableSeats,
          memberFeeAmount: memberCourse.memberFeeAmount,
          description: `${memberCourse.sessionCode} ${moment(memberCourse.startDate).format('l')} - ${moment(memberCourse.endDate).format('l')}`
        };
    })
})

export const Sorts = createSelector(
  selectMemberCourseState,
  (state) => state.sorts
)

export const CurrentOrder = createSelector(
  selectMemberCourseState,
  (state) => !!state ? state.currentOrder : undefined
)

export const CurrentSort = createSelector(
  selectMemberCourseState,
  (state) => !!state ? state.currentSort : undefined
)


export const SearchText = createSelector(
  selectMemberCourseState,
  (state) => !!state ? state.searchText : undefined
)

export const PresetFilterType = createSelector(
  selectMemberCourseState,
  (state) => state.presetFilterType
)

export const SemesterFilters = createSelector(
  selectMemberCourseState,
  MemberCourseListItems,
  ({semesterFilters}, memberCourseListItems) => {
      const result = semesterFilters.map(semesterFilter=>{
          const semesterCount = memberCourseListItems.filter(x=>moment(x.startDate)
                                           .isBetween(semesterFilter.startDate,semesterFilter.endDate,undefined,'[]')).length;
          return{
              ...semesterFilter,
              descriptionForView : `${semesterFilter.description} (${semesterCount})`
          }
      });

      return result;
  }
)

export const SelectedSemesterFilters = createSelector(
  SemesterFilters,
  (filters) => filters.filter(x=>x.selected)
)
export const SemesterFilterCount = createSelector(
  SelectedSemesterFilters,
  (filters) => filters.length> 0? filters.length : null
)
export const CourseLevelFilters = createSelector(
  selectMemberCourseState,
  (state) => state.courseLevelFilters
)
export const CourseLevelFiltersForView = createSelector(
  MemberCourseListItems,
  CourseLevelFilters,
  (memberCourseListItems, courseLevelFilters) =>{
    
    return courseLevelFilters.filter(x=>memberCourseListItems.find(m=>!!m.level && m.level.id==x.id)).sort(sortBy('description')).map(filter => {
      const count = !!memberCourseListItems ? memberCourseListItems.filter(x=>!!x.level && x.level.id===filter.id).length : 0;
      return {
        ...filter,
        descriptionForView: `${filter.description} (${count})`
      }
    })
  }
)

export const SelectedCourseLevelFilters = createSelector(
  CourseLevelFiltersForView,
  (filters) => !!filters ? filters.filter(x=>x.selected) : []
)

export const CourseLevelFilterCount = createSelector(
  SelectedCourseLevelFilters,
  (filters) => filters.length>0 ? filters.length : null
)

export const ProviderFilters = createSelector(
  selectMemberCourseState,
  ({memberCourseListItems: memberCourses, providerFilters}) =>{

    return providerFilters.filter(x=>memberCourses.find(m=>m.providerId==x.id)).sort(sortBy('description')).map(filter => {
      const count = !!memberCourses ? memberCourses.filter(x=>x.providerId===filter.id).length : 0;
      return {
        ...filter,
        descriptionForView: `${filter.description} (${count})`
      }
    })
  }
)
export const SelectedProviderFilters = createSelector(
  ProviderFilters,
  (filters) => 
      !!filters ? filters.filter(x=>x.selected) : []
)
export const ProviderFilterCount = createSelector(
  SelectedProviderFilters,
  (filters) => 
      filters.length> 0? filters.length : null
)
export const CategoryFilters = createSelector(
  selectMemberCourseState,
  ({memberCourseListItems: memberCourses, categoryFilters}) =>{
    
    return categoryFilters.filter(x=>memberCourses.find(m=>!!m.category && m.category.id==x.id)).sort(sortBy('description')).map(filter => {
      const count = !!memberCourses ? memberCourses.filter(x=>!!x.category && x.category.id===filter.id).length: 0;
      return {
        ...filter,
        descriptionForView: `${filter.description} (${count})`
      }
    })
  }
)

export const SelectedCategoryFilters = createSelector(
  CategoryFilters,
  (filters) => !!filters ? filters.filter(x=>x.selected) : []
)
export const CategoryFilterCount = createSelector(
  SelectedCategoryFilters,
  (filters) => filters.length > 0 ? filters.length : null
)

export const SubCategoryFilters = createSelector(
  selectMemberCourseState,
  ({memberCourseListItems: memberCourses, subCategoryFilters}) =>{
    
    return subCategoryFilters.filter(x=>memberCourses.find(m=>!!m.subCategory && m.subCategory.id==x.id)).sort(sortBy('description')).map(filter => {
      const count = !!memberCourses ? memberCourses.filter(x=>!!x.subCategory && x.subCategory.id===filter.id).length: 0;
      return {
        ...filter,
        descriptionForView: `${filter.description} (${count})`
      }
    })
  }
)

export const SelectedSubCategoryFilters = createSelector(
  SubCategoryFilters,
  (filters) => !!filters ? filters.filter(x=>x.selected) : []
)
export const SubCategoryFilterCount = createSelector(
  SelectedSubCategoryFilters,
  (filters) => filters.length > 0 ? filters.length : null
)

export const TagFilters = createSelector(
  selectMemberCourseState,
  ({memberCourseListItems: memberCourses, tagFilters}) =>{
    
    return tagFilters.filter(x=>memberCourses.find(m=>m.tags.find(t=>t.id==x.id))).sort(sortBy('description')).map(filter => {
      const count = !!memberCourses ? memberCourses.filter(x=>x.tags.findIndex(tag=>tag.id===filter.id)!=1).length:0;
      return {
        ...filter,
        descriptionForView: `${filter.description} (${count})`
      }
    })
  }
)

export const SelectedTagFilters = createSelector(
  TagFilters,
  (filters) => !!filters ? filters.filter(x=>x.selected) : []
)
export const TagFilterCount = createSelector(
  SelectedTagFilters,
  (filters) => filters.length > 0 ? filters.length : null
)

export const SelectedFilters = createSelector(
  SelectedSemesterFilters,
  SelectedCourseLevelFilters,
  SelectedProviderFilters,
  SelectedCategoryFilters,
  SelectedSubCategoryFilters,
  SelectedTagFilters,
  (semesterFilters, courseLevelFilters, providerFilters,
  categoryFilters, subCategoryFilters, tagFilters) =>
     [...semesterFilters, ...courseLevelFilters, ...providerFilters,
    ...categoryFilters,...subCategoryFilters,...tagFilters]
)

export const SortOrderSearchText = createSelector(
  CurrentOrder,
  CurrentSort,
  SearchText
)

export const SelectedFilterList = createSelector(
  SelectedSemesterFilters,
  SelectedCourseLevelFilters,
  SelectedProviderFilters,
  SelectedCategoryFilters,
  SelectedSubCategoryFilters,
  SelectedTagFilters,
  (semesterFilters, courseLevelFilters, providerFilters, categoryFilters, subCategoryFilters, tagFilters) =>{
    return {
      semesterFilters,
      courseLevelFilters,
      providerFilters,
      categoryFilters,
      subCategoryFilters,
      tagFilters
    }
  }
)

export const FilteredMemberCourses = createSelector(
  selectMemberCourseState,
  CombinedMemberCourseListItems,
  (state, memberCourseListItems) => {
    let filteredMemberCourses: MemberCourseListItem[] = !!memberCourseListItems ? [...memberCourseListItems] : [];
    
    if(state.presetFilterType.id == MemberCourseType.MyCourses){
      filteredMemberCourses = filteredMemberCourses.filter(x=>x.isFavourited);
    }
    
    const selectedSemesterFilters = state.semesterFilters.filter(x=>x.selected);
    if (selectedSemesterFilters.length) {
      filteredMemberCourses = filteredMemberCourses.filter(memberCourse => {
        const index = selectedSemesterFilters.findIndex(item => moment(memberCourse.startDate).isBetween(item.startDate,item.endDate,undefined,'[]'));
        return (index !== -1);
      })
    }

    const selectedCourseLevelFilters = state.courseLevelFilters.filter(x=>x.selected);
    if (selectedCourseLevelFilters.length) {
      filteredMemberCourses = filteredMemberCourses.filter(memberCourse => {
        const index = selectedCourseLevelFilters.findIndex(item => memberCourse.level.id===item.id);
        return (index !== -1);
      })
    }

    const selectedProviderFilters = state.providerFilters.filter(x=>x.selected);
    if (selectedProviderFilters.length) {
      filteredMemberCourses = filteredMemberCourses.filter(memberCourse => {
        const index = selectedProviderFilters.findIndex(item => memberCourse.providerId===item.id);
        return (index !== -1);
      })
    }

    const selectedCategoryFilters = state.categoryFilters.filter(x=>x.selected);
    if (selectedCategoryFilters.length) {
      filteredMemberCourses = filteredMemberCourses.filter(memberCourse => {
        const index = selectedCategoryFilters.findIndex(item => memberCourse.category.id===item.id);
        return (index !== -1);
      })
    }

    const selectedSubCategoryFilters = state.subCategoryFilters.filter(x=>x.selected);
    if (selectedSubCategoryFilters.length) {
      filteredMemberCourses = filteredMemberCourses.filter(memberCourse => {
        const index = selectedSubCategoryFilters.findIndex(item => memberCourse.subCategory.id===item.id);
        return (index !== -1);
      })
    }

    const selectedTagFilters = state.tagFilters.filter(x=>x.selected);
    if (selectedTagFilters.length) {
      filteredMemberCourses = filteredMemberCourses.filter(memberCourse => {
        const index = selectedTagFilters.findIndex(item => memberCourse.tags.findIndex(tag=>tag.id===item.id)!=-1);
        return (index !== -1);
      })
    }

    const searchText = state.searchText;
    if (searchText) {
      filteredMemberCourses = searchInText(searchText, filteredMemberCourses, [
        'title',
        'code',
        'institution.description',
        'level.description',
        'category.description',
        'subCategory.description'
      ]);
    }
    const currentSort = state.currentSort;
    const currentOrder = state.currentOrder;

    if(currentSort && currentOrder){
      const sort = currentSort.value;
      const order = currentOrder.value;
      filteredMemberCourses = filteredMemberCourses.sort(sortBy(`${order}${sort}`));      
    }

    return filteredMemberCourses;
  }
)

export const SelectedMemberCourses = createSelector(
  FilteredMemberCourses,
  (memberCourses) => {
    const result = !!memberCourses ? memberCourses.filter(x=>x.selected) : [];
    return result;
  }
)
export const SelectedFavouritedCourses = createSelector(
  SelectedMemberCourses,
  (courses) => courses.filter(x=>x.isFavourited)
)
export const SelectedFavouritedCoursesForEffect = createSelector(
  fromMember.Selectors.MemberId,
  SelectedFavouritedCourses,
  (memberId,memberCourseListItems) =>{
    return {
      memberCourseListItems,
      memberId
    }
  }
)
export const SelectedNonFavouritedCourses = createSelector(
  SelectedMemberCourses,
  (courses) => courses.filter(x=>!x.isFavourited)
)
export const SelectedNonFavouritedCoursesForEffect = createSelector(
  fromMember.Selectors.MemberId,
  SelectedNonFavouritedCourses,
  (memberId, selectedMemberCourseListItems) => {
    return {
      selectedMemberCourseListItems,
      memberId
    }
  }
)
export const CanCompareCourses = createSelector(
  SelectedMemberCourses,
  (selectedCourses) => 
    selectedCourses.length  >= 2
)

export const CanExportToExcel = createSelector(
  MemberCourseListItems,
  (memberCourseItems) => !!memberCourseItems  ? memberCourseItems.length > 0 : false
)

export const SelectedStatus = createSelector(
  SelectedMemberCourses,
  FilteredMemberCourses,
  (selected, filtered) => !!filtered ? `${selected.length} of ${filtered.length}` : ""
)

export const IsAllSelected = createSelector(
  SelectedMemberCourses,
  FilteredMemberCourses,
  (selected, filtered) => !!filtered ? filtered.length>0 && filtered.length===selected.length : false    
)

export const IndeterminateSelected = createSelector(
  IsAllSelected,
  FilteredMemberCourses,
  (isAllSelected, filtered) => !!filtered ? isAllSelected ? false : filtered.some(x=>x.selected) : false
)
export const IsMemberCoursesLoading = createSelector(
  selectMemberCourseState,
  (state) => state.isMemberCourseListItemsLoading
)