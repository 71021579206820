import { createReducer, on } from '@ngrx/store';
import * as fromCourseExchangeDashboard from '@courseExchange/dashboard/store';

export interface CourseExchangeDashboardState {
    courseExchangeId: string;
    startDate: Date;
    endDate: Date;
    pendingInstitutions: number;
    memberInstitutions: number;
    providerInstitutions: number;
    totalCourses: number;
    newCourses: number;
    totalEnrollments: number;
    newEnrollments: number;
    isLoadDashboardData: boolean;
}

export const initialDashboardState: CourseExchangeDashboardState = {
    courseExchangeId: '',
    startDate: undefined,
    endDate: undefined,
    pendingInstitutions: 0,
    memberInstitutions : 0,
    providerInstitutions: 0,
    totalCourses: 0,
    newCourses: 0,
    totalEnrollments: 0,
    newEnrollments: 0,
    isLoadDashboardData: false
};

export const courseExchangeDashboardReducer = createReducer(
    initialDashboardState,
    on(fromCourseExchangeDashboard.Actions.LoadDashboardData, (state, action) => {
        const courseExchangeId = action.courseExchangeId;
        const startDate = action.startDate;
        const endDate = action.endDate;
        return {
            ...state,
            courseExchangeId,
            startDate,
            endDate,
            isLoadDashboardData: true
        }
    }),
    on(fromCourseExchangeDashboard.Actions.LoadDashboardDataSuccess, (state, action) => {
        const {pendingInstitutions,memberInstitutions,providerInstitutions,totalCourses,newCourses,totalEnrollments,newEnrollments} = action.data;
        return {
            ...state,
            pendingInstitutions,
            memberInstitutions,
            providerInstitutions,
            totalCourses,
            newCourses,
            totalEnrollments,
            newEnrollments,
            isLoadDashboardData:false
        }
    }),
    on(fromCourseExchangeDashboard.Actions.LoadDashboardDataError, (state) => {
        return {
            ...state,
            istotalCoursesLoading:false
        }
    })
)
