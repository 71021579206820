import * as fromStore from '@provider/billing/store';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
@NgModule({
    imports: [
        StoreModule.forFeature('provider/billing', fromStore.providerBillingReducer),
        EffectsModule.forFeature([...fromStore.EFFECTS])
    ],
    providers: [...fromStore.RESOLVERS]
})
export class ProviderBillingStoreModule { }
