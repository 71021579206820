import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'enrollment-short-course-info',
  templateUrl: './enrollment-short-course-info.component.html',
  styleUrls: ['./enrollment-short-course-info.component.scss'],
})
export class EnrollmentShortCourseInfoComponent implements OnInit {
  constructor() {
  }

  ngOnInit(): void {

  }
}
