

import {createFeatureSelector, createSelector} from '@ngrx/store';
import { InstitutionState } from '../reducers/institution.reducer';
import { getStatusComplete } from '@shared/wizard/store/reducers/helpers/get-status-complete';
import { ChecklistItemType } from '@core/models/checklistItemType.enum';
import { sortBy } from 'sort-by-typescript';

export const selectInstitutionState = createFeatureSelector<InstitutionState>('institution');

export const Institution = createSelector(
    selectInstitutionState,
    (state) => {
        return state.institution
    }
);

export const IsSendingLogo = createSelector(
  selectInstitutionState,
  (state) => state.isSendingLogo
);

export const InstitutionId = createSelector(
    Institution,
    (institution) => !!institution ? institution.id : ""
);

export const InstitutionName = createSelector(
    Institution,
    (institution) => !!institution ? institution.institutionName : ""
)
export const AcademicYears = createSelector(
    selectInstitutionState,
    (state) => {
        return state.academicYears;
    }
);

export const CurrentAcademicYear = createSelector(
    selectInstitutionState,
    (state) => state.currentAcademicYear
);

export const CurrentAcademicYearId = createSelector(
    selectInstitutionState,
    (state) => state.currentAcademicYearId
);

export const FirstAcademicYear = createSelector(
    AcademicYears,
    (academicYears) => {
        if(!!academicYears && academicYears.length>0){
            academicYears = academicYears.sort(sortBy("startDate"));
            var result = academicYears[0];
            return result;
        }else{
            return undefined;
        }
    }
)

export const FirstSemester = createSelector(
    FirstAcademicYear,
    (firstAcademicYear) => {
        if(!!firstAcademicYear && firstAcademicYear.semesters.length>0){
            const semesters = firstAcademicYear.semesters.sort(sortBy("startDate"));
            var result = semesters[0];
            return result;
        }else{
            return undefined;
        }
    }
)

export const FirstSession = createSelector(
    FirstSemester,
    (firstSemester) => {
        if(!!firstSemester && firstSemester.sessions.length>0){
            const sessions = firstSemester.sessions.sort(sortBy("startDate"));
            var result = sessions[0];
            return result;
        }else{
            return undefined;
        }
    }
)

export const SessionDateRules = createSelector(
    selectInstitutionState,
    (state) => state.sessionDateRules
)

export const calendarLoaded = createSelector(
    selectInstitutionState,
    (state) => state.calendarLoaded
)

export const CalendarForResolver = createSelector(
    selectInstitutionState,
    (state)=> {
        const institutionId = !!state.institution ? state.institution.id : '';
        const calendarsLoaded = state.calendarLoaded;
        return{
            institutionId,
            calendarsLoaded
        }
    }
)

export const BankInfo = createSelector(
    Institution,
    (institution) => !!institution ? institution.bankInfo : undefined
)

export const ProviderCheckList = createSelector(
    Institution,
    (institution) => {
        return !!institution ?
         {
            ...institution.providerChecklist,
            accordianFlag: false,
            statusComplete: getStatusComplete(institution.providerChecklist),
            items: institution.providerChecklist.items.map(x=>{
                return {
                    ...x,
                    accordionFlag: false,
                    statusComplete: getStatusComplete(x),
                    subCheckListsProgress: x.items.length ?
                                            {
                                                countCompleted: x.items.filter(x=>x.isComplete).length,
                                                all: x.items.length
                                            }:undefined
                }
            })
        }:undefined
    }
)

export const ProviderChecklistOrder = createSelector(
    selectInstitutionState,
    (state) => {
        return [
            ChecklistItemType.EntityProfile,
            ChecklistItemType.ContactAndUserSetup_Accounting,
            ChecklistItemType.ContactAndUserSetup_President,
            ChecklistItemType.ContactAndUserSetup_ProvostOrVicePresident,
            ChecklistItemType.Calendar_AddYear,
            ChecklistItemType.Calendar_AddSemesters,
            ChecklistItemType.Calendar_AddSessions,
            ChecklistItemType.BankInformation,
            ChecklistItemType.Policies
        ];

    }
)

export const ProviderChecklistComplete = createSelector(
    ProviderCheckList,
    (checklist) => !!checklist ? checklist.items.every(x=>x.isComplete) : false
);

export const FirstIncompleteProviderChecklistItem = createSelector(
    ProviderCheckList,
    (checklist) => !!checklist ? checklist.items.find(x=>x.isComplete===false): undefined
)

export const MemberChecklist = createSelector(
    Institution,
    (institution) => {
        return !!institution ?
         {
            ...institution.memberChecklist,
            accordianFlag: false,
            statusComplete: getStatusComplete(institution.memberChecklist),
            items: institution.memberChecklist.items.map(x=>{
                return {
                    ...x,
                    accordionFlag: false,
                    statusComplete: getStatusComplete(x),
                    subCheckListsProgress: x.items.length ?
                                            {
                                                countCompleted: x.items.filter(x=>x.isComplete).length,
                                                all: x.items.length
                                            }:undefined
                }
            })
        }:undefined
    }
)

export const MemberChecklistOrder = createSelector(
    selectInstitutionState,
    (state) => {
        return [
            ChecklistItemType.EntityProfile,
            ChecklistItemType.ContactAndUserSetup_Accounting,
            ChecklistItemType.ContactAndUserSetup_President,
            ChecklistItemType.ContactAndUserSetup_ProvostOrVicePresident,
            ChecklistItemType.Calendar_AddYear,
            ChecklistItemType.Calendar_AddSemesters,
            ChecklistItemType.BankInformation,
            ChecklistItemType.Policies
        ];

    }
)

export const MemberChecklistComplete = createSelector(
    MemberChecklist,
    (checklist) => !!checklist ?  checklist.items.every(x=>x.isComplete) : false
);

export const FirstIncompleteMemberChecklistItem = createSelector(
    MemberChecklist,
    (checklist) => !!checklist ? checklist.items.find(x=>x.isComplete===false) : undefined
)

export const areFacultyCVsLoaded = createSelector(
    selectInstitutionState,
    (state)=> state.areFacultyCVsLoaded
)

export const FacultyCVs = createSelector(
    selectInstitutionState,
    (state) => state.facultyCVs
)
