import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { Store, select } from "@ngrx/store";
import { first, finalize, filter, map, concatMap, withLatestFrom } from "rxjs/operators";
import { IAppState } from 'app/store/state/app.state';
import { CourseExchangeFeeListItem } from 'app/shared/courseExchange/models';
import * as fromProvider from '@provider/store';
import * as fromCourseExchange from '@courseExchange/store';

@Injectable()
export class ProviderFeesResolver implements Resolve<CourseExchangeFeeListItem[]>{
    isLoading = false;
    constructor(private store: Store<IAppState>){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
        const providerId = route.params['providerId'];
        let courseExchangeId = '';
        return this.store 
            .pipe(
                select(fromProvider.Selectors.Provider),
                map((currentProvider)=> {
                    courseExchangeId = currentProvider.courseExchangeId;
                }),
                map(() => {
                    if(!this.isLoading){
                        this.isLoading = true;
                        this.store.dispatch(fromCourseExchange.Actions.LoadFees({courseExchangeId}));
                    }
                    return true;
                }),
                filter(feesLoaded => feesLoaded),
                first(),
                finalize(()=>this.isLoading=false)
            )
    }
}