<div class="toolbar-icon">
  <button mat-icon-button
          [matMenuTriggerFor]="notificationMenu"
          matTooltip="Notifications"
          class="toolbar-icon-button"
          [matBadge]="notificationsCount$ | async"
          [disabled]="!(notificationsCount$ | async)">
    <mat-icon>notifications</mat-icon>
  </button>

  <mat-menu #notificationMenu="matMenu" class="mat-menu-notifications">
    <notification-bar [accessRole]="currentAccessRole"></notification-bar>
  </mat-menu>
</div>
