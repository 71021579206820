import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {hasErrorForm} from '../../../../helpers/has-error-form';
import {IControlsConfig} from '../../../../models/shared';
import {
  AbstractControl, AsyncValidatorFn,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidationErrors,
  Validators
} from '@angular/forms';
import {AddStudentCommand, EditStudentCommand} from '../../../../shared/student/commands';
import {BaseListItem} from '../../../../core/models';
import {Student} from '../../../../shared/student/models';
import {Observable, of} from 'rxjs';
import {fillControlsConfig} from '../../../../helpers/fill-controls-config';
import { IAppState } from 'app/store/state/app.state';
import { Store, select } from '@ngrx/store';
import { Region } from '@refData';
import * as fromRefData from '@refData';
import * as fromStudent from '@member/students/store';
import * as fromMember from '@member/store';
import * as fromMemberDashboard from '@member/dashboard/store';
import * as fromCourseExchange from '@courseExchange/store';
import {catchError, map, switchMap, take, tap, first, startWith, delay} from 'rxjs/operators';
import {StudentService} from '@shared/student/services';
import { ActivatedRoute } from '@angular/router';
import {v4 as uuidv4} from 'uuid';
type IFormFields = Omit<EditStudentCommand, 'institutionId'>;

type ILocalControlsConfig = IControlsConfig<IFormFields>;

@Component({
  selector: 'app-student-add-edit',
  templateUrl: './student-add-edit.component.html',
  styleUrls: ['./student-add-edit.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class StudentAddEditComponent implements OnInit {
  form: UntypedFormGroup = this.formBuilder.group(this.shapeControlsConfig());;
  institutionId$: Observable<string>;
  regions$: Observable<Region[]>;
  genders$: Observable<BaseListItem[]>;
  studentTypes$: Observable<BaseListItem[]>;
  student$: Observable<Student>;
  isEdit = false;
  sending: boolean = false;
  student: Student;
  phoneMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store<IAppState>,
    private studentService: StudentService,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.genders$ = this.store.pipe(select(fromRefData.Selectors.genders));
    this.studentTypes$ = this.store.pipe(select(fromCourseExchange.Selectors.StudentTypes));
    this.regions$ = this.store.pipe(select(fromRefData.Selectors.regions));
    this.student$ = this.store.pipe(select(fromStudent.Selectors.CurrentStudent));
    this.institutionId$ = this.store.pipe(select(fromMember.Selectors.InstitutionId));
    const route = this.activatedRoute.snapshot;
    const path = route.routeConfig.path;
    if(path != 'new')
    {
      this.student$.pipe(
        startWith(null),
        delay(0),
        tap((student)=>{
          if(!!student){
            this.isEdit = true;
            this.student = student;
            this.form = this.formBuilder.group(this.shapeControlsConfig(student));
          }
        })
      ).subscribe();
    }
    else
    {
      this.isEdit = false;
    }
  }

  studentNumberAsyncValidator = (): AsyncValidatorFn => {
    let initialStudentNumber: string | null = null;

    this.student$.pipe(
      tap((student) => {
        if (student) {
          initialStudentNumber = student.studentNumber;
        }
      }),
      first()
    ).subscribe();

    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return this.institutionId$.pipe(
        take(1),
        switchMap((institutionId) => {
          return this.studentService.studentNumberExists(institutionId, control.value)
        }),
        catchError(() => {
          return of(false);
        }),
        map((isExist) => {
          return (initialStudentNumber !== control.value) && isExist ? {studentNumberExist: true} : null;
        }),
        first()
      )
    }
  };

  getInitialEmail(): string {
    return this.student && this.student.emailAddress ? this.student.emailAddress.value : '';
  }

  getInitialEmail2(): string {
    return this.student && this.student.emailAddress2 ? this.student.emailAddress2.value : '';
  }

  getUserId(): string {
    return this.student && this.student.id ? this.student.id : '';
  }

  hasError = (controlName: keyof IFormFields, errorName: string) => hasErrorForm(controlName, errorName, this.form);

  private shapeControlsConfig(student?: Student): ILocalControlsConfig {
    const initialControlsConfig: ILocalControlsConfig = {
      id: [uuidv4()],
      studentNumber: ['', [Validators.required]],
      firstName: ['', Validators.required],
      middleName: [''],
      lastName: ['', Validators.required],
      phoneNumber: ['',[Validators.required]],
      emailAddress: ['',[Validators.email, Validators.required]],
      emailAddress2: [''],
      addressLine1: ['', Validators.required],
      addressLine2: [''],
      regionId: ['', Validators.required],
      city: ['', Validators.required],
      genderId: ['', Validators.required],
      studentTypeId: [''],
      dateOfBirth: [null, Validators.required],
      postalCode: ['', Validators.required]
    };

    if (student) {
      return fillControlsConfig<IFormFields>(initialControlsConfig, this.studentPrepareForForm(student));
    }else{
      return initialControlsConfig;
    }

  }

  private studentPrepareForForm = (student: Student): IFormFields => {
    const {id,fullName, phoneNumber, address, dateOfBirth, emailAddress, emailAddress2, genderId, studentTypeId, studentNumber} = student;
    return {
      id,
      firstName: fullName.firstName,
      lastName: fullName.lastName,
      middleName: fullName.middleName,
      emailAddress: emailAddress ? emailAddress.value : '',
      emailAddress2: emailAddress2 ? emailAddress2.value : '',
      phoneNumber: phoneNumber.value,
      genderId,
      studentTypeId,
      studentNumber,
      addressLine1: address.addressLine1,
      addressLine2: address.addressLine2,
      dateOfBirth: dateOfBirth,
      postalCode: address.postalCode,
      regionId: address.region ? address.region.id : '',
      city: address.city
    };
  };

  private shapeSendData(formValue): IFormFields {
    const {
      id,
      studentNumber,
      firstName,
      middleName,
      lastName,
      phoneNumber,
      emailAddress,
      emailAddress2,
      addressLine1,
      addressLine2,
      regionId,
      city,
      genderId,
      studentTypeId,
      dateOfBirth,
      postalCode
    } = formValue as IFormFields;

    return {
      id,
      studentNumber,
      firstName,
      middleName,
      lastName,
      phoneNumber,
      emailAddress,
      emailAddress2,
      addressLine1,
      addressLine2,
      regionId,
      city,
      genderId,
      studentTypeId,
      dateOfBirth,
      postalCode
    };
  }

  goToMemberDashboard(): void {
    this.store.dispatch(fromMemberDashboard.Navigate.Dashboard({}));
  }

  save(): void {
    if (!this.form.valid) return;
    this.sending = true;
    if (this.isEdit) {
      const command: EditStudentCommand = {
        institutionId: '',
        ...this.shapeSendData(this.form.value)
      };
      const oldStudent = this.student;
      this.store.dispatch(fromStudent.Actions.EditStudent({command, oldStudent}));
    } else {
      const command: AddStudentCommand = {
        institutionId: '',
        ...this.shapeSendData(this.form.value)
      };
      this.store.dispatch(fromStudent.Actions.AddStudent({command}));
      this.cancel();
    }
  }

  cancel(): void{
    this.store.dispatch(fromStudent.Navigate.StudentList({}));
  }

  canSave(): boolean{
    if(!this.form) return false;
    return this.form.valid && !this.sending;
  }
}
