import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {hasErrorForm} from 'app/helpers/has-error-form';
import {Store} from '@ngrx/store';
import {IAppState} from '../../../../store/state/app.state';
import {ECourseCalendarEntity, ECourseCalendarTypesActionsForm} from '../../constants';
import {IEntityActionsPayload} from '../../models/shared';
import {AcademicYear} from '../../models';
import {Observable} from 'rxjs';
import {IControlsConfig} from '../../../../models/shared';
import {fillControlsConfig} from '../../../../helpers/fill-controls-config';
import {EditAcademicYearCommand, AddAcademicYearCommand} from '../../commands';
import {validateStartEndDates} from '../../../../helpers/validate-start-end-dates';
import * as moment from 'moment';
import {v4 as uuidv4} from 'uuid';
import * as fromInstitution from '@institution/store';

type IFormFields = Pick<AcademicYear, 'id' | 'name' | 'startDate' | 'endDate'>;

type ILocalControlsConfig = IControlsConfig<IFormFields>;

@Component({
  selector: 'app-academic-year-form',
  templateUrl: './academic-year-form.component.html',
  styleUrls: ['./academic-year-form.component.scss', '../styles/calendar-entity-creator-forms.scss']
})
export class AcademicYearFormComponent implements OnInit {

  @Input() payload: IEntityActionsPayload.AcademicYearPayload;
  @Output() onClose = new EventEmitter();
  @Output() onChangePayload = new EventEmitter<IEntityActionsPayload.Types>();

  currentAcademicYear$: Observable<AcademicYear>;

  form: UntypedFormGroup;
  isSending = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store<IAppState>,
  ) {
  }

  ngOnInit(): void {
    this.createForm();
  }

  private createForm(): void {
      this.form = this.formBuilder.group(this.shapeControlsConfig(this.payload.academicYearToEdit), {
        validators: this.checkDates
      });
  }

  private checkDates = (group: UntypedFormGroup): void => {
    const {endDate, startDate} = group.controls;
    const checkResult = validateStartEndDates(startDate.value, endDate.value);
   
    if(endDate.status == "INVALID" && this.form)
    {
      const IsAfterStartDate =!!moment(endDate.value).isValid() ? null : {IsValid: true};
      if(this.form.get('endDate') != null){
        this.form.get('endDate').setErrors(IsAfterStartDate);
      }
    }
    else if(this.form)
    {
      const errorsForEndDate = checkResult ? null : {lessStartDate: true};
      if(this.form.get('endDate') != null){
        this.form.get('endDate').setErrors(errorsForEndDate);
      }
    }
  }

  private shapeControlsConfig(dataForFill?: AcademicYear | null): ILocalControlsConfig {
    const initialControlsConfig: ILocalControlsConfig = {
      id: [''],
      name: ['', [Validators.required,Validators.maxLength(30)]],
      startDate: [null, Validators.required],
      endDate: [null, Validators.required]
    };

    if (dataForFill) {
      return fillControlsConfig<IFormFields>(initialControlsConfig, dataForFill);
    } else {
      return initialControlsConfig;
    }
  }

  hasError = (controlName: string, errorName: string) => hasErrorForm(controlName, errorName, this.form);

  onEditAcademicYear = () => {
    if (this.form.valid) {
      this.isSending = true;
      const {id, name, startDate, endDate} =this.form.value;
      const command: EditAcademicYearCommand = {
        id,
        name,
        startDate,
        endDate
      };

      this.store.dispatch(fromInstitution.Actions.UpdateAcademicYear({command}));
      this.isSending = false;
      this.onClose.emit();
    }
  }

  onAddAcademicYear = () => {
    this.addAcademicYear();
  }

  addAcademicYear = (fromContinue = false): void => {
    if (this.form.valid) {
      this.isSending = true;
      
      let {name, startDate, endDate} = this.form.value;
      
      const academicYearId = uuidv4();
      const command: AddAcademicYearCommand = {
        id: academicYearId,
        name,
        startDate,
        endDate
      };

      this.store.dispatch(fromInstitution.Actions.AddAcademicYear({command}))
      this.isSending = false;
      
      if (fromContinue) {
        const academicYearStartDate = startDate;
        const academicYearEndDate = endDate;
        this.onChangePayload.emit({
          entity: ECourseCalendarEntity.semester,
          typesActionsForm: ECourseCalendarTypesActionsForm.add,
          academicYearId,
          academicYearStartDate,
          academicYearEndDate,
          showSessions: true
        });
      }
      else
      {
        this.onClose.emit();
      }
    }
  };

  onContinueAddSemester = (): void => {
    this.addAcademicYear(true);
  };
}
