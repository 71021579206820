import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { Store, select } from "@ngrx/store";
import { tap, first, finalize, filter, map } from "rxjs/operators";
import { IAppState } from 'app/store/state/app.state';
import { BaseListItem } from 'app/core/models';
import * as fromRefData from '@refData';

@Injectable()
export class CourseTagsResolver implements Resolve<BaseListItem[]>{
    isLoading = false;
    constructor(private store: Store<IAppState>){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
        return this.store 
            .pipe(
                select(fromRefData.Selectors.courseTags),
                map((courseTags)=>{
                    if(!!courseTags && courseTags.length>0){
                        this.store.dispatch(fromRefData.Actions.LoadCourseTagsSuccess({courseTags}));
                    }else if(!this.isLoading){
                        this.isLoading = true;
                        this.store.dispatch(fromRefData.Actions.LoadCourseTags({forceReload:false}));
                    }
                    return true;
                }),
                filter(courseTags => courseTags),
                first(),
                finalize(()=>this.isLoading=false)
            )
    }
}