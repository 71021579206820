import { createAction, props } from '@ngrx/store';
import { UserListItem, User } from '@shared/user/models';
import { AddUserCommand, UpdateUserCommand } from '@shared/user/commands';
import { AccessRoleSummary } from '@auth/models/accessRoleSummary';

export const LoadUsers = createAction(
    '[Course Exchange Users Resolver] - Load Users',
    props<{
        courseExchangeId: string
    }>()
);

export const LoadUsersSuccess = createAction(
    '[Course Exchange Users Effects] - Load Users Success',
    props<{
        users: UserListItem[]
    }>()
)

export const SelectInstitution = createAction(
    '[Course Exchange User List] - Select Institution'
)

export const SetInstitutionId = createAction(
    '[Course Exchange Users] - Set InstitutionId',
    props<{
        institutionId: string
    }>()
)

export const UserListItemAdded = createAction(
    '[Course Exchange Users Realtime] - User List Item Added',
    props<{
        userListItem: UserListItem
    }>()
)

export const UserListItemUpdated = createAction(
    '[Course Exchange Users Realtime] - User List Item Updated',
    props<{
        userListItem: UserListItem
    }>()
)

export const AccessRoleUpdated = createAction(
    '[Course Exchange Users] - Access Role Updated',
    props<{
        accessRole: AccessRoleSummary
    }>()
)

export const AccessRoleAdded = createAction(
    '[Course Exchange Users] - Access Role Added',
    props<{
        accessRole: AccessRoleSummary
    }>()
)

export const AccessRoleDeleted = createAction(
    '[Course Exchange Users] - Access Role Deleted',
    props<{
        accessRole: AccessRoleSummary
    }>()
)


