import {AcademicYear, SessionDateRules} from '../../models';

export interface ICalendarState {
  calendar: AcademicYear[];
  loadedCalendarsError: boolean;
  selectedAcademicYearId: string;
  parentId: string;
  sessionDateRules: SessionDateRules[];
  loadedSessionDateRulesError: boolean;
  showSessions: boolean;
}

export const initialCalendarState: ICalendarState = {
  calendar: [],
  loadedCalendarsError: false,
  selectedAcademicYearId: 'none',
  parentId: '',
  sessionDateRules: [],
  loadedSessionDateRulesError: false,
  showSessions: true
};
