import { Injectable } from "@angular/core";
import * as fromCourseExchange from '@courseExchange/store';
import { Store } from '@ngrx/store';
import { IAppState } from 'app/store/state/app.state';
import { RealTimeService } from "@core/services/realTime.service";
import { CourseExchange, CourseExchangeFeeListItem, CourseExchangePolicy, CourseExchangeSummary } from "@shared/courseExchange/models";
import { HubConnection } from "@microsoft/signalr";

@Injectable()
export class CourseExchangeRealtime{
    constructor(private store: Store<IAppState>, private realTimeService: RealTimeService){
        this.initSignalRMessages();
    }

    private initSignalRMessages(){
        this.realTimeService.hubConnection$.subscribe(hubConnection=>{
          if(!hubConnection) return;
          this.courseExchangeSummaryUpdated(hubConnection);
          this.courseExchangeUpdated(hubConnection);
          this.courseExchangePolicyUpdated(hubConnection);
          this.courseExchangeFeesUpdated(hubConnection);
        })
      }
    private courseExchangeSummaryUpdated(hubConnection:HubConnection){
        hubConnection.on('CourseExchangeSummaryUpdated', (courseExchangeSummary: CourseExchangeSummary) => {
            this.store.dispatch(fromCourseExchange.Actions.CourseExchangeSummaryUpdated({courseExchangeSummary}));
          });
    }
    private courseExchangeUpdated(hubConnection:HubConnection){
        hubConnection.on('CourseExchangeUpdated', (courseExchange: CourseExchange) => {
            this.store.dispatch(fromCourseExchange.Actions.CourseExchangeUpdated({courseExchange}));
          });
    }
    private courseExchangePolicyUpdated(hubConnection:HubConnection){
        hubConnection.on('CourseExchangePolicyUpdated', (courseExchangePolicy: CourseExchangePolicy) => {
            this.store.dispatch(fromCourseExchange.Actions.CourseExchangePolicyUpdated({courseExchangePolicy}));
          });
    }
    private courseExchangeFeesUpdated(hubConnection:HubConnection){
        hubConnection.on('CourseExchangeFeesUpdated', (fees: CourseExchangeFeeListItem[])=>{
            this.store.dispatch(fromCourseExchange.Actions.CourseExchangeFeesUpdated({fees}));
        })
    }
}
