import { createEffect, Actions, ofType } from "@ngrx/effects";
import { Injectable } from "@angular/core";
import { map, switchMap, tap, concatMap, withLatestFrom } from "rxjs/operators";
import * as fromInstitution from '@institution/store';
import { InstitutionService } from '@shared/institution/services/institution.service';
import { CalendarService } from '@shared/calendar/services';
import * as fromCore from '@core/store';
import { of } from 'rxjs';
import { IAppState } from 'app/store/state/app.state';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';
import { ImageStorageService } from '@core/services/image-storage.service';
import getCurrentAcademicYear from "@shared/common/helpers/getCurrentAcademicYear";
import { update } from "lodash";


@Injectable()
export class InstitutionEffects {
        constructor(private actions$: Actions, private calendarService: CalendarService,
                private institutionService: InstitutionService, private imageService: ImageStorageService,
                private store: Store<IAppState>, private router: Router) {
        }

        loadInstitution$ = createEffect(() => {
                return this.actions$.pipe(
                        ofType(fromInstitution.Actions.LoadInstitution),
                        switchMap((action) => this.institutionService.getInstitutionById(action.institutionId)),
                        map((institution) =>
                                fromInstitution.Actions.LoadInstitutionSuccess({ institution }))
                );
        });

        updateInstitution$ = createEffect(() => {
                return this.actions$.pipe(
                        ofType(fromInstitution.Actions.UpdateInstitution),
                        switchMap((action) => this.institutionService.updateInstitution(action.command)),
                        map((response) => fromCore.Actions.HandleResponse({response}))
                );
        });

        loadInstitutionSummary$ = createEffect(() => {
                return this.actions$.pipe(
                        ofType(fromInstitution.Actions.LoadInstitutionSummary),
                        switchMap((action) => this.institutionService.getInstitutionSummaryById(action.institutionId)),
                        map((institutionSummary) =>
                                fromInstitution.Actions.LoadInstitutionSummarySuccess({ institutionSummary }))
                );
        });

        loadCalendar$ = createEffect(() => {
                return this.actions$.pipe(
                        ofType(fromInstitution.Actions.LoadCalendar),
                        switchMap((action) => this.calendarService.getAcademicYears(action.institutionId)),
                        map((academicYears) => {
                                return fromInstitution.Actions.LoadCalendarSuccess({ academicYears })
                        })
                );
        });

        loadCalendarSuccess$ = createEffect(() => {
                return this.actions$.pipe(
                        ofType(fromInstitution.Actions.LoadCalendarSuccess),
                        map((action) => getCurrentAcademicYear(action.academicYears)),
                        map((academicYear) => {
                                const academicYearId = !!academicYear ? academicYear.id : 'empty';
                                return fromInstitution.Actions.ChangeCurrentAcademicYearId({ academicYearId })
                        })
                );
        });

        changeAcademicYearId$ = createEffect(() => {
                return this.actions$.pipe(
                        ofType(fromInstitution.Actions.ChangeCurrentAcademicYearId),
                        concatMap(action => of(action).pipe(
                                withLatestFrom(this.store.pipe(select(fromInstitution.Selectors.AcademicYears)))
                        )),
                        map(([{ academicYearId }, academicYears]) => {
                                const academicYear = academicYears.find(x => x.id === academicYearId);
                                return fromInstitution.Actions.ChangeCurrentAcademicYear({ academicYear });
                        })
                );
        });

        uploadBankInfo$ = createEffect(() => {
                return this.actions$.pipe(
                        ofType(fromInstitution.Actions.UpdateBankInfo),
                        concatMap(action => of(action).pipe(
                                withLatestFrom(this.store.pipe(select(fromInstitution.Selectors.InstitutionId)))
                        )),
                        switchMap(([action, institutionId]) => this.institutionService.uploadBankInfo(action.file, institutionId)),
                        map((response) => fromCore.Actions.HandleResponse({response}))
                )
        });

        downloadBankInfo$ = createEffect(() => {
                return this.actions$.pipe(
                        ofType(fromInstitution.Actions.DownloadBankInfo),
                        concatMap(action => of(action).pipe(
                                withLatestFrom(this.store.pipe(select(fromInstitution.Selectors.InstitutionId)))
                        )),
                        tap(([action, institutionId]) => this.institutionService.downloadBankInfo(institutionId, action.bankInfo))
                );
        }, { dispatch: false });

        addAcademicYear$ = createEffect(() => {
                return this.actions$.pipe(
                        ofType(fromInstitution.Actions.AddAcademicYear),
                        concatMap(action => of(action).pipe(
                                withLatestFrom(this.store.pipe(select(fromInstitution.Selectors.InstitutionId)))
                        )),
                        switchMap(([action, institutionId]) => {
                                action.command.institutionId = institutionId;
                                return this.calendarService.addAcademicYear(institutionId, action.command)
                        }),
                        map((response) => fromCore.Actions.HandleResponse({response})))
        });

        editAcademicYear$ = createEffect(() => {
                return this.actions$.pipe(
                        ofType(fromInstitution.Actions.UpdateAcademicYear),
                        concatMap(action => of(action).pipe(
                                withLatestFrom(this.store.pipe(select(fromInstitution.Selectors.InstitutionId)))
                        )),
                        switchMap(([action, institutionId]) => this.calendarService.editAcademicYear(institutionId, action.command)),
                        map((response) => fromCore.Actions.HandleResponse({response})))
        });

        deleteAcademicYear$ = createEffect(() => {
                return this.actions$.pipe(
                        ofType(fromInstitution.Actions.DeleteAcademicYear),
                        concatMap(action => of(action).pipe(
                                withLatestFrom(this.store.pipe(select(fromInstitution.Selectors.InstitutionId)))
                        )),
                        switchMap(([action, institutionId]) => {
                                return this.calendarService.deleteAcademicYear(institutionId, action.academicYearId)
                        }),
                        map((response) => fromCore.Actions.HandleResponse({response})))
        });

        addSemester$ = createEffect(() => {
                return this.actions$.pipe(
                        ofType(fromInstitution.Actions.AddSemester),
                        concatMap(action => of(action).pipe(
                                withLatestFrom(this.store.pipe(select(fromInstitution.Selectors.InstitutionId)))
                        )),
                        switchMap(([action, institutionId]) => this.calendarService.addSemester(institutionId, action.command)),
                        map((response) => fromCore.Actions.HandleResponse({response})))
        });

        editSemester$ = createEffect(() => {
                return this.actions$.pipe(
                        ofType(fromInstitution.Actions.UpdateSemester),
                        concatMap(action => of(action).pipe(
                                withLatestFrom(this.store.pipe(select(fromInstitution.Selectors.InstitutionId)))
                        )),
                        switchMap(([action, institutionId]) => this.calendarService.editSemester(institutionId, action.command)),
                        map((response) => fromCore.Actions.HandleResponse({response})))
        });

        deleteSemester$ = createEffect(() => {
                return this.actions$.pipe(
                        ofType(fromInstitution.Actions.DeleteSemester),
                        concatMap(action => of(action).pipe(
                                withLatestFrom(this.store.pipe(select(fromInstitution.Selectors.InstitutionId)))
                        )),
                        switchMap(([action, institutionId]) => {
                                return this.calendarService.deleteSemester(institutionId, action.semester.id)
                        }),
                        map((response) => fromCore.Actions.HandleResponse({response})))
        });

        addSession$ = createEffect(() => {
                return this.actions$.pipe(
                        ofType(fromInstitution.Actions.AddSession),
                        concatMap(action => of(action).pipe(
                                withLatestFrom(this.store.pipe(select(fromInstitution.Selectors.InstitutionId)))
                        )),
                        switchMap(([action, institutionId]) => this.calendarService.addSession(institutionId, action.command)),
                        map((response) => fromCore.Actions.HandleResponse({response})))
        });

        editSession$ = createEffect(() => {
                return this.actions$.pipe(
                        ofType(fromInstitution.Actions.UpdateSession),
                        concatMap(action => of(action).pipe(
                                withLatestFrom(this.store.pipe(select(fromInstitution.Selectors.InstitutionId)))
                        )),
                        switchMap(([action, institutionId]) => this.calendarService.editSession(institutionId, action.command)),
                        map((response) => fromCore.Actions.HandleResponse({response})))
        });
        deleteSession$ = createEffect(() => {
                return this.actions$.pipe(
                        ofType(fromInstitution.Actions.DeleteSession),
                        concatMap(action => of(action).pipe(
                                withLatestFrom(this.store.pipe(select(fromInstitution.Selectors.InstitutionId)))
                        )),
                        switchMap(([action, institutionId]) => {
                                return this.calendarService.deleteSession(institutionId, action.session.id)
                        }),
                        map((response) => fromCore.Actions.HandleResponse({response})))
        });


        goToDashboard$ = createEffect(() => {
                return this.actions$.pipe(
                        ofType(fromInstitution.Actions.Dashboard),
                        concatMap(action => of(action).pipe(
                                withLatestFrom(this.store.pipe(select(fromInstitution.Selectors.InstitutionId)))
                        )),
                        tap(([action, institutionId]) => {
                                this.router.navigate([`/institution/${institutionId}/dashboard`])
                        })
                )
        }, { dispatch: false });

        goToUserList$ = createEffect(() => {
                return this.actions$.pipe(
                        ofType(fromInstitution.Actions.GoToUserList),
                        concatMap(action => of(action).pipe(
                                withLatestFrom(this.store.pipe(select(fromInstitution.Selectors.InstitutionId)))
                        )),
                        tap(([action, institutionId]) => {
                                this.router.navigate([`/institution/${institutionId}/users/list`])
                        })
                )
        }, { dispatch: false });

        uploadInstitutionLogo$ = createEffect(() => {
                let uploadInstitutionLogoCommand;
                let updateInstitutionCommand;
                return this.actions$.pipe(
                        ofType(fromInstitution.Actions.UploadInstitutionLogo),
                        tap((action)=>{
                                uploadInstitutionLogoCommand = action.command,
                                updateInstitutionCommand = action.command.UpdateInstitutionCommand
                        }),
                        switchMap(()=>this.institutionService.updateInstitution(updateInstitutionCommand)),
                        switchMap(() => this.imageService.saveInstitutionLogo(uploadInstitutionLogoCommand)),
                        map((response) => {
                                response.isSuccess ?
                                        this.store.dispatch(fromInstitution.Actions.UploadInstitutionLogoSuccess()):
                                        this.store.dispatch(fromInstitution.Actions.UploadInstitutionLogoError());
                                return fromCore.Actions.HandleResponse({response})
                        })
                )
        });

        loadSessionDateRules$ = createEffect(() => {
                return this.actions$.pipe(
                        ofType(fromInstitution.Actions.LoadSessionDateRules),
                        switchMap(({ institutionId }) => this.institutionService.getSessionDateRules(institutionId)),
                        map((sessionDateRules) => fromInstitution.Actions.LoadSessionDateRulesSuccess({ sessionDateRules }))
                )
        });

}
