import { createFeatureSelector, createSelector } from "@ngrx/store";
import { MemberDashboardState } from '../reducers';

const selectMemberDashboardState = createFeatureSelector<MemberDashboardState>('member/dashboard');

export const MemberId = createSelector(
  selectMemberDashboardState,
  (state) => state.memberId
)

export const AvailableCourses = createSelector(
  selectMemberDashboardState,
  (state) => state.availableCourses
)

export const TotalEnrollments = createSelector(
  selectMemberDashboardState,
  (state) => state.totalEnrollments
)

export const UpdatedEnrollments = createSelector(
  selectMemberDashboardState,
  (state) => state.updatedEnrollments
)

export const TotalStudents = createSelector(
  selectMemberDashboardState,
  (state) => state.totalStudents
)

export const SelectorForMemberDashboard = createSelector(
  selectMemberDashboardState,
  (state) =>{
    const {memberId, startDate, endDate} = state;
    return{
      currentMemberId: memberId,
      currentStartDate: startDate,
      currentEndDate: endDate
    }
  }
)
export const IsLoadDashboardData = createSelector(
  selectMemberDashboardState,
  (state) => state.isLoadDashboardData
)