import { MemberCourseListItem } from "@member/memberCourse/models";
import convertMemberCourseListItemDates from "@shared/common/helpers/convertMemberCourseListItemDates";
import { MemberCourse } from "@shared/member/models";

export default(items: MemberCourseListItem[], memberCourses: MemberCourse[]): MemberCourseListItem[] => {
    let result = new Array<MemberCourseListItem>();
    items.forEach((item)=>{
      item = convertMemberCourseListItemDates(item);
      const idx = memberCourses.findIndex(x=>x.providerCourseId===item.providerCourseId);
      if(idx===-1){
        result.push(item);
      }else{
        const memberCourse = memberCourses[idx];
        let firstItem = {...item};
        firstItem.memberCourseId= memberCourse.id;
        firstItem.isFavourited = true;
        firstItem.institutionalCourseCode = !!memberCourse.institutionalCourseCodes[0] ?  memberCourse.institutionalCourseCodes[0] : "";
        result.push(firstItem);
        for(var i=1; i<memberCourse.institutionalCourseCodes.length;i++){
          const newItem = {...firstItem, id: `${item.id}-${i}`, institutionalCourseCode: memberCourse.institutionalCourseCodes[i]};
          result.push(newItem);
        }
      }
    });
    return [...result];
}