

import {createFeatureSelector, createSelector} from '@ngrx/store';
import { FacultyCVState } from '../reducers';
import { Status } from '@shared/common/models';
import { searchInText } from 'app/helpers/search-in-text';
import { sortBy } from 'sort-by-typescript';
import { FacultyCV } from '@shared/institution/models';

export const selectFacultyCVState = createFeatureSelector<FacultyCVState>('provider/facultyCV');

export const FacultyCVs = createSelector(
    selectFacultyCVState,
    (state) => state.facultyCVs
);
export const CurrentFacultyCV = createSelector(
    selectFacultyCVState,
    (state) => state.currentFacultyCV
)
export const ActiveFacultyCVs = createSelector(
    selectFacultyCVState,
    (state) => state.facultyCVs.filter(x=>x.isActive)
)

export const StatusFilters = createSelector(
    selectFacultyCVState,
    (state) => state.statusFilters
)

export const SearchText = createSelector(
    selectFacultyCVState,
    (state) => state.searchText
)


export const CurrentSort = createSelector(
    selectFacultyCVState,
    (state) => state.currentSort
)
export const CurrentOrder = createSelector(
    selectFacultyCVState,
    (state) => state.currentOrder
)

export const FilteredFacultyCVs = createSelector(
    FacultyCVs,
    StatusFilters,
    SearchText,
    CurrentSort, 
    CurrentOrder,
    (facultyCVs, statusFilters, searchText, currentSort, currentOrder) => {
        const selectedFilters = [...statusFilters.filter(x=>x.selected===true)];
        let result = new Array<FacultyCV>();
        if(selectedFilters.length===0) 
            result = [...facultyCVs];
        else{
            selectedFilters.forEach(filter =>{
                switch(filter.id){
                    case Status.Active:
                        result.push(...facultyCVs.filter(x=>x.isActive));
                        break;
                    case Status.Inactive:
                        result.push(...facultyCVs.filter(x=>!x.isActive));
                        break;
                }
            })
        }
        
        result = searchInText<FacultyCV>(searchText, result, [
            'description'
          ]);

        const sort = currentSort.value;
        const order = currentOrder.value;

        result = result.sort(sortBy(`${order}${sort}`));

        
        return result;
        
    }
);

export const InstitutionId = createSelector(
    selectFacultyCVState,
    (state) => state.institutionId
);

export const Sorts = createSelector(
    selectFacultyCVState,
    (state) => state.sorts
)
export const Orders = createSelector(
    selectFacultyCVState,
    (state) => state.orders
)

export const StatusFiltersWithCounts = createSelector(
    FacultyCVs,
    StatusFilters,
    (facultyCVs, statusFilters) => statusFilters.map(x=>{
        return {
            ...x,
            descriptionForView: `${x.description} (${facultyCVs.filter(facultyCV => x.id==Status.Active ? facultyCV.isActive : !facultyCV.isActive).length})`
        }
    })
)
export const SelectedStatusFilters = createSelector(
    StatusFilters,
    (statusFilters) => statusFilters.filter(x=>x.selected)
)

export const StatusFiltersCount = createSelector(
    SelectedStatusFilters,
    (statusFilters) => statusFilters.length > 0 ? statusFilters.length : null
);
