import {Moment} from 'moment';
import * as moment from 'moment';

export const compareFnToSortDates = (firstDate: Moment | string | Date, secondDate: Moment | string | Date) => {
  if (moment(firstDate).isBefore(secondDate)) {
    return -1;
  }

  if (moment(firstDate).isAfter(secondDate)) {
    return 1;
  }

  if (moment(firstDate).isSame(secondDate)) {
    return 0;
  }
};
