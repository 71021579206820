export enum ECourseCalendarEntity {
  academicYear = 'academicYear',
  semester = 'semester',
  session = 'session'
}

export enum ECourseCalendarTypesActionsForm {
  add = 'add',
  edit = 'edit',
  duplicate = 'duplicate'
}
