import { createAction, props } from "@ngrx/store";
import { UserSummary } from '../models/userSummary.model';
import { AccessRole } from '../models/accessRole';
import { CourseExchangeListItem } from '@shared/courseExchange/models';
import { Moment } from "moment";

export const login = createAction(
    "[Login Page] - User Login",
    props<{
        user: UserSummary,
        returnUrl: string
    }>()
)

export const logout = createAction(
    "[Top Menu] - Logout"
);

export const selectAccessRole = createAction(
    "[NavBar] - Select AccessRole",
    props<{
        accessRoleId: string
    }>()
);

export const selectCourseExchangeId = createAction(
    "[NavBar] - Select Course Exchange Id",
    props<{
        courseExchangeId: string
    }>()
)

export const navigateToDashboard = createAction(
    "[NavBar] - Navigate to Dashboard",
    props<{
        accessRoleId: string
    }>()
)

export const navigateToLogin = createAction(
    "[NavBar] - Navigate to Login",
    props<{
        returnUrl?: string;
    }>()
)

export const UserSummaryUpdated = createAction(
    '[Auth Effects] - User Summary Updated',
    props<{
        userSummary: UserSummary
    }>()
)

export const AccessRoleAdded = createAction(
    '[Auth Effects] - Access Role Added',
    props<{
        accessRole: AccessRole
    }>()
)

export const AccessRoleUpdated = createAction(
    '[Auth Effects] - Access Role Updated',
    props<{
        accessRole: AccessRole
    }>()
)

export const AccessRoleDeleted = createAction(
    '[Auth Effects] - Access Role Deleted',
    props<{
        accessRole: AccessRole
    }>()
)

export const ChangeStartDate = createAction(
    '[Everywhere] - Change Start Date',
    props<{
        startDate: Date
    }>()
)

export const ChangeEndDate = createAction(
    '[Everywhere] - Change End Date',
    props<{
        endDate: Date
    }>()
)
