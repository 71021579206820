import {Component, ViewEncapsulation, Input, Output, EventEmitter} from '@angular/core';
import {Store} from '@ngrx/store';
import {IAppState} from '../../../../store/state/app.state';
import { IWizardChecklistForView } from '@shared/wizard/models';
import { ChecklistItemType } from '@core/models/checklistItemType.enum';

@Component({
  selector: 'wizard-home-step-check-list',
  templateUrl: './wizard-home-step-check-list.component.html',
  styleUrls: [
    '../../styles/common-wizard-step.scss',
    './wizard-home-step-check-list.component.scss'
  ],
  encapsulation: ViewEncapsulation.None
})
export class WizardHomeStepCheckListComponent {

  @Input()checklist: IWizardChecklistForView;
  @Output() navigate: EventEmitter<ChecklistItemType> = new EventEmitter<ChecklistItemType>();
  constructor() {
  }

  ngOnInit(): void {
  }

  goToFirstNotCompleted = () => {
    const item = this.checklist.items.find(x=>!x.isComplete);
    if(!item){
      const lastItem = this.checklist.items[this.checklist.items.length-1];
      this.navigate.emit(lastItem.itemType);
    }else if(item.itemType === ChecklistItemType.EntityProfile){
      this.navigate.emit(ChecklistItemType.EntityProfile);
    }else{
      const firstNotCompleted = !!item.items.length ? item.items.find(x=>!x.isComplete) : item;
    
      this.navigate.emit(firstNotCompleted.itemType);
    }
    
  }
}
