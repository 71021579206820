import {createAction, props} from '@ngrx/store';
import { AcademicYear, Semester, Session, SessionDateRules } from '../../models';
import { DeleteAcademicYearCommand, DeleteSemesterCommand, DeleteSessionCommand } from '../../commands';


export const loadCalendar = createAction(
  '[Calendar] LoadCalendar',
  props<{
    payload: string
  }>()
);

export const loadedCalendarSuccess = createAction(
  '[Calendar] loadedCalendarSuccess',
  props<{
    academicYears: AcademicYear[],
    parentId: string
  }>()
);

export const loadedCalendarError = createAction(
  '[Calendar] loadedCalendarError'
);

export const addedNewAcademicYearSuccess = createAction(
  '[Calendar] addedNewAcademicYearSuccess',
  props<{
    payload: AcademicYear
  }>()
);

export const duplicateAcademicYear = createAction(
  '[Calendar] duplicateAcademicYear',
  props<{
    payload: AcademicYear
  }>()
);

export const updatedAcademicYearSuccess = createAction(
  '[Calendar] updatedAcademicYearSuccess',
  props<{
    payload: AcademicYear
  }>()
);

export const deleteAcademicYear = createAction(
  '[Calendar] deleteAcademicYear',
  props<{
    payload: DeleteAcademicYearCommand,
  }>()
);

export const deleteAcademicYearSuccess = createAction(
  '[Calendar] deleteAcademicYearSuccess',
  props<{
    payload: DeleteAcademicYearCommand
  }>()
);

export const deleteAcademicYearError = createAction(
  '[Calendar] deleteAcademicYearError',
  props<{
    payload: string
  }>()
);

export const addedNewSemesterSuccess = createAction(
  '[Calendar] addedNewSemesterSuccess',
  props<{
    payload: Semester
  }>()
);

export const duplicateSemesterSuccess = createAction(
  '[Calendar] duplicateSemesterSuccess',
  props<{
    payload: Semester
  }>()
);

export const updatedSemesterSuccess = createAction(
  '[Calendar] updatedSemesterSuccess',
  props<{
    payload: Semester
  }>()
);

export const deleteSemester = createAction(
  '[Calendar] deleteSemester',
  props<{
    payload: DeleteSemesterCommand,
  }>()
);

export const deleteSemesterSuccess = createAction(
  '[Calendar] deleteSemesterSuccess',
  props<{
    payload: DeleteSemesterCommand
  }>()
);

export const deleteSemesterError = createAction(
  '[Calendar] deleteSemesterError',
  props<{
    payload: string
  }>()
);

export const changeCurrentAcademicYear = createAction(
  '[Calendar] changeCurrentAcademicYear',
  props<{
    payload: string
  }>()
);

export const addedNewSessionSuccess = createAction(
  '[Calendar] addedNewSessionSuccess',
  props<{
    payload: Session
  }>()
);

export const duplicateSessionSuccess = createAction(
  '[Calendar] duplicateSessionSuccess',
  props<{
    payload: Session
  }>()
);

export const updatedSessionSuccess = createAction(
  '[Calendar] updatedSessionSuccess',
  props<{
    payload: Session
  }>()
);

export const deleteSession = createAction(
  '[Calendar] deleteSession',
  props<{
    payload: DeleteSessionCommand
  }>()
);

export const deleteSessionSuccess = createAction(
  '[Calendar] deleteSessionSuccess',
  props<{
    payload: DeleteSessionCommand
  }>()
);

export const deleteSessionError = createAction(
  '[Calendar] deleteSessionError',
  props<{
    payload: string
  }>()
);

export const setParentIdForCalendar = createAction(
  '[Calendar] setParentIdForCalendar',
  props<{
    payload: string
  }>()
);

export const setShowSessions = createAction(
  '[Calendar] setShowSessoinsForCalendar',
  props<{
    showSessions: boolean
  }>()
);

export const loadSessionDateRules = createAction(
  '[Calendar] LoadSessionDateRules',
  props<{
    payload: string
  }>()
);

export const loadedSessionDateRulesSuccess = createAction(
  '[Calendar] loadedSessionDateRulesSuccess',
  props<{
    payload: SessionDateRules[]
  }>()
);

export const loadedSessionDateRulesError = createAction(
  '[Calendar] loadedSessionDateRulesError'
);

