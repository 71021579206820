import { Injectable } from "@angular/core";
import * as fromUsers from '@shared/user/store';
import { IAppState } from 'app/store/state/app.state';
import { Store } from '@ngrx/store';
import { RealTimeService } from "@core/services/realTime.service";
import { UserListItem } from "@shared/user/models";
import { HubConnection } from "@microsoft/signalr";
import { AccessRoleSummary } from "@auth/models/accessRoleSummary";

@Injectable()
export class UsersRealtime {
        constructor(private store: Store<IAppState>, private realTimeService: RealTimeService) {
                this.initSignalRMessages();
        }

        private initSignalRMessages() {
                this.realTimeService.hubConnection$.subscribe(hubConnection => {
                if (!hubConnection) return;
                    hubConnection.on('UserListItemUpdated', (user: UserListItem) => {
                            this.store.dispatch(fromUsers.Actions.UserUpdated({ user }));
                    });
                    hubConnection.on('UserListItemAdded', (user: UserListItem) => {
                        this.store.dispatch(fromUsers.Actions.UserAdded({ user }));
                    });
                    hubConnection.on('UserListItemDeleted', (user:UserListItem)=>{
                        this.store.dispatch(fromUsers.Actions.UserDeleted({user}));
                    });
                    hubConnection.on('AccessRoleSummaryUpdated', (accessRole: AccessRoleSummary) => {
                        this.store.dispatch(fromUsers.Actions.AccessRoleUpdated({ accessRole }));
                    });
                    hubConnection.on('AccessRoleSummaryAdded', (accessRole: AccessRoleSummary) => {
                        this.store.dispatch(fromUsers.Actions.AccessRoleAdded({ accessRole }));
                    });
                    hubConnection.on('AccessRoleSummaryDeleted', (accessRole: AccessRoleSummary)=>{
                        this.store.dispatch(fromUsers.Actions.AccessRoleDeleted({accessRole}));
                    });
                })
        }
}
