import {Component, Inject, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {IAppState} from '../../../../store/state/app.state';
import {select, Store} from '@ngrx/store';
import {Subject, Observable} from 'rxjs';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {IControlsConfig} from '../../../../models/shared';
import {hasErrorForm} from '../../../../helpers/has-error-form';
import {FacultyCV, Course} from '../../../../shared/institution/models';
import { MemberCourseListItem } from '@member/memberCourse/models';
import * as fromMemberCourse from '@member/memberCourse/store';
import * as fromFacultyCV from '@provider/facultyCV/store';
export interface IFacultyCvDialogData {
  courseId: string
}

type IFormFields = {
  description: string;
};

type ILocalControlsConfig = IControlsConfig<IFormFields>;

@Component({
  selector: 'faculty-cv-dialog',
  templateUrl: './faculty-cv-dialog.component.html',
  styleUrls: ['./faculty-cv-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FacultyCvDialogComponent implements OnInit, OnDestroy {
  private ngDestroyed$: Subject<any>;

  currentMemberCourse$: Observable<MemberCourseListItem>;
  form: UntypedFormGroup;
  institutionFacultyCVs$: Observable<FacultyCV[]>;
  institution

  maxLengthForDescription = 30;


  constructor(
    public matDialogRef: MatDialogRef<FacultyCvDialogComponent>,
    private store: Store<IAppState>,
    @Inject(MAT_DIALOG_DATA) public matDialogData: IFacultyCvDialogData,
    private formBuilder: UntypedFormBuilder,
  ) {
    this.ngDestroyed$ = new Subject();
  }

  ngOnInit(): void {
    this.currentMemberCourse$ = this.store.pipe(select(fromMemberCourse.Selectors.CurrentMemberCourseListItem));
    this.matDialogRef.addPanelClass([
      'faculty-cv-dialog'
    ]);

    this.initForm();
  }

  ngOnDestroy(): void {
    this.ngDestroyed$.next();
    this.ngDestroyed$.complete();
  }

  initForm(): void {
    this.form = this.formBuilder.group(this.shapeControlsConfig());
  }

  private shapeControlsConfig(): ILocalControlsConfig {
    const initialControlsConfig: ILocalControlsConfig = {
      description: ['', [Validators.required, Validators.maxLength(this.maxLengthForDescription)]]
    };

    return initialControlsConfig;
  }

  hasError = (controlName: keyof IFormFields, errorName: string) => hasErrorForm(controlName, errorName, this.form);

  close(): void {
    this.matDialogRef.close();
  }

  downloadFile(facultyCV: FacultyCV): void {
    this.store.dispatch(fromFacultyCV.Actions.DownloadFacultyCV({facultyCV}));
  }
}
