import { createEffect, Actions, ofType } from "@ngrx/effects";
import { Injectable } from "@angular/core";
import { map, switchMap, withLatestFrom, concatMap } from "rxjs/operators";
import { MemberService } from 'app/shared/member/services';
import * as fromMember from '..';
import * as fromCourseExchange from '@courseExchange/store';
import * as fromCore from '@core/store';
import { CourseExchangeService } from '@shared/courseExchange/services/course-exchange.service';
import { IAppState } from 'app/store/state/app.state';
import { Store, select } from '@ngrx/store';
import { of } from 'rxjs';
import { MatDialog } from "@angular/material/dialog";

@Injectable()
export class MemberEffects{
    constructor(private actions$: Actions,private store:Store<IAppState>, private memberService: MemberService, 
        private courseExchangeService: CourseExchangeService,private dialog: MatDialog){
            }


    completeMemberSetup$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromMember.Actions.CompleteMemberSetup),
                concatMap((action)=> of(action).pipe(
                    withLatestFrom(this.store.pipe(select(fromMember.Selectors.MemberId)))
                )),
                switchMap(([action,memberId]) => this.memberService.completeMemberSetup(memberId)),
                map((response) => fromCore.Actions.HandleResponse({response}))
        )
    });

    loadMember$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromMember.Actions.LoadMember),
                switchMap(({memberId})=> this.memberService.getMemberById(memberId)),
                map((member)=>{
                    return fromMember.Actions.LoadMemberSuccess({member});
                })
            
        )
    });

    loadCourseExchangeSummary$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromMember.Actions.LoadCourseExchangeSummary),
                /** An EMPTY observable only emits completion. Replace with your own observable stream */
                switchMap(({memberId}) => this.memberService.getCourseExchangeSummary(memberId)),
                map((courseExchangeSummary)=> fromCourseExchange.Actions.LoadCourseExchangeSummarySuccess({courseExchangeSummary}))
        );
    });
}