import {Component, Input} from '@angular/core';
import {EntityStatus} from '@core/models';
import {Entity} from '@core/models/entity.model';

@Component({
  selector: 'wizard-entity-status-notifications',
  templateUrl: './wizard-entity-status-notifications.component.html',
  styleUrls: ['./wizard-entity-status-notifications.component.scss']
})
export class WizardEntityStatusNotificationsComponent {
  @Input() entity: Entity;
  @Input() entityStatus: EntityStatus;

  EntityStatus = EntityStatus;

  getEntityViewName(): string {
    switch (this.entity) {
      case Entity.courseExchange: return 'Course Exchange';
      case Entity.member: return 'Member';
      case Entity.provider: return 'Provider';
    }
  }
}
