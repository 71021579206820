import { createFeatureSelector, createSelector } from "@ngrx/store";
import { CourseExchangeDashboardState } from '../reducers';

const selectCourseExchangeDashboardState = createFeatureSelector<CourseExchangeDashboardState>('courseExchange/dashboard');

export const CourseExchangeId = createSelector(
  selectCourseExchangeDashboardState,
  (state) => state.courseExchangeId
)
export const PendingInstitutions = createSelector(
  selectCourseExchangeDashboardState,
  (state) => state.pendingInstitutions
)
export const SelectorForCourseExchangeDashboard = createSelector(
  selectCourseExchangeDashboardState,
  (state) =>{
    const {courseExchangeId, startDate, endDate} = state;
    return{
      currentCourseExchangeId: courseExchangeId,
      currentStartDate: startDate,
      currentEndDate: endDate
    }
  }
)
export const MemberInstitutions = createSelector(
  selectCourseExchangeDashboardState,
  (state) => state.memberInstitutions
)
export const ProviderInstitutions = createSelector(
  selectCourseExchangeDashboardState,
  (state) => state.providerInstitutions
)
export const TotalCourses = createSelector(
  selectCourseExchangeDashboardState,
  (state) => state.totalCourses
)
export const NewCourses = createSelector(
  selectCourseExchangeDashboardState,
  (state) => state.newCourses
)
export const TotalEnrollments = createSelector(
  selectCourseExchangeDashboardState,
  (state) => state.totalEnrollments
)
export const NewEnrollments = createSelector(
  selectCourseExchangeDashboardState,
  (state) => state.newEnrollments
)
export const  IsLoadDashboardData  = createSelector(
  selectCourseExchangeDashboardState,
  (state) => state.isLoadDashboardData
)
