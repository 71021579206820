import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { Store, select } from "@ngrx/store";
import { first, finalize, filter, map } from "rxjs/operators";
import { IAppState } from 'app/store/state/app.state';
import * as fromStudent from '@member/students';
import * as fromFacultyCV from '@provider/facultyCV/store';
import { FacultyCV } from '@shared/institution/models';
import { getInstitutionIdFromRoute } from 'app/helpers/routeParamHelper';
@Injectable()
export class FacultyCVListResolver implements Resolve<FacultyCV[]>{
    isLoading = false;
    constructor(private store: Store<IAppState>){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
        const institutionId = getInstitutionIdFromRoute(route);
        return this.store 
            .pipe(
                select(fromFacultyCV.Selectors.InstitutionId),
                map((currentInstitution) => currentInstitution ===institutionId),
                map((facultyCVListLoaded)=>{
                    if(!this.isLoading && !facultyCVListLoaded){
                        this.isLoading = true;
                        this.store.dispatch(fromFacultyCV.Actions.LoadFacultyCVs({institutionId}));
                    }
                    return true;
                }),
                filter(facultyCVListLoaded => facultyCVListLoaded),
                first(),
                finalize(()=>this.isLoading=false)
            )
    }
}