import {ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {getBgColor, getDefaultBgColor, getDefaultInitials, getInitials} from '@shared/avatar/helpers/shared';

@Component({
  selector: 'elb-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AvatarComponent implements OnInit, OnChanges {
  @Input() name: string;

  firstInitial: string;
  secondInitial: string;
  bgColor: string;

  constructor() {
  }

  ngOnInit(): void {
    this.shapeData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.name.firstChange) {
      this.shapeData();
    }
  }

  private shapeData(): void {
    this.name && typeof this.name === 'string' ? this.shapeRealData() : this.shapeDefaultData();
  }

  private shapeRealData(): void {
    const {firstInitial, secondInitial} = getInitials(this.name);

    this.firstInitial = firstInitial;
    this.secondInitial = secondInitial;
    this.bgColor = getBgColor(firstInitial);
  }

  private shapeDefaultData(): void {
    this.firstInitial = getDefaultInitials().firstInitial;
    this.bgColor = getDefaultBgColor();
  }
}
