

import {createFeatureSelector, createSelector} from '@ngrx/store';
import { AdminUsersState } from '@admin/users/store';
export const selectAdminUsersState = createFeatureSelector<AdminUsersState>('admin/users');

export const Users = createSelector(
    selectAdminUsersState,
    (state) => {
        return state.users;
    }
);


export const IsLoadingUsers = createSelector(
    selectAdminUsersState,
    (state) => state.isLoadingUsers
)

export const IsLoadingUser = createSelector(
    selectAdminUsersState,
    (state) => state.isLoadingUser
)

export const CurrentInstitutionId = createSelector(
    selectAdminUsersState,
    (state) => state.currentInstitutionId
)
