import { UserSummary } from '../models/userSummary.model'
import { ViewContext } from '../models/viewContext.enum'
import { PermissionType } from '../models/permissionType.enum';
import isSuperAdmin from './isSuperAdmin';

export default function (user: UserSummary, entityId: string, permissionType: PermissionType): boolean {
    if(!user) return false;
    if(isSuperAdmin(user)) return true;
    var accessRole = user.accessRoles.find(x=>x.entityId===entityId && x.viewContext===ViewContext.Member);

    return accessRole.permissions.find(x=>x===permissionType) !== undefined;
}