import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
    {
        id       : 'dashboards',
        title    : 'Dashboards',
        type     : 'group',
        children : [
            {
                id       : 'sms_dashboard',
                title    : 'SMS Dashboard',
                type     : 'item',
                icon     : 'supervisor_account',
                url      : '/admin/dashboard'
            }
        ]
    }
];
