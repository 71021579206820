import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'elb-page-list-main-footer',
  templateUrl: './page-list-main-footer.component.html',
  styleUrls: ['./page-list-main-footer.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PageListMainFooterComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }
}
