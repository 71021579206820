import {Component, OnInit, ViewEncapsulation, Input} from '@angular/core';
import {AccessRole} from 'app/authentication/models/accessRole';
import {IAppState} from 'app/store/state/app.state';
import {select, Store} from '@ngrx/store';
import {Member} from '@shared/member/models';
import {PermissionType} from '@auth/models/permissionType.enum';
import {Observable} from 'rxjs';
import * as fromAuth from '@auth/store';
import * as fromMember from '@member/store';
import * as fromMemberDashboard from '@member/dashboard/store';
import * as fromEnrollment from '@member/enrollment/store';
import * as fromMemberBilling from '@member/billing/store';
import * as fromMemberShared from '@shared/member/store';
import * as fromStudents from '@member/students/store';
import { CourseExchangeListItem } from '@shared/courseExchange/models';

@Component({
  selector: 'member-admin-toolbar',
  templateUrl: './member-admin-toolbar.component.html',
  styleUrls: ['./member-admin-toolbar.component.scss', '../styles/common-toolbar.scss'],
  encapsulation: ViewEncapsulation.None
})

export class MemberAdminToolbarComponent implements OnInit {
  @Input() currentAccessRole: AccessRole;

  showCourses: Observable<boolean>;
  showInstitutionalPolicies$: Observable<boolean>;
  showUsers$: Observable<boolean>;
  showBilling$: Observable<boolean>;
  showCalendar$: Observable<boolean>;
  showFees$: Observable<boolean>;
  member$: Observable<Member>;
  showToolbarButtons$: Observable<boolean>;
  currentCourseExchange$: Observable<CourseExchangeListItem>;
  courseExchanges$: Observable<CourseExchangeListItem[]>;
  showStudents$: Observable<boolean>;
  showMemberCourses$: Observable<boolean>;
  showDataMigration$: Observable<boolean>;
  constructor(
    private store: Store<IAppState>,
  ) {
  }

  ngOnInit(): void {
    this.member$ = this.store.pipe(select(fromMember.Selectors.Member))
    this.showToolbarButtons$ = this.store.pipe(select(fromMember.Selectors.ShowToolbarButtons));
    this.showInstitutionalPolicies$ = this.store.pipe(select(fromMember.Selectors.HasInstitutionPermission(PermissionType.ReadInstitutionalPolicy)))
    this.showUsers$ = this.store.pipe(select(fromMember.Selectors.HasInstitutionPermission(PermissionType.ReadUser)));
    this.showBilling$ = this.store.pipe(select(fromMember.Selectors.HasMemberPermission(PermissionType.ViewMemberBilling)));
    this.showCalendar$ = this.store.pipe(select(fromMember.Selectors.HasInstitutionPermission(PermissionType.ReadCalendar)));
    this.showFees$ = this.store.pipe(select(fromMember.Selectors.HasInstitutionPermission(PermissionType.ReadFee)));
    this.currentCourseExchange$ = this.store.pipe(select(fromAuth.Selectors.CurrentCourseExchange));
    this.courseExchanges$ = this.store.pipe(select(fromAuth.Selectors.CourseExchanges));
    this.showStudents$ = this.store.pipe(select(fromMember.Selectors.HasInstitutionPermission(PermissionType.ReadStudentInformation)));
    this.showMemberCourses$ = this.store.pipe(select(fromMember.Selectors.HasMemberPermission(PermissionType.ReadApprovedCourse)));
    this.showDataMigration$ = this.store.pipe(select(fromMember.Selectors.HasInstitutionPermission(PermissionType.ReadStudentInformation)));
  }

  logout(): boolean {
    this.store.dispatch(fromAuth.Actions.logout());
    return false;
  }

  goToUserList(): boolean {
    this.store.dispatch(fromMember.Navigate.UserList({}));
    return false;
  }

  goToDashboard(): boolean {
    this.store.dispatch(fromMemberDashboard.Navigate.Dashboard({}));
    return false;
  }

  goToEnrollments(): boolean {
    this.store.dispatch(fromEnrollment.Navigate.Enrollments({}));
    return false;
  }

  goToFees(): boolean{
    this.store.dispatch(fromMember.Navigate.Fees({}));
    return false;
  }

  goToCalendar(): boolean{
    this.store.dispatch(fromMember.Navigate.Calendar({}));
    return false;
  }

  goToBillingSummary(): boolean{
    this.store.dispatch(fromMemberBilling.Navigate.MemberBillingSummary({}));
    return false;
  }

  goToCourseList(){
    this.store.dispatch(fromMember.Navigate.MemberCourses({}));
    return false;
  }
  goToStudentList(){
    this.store.dispatch(fromStudents.Navigate.StudentList({}));
    return false;
  }

  changeCourseExchange(courseExchangeId: string): void{
    if(this.currentAccessRole.courseExchangeId === courseExchangeId) return;
    this.store.dispatch(fromAuth.Actions.selectCourseExchangeId({courseExchangeId}));
  }

  openDataMigration(){
    const memberId = this.currentAccessRole.entityId;
    const institutionId = this.currentAccessRole.institutionId;
    this.store.dispatch(fromMemberShared.Actions.ShowMigrateData({memberId, institutionId}));
  }
}
