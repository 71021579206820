import { Injectable } from "@angular/core";
import { Store } from '@ngrx/store';
import { IAppState } from 'app/store/state/app.state';
import * as fromFacultyCV from '@provider/facultyCV/store';
import { RealTimeService } from "@core/services/realTime.service";
import { HubConnection } from "@microsoft/signalr";
import { FacultyCV } from "@shared/institution/models";

@Injectable()
export class FacultyCVRealtime{
    constructor(private store: Store<IAppState>, private realTimeService: RealTimeService){
        this.initSignalRMessages();
    }

    private initSignalRMessages(){
        this.realTimeService.hubConnection$.subscribe(hubConnection => {
            if (!hubConnection) return;
            this.facultyCVUpdated(hubConnection);
            this.facultyCVAdded(hubConnection);
        });
    }

    private facultyCVUpdated(hubConnection:HubConnection){
        hubConnection.on('FacultyCVUpdated', (facultyCV: FacultyCV) => {
            this.store.dispatch(fromFacultyCV.Actions.FacultyCVUpdated({ facultyCV }));
        });
    }
    private facultyCVAdded(hubConnection:HubConnection){
        hubConnection.on('FacultyCVAdded', (facultyCV: FacultyCV) => {
            this.store.dispatch(fromFacultyCV.Actions.FacultyCVAdded({ facultyCV }));
        });
    }
}