<elb-page-list-container [kind]="'member'" class="page-list-member-student-list">
  <!-- <elb-page-header [pageTitle]="'Students'"
                   [pageSubTitle]="''"
                   (returnToDashboardClicked)="goToDashboard()">
    <button class="btn--icon btn--member-admin" mat-button (click)="addStudent()">
      <mat-icon>add</mat-icon>Add New Student
    </button>
  </elb-page-header> -->

  <elb-page-list-content>

    <elb-page-list-sidebar>
      <mat-accordion>
        <mat-expansion-panel *ngIf="(statusFilters$ | async)?.length>0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 [matBadge]="statusFiltersCount$ | async" matBadgeOverlap="false">Status</h3>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="side-panel-list">
            <mat-selection-list (selectionChange)="onToggleStatusFilter($event)">
              <mat-list-option [value]="status.id" [selected]="status.selected"
                               *ngFor="let status of statusFilters$ | async; trackBy: trackBy">
                {{ status.descriptionForView }}
              </mat-list-option>
            </mat-selection-list>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </elb-page-list-sidebar>

    <elb-page-list-main>
      <elb-page-header [pageTitle]="'Students'"
        [pageSubTitle]="''"
        (returnToDashboardClicked)="goToDashboard()">
        <button class="btn--icon btn--member-admin" mat-button (click)="addStudent()">
          <mat-icon>add</mat-icon>Add New Student
        </button>
      </elb-page-header>

      <elb-page-list-main-header>
        <div class="search-and-sort-list" fxLayout="row" fxLayoutAlign="space-between center">
          <!-- SEARCH -->
          <div class="search-wrapper">
            <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
              <mat-icon>search</mat-icon>
              <input #filter placeholder="Search Students">
            </div>
          </div>
          <!-- / END SEARCH -->

          <!-- SORT MENU -->
          <div class="sort-wrapper" *ngIf="!(IsStudentsLoading$ | async)">
            <mat-form-field class="full-list-sort">
              <mat-select placeholder="Sort By" [value]="currentSort$ | async"
                          (selectionChange)="changeSort($event.value)">
                <mat-select-trigger>
                  <mat-icon>{{(currentSort$ | async)?.icon}}</mat-icon>&nbsp;{{(currentSort$ | async)?.name}}
                </mat-select-trigger>
                <mat-option *ngFor="let sort of sorts$ | async" [value]="sort">
                  <mat-icon>{{sort.icon}}</mat-icon>{{sort.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="full-list-sort">
              <mat-select placeholder="Order By" [value]="currentOrder$ | async"
                          (selectionChange)="changeOrderBy($event.value)">
                <mat-select-trigger>
                  <mat-icon>{{(currentOrder$ | async)?.icon}}</mat-icon>&nbsp;{{(currentOrder$ | async)?.name}}
                </mat-select-trigger>
                <mat-option *ngFor="let order of orders" [value]="order">
                  <mat-icon>{{order.icon}}</mat-icon>{{order.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <!-- /END SORT -->
        </div>
        <!-- FILTERS  -->
        <div class="filters-wrapper" fxLayout="row" fxLayoutAlign="space-between center">
          <div class="list-select-all">
            <mat-checkbox (change)="masterToggle()"
                          [checked]="isAllSelected$ | async"
                          [indeterminate]="indeterminateSelected$ | async">
              Select All Students ({{selectedStatus$ | async}})
            </mat-checkbox>
          </div>
          <div class="full-filters-chosen">
            <mat-chip-list>
              <mat-chip *ngFor="let filter of selectedFilters$ | async" [selectable]="false" [removable]="true"
                        (removed)="removeFilter(filter)">
                {{filter.description}}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
            </mat-chip-list>
          </div>
        </div>
      </elb-page-list-main-header>

      <elb-page-list-main-body>
        <div class="student-list">
          <div class ="list-item--custom" [ngClass]="['student-item', student.isActive ? 'is_active' : '']"
               *ngFor="let student of filteredStudents$ | async; trackBy: studentById">
            <div class="student-checkbox">
              <mat-checkbox (change)="onToggleStudent(student.id)" [checked]="student.selected"></mat-checkbox>
            </div>

            <div class="student-menu">
              <button mat-icon-button [matMenuTriggerFor]="selectMenu">
                <mat-icon>more_horiz</mat-icon>
              </button>

              <mat-menu #selectMenu="matMenu">
                <button mat-menu-item (click)="goToStudentDetail(student.id)">View Student Details</button>
                <button mat-menu-item (click)="goToEditStudent(student.id)">Edit Student Details</button>
                <button mat-menu-item (click)="deleteStudent(student)" *ngIf="canDeleteStudent(student)">Delete Student</button>
                <button mat-menu-item (click)="activateStudent(student.id)" *ngIf="canActivateStudent(student)">Activate Student</button>
                <button mat-menu-item (click)="deactivateStudent(student.id)" *ngIf="canDeactivateStudent(student)">Deactivate Student</button>
              </mat-menu>
            </div>

            <div class="student-content">
              <div class="student-col student-col-left">
                <div class="student-info">
                  <div class="student-info-top">
                    <div class="student-info-top-logo">
                      <img src="{{student.institutionLogoUrl}}">
                    </div>
                    <div class="student-info-top-box">
                      <div class="student-name ellipsis">{{student.lastName}},&nbsp;{{student.firstName}} - {{student.studentNumber}}
                      </div>
                      <div class="student-id ellipsis">{{student.institutionName}}</div>
                    </div>
                  </div>

                  <div class="student-email">
                    <a href="mailto:{{student.emailAddress}}">
                      <mat-icon>mail</mat-icon><span class="ellipsis">{{student.emailAddress}}</span>
                    </a>
                  </div>
                  <div class="student-phone">
                    <a href="tel:{{student.phoneNumber}}" class="ellipsis">
                      <mat-icon>phone</mat-icon><span>{{student.phoneNumber}}</span>
                    </a>
                  </div>
                </div>
              </div>
              <div class="student-col student-col-right">
                <div class="enrollment">
                  <div class="enrollment-title">Enrollment Information:</div>
                  <div class="enrollment-content">
                    <div class="enrollment-row" *ngFor="let enrollment of student.enrollments">
                      {{getEnrollmentDescription(enrollment)}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </elb-page-list-main-body>
      <app-loading-spinner  *ngIf="(IsStudentsLoading$ | async)"></app-loading-spinner>
      <elb-page-list-main-footer>
        <ng-content></ng-content>
        <button class="btn--member-admin btn-footer" mat-button (click)="exportToExcel()"
                [disabled]="!(canExportToExcel$ | async)">
          <mat-icon>save_alt</mat-icon>Export to Excel
        </button>
        <button class="btn--member-admin btn-footer" mat-button
                (click)="downloadStudentBulkImportTemplate()">
          <mat-icon>cloud_download</mat-icon> Bulk Student Template
        </button>
        <input hidden type="file" (change)="importStudents($event)" #fileInput />
        <button class="btn--member-admin btn-footer" mat-button (click)="fileInput.click()"
                [disabled]="downloading">
          <mat-icon>cloud_upload</mat-icon> Bulk Student Upload
        </button>

        <button class="btn--member-admin btn-footer"
                *ngIf="canEnrollStudents$ | async"
                [disabled]="!(selectedStudents$ | async)?.length"
                mat-button
                (click)="enrollStudent()">
          <mat-icon>person_add</mat-icon>Enroll Student
        </button>
      </elb-page-list-main-footer>

    </elb-page-list-main>
  </elb-page-list-content>
</elb-page-list-container>
