import {Filter, FilterType} from '@shared/common/models';

export default  (enumerator: string[], filterType: FilterType): Filter[] => {
  return enumerator.map(item => {
    return {
      id: item,
      description: item,
      type: filterType,
      selected: false,
      descriptionForView: item
    }
  });
}
