import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Course } from '../../../shared/institution/models/course.model';
import { BaseResponse, ElbertFile } from 'app/core/models';
import { ProviderCourseListItem } from '@provider/courses/models/providerCourseListItem.model';
import { ConfigService } from '@core/services/config.service';
import { saveAs } from 'file-saver';

@Injectable()
export class CourseService {
  private apiUrl:string='';
  constructor(private http:HttpClient, private config:ConfigService){
    this.config.apiUrl$.subscribe(result=>this.apiUrl=`${result}/courseExchange`);
  }

  public getCourses(courseExchangeId: string): Observable<ProviderCourseListItem[]>{
    return this.http.get<ProviderCourseListItem[]>(`${this.apiUrl}/${courseExchangeId}/courses`);
  }

  public getCourseById(courseExchangeId: string, courseId: string): Observable<Course>
  {
    return this.http.get<Course>(`${this.apiUrl}/${courseExchangeId}/courses/${courseId}`);
  }

  public approveCourses(courseExchangeId: string, courseIds: string[]): Observable<BaseResponse>{
   return this.http.post<BaseResponse>(`${this.apiUrl}/${courseExchangeId}/courses/approve`, {providerCourseIds: courseIds});
  }

  public disapproveCourses(courseExchangeId: string, courseIds: string[]): Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.apiUrl}/${courseExchangeId}/courses/disapprove`, {providerCourseIds: courseIds});
  }

  public downloadSyllabus(courseExchangeId: string, courseId: string, file: ElbertFile) {    
    return this.http.get(`${this.apiUrl}/${courseExchangeId}/courses/syllabus/download/${courseId}`, { responseType: "blob" })  
      .subscribe((result: any) => {  
        if (result.type != 'text/plain') {  
          var blob = new Blob([result]);  
            
          saveAs(blob, file.fileName);  
        }  
      }  
      );  
  }  
}
