import {Injectable} from '@angular/core';
import {Actions, Effect, ofType, createEffect} from '@ngrx/effects';
import {catchError, map, mergeMap} from 'rxjs/operators';
import {of} from 'rxjs';
import {CalendarService} from '../../services/calendar.service';
import { InstitutionService } from 'app/shared/institution/services/institution.service';
import * as fromCalendar from '@shared/calendar/store';
@Injectable()
export class CalendarEffects {
  @Effect()
  loadCalendar$ = this.actions$.pipe(
    ofType(fromCalendar.Actions.loadCalendar),
    mergeMap((action) => {
      const parentId = action.payload;
      return this.calendarService.getAcademicYears(parentId).pipe(
        map(academicYears => (fromCalendar.Actions.loadedCalendarSuccess({
          academicYears,
          parentId
        }))),
        catchError(() => of(fromCalendar.Actions.loadedCalendarError()))
      );
    })
  );
  
  loadSessionDateRules$ = createEffect(() => {
    return this.actions$.pipe(
        ofType(fromCalendar.Actions.loadSessionDateRules),
        mergeMap((action) => {
          const parentId = action.payload;
          return this.institutionService.getSessionDateRules(parentId).pipe(
            map(sessionDateRules => (fromCalendar.Actions.loadedSessionDateRulesSuccess({
              payload: sessionDateRules
            }))),
            catchError(()=> of(fromCalendar.Actions.loadedSessionDateRulesError()))
          )
        }));
  });

  deleteAcademicYear$ = createEffect(() => {
    return this.actions$.pipe(
        ofType(fromCalendar.Actions.deleteAcademicYear),
        mergeMap((action) => {
          const {parentId, academicYearId} = action.payload;
          return this.calendarService.deleteAcademicYear(parentId, academicYearId)
            .pipe(
              map(response => {
                if(response.isSuccess){
                  return fromCalendar.Actions.loadCalendar({payload: parentId});
                }else{
                  return fromCalendar.Actions.deleteAcademicYearError({payload: response.message});
                }
              })
            )
        })
      )
  });

  deleteSemester$ = createEffect(() => {
    return this.actions$.pipe(
        ofType(fromCalendar.Actions.deleteSemester),
        mergeMap((action) => {
          const {parentId, semesterId} = action.payload;
          return this.calendarService.deleteSemester(parentId, semesterId)
            .pipe(
              map(response => {
                if(response.isSuccess){
                  return fromCalendar.Actions.loadCalendar({payload: parentId});
                }else{
                  return fromCalendar.Actions.deleteSemesterError({payload: response.message});
                }
              })
            )
        })
      )
  });

  deleteSession$ = createEffect(() => {
    return this.actions$.pipe(
        ofType(fromCalendar.Actions.deleteSession),
        mergeMap((action) => {
          const {parentId, sessionId} = action.payload;
          return this.calendarService.deleteSession(parentId, sessionId)
            .pipe(
              map(response => {
                if(response.isSuccess){
                  return fromCalendar.Actions.loadCalendar({payload: parentId});
                }else{
                  return fromCalendar.Actions.deleteSessionError({payload: response.message});
                }
              })
            )
        })
      )
  });


  constructor(
    private actions$: Actions,
    private calendarService: CalendarService,
    private institutionService: InstitutionService
  ) {
  }
}
