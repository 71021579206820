import { createReducer, on } from '@ngrx/store';
import * as fromProviderBilling from '@provider/billing/store';
import * as fromProvider from '@provider/store'
import { ProviderMonthlyPayoutStatement } from '@provider/billing/models';

  export interface ProviderBillingState {
    providerId: string;
    monthlyStatements: Array<ProviderMonthlyPayoutStatement>;
    isMonthlyStatementsLoading: boolean;
    areMonthlyStatementsLoaded: boolean;
    currentProviderMonthlyPayoutStatement: ProviderMonthlyPayoutStatement;
    isProviderMonthlyPayoutStatementLoading: boolean;
  };
  
  export const initialProviderBillingState: ProviderBillingState = {
    providerId: '',
    monthlyStatements: [],
    isMonthlyStatementsLoading: false,
    areMonthlyStatementsLoaded: false,
    currentProviderMonthlyPayoutStatement: undefined,
    isProviderMonthlyPayoutStatementLoading: false
  };
  
  export const providerBillingReducer = createReducer(
    initialProviderBillingState,
    on(fromProvider.Actions.LoadProviderSuccess, (state, {provider})=>{
        return{
            ...initialProviderBillingState,
            providerId: provider.id
        }
    }),
    on(fromProviderBilling.Actions.LoadMonthlyStatementsSuccess, (state, {monthlyStatements})=>{
      if(monthlyStatements.length>0){
        const currentProviderMonthlyPayoutStatement = monthlyStatements[0];
        return {
          ...state,
          monthlyStatements,
          currentProviderMonthlyPayoutStatement,
          areMonthlyStatementsLoaded: true,
          isMonthlyStatementsLoading: false
        }
      }else{
        return {
          ...state,
          areMonthlyStatementsLoaded: true,
          isMonthlyStatementsLoading: false
        }
      } 
    }),
    on(fromProviderBilling.Actions.LoadMonthlyStatementsError, (state, action)=>{
        return {
            ...state,
            monthlyStatements: [],
            areMonthlyStatementsLoaded: false,
            isMonthlyStatementsLoading: false
        }
    }),
    on(fromProviderBilling.Actions.ProviderMonthlyPayoutStatementAdded, (state, {providerMonthlyPayoutStatement})=>{
      const idx = state.monthlyStatements.findIndex(x=>x.id===providerMonthlyPayoutStatement.id);
      let monthlyStatements = new Array<ProviderMonthlyPayoutStatement>();
      if(idx===-1){
        monthlyStatements = [...state.monthlyStatements, providerMonthlyPayoutStatement];
      }else{
        monthlyStatements = [...state.monthlyStatements.slice(0,idx),providerMonthlyPayoutStatement,...state.monthlyStatements.slice(idx+1)];
      }
      return {
        ...state,
        monthlyStatements
      }
    }),
    on(fromProviderBilling.Actions.ProviderMonthlyPayoutStatementUpdated, (state, {providerMonthlyPayoutStatement})=>{
      const idx = state.monthlyStatements.findIndex(x=>x.id===providerMonthlyPayoutStatement.id);
      const monthlyStatements = [...state.monthlyStatements.slice(0,idx),providerMonthlyPayoutStatement,...state.monthlyStatements.slice(idx+1)];
      
      return {
        ...state,
        monthlyStatements
      }
    })
  );
  
  
  