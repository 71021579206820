import * as fromStore from '@member/students/store';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
@NgModule({
    imports: [
        StoreModule.forFeature('member/students', fromStore.studentsReducer),
        EffectsModule.forFeature([...fromStore.EFFECTS])
    ],
    providers: [...fromStore.RESOLVERS]
})
export class StudentStoreModule { }
