import { Component, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AccessRole } from 'app/authentication/models/accessRole';
import { InstitutionRole, CourseExchangeRole } from 'app/core/referenceData';
import { PermissionType } from 'app/authentication/models/permissionType.enum';
import { PermissionTypeSelectList } from 'app/authentication/models/permissionTypeSelectList';
import { FormBuilder, FormGroup, Validators, FormArray, FormControl } from '@angular/forms';
import { PermissionDisplay } from 'app/authentication/models/permissionDisplay';
import { PermissionTypeSelectItem } from 'app/authentication/models/permissionTypeSelectItem';
import { ViewContext } from 'app/authentication/models/viewContext.enum';
import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Course } from 'app/shared/institution/models/course.model';
import { ConfirmDialogData, ConfirmDialogComponent } from 'app/core/components/confirm-dialog/confirm-dialog.component';
import { ActivateUserCommand } from 'app/shared/user/commands';
import { tap } from 'rxjs/operators';
import { IAppState } from 'app/store/state/app.state';
import { Store, select } from '@ngrx/store';
import { Observable } from 'rxjs';
import * as fromRefData from '@refData';

export interface PermissionDialogData {
  accessRole: AccessRole;
  basePermissions: PermissionDisplay[];
  viewContext: ViewContext;
}

@Component({
  selector: 'user-permissions',
  templateUrl: 'permissions.dialog.html',
})

export class PermissionsDialogComponent implements AfterViewInit {
  permissionList: PermissionTypeSelectList;
  selection: SelectionModel<PermissionTypeSelectItem> = new SelectionModel<PermissionTypeSelectItem>(true, []);
  dataSource: MatTableDataSource<PermissionTypeSelectItem>;
  displayedColumns: string[] = ['isSelected', 'permission'];
  institutionRoles$: Observable<InstitutionRole[]>;
  courseExchangeRoles$: Observable<CourseExchangeRole[]>;
  viewContext: ViewContext;
  institutionRoles: InstitutionRole[];
  institutionRole: InstitutionRole;
  courseExchangeRoles: CourseExchangeRole[];
  courseExchangeRole: CourseExchangeRole;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  constructor(
    public dialogRef: MatDialogRef<PermissionsDialogComponent>,
    private dialog: MatDialog,
    private store: Store<IAppState>,
    @Inject(MAT_DIALOG_DATA) public data: PermissionDialogData
  ) {
    this.setupSelectedPermissions(data.basePermissions, data.accessRole.permissions);
    this.viewContext = data.viewContext
    this.institutionRoles$ = this.store.pipe(select(fromRefData.institutionRoles));
    this.institutionRoles$.subscribe(result=>{
      this.institutionRoles=result;
      this.institutionRole = this.institutionRoles.find(x=>x.id===data.accessRole.roleId);
    });
    this.courseExchangeRoles$ = this.store.pipe(select(fromRefData.courseExchangeRoles));
    this.courseExchangeRoles$.subscribe(result=>{
      this.courseExchangeRoles=result;
      this.courseExchangeRole = this.courseExchangeRoles.find(x=>x.id===data.accessRole.roleId);
    });
  }

  setupSelectedPermissions(basePermissions: PermissionDisplay[], selectedPermissions: PermissionType[]) {
    this.permissionList = new PermissionTypeSelectList(basePermissions, selectedPermissions);
    var selected = this.permissionList.permissionTypeSelectItems.filter(x => x.isSelected);
    this.selection = new SelectionModel<PermissionTypeSelectItem>(true, selected);
    this.dataSource = new MatTableDataSource<PermissionTypeSelectItem>(this.permissionList.permissionTypeSelectItems);
    this.dataSource.paginator = this.paginator;
  }

  changeInstitutionRole(role:InstitutionRole): void{
    this.institutionRole = role;
    this.setupSelectedPermissions(this.data.basePermissions, role.permissions);
  }

  changeCourseExchangeRole(role:CourseExchangeRole): void{
    this.courseExchangeRole=role;
    let permissions: PermissionType[];
    if (this.viewContext === ViewContext.Member)
      permissions = role.memberPermissions;
    else if (this.viewContext === ViewContext.Provider)
      permissions = role.providerPermissions;
    this.setupSelectedPermissions(this.data.basePermissions, permissions);
  }

  get showInstitutionRole(): boolean {
    return this.viewContext === ViewContext.Institution;
  }

  get showCourseExchangeRole(): boolean {
    return this.viewContext === ViewContext.Member || this.viewContext === ViewContext.Provider;
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
  }

  isAllSelected(): boolean {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle(): void {
    this.isAllSelected() ?
      this.removeAll() :
      this.selectAll();
  }

  get selectedStatus(): string {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return `${numSelected} of ${numRows}`
  }

  toggle(permission: PermissionTypeSelectItem): void {
    permission.toggleSelected();
  }

  private removeAll() {
    this.selection.clear();
  }
  private selectAll() {
    this.selection.select(...this.permissionList.permissionTypeSelectItems);
  }

  closeDialogPermissions(): void {
    this.dialogRef.close(null);
  }

  saveAccessRole(): void {
    let accessRole = { ...this.data.accessRole };
    let permissions = this.selection.selected.map(s => s.permissionType);
    switch (this.viewContext) {
      case ViewContext.Institution:
        accessRole.roleId = this.institutionRole.id;
        accessRole.permissions = permissions;
        this.saveInstitutionAccessRole(accessRole);
        break;
      case ViewContext.Provider:
      case ViewContext.Member:
        accessRole.roleId = this.courseExchangeRole.id;
        accessRole.permissions = permissions;
        this.dialogRef.close({ accessRole: accessRole, changeAllRoles: false });
        break;
      case ViewContext.CourseExchangeAdmin, ViewContext.SuperAdmin:
        accessRole.permissions = permissions;
        this.dialogRef.close({ accessRole: accessRole, changeAllRoles: false });
        break;
    }
  }

  saveInstitutionAccessRole(accessRole: AccessRole): void {
    const dialogData: ConfirmDialogData = {
      title: "Change All Access Roles",
      message: `You are changing this user’s permissions.  This change will be applied to all roles this user may have.  Do you want to continue?`,
      cancelButtonText: "No",
      okButtonText: "Yes"
    };

    const confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px;",
      data: dialogData
    });

    confirmDialogRef.afterClosed().subscribe(confirmResult => {
      if (confirmResult) {
        var result = {
          accessRole: accessRole,
          changeAllRoles: confirmResult
        };
        this.dialogRef.close(result);
      }else{
        this.closeDialogPermissions();
      }
    })
  }
}