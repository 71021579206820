import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromCourseExchangeCourses from '@courseExchange/courses/store';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature('courseExchange/courses', fromCourseExchangeCourses.courseExchangeCoursesReducer),
    EffectsModule.forFeature([...fromCourseExchangeCourses.EFFECTS])
  ],
  providers: [...fromCourseExchangeCourses.RESOLVERS]
})
export class CourseExchangeCoursesStoreModule { }
