import { createEffect, Actions, ofType } from "@ngrx/effects";
import { Injectable } from "@angular/core";
import { map, concatMap, withLatestFrom, tap } from "rxjs/operators";
import * as fromMember from '@member/store';
import { of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { IAppState } from 'app/store/state/app.state';
import { Router } from '@angular/router';
@Injectable()
export class MemberNavigateEffects{
    constructor(private actions$: Actions, private store:Store<IAppState>, private router: Router){
            }
    Calendar$ = createEffect(() => {
                return this.actions$.pipe(
                        ofType(fromMember.Navigate.Calendar),
                        concatMap(action => of(action).pipe(
                                withLatestFrom(this.store.pipe(select(fromMember.Selectors.Member)))
                            )),
                        tap(([action,member]) => {  
                            const memberId = !!action.memberId ? action.memberId : member.id;
                            const institutionId = !!action.institutionId ? action.institutionId: member.institutionId;
                           this.router.navigate([`/member/${memberId}/${institutionId}/calendar`])
                        })
                )
            },{dispatch : false});

    Fees$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromMember.Navigate.Fees),
                concatMap(action => of(action).pipe(
                        withLatestFrom(this.store.pipe(select(fromMember.Selectors.Member)))
                    )),
                tap(([action,member]) => {  
                    const memberId = !!action.memberId ? action.memberId : member.id;
                    const institutionId = !!action.institutionId ? action.institutionId: member.institutionId;
                   this.router.navigate([`/member/${memberId}/${institutionId}/fees`])
                })
        )
    },{dispatch : false});

    MemberCourses$ = createEffect(() => {
        return this.actions$.pipe(
          ofType(fromMember.Navigate.MemberCourses),
          concatMap(action => of(action).pipe(
            withLatestFrom(this.store.pipe(select(fromMember.Selectors.Member)))
          )),
          map(([action, member]) => {
            const memberId = !!action.memberId ? action.memberId : member.id;
            const institutionId = !!action.memberId ? action.memberId : member.institutionId;
    
            return this.router.navigate(['member',
              memberId,
              institutionId,
              'memberCourses',
              'list']);
          }
          ))
      }, { dispatch: false });

      UserList$ = createEffect(() => {
        return this.actions$.pipe(
          ofType(fromMember.Navigate.UserList),
          concatMap(action => of(action).pipe(
            withLatestFrom(this.store.pipe(select(fromMember.Selectors.Member)))
          )),
          map(([action, member]) => {
            const memberId = !!action.memberId ? action.memberId : member.id;
            const institutionId = !!action.memberId ? action.memberId : member.institutionId;
    
            return this.router.navigate(['member',
              memberId,
              institutionId,
              'users']);
          }
          ))
      }, { dispatch: false });

    
}