import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BaseResponse } from '../models/baseResponse.model';

@Injectable({
  providedIn: 'root'
})
export class PopupService {

  constructor(private snack: MatSnackBar) { }

  public openSnackBar(message: string, action:string): void {
    this.snack.open(message, action, {
      duration: 5000
    });
  }

  public handleBaseResponse(response: BaseResponse): void{
    var message = response.isSuccess ? response.message : `Error - ${response.message}`;
    if(message.trim()==='')return;
    this.openSnackBar(message, 'Ok');
  }
}
