import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { PopupService } from 'app/core/services/popup.service';
import { ConfigService } from '@core/services/config.service';
import { BaseResponse } from '@core/models';

@Injectable({providedIn:'root'})
export class InstitutionService {
  private apiUrl:string = '';
  constructor(private http:HttpClient, private popupService: PopupService,private config:ConfigService) {
    this.config.apiUrl$.subscribe(result=>this.apiUrl=result);
  }

  public institutionNameExists(institutionId: string,institutionName: string): Observable<boolean>{
    if(institutionName.trim()==="") return of(false);
    return this.http.get<boolean>(`${this.apiUrl}/institution/${institutionId}/institutionNameExists/${institutionName}`);
  }
}
