import {ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {fromEvent, Observable, of} from 'rxjs';
import {Router} from '@angular/router';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {Order, Sort} from 'app/core/models';
import {debounceTime, distinctUntilChanged, finalize, map, switchMap, tap} from 'rxjs/operators';
import {ExcelColumn, ExcelService} from 'app/core/services/excel.service';
import {Filter} from 'app/shared/common/models';
import {StudentListItem} from 'app/shared/student/models';
import {MatSelectionListChange} from '@angular/material/list';
import {FuseProgressBarService} from '@fuse/components/progress-bar/progress-bar.service';
import {IAppState} from 'app/store/state/app.state';
import {select, Store} from '@ngrx/store';
import * as fromEnrollment from '@member/enrollment/store';
import * as fromMemberCourses from '@member/memberCourse/store';
import {
  NewEnrollmentDialogContainerComponent,
  NewEnrollmentDialogContainerData
} from '@member/enrollment/components';
import {ENewEnrollmentTypeFrom} from '@member/enrollment/constants/shared';
import * as fromStudent from '@member/students/store';
import * as fromMember from '@member/store';
import * as fromMemberDashboard from '@member/dashboard/store';
import { Member } from '@shared/member/models';
import { EnrollmentListItem } from '@member/enrollment';
import * as moment from 'moment';
import { DeactivateStudentCommand, ActivateStudentCommand } from '@shared/student/commands';
import { PermissionType } from '@auth/models/permissionType.enum';
import { EnrollmentInformation } from '@shared/student/models/enrollmentInformation.model';
@Component({
  selector: 'student-list',
  templateUrl: './student-list.component.html',
  styleUrls: ['./student-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class StudentListComponent implements OnInit {
  pageTitle = 'Students';
  pageSubTitle = '';
  panelOpenState = false;
  downloading = false;

  sorts: Sort[] = [
    { name: 'Last Name', icon: 'person_pin', value:'lastName', isDefault: true},
    { name: 'First Name', icon: 'person_pin', value: 'firstName', isDefault: false },
    { name: 'Student Number', icon: 'import_export', value: 'studentNumber', isDefault: false }
  ];
  orders: Order[] = [
    { name: 'Ascending', icon: 'arrow_upward', value: '', isDefault: true },
    { name: 'Descending', icon: 'arrow_downward', value: '-', isDefault: false },
  ];

  currentOrder$: Observable<Order>;
  currentSort$: Observable<Sort>;
  students$: Observable<StudentListItem[]>;
  filteredStudents$: Observable<StudentListItem[]>;
  selectedStudents$: Observable<StudentListItem[]>;
  canExportToExcel$:Observable<boolean>;
  canEnrollStudents$: Observable<boolean>;
  selectedStatus$: Observable<string>;
  isAllSelected$: Observable<boolean>;
  indeterminateSelected$: Observable<boolean>;
  statusFilters$: Observable<Filter[]>;
  selectedFilters$: Observable<Filter[]>;
  statusFiltersCount$: Observable<number>;
  sorts$: Observable<Sort[]>;
  orders$: Observable<Order[]>;
  member$: Observable<Member>;
  IsStudentsLoading$: Observable<boolean>;
  @ViewChild("filter") filter: ElementRef;

  constructor(
    public dialog: MatDialog,
    public excelService: ExcelService,
    private fuseProgressBarService: FuseProgressBarService,
    private router: Router,
    private store: Store<IAppState>) { }

  ngOnInit(): void {
    this.students$ = this.store.pipe(select(fromStudent.Selectors.Students));
    this.IsStudentsLoading$ = this.store.pipe(select(fromStudent.Selectors.IsStudentsLoading));
    this.filteredStudents$ = this.store.pipe(select(fromStudent.Selectors.FilteredStudents));
    this.selectedStatus$ = this.store.pipe(select(fromStudent.Selectors.SelectedStatus));
    this.selectedStudents$ = this.store.pipe(select(fromStudent.Selectors.SelectedStudents));
    this.canExportToExcel$ = this.store.pipe(select(fromStudent.Selectors.CanExportToExcel));
    this.canEnrollStudents$ = this.store.pipe(select(fromMember.Selectors.HasInstitutionPermission(PermissionType.ReadStudentInformation)));
    this.currentSort$ = this.store.pipe(select(fromStudent.Selectors.CurrentSort));
    this.currentOrder$ = this.store.pipe(select(fromStudent.Selectors.CurrentOrder));
    this.isAllSelected$ = this.store.pipe(select(fromStudent.Selectors.IsAllSelected));
    this.indeterminateSelected$ = this.store.pipe(select(fromStudent.Selectors.IndeterminateSelected));
    this.statusFilters$ = this.store.pipe(select(fromStudent.Selectors.StatusFiltersWithCounts));
    this.statusFiltersCount$ = this.store.pipe(select(fromStudent.Selectors.StatusFiltersCount));
    this.selectedFilters$ = this.store.pipe(select(fromStudent.Selectors.SelectedStatusFilters));
    this.sorts$ = this.store.pipe(select(fromStudent.Selectors.Sorts));
    this.orders$ = this.store.pipe(select(fromStudent.Selectors.Orders));
    this.member$ = this.store.pipe(select(fromMember.Selectors.Member));
    this.store.dispatch(fromStudent.Actions.LoadSorts({sorts: this.sorts}));
    this.store.dispatch(fromStudent.Actions.LoadOrders({orders: this.orders}));
    this.store.dispatch(fromStudent.Actions.ChangeSearchText({searchText:''}));
    this.store.dispatch(fromStudent.Actions.ClearSelected());
  }

  ngAfterViewInit(){
    this.setupTextSearch();
  }
  addStudent(): void {
    this.store.dispatch(fromStudent.Navigate.StudentNew({}));
  }
  changeOrderBy(order: Order): void {
    this.store.dispatch(fromStudent.Actions.ChangeOrder({currentOrder: order}))
  }
  changeSort(sort: Sort): void {
    this.store.dispatch(fromStudent.Actions.ChangeSort({currentSort: sort}))

  }
  downloadStudentBulkImportTemplate(): void
  {
    this.fuseProgressBarService.show();
    this.downloading = true;
    this.excelService.createStudentBulkImportTemplate()
    .pipe(
      tap(data=>{
        const blob = new Blob([data],{type:"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"});
        const linkDomObject: HTMLAnchorElement = document.createElement('a');
        linkDomObject.href = URL.createObjectURL(blob);
        linkDomObject.download = `StudentBulkImportTemplate_${Date.now().toString()}.xlsx`;
        document.body.appendChild(linkDomObject);
        linkDomObject.click();
        linkDomObject.remove();
      }),
      finalize(()=>{
        this.fuseProgressBarService.hide();
        this.downloading = false;
      })
    )
    .subscribe();
  }
  exportToExcel(): void {
    const columns: ExcelColumn[] = new Array<ExcelColumn>(
      {header: 'Student ID', fieldName: 'studentId'},
      {header: 'First Name', fieldName: 'firstName'},
      {header: 'Last Name', fieldName: 'lastName'},
      {header: 'Email Address', fieldName: 'emailAddress'},
      {header: 'Phone Number', fieldName: 'phoneNumber'},
    );
    this.store.dispatch(fromStudent.Actions.ExportToExcel({columns, fileName: 'Students'}))
  }
  goToDashboard(): void {
        this.store.dispatch(fromMemberDashboard.Navigate.Dashboard({}));
  }
  goToEditStudent(studentId: string): void {
   this.store.dispatch(fromStudent.Navigate.StudentEdit({studentId}));
  }
  goToStudentDetail(studentId:string): void {
    this.store.dispatch(fromStudent.Navigate.StudentDetail({studentId}));
  }
  canDeleteStudent(student: StudentListItem): boolean{
    return !!student.enrollments && student.enrollments.length===0;
  }
  deleteStudent(student: StudentListItem): void {
    this.store.dispatch(fromStudent.Actions.ConfirmDelete({student}));
  }
  canActivateStudent(student: StudentListItem): boolean{
    return student.isActive===false;
  }
  activateStudent(studentId:string): void{
    var command:ActivateStudentCommand = {studentId};
    this.store.dispatch(fromStudent.Actions.ActivateStudent({command}));
  }
  canDeactivateStudent(student: StudentListItem): boolean{
    return student.isActive;
  }
  deactivateStudent(studentId:string): void{
    var command: DeactivateStudentCommand = {studentId};
    this.store.dispatch(fromStudent.Actions.DeactivateStudent({command}));
  }

  importStudents(event: any): void {
    this.member$.pipe(
      tap((member)=>{
        const file = event.target.files[0];
        const institutionId = member.institutionId;
        this.excelService.importStudents(member.institutionId, file, (commands)=>
          this.store.dispatch(fromStudent.Actions.ImportStudents({institutionId, commands})));
      })
    ).subscribe();

  }

  masterToggle(): void {
    this.store.dispatch(fromStudent.Actions.MasterToggle({}));
  }
  
  removeFilter(filter: Filter): void {
    this.store.dispatch(fromStudent.Actions.RemoveFilter({filter}))
  }
  setupTextSearch(): void{
    fromEvent(this.filter.nativeElement, "keyup").pipe(
      map((event: any) => event.target.value),
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe((searchText: string) => this.store.dispatch(fromStudent.Actions.ChangeSearchText({searchText})))
  }
  onToggleStatusFilter(event:MatSelectionListChange): void{
    const id = event.options[0].value
    this.store.dispatch(fromStudent.Actions.ToggleStatusFilter({id}));
  }
  onToggleStudent(studentId: string): void{
    this.store.dispatch(fromStudent.Actions.ToggleStudent({studentId}));
  }
  studentById(index:number, student: StudentListItem){
    return student.id;
  }

  enrollStudent(): void {
    this.store.dispatch(fromEnrollment.Actions.EnrollStudent({fromType: ENewEnrollmentTypeFrom.fromStudents}));
  }

  getEnrollmentDescription(enrollment: EnrollmentInformation): string{
    if(enrollment.memberCourseCode != ""){
      return `${enrollment.memberCourseCode}, ${enrollment.courseName}, ${enrollment.sessionCode}: ${enrollment.sessionDates}`;
    }else{
      return `${enrollment.courseName}, ${enrollment.sessionCode}: ${enrollment.sessionDates}`;
    }
  }
}
