<mat-toolbar>
  <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <span class="title dialog-title">Select Report(s)</span>
    <button mat-icon-button (click)="close()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div class="mat-dialog-container">
  <div class="content">
    <div class="reports">
      <div >
        <div>
          <mat-checkbox [checked]="exchangeSummaryReport" (change)="updateExchangeSummaryReport($event)">Exchange Summary Report</mat-checkbox>
        </div>
        <div>
          <mat-checkbox [checked]="memberReport" (change)="updateMemberReport($event)">Detailed Member Report</mat-checkbox>
        </div>
        <div>
          <mat-checkbox [checked]="providerReport" (change)="updateProviderReport($event)">Detailed Provider Report</mat-checkbox>
        </div>
    </div>
  </div>
  <div class="actions">
    <button mat-raised-button color="warn" (click)="close()">
      <mat-icon>close</mat-icon>
      No, Cancel
    </button>
  
    <button mat-raised-button color="primary" (click)="exportReports()" [disabled]="!canExport()">
      <mat-icon>import_export</mat-icon>
      Export
    </button>
  </div>
</div>
