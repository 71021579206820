<!-- <div class="content-card course-calendar-scene"> -->

  <!-- CONTENT -->
  <div class="inner-content">
    <div class="header-wrap">
      <elb-page-header [pageTitle]="pageTitle" [pageSubTitle]="''" (returnToDashboardClicked)="returnToDashboardClicked.emit()">  
      </elb-page-header>
    </div>

    <div class="course-calendar" *ngIf="(academicYears$ | async) as academicYears">
     
      <div *ngIf="academicYears.length > 0 else noCourseCalendarBlock" class="inner-calendar-content">
        <!-- year -->
        <div class="academic-year-panel">
          <div class="academic-year-title"><h2 class="mat-title">Academic Year</h2></div>
          <div class="academic-year-info">
            <div class="academic-year-select-panel">
              <div class="title">Select Academic Year:</div>
              <div class="academic-year-select">
                <mat-form-field appearance="outline">
                  <mat-select (selectionChange)="onChangeAcademicYearsSelect($event)"
                              [value]="(currentAcademicYear$ | async).id">
                    <mat-option *ngFor="let academicYear of academicYears$ | async"
                                [value]="academicYear.id">
                      {{academicYear.name}} &#40;{{academicYear.startDate | date}} &#8211; {{academicYear.endDate | date}}&#41;
                      
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>

            <div class="academic-year-button-wrap" *ngIf="(currentAcademicYear$ | async) as currentAcademicYear">
              <div class="button">
                <button class="add-new-btn" mat-button (click)="addAcademicYear()">
                  <mat-icon>add</mat-icon>Add New Academic Year  
                </button>
              </div>
              <div class="button">
                <button mat-icon-button [matMenuTriggerFor]="selectMenu">
                  <mat-icon>more_horiz</mat-icon>
                </button>

                <mat-menu #selectMenu="matMenu">
                  <!-- <button mat-menu-item (click)="addAcademicYear()">Add New Academic Year</button> -->
                  <button mat-menu-item (click)="updateAcademicYear(currentAcademicYear)">Edit Academic Year</button>
                  <button mat-menu-item (click)="duplicateAcademicYear(currentAcademicYear)">Duplicate Academic Year</button>
                  <button mat-menu-item (click)="deleteAcademicYear(currentAcademicYear.id)" *ngIf="currentAcademicYear?.canDelete">Delete Academic Year</button>
                </mat-menu>
              </div>
            </div>

          </div>

        </div>
        <!-- semester -->
        <div *ngIf="(currentAcademicYear$ | async) as currentAcademicYear" class="semesters">
          <div class="semester_title">
            <h2 class="mat-title">Semesters</h2>
            <div class="button">
              <button class="add-new-btn" mat-button (click)="addSemester(currentAcademicYear)">
                <mat-icon>add</mat-icon>Add New Semester  
              </button>
            </div>
          </div>
          
          <div *ngIf="currentAcademicYear.semesters.length > 0 else noSemestersBlock" class="current-semesters">
            <div *ngFor="let semester of currentAcademicYear.semesters" class="semester">
              <div class="semester-panel">
                <div class="item"><span>{{semester.code}}</span></div>
                <div class="item">Start Date:<span> {{semester.startDate | date}}</span></div>
                <div class="item">End Date: <span>{{semester.endDate | date}}</span></div>
                <div class="item">
                  <div class="semester-options">
                    <button mat-icon-button [matMenuTriggerFor]="selectSemesterMenu">
                      <mat-icon>more_horiz</mat-icon>
                    </button>
  
                    <mat-menu #selectSemesterMenu="matMenu">
                      <!-- <button mat-menu-item
                              (click)="addSemester(currentAcademicYear.id)">Add New Semester</button> -->
                      <button mat-menu-item
                              (click)="updateSemester(currentAcademicYear, semester)">Edit Semester</button>
                      <button mat-menu-item
                              (click)="duplicateSemester(currentAcademicYear, semester)">Duplicate Semester</button>
                      <button mat-menu-item *ngIf="semester.canDelete"
                              (click)="deleteSemester(semester)">Delete Semester</button>
                    </mat-menu>
                  </div>
                  
                </div>
              </div>
              <div *ngIf="showSessions">
                <ng-container *ngIf="semester.sessions.length > 0 else noSessionBlock">
                  <div class="semester-accordion-panel" (click)="semester.accordionFlag = !semester.accordionFlag">
                    <div class="accordion-btn-wrap">
                      <div *ngIf="!semester.accordionFlag" class="semester-accordion-panel-title"><button class="outline-btn" mat-stroked-button>Show Sessions <mat-icon>keyboard_arrow_down</mat-icon></button></div>
                      <div *ngIf="semester.accordionFlag" class="semester-accordion-panel-title"><button class="outline-btn" mat-stroked-button>Hide Sessions <mat-icon>keyboard_arrow_up</mat-icon></button></div>
                      <div>
                        <button mat-button class="add-new-btn" 
                                  (click)="addSession(currentAcademicYear.id, semester)"><mat-icon>add</mat-icon>Add New Session</button>
                      </div>
                      <!-- <button mat-icon-button class="expand-button">
                        <mat-icon>keyboard_arrow_up</mat-icon>
                        <mat-icon >keyboard_arrow_down</mat-icon>
                      </button> -->
                    </div>
                  </div>

                  <div *ngIf="semester.accordionFlag">
                    <div *ngFor="let session of semester.sessions" class="session">
                      
                      <div class="session-menu">
                        <button mat-icon-button [matMenuTriggerFor]="selectSessionMenu">
                          <mat-icon>more_horiz</mat-icon>
                        </button>

                        <mat-menu #selectSessionMenu="matMenu">
                          
                          <button mat-menu-item
                                  (click)="updateSession(currentAcademicYear.id, semester, session)">Edit Session</button>
                          <button mat-menu-item
                                  (click)="duplicateSession(currentAcademicYear.id, semester, session)">Duplicate Session</button>
                          <button mat-menu-item *ngIf="session.canDelete"
                                  (click)="deleteSession(session)">Delete Session</button>
                        </mat-menu>
                      </div>

                      <div class="session-header">
                        <div class="item">Session:<span> {{ session.code }}</span></div>
                        <div class="item">Start Date:<span> {{ session.startDate | date}}</span></div>
                        <div class="item">End Date:<span> {{ session.endDate | date}}</span></div>
                      </div>

                      <div class="session-body">
                        <div class="session-body-panel" (click)="session.accordionFlag = !session.accordionFlag">
                          <div class="title">Additional Info:</div>
                          <div class="description">
                            <div class="item">Registration Start:<span> {{session.registrationStartDate | date}}</span></div> 
                            <div class="item">Registration Deadline:<span> {{session.registrationDeadlineDate | date}}</span></div>
                            <div class="item">Fee Earned:<span> {{session.feeEarnedDate | date}}</span></div>
                            <div class="item">Drop Deadline:<span> {{session.dropDeadlineDate | date}}</span></div>
                            <div class="item">Withdrawal Deadline:<span> {{session.withdrawalDeadlineDate | date}}</span></div>
                          </div> 
                        </div>
                      </div>
                    </div>
                  </div>
                </ng-container>

                <ng-template #noSessionBlock>
                  <div class="no-session">
                    <div><span >No Existing Sessions</span></div>
                    <div><button mat-button
                      (click)="addSession(currentAcademicYear.id, semester)"
                      class="add-new-btn">
                        <mat-icon>add</mat-icon>
                        Add New Session
                      </button></div>
                  </div>
                </ng-template>
              </div>
            </div>
          </div>

          <ng-template #noSemestersBlock>
            <button mat-raised-button
                    (click)="addSemester(currentAcademicYear)"
                    class="add-new-btn">
              <mat-icon>add</mat-icon>
              Add New Semester
            </button>
          </ng-template>
        </div>
      </div>

      <ng-template #noCourseCalendarBlock>
        <button (click)="addAcademicYear()"
                mat-raised-button
                class="add-new-btn">
          <mat-icon>add</mat-icon>
          Add New Academic Year
        </button>
      </ng-template>
    </div>
  </div>
  <!-- / CONTENT -->


