import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, tap, catchError } from 'rxjs/operators';

import { UserSummary } from '../models/userSummary.model';
import { ViewContext } from '../models/viewContext.enum';
import { AccessRole } from '../models/accessRole';
import { Router } from '@angular/router';
import { BaseResponse } from 'app/core/models';
import { IAppState } from 'app/store/state/app.state';
import { Store } from '@ngrx/store';
import { AuthActions } from '../store/action-types';

import * as fromAuth from '@auth/store';
import * as fromCore from '@core/store';
import { ConfigService } from '@core/services/config.service';
import { RealTimeService } from '@core/services/realTime.service';

@Injectable({providedIn: 'root'})
export class AuthenticationService {
    private authUrl: string = '';
    private baseUrl: string = '';
    public isAuthenticated$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    constructor(private store: Store<IAppState>,private http: HttpClient,private router: Router, private config:ConfigService,
        private realTimeService: RealTimeService) {
        this.config.apiUrl$.subscribe(result=>{
            this.authUrl = `${result}/auth`;
            this.baseUrl = result;
        });
    }

    login(emailAddress: string, password: string, returnUrl: string) {
        return this.http.post<UserSummary>(`${this.authUrl}/login`, { emailAddress, password })
            .pipe(map(user => {
                this.store.dispatch(AuthActions.login({user, returnUrl}));
    
                if(returnUrl && returnUrl != ""){
                    this.router.navigateByUrl(returnUrl);
                }else{
                    var accessRole;
                    if(!user.lastAccessRoleId || user.lastAccessRoleId ===''){
                        let accessRoleId = this.getHighestAccessRoleId(user.accessRoles);
                        accessRole = user.accessRoles.find(x=>x.id===accessRoleId);
                    }else{
                        if(user.accessRoles.findIndex(x=>x.id===user.lastAccessRoleId) !==-1){
                            accessRole = user.accessRoles.find(x=>x.id===user.lastAccessRoleId);
                        }else{
                            let accessRoleId = this.getHighestAccessRoleId(user.accessRoles);
                            accessRole = user.accessRoles.find(x=>x.id===accessRoleId);
                        }
                    }
                    if(accessRole == null){
                        this.store.dispatch(fromCore.Actions.ShowMessage({message: "User doesn't have any active Access Roles"}));
                    }else{
                        const accessRoleId = accessRole.id;
                        this.store.dispatch(AuthActions.selectAccessRole({accessRoleId}));
                        var courseExchangeId =''
                        if(accessRole.courseExchangeId != ''){
                            courseExchangeId = accessRole.courseExchangeId;
                            this.store.dispatch(AuthActions.selectCourseExchangeId({courseExchangeId}));
                        }
                        
                        this.store.dispatch(AuthActions.navigateToDashboard({accessRoleId}));
                    }

                }
                return user;
            }));
    }
    navigateToDashboard(accessRole: AccessRole): void{
        if(!accessRole) return;
        
        switch(accessRole.viewContext){
            case "SuperAdmin":
                this.router.navigate(['admin','dashboard']);
                break;
            case "CourseExchangeAdmin":
                this.router.navigate(['course-exchange',
                                    accessRole.courseExchangeId,
                                    accessRole.institutionId,
                                    'dashboard']);
                break;
            case "Institution":
                this.router.navigate(['institution',accessRole.institutionId,'dashboard']);
                break;
            case "Provider":
                this.router.navigate(['provider', accessRole.entityId,accessRole.institutionId,'dashboard']);
                break;
            case "Member":
                this.router.navigate(['member', accessRole.entityId,accessRole.institutionId,'dashboard']);
                break;
            case "Student":
                this.router.navigate(['student',accessRole.entityId,'dashboard']);
                break;
        }
    }

    resetPassword(emailAddress: string, code: string, password: string): Observable<BaseResponse>{
        const data ={
            emailAddress,
            code,
            password
        };
        return this.http.post<BaseResponse>(`${this.authUrl}/resetPassword`,data);

    }

    selectAccessRole(userId: string, accessRoleId: string): Observable<BaseResponse>{
        const data ={
            userId,
            accessRoleId
        };
        return this.http.post<BaseResponse>(`${this.authUrl}/selectAccessRole`,data);

    }

    requestResetPassword(emailAddress: string): Observable<void>{
        return this.http.post<void>(`${this.authUrl}/requestResetPassword`, {emailAddress})
            .pipe(
                tap(() => {
                    this.store.dispatch(fromCore.Actions.ShowMessage({message: 'An email was sent with a Reset link'}));
                    this.store.dispatch(fromAuth.Actions.navigateToLogin({}));
                }),
                catchError(() =>
                    of(this.store.dispatch(fromCore.Actions.ShowMessage({message:"Email Address not recognized. Please try again or contact your Exchange Administrator for assistance."})))
                )
            );
    }

    logout() {
        // remove user from local storage and set current user to null
        this.store.dispatch(AuthActions.logout());
        this.realTimeService.stopHub();
    }

    getToken(){
        let currentUser = this.currentUser();
        return !!currentUser ? currentUser.token : '';
    }

    currentUser(): UserSummary{
        let currentUser = JSON.parse(localStorage.getItem('user'));
        return currentUser;
    }

    public getHighestAccessRoleId(accessRoles:Array<AccessRole>):string{
        var superAdmin = accessRoles.find(x=>x.viewContext==ViewContext.SuperAdmin);
        if(superAdmin != null) return superAdmin.id;
        var courseExchangeAdmin = accessRoles.find(x=>x.viewContext==ViewContext.CourseExchangeAdmin);
        if(courseExchangeAdmin != null) return courseExchangeAdmin.id;
        var provider = accessRoles.find(x=>x.viewContext==ViewContext.Provider);
        if(provider != null) return provider.id;
        var member = accessRoles.find(x=>x.viewContext==ViewContext.Member);
        if(member != null) return member.id;
      }
}
