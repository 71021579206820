import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { tap, switchMap, map, flatMap, concatMap, withLatestFrom } from 'rxjs/operators';
import * as fromCourseExchangeInstitution from '@courseExchange/institution';
import * as fromCourseExchange from '@courseExchange/store';
import { Router } from '@angular/router';
import { of } from 'rxjs';
import { IAppState } from 'app/store/state/app.state';
import { Store, select } from '@ngrx/store';
@Injectable()
export class CourseExchangeInstitutionsNavigateEffects {
        constructor(private actions$: Actions, private router: Router, private store: Store<IAppState>) {
        }

        goToCourseExchangeInstitutionsList$ = createEffect(() => {
                return this.actions$.pipe(
                        ofType(fromCourseExchangeInstitution.Actions.InstitutionList),
                        concatMap(action => of(action).pipe(
                                withLatestFrom(this.store.pipe(select(fromCourseExchange.Selectors.CourseExchange)))
                        )),
                        tap(([action, courseExchange]) =>
                        {
                                const courseExchangeId = !!action.courseExchangeId ? action.courseExchangeId : courseExchange.id;
                                const managingInstitutionId = !!action.managingInstitutionId ? action.managingInstitutionId : courseExchange.managingInstitutionId;
                                this.router.navigate(['course-exchange',courseExchangeId,managingInstitutionId,'institutions','list']);
                        }))
        },{dispatch: false});

        goToReadOnlyMemberWizard$ = createEffect(() => {
                return this.actions$.pipe(
                        ofType(fromCourseExchangeInstitution.Actions.MemberReadOnlyWizard),
                        tap((action) =>{
                             const {courseExchangeId, institutionId, memberId, managingInstitutionId} = action.institution;
                            this.router.navigate(['course-exchange',
                            courseExchangeId, managingInstitutionId,
                            'institutions','memberWizard',
                            memberId, institutionId])}
                        )
                )
        },{dispatch: false});

        goToReadOnlyProviderWizard$ = createEffect(() => {
                return this.actions$.pipe(
                        ofType(fromCourseExchangeInstitution.Actions.ProviderReadOnlyWizard),
                            tap((action) => {
                             const {courseExchangeId, institutionId, providerId, managingInstitutionId} = action.institution;
                            this.router.navigate(['course-exchange', courseExchangeId,
                                        managingInstitutionId,
                                        'institutions','providerWizard',
                                        providerId, institutionId])
                                })
                )
        },{dispatch: false});

        goToInstitutionsDetail$ = createEffect(() => {
                return this.actions$.pipe(
                        ofType(fromCourseExchangeInstitution.Actions.InstitutionDetail),
                        tap((action) =>{
                            const {managingInstitutionId, courseExchangeId, institutionId} = action.institution;
                            return this.router.navigate(['course-exchange',
                                                        courseExchangeId,
                                                        managingInstitutionId,
                                                        'institutions',
                                                        'detail',
                                                        institutionId]);
                        } 
                ))
        },{dispatch: false});

}