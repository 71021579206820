<div class="wizard-step wizard-home-step">
  <div class="title">
    Welcome {{userName}} to {{institutionName}}!
  </div>

    <wizard-home-step-check-list
      [checklist]="checklist"
      (navigate)="navigate.emit($event)">
    </wizard-home-step-check-list>
  
</div>
