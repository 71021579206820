import { createEffect, Actions, ofType } from "@ngrx/effects";
import { Injectable } from "@angular/core";
import { AuthActions } from "./action-types";
import { tap, withLatestFrom, concatMap } from "rxjs/operators";
import { Router } from "@angular/router";
import { IAppState } from 'app/store/state/app.state';
import { Store, select } from '@ngrx/store';
import { of } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import * as fromAuth from '@auth/store';
import { RealTimeService } from "@core/services/realTime.service";
import { UserSummary } from "@auth/models/userSummary.model";
import { AccessRole } from "@auth/models/accessRole";
@Injectable()
export class AuthEffects {
    constructor(private authService: AuthenticationService, private realTimeService: RealTimeService, private actions$: Actions, private router: Router, private store: Store<IAppState>) {
        this.initSignalRMessages();
    }
    private initSignalRMessages() {
        this.realTimeService.hubConnection$.subscribe(hubConnection => {
            if (!hubConnection) return;
            hubConnection.on('UserSummaryUpdated', (userSummary: UserSummary) => {
                this.store.dispatch(fromAuth.Actions.UserSummaryUpdated({ userSummary }));
            });
            hubConnection.on('AccessRoleAdded', (accessRole: AccessRole) => {
                this.store.dispatch(fromAuth.Actions.AccessRoleAdded({ accessRole }));
            });
            hubConnection.on('AccessRoleUpdated', (accessRole: AccessRole) => {
                this.store.dispatch(fromAuth.Actions.AccessRoleUpdated({ accessRole }));
            });
            hubConnection.on('AccessRoleDeleted', (accessRole: AccessRole) => {
                this.store.dispatch(fromAuth.Actions.AccessRoleDeleted({ accessRole }));
            });
        })
    }

    login$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(AuthActions.login),
                tap((action) => localStorage.setItem('user', JSON.stringify(action.user))),
            ), { dispatch: false });

    userSummaryUpdated$ = createEffect(() =>
        this.actions$
            .pipe(
                ofType(AuthActions.UserSummaryUpdated),
                tap((action) => {
                    let user:UserSummary = JSON.parse(localStorage.getItem('user'));
                    var token = !!user ? user.token : "";
                    user = {...action.userSummary, token};
                    localStorage.setItem('user', JSON.stringify(user));
                }),
            ), { dispatch: false });

    logout$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.logout),
            tap(() => {
                localStorage.removeItem('user');
                this.realTimeService.stopHub();
                this.router.navigateByUrl('/auth/login');
            }))
        , { dispatch: false });

    navigateToLogin$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.navigateToLogin),
            tap((action) => {
                if ((action.returnUrl==null || action.returnUrl == undefined)) {
                    this.router.navigate(['auth', 'login']);
                } else {
                    this.router.navigate(['auth', 'login'], {
                        queryParams: {
                            ['returnUrl']: action.returnUrl
                        }
                    });
                }
            }))
        , { dispatch: false });

    navigateToDashboard$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.navigateToDashboard),
            concatMap((action) => of(action).pipe(
                withLatestFrom(this.store.pipe(select(fromAuth.Selectors.CurrentUser)))
            )),
            tap(([{ accessRoleId }, user]) => {
                var accessRole = !!user ? user.accessRoles.find(x => x.id === accessRoleId) : null;
                if(accessRole!=null){
                    this.authService.navigateToDashboard(accessRole);
                }
            })
        ),
        { dispatch: false });

    // selectAccessRole$ = createEffect(() =>
    //     this.actions$.pipe(
    //         ofType(AuthActions.selectAccessRole),
    //         concatMap((action) => of(action).pipe(
    //             withLatestFrom(this.store.pipe(select(fromAuth.Selectors.CurrentUser)))
    //         )),
    //         tap(([{ accessRoleId }, currentUser]) => {
    //             var user = { ...currentUser, lastAccessRoleId: accessRoleId };
    //             localStorage.setItem('user', JSON.stringify(user));
    //             this.authService.selectAccessRole(user.userId, accessRoleId).subscribe();
    //         })
    //     ),
    //     { dispatch: false });

    selectCourseExchangeId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AuthActions.selectCourseExchangeId),
            concatMap((action) => of(action).pipe(
                withLatestFrom(this.store.pipe(select(fromAuth.Selectors.AccessRolesForEffects)))
            )),
            tap(([action, { currentAccessRole, courseExchangeAccessRoles }]) => {
                if (action.courseExchangeId == currentAccessRole.courseExchangeId) return;
                var newAccessRole = !!currentAccessRole ? courseExchangeAccessRoles.find(x => x.viewContext === currentAccessRole.viewContext) : null;
                var accessRoleId = !!newAccessRole ? newAccessRole.id : this.authService.getHighestAccessRoleId(courseExchangeAccessRoles);
                if (accessRoleId != currentAccessRole.id) {
                    this.store.dispatch(fromAuth.Actions.navigateToDashboard({ accessRoleId }));
                    this.store.dispatch(fromAuth.Actions.selectAccessRole({ accessRoleId }));
                }
            })
        ), { dispatch: false });


}