import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { Store, select } from "@ngrx/store";
import { first, finalize, filter, map, tap, concatMap, withLatestFrom } from "rxjs/operators";
import { IAppState } from 'app/store/state/app.state';
import * as fromMember from '@member/store';
import { CourseExchangeSummary } from '@shared/courseExchange/models';
import { MemberService } from '@shared/member/services';
import * as fromCourseExchange from '@courseExchange/store';
@Injectable()
export class CourseExchangeSummaryResolver implements Resolve<CourseExchangeSummary>{
    isLoading = false;
    constructor(private store: Store<IAppState>, private memberService: MemberService){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
        const memberId = route.params["memberId"];
        let courseExchangeId = '';
        return this.store 
            .pipe(
                select(fromCourseExchange.Selectors.CourseExchangeSummary),
                concatMap(action => of(action).pipe(
                    withLatestFrom(this.store.pipe(select(fromMember.Selectors.Member)))
                )),
                map(([courseExchangeSummary, member]) => {
                    courseExchangeId = !!member ? member.courseExchangeId : '';
                    var result = !!courseExchangeSummary && !!member && courseExchangeSummary.id == member.courseExchangeId;
                    if(result){ 
                        this.store.dispatch(fromCourseExchange.Actions.LoadCourseExchangeSummarySuccess({courseExchangeSummary}));
                    }
                    return result;
                }),
                map((courseExchangeSummaryLoaded)=>{
                    if(!this.isLoading && !courseExchangeSummaryLoaded){
                        this.store.dispatch(fromMember.Actions.LoadCourseExchangeSummary({memberId}));
                    }
                    return true;
                }),
                filter(courseExchangeSummary => !!courseExchangeSummary),
                first(),
                finalize(()=>this.isLoading=false)
            )
    }
}