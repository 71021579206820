import { NgModule } from '@angular/core';
import * as fromUsers from '@shared/user/store';

import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { InstitutionSharedModule } from '@shared/institution/institution-shared.module';
import { UserSharedModule } from '@shared/user/user-shared.module';
import { CalendarSharedModule } from '@shared/calendar/calendar.module';

@NgModule({
  declarations: [],
  imports: [
    InstitutionSharedModule,
    UserSharedModule,
    CalendarSharedModule,
    StoreModule.forFeature('institution/users', fromUsers.usersReducer),
    EffectsModule.forFeature([...fromUsers.EFFECTS])
  ],
  providers: [...fromUsers.RESOLVERS]
})
export class UsersStoreModule { }
