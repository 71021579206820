<div id="select-institution-dialog" style="width: 300px; height:120px">
    <div>
        <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
            <mat-label>Select Institution</mat-label>
            <mat-select [(value)]="selectedInstitution">
                <mat-option *ngFor="let institution of data.institutions" [value]="institution.id">
                    {{institution.institutionName}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div>
        <button color="warn" (click)="cancel()" mat-button>Cancel</button>
        <button sytle="margin-left:10px;" color="primary" [disabled]="!selectedInstitution" (click)="ok()"
            mat-button>Select</button>
    </div>
</div>