import { Region, Country} from '../referenceData';

export class Address{
    addressLine1: string;
    addressLine2: string;
    city: string;
    region: Region;
    country: Country;
    postalCode: string;

    public toString():string {
        return this.addressLine1;
    }
}