

import {createFeatureSelector, createSelector} from '@ngrx/store';
import { UserListState } from '..';
import { sortBy } from 'sort-by-typescript';
import { Status } from '@shared/common/models';
import { ViewContext } from '@auth/models/viewContext.enum';
import { UserListItemForView } from '@shared/user/models';
import { searchInText } from 'app/helpers/search-in-text';
import * as fromRefData from '@refData';
import { permissionDisplays } from '@refData';
export const selectUserListState = createFeatureSelector<UserListState>('shared/users');

export const UsersForView = createSelector(
    selectUserListState,
    (state) => !!state ? state.users : []
)

export const Sorts = createSelector(
    selectUserListState,
    (state) => !!state ? state.sorts : []
)

export const CurrentOrder = createSelector(
    selectUserListState,
    (state) => !!state ? state.currentOrder : undefined
)

export const CurrentSort = createSelector(
    selectUserListState,
    (state) => !!state ? state.currentSort : undefined
)

export const SearchText = createSelector(
    selectUserListState,
    (state) => !!state ? state.searchText : undefined
)

export const CourseExchangeFilters = createSelector(
    selectUserListState,
    ({users, courseExchangeFilters}) =>{
      if (!users || !courseExchangeFilters) return [];
      
      return courseExchangeFilters.filter(x=>users.find(u=>u.accessRoles.find(a=>a.courseExchangeId==x.id))).sort(sortBy('description')).map(filter => {
        const count = users.filter(x=>x.accessRoles.find(y=>y.courseExchangeId===filter.id 
                                                && (y.viewContext===ViewContext.Member 
                                                    || y.viewContext===ViewContext.Provider))).length;
        return {
          ...filter,
          descriptionForView: `${filter.description} (${count})`
        }
      })
    }
)

export const SelectedCourseExchangeFilters = createSelector(
    CourseExchangeFilters,
    (filters) => !!filters ? filters.filter(x=>x.selected): []
)

export const CourseExchangeFilterCount = createSelector(
    SelectedCourseExchangeFilters,
    (filters) => filters.length > 0 ? filters.length : null
)

export const InstitutionFilters = createSelector(
    selectUserListState,
    ({users, institutionFilters}) =>{
      if (!users || !institutionFilters) return [];
      
      return institutionFilters.filter(x=>users.find(u=>u.institutionId==x.id)).sort(sortBy('description')).map(filter => {
        const count = users.filter(x=>x.institutionId===filter.id).length;
        return {
          ...filter,
          descriptionForView: `${filter.description} (${count})`
        }
      })
    }
)

export const SelectedInstitutionFilters = createSelector(
    InstitutionFilters,
    (filters) => !!filters ? filters.filter(x=>x.selected) : []
)
export const InstitutionFilterCount = createSelector(
    SelectedInstitutionFilters,
    (filters) => filters.length>0 ? filters.length : null
)

export const InstitutionRoleFilters = createSelector(
    selectUserListState,
    ({users, institutionRoleFilters}) =>{
      if (!users || !institutionRoleFilters) return [];
      
      return institutionRoleFilters.filter(x=>users.find(u=>u.institutionRoleId==x.id)).sort(sortBy('description')).map(filter => {
        const count = users.filter(x=>x.institutionRoleId===filter.id).length;
        return {
          ...filter,
          descriptionForView: `${filter.description} (${count})`
        }
      })
    }
)

export const SelectedInstitutionRoleFilters = createSelector(
    InstitutionRoleFilters,
    (filters) => 
        !!filters ? filters.filter(x=>x.selected) : []
)
export const InstitutionRoleFilterCount = createSelector(
    SelectedInstitutionRoleFilters,
    (filters) => 
        filters.length> 0? filters.length : null
)
export const StatusFilters = createSelector(
    selectUserListState,
    ({users, statusFilters}) =>{
      if (!users || !statusFilters) return [];
      
      return statusFilters.map(filter => {
        let count = 0;
        if(filter.id === Status.Active)
            count = users.filter(x=>x.isActive).length;
        else if(filter.id === Status.Inactive){
            count = users.filter(x=>!x.isActive).length;
        }

        return {
          ...filter,
          descriptionForView: `${filter.description} (${count})`
        }
      })
    }
)

export const SelectedStatusFilters = createSelector(
    StatusFilters,
    (filters) => !!filters ? filters.filter(x=>x.selected) : []
)
export const StatusFilterCount = createSelector(
    SelectedStatusFilters,
    (filters) => filters.length > 0 ? filters.length : null
)
export const SelectedFilters = createSelector(
    SelectedCourseExchangeFilters,
    SelectedInstitutionFilters,
    SelectedInstitutionRoleFilters,
    SelectedStatusFilters,
    (courseExchangeFilters, institutionFilters, institutionRoleFilters, statusFilters) =>
       [...courseExchangeFilters, ...institutionFilters, ...institutionRoleFilters, ...statusFilters]
)

export const FilteredUsers = createSelector(
    UsersForView,
    SelectedCourseExchangeFilters,
    SelectedInstitutionFilters,
    SelectedInstitutionRoleFilters,
    SelectedStatusFilters,
    SearchText,
    CurrentSort,
    CurrentOrder,
    (
      users,
      courseExchangeFilters,
      institutionFilters,
      institutionRoleFilters,
      statusFilters,
      searchText,
      currentSort,
      currentOrder
    ) => {
      let filteredUsers: UserListItemForView[] = [...users];
  
      if (courseExchangeFilters.length) {
        filteredUsers = filteredUsers.filter(user => {
          const index = courseExchangeFilters.findIndex(item => user.accessRoles.find(x=>x.courseExchangeId===item.id && (x.viewContext===ViewContext.Member || x.viewContext===ViewContext.Provider)));
          return (index !== -1);
        })
      }
  
      if (institutionFilters.length) {
        filteredUsers = filteredUsers.filter(user => {
          const index = institutionFilters.findIndex(item => user.institutionId===item.id);
          return (index !== -1);
        })
      }
  
      if (institutionRoleFilters.length) {
        filteredUsers = filteredUsers.filter(user => {
          const index = institutionRoleFilters.findIndex(item => user.institutionRoleId===item.id);
          return (index !== -1);
        })
      }

      if (statusFilters.length) {
        filteredUsers = filteredUsers.filter(user =>{
            const index = statusFilters.findIndex(item => (item.id===Status.Active && user.isActive) 
                                                        || (item.id===Status.Inactive && !user.isActive));
            return (index !== -1);
        })
      }
  
      if (searchText) {
        filteredUsers = searchInText(searchText, filteredUsers, [
          'firstName',
          'lastName',
          'department',
          'title',
          'emailAddress',
          'phoneNumber',
          'institutionRole',
          'institutionName'
        ]);
      }
  
      if(currentSort && currentOrder){
        const sort = currentSort.value;
        const order = currentOrder.value;
        filteredUsers = filteredUsers.sort(sortBy(`${order}${sort}`));      
      }
  
      return filteredUsers;
    }
  )

export const SelectedUsers = createSelector(
    FilteredUsers,
    (users) => !!users ? users.filter(x=>x.selected) : []
)

export const CanExportToExcel = createSelector(
    SelectedUsers,
    (selected) => !!selected  ? selected.length > 0 : false
)

export const SelectedStatus = createSelector(
    SelectedUsers,
    FilteredUsers,
    (selected, filtered) => `${selected.length} of ${filtered.length}`

)

export const IsAllSelected = createSelector(
    SelectedUsers,
    FilteredUsers,
    (selected, filtered) => !!filtered ? filtered.length>0 && filtered.length===selected.length : false    
)

export const IndeterminateSelected = createSelector(
    IsAllSelected,
    FilteredUsers,
    (isAllSelected, users) => isAllSelected ? false : users.some(x=>x.selected)
)

export const RefDataForManagePermissions = createSelector(
  fromRefData.permissionDisplays,
  fromRefData.courseExchangeRoles,
  fromRefData.institutionRoles,
  (permissionDisplays, courseExchangeRoles, institutionRoles)=>{
    return{
      permissionDisplays,
      courseExchangeRoles,
      institutionRoles
    }
  }
)