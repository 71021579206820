import { createEffect, Actions, ofType } from "@ngrx/effects";
import { Injectable } from "@angular/core";
import { map, switchMap, withLatestFrom, concatMap, tap } from "rxjs/operators";
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { IAppState } from 'app/store/state/app.state';
import { Router } from '@angular/router';
import * as fromCourseExchange from '@courseExchange/store';
import * as fromCourseExchangeBilling from '@courseExchange/billing/store';

@Injectable()
export class CourseExchangeBillingNavigateEffects{
    constructor(private actions$: Actions, private store: Store<IAppState>,
        private router: Router){
    }

    CourseExchangeBillingSummary$ = createEffect(() => {
        return this.actions$.pipe(
          ofType(fromCourseExchangeBilling.Navigate.CourseExchangeBillingSummary),
          concatMap(action => of(action).pipe(
            withLatestFrom(this.store.pipe(select(fromCourseExchange.Selectors.CourseExchange)))
          )),
          map(([action, courseExchange]) => {
            const courseExchangeId = !!action.courseExchangeId ? action.courseExchangeId : courseExchange.id;
            const institutionId = !!action.institutionId ? action.institutionId : courseExchange.managingInstitutionId;
    
            return this.router.navigate(['course-exchange',
              courseExchangeId,
              institutionId,
              'billing',
              'summary']);
          }))
      }, { dispatch: false });
}