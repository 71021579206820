import {Component, OnDestroy, OnInit, ViewEncapsulation, ViewChild, ChangeDetectionStrategy} from '@angular/core';
import {Subject, Observable} from 'rxjs';
import {takeUntil, map} from 'rxjs/operators';
import {TranslateService} from '@ngx-translate/core';
import * as _ from 'lodash';

import {FuseConfigService} from '@fuse/services/config.service';
import {FuseSidebarService} from '@fuse/components/sidebar/sidebar.service';

import {navigation} from 'app/navigation/navigation';
import {UserSummary} from 'app/authentication/models/userSummary.model';
import {AuthenticationService} from 'app/authentication/services/authentication.service';
import {AccessRole, AccessRoleForView} from 'app/authentication/models/accessRole';
import {IAppState} from 'app/store/state/app.state';
import {Store, select} from '@ngrx/store';
import {
  isLoggedIn,
  CurrentUser,
  currentUserName,
  CurrentAccessRole} from 'app/authentication/store/auth.selectors';
import {MatSelect} from '@angular/material/select';
import * as fromAuth from '@auth/store';
import * as fromCourseExchange from '@courseExchange/store';
import {ViewContext} from '@auth/models/viewContext.enum';

@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class ToolbarComponent implements OnInit, OnDestroy {
  ViewContext = ViewContext;

  @ViewChild(MatSelect) accessRoleCombo: MatSelect;
  horizontalNavbar: boolean;
  rightNavbar: boolean;
  hiddenNavbar: boolean;
  languages: any;
  navigation: any;
  selectedLanguage: any;
  userStatusOptions: any[];
  public isAuthenticated$: Observable<boolean>;
  public userName$: Observable<string>;
  public userSummary$: Observable<UserSummary>;
  currentAccessRole$: Observable<AccessRole>;
  public viewContextColor$: Observable<string>;
  public viewContextDescription$: Observable<string>;
  public primaryLogo$: Observable<string>;
  // Private
  private _unsubscribeAll: Subject<any>;
  currentUser$: Observable<UserSummary>;
  courseExchangeId$: Observable<string>;
  currentAccessRoleId$: Observable<string>;
  accessRoles$: Observable<AccessRoleForView[]>;

  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {TranslateService} _translateService
   */
  constructor(
    private authorizeService: AuthenticationService,
    private _fuseConfigService: FuseConfigService,
    private _fuseSidebarService: FuseSidebarService,
    private _translateService: TranslateService,
    private store: Store<IAppState>
  ) {
    this.primaryLogo$ = this.store.pipe(select(fromAuth.Selectors.CourseExchangePrimaryLogo));
    // Set the defaults
    this.userStatusOptions = [
      {
        title: 'Online',
        icon: 'icon-checkbox-marked-circle',
        color: '#4CAF50'
      },
      {
        title: 'Away',
        icon: 'icon-clock',
        color: '#FFC107'
      },
      {
        title: 'Do not Disturb',
        icon: 'icon-minus-circle',
        color: '#F44336'
      },
      {
        title: 'Invisible',
        icon: 'icon-checkbox-blank-circle-outline',
        color: '#BDBDBD'
      },
      {
        title: 'Offline',
        icon: 'icon-checkbox-blank-circle-outline',
        color: '#616161'
      }
    ];

    this.languages = [
      {
        id: 'en',
        title: 'English',
        flag: 'us'
      },
      {
        id: 'tr',
        title: 'Turkish',
        flag: 'tr'
      }
    ];

    this.navigation = navigation;

    // Set the private defaults
    this._unsubscribeAll = new Subject();

    this.isAuthenticated$ = this.store.pipe(select(isLoggedIn));
    this.userSummary$ = this.store.pipe(select(CurrentUser));
    this.userName$ = this.store.pipe(select(currentUserName));

    this.currentAccessRole$ = this.store.pipe(select(CurrentAccessRole));

    this.viewContextColor$ = this.store.pipe(
      select(CurrentAccessRole),
      map((accessRole) => {
        if (accessRole) {
          return accessRole.viewContext + '_color';
        }
      })
    );

    this.viewContextDescription$ = this.store.pipe(
      select(CurrentAccessRole),
      map((accessRole) => {
        if (accessRole) {
          return accessRole.viewContext.match(/[A-Z][a-z]+|[0-9]+/g).join(" ") + " Dashboard";
        }
      })
    );

  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.currentUser$ = this.store.pipe(select(fromAuth.Selectors.CurrentUser));
    this.accessRoles$ = this.store.pipe(select(fromAuth.Selectors.CurrentAccessRoles));
    this.currentAccessRoleId$ = this.store.pipe(select(fromAuth.Selectors.currentAccessRoleId));
    this.courseExchangeId$ = this.store.pipe(select(fromCourseExchange.Selectors.CourseExchangeId));
    // Subscribe to the config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((settings) => {
        this.horizontalNavbar = settings.layout.navbar.position === 'top';
        this.rightNavbar = settings.layout.navbar.position === 'right';
        this.hiddenNavbar = settings.layout.navbar.hidden === true;
      });

    // Set the selected language from default languages
    this.selectedLanguage = _.find(this.languages, {id: this._translateService.currentLang});


  }

  changeAccessRole(accessRoleId: string): void{
    this.store.dispatch(fromAuth.Actions.selectAccessRole({accessRoleId}));
    this.store.dispatch(fromAuth.Actions.navigateToDashboard({accessRoleId}));
  }

  ngAfterViewInit(): void {
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar open
   *
   * @param key
   */
  toggleSidebarOpen(key): void {
    this._fuseSidebarService.getSidebar(key).toggleOpen();
  }

  toggleNotifications(): void {

  }

  /**
   * Search
   *
   * @param value
   */
  search(value): void {
    // Do your search here...
    console.log(value);
  }

  /**
   * Set the language
   *
   * @param lang
   */
  setLanguage(lang): void {
    // Set the selected language for the toolbar
    this.selectedLanguage = lang;

    // Use the selected language for translations
    this._translateService.use(lang.id);
  }

  logout(): void {
    this.authorizeService.logout();
  }
}
