import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { first, finalize, filter, map } from "rxjs/operators";
import { IAppState } from 'app/store/state/app.state';
import * as fromCourseExchangeBilling from '@courseExchange/billing/store';
import { MonthlyEarningsStatement } from '@courseExchange/billing/models';

@Injectable()
export class CourseExchangeMonthlyStatementsResolver implements Resolve<MonthlyEarningsStatement[]>{
    isLoading = false;
    constructor(private store: Store<IAppState>){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
        const courseExchangeId = route.params['courseExchangeId'];
        return this.store 
            .pipe(
                map(() => {
                    if(!this.isLoading){
                        this.isLoading = true;
                        this.store.dispatch(fromCourseExchangeBilling.Actions.LoadMonthlyStatements({courseExchangeId}));
                    }
                    return true;
                }),
                filter(statementLoaded => statementLoaded),
                first(),
                finalize(()=>this.isLoading=false)
            )
    }
}