<mat-toolbar>
  <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <span class="title dialog-title">Course Description</span>
    <button mat-icon-button (click)="close()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div class="content">
  <div class="sub-title">
    {{matDialogData.title}} Description:
  </div>

  <div class="description">{{matDialogData.description}}</div>

  <div class="action">
    <button mat-raised-button
            (click)="close()">
      <mat-icon>close</mat-icon>
      Exit
    </button>
  </div>
</div>
