import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import { UserListItem } from '@shared/user/models';
import { InstitutionRole } from '@core/referenceData';
import { AddUserCommand,UpdateUserCommand } from '@shared/user/commands';
import { ChecklistItemType } from '@core/models/checklistItemType.enum';

@Component({
  selector: 'wizard-contacts-step',
  templateUrl: './wizard-contacts-step.component.html',
  styleUrls: ['../../styles/common-wizard-step.scss', './wizard-contacts-step.component.scss']
})
export class WizardContactsStepComponent implements OnInit, OnChanges {
  @Input() currentStepId: string;
  @Input() accountingUser: UserListItem;
  @Input() presidentUser: UserListItem;
  @Input() vicePresidentOrProvostUser: UserListItem;
  @Input() institutionId: string;
  @Input() institutionRoles: InstitutionRole[];
  @Output() addUser: EventEmitter<AddUserCommand> = new EventEmitter<AddUserCommand>();
  @Output() editUser: EventEmitter<UpdateUserCommand> = new EventEmitter<UpdateUserCommand>();
  @Output() navigate: EventEmitter<string> = new EventEmitter<string>();
  TwoRoles: InstitutionRole[];
  accountingStep: ChecklistItemType.ContactAndUserSetup_Accounting;
  presidentStep: ChecklistItemType.ContactAndUserSetup_President;
  provostStep: ChecklistItemType.ContactAndUserSetup_ProvostOrVicePresident;
  additionalUserStep: ChecklistItemType.ContactAndUserSetup_AdditionalUsers;
  constructor() {
  }

  ngOnInit(): void {
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.institutionRoles && changes.institutionRoles.currentValue) {
      this.institutionRoles = changes.institutionRoles.currentValue;
      this.TwoRoles = this.institutionRoles.filter(x=>x.description==='Provost'|| x.description==='Vice President');
    }
  }
}
