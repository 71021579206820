import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation, Input} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {delay, filter, take, takeUntil} from 'rxjs/operators';

import {FuseConfigService} from '@fuse/services/config.service';
import {FuseNavigationService} from '@fuse/components/navigation/navigation.service';
import {FusePerfectScrollbarDirective} from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import {FuseSidebarService} from '@fuse/components/sidebar/sidebar.service';

import {faCog, faQuestionCircle} from '@fortawesome/free-solid-svg-icons';

import {UserSummary} from 'app/authentication/models/userSummary.model';
import {AuthenticationService} from 'app/authentication/services/authentication.service';
import {IAppState} from '../../../../../store/state/app.state';
import {select, Store} from '@ngrx/store';
import * as fromAuth from '@auth/store';
import { CalendarService } from '@shared/calendar/services';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';
import * as fromAdminUsers from '@admin/users/store';
import {AccessRoleForView} from '@auth/models/accessRole';
import * as fromNotification from '@core/notifications/store';
export interface Role {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-super-admin-dashboard-nav',
  templateUrl: './super-admin-dashboard-nav.component.html',
  styleUrls: ['./super-admin-dashboard-nav.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SuperAdminDashboardNavComponent implements OnInit, OnDestroy {
  fuseConfig: any;
  navigation: any;

  faCog = faCog;
  faQuestionCircle = faQuestionCircle;

  userRoles: Role[] = [
    {value: 'admin', viewValue: 'Admin'},
    {value: 'provider', viewValue: 'Provider'},
  ];

  notificationsCount$: Observable<number>;

  @Input() user: UserSummary;
// Private
  private _fusePerfectScrollbar: FusePerfectScrollbarDirective;
  private _unsubscribeAll: Subject<any>;

  currentUser$: Observable<UserSummary>;
  currentSystemDate$: Observable<Date>;
  accessRoles$: Observable<AccessRoleForView[]>;
  currentAccessRoleId$: Observable<string>;
  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseNavigationService} _fuseNavigationService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {Router} _router
   */
  constructor(
    private authService: AuthenticationService,
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    private _router: Router,
    private store: Store<IAppState>,
    private calendarService: CalendarService
  ) {

    // Set the private defaults
    this._unsubscribeAll = new Subject();

    this.notificationsCount$ = this.store.pipe(select(fromNotification.Selectors.NotificationsCount));
  }

// -----------------------------------------------------------------------------------------------------
// @ Accessors
// -----------------------------------------------------------------------------------------------------

// Directive
  @ViewChild(FusePerfectScrollbarDirective, {static: true})
  set directive(theDirective: FusePerfectScrollbarDirective) {
    if (!theDirective) {
      return;
    }

    this._fusePerfectScrollbar = theDirective;

    // Update the scrollbar on collapsable item toggle
    this._fuseNavigationService.onItemCollapseToggled
      .pipe(
        delay(500),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(() => {
        this._fusePerfectScrollbar.update();
      });

    // Scroll to the active item position
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        take(1)
      )
      .subscribe(() => {
          setTimeout(() => {
            this._fusePerfectScrollbar.scrollToElement('navbar .nav-link.active', -120);
          });
        }
      );
  }

// -----------------------------------------------------------------------------------------------------
// @ Lifecycle hooks
// -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.currentUser$ = this.store.pipe(select(fromAuth.Selectors.CurrentUser));
    this.accessRoles$ = this.store.pipe(select(fromAuth.Selectors.CurrentAccessRoles));
    this.currentAccessRoleId$ = this.store.pipe(select(fromAuth.Selectors.currentAccessRoleId));
    this.currentSystemDate$ = this.calendarService.getCurrentSystemDate();
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(() => {
          if (this._fuseSidebarService.getSidebar('navbar')) {
            this._fuseSidebarService.getSidebar('navbar').close();
          }
        }
      );

    // Subscribe to the config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.fuseConfig = config;
      });

    // Get current navigation
    this._fuseNavigationService.onNavigationChanged
      .pipe(
        filter(value => value !== null),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(() => {
        this.navigation = this._fuseNavigationService.getCurrentNavigation();
      });
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();

  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar opened status
   */
  toggleSidebarOpened(): void {
    this._fuseSidebarService.getSidebar('navbar').toggleOpen();
  }

  /**
   * Toggle sidebar folded status
   */
  toggleSidebarFolded(): void {
    this._fuseSidebarService.getSidebar('navbar').toggleFold();
  }

  logout(): void {
    this.authService.logout();
  }

  openNotificationBar(): void {
    this.store.dispatch(fromNotification.Actions.ToggleNotificationBar({toggle: true}));
  }

  setSystemDate(event:MatDatepickerInputEvent<Date>):void{
    const systemDate = moment(event.target.value).toDate();
    this.calendarService.setDate(systemDate);
  }

  goToUserList(): boolean{
    this.store.dispatch(fromAdminUsers.Navigate.UserList());
    return false;
  }

  changeAccessRole(accessRoleId: string): void{
    this.store.dispatch(fromAuth.Actions.selectAccessRole({accessRoleId}));
    this.store.dispatch(fromAuth.Actions.navigateToDashboard({accessRoleId}));
  }
}
