import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { select, Store } from "@ngrx/store";
import { first, finalize, filter, map, concatMap, withLatestFrom } from "rxjs/operators";
import { IAppState } from 'app/store/state/app.state';
import * as fromProviderDashboard from '@provider/dashboard/store';
import * as fromInstitution from '@institution/store';
import { ProviderDashboardViewModel } from '@provider/dashboard/models';
import { AcademicYear } from "@shared/calendar/models";

@Injectable()
export class ProviderDashboardResolver implements Resolve<ProviderDashboardViewModel>{
    isLoading = false;
    constructor(private store: Store<IAppState>){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
        const providerId = route.params["providerId"];
        var academicYearId ="";
        return this.store 
            .pipe(
                select(fromInstitution.Selectors.CurrentAcademicYear),
                concatMap((action) => of(action).pipe(
                    withLatestFrom(this.store.pipe(select(fromProviderDashboard.Selectors.SelectorForProviderDashboard)))
                )),
                map(([currentAcademicYear, {currentProviderId, currentAcademicYearId}])=>{
                    var result = !!currentAcademicYear && currentProviderId === providerId && currentAcademicYear.id===currentAcademicYearId;
                    academicYearId = !!currentAcademicYear ? currentAcademicYear.id : "empty";
                    return result;
                }),
                map((dashboardLoaded) => {
                    if(!this.isLoading && !dashboardLoaded){
                        this.isLoading = true;
                        this.store.dispatch(fromProviderDashboard.Actions.LoadDashboardData({providerId,academicYearId}));
                    }
                    return true;
                }),
                filter(dashboardLoaded => dashboardLoaded),
                first(),
                finalize(()=>this.isLoading=false)
            )
    }
}