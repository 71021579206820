import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ViewChild, ElementRef } from '@angular/core';
import { Observable, fromEvent, of } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Order, Sort, BaseListItem } from 'app/core/models';
import { EnrollmentListItemForView, EnrollmentListItem } from '@member/enrollment/models';
import { MatDialog } from '@angular/material/dialog';
import { IAppState } from 'app/store/state/app.state';
import { Store, select } from '@ngrx/store';
import * as fromEnrollment from '@provider/enrollment/store';
import * as fromProvider from '@provider/store';
import * as fromProviderDashboard from '@provider/dashboard/store';
import * as fromRefData from '@refData';
import * as fromInstitution from '@institution/store';
import { map, debounceTime, distinctUntilChanged, withLatestFrom, concatMap } from 'rxjs/operators';
import { Filter, FilterType, SemesterFilter } from '@shared/common/models';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { EnrollmentListMode } from '@provider/enrollment/store/enrollmentListMode.enum';
import { EnrollmentStatusType, EnrollmentStatus } from '@refData';
import { UpdateEnrollmentStatusDialogComponent } from '@provider/enrollment/components/update-enrollment-status-dialog/update-enrollment-status-dialog.component';
import { MatSelectionListChange } from '@angular/material/list';
import { PermissionType } from '@auth/models/permissionType.enum';
import { AcademicYear } from '@shared/calendar/models';
import { UpdateEnrollmentLetterGradeDialogComponent } from '../update-enrollment-letter-grade-dialog/update-enrollment-letter-grade-dialog.component';

@Component({
  selector: 'app-enrollment-list',
  templateUrl: './enrollment-list.component.html',
  styleUrls: ['./enrollment-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnrollmentListComponent implements OnInit {
  pageTitle = 'Enrollments';
  pageSubTitle = '';
  panelOpenState = false;

  filteredEnrollments$: Observable<EnrollmentListItemForView[]>;
  selectedEnrollments$: Observable<EnrollmentListItemForView[]>;
  selectedEnrollmentsForGrading$: Observable<EnrollmentListItemForView[]>;
  selectedStatus$: Observable<string>;
  currentOrder$: Observable<Order>;
  currentSort$: Observable<Sort>;
  canExportToExcel$: Observable<boolean>;
  canSubmitChanges$: Observable<boolean>;
  canSubmitGrading$: Observable<boolean>;
  isAllSelected$: Observable<boolean>;
  indeterminateSelected$: Observable<boolean>;
  sorts$: Observable<Sort[]>;
  orders$: Observable<Order[]>;
  selectedFilters$: Observable<Filter[]>;
  statusFilters$: Observable<Filter[]>;
  enrollmentListMode$: Observable<EnrollmentListMode>;
  letterGrades$: Observable<BaseListItem[]>;
  canViewGrades$: Observable<boolean>;
  commonAllowedStatuses$: Observable<EnrollmentStatus[]>;
  academicYears$: Observable<AcademicYear[]>;
  currentAcademicYear$: Observable<AcademicYear>;
  currentAcademicYearId$: Observable<string>;
  semesterFilters$: Observable<SemesterFilter[]>;
  IsEnrollmentsLoading$: Observable<boolean>;
  memberFilters$: Observable<Filter[]>;
  memberFilterCount$: Observable<number>;
  tagFilters$: Observable<Filter[]>;
  tagFilterCount$: Observable<number>;
  categoryFilters$: Observable<Filter[]>;
  categoryFilterCount$: Observable<number>;
  subCategoryFilters$: Observable<Filter[]>;
  subCategoryFilterCount$: Observable<number>;
  @ViewChild("filter") filter: ElementRef;

  constructor(public dialog: MatDialog, private store: Store<IAppState>) { }

  ngOnInit(): void {
    this.IsEnrollmentsLoading$ = this.store.pipe(select(fromEnrollment.Selectors.IsEnrollmentsLoading));
    this.canExportToExcel$ = this.store.pipe(select(fromEnrollment.Selectors.CanExportToExcel));
    this.canSubmitChanges$ = this.store.pipe(select(fromEnrollment.Selectors.CanSubmitChanges));
    this.canSubmitGrading$ = this.store.pipe(select(fromEnrollment.Selectors.CanSubmitGrading));
    this.currentOrder$ = this.store.pipe(select(fromEnrollment.Selectors.CurrentOrder));
    this.currentSort$ = this.store.pipe(select(fromEnrollment.Selectors.CurrentSort));
    this.filteredEnrollments$ = this.store.pipe(select(fromEnrollment.Selectors.FilteredEnrollments));
    this.indeterminateSelected$ = this.store.pipe(select(fromEnrollment.Selectors.IndeterminateSelected));
    this.isAllSelected$ = this.store.pipe(select(fromEnrollment.Selectors.IsAllSelected));
    this.orders$ = this.store.pipe(select(fromEnrollment.Selectors.Orders));
    this.selectedEnrollments$ = this.store.pipe(select(fromEnrollment.Selectors.SelectedEnrollments));
    this.selectedEnrollmentsForGrading$ = this.store.pipe(select(fromEnrollment.Selectors.SelectedEnrollmentsForGrading));
    this.selectedFilters$ = this.store.pipe(select(fromEnrollment.Selectors.SelectedFilters));
    this.selectedStatus$ = this.store.pipe(select(fromEnrollment.Selectors.SelectedStatus));
    this.sorts$ = this.store.pipe(select(fromEnrollment.Selectors.Sorts));
    this.statusFilters$ = this.store.pipe(select(fromEnrollment.Selectors.StatusFilters));
    this.letterGrades$ = this.store.pipe(select(fromRefData.Selectors.letterGrades));
    this.canViewGrades$ = this.store.pipe(select(fromProvider.Selectors.HasProviderPermission(PermissionType.ReadFinalGrade)));
    this.enrollmentListMode$ = this.store.pipe(select(fromEnrollment.Selectors.EnrollmentListMode));
    this.commonAllowedStatuses$ = this.store.pipe(select(fromEnrollment.Selectors.CommonAllowedStatuses));
    this.academicYears$ = this.store.pipe(select(fromInstitution.Selectors.AcademicYears));
    this.currentAcademicYear$ = this.store.pipe(select(fromInstitution.Selectors.CurrentAcademicYear));
    this.currentAcademicYearId$ = this.store.pipe(select(fromInstitution.Selectors.CurrentAcademicYearId));
    this.semesterFilters$ = this.store.pipe(select(fromEnrollment.Selectors.SemesterFilters));
    this.tagFilters$ = this.store.pipe(select(fromEnrollment.Selectors.TagFiltersForView));
    this.tagFilterCount$ = this.store.pipe(select(fromEnrollment.Selectors.TagFilterCount));
    this.memberFilters$ = this.store.pipe(select(fromEnrollment.Selectors.MemberFiltersForView));
    this.memberFilterCount$ = this.store.pipe(select(fromEnrollment.Selectors.MemberFilterCount));
    this.categoryFilters$ = this.store.pipe(select(fromEnrollment.Selectors.CategoryFiltersForView));
    this.categoryFilterCount$ = this.store.pipe(select(fromEnrollment.Selectors.CategoryFilterCount));
    this.subCategoryFilters$ = this.store.pipe(select(fromEnrollment.Selectors.SubCategoryFiltersForView));
    this.subCategoryFilterCount$ = this.store.pipe(select(fromEnrollment.Selectors.SubCategoryFilterCount));
    this.store.dispatch(fromEnrollment.Actions.LoadSorts({ sorts: fromEnrollment.sorts }));
    this.store.dispatch(fromEnrollment.Actions.LoadOrders({ orders: fromEnrollment.orders }));

  }

  ngAfterViewInit() {
    this.setupTextSearch();
  }

  goToDashboard(): void {
    this.store.dispatch(fromProviderDashboard.Navigate.Dashboard({}));
  }
  masterToggle(): void {
    this.store.dispatch(fromEnrollment.Actions.MasterToggle());
  }
  removeFilter(filter: Filter): void {
    const id = filter.id;
    switch (filter.type) {
      case FilterType.EnrollmentStatus:
        this.store.dispatch(fromEnrollment.Actions.ToggleStatusFilter({ id }));
        break;
      case FilterType.Session:
        this.store.dispatch(fromEnrollment.Actions.ToggleSession({ sessionId: filter.id }));
        break;
      case FilterType.Member:
        this.store.dispatch(fromEnrollment.Actions.ToggleMemberFilter({ id }));
        break;
      case FilterType.Tag:
        this.store.dispatch(fromEnrollment.Actions.ToggleTagFilter({ id }));
        break;
      case FilterType.CourseCategory:
        this.store.dispatch(fromEnrollment.Actions.ToggleCategoryFilter({ id }));
        break;
      case FilterType.CourseSubCategory:
        this.store.dispatch(fromEnrollment.Actions.ToggleSubCategoryFilter({ id }));
        break;
    }
  }
  setupTextSearch(): void {
    fromEvent(this.filter.nativeElement, "keyup").pipe(
      map((event: any) => event.target.value),
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe((searchText: string) => this.store.dispatch(fromEnrollment.Actions.ChangeSearchText({ searchText })))
  }
  onToggleEnrollment(enrollmentId: string): void {
    this.store.dispatch(fromEnrollment.Actions.ToggleEnrollment({ enrollmentId }));
  }
  onToggleSemesterFilter(semesterId: string): void {
    this.store.dispatch(fromEnrollment.Actions.ToggleSemester({ semesterId }));
  }
  onToggleSessionFilter(event: MatSelectionListChange): void {
    const sessionId = event.options[0].value;
    this.store.dispatch(fromEnrollment.Actions.ToggleSession({ sessionId }));
  }
  enrollmentById(enrollment: EnrollmentListItemForView) {
    return enrollment.id;
  }
  onToggleStatusFilter(event: MatSelectionListChange): void {
    const id = event.options[0].value;
    this.store.dispatch(fromEnrollment.Actions.ToggleStatusFilter({ id }));
  }
  changeOrderBy(order: Order): void {
    this.store.dispatch(fromEnrollment.Actions.ChangeOrder({ currentOrder: order }))
  }
  changeSort(sort: Sort): void {
    this.store.dispatch(fromEnrollment.Actions.ChangeSort({ currentSort: sort }))

  }
  editStatus(requestedStatus: BaseListItem, enrollmentId: string): void {
    this.store.dispatch(fromEnrollment.Actions.EditStatus({ enrollmentId, requestedStatus }));
  }
  editLetterGrade(requestedLetterGrade: BaseListItem, enrollmentId: string): void {
    this.store.dispatch(fromEnrollment.Actions.EditLetterGrade({ enrollmentId, requestedLetterGrade }));
  }
  getEnrollmentStatusDescription$(enrollment: EnrollmentListItem): Observable<string> {
    return of(enrollment)
      .pipe(
        withLatestFrom(this.canViewGrades$),
        map(([enrollment, canViewGrade]) => {
          if (enrollment.status.statusType == EnrollmentStatusType.Graded && canViewGrade) {
            return `${enrollment.status.description} - ${enrollment.letterGrade.description}`;
          } else {
            return `${enrollment.status.description}`;
          }
        })
      );
  }
  trackById(item: EnrollmentListItem): string {
    return item.id;
  }

  submitStatusChanges(): void {
    this.store.dispatch(fromEnrollment.Actions.SubmitStatusChanges());
  }

  submitGrading(): void {
    this.store.dispatch(fromEnrollment.Actions.SubmitGrading());
  }

  changeEnrollmentMode(change: MatButtonToggleChange): void {
    const enrollmentListMode = change.value;
    this.store.dispatch(fromEnrollment.Actions.ChangeEnrollmentListMode({ enrollmentListMode }));
  }

  showEditStatus$(enrollment: EnrollmentListItem): Observable<boolean> {
    return of(enrollment)
      .pipe(
        withLatestFrom(this.enrollmentListMode$),
        map(([enrollment, listMode]) => {
          return listMode === EnrollmentListMode.Enrollment && enrollment.status.providerAllowedStatuses.length > 0;
        })
      )
  }

  showEditLetterGrade$(): Observable<boolean> {
    return this.canViewGrades$
      .pipe(
        withLatestFrom(this.enrollmentListMode$),
        map(([canViewGrade, listMode]) => !!canViewGrade && listMode === EnrollmentListMode.Grading)
      )
  }


  updateEnrollments(): void {
    this.dialog.open(UpdateEnrollmentStatusDialogComponent);
  }

  updateLetterGrades(): void {
    this.dialog.open(UpdateEnrollmentLetterGradeDialogComponent);
  }

  compare(o1: any, o2: any): boolean {
    if (!o1 || !o2) return false;
    return o1.id === o2.id;
  }

  exportToExcel(enrollment?: EnrollmentListItem): void {
    const enrollments = [enrollment];
    this.store.dispatch(fromEnrollment.Actions.ExportToExcel({
      enrollments
    }))
  }

  goToStudentDetail(studentId: string): void {
    this.store.dispatch(fromEnrollment.Navigate.StudentDetail({ studentId }));
  }

  getCourseTitle(enrollment: EnrollmentListItemForView): string {
    const memberCourseCode = !!enrollment.memberCourseCode ? `${enrollment.memberCourseCode}, ` : '';
    return `${memberCourseCode}${enrollment.courseTitle}`;
  }

  changeAcademicYear(academicYearId: string): void {
    this.store.dispatch(fromInstitution.Actions.ChangeCurrentAcademicYearId({ academicYearId }));
    this.store.dispatch(fromEnrollment.Actions.LoadEnrollmentsByAcademicYear({ academicYearId }));
  }

  toggleExpanded(semesterFilterId: string): void {
    this.store.dispatch(fromEnrollment.Actions.ToggleSemesterExpanded({ semesterFilterId }));
  }

  onToggleMembersFilter(event: MatSelectionListChange): void {
    const id = event.options[0].value
    this.store.dispatch(fromEnrollment.Actions.ToggleMemberFilter({ id }));
  }
  onToggleTagFilter(event: MatSelectionListChange): void {
    const id = event.options[0].value
    this.store.dispatch(fromEnrollment.Actions.ToggleTagFilter({ id }));
  }
  onToggleCategoryFilter(event: MatSelectionListChange): void {
    const id = event.options[0].value
    this.store.dispatch(fromEnrollment.Actions.ToggleCategoryFilter({ id }));
  }
  onToggleSubCategoryFilter(event: MatSelectionListChange): void {
    const id = event.options[0].value
    this.store.dispatch(fromEnrollment.Actions.ToggleSubCategoryFilter({ id }));
  }
}
