import {Component, OnInit, ViewEncapsulation, Input} from '@angular/core';
import {AccessRole} from 'app/authentication/models/accessRole';
import {IAppState} from 'app/store/state/app.state';
import {select, Store} from '@ngrx/store';
import {PermissionType} from '@auth/models/permissionType.enum';
import * as fromCourseExchange from '@courseExchange/store';
import * as fromCourseExchangeDashboard from '@courseExchange/dashboard/store';
import * as fromCourseExchangeUsers from '@courseExchange/users/store';
import * as fromCourseExchangeCourses from '@courseExchange/courses/store';
import * as fromAuth from '@auth/store';
import * as fromCourseExchangeInstitution from '@courseExchange/institution';
import * as fromCourseExchangeBilling from '@courseExchange/billing/store';
import {BehaviorSubject, Observable} from 'rxjs';
import { CourseExchangeListItem } from '@shared/courseExchange/models';

@Component({
  selector: 'exchange-admin-toolbar',
  templateUrl: './exchange-admin-toolbar.component.html',
  styleUrls: ['./exchange-admin-toolbar.component.scss', '../styles/common-toolbar.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ExchangeAdminToolbarComponent implements OnInit {
  @Input() currentAccessRole: AccessRole;
  @Input() courseExchangeId: string;

  showCourseExchangeFees$: Observable<boolean>;
  showCourseExchangeCourses$: Observable<boolean>;
  showCourseCategories$: Observable<boolean>;
  showBankingInfo$: Observable<boolean>;
  showCourseExchangeCalendar$: Observable<boolean>;
  showBilling$: Observable<boolean>;
  showCourseExchangeUsers$: Observable<boolean>;
  managingInstitutionId$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  showToolbarButtons$: Observable<boolean>;
  currentCourseExchange$: Observable<CourseExchangeListItem>;
  courseExchanges$: Observable<CourseExchangeListItem[]>;

  constructor(
    private store: Store<IAppState>  ) {
  }

  ngOnInit(): void {
    this.showCourseExchangeCourses$ = this.store.pipe(select(fromCourseExchange.Selectors.HasCourseExchangePermission(PermissionType.ReadCourse)));
    this.showCourseExchangeFees$ = this.store.pipe(select(fromCourseExchange.Selectors.HasCourseExchangePermission(PermissionType.SetCourseExchangeFees)));
    this.showCourseCategories$ = this.store.pipe(select(fromCourseExchange.Selectors.HasCourseExchangePermission(PermissionType.ReadCourseCategory)));
    this.showBankingInfo$ = this.store.pipe(select(fromCourseExchange.Selectors.HasCourseExchangePermission(PermissionType.ManageCourseExchangeBankingInformation)));
    this.showCourseExchangeCalendar$ = this.store.pipe(select(fromCourseExchange.Selectors.HasInstitutionPermission(PermissionType.ReadCalendar)));
    this.showBilling$ = this.store.pipe(select(fromCourseExchange.Selectors.HasInstitutionPermission(PermissionType.ManageInstitutionBankingInformation)));
    this.showCourseExchangeUsers$ = this.store.pipe(select(fromCourseExchange.Selectors.HasCourseExchangePermission(PermissionType.ReadCourseExchangeContacts)));
    this.showToolbarButtons$ = this.store.pipe(select(fromCourseExchange.Selectors.ShowToolbarButtons));
    this.currentCourseExchange$ = this.store.pipe(select(fromAuth.Selectors.CurrentCourseExchange));
    this.courseExchanges$ = this.store.pipe(select(fromAuth.Selectors.CourseExchanges));
  }


  logout(): boolean {
    this.store.dispatch(fromAuth.Actions.logout());
    return false;
  }

  goToCoursesData(): boolean {
    const courseExchangeId = this.courseExchangeId;
    this.store.dispatch(fromCourseExchange.Actions.ViewCoursesData({courseExchangeId}));
    return false;
  }

  goToFees(): boolean {
    const courseExchangeId = this.courseExchangeId;
    this.store.dispatch(fromCourseExchange.Actions.ViewFees({courseExchangeId}));
    return false;
  }

  goToCalendar(): boolean {
    const courseExchangeId = this.courseExchangeId;
    this.store.dispatch(fromCourseExchange.Actions.ViewCalendars({courseExchangeId}));
    return false;
  }

  goToBilling(): boolean {
    const courseExchangeId = this.courseExchangeId;
    this.store.dispatch(fromCourseExchangeBilling.Navigate.CourseExchangeBillingSummary({courseExchangeId}));
    return false;
  }

  goToEnrollments(): boolean {
    const courseExchangeId = this.courseExchangeId;
    this.store.dispatch(fromCourseExchange.Actions.ViewEnrollments({courseExchangeId}));
    return false;
  }

  goToUserList(): boolean {
    this.store.dispatch(fromCourseExchangeUsers.Navigate.UserList());
    return false;
  }

  goToDashboard(): boolean {
    const courseExchangeId = this.courseExchangeId;
    this.store.dispatch(fromCourseExchangeDashboard.Navigate.Dashboard({courseExchangeId}));
    return false;
  }

  goToCourseList(): boolean {
    const courseExchangeId = this.courseExchangeId;
    this.store.dispatch(fromCourseExchangeCourses.Navigate.CourseList({courseExchangeId}));
    return false;
  }

  goToInstitutionsList(): void {
    const courseExchangeId = this.courseExchangeId;
    this.store.dispatch(fromCourseExchangeInstitution.Actions.InstitutionList({courseExchangeId}));
  }

  changeCourseExchange(courseExchangeId: string): void{
    if(this.currentAccessRole.courseExchangeId === courseExchangeId) return;
    this.store.dispatch(fromAuth.Actions.selectCourseExchangeId({courseExchangeId}));
  }
}
