import {Component, OnInit, ViewEncapsulation, Input} from '@angular/core';
import {AccessRole} from 'app/authentication/models/accessRole';
import {IAppState} from 'app/store/state/app.state';
import {Store, select} from '@ngrx/store';
import * as fromAdminUsers from '@admin/users/store';
import * as fromSuperAdminDashboard from '@admin/dashboard/store';
import * as fromAuth from '@auth/store';
import { CourseExchangeListItem } from '@shared/courseExchange/models';
import { Observable } from 'rxjs';
import { CalendarService } from '@shared/calendar/services';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import * as moment from 'moment';
import { environment } from 'environments/environment';

@Component({
  selector: 'super-admin-toolbar',
  templateUrl: './super-admin-toolbar.component.html',
  styleUrls: ['./super-admin-toolbar.component.scss', '../styles/common-toolbar.scss'],
  encapsulation: ViewEncapsulation.None
})

export class SuperAdminToolbarComponent implements OnInit {
  @Input() currentAccessRole: AccessRole;
  currentCourseExchange$: Observable<CourseExchangeListItem>;
  courseExchanges$: Observable<CourseExchangeListItem[]>;
  currentSystemDate$: Observable<Date>;
  constructor(
    private store: Store<IAppState>,
    private calendarService: CalendarService
  ) {
    this.currentSystemDate$ = this.calendarService.getCurrentSystemDate();
  }

  ngOnInit(): void {
    this.currentCourseExchange$ = this.store.pipe(select(fromAuth.Selectors.CurrentCourseExchange));
    this.courseExchanges$ = this.store.pipe(select(fromAuth.Selectors.CourseExchanges));
  }

  logout(): boolean {
    this.store.dispatch(fromAuth.Actions.logout());
    return false;
  }

  goToUserList(): boolean {
    this.store.dispatch(fromAdminUsers.Navigate.UserList());
    return false;
  }

  goToDashboard(): boolean {
    this.store.dispatch(fromSuperAdminDashboard.Navigate.Dashboard());
    return false;
  }

  changeCourseExchange(courseExchangeId: string): void{
    if(this.currentAccessRole.courseExchangeId === courseExchangeId) return;
    this.store.dispatch(fromAuth.Actions.selectCourseExchangeId({courseExchangeId}));
  }

  setSystemDate(event:MatDatepickerInputEvent<Date>):void{
    const systemDate = moment(event.target.value).toDate();
    this.calendarService.setDate(systemDate);
  }

  get showSystemDate(): boolean{
    return environment.canOverwiteClock;
  }
}
