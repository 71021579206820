<div class="new-enrollment-from-courses new-enrollment-from-common">
  <div class="head-content">
    <new-enrollment-list-of-selected
      (remove)="onRemove($event)"
      [tableScheme]="tableScheme"
      [list]="courses$ | async"></new-enrollment-list-of-selected>
  </div>

  <div class="body-content">
    <div class="list-content">
      <div class="main main-full">
        <div class="top-panel">
          <div class="search-wrapper">
            <div class="search">
              <mat-icon>search</mat-icon>
              <input #filter placeholder="Search Students">
            </div>
          </div>
          <div class="sorts">
            <div class="field">
              <mat-form-field class="full-list-sort">
                <mat-select placeholder="Sort By" [value]="currentSort$ | async"
                            (selectionChange)="changeSort($event.value)">
                  <mat-select-trigger>
                    <mat-icon>{{(currentSort$ | async)?.icon}}</mat-icon>&nbsp;{{(currentSort$ | async)?.name}}
                  </mat-select-trigger>
                  <mat-option *ngFor="let sort of sorts$ | async" [value]="sort">
                    <mat-icon>{{sort.icon}}</mat-icon>{{sort.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="field">
              <mat-form-field class="full-list-sort">
                <mat-select placeholder="Order By" [value]="currentOrder$ | async"
                            (selectionChange)="changeOrderBy($event.value)">
                  <mat-select-trigger>
                    <mat-icon>{{(currentOrder$ | async)?.icon}}</mat-icon>&nbsp;{{(currentOrder$ | async)?.name}}
                  </mat-select-trigger>
                  <mat-option *ngFor="let order of orders" [value]="order">
                    <mat-icon>{{order.icon}}</mat-icon>{{order.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="list">
            <div [ngClass]="['student-item', student.isActive ? 'is_active' : '']"
                 *ngFor="let student of filteredStudents$ | async; trackBy: trackById">
              <div class="checkbox">
                <mat-checkbox (change)="onToggleStudent(student.id)" [checked]="student.selected"></mat-checkbox>
              </div>

              <div class="logo">
                <img src="{{student.institutionLogoUrl}}">
              </div>

              <div class="student-item-content">
                <div class="col">
                  <div class="text">{{student.firstName}}&nbsp;{{student.lastName}}</div>
                </div>

                <div class="col">
                  <div class="text">Student ID: {{student.studentNumber}}</div>
                </div>

                <div class="col">
                  <div class="link">
                    <a href="mailto:{{student.emailAddress}}">
                      <mat-icon>mail</mat-icon><span class="ellipsis">{{student.emailAddress}}</span>
                    </a>
                  </div>
                </div>

                <div class="col">
                  <div class="link">
                    <a href="tel:{{student.phoneNumber}}" class="ellipsis">
                      <mat-icon>phone</mat-icon><span>{{student.phoneNumber}}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>

  <div class="footer-content">
    <div class="actions">
      <button mat-raised-button color="warn" (click)="close.emit()">
        <mat-icon>close</mat-icon>
        Cancel, Exit
      </button>

      <button mat-raised-button [disabled]="!(canCreateEnrollment$ | async)" (click)="createEnrollment()">
        <mat-icon>person_add</mat-icon>
        Request Enrollment
      </button>
    </div>
  </div>
</div>
