import { AcademicYear, Session } from "@shared/calendar/models"

export default (academicYears:AcademicYear[], sessionId: string): Session => {
  var result = undefined;
  academicYears.forEach((academicYear)=>{
    academicYear.semesters.forEach((semester)=>{
      semester.sessions.forEach((session)=>{
        if(session.id===sessionId){
          result = session;
        }
      })
    })
  })
  return result;  
}