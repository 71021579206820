import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fromServices from './services';

@NgModule({
    declarations: [],
    exports: [],
    imports: [
        CommonModule,
    ],
    providers: [...fromServices.SERVICES]
})
export class StudentSharedModule { }
