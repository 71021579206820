import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { Store, select } from "@ngrx/store";
import { tap, first, finalize, filter, map, concatMap, withLatestFrom, switchMap } from "rxjs/operators";
import { IAppState } from 'app/store/state/app.state';
import * as fromRefData from '@refData';
import * as fromCourseExchange from '@courseExchange/store';
import * as fromCourseExchangeCourses from '@courseExchange/courses/store';
import { CourseExchangeSummary } from '@shared/courseExchange/models';

@Injectable()
export class CourseExchangeSummaryResolver implements Resolve<CourseExchangeSummary>{
    isLoading = false;
    constructor(private store: Store<IAppState>){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
        const courseExchangeId = route.params["courseExchangeId"];
        return this.store 
            .pipe(
                select(fromCourseExchange.Selectors.CourseExchangeSummary),
                map((courseExchangeSummary) => {
                    var result = !!courseExchangeSummary && courseExchangeSummary.id === courseExchangeId;
                    if(result) this.store.dispatch(fromCourseExchange.Actions.LoadCourseExchangeSummarySuccess({courseExchangeSummary}));
                    return result;
                }),
                map((isSummaryLoaded) => {
                    if(!this.isLoading && !isSummaryLoaded){
                        this.isLoading = true;
                        this.store.dispatch(fromCourseExchange.Actions.LoadCourseExchangeSummary({courseExchangeId}));
                    }
                
                    return true;
                }),
                filter(coursesLoaded => coursesLoaded),
                first(),
                finalize(()=>this.isLoading=false)
            )
    }
}