import { Injectable } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class CurrencyFormatter {

  constructor(private cp: CurrencyPipe) {
  }

  format(money: number): string {
    return this.cp.transform(money, 'USD', "symbol", '1.2')
  }
}
