import {Filter, FilterType} from '@shared/common/models';
import { BaseListItem } from '@core/models';

export default  (arr: BaseListItem[], filterType: FilterType): Filter[] => {
  return arr.map(item => {
    return {
      id: item.id,
      description: item.description,
      type: filterType,
      selected: false,
      descriptionForView: item.description
    }
  });
}
