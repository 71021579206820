import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import * as fromFacultyCV from '@provider/facultyCV/store';
@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature('provider/facultyCV', fromFacultyCV.facultyCVReducer),
    EffectsModule.forFeature([...fromFacultyCV.EFFECTS])
  ],
  providers: [...fromFacultyCV.RESOLVERS]
})
export class FacultyCVStoreModule {
}
