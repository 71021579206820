import { CourseExchangeBillingReportDialogComponent, CourseExchangeBillingReportDialogData } from './../../dialogs/courseExchange-billingReport-dialog/courseExchange-billingReport-dialog.component';
import {createEffect, Actions, ofType} from "@ngrx/effects";
import {Injectable} from "@angular/core";
import {map, switchMap, withLatestFrom, concatMap, tap, catchError} from "rxjs/operators";
import {select, Store} from '@ngrx/store';
import {of} from 'rxjs';
import {IAppState} from 'app/store/state/app.state';
import {PopupService} from 'app/core/services/popup.service';
import {MatDialog} from '@angular/material/dialog';
import * as fromCore from '@core/store';
import * as fromCourseExchangeBilling from '@courseExchange/billing/store';
import * as fromCourseExchange from '@courseExchange/store';
import * as moment from 'moment';
import { CourseExchangeService } from '@shared/courseExchange/services/course-exchange.service';
import { ExcelService } from "@core/services/excel.service";

@Injectable()
export class CourseExchangeBillingEffects {
  constructor(private actions$: Actions, private store: Store<IAppState>, private courseExchangeService: CourseExchangeService,
              private dialog: MatDialog, private popupService: PopupService, private excelService: ExcelService) {
  }

  loadMonthlyStatements$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromCourseExchangeBilling.Actions.LoadMonthlyStatements),
      concatMap((action)=> of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromCourseExchange.Selectors.CourseExchange)))
      )),
      switchMap(([action,courseExchange]) =>{
        const courseExchangeId = !!action.courseExchangeId ? action.courseExchangeId : courseExchange.id;
        return this.courseExchangeService.getMonthlyStatements(courseExchangeId)
      }),
      map((monthlyStatements) => fromCourseExchangeBilling.Actions.LoadMonthlyStatementsSuccess({monthlyStatements})),
      catchError(() =>
        of(fromCourseExchangeBilling.Actions.LoadMonthlyStatementsError(
          {message: 'Problem retrieving Course Exchange Monthly Summaries'}
        )))
    )
  });

  loadCourseExchangeMonthlySummariesError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromCourseExchangeBilling.Actions.LoadMonthlyStatementsError),
      /** An EMPTY observable only emits completion. Replace with your own observable stream */
      map(({message}) => fromCore.Actions.ShowMessage({message})));
  });

  downloadMonthlyStatement$ = createEffect(() => {
    return this.actions$.pipe(
            ofType(fromCourseExchangeBilling.Actions.DownloadMonthlyStatement),
            tap(({monthlyStatement})=> this.courseExchangeService.downloadMonthlyStatement(monthlyStatement))
            );
    },{dispatch : false});

    showCourseExchangeBillingSummary$ = createEffect(() => {
      return this.actions$.pipe(
        ofType(fromCourseExchangeBilling.Actions.ShowCourseExchangeBillingReportDialog),
        tap((action) => this.dialog.open<CourseExchangeBillingReportDialogComponent, CourseExchangeBillingReportDialogData>(CourseExchangeBillingReportDialogComponent, {
          data: {
            year : action.year,
            month: action.month
          }
        })));
    }, { dispatch: false });

    exportCourseExchangeBillingReport$ = createEffect(() => {
      let courseExchangeName = '';
      let year = 0;
      let month = 0;
      return this.actions$.pipe(
          ofType(fromCourseExchangeBilling.Actions.ExportCourseExchangeBillingReport),
          concatMap((action)=> of(action).pipe(
            withLatestFrom(this.store.pipe(select(fromCourseExchange.Selectors.CourseExchange)))
          )),
          /** An EMPTY observable only emits completion. Replace with your own observable stream */
          switchMap(([action, courseExchange]) => {
            courseExchangeName = courseExchange.name;
            year = action.year;
            month = action.month;
            return this.excelService.exportCourseExchangeBillingSummary(courseExchange.id,year,month);
          }),
          tap((data) => {
            const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const linkDomObject: HTMLAnchorElement = document.createElement('a');
            linkDomObject.href = URL.createObjectURL(blob);
            linkDomObject.download = `BillingSummary_${courseExchangeName}-${year}-${month}.xlsx`;
            document.body.appendChild(linkDomObject);
            linkDomObject.click();
            linkDomObject.remove();
          })
      )
    },{dispatch:false});

    exportMemberDetailedReport$ = createEffect(() => {
      let courseExchangeName = '';
      let year = 0;
      let month = 0;
      return this.actions$.pipe(
          ofType(fromCourseExchangeBilling.Actions.ExportMemberDetailedReport),
          concatMap((action)=> of(action).pipe(
            withLatestFrom(this.store.pipe(select(fromCourseExchange.Selectors.CourseExchange)))
          )),
          /** An EMPTY observable only emits completion. Replace with your own observable stream */
          switchMap(([action, courseExchange]) => {
            courseExchangeName = courseExchange.name;
            year = action.year;
            month = action.month;
            return this.excelService.exportCourseExchangeMemberDetailedReport(courseExchange.id,year,month);
          }),
          tap((data) => {
            const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const linkDomObject: HTMLAnchorElement = document.createElement('a');
            linkDomObject.href = URL.createObjectURL(blob);
            linkDomObject.download = `MemberDetailedReport_${courseExchangeName}-${year}-${month}.xlsx`;
            document.body.appendChild(linkDomObject);
            linkDomObject.click();
            linkDomObject.remove();
          })
      )
    },{dispatch:false});

    exportProviderDetailedReport$ = createEffect(() => {
      let courseExchangeName = '';
      let year = 0;
      let month = 0;
      return this.actions$.pipe(
          ofType(fromCourseExchangeBilling.Actions.ExportProviderDetailedReport),
          concatMap((action)=> of(action).pipe(
            withLatestFrom(this.store.pipe(select(fromCourseExchange.Selectors.CourseExchange)))
          )),
          /** An EMPTY observable only emits completion. Replace with your own observable stream */
          switchMap(([action, courseExchange]) => {
            courseExchangeName = courseExchange.name;
            year = action.year;
            month = action.month;
            return this.excelService.exportCourseExchangeProviderDetailedReport(courseExchange.id,year,month);
          }),
          tap((data) => {
            const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const linkDomObject: HTMLAnchorElement = document.createElement('a');
            linkDomObject.href = URL.createObjectURL(blob);
            linkDomObject.download = `ProviderDetailedReport_${courseExchangeName}-${year}-${month}.xlsx`;
            document.body.appendChild(linkDomObject);
            linkDomObject.click();
            linkDomObject.remove();
          })
      )
    },{dispatch:false});
}
