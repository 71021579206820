import { NgModule } from '@angular/core';
import { UserSharedModule } from '../user/user-shared.module';
import { InstitutionSharedModule } from '../institution/institution-shared.module';
import { CourseExchangeSharedModule } from '../courseExchange/courseExchange-shared.module';
import { CalendarSharedModule } from '../calendar/calendar.module';
import * as fromComponents from './components';
import * as fromWizard from './store';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '@core/material/material.module';
import { StoreModule } from '@ngrx/store';
import {DirectivesModule} from '../../directives/directives.module';
@NgModule({
    declarations: [...fromComponents.COMPONENTS],
    exports: [...fromComponents.COMPONENTS],
    imports: [
        CommonModule,
        CalendarSharedModule,
        CourseExchangeSharedModule,
        InstitutionSharedModule,
        MaterialModule,
        UserSharedModule,
        DirectivesModule,
        StoreModule.forFeature(fromWizard.wizardStateFeatureKey, fromWizard.wizardReducer)
    ]
})
export class WizardSharedModule { }
