import { props, createAction } from '@ngrx/store';
import { ProviderCourseListItem } from '@provider/courses/models/providerCourseListItem.model';

export const CourseList = createAction(
    '[Navigate] - Course Exchange Course List',
    props<{
      courseExchangeId?: string;
      managingInstitutionId?: string;
    }>()
)

export const CourseDetail = createAction(
    '[Navigate] - Course Exchange Course Detail',
    props<{
        courseExchangeId?: string;
        managingInstitutionId?: string;
        providerCourse: ProviderCourseListItem;
    }>()
)