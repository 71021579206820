<h1 mat-dialog-title>
    {{title}}
  </h1>
   
  <div mat-dialog-content>
    <p>{{message}}</p>
  </div>
   
  <div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onConfirm()">{{okButtonText}}</button>
    <button mat-button (click)="onDismiss()" *ngIf="showCancelButton">{{cancelButtonText}}</button>
  </div>