<mat-toolbar>
  <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <span class="title dialog-title">Add to Pre-Selected Member Courses</span>
    <button mat-icon-button (click)="close()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div class="content" *ngIf="(currentMemberCourse$ | async) as memberCourse">
  <ng-container *ngIf="(hasInstitutionCourseCodes$ | async)">
    <div class="title">Success!</div>

    <div class="description">
      <p>You have added {{memberCourse.code}}, {{memberCourse.title}} to your pre-selected member courses.
      </p>

      <p>Would you like to enter your institutional course code?</p>
    </div>
  </ng-container>

  <ng-container *ngIf="(hasInstitutionCourseCodes$ | async)">
    <div class="description">
      <p>{{memberCourse.code}}, {{memberCourse.title}} has the following institutional course codes:</p>
    </div>

    <div class="code-list">
      <div class="code-item" *ngFor="let code of institutionCourseCodes$ | async">
        <div class="action">
          <button mat-icon-button
                  [disabled]="isSending"
                  (click)="removeInstitutionalCode(memberCourse.memberCourseId, code)">
            <mat-icon>close</mat-icon>
          </button>
        </div>

        <div class="code">{{code}}</div>
      </div>
    </div>

    <div class="description">
      <p>Add additional institutional course code:</p>
    </div>
  </ng-container>

  <form class="form" [formGroup]="form">
    <div class="field">
      <mat-form-field appearance="outline" class="mt-8" fxFill>
        <mat-label>Institutional Course Code</mat-label>
        <input
          matInput
          name="code"
          formControlName="code">
        <mat-error *ngIf="hasError('code', 'required')">Code is required</mat-error>
        <mat-error *ngIf="hasError('code', 'maxlength')">You have exceeded the maximum length ({{maxLengthForCode}} characters)</mat-error>
      </mat-form-field>
    </div>

    <div class="actions actions-single">
      <div class="button">
        <button mat-raised-button
                [disabled]="isSending || !form.valid"
                (click)="onAddAnother(memberCourse.memberCourseId)">
          <mat-icon>add</mat-icon>
          Save and Add Another
        </button>
      </div>
    </div>

    <div class="actions">
      <div class="button">
        <button mat-raised-button color="warn" (click)="onCancel()">
          <mat-icon>not_interested</mat-icon>
          No, Exit
        </button>
      </div>

      <div class="button">
        <button mat-raised-button
                [disabled]="isSending || !form.valid"
                (click)="onSave(memberCourse.memberCourseId)">
          <mat-icon>save</mat-icon>
          Save
        </button>
      </div>
    </div>
  </form>
</div>
