import { createAction, props } from "@ngrx/store";

export const ProviderCourses = createAction(
    '[Provider Navigation] - Provider Courses',
    props<{
        providerId?: string,
        institutionId?: string
    }>()
)

export const AddProviderCourse = createAction(
    '[Provider Navigation] - Add Provider Course',
    props<{
        providerId?: string,
        institutionId?: string
    }>()
)

export const CourseSchedule = createAction(
    '[Provider Navigation] - Course Schedule',
    props<{
        providerId?: string,
        institutionId?: string
    }>()
)

export const Fees = createAction(
    '[Provider Navigation] - Fee',
    props<{
        providerId?: string,
        institutionId?: string
    }>()
)

export const Calendar = createAction(
    '[Provider Navigation] - Calendar',
    props<{
        providerId?: string,
        institutionId?: string
    }>()
)

export const Policies = createAction(
    '[Provider Navigation] - Policies',
    props<{
        providerId?: string,
        institutionId?: string
    }>()
)

export const Users = createAction(
    '[Provider Navigation] - Users',
    props<{
        providerId?: string,
        institutionId?: string
    }>()
)