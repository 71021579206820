<div [ngClass]="['notification-bar-component', accessRole?.viewContext ? accessRole.viewContext: '']">
  <div class="header">
    <div class="top">
      <div class="title">Notifications</div>
    </div>
    <ng-container *ngIf="(notifications$ | async)?.length > 0">
      <div class="mark-as-read-all" (click)="markAsReadAll()">Mark All as Read</div>
    </ng-container>
  </div>
  <div class="body">
    <div *ngIf="(notifications$ | async); else loadingBlock">
      <div class="notifications-list">
        <div *ngFor="let notification of notifications$ | async" class="notifications-item">
          <div class="title">
            {{notification.title}}
          </div>
          <div class="description">
            {{notification.message}}
          </div>

          <div class="actions">
            <button mat-raised-button
                    (click)="navigateTo(notification)"
                    color="warn">
              Navigate
            </button>

            <button mat-raised-button
                    (click)="markAsRead(notification)"
                    class="btn-primary">
              Mark as read
            </button>
          </div>
        </div>
      </div>
    </div>

    <ng-template #loadingBlock>
      <p class="loading">...Loading</p>
    </ng-template>
  </div>
</div>
