import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Course } from '../../../shared/institution/models/course.model';
import { ElbertFile, BaseResponse } from 'app/core/models';
import { ProviderCourseListItem } from '@provider/courses/models/providerCourseListItem.model';
import { AddCourseCommand, UpdateCourseCommand } from '@provider/courses/models/commands';
import { ConfigService } from '@core/services/config.service';
import { saveAs } from 'file-saver';
import { CourseDataMergeCommand } from '@provider/courses/models/commands/courseDataMerge.command';

@Injectable()
export class CourseService {
  private apiUrl:string='';
  private migrationUrl:string='';
  private coursesUrl: string='';
  constructor(private http:HttpClient, private config:ConfigService){
    this.config.apiUrl$.subscribe(result=>{
      this.apiUrl=`${result}/provider`;
      this.coursesUrl=`${result}/institution`;
      this.migrationUrl=`${result}/migrationProvider`;
    });
  }

  public getCourses(providerId: string): Observable<ProviderCourseListItem[]>{
    return this.http.get<ProviderCourseListItem[]>(`${this.apiUrl}/${providerId}/course`);
  }
  public addCourse(command: AddCourseCommand): Observable<BaseResponse>
  {
    return this.http.post<BaseResponse>(`${this.apiUrl}/${command.institutionId}/course`,command);
  }

  public addCourses(commands: AddCourseCommand[]): Observable<BaseResponse>
  {
    const institutionId = !!commands[0] ? commands[0].institutionId : '';
    return this.http.post<BaseResponse>(`${this.coursesUrl}/${institutionId}/importCourses`,commands);
  }
  
  public mergeCourseData(providerId: string,institutionId: string, commands: CourseDataMergeCommand[]): Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.migrationUrl}/${providerId}/${institutionId}/courses`,commands);
  }

  public getCourseData(providerId: string, institutionId: string):Observable<any[]>{
    return this.http.get<any[]>(`${this.migrationUrl}/${providerId}/${institutionId}/courses`);
  }
  
  public editCourse(institutionId: string, command:UpdateCourseCommand): Observable<BaseResponse>{
    return this.http.put<BaseResponse>(`${this.apiUrl}/${institutionId}/course`,command);
  }

  public activateCourse(institutionId: string, id: string): Observable<BaseResponse>{
    return this.http.put<BaseResponse>(`${this.apiUrl}/${institutionId}/course/activate`,{id});
  }

  public deactivateCourse(institutionId: string, id: string): Observable<BaseResponse>{
    return this.http.put<BaseResponse>(`${this.apiUrl}/${institutionId}/course/deactivate`,{id});
  }
  public getCourseById(institutionId: string, courseId: string): Observable<Course>
  {
    return this.http.get<Course>(`${this.apiUrl}/${institutionId}/course/${courseId}`);
  }
  
  public downloadSyllabus(providerId: string, courseId: string, file: ElbertFile) {    
    return this.http.get(`${this.apiUrl}/${providerId}/course/syllabus/download/${courseId}`, { responseType: "blob" })  
      .subscribe((result: any) => {  
        if (result.type != 'text/plain') {  
          var blob = new Blob([result]);  
            
          saveAs(blob, file.fileName);  
        }  
      }  
      );  
  }
  public ImportBulkCourseJson(path: string): Observable<any>{
    return this.http.get<any>(`${this.migrationUrl}/ImportBulkCourseJson?path=`+path);
  }
}
