

import {createFeatureSelector, createSelector} from '@ngrx/store';
import { MemberState } from '../reducers/member.reducer';
import { EntityStatus } from '@core/models';
import { PermissionType } from '@auth/models/permissionType.enum';
import * as fromAuth from '@auth/store';
import * as fromInstitution from '@institution/store';
import { ViewContext } from '@auth/models/viewContext.enum';
import { memberBillingReducer } from '@member/billing/store';
export const selectMemberState = createFeatureSelector<MemberState>('member');

export const Member = createSelector(
    selectMemberState,
    (state) => state.member
);
export const CourseExchangeId = createSelector(
    Member,
    (member) => !!member ? member.courseExchangeId : ""
);
export const MemberId = createSelector(
    Member,
    (member) => !!member ? member.id : ""
);

export const DateRangeArgs = createSelector(
    Member,
    fromAuth.Selectors.DateRange,
    (member, dateRange) =>{
        return{
            memberId: !!member ? member.id : '',
            startDate: dateRange.startDate,
            endDate: dateRange.endDate
        }
    }
)

export const InstitutionId = createSelector(
    selectMemberState,
    (state) => !!state.member ? state.member.institutionId : ''
);

export const IdsForEffects = createSelector(
    MemberId,
    InstitutionId,
    CourseExchangeId,
    (memberId, institutionId, courseExchangeId) => {
        return {
            memberId,
            institutionId,
            courseExchangeId            
        }
    }
) 

export const MemberStatus = createSelector(
    Member,
    (member) => !!member ? member.status : EntityStatus.None
)

export const HasInstitutionPermission = (permissionType: PermissionType)=> createSelector(
    fromAuth.Selectors.CurrentUser,
    fromAuth.Selectors.IsSuperAdmin,
    InstitutionId,
    (user, isSuperAdmin, institutionId) => {
      if(isSuperAdmin) return true;
      var accessRole = !!user && !!user.accessRoles ? user.accessRoles.find(x=>x.entityId===institutionId && x.viewContext===ViewContext.Institution):null;
      if(!accessRole) return false;
      if(!accessRole.permissions) return false;
      return accessRole.permissions.find(x=>x===permissionType) !== undefined;
    }
  )

  export const HasMemberPermission = (permissionType: PermissionType)=> createSelector(
    fromAuth.Selectors.CurrentUser,
    fromAuth.Selectors.IsSuperAdmin,
    MemberId,
    (user, isSuperAdmin, memberId) => {
      if(isSuperAdmin) return true;
      var accessRole = !!user && !!user.accessRoles ? user.accessRoles.find(x=>x.entityId===memberId && x.viewContext===ViewContext.Member):null;
      if(!accessRole) return false;
      if(!accessRole.permissions) return false;
      return accessRole.permissions.find(x=>x===permissionType) !== undefined;
    }
  )

  export const ShowToolbarButtons = createSelector(
    Member,
    (member) => {
        return !!member && member.status == EntityStatus.Approved;
    }
)