import { Checklist } from '@core/models'
import { ChecklistItemType } from '@core/models/checklistItemType.enum'

export const completeChecklistItem = (checklist: Checklist, itemType: ChecklistItemType): Checklist =>{
    if(checklist.itemType === itemType){
        checklist.isDone = true;
    }else{
        checklist.items.forEach(item =>{
            if(item.itemType == itemType){
                item.isDone = true;
            }
            completeChecklistItem(item,itemType);
        });
    }
    checklist.isComplete = checklist.items.length ? checklist.items.every(x=>x.isDone) : checklist.isDone;
    return checklist;
}

export const incompleteChecklistItem = (checklist: Checklist, itemType: ChecklistItemType): Checklist =>{
    if(checklist.itemType === itemType){
        checklist.isDone = false;
    }else{
        checklist.items.forEach(item =>{
            if(item.itemType == itemType){
                item.isDone = false;
            }
            incompleteChecklistItem(item,itemType);
        });
    }
    checklist.isComplete = checklist.items.length ? checklist.items.every(x=>x.isDone) : checklist.isDone;
    return checklist;
}

export const completeChecklistItemByIdenitifer = (checklist: Checklist, identifier: string): Checklist =>{
    if(checklist.identifier === identifier){
        checklist.isDone = true;
    }else{
        checklist.items.forEach(item =>{
            if(item.identifier == identifier){
                item.isDone = true;
            }
            completeChecklistItemByIdenitifer(item, identifier);
        }) 
    }
    checklist.isComplete = checklist.items.length ? checklist.items.every(x=>x.isDone) : checklist.isDone;
    return checklist;
}

export const incompleteChecklistItemByIdenitifer = (checklist: Checklist, identifier: string): Checklist =>{
    if(checklist.identifier === identifier){
        checklist.isDone = false;
    }else{
        checklist.items.forEach(item =>{
            if(item.identifier == identifier){
                item.isDone = false;
            }
            incompleteChecklistItemByIdenitifer(item, identifier);
        }) 
    }
    checklist.isComplete = checklist.items.length ? checklist.items.every(x=>x.isDone) : checklist.isDone;
    return checklist;
}



