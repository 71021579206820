import * as fromCourseExchangeUsers from '@courseExchange/users/store';
import * as fromCourseExchange from '@courseExchange/store';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
@NgModule({
    imports: [
        StoreModule.forFeature('courseExchange/users', fromCourseExchangeUsers.courseExchangeUsersReducer),
        EffectsModule.forFeature([...fromCourseExchangeUsers.EFFECTS])
    ],
    providers: [...fromCourseExchangeUsers.RESOLVERS]
})
export class CourseExchangeUsersStoreModule { }
