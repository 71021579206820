import { createEffect, Actions, ofType } from "@ngrx/effects";
import { Injectable } from "@angular/core";
import { map, switchMap, withLatestFrom, concatMap, tap } from "rxjs/operators";
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { IAppState } from 'app/store/state/app.state';
import { PopupService } from 'app/core/services/popup.service';
import * as fromCore from '@core/store';
import * as fromFacultyCV from '@provider/facultyCV/store';
import * as fromProvider from '@provider/store';
import { StudentAddedDialogComponent, StudentAddedDialogData, StudentDeleteDialogComponent, StudentDeleteDialogData, StudentResultActionDialogComponent, StudentResultActionDialogData } from '@member/students/components';
import { MatDialog } from '@angular/material/dialog';
import { InstitutionService } from '@shared/institution/services/institution.service';
import { Router } from '@angular/router';

@Injectable()
export class FacultyCVNavigateEffects{
    constructor(private actions$: Actions, private router: Router, private store: Store<IAppState>){}

    goToFacultyCVList$ = createEffect(() => {
        return this.actions$.pipe(
          ofType(fromFacultyCV.Navigate.FacultyCVList),
          concatMap(action => of(action).pipe(
            withLatestFrom(this.store.pipe(select(fromProvider.Selectors.Provider)))
          )),
          map(([action, provider]) => {
            const providerId = provider.id;
            const institutionId = provider.institutionId;
    
            return this.router.navigate(['provider',
              providerId,
              institutionId,
              'facultyCV',
              'list']);
          }
          ))
      }, { dispatch: false });
}