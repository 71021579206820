import {createFeatureSelector, createSelector} from '@ngrx/store';
import { UsersState } from '..';
export const selectUsersState = createFeatureSelector<UsersState>('institution/users');

export const Users = createSelector(
    selectUsersState,
    (state) => state.users
);
export const InstitutionId = createSelector(
    selectUsersState,
    (state) => state.institutionId
);
export const CurrentUser = createSelector(
    selectUsersState,
    (state) => state.currentUser
);
export const UserForResolver = createSelector(
    selectUsersState,
    (state) =>{
        return {
            currentUser: state.currentUser,
            InstitutionId: state.institutionId
        }
    }
)
export const UsersForResolver = createSelector(
    selectUsersState,
    (state) =>{
        return {
            users: state.users,
            currentInstitutionId: state.institutionId
        }
    }
)
export const UsersForView = createSelector(
    Users,
    (users) =>{
     if(!users) return [];
    const accountingUser = users.find(x=>x.institutionRole ==='Accounting');
      const presidentUser = users.find(x=>x.institutionRole === 'President');
      const vicePresidentUser = users.find(x=>x.institutionRole === 'Vice President');
      const provostUser = users.find(x=>x.institutionRole === 'Provost');

      const accounting = {...accountingUser, titleForView: 'Accounting'};
      const president = {...presidentUser, titleForView: 'President'};
      const vpOrProvost = !!vicePresidentUser ? vicePresidentUser : provostUser;
      const vicePresidentOrProvost = {...vpOrProvost, titleForView: 'Vice President Or Provost'};

      return [accounting, president, vicePresidentOrProvost];
    }
)

export const AccountingUser = createSelector(
    Users,
    (users) =>  {
        return !!users ? users.find(x=>x.institutionRole === 'Accounting') : undefined;
    }
)

export const PresidentUser = createSelector(
    Users,
    (users) => !!users ? users.find(x=>x.institutionRole === 'President') : undefined
)

export const ProvostUser = createSelector(
    Users,
    (users) => !!users ? users.find(x=>x.institutionRole === 'Provost') : undefined
)

export const VicePresidentUser = createSelector(
    Users,
    (users) => !!users ? users.find(x=>x.institutionRole === 'Vice President') : undefined
)

export const ProvostOrVicePresidentUser = createSelector(
    ProvostUser,
    VicePresidentUser,
    (provost, vicePresident) => !!vicePresident ? vicePresident : provost
)
