<div class="wizard-step wizard-contacts-step">
  <div [ngSwitch]="currentStepId" class="steps-content">
    <ng-container *ngSwitchCase="'Calendar_AddYear'">
      <wizard-calendar-step-add-year
        [institutionId]="institutionId"
        [academicYear]="academicYear"
        (addAcademicYear)="addAcademicYear.emit($event)"
        (editAcademicYear)="editAcademicYear.emit($event)"
        (navigate)="navigate.emit($event)">
      </wizard-calendar-step-add-year>
    </ng-container>
    <ng-container *ngSwitchCase="'Calendar_AddSemesters'">
      <wizard-calendar-step-add-semester
      [institutionId]="institutionId"
      [academicYear]="academicYear"
      [academicYearId]="academicYear?.id"
      [semester]="semester"
      [showSessions]="showSessions"
      (addSemester)="addSemester.emit($event)"
      (editSemester)="editSemester.emit($event)"
      (navigate)="navigate.emit($event)">
    </wizard-calendar-step-add-semester>
    </ng-container>
    <ng-container *ngSwitchCase="'Calendar_AddSessions'">
      <wizard-calendar-step-add-session
        [institutionId]="institutionId"
        [semester]="semester"
        [semesterId]="semester?.id"
        [session]="session"
        [academicYearId]="academicYear?.id"
        [sessionDateRules]="sessionDateRules"
        [session]="session"
        (addSession)="addSession.emit($event)"
        (editSession)="editSession.emit($event)"
        (navigate)="navigate.emit($event)">
      </wizard-calendar-step-add-session>
    </ng-container>
  </div>
</div>
