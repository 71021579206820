import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { IAppState } from 'app/store/state/app.state';
import { Store } from '@ngrx/store';
import * as fromProviderShared from '@shared/provider/store';
export interface DataMigrationDialogData {
  providerId: string;
  institutionId: string;
}

@Component({
  selector: 'data-migration-dialog',
  templateUrl: './data-migration-dialog.component.html',
  styleUrls: ['./data-migration-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {'class': 'data-migration-dialog'}
})
export class DataMigrationDialogComponent {
  providerId: string;
  constructor(
    public matDialogRef: MatDialogRef<DataMigrationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public matDialogData: DataMigrationDialogData,
    public store: Store<IAppState>
  ) {
    this.matDialogRef.addPanelClass('data-migration-dialog');
    this.providerId = matDialogData.providerId;
  }

  close(): void {
    this.matDialogRef.close();
  }

  enrollmentMergeData(): void{
    const {providerId, institutionId} = this.matDialogData;
    this.store.dispatch(fromProviderShared.Actions.SelectEnrollmentMigrationDataFile({providerId, institutionId}));
  }
  getEnrollmentMergeData(): void{
    const {providerId, institutionId} = this.matDialogData;
    this.store.dispatch(fromProviderShared.Actions.GetEnrollmentMigrationData({providerId,institutionId}));
  }
  getCourseMigrationData(): void{
    const {providerId, institutionId} = this.matDialogData;
    this.store.dispatch(fromProviderShared.Actions.GetCourseMergeData({providerId,institutionId}));
  }
  courseMergeData(): void{
    const {providerId, institutionId} = this.matDialogData;
    this.store.dispatch(fromProviderShared.Actions.SelectCourseMergeDataFile({providerId,institutionId}));
  }
  gradesMergeData(): void{
    const {providerId, institutionId} = this.matDialogData;
    this.store.dispatch(fromProviderShared.Actions.SelectLetterGradeMigrationDataFile({providerId,institutionId}));
  }
  getGradeMergeData(): void{
    const {providerId, institutionId} = this.matDialogData;
    this.store.dispatch(fromProviderShared.Actions.GetLetterGradeMigrationData({providerId,institutionId}));
  }
}
