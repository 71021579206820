import { Inject, Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { Store, select } from "@ngrx/store";
import { tap, first, finalize, filter, map } from "rxjs/operators";
import { IAppState } from 'app/store/state/app.state';
import * as fromRefData from '@refData';
import { BaseListItem } from '@core/models';
@Injectable()
export class CourseExchangesResolver implements Resolve<BaseListItem[]>{
    isLoading = false;
    constructor(private store: Store<IAppState>){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
        return this.store 
            .pipe(
                select(fromRefData.Selectors.courseExchanges),
                map((courseExchanges)=>{
                    if(!!courseExchanges && courseExchanges.length>0){
                        this.store.dispatch(fromRefData.Actions.LoadCourseExchangesSuccess({courseExchanges}));
                    }else if(!this.isLoading){
                        this.isLoading = true;
                        this.store.dispatch(fromRefData.Actions.LoadCourseExchanges({forceReload:false}));
                    }
                    return true;
                }),
                filter(courseExchanges => !!courseExchanges),
                first(),
                finalize(()=>this.isLoading=false)
            )
    }
}