import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {StudentListItem} from '@shared/student/models';
import { DeleteStudentCommand } from '@shared/student/commands';
import * as fromStudent from '@member/students/store';
import { IAppState } from 'app/store/state/app.state';
import { Store } from '@ngrx/store';

export interface StudentDeleteDialogData {
  student: StudentListItem;
}

@Component({
  selector: 'student-delete-dialog',
  templateUrl: './student-delete-dialog.component.html',
  styleUrls: ['./student-delete-dialog.component.scss', '../styles/student-added-delete-common.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StudentDeleteDialogComponent {

  constructor(
    public dialog: MatDialog,
    public matDialogRef: MatDialogRef<StudentDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public matDialogData: StudentDeleteDialogData,
    private store: Store<IAppState>
  ) {
    this.matDialogRef.addPanelClass('student-delete-dialog');
  }

  close(): void {
    this.matDialogRef.close();
  }

  delete(): void {
    var command: DeleteStudentCommand ={
      studentId: this.matDialogData.student.id
    };
    this.store.dispatch(fromStudent.Actions.DeleteStudent({command}));
    this.close();
  }
}
