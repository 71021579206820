import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { FuseConfigService } from '@fuse/services/config.service';
import { navigation } from 'app/navigation/navigation';
import { AccessRole } from 'app/authentication/models/accessRole';
import { UserSummary } from 'app/authentication/models/userSummary.model';
import { AuthenticationService } from 'app/authentication/services/authentication.service';
import { IAppState } from 'app/store/state/app.state';
import { Store, select } from '@ngrx/store';
import { isLoggedIn, CurrentUser, CurrentAccessRole } from 'app/authentication/store/auth.selectors';

@Component({
    selector     : 'vertical-layout-1',
    templateUrl  : './layout-1.component.html',
    styleUrls    : ['./layout-1.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class VerticalLayout1Component implements OnInit, OnDestroy
{
    accessRole$: Observable<AccessRole>;
    fuseConfig$: BehaviorSubject<any> = new BehaviorSubject<any>({});
    navigation: any;
    isAuthorized$: Observable<boolean>;
    // Private
    private _unsubscribeAll: Subject<any>;

    user$: Observable<UserSummary>;
    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private store: Store<IAppState>
    )
    {
        // Set the defaults
        this.navigation = navigation;

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {
                this.fuseConfig$.next(config);
            });
        this.isAuthorized$ = this.store.pipe(select(isLoggedIn));
        this.accessRole$ = this.store.pipe(select(CurrentAccessRole));
        this.user$ = this.store.pipe(select(CurrentUser));
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }
}
