import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromCourseSchedule from '@provider/course-schedule/store';
@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature('provider/courseSchedule', fromCourseSchedule.courseScheduleReducer),
    EffectsModule.forFeature([...fromCourseSchedule.EFFECTS])
  ],
  providers: []
})
export class ProviderCourseScheduleStoreModule { }
