import {createAction, props} from '@ngrx/store';
import { ProviderCourseListItem, ProviderCourseListItemForView } from '@provider/courses/models/providerCourseListItem.model';
import { Course, FacultyCV } from '@shared/institution/models';
import { Sort, Order, ElbertFile } from '@core/models';
import { Filter } from '@shared/common/models';
import { PresetFilterType } from '../enums';
import { AddCourseCommand, UpdateCourseCommand } from '@provider/courses/models/commands';
import { UpdateSyllabusCommand } from '@shared/provider/commands';

export const LoadCourses = createAction(
  '[Provider Courses Effects] - Load Courses',
  props<{
    providerId: string;
  }>()
)
export const LoadCoursesSuccess = createAction(
  '[Provider Courses Resolver] - Load Courses Success',
  props<{
    courses: ProviderCourseListItemForView[];
  }>()
);

export const LoadCoursesError = createAction(
  '[Provider Courses Resolver] - Load Courses Error',
  props<{
    message: string
  }>()
);

export const LoadCourse = createAction(
  '[Provider Courses Effects] - Load Course',
  props<{
    institutionId: string,
    courseId: string;
  }>()
)
export const LoadCourseSuccess = createAction(
  '[Provider Course Resolver] - Load Course',
  props<{
    currentCourse: Course;
  }>()
);

export const LoadFacultyCVList = createAction(
  '[Provider Courses Effects] - Load Faculty CVs',
  props<{
    institutionId: string,
    courseId: string;
  }>()
)
export const LoadFacultyCVListSuccess = createAction(
  '[Provider Course Resolver] - Load Faculty CVs Success',
  props<{
    currentFacultyCVs: FacultyCV[];
  }>()
);

export const AddCourse = createAction(
  '[Provider Course List] - Add Course',
  props<{
    command: AddCourseCommand
  }>()
)

export const AddCourseSuccess = createAction(
  '[Provider Course List] - Add Course Success',
  props<{
    providerCourse: ProviderCourseListItem
  }>()
)

export const ProviderCourseListItemAdded = createAction(
  '[Provider Course Realtime] - Provider Course List Item Added',
  props<{
    course: ProviderCourseListItem
  }>()
)

export const ProviderCourseListItemUpdated = createAction(
  '[Provider Course Realtime] - Provider Course List Item Updated',
  props<{
    course: ProviderCourseListItem
  }>()
)

export const ProviderCourseListItemsDeleted = createAction(
  '[Provider Course Realtime] - Provider Course List Item Deleted',
  props<{
    courseIds: Array<string>
  }>()
)

export const AddCourseError = createAction(
  '[Provider Course List] - Add Course Error',
  props<{
    message: string
  }>()
)

export const AddCourses = createAction(
  '[Provider Course List] - Add Courses',
  props<{
    commands: AddCourseCommand[]
  }>()
)

export const AddCoursesSuccess = createAction(
  '[Provider Course List] - Add Courses Success',
  props<{
    providerCourse: ProviderCourseListItem[]
  }>()
)

export const UpdateCourse = createAction(
  '[Provider Course List] - Update Course',
  props<{
    command: UpdateCourseCommand
  }>()
)

export const LoadCourseError = createAction(
  '[Provider Course Resolver] - Load Course Error',
  props<{
    message: string
  }>()
);

export const LoadSorts = createAction(
  '[Provider Courses List] - Load Sorts',
  props<{
      sorts: Sort[];
  }>()
)

export const ChangeSort = createAction(
  '[Provider Courses List] - Change Sort',
  props<{
      currentSort: Sort
  }>()
)

export const LoadOrders = createAction(
  '[Provider Courses List] - Load Orders',
  props<{
      orders: Order[];
  }>()
)

export const ChangeOrder = createAction(
  '[Provider Courses List] - Change Order',
  props<{
      currentOrder: Order
  }>()
)

export const ChangeSearchText = createAction(
  '[Provider Courses List] - Change Search Text',
  props<{
      searchText: string
  }>()
)

export const MasterToggle = createAction(
  '[Provider Course List] - Master Toggle'
)

export const MasterToggled = createAction(
  '[Provider Course List] - MasterToggled',
  props<{
    courses: ProviderCourseListItemForView[]
  }>()
)

export const ToggleCourse = createAction(
  '[Provider Course List] - Toggle Course',
  props<{
    id: string
  }>()
)

export const RemoveFilter = createAction(
  '[Provider Course List] - Remove Filter',
  props<{
    filter: Filter
  }>()
)

export const ToggleStatusFilter = createAction(
  '[Provider Courses List] - Toggle Status Filter',
  props<{
      id: string
  }>()
)
export const ToggleProviderFilter = createAction(
  '[Provider Course List] - Toggle Provider Filter',
  props<{
      id: string
  }>()
)

export const ToggleCourseLevelFilter = createAction(
  '[Provider Course List] - Toggle Course Level Filter',
  props<{
      id: string
  }>()
)


export const ToggleCategoryFilter = createAction(
  '[Provider Course List] - Toggle Category Filter',
  props<{
      id: string
  }>()
)


export const ToggleSubCategoryFilter = createAction(
  '[Provider Course List] - Toggle Sub Category Filter',
  props<{
      id: string
  }>()
)

export const ToggleTagFilter = createAction(
  '[Provider Course List] - Toggle Tag Filter',
  props<{
      id: string
  }>()
)

export const ChangePresetFilterType = createAction(
  '[Provider Course list] - Change Preset Filter Type',
  props<{
    presetFilterType: PresetFilterType
  }>()
)

export const LoadCourseLevelFilters = createAction(
  '[Provider Summary Resolver] - Load Course Level Filters',
  props<{
    levelFilters: Filter[]
  }>()
)

export const LoadCourseCategoryFilters = createAction(
  '[Provider Summary Resolver] - Load Course Category Filters',
  props<{
    courseCategoryFilters: Filter[]
  }>()
)

export const LoadCourseSubCategoryFilters = createAction(
  '[Provider Summary Resolver] - Load Course Sub Category Filters',
  props<{
    courseSubCategoryFilters: Filter[]
  }>()
)

export const LoadTagFilters = createAction(
  '[Provider Summary Resolver] - Load Course Tag Filters',
  props<{
    tagFilters: Filter[]
  }>()
)

export const LoadProviderFilters = createAction(
  '[Provider Summary Resolver] - Load Provider Filters',
  props<{
    providerFilters: Filter[]
  }>()
)

export const LoadStatusFilters = createAction(
  '[Provider Summary Resolver] - Load Course Status Filters',
  props<{
    statusFilters: Filter[]
  }>()
)

export const ExportToExcel = createAction(
  '[Provider Course list] - Export to Excel',
  props<{
    courses?: ProviderCourseListItem[]
  }>()
)

export const ReadMore = createAction(
  '[Provider Course Detail] - Read More'
)

export const ImportCourses = createAction(
  '[Provider Course List] - Import Courses',
  props<{
    file: File
  }>()
)

export const DownloadBulkImport = createAction(
  '[Provider Course list] - Download Bulk Import file',
  props<{
    institutionId?: string;
    providerId?: string;
  }>()
)

export const DownloadCourseSyllabus = createAction(
  '[Courses] - Download Provider Course Syllabus',
  props<{
    providerId?: string;
    courseId?: string;
    syllabus?: ElbertFile;
  }>()
)

export const ActivateCourse = createAction(
  '[Courses] - Activate Course',
  props<{
    providerCourseId: string;
  }>()
)

export const DeactivateCourse = createAction(
  '[Courses] - Deactivate Course',
  props<{
    providerCourse: ProviderCourseListItem;
  }>()
)

export const UpdateCourseSyllabus = createAction(
  '[Courses] - Update Course Syllabus',
  props<{
    command: UpdateSyllabusCommand
  }>()
)

export const UpdateCourseSyllabusSuccessful = createAction(
  '[Courses] - Update Course Syllabus Successful',
  props<{
    courseId: string,
    elbertFile: ElbertFile
  }>()
)