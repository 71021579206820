import {createAction, props} from '@ngrx/store';
import {ChecklistItemType} from '../../../../core/models/checklistItemType.enum';
import { IWizardChecklistForView } from '@shared/wizard/models/wizardChecklistForView';

export const changeCurrentStepId = createAction(
  '[Wizard] changeCurrentStepId',
  props<{
    stepId: string
  }>()
);

export const skipStep = createAction(
  '[Wizard] skipStep',
  props<{
    skipTo: ChecklistItemType
  }>()
);

export const loadChecklist = createAction(
  '[Wizard] - LoadChecklist',
  props<{
  checklist: IWizardChecklistForView}>
  ()
)

export const loadChecklistOrder = createAction(
  '[Wizard] - LoadChecklist Order',
  props<{
    checklistOrder: string[]
  }>()
)

export const goForward = createAction(
  '[Wizard]  - Go Forward',
  props<{
    currentStepId: string;
  }>()
)

export const goBack = createAction(
  '[Wizard] - Go Back',
  props<{
    currentStepId: string;
  }>()
)