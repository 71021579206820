import { EnrollmentListItem, EnrollmentListItemForView } from "@member/enrollment";
import { StudentListItem } from "@shared/student/models";

export default (studentToConvert:StudentListItem): StudentListItem => {
    
        return {
          ...studentToConvert,
          dateOfBirth: new Date(studentToConvert.dateOfBirth2.year, studentToConvert.dateOfBirth2.month-1, studentToConvert.dateOfBirth2.day)
        }
  }
  