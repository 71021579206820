import { props, createAction } from '@ngrx/store';
import { ProviderCourseListItem } from '@provider/courses/models/providerCourseListItem.model';

export const CourseList = createAction(
    '[Navigate] - Provider Course List',
    props<{
      providerId?: string;
      institutionId? : string;
    }>()
)

export const CourseDetail = createAction(
    '[Navigate] - Provider Course Detail',
    props<{
        providerId?: string;
        institutionId?: string;
        providerCourse: ProviderCourseListItem;
    }>()
)

export const CourseEdit = createAction(
    '[Navigate] - Provider Course Edit',
    props<{
        providerId?: string;
        institutionId?: string;
        providerCourse: ProviderCourseListItem;
    }>()
)

export const CourseAdd = createAction(
    '[Navigate] - Provider Course Add',
    props<{
        providerId?: string;
        institutionId?: string;
    }>()
)

export const CreateCourseSchedule = createAction(
    '[Navigate] - Create Course Schedule'
)