import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { Store, select } from "@ngrx/store";
import { tap, first, finalize, filter, map, concatMap, withLatestFrom, switchMap } from "rxjs/operators";
import { IAppState } from 'app/store/state/app.state';
import * as fromCourseExchange from '@courseExchange/store';
import * as fromCourseExchangeCourses from '@courseExchange/courses/store';
import { ProviderCourseListItem } from '@provider/courses/models/providerCourseListItem.model';

@Injectable()
export class CourseExchangeCoursesResolver implements Resolve<ProviderCourseListItem[]>{
    isLoading = false;
    constructor(private store: Store<IAppState>){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
        const courseExchangeId = route.params["courseExchangeId"];
        return this.store 
            .pipe(
                select(fromCourseExchangeCourses.Selectors.CourseExchangeId),
                concatMap(currentCourseExchangeId => of(currentCourseExchangeId).pipe(
                    withLatestFrom(this.store.pipe(select(fromCourseExchangeCourses.Selectors.Courses)))
                )),
                map(([currentCourseExchangeId, courses]) => courseExchangeId === currentCourseExchangeId && courses.length > 0),
                map((areCoursesLoaded) => {
                    if(!this.isLoading && !areCoursesLoaded){
                        this.isLoading = true;
                        this.store.dispatch(fromCourseExchangeCourses.Actions.LoadCourses({courseExchangeId}));
                    }
                    return true;
                }),
                filter(coursesLoaded => coursesLoaded),
                first(),
                finalize(()=>this.isLoading=false)
            )
    }
}