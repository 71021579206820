<div class="course-schedule-dialog-new">
  <form class="form" [formGroup]="form">
    <div class="field">
      <mat-form-field appearance="outline" fxFill>
        <mat-label>Select Academic Year</mat-label>
        <mat-select (selectionChange)="onChangeSelectedAcademicYear($event)" formControlName="academicYearId">
          <mat-option *ngFor="let academicYear of academicYears"
                      [value]="academicYear.id">
            {{academicYear.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="field">
      <mat-form-field appearance="outline" fxFill>
        <mat-label>Select Semester</mat-label>
        <mat-select
          (selectionChange)="onChangeSelectedSemester($event)"
          formControlName="semesterId"
          [disabled]="!selectedAcademicYear || !selectedAcademicYear.semesters.length">
          <ng-container *ngIf="selectedAcademicYear">
            <mat-option *ngFor="let semester of selectedAcademicYear.semesters"
                        [value]="semester.id">
              {{semester.code}}
            </mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
    </div>

    <div class="submit">
      <button mat-raised-button
              [disabled]="!canSubmit"
              (click)="onSubmit()">
        <mat-icon>arrow_forward</mat-icon>
        Next
      </button>
    </div>
  </form>
</div>
