import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fromComponents from './components';
import { MaterialModule } from './material/material.module';
import { StoreModule } from '@ngrx/store';
import { refDataReducer } from './referenceData/store/reducers/refData.reducer';
import { RefDataEffects } from './referenceData/store/refData.effects';
import { EffectsModule } from '@ngrx/effects';
import * as fromStore from './referenceData/store';
import { refDataFeatureKey } from './referenceData/store/featureKey';
import { CoreEffects } from './store/core.effects';
@NgModule({
    declarations: [...fromComponents.COMPONENTS],
    imports: [
        CommonModule,
        MaterialModule,
        StoreModule.forFeature(refDataFeatureKey, refDataReducer),
        EffectsModule.forFeature([RefDataEffects, CoreEffects])
    ],
    exports: [...fromComponents.COMPONENTS],
    providers: [...fromStore.RESOLVERS]
})
export class CoreModule { }
