<div class="wizard-step wizard-calendar-step-add-year">
  <ng-container>
    <div class="title">
      Add Calendar: Academic Year
    </div>

    <div class="description">Please add your first academic year.</div>

    <form class="form" [formGroup]="form" (keydown)="onKeyDownForm($event)">
      <div class="fields">
        <div class="field">
          <mat-form-field appearance="outline" class="mt-8" fxFill>
            <mat-label>Name</mat-label>
            <input
              matInput
              name="name"
              required
              formControlName="name">
            <mat-error *ngIf="hasError('name', 'required')">This field is required</mat-error>
            <mat-error *ngIf="hasError('name', 'maxlength')">Name can't be more than 30 characters</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="fields">
        <div class="field">
          <mat-form-field appearance="outline" class="mr-sm-12" fxFlex>
            <mat-label>Start Date</mat-label>
            <input matInput [matDatepicker]="startDatePicker" formControlName="startDate">
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field appearance="outline" class="ml-sm-12" fxFlex>
            <mat-label>End Date</mat-label>
            <input matInput [matDatepicker]="endDatePicker" formControlName="endDate" placeholder="End Date">
            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker></mat-datepicker>
            <mat-error *ngIf="hasError('endDate', 'lessStartDate')">Is less or equal than the start date</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="form-actions-continue">
        <span class="skip" (click)="skip()">skip this step</span>
      </div>

      <div class="form-actions">
        <button mat-stroked-button
                (click)="goBack()">
          Back
        </button>

        <button mat-flat-button
                (click)="onSubmit()"
                [disabled]="isSending"
                type="submit">
          Next
        </button>
      </div>
    </form>
  </ng-container>

  <ng-template #loading>
    ...loading
  </ng-template>
</div>
