import { Injectable } from '@angular/core';
import { PercentPipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class PercentFormatter {

  constructor(private pp: PercentPipe) { }

  format(percnt: number): string {
    return this.pp.transform(percnt, '2.0-2');
  }
}
