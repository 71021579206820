import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

export interface ICourseDescriptionDialogData {
    description: string,
    title: string
}

@Component({
  selector: 'course-description-dialog',
  templateUrl: './course-description-dialog.component.html',
  styleUrls: ['./course-description-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CourseDescriptionDialogComponent implements OnInit {
  constructor(
    public matDialogRef: MatDialogRef<CourseDescriptionDialogComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public matDialogData: ICourseDescriptionDialogData,
  ) {
  }

  ngOnInit(): void {
    this.matDialogRef.addPanelClass([
      'course-description-dialog'
    ]);
  }

  close(): void {
    this.matDialogRef.close();
  }
}
