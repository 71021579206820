import { UploadInstitutionLogoCommand } from '@admin/institution/models/commands/UploadInstitutionLogoCommand';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseResponse, SingleValueResult } from '@core/models';
import { Observable } from 'rxjs';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class ImageStorageService {
  private apiUrl: string;
  constructor(private http: HttpClient, private config:ConfigService) {
    this.config.apiUrl$.subscribe(result=>this.apiUrl=`${result}/institution`);
  }

  // saveImage(image: File, institutionId: string): Observable<BaseResponse> {
  //   const formData = new FormData();
  //   formData.append('logo', image, image.name);
  //   formData.append('institutionId', institutionId);
  //   return this.http.post<BaseResponse>(`${this.apiUrl}/${institutionId}/uploadLogo`, formData);
  // }

  saveInstitutionLogo(command: UploadInstitutionLogoCommand): Observable<BaseResponse> {
    const {image, institutionId, UpdateInstitutionCommand} = command;
    const formData = new FormData();
    formData.append('logo', image, image.name);
    formData.append('institutionId', institutionId);
    return this.http.post<BaseResponse>(`${this.apiUrl}/${institutionId}/uploadLogo`, formData);
  }
}
