import { createSelector, createFeatureSelector } from '@ngrx/store'
import { EnrollmentsState } from '../reducers/enrollment.reducer';
import * as fromProvider from '@provider/store';
import * as fromRefData from '@refData';
import * as fromInstitution from '@institution/store';
import { searchInText } from 'app/helpers/search-in-text';
import { EnrollmentListItemForView } from '@member/enrollment';
import { sortBy } from 'sort-by-typescript';
import { EnrollmentStatus, EnrollmentStatusType } from '@refData';
import { PermissionType } from '@auth/models/permissionType.enum';

export const selectEnrollmentsState = createFeatureSelector<EnrollmentsState>('provider/enrollments');

export const Enrollments = createSelector(
    selectEnrollmentsState,
    (state) => state.enrollments
)
export const EnrollmentsForExport = createSelector(
    Enrollments,
    fromProvider.Selectors.HasProviderPermission(PermissionType.ReadFinalGrade),
    (enrollments, canViewGrades) => {
        return {
            enrollments,
            canViewGrades
        }
    }
)



export const SelectorForEnrollments = createSelector(
    fromProvider.Selectors.ProviderId,
    selectEnrollmentsState,
    (currentProviderId, state) => {
        const currentAcademicYearId = state.currentAcademicYearId;
        const enrollmentCount = state.enrollments.length;
        return {currentProviderId,currentAcademicYearId,enrollmentCount}
    }
)

export const CurrentStudent = createSelector(
    selectEnrollmentsState,
    (state) => state.currentStudent
)
export const isCurrentStudentLoading = createSelector(
    selectEnrollmentsState,
    (state)=> state.isCurrentStudentLoading
)

export const SemesterFilters = createSelector(
    selectEnrollmentsState,
    Enrollments,
    ({semesterFilters}, enrollments) => {
        const result = semesterFilters.filter(x=>enrollments.find(e=>e.semesterId==x.id)).map(semesterFilter=>{
            const semesterCount = enrollments.filter(x=>x.semesterId==semesterFilter.id).length;
            return{
                ...semesterFilter,
                descriptionForView : `${semesterFilter.description} (${semesterCount})`,
                sessionFilters: semesterFilter.sessionFilters.filter(x=>enrollments.find(e=>e.sessionId==x.id)).map(sessionFilter=>{
                    const sessionCount = enrollments.filter(x=>x.sessionId==sessionFilter.id).length;
                    return{
                        ...sessionFilter,
                        descriptionForView: `${sessionFilter.description} (${sessionCount})`
                    }
                })
            }
        });

        return result;
    }
)

export const StudentEnrollments = createSelector(
    selectEnrollmentsState,
    (state) => {
      if(!state.currentStudent) return new Array<EnrollmentListItemForView>();
      
      const enrollments = state.enrollments.filter(enrollment =>enrollment.studentId==state.currentStudent.id);
      return enrollments
    }
  )

export const CurrentStudentDetails = createSelector(
    selectEnrollmentsState,
    (state) => state.currentStudentDetails
)
export const isCurrentStudentDetailsLoading = createSelector(
    selectEnrollmentsState,
    (state)=> state.isCurrentStudentDetailsLoading
)


export const RequestedChanges = createSelector(
    Enrollments,
    (enrollments) => enrollments.filter(x=>x.requestedStatus != null)
)

export const RequestedLetterGrades = createSelector(
    Enrollments,
    (enrollments) => enrollments.filter(x=>x.requestedLetterGrade != null)
)

export const RequestedChangesForEffects = createSelector(
    RequestedChanges,
    fromProvider.Selectors.ProviderId,
    fromRefData.enrollmentStatuses,
    (requestedChanges, providerId, enrollmentStatuses) => {
        return {
            providerId,
            command: {
                commands: requestedChanges.map(x=>{
                return {
                    enrollmentId: x.enrollmentId,
                    requestedStatus: x.requestedStatus,
                    fromMember: false,
                    fromProvider: true
                }
            })},
            enrollmentStatuses
        }
    }
)

export const RequestedLetterGradesForEffects = createSelector(
    RequestedLetterGrades,
    fromProvider.Selectors.ProviderId,
    fromRefData.enrollmentStatuses,
    (requestedLetterGrades, providerId, enrollmentStatuses) => {
        return {
            providerId,
            command: {
                commands: requestedLetterGrades.map(x=>{
                return {
                    enrollmentId: x.enrollmentId,
                    requestedLetterGrade: x.requestedLetterGrade
                }
            })},
            enrollmentStatuses
        }
    }
)

export const CanSubmitChanges = createSelector(
    RequestedChanges,
    (requestedChanges) => {
      return requestedChanges.length>0;
    }
)

export const CanSubmitGrading = createSelector(
    RequestedLetterGrades,
    (requestedLetterGrades) => requestedLetterGrades.length>0
)

export const Orders = createSelector(
    selectEnrollmentsState,
    (state) => state.orders
)
export const CurrentOrder = createSelector(
    selectEnrollmentsState,
    (state) => state.currentOrder
)
export const Sorts = createSelector(
    selectEnrollmentsState,
    (state) => state.sorts
)
export const CurrentSort = createSelector(
    selectEnrollmentsState,
    (state) => state.currentSort
)

export const EnrollmentListMode = createSelector(
    selectEnrollmentsState,
    (state) => state.enrollmentListMode
)

export const SearchText = createSelector(
    selectEnrollmentsState,
    (state) => state.searchText
)
export const StatusFilters = createSelector(
    selectEnrollmentsState,
    ({enrollments, statusFilters})=>{
        return statusFilters.filter(x=>enrollments.find(e=>e.status.id==x.id)).sort(sortBy('description')).map(statusFilter => {
            const count = !!enrollments ? enrollments.filter(enrollment=> enrollment.status.id===statusFilter.id).length : 0;
            return {
              ...statusFilter,
              descriptionForView: `${statusFilter.description} (${count})`
            }
          })
    }
)
export const SelectedStatusFilters = createSelector(
    StatusFilters,
    (filters) => !!filters ? filters.filter(x=>x.selected): []
)
export const SelectedSemesterFilters = createSelector(
    SemesterFilters,
    (filters) => !!filters ? filters.filter(x=>x.selected): []
)

export const SelectedSessionFilters = createSelector(
    SelectedSemesterFilters,
    (filters) => {
        if(!!filters){
            let sessionFilters = [];
            filters.forEach(filter=>{
                const selectedFilters = filter.sessionFilters.filter(x=>x.selected);
                sessionFilters.push(...selectedFilters);
            });
            return sessionFilters;
        }else{
            return [];
        }
    }
)
export const MemberFilters = createSelector(
    selectEnrollmentsState,
    (state) => state.memberFilters
  )
  export const SelectedMemberFilters = createSelector(
    MemberFilters,
    (filters) => filters.filter(x=>x.selected)
  )
  
  export const MemberFilterCount = createSelector(
    SelectedMemberFilters,
    (filters) => filters.length > 0 ? filters.length : null
  )
  export const MemberFiltersForView = createSelector(
    Enrollments,
    MemberFilters,
    (enrollments, members) =>{
      return members.filter(m=>enrollments.find(x=>x.memberId==m.id)).sort(sortBy('description')).map(filter => {
        const count = !!enrollments ? enrollments.filter(x=>x.memberId===filter.id).length : 0;
        return {
          ...filter,
          descriptionForView: `${filter.description} (${count})`
        }
      })
    }
  )
  export const TagFilters = createSelector(
    selectEnrollmentsState,
    (state) => state.tagFilters
  )
  export const SelectedTagFilters = createSelector(
    TagFilters,
    (filters) => filters.filter(x=>x.selected)
  )
  export const TagFilterCount = createSelector(
    SelectedTagFilters,
    (filters) => filters.length > 0 ? filters.length : null
  )
  export const TagFiltersForView = createSelector(
    Enrollments,
    TagFilters,
    (enrollments, enrollmentTags) =>{
      return enrollmentTags.filter(x=>enrollments.find(y=>!!y.tags && y.tags.find(t=>t.id===x.id))).sort(sortBy('description')).map(filter => {
        const count = !!enrollments ? enrollments.filter(x=>!!x.tags && x.tags.find(tag=>tag.id===filter.id)!=undefined).length : 0;
        return {
          ...filter,
          descriptionForView: `${filter.description} (${count})`
        }
      })
    }
  )
  export const CategoryFilters = createSelector(
    selectEnrollmentsState,
    (state) => state.courseCategoryFilters
  )
  
  export const SelectedCategoryFilters = createSelector(
    CategoryFilters,
    (filters) => filters.filter(x=>x.selected)
  )
  
  export const CategoryFilterCount = createSelector(
    SelectedCategoryFilters,
    (filters) => filters.length > 0 ? filters.length : null
  )
  
  export const CategoryFiltersForView = createSelector(
    Enrollments,
    CategoryFilters,
    (enrollments, enrollmentsCategories) =>{
      
      return enrollmentsCategories.filter(x=>enrollments.find(e=>!!e.category && e.category.id==x.id)).sort(sortBy('description')).map(filter => {
        const count = !!enrollments ? enrollments.filter(x=>!!x.category && x.category.id===filter.id).length : 0;
        return {
          ...filter,
          descriptionForView: `${filter.description} (${count})`
        }
      })
    }
  )
  export const SubCategoryFilters = createSelector(
    selectEnrollmentsState,
    (state) => state.courseSubCategoryFilters
  )
  
  export const SelectedSubCategoryFilters = createSelector(
    SubCategoryFilters,
    (filters) => filters.filter(x=>x.selected)
  )
  
  export const SubCategoryFilterCount = createSelector(
    SelectedSubCategoryFilters,
    (filters) => filters.length > 0 ? filters.length : null
  )
  
  export const SubCategoryFiltersForView = createSelector(
    Enrollments,
    SubCategoryFilters,
    (enrollments, enrollmentsSubCategories) =>{
      
      return enrollmentsSubCategories.filter(x=>enrollments.find(e=>!!e.subCategory && e.subCategory.id==x.id)).sort(sortBy('description')).map(filter => {
        const count = !!enrollments ? enrollments.filter(x=>!!x.subCategory && x.subCategory.id===filter.id).length : 0;
        return {
          ...filter,
          descriptionForView: `${filter.description} (${count})`
        }
      })
    }
  )

export const SelectedFilters = createSelector(
    SelectedStatusFilters,
    SelectedSessionFilters,
    SelectedMemberFilters,
    SelectedTagFilters,
    SelectedCategoryFilters,
    SelectedSubCategoryFilters,
    (statusFilters,sessionFilters,memberFilters,tagFilters,categoryFilters,subCategoryFilters) => 
        [...statusFilters,...sessionFilters, ...memberFilters,...tagFilters,...categoryFilters,...subCategoryFilters ]
)
export const SelectedFilterList = createSelector(
    SelectedStatusFilters,
    SelectedSessionFilters,
    SelectedMemberFilters,
    SelectedTagFilters,
    SelectedCategoryFilters,
    SelectedSubCategoryFilters,
    (statusFilters,sessionFilters, memberFilters, tagFilters, categoryFilters, subCategoryFilters) => {
        return {
            statusFilters,
            sessionFilters,
            memberFilters,
            tagFilters,
            categoryFilters,
            subCategoryFilters
        }
    }
)

export const FilteredEnrollments = createSelector(
    Enrollments,
    CurrentOrder,
    CurrentSort,
    SearchText,
    SelectedFilterList,
    EnrollmentListMode,
    (enrollments, currentOrder, currentSort, searchText, selectedFilters, listMode) => {
        let result = [...enrollments];
        
        const {statusFilters,sessionFilters, memberFilters, tagFilters, categoryFilters, subCategoryFilters} = selectedFilters;        

        if(listMode==="Enrollment"){
            if (statusFilters.length) {
                result = result.filter(enrollment => {
                  const index = statusFilters.findIndex(item => enrollment.status.id===item.id);
                  return (index !== -1);
                })
            }
        }else if(listMode==="Grading"){
            result = result.filter(enrollment => enrollment.status.statusType === EnrollmentStatusType.Registered ||
                                                enrollment.status.statusType === EnrollmentStatusType.Graded ||
                                                enrollment.status.statusType===EnrollmentStatusType.Incomplete);
        }

        if(sessionFilters.length){
            result = result.filter(enrollment => {
                const index = sessionFilters.findIndex(item => enrollment.sessionId ===item.id);
                return (index !== -1);
            })
        }
        
        if (memberFilters.length) {
            result = result.filter(enrollment => {
              const index = memberFilters.findIndex(item => item.id===enrollment.memberId);
              return (index !== -1);
            })
        }
          
        if (tagFilters.length) {
            result = result.filter(enrollment => {
              const index = tagFilters.findIndex(item =>!!enrollment.tags && enrollment.tags.find(tag=>tag.id === item.id) != undefined);
              return (index !== -1);
            })
          }
        if (categoryFilters.length) {
          result = result.filter(enrollment => {
            const index = categoryFilters.findIndex(item =>!!enrollment.category && item.id===enrollment.category.id);
            return (index !== -1);
          })
        }

        if (subCategoryFilters.length) {
          result = result.filter(enrollment => {
            const index = subCategoryFilters.findIndex(item =>!!enrollment.subCategory && item.id===enrollment.subCategory.id);
            return (index !== -1);
          })
        }
        result = searchInText<EnrollmentListItemForView>(searchText, result, [
            'studentFullName.firstName',
            'studentFullName.middleName',
            'studentFullName.lastName',
            'studentNumber',
            'studentEmailAddress',
            'studentPhoneNumber',
            'memberName',
            'memberCourseCode',
            'providerName',
            'providerCourseCode',
            'courseTitle',
            'sessionCode',
            'status.description'
          ]);

        const sort = currentSort.value;
        const order = currentOrder.value;

        result = result.sort(sortBy(`${order}${sort}`));

        return result;
    }
)

export const SelectedEnrollments = createSelector(
  FilteredEnrollments,
  (enrollments) => !!enrollments ? enrollments.filter(x=>x.selected): []
)

export const CanExportToExcel = createSelector(
  SelectedEnrollments,
  (selected) => selected.length > 0
)

export const SelectedEnrollmentsForGrading = createSelector(
  SelectedEnrollments,
  (enrollments) => {
      var result = enrollments.filter(enrollment => enrollment.status.statusType === EnrollmentStatusType.Registered ||
          enrollment.status.statusType === EnrollmentStatusType.Graded ||
          enrollment.status.statusType===EnrollmentStatusType.Incomplete);
      return result;
  }
)

export const CommonAllowedStatuses = createSelector(
  SelectedEnrollments,
  fromRefData.enrollmentStatuses,
  (enrollments,statuses) => {
      let result = new Array<EnrollmentStatus>();
      statuses.forEach(status =>{
          if(enrollments.every(e=>e.status.providerAllowedStatuses.find(x=>x.id===status.id)!=undefined)){
              result.push(status);
          }
      });
      return result;
  }
)



export const SelectedStatus = createSelector(
    FilteredEnrollments,
    (filtered) => `${filtered.filter(x=>x.selected).length} of ${filtered.length}`
)

export const IsAllSelected = createSelector(
    FilteredEnrollments,
    (filtered) => filtered.length==0 ? false : filtered.every(x=>x.selected)
);

export const IndeterminateSelected = createSelector(
    IsAllSelected,
    FilteredEnrollments,
    (isAllSelected, students) => isAllSelected ? false : students.some(x=>x.selected)
)

export const EnrollmentsForResolver = createSelector(
    selectEnrollmentsState,
    fromInstitution.Selectors.CurrentAcademicYear,
    ({providerId, enrollments}, currentAcademicYear) => {
        return {
            providerId,
            currentAcademicYear,
            enrollments     
        }
    }
)
export const IsEnrollmentsLoading = createSelector(
    selectEnrollmentsState,
    (state) => state.isEnrollmentsLoading
)
