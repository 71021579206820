<div class="new-enrollment-from-students new-enrollment-from-common">
  <div class="head-content">
    <new-enrollment-list-of-selected
      (remove)="onRemove($event)"
      [tableScheme]="tableScheme"
      [list]="students$ | async"></new-enrollment-list-of-selected>
  </div>

  <div class="body-content">
    <div class="list-content">
      <div class="sidebar">
        <mat-form-field class="dateRange">
          <mat-label>Date Range</mat-label>
          <mat-date-range-input [rangePicker]="picker">
            <input matStartDate [value]="startDate$ |async" placeholder="Start date" (dateChange)="changeStartDate($event)" #startDate>
            <input matEndDate [value]="endDate$ | async" placeholder="End date" (dateChange)="changeEndDate($event)" #endDate>
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
        <div class="section">
          <div class="section-title">Course List:</div>
  
          <div class="radios">
            <mat-radio-group aria-label="Select an option" (change)="onChangePresetFilter($event)">
              <mat-radio-button *ngFor="let type of presetFilterTypes; trackBy: trackById"
                                [checked]="type.id === (presetFilterType$ | async)?.id" [value]="type">
                {{type.description}}
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
        <mat-accordion>
          <mat-expansion-panel *ngIf="(levelFilters$ | async)?.length>0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3 [matBadge]="levelFilterCount$ | async" matBadgeOverlap="false">Course Level</h3>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="side-panel-list">
              <mat-selection-list (selectionChange)="onToggleCourseLevelFilter($event)">
                <mat-list-option *ngFor="let filter of levelFilters$ | async; trackBy: trackById"
                                 [value]="filter.id" [selected]="filter.selected">
                  {{filter.descriptionForView}}
                </mat-list-option>
              </mat-selection-list>
            </div>
          </mat-expansion-panel>
  
          <mat-expansion-panel *ngIf="(providerFilters$ | async)?.length>0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3 [matBadge]="providerFilterCount$ | async" matBadgeOverlap="false">Provider</h3>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="side-panel-list">
              <mat-selection-list (selectionChange)="onToggleProviderFilter($event)">
                <mat-list-option *ngFor="let filter of providerFilters$ | async; trackBy: trackById"
                                 [value]="filter.id" [selected]="filter.selected">
                  {{filter.descriptionForView}}
                </mat-list-option>
              </mat-selection-list>
            </div>
          </mat-expansion-panel>
  
          <mat-expansion-panel *ngIf="(categoryFilters$ | async)?.length>0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3 [matBadge]="categoryFilterCount$ | async" matBadgeOverlap="false">Category</h3>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="side-panel-list">
              <mat-selection-list (selectionChange)="onToggleCategoryFilter($event)">
                <mat-list-option *ngFor="let filter of categoryFilters$ | async; trackBy: trackById"
                                 [value]="filter.id" [selected]="filter.selected">
                  {{filter.descriptionForView}}
                </mat-list-option>
              </mat-selection-list>
            </div>
          </mat-expansion-panel>
  
          <mat-expansion-panel *ngIf="(subCategoryFilters$ | async)?.length>0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3 [matBadge]="subCategoryFilterCount$ | async" matBadgeOverlap="false">Sub Category</h3>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="side-panel-list">
              <mat-selection-list (selectionChange)="onToggleSubCategoryFilter($event)">
                <mat-list-option *ngFor="let filter of subCategoryFilters$ | async; trackBy: trackById"
                                 [value]="filter.id" [selected]="filter.selected">
                  {{filter.descriptionForView}}
                </mat-list-option>
              </mat-selection-list>
            </div>
          </mat-expansion-panel>
  
          <mat-expansion-panel *ngIf="(tagFilters$ | async)?.length>0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3 [matBadge]="tagFilterCount$ | async" matBadgeOverlap="false">Tag</h3>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="side-panel-list">
              <mat-selection-list (selectionChange)="onToggleTagFilter($event)">
                <mat-list-option *ngFor="let filter of tagFilters$ | async; trackBy: trackById"
                                 [value]="filter.id" [selected]="filter.isSelected">
                  {{filter.descriptionForView}}
                </mat-list-option>
              </mat-selection-list>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="main">
        <div class="top-panel">
          <div class="search-wrapper">
            <div class="search">
              <mat-icon>search</mat-icon>
              <input #filter placeholder="Search Courses">
            </div>
          </div>
          <div class="sorts">
            <div class="field">
              <mat-form-field class="full-list-sort">
                <mat-select placeholder="Sort By" [value]="currentSort$ | async"
                            (selectionChange)="changeSort($event.value)">
                  <mat-select-trigger>
                    <mat-icon>{{(currentSort$ | async)?.icon}}</mat-icon>&nbsp;{{(currentSort$ | async)?.name}}
                  </mat-select-trigger>
                  <mat-option *ngFor="let sort of sorts$ | async" [value]="sort">
                    <mat-icon>{{sort.icon}}</mat-icon>{{sort.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>

            <div class="field">
              <mat-form-field class="full-list-sort">
                <mat-select placeholder="Order By" [value]="currentOrder$ | async"
                            (selectionChange)="changeOrderBy($event.value)">
                  <mat-select-trigger>
                    <mat-icon>{{(currentOrder$ | async)?.icon}}</mat-icon>&nbsp;{{(currentOrder$ | async)?.name}}
                  </mat-select-trigger>
                  <mat-option *ngFor="let order of orders" [value]="order">
                    <mat-icon>{{order.icon}}</mat-icon>{{order.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="list">
          <mat-list>
            <member-course-item
              *ngFor="let memberCourse of memberCourses$ | async; trackBy: trackById"
              [course]="memberCourse"
              (toggleMemberCourse)="onToggleMemberCourse($event)"
              (goToCourseDetail)="goToMemberCourseDetail($event)"
              (exportCourseToExcel)="exportSelectedToExcel()"
            ></member-course-item>
          </mat-list>
        </div>
      </div>
    </div>
  </div>

  <div class="footer-content">
    <div class="actions">
      <button mat-raised-button color="warn" (click)="close.emit()">
        <mat-icon>close</mat-icon>
        Cancel, Exit
      </button>

      <button mat-raised-button [disabled]="!(canCreateEnrollment$ | async)" (click)="createEnrollments()">
        <mat-icon>person_add</mat-icon>
        Request Enrollment
      </button>
    </div>
  </div>




</div>
