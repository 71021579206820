import {
  createReducer,
  on
} from '@ngrx/store';
import * as fromAuth from '@auth/store';
import * as fromStudent from '@member/students/store';
import * as fromMemberEnrollment from '@member/enrollment/store';
import {StudentListItem, Student} from '@shared/student/models';
import {Sort, Order, Address} from 'app/core/models';
import {Filter, FilterType} from '@shared/common/models';
import DefaultStatusList from '@shared/common/helpers/DefaultStatusList';
import toggleItem from '@shared/common/helpers/toggleItem';
import {StudentDetailsViewModel} from '@shared/student/models/studentDetails.viewModel';
import { state } from '@angular/animations';
import convertStudentDates from '@shared/common/helpers/convertStudentDates';
import convertStudentsDates from '@shared/common/helpers/convertStudentsDates';
import convertEnrollmentDates from '@shared/common/helpers/convertEnrollmentDates';
import { EnrollmentListItemForView } from '@member/enrollment';
import { DateParts } from '@core/models/dateParts';
import * as moment from 'moment';

export interface StudentsState {
  institutionId: string;
  students: StudentListItem[];
  isStudentsLoading: boolean;
  currentStudent: Student;
  isCurrentStudentLoading: boolean;
  currentStudentDetails: StudentDetailsViewModel;
  isCurrentStudentDetailsLoading: boolean;
  enrollments: EnrollmentListItemForView[];
  isAddStudentSending: boolean;
  isEditStudentSending: boolean;
  isDeleteStudentSending: boolean;
  sorts: Sort[],
  currentSort: Sort,
  orders: Order[],
  currentOrder: Order,
  statusFilters: Filter[],
  searchText: string;
  isSubmittingChanges: boolean;
};

export const initialStudentsState: StudentsState = {
  institutionId: '',
  students: new Array<StudentListItem>(),
  isStudentsLoading: false,
  currentStudent: undefined,
  isCurrentStudentLoading: false,
  currentStudentDetails: undefined,
  enrollments: new Array<EnrollmentListItemForView>(),
  isCurrentStudentDetailsLoading: false,
  isAddStudentSending: false,
  isEditStudentSending: false,
  isDeleteStudentSending: false,
  sorts: new Array<Sort>(),
  currentSort: new Sort(),
  orders: new Array<Order>(),
  currentOrder: new Order(),
  statusFilters: DefaultStatusList(),
  searchText: '',
  isSubmittingChanges: false
};

export const studentsReducer = createReducer(
  initialStudentsState,
  on(fromAuth.Actions.selectCourseExchangeId, (state, store)=>{
    return initialStudentsState
  }),
  on(fromStudent.Actions.StudentAdded, (state, action) => {
    const student = convertStudentDates(action.student);
    const idx = state.students.findIndex(x=>x.id==student.id);
    let students = state.students;
    if(idx==-1){
      students = [...students, student];
    }else{
      students = [...state.students.slice(0, idx), student, ...state.students.slice(idx + 1)];
    }
    return {
      ...state,
      students
    }
  }),
  on(fromStudent.Actions.EditStudent, (state, {command}) => {
    const students = state.students.map(student=>{
      if(student.id != command.id) return student;
      return {
        ...student,
        firstName: command.firstName,
        middleName: command.middleName,
        lastName: command.lastName,
        studentNumber: command.studentNumber,
        emailAddress: command.emailAddress,
        phoneNumber: command.phoneNumber
      }
    })
    return {
      ...state,
      students,
      isEditStudentSending: true
    };
  }),
  on(fromStudent.Actions.EditStudentSuccess, (state, action) => {
    return {
      ...state,
      isEditStudentSending: false
    };
  }),
  on(fromStudent.Actions.EditStudentError, (state, {oldStudent}) => {
    const idx = state.students.findIndex(x=>x.id==oldStudent.id);
    const {firstName,middleName,lastName} = oldStudent.fullName;
    const emailAddress = oldStudent.emailAddress.value;
    const emailAddress2 = oldStudent.emailAddress2.value;
    const phoneNumber = oldStudent.phoneNumber.value;
    const studentNumber = oldStudent.studentNumber;
    const {addressLine1, addressLine2, postalCode, city} = oldStudent.address;
    const regionId = oldStudent.address.region.id;
    const genderId = oldStudent.genderId;
    const studentTypeId = oldStudent.studentTypeId;
    const dateOfBirth = new Date(oldStudent.dateOfBirth2.year, oldStudent.dateOfBirth2.month-1, oldStudent.dateOfBirth2.day);
    const student = {...state.students[idx], firstName,middleName,lastName,emailAddress,emailAddress2,
                                              phoneNumber,studentNumber,addressLine1,addressLine2,
                                              postalCode,city,regionId, genderId,studentTypeId,dateOfBirth};
    const students = [...state.students.slice(0, idx), student, ...state.students.slice(idx + 1)];
    return {
      ...state,
      students,
      isEditStudentSending: false
    };
  }),
  on(fromStudent.Actions.StudentUpdated, (state, action) => {
    const student = convertStudentDates(action.student);
    const idx = state.students.findIndex(x => x.id === student.id);
    const students = [...state.students.slice(0, idx), student, ...state.students.slice(idx + 1)];
    return {
      ...state,
      students,
      isEditStudentSending: false
    };
  }),
  on(fromStudent.Actions.MasterToggled, (state, action) => {
    const students = state.students.map((student)=>{
      const idx = action.students.findIndex(x=>x.id==student.id);
      if(idx===-1){
        return student;
      }else{
        return action.students[idx];
      }
    })
    return {
      ...state,
      students
    };
  }),
  on(fromStudent.Actions.EditStudentError, (state, action) => {
    return {
      ...state,
      isEditStudentSending: true
    };
  }),
  on(fromStudent.Actions.DeleteStudentSuccess, (state, {id})=>{
    const students = state.students.filter(x=>x.id != id);
    return {
      ...state,
      students
    }
  }),
  on(fromStudent.Actions.ActivateStudentSuccess, (state, {id})=>{
    var student = state.students.find(x=>x.id===id);
    student.isActive=true;
    var idx = state.students.findIndex(x=>x.id===id);
    const students = [...state.students.slice(0,idx),student, ...state.students.slice(idx+1)];
    return {
      ...state,
      students
    }
  }),
  on(fromStudent.Actions.DeactivateStudentSuccess, (state, {id})=>{
    var student = state.students.find(x=>x.id===id);
    student.isActive=false;
    var idx = state.students.findIndex(x=>x.id===id);
    const students = [...state.students.slice(0,idx),student, ...state.students.slice(idx+1)];
    return {
      ...state,
      students
    }
  }),
  on(fromStudent.Actions.LoadStudents, (state, action) => {
    const institutionId = action.institutionId;
    return {
      ...state,
      institutionId,
      isStudentsLoading: true
    };
  }),
  on(fromStudent.Actions.LoadStudentsError, (state, action) => {
    return {
      ...state,
      isStudentsLoading: false
    };
  }),
  on(fromStudent.Actions.LoadStudentsSuccess, (state, action) => {
    const students = convertStudentsDates(action.students);
    return {
      ...state,
      students,
      isStudentsLoading: false
    };
  }),
  on(fromStudent.Navigate.StudentList, (state, action) => {
    const searchText = '';
    return {
      ...state,
      searchText,
      currentStudentDetails: undefined,
      enrollments: new Array<EnrollmentListItemForView>()
    };
  }),
  on(fromStudent.Actions.ClearSelected, (state, action) => {
    const students = state.students.map((student)=>{
      return {...student, selected: false}
    });
    const searchText = '';
    return {
      ...state,
      students,
      searchText
    };
  }),
  on(fromStudent.Actions.LoadSorts, (state, action) => {
    const sorts = action.sorts;
    const currentSort = sorts.find(x => x.isDefault);
    return {
      ...state,
      sorts,
      currentSort
    }
  }),
  on(fromStudent.Actions.ChangeSort, (state, action) => {
    const currentSort = action.currentSort;
    return {
      ...state,
      currentSort
    }
  }),
  on(fromStudent.Actions.LoadOrders, (state, action) => {
    const orders = action.orders;
    const currentOrder = orders.find(x => x.isDefault);
    return {
      ...state,
      orders,
      currentOrder
    }
  }),
  on(fromStudent.Actions.ChangeOrder, (state, action) => {
    const currentOrder = action.currentOrder;
    return {
      ...state,
      currentOrder
    }
  }),
  on(fromStudent.Actions.ToggleStudent, (state, action) => {
    const students = toggleItem(state.students, action.studentId);
    return {
      ...state,
      students
    }
  }),
  on(fromStudent.Actions.RemoveFilter, (state, action) => {
    const idx = state.statusFilters.findIndex(x => x.id == action.filter.id);
    const item = state.statusFilters[idx];
    const statusFilter = {...item, selected: false};
    const statusFilters = [...state.statusFilters.slice(0, idx), statusFilter, ...state.statusFilters.slice(idx + 1)]
    return {
      ...state,
      statusFilters
    }
  }),
  on(fromStudent.Actions.AddFilter, (state, action) => {
    const idx = state.statusFilters.findIndex(x => x.id == action.filter.id);
    const item = state.statusFilters[idx];
    const statusFilter = {...item, selected: true};
    const statusFilters = [...state.statusFilters.slice(0, idx), statusFilter, ...state.statusFilters.slice(idx + 1)]
    return {
      ...state,
      statusFilters
    }
  }),
  on(fromStudent.Actions.ToggleStatusFilter, (state, action) => {
    const statusFilters = toggleItem(state.statusFilters, action.id);

    return {
      ...state,
      statusFilters
    }
  }),
  on(fromStudent.Actions.ChangeSearchText, (state, action) => {
    const searchText = action.searchText;
    return {
      ...state,
      searchText
    };
  }),
  on(fromStudent.Navigate.StudentList, (state, action) => {
    return {
      ...state,
      currentStudent: undefined
    }
  }),
  on(fromStudent.Actions.LoadStudent, (state) => {
    return {
      ...state,
      isCurrentStudentLoading: true
    }
  }),
  on(fromStudent.Actions.LoadStudentSuccess, (state, action) => {
    const currentStudent = {
      ...action.currentStudent,
      dateOfBirth: new Date(action.currentStudent.dateOfBirth2.year, action.currentStudent.dateOfBirth2.month-1, action.currentStudent.dateOfBirth2.day)
    }
    return {
      ...state,
      currentStudent,
      isCurrentStudentLoading: false
    }
  }),
  on(fromStudent.Actions.LoadStudentError, (state) => {
    return {
      ...state,
      isCurrentStudentLoading: false
    }
  }),
  on(fromStudent.Actions.LoadStudentDetails, (state) => {
    return {
      ...state,
      isCurrentStudentDetailsLoading: true
    }
  }),
  on(fromStudent.Actions.LoadStudentDetailsSuccess, (state, action) => {
    const enrollments = action.currentStudentDetails.enrollments.map(x => {
      return {
        ...x,
        selected: false,
        requestedStatus: null,
        letterGradeDescription: !!x.letterGrade ? x.letterGrade.description : ""
      }
    });
    const currentStudentDetails = {
      ...action.currentStudentDetails,
      dateOfBirth : new Date(action.currentStudentDetails.dateOfBirth2.year, action.currentStudentDetails.dateOfBirth2.month-1, action.currentStudentDetails.dateOfBirth2.day)
    }
    return {
      ...state,
      currentStudentDetails,
      enrollments,
      isCurrentStudentDetailsLoading: false
    }
  }),
  on(fromStudent.Actions.LoadStudentDetailsError, (state) => {
    return {
      ...state,
      isCurrentStudentDetailsLoading: false
    }
  }),
  on(fromStudent.Actions.EditStatus, (state, {enrollmentId, requestedStatus}) => {
    const idx = state.enrollments.findIndex(x => x.id === enrollmentId);
    const enrollment = {...state.enrollments[idx], requestedStatus};
    const enrollments = [...state.enrollments.slice(0, idx), enrollment, ...state.enrollments.slice(idx + 1)];
    return {
      ...state,
      enrollments
    };
  }),
  on(fromMemberEnrollment.Actions.EnrollmentListItemUpdated, (state, action) => {
    const item = convertEnrollmentDates(action.enrollment);
    let enrollments = state.enrollments;
    var idx = enrollments.findIndex(x => x.id === item.id);
    if(idx===-1) return {...state, isSubmittingChanges:false};
    const enrollmentForView = {...item, selected: false, requestedStatus: null, letterGradeDescription: !!item.letterGrade ? item.letterGrade.description : ""};
    enrollments = [...enrollments.slice(0, idx), enrollmentForView, ...enrollments.slice(idx + 1)];
    
    const currentStudentDetails = {...state.currentStudentDetails, enrollments};
    return {
      ...state,
      currentStudentDetails,
      enrollments,
      isSubmittingChanges: false
    };
  }),
  on(fromMemberEnrollment.Actions.EnrollmentListItemAdded, (state, {enrollment})=>{
    const item = convertEnrollmentDates(enrollment);
    var idx = state.enrollments.findIndex(x => x.id === item.id);
    const enrollmentForView = {...item, selected: false, requestedStatus: null, letterGradeDescription: !!item.letterGrade ? item.letterGrade.description : ""};
    let enrollments = new Array<EnrollmentListItemForView>();
    if(idx===-1){
      enrollments = [...state.enrollments, enrollmentForView];
    }else{
      enrollments = [...enrollments.slice(0, idx), enrollmentForView, ...enrollments.slice(idx + 1)];
    }
    return {
      ...state,
      enrollments
    }
  }),
  on(fromStudent.Actions.SubmitStatusChangesError, (state, action) => {
    return {
      ...state,
      isSubmittingChanges: false
    }
  }),
  on(fromStudent.Actions.DeleteStudent, (state) => {
    return {
      ...state,
      isDeleteStudentSending: true
    }
  }),
  on(fromStudent.Actions.DeleteStudentSuccess, (state) => {
    return {
      ...state,
      isDeleteStudentSending: false
    }
  }),
  on(fromStudent.Actions.DeleteStudentError, (state) => {
    return {
      ...state,
      isDeleteStudentSending: false
    }
  }),
);


