import { Injectable } from "@angular/core";
import { Store } from '@ngrx/store';
import { IAppState } from 'app/store/state/app.state';
import * as fromCourseExchangeBilling from '@courseExchange/billing/store';
import { RealTimeService } from "@core/services/realTime.service";
import { HubConnection } from "@microsoft/signalr";
import { MonthlyEarningsStatement } from "@courseExchange/billing/models";

@Injectable()
export class CourseExchangeBillingRealtime {
  constructor(private store: Store<IAppState>, private realTimeService: RealTimeService) {
    this.initSignalRMessages();
  }
  private initSignalRMessages() {
    this.realTimeService.hubConnection$.subscribe(hubConnection => {
      if (!hubConnection) return;
      this.monthlyEarningStatementUpdated(hubConnection);
    });
  }
  private monthlyEarningStatementUpdated(hubConnection: HubConnection) {
    hubConnection.on('MonthlyEarningStatementUpdated', (monthlyEarningsStatement: MonthlyEarningsStatement) => {
      this.store.dispatch(fromCourseExchangeBilling.Actions.MonthlyEarningStatementUpdated({ monthlyEarningsStatement }));
    });
  }
  private monthlyEarningStatementAdded(hubConnection: HubConnection) {
    hubConnection.on('MonthlyEarningStatementAdded', (monthlyEarningsStatement: MonthlyEarningsStatement) => {
      this.store.dispatch(fromCourseExchangeBilling.Actions.MonthlyEarningStatementAdded({ monthlyEarningsStatement }));
    });
  }
}
