import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { Store, select } from "@ngrx/store";
import { tap, first, finalize, filter, map } from "rxjs/operators";
import { IAppState } from 'app/store/state/app.state';
import * as fromRefData from '@refData';
import { PermissionDisplay } from '@auth/models/permissionDisplay';

@Injectable()
export class PermissionDisplaysResolver implements Resolve<PermissionDisplay[]>{
    isLoading = false;
    constructor(private store: Store<IAppState>){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
        return this.store 
            .pipe(
                select(fromRefData.Selectors.permissionDisplays),
                map((permissionDisplays)=>{
                    if(!!permissionDisplays && permissionDisplays.length>0){
                        this.store.dispatch(fromRefData.Actions.LoadPermissionDisplaysSuccess({permissionDisplays}));
                    }else if(!this.isLoading){
                        this.isLoading = true;
                        this.store.dispatch(fromRefData.Actions.LoadPermissionDisplays({forceReload:false}));
                    }
                    return true;
                }),
                filter(permissionDisplays => permissionDisplays),
                first(),
                finalize(()=>this.isLoading=false)
            )
    }
}