import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { Store, select } from "@ngrx/store";
import { first, finalize, filter, map } from "rxjs/operators";
import { IAppState } from 'app/store/state/app.state';
import { CourseExchangeFeeListItem } from 'app/shared/courseExchange/models';
import * as fromMember from '@member/store';
import * as fromCourseExchange from '@courseExchange/store';

@Injectable()
export class MemberFeesResolver implements Resolve<CourseExchangeFeeListItem[]>{
    isLoading = false;
    constructor(private store: Store<IAppState>){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
        const memberId = route.params['memberId'];
        let courseExchangeId = '';
        return this.store 
            .pipe(
                select(fromMember.Selectors.Member),
                map((currentMember)=> {
                    courseExchangeId = currentMember.courseExchangeId;
                    return;
                }),
                map(() => {
                    if(!this.isLoading){
                        this.isLoading = true;
                        this.store.dispatch(fromCourseExchange.Actions.LoadFees({courseExchangeId}));
                    }
                    return true;
                }),
                filter(feesLoaded => feesLoaded),
                first(),
                finalize(()=>this.isLoading=false)
            )
    }
}