import {
  createReducer,
  on
} from '@ngrx/store';
import * as fromStore from '..';
import * as fromRefData from '@refData';

import {Sort, Order, EntityStatus} from 'app/core/models';
import {Filter, FilterType} from '@shared/common/models';
import { CourseExchangeInstitutionListItem } from '@shared/courseExchange/models';
import { CourseExchangeInstitutionType } from '@courseExchange/store/enums';
import * as fromCourseExchange from '@courseExchange/store';
import initEntityStatusFIlters from '@shared/common/helpers/DefaultEntityStatusList';
import toggleItem from '@shared/common/helpers/toggleItem';
import { CourseExchangeInstitutionListMode } from '../courseExchangeInstitutionListMode.enum';
import { UserListItem } from '@shared/user/models';
import { AccreditationBody } from '@refData';

export interface CourseExchangeInstitutionState {
  courseExchangeId: string,
  institutions: CourseExchangeInstitutionListItem[],
  currentInstitution: CourseExchangeInstitutionListItem,
  currentInstitutionUsers: UserListItem[],
  currentSort: Sort,
  currentOrder: Order,
  currentType: CourseExchangeInstitutionType,
  statusFilters: Filter[],
  accreditationBodies: AccreditationBody[],
  accreditationBodyFilters: Filter[],
  regionFilters: Filter[],
  searchText: string;
  allInstitutions: CourseExchangeInstitutionListItem[];
  courseExchangeInstitutionListMode: CourseExchangeInstitutionListMode;
  IsLoadingInstitutions:boolean;
}

export const initialCourseExchangeInstitutionState: CourseExchangeInstitutionState = {
  courseExchangeId: '',
  institutions: new Array<CourseExchangeInstitutionListItem>(),
  currentInstitution: undefined,
  currentInstitutionUsers: Array<UserListItem>(),
  currentSort: new Sort(),
  currentOrder: new Order(),
  currentType: CourseExchangeInstitutionType.Both,
  statusFilters: initEntityStatusFIlters(),
  accreditationBodies: new Array<AccreditationBody>(),
  accreditationBodyFilters: new Array<Filter>(),
  regionFilters: new Array<Filter>(),
  searchText: '',
  allInstitutions: new Array<CourseExchangeInstitutionListItem>(),
  courseExchangeInstitutionListMode: CourseExchangeInstitutionListMode.CourseExchange,
  IsLoadingInstitutions:false
};

export const courseExchangeInstitutionReducer = 
createReducer(
  initialCourseExchangeInstitutionState,
  on(fromStore.Actions.ToggleStatusFilter, (state, {id}) => {
    return {
      ...state,
      statusFilters: toggleItem(state.statusFilters,id)
    };
  }),
  on(fromStore.Actions.ToggleAccreditationBodyFilter, (state, {id}) => {
    return {
      ...state,
      accreditationBodyFilters: toggleItem(state.accreditationBodyFilters, id)
    };
  }),
  on(fromStore.Actions.ToggleRegionFilter, (state, {id}) => {
    return {
      ...state,
      regionFilters: toggleItem(state.regionFilters, id)
    };
  }),
  on(fromStore.Actions.RemoveFilter, (state, {filter})=>{
      let statusFilters = state.statusFilters;
      let accreditationBodyFilters = state.accreditationBodyFilters;
      let regionFilters = state.regionFilters;
      switch(filter.type){
          case FilterType.EntityStatus:
              statusFilters = toggleItem(statusFilters,filter.id);
              break;
          case FilterType.AccreditationBody:
              accreditationBodyFilters = toggleItem(accreditationBodyFilters,filter.id);
              break;
          case FilterType.InstitutionRole:
              regionFilters = toggleItem(regionFilters,filter.id);
              break;
          case FilterType.EntityStatus:
              statusFilters = toggleItem(statusFilters,filter.id);
              break;
      }
      return {
          ...state,
          statusFilters,
          accreditationBodyFilters,
          regionFilters
      }
  }),
  on(fromStore.Actions.ChangeSort, (state, {currentSort}) => {

    return {
      ...state,
      currentSort
    }
  }),
  on(fromStore.Actions.ChangeOrder, (state, {currentOrder}) => {
    return {
      ...state,
      currentOrder
    }
  }),
  on(fromStore.Actions.ChangeCourseExchangeInstitutionType, (state, {currentType}) => {
    return {
      ...state,
      currentType
    };
  }),
  on(fromRefData.Actions.LoadRegionsSuccess, (state, action) => {
    const regions = action.regions;

    const regionFilters = [...regions.map(region => {
      return {
        id: region.id,
        type: FilterType.Region,
        description: region.description,
        descriptionForView: region.description,
        selected: false
      };
    })];

    return {
      ...state,
      regionFilters
    };
  }),
  on(fromRefData.Actions.LoadAccreditationBodiesSuccess, (state, action) => {
    const accreditationBodies = action.accreditationBodies;

    const accreditationBodyFilters = [...accreditationBodies.map(accreditationBody => {
      return {
        id: accreditationBody.id,
        type: FilterType.AccreditationBody,
        description: accreditationBody.shortDescription,
        descriptionForView: accreditationBody.shortDescription,
        selected: false
      };
    })];

    return {
      ...state,
      accreditationBodies,
      accreditationBodyFilters
    };
  }),
  on(fromStore.Actions.ChangeSearchText, (state, action) => {
    const searchText = action.searchText;

    return {
      ...state,
      searchText
    };
  }),
  on(fromStore.Actions.InstitutionList, (state, action)=>{
    const {institutionType} = action;
    const currentType = institutionType;
    const filter = !!action.status ? {...state.statusFilters.find(x=>x.id===action.status),selected:true} : undefined;
    const statusFilters = !!filter ? toggleItem(state.statusFilters,filter.id): initEntityStatusFIlters();
    return {
      ...state,
      currentType,
      statusFilters,
      accreditationBodyFilters: new Array<Filter>(),
      regionFilters: new Array<Filter>(),
      searchText: '',
    }
  }),
  on(fromStore.Actions.LoadCourseExchangeInstitutions, (state, {courseExchangeId})=>{
    return {
      ...state,
      courseExchangeId,
      IsLoadingInstitutions:true
    }
  }),
  on(fromStore.Actions.LoadCourseExchangeInstitutionsSuccess, (state, action)=>{
    const institutions = action.institutions;
    const allInstitutions = state.allInstitutions.map((institution)=>{
      var courseExchangeInstitution = institutions.find(x=>x.institutionId==institution.institutionId);
      if(!!courseExchangeInstitution) return courseExchangeInstitution;
      return institution;
    })
    return {
      ...state,
      institutions,
      allInstitutions,
      IsLoadingInstitutions:false
    };
  }),
  on(fromCourseExchange.Actions.ApproveMemberSuccess, (state, {member}) =>{
    const idx = !!state.institutions ? state.institutions.findIndex(x=>x.memberId===member.id) : -1;
    const institution = !!state.institutions ? state.institutions[idx] : undefined;
    const approvedMember = !!institution ? {...institution, memberStatus: member.status } : undefined;
    const institutions = !!institution 
                        ? [...state.institutions.slice(0,idx),approvedMember, ...state.institutions.slice(idx+1)]
                        :state.institutions;
    return {
      ...state,
      institutions
    };
  }),
  on(fromStore.Actions.LoadAllInstitutions, (state, action)=>{
    const accreditationBodies = state.accreditationBodies;
    const institutions = state.institutions;
    const allInstitutions = action.allInstitutions.map((institution)=>{
      var courseExchangeInstitution = institutions.find(x=>x.institutionId==institution.id);
      if(!!courseExchangeInstitution) return courseExchangeInstitution;
      const accreditationBody = accreditationBodies.find(x=>x.shortDescription==institution.accreditationBody);
      return {
          courseExchangeId: '',
          managingInstitutionId: '',
          institutionId: institution.id,
          isMember: false,
          memberId: '',
          memberStatus: EntityStatus.None,
          isProvider: false,
          providerId: '',
          providerStatus: EntityStatus.None,
          institutionName: institution.name,
          institutionLogo: institution.logoUrl,
          websiteUrl: institution.websiteUrl,
          accreditationBody,
          address: institution.address,
          primaryContact: institution.primaryContact ,
          isActive: true,
          canDelete: false
        };
    });
    return {
      ...state,
      allInstitutions
    };
  }),
  on(fromStore.Actions.MemberReadOnlyWizard, (state, action)=>{
    const currentInstitution = action.institution;
    return {
      ...state,
      currentInstitution
    }
  }),
  on(fromStore.Actions.ProviderReadOnlyWizard, (state, action)=>{
    const currentInstitution = action.institution;
    return {
      ...state,
      currentInstitution
    }
  }),
  on(fromStore.Actions.InstitutionDetail, (state, action)=>{
    const currentInstitution = action.institution;
    return {
      ...state,
      currentInstitution
    }
  }),
  on(fromStore.Actions.LoadCurrentInstitutionSuccess, (state, {currentInstitution})=>{
    return {
      ...state,
      currentInstitution
    }
  }),
  on(fromStore.Actions.ChangeInstitutionListMode, (state, {courseExchangeInstitutionListMode})=>{
    return {
      ...state,
      courseExchangeInstitutionListMode
    }
  }),
  on(fromStore.Actions.UpdateInstitutionSuccess, (state, {institution})=>{
    var idx = state.institutions.findIndex(x=>x.institutionId==institution.institutionId);
    const institutions = [...state.institutions.slice(0,idx), institution, ...state.institutions.slice(idx+1)];
    return{
      ...state,
      institutions
    }
  }),
  on(fromStore.Actions.LoadInstitutionUsersSuccess, (state, {currentInstitutionUsers})=>{
    return {
      ...state,
      currentInstitutionUsers
    }
  }),
  on(fromStore.Actions.CourseExchangeInstitutionListItemUpdated, (state, {courseExchangeInstitutionListItem})=>{
    let idx = state.institutions.findIndex(x=>x.institutionId===courseExchangeInstitutionListItem.institutionId);
    const institutions = idx != -1 ? 
                        [...state.institutions.slice(0,idx),courseExchangeInstitutionListItem,...state.institutions.slice(idx+1)] :
                        courseExchangeInstitutionListItem.isMember || courseExchangeInstitutionListItem.isProvider ?
                        [...state.institutions, courseExchangeInstitutionListItem] : state.institutions;
    const allIdx = state.allInstitutions.findIndex(x=>x.institutionId==courseExchangeInstitutionListItem.institutionId);
    const allInstitutions = [...state.allInstitutions.slice(0,allIdx), courseExchangeInstitutionListItem, ...state.allInstitutions.slice(allIdx+1)];
    return {
      ...state,
      institutions,
      allInstitutions
    }
  }),
  on(fromStore.Actions.CourseExchangeInstitutionListItemUpdated, (state, {courseExchangeInstitutionListItem})=>{
    if(!!state.currentInstitution && state.currentInstitution.institutionId != courseExchangeInstitutionListItem.institutionId) return state;
    const currentInstitution = courseExchangeInstitutionListItem;
    return {
      ...state,
      currentInstitution
    }
  }),
  on(fromStore.Actions.CourseExchangeInstitutionListItemDeleted, (state, {courseExchangeInstitutionListItem})=>{
    if(courseExchangeInstitutionListItem.courseExchangeId != state.courseExchangeId) return state;
    const institutions = state.institutions.filter(x=>x.institutionId != courseExchangeInstitutionListItem.institutionId);
    var idx = state.allInstitutions.findIndex(x=>x.institutionId==courseExchangeInstitutionListItem.institutionId);
    const allInstitutions = [...state.allInstitutions.slice(0,idx),courseExchangeInstitutionListItem, ...state.allInstitutions.slice(idx+1)];
  
    return {
      ...state,
      institutions,
      allInstitutions
    }
  }),
  on(fromStore.Actions.CourseExchangeInstitutionListItemAdded, (state, {courseExchangeInstitutionListItem})=>{
    if(state.courseExchangeId != courseExchangeInstitutionListItem.courseExchangeId) return state;
    const institutions = courseExchangeInstitutionListItem.isMember || courseExchangeInstitutionListItem.isProvider ?
                          [...state.institutions, courseExchangeInstitutionListItem]: state.institutions;
    const idx = state.allInstitutions.findIndex(x=>x.institutionId == courseExchangeInstitutionListItem.institutionId);
    const allInstitutions = idx === -1 ? [...state.allInstitutions, courseExchangeInstitutionListItem] :
                          [...state.allInstitutions.slice(0,idx), courseExchangeInstitutionListItem, ...state.allInstitutions.slice(idx+1)];
    return {
      ...state,
      institutions,
      allInstitutions
    }
  }),
  on(fromStore.Actions.DeactivateCourseExchangeInstitution, (state, action)=>{
    const idx = state.institutions.findIndex(x=>x.institutionId==action.courseExchangeInstitutionListItem.institutionId);
    const courseExchangeInstitutionListItem = {...state.institutions[idx], isActive: false};
    const institutions = [...state.institutions.slice(0,idx),courseExchangeInstitutionListItem,...state.institutions.slice(idx+1)];
    return {
      ...state,
      institutions
    }
  }),
  on(fromStore.Actions.ActivateCourseExchangeInstitution, (state, action)=>{
    const idx = state.institutions.findIndex(x=>x.institutionId==action.courseExchangeInstitutionListItem.institutionId);
    const courseExchangeInstitutionListItem = {...state.institutions[idx], isActive: true};
    const institutions = [...state.institutions.slice(0,idx),courseExchangeInstitutionListItem,...state.institutions.slice(idx+1)];
    return {
      ...state,
      institutions
    }
  }),
  on(fromStore.Actions.DeleteCourseExchangeInstitution, (state, action)=>{
    const institutions = state.institutions.filter(x=>x.institutionId!=action.courseExchangeInstitutionListItem.institutionId);
    return {
      ...state,
      institutions
    }
  })
);


