import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {AccessRole} from '@auth/models/accessRole';
import { select, Store } from '@ngrx/store';
import { UserNotification } from '@shared/common/models';
import { IAppState } from 'app/store';
import { Observable } from 'rxjs';
import * as fromNotification from '@core/notifications/store';
@Component({
  selector: 'toolbar-notifications',
  templateUrl: './toolbar-notifications.component.html',
  styleUrls: ['./toolbar-notifications.component.scss', '../styles/common-toolbar.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ToolbarNotificationsComponent implements OnInit {
  @Input() currentAccessRole: AccessRole;
  notificationsCount$: Observable<number>;

  constructor(private store: Store<IAppState>) {
  }

  ngOnInit(): void {
    this.notificationsCount$ = this.store.pipe(select(fromNotification.Selectors.NotificationsCount));
  }
}
