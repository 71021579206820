import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { first, finalize, filter, map } from "rxjs/operators";
import { IAppState } from 'app/store/state/app.state';
import * as fromMemberCourses from '@member/memberCourse/store';
import { FacultyCV } from '@shared/institution/models';
import { getInstitutionIdFromRoute } from 'app/helpers/routeParamHelper';

@Injectable()
export class FacultyCVListResolver implements Resolve<FacultyCV[]>{
    isLoading = false;
    constructor(private store: Store<IAppState>){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
        return this.store 
            .pipe(
                map(() => {
                    if(!this.isLoading){
                        this.isLoading = true;
                        this.store.dispatch(fromMemberCourses.Actions.LoadFacultyCVList());
                    }
                    return true;
                }),
                filter(facultyCVListLoaded => facultyCVListLoaded),
                first(),
                finalize(()=>this.isLoading=false)
            )
    }
}