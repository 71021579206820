import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {IEntityActionsPayload} from '../../models/shared';
import {ECourseCalendarEntity, ECourseCalendarTypesActionsForm} from '../../constants';
import * as fromCourseExchange from '@courseExchange/store';
import { IAppState } from 'app/store';
import { select, Store } from '@ngrx/store';
import { SessionDateRules } from '@shared/calendar/models';
import { Observable } from 'rxjs';
@Component({
  selector: 'app-course-calendar-entity-creator-dialog',
  templateUrl: './calendar-entity-creator-dialog.component.html',
  styleUrls: ['./calendar-entity-creator-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CalendarEntityCreatorDialogComponent {
  title = '';
  payload: IEntityActionsPayload.Types;
  sessionDateRules$:Observable<SessionDateRules>;
  constructor(
    public matDialogRef: MatDialogRef<CalendarEntityCreatorDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private matDialogData: IEntityActionsPayload.Types,
    private store: Store<IAppState>
  ) {
    this.setPayload(matDialogData);
    this.shapeTitle();
    this.sessionDateRules$ = this.store.pipe(select(fromCourseExchange.Selectors.SessionDateRules));
  }

  onChangePayload(newPayload: IEntityActionsPayload.Types): void {
    this.payload = newPayload;
    this.shapeTitle();

    this.matDialogRef.removePanelClass([
      ECourseCalendarEntity.session,
      ECourseCalendarEntity.semester,
      ECourseCalendarEntity.academicYear
    ]);

    this.matDialogRef.addPanelClass(newPayload.entity);
  }

  private setPayload(payload: IEntityActionsPayload.Types): void {
    this.payload = payload;
  }

  private shapeTitle(): void {
    let title = '';

    switch (this.payload.typesActionsForm) {
      case ECourseCalendarTypesActionsForm.add: {
        switch (this.payload.entity) {
          case ECourseCalendarEntity.academicYear: {
            title = 'Add New Academic Year';
            break;
          }
          case ECourseCalendarEntity.semester: {
            title = 'Add New Semester';
            break;
          }
          case ECourseCalendarEntity.session: {
            title = 'Add New Session';
            break;
          }
        }

        break;
      }
      case ECourseCalendarTypesActionsForm.edit: {
        switch (this.payload.entity) {
          case ECourseCalendarEntity.academicYear: {
            title = 'Edit Academic Year';
            break;
          }
          case ECourseCalendarEntity.semester: {
            title = 'Edit Semester';
            break;
          }
          case ECourseCalendarEntity.session: {
            title = 'Edit Session';
            break;
          }
        }

        break;
      }
      case ECourseCalendarTypesActionsForm.duplicate: {
        switch (this.payload.entity) {
          case ECourseCalendarEntity.academicYear: {
            title = 'Duplicate Academic Year';
            break;
          }
          case ECourseCalendarEntity.semester: {
            title = 'Duplicate Semester';
            break;
          }
          case ECourseCalendarEntity.session: {
            title = 'Duplicate Session';
            break;
          }
        }

        break;
      }
    }

    this.title = title + ' Dialog';
  }
}
