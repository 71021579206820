import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import * as fromComponents from './components';

@NgModule({
  declarations: [...fromComponents.COMPONENTS],
  imports: [
    CommonModule
  ],
  exports: [...fromComponents.COMPONENTS]
})
export class AvatarSharedModule {
}
