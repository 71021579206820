<div class="navbar-container">
  <div class="navbar-user" fxLayout="column">
    <div class="c_sidebar-buttons buttons">
      <button mat-icon-button class="toggle-sidebar-folded"
              (click)="toggleSidebarFolded()" fxHide.lt-lg>
        <mat-icon class="secondary-text">arrow_forward_ios</mat-icon>
      </button>
      <button mat-icon-button class="toggle-sidebar-opened"
              (click)="toggleSidebarOpened()" fxHide.gt-md>
        <mat-icon class="secondary-text">close</mat-icon>
      </button>
    </div>

    <div class="avatar-container">
      <elb-avatar [name]="(currentUser$ | async)?.fullName"></elb-avatar>
      <ng-container *ngIf="(notificationsCount$ | async)">
        <div class="counter" (click)="openNotificationBar()">
          {{ ((notificationsCount$ | async) | limitedCounter) }}
        </div>
      </ng-container>
    </div>

    <div class="user-role h3">Super Admin</div>
    <button mat-button [matMenuTriggerFor]="userLogout"
            class="user-button">
      <div fxLayout="row" fxLayoutAlign="center center">
        <span class="user-name mr-12" fxHide fxShow.gt-sm>{{ user.fullName }}</span>
        <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
      </div>
    </button>

    <mat-menu class="user-account-menu" #userLogout="matMenu" [overlapTrigger]="false">
      <button mat-menu-item [routerLink]="['/auth/profile']" routerLinkActive="router-link-active">
        <mat-icon>account_circle</mat-icon>
        <span>My Profile</span>
      </button>
      <button mat-menu-item class="" (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>
        <span>Logout</span>
      </button>
      <mat-form-field>
        <mat-label>Set System Date</mat-label>
        <input matInput [matDatepicker]="picker" [value]="currentSystemDate$ | async"
               (dateChange)="setSystemDate($event)">
        <mat-datepicker-toggle matSuffix [for]="picker">
          <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
        </mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </mat-menu>

    <div class="user-email h5 mt-8">{{user.emailAddress}}</div>

    <div class="navbar-user-links mt-16">
      <div class="navbar-edit-profile-link"><a [routerLink]="['/auth/profile']" routerLinkActive="router-link-active">
        <fa-icon [icon]="faCog" class="nav-icon"></fa-icon>
        Edit Profile</a></div>
      <div class="navbar-quick-help-link mt-8"><a href="#">
        <mat-icon class="s-20 nav-icon">help_outline</mat-icon>
        Quick Help</a></div>
    </div>
  </div>
  <div class="navbar-scroll-container" fusePerfectScrollbar
       [fusePerfectScrollbarOptions]="{'suppressScrollX': true, 'updateOnRouteChange': true}">
    <div class="navbar-ws"></div>
    <div class="navbar-content">
      <div class="navbar-member-provider-dropdown">
        <mat-form-field appearance="outline">
          <mat-select placeholder="Admin"
                      (selectionChange)="changeAccessRole($event.value)"
                      class="w-216" mat-select [value]="currentAccessRoleId$ | async">
            <mat-option *ngFor="let role of accessRoles$ | async" [value]="role.id">
              {{role.descriptionForView}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>
  
  <div class="navigation">
    <div class="nav-item">
      <a href="#" class="nav-link " (click)="goToUserList()" [routerLinkActive]="['active', 'accent']">

        <mat-icon class="nav-link-icon mat-icon material-icons mat-icon-no-color ng-star-inserted" role="img"
                  aria-hidden="true">people_alt
        </mat-icon>
        <span class="nav-link-title ng-star-inserted">Users</span>

        <span class="nav-link-badge item-alert">

                    <mat-icon class="mat-icon material-icons mat-icon-no-color nav-link-badge-icon" role="img"
                              aria-hidden="true">error_outline</mat-icon>
                </span>
      </a>
    </div>
  </div>
</div>


