import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { NgModule } from '@angular/core';
import { StoreRouterConnectingModule, DefaultRouterStateSerializer } from '@ngrx/router-store';

import { environment } from 'environments/environment';
import {appReducers} from './reducers/app.reducers';
import {NotificationEffects} from '../core/notifications/store/effects/notification.effects';
import { InstitutionStoreModule } from '@institution/store/institutionStore.module';
import { WizardSharedModule } from '@shared/wizard/wizard.module';
import { UserListStoreModule } from '@shared/user/components/user-list/store/userListStore.module';
import { AuthenticationStoreModule } from '@auth/store/auth.store.module';
import { CalendarStoreModule } from '@shared/calendar/store/calendarStore.module';
import { ProviderStoreModule } from '@provider/store/providerStore.module';
import { MemberStoreModule } from '@member/store/memberStore.module';
import { CourseExchangeStoreModule } from '@courseExchange/store/courseExchangeStore.module';
import { NotificationStoreModule } from '@core/notifications/store/notifications.store.module';

@NgModule({
    imports  : [
        StoreModule.forRoot(appReducers,{runtimeChecks:{
            strictStateImmutability: false,
            strictStateSerializability: false,
            strictActionImmutability: false,
            strictActionSerializability: false
        }}),
        EffectsModule.forRoot([NotificationEffects]),
        StoreDevtoolsModule.instrument({
            maxAge: 25,
            logOnly: environment.production,
            name: 'Taawun',
            autoPause: true
        }),
        StoreRouterConnectingModule.forRoot({ serializer: DefaultRouterStateSerializer }),
        AuthenticationStoreModule,
        CalendarStoreModule,
        CourseExchangeStoreModule,
        InstitutionStoreModule,
        UserListStoreModule,
        WizardSharedModule,
        MemberStoreModule,
        ProviderStoreModule,
        NotificationStoreModule

    ],
    providers: []
})

export class AppStoreModule
{
}
