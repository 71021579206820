<form [formGroup]="form" class="form" *ngIf="form">
  <h2 class="mat-title">
    <ng-container *ngIf="payload.typesActionsForm === 'add'">
      Add New Academic Year
    </ng-container>
    <ng-container *ngIf="payload.typesActionsForm === 'edit'">
      Edit Academic Year
    </ng-container>
    <ng-container *ngIf="payload.typesActionsForm === 'duplicate'">
      Duplicate Academic Year
    </ng-container>
  </h2>

  <mat-form-field appearance="outline" class="mt-8" fxFill>
    <mat-label>Name</mat-label>
    <input
      matInput
      name="name"
      required
      formControlName="name">
    <mat-error *ngIf="hasError('name', 'required')">Name is required</mat-error>
  </mat-form-field>

  <div class="dates" fxFlexFill fxLayout="column" fxLayout.gt-xs="row">

    <mat-form-field appearance="outline" class="mr-sm-12" fxFlex>
      <mat-label>Start Date</mat-label>
      <input matInput [matDatepicker]="startDatePicker" formControlName="startDate">
      <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #startDatePicker></mat-datepicker>
    </mat-form-field>

    <mat-form-field appearance="outline" class="ml-sm-12" fxFlex>
      <mat-label>End Date</mat-label>
      <input matInput [matDatepicker]="endDatePicker" formControlName="endDate" placeholder="End Date">
      <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
      <mat-datepicker #endDatePicker></mat-datepicker>
      <mat-error *ngIf="hasError('endDate', 'lessStartDate')">Is less or equal than the start date</mat-error>
      <mat-error *ngIf="hasError('endDate', 'IsValid')">In valid date</mat-error>
    </mat-form-field>

  </div>

  <div class="form-actions">
    <button mat-raised-button type="button" color="warn" (click)="onClose.emit()">
      Cancel
    </button>

    <ng-container *ngIf="payload.typesActionsForm === 'add' || payload.typesActionsForm === 'duplicate'">
      <button mat-raised-button
              (click)="onAddAcademicYear()"
              [disabled]="isSending || form.invalid"
              type="submit"
              class="btn-primary">
        Add Year
      </button>
    </ng-container>

    <ng-container *ngIf="payload.typesActionsForm === 'edit'">
      <button mat-raised-button
              (click)="onEditAcademicYear()"
              [disabled]="isSending || !form.valid"
              type="submit"
              class="btn-primary">
        Edit Year
      </button>
    </ng-container>
  </div>

  <div class="continue" *ngIf="payload.typesActionsForm === 'add' || payload.typesActionsForm === 'duplicate'">
    <button mat-raised-button
            (click)="onContinueAddSemester()"
            [disabled]="isSending"
            type="submit"
            class="btn-primary">
      Continue to Add Semester
    </button>
  </div>
</form>
