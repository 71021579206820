import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ProviderDashboardState } from '../reducers';
import * as fromProvider from '@provider/store';
const selectProviderDashboardState = createFeatureSelector<ProviderDashboardState>('provider/dashboard');

export const ProviderId = createSelector(
  selectProviderDashboardState,
  (state) => state.providerId
)

export const TotalCoursesOffered = createSelector(
  selectProviderDashboardState,
  (state) => state.totalCoursesOffered
)

export const PendingCourses = createSelector(
  selectProviderDashboardState,
  (state) => state.pendingCourses
)

export const ApprovedCourses = createSelector(
  selectProviderDashboardState,
  (state) => state.approvedCourses
)

export const TotalEnrollments = createSelector(
  selectProviderDashboardState,
  (state) => state.totalEnrollments
)

export const NewEnrollments = createSelector(
  selectProviderDashboardState,
  (state) => state.newEnrollments
)

export const SelectorForProviderDashboard = createSelector(
  fromProvider.Selectors.ProviderId,
  selectProviderDashboardState,
  (currentProviderId, {currentAcademicYearId})=>{
    return {
      currentProviderId,
      currentAcademicYearId
    }
  }
)
export const IsLoadDashboardData = createSelector(
  selectProviderDashboardState,
  (state) => state.isLoadDashboardData
)