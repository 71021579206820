<div class="enrollment-short-details-info">
  <div class="row">
    <div class="col">
      <div class="info-box">
        <div class="title">Registration Start:</div>
        <div class="text">04/01/2020</div>
      </div>
    </div>

    <div class="col">
      <div class="info-box">
        <div class="title">Drop Deadline:</div>
        <div class="text">04/01/2020</div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="info-box">
        <div class="title">Registration End:</div>
        <div class="text">04/01/2020</div>
      </div>
    </div>

    <div class="col">
      <div class="info-box">
        <div class="title">Withdrawal Deadline:</div>
        <div class="text">04/01/2020</div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="info-box">
        <div class="title">Fee Earned:</div>
        <div class="text">04/01/2020</div>
      </div>
    </div>
  </div>
</div>
