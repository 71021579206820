import { createSelector, createFeatureSelector } from '@ngrx/store'
import { SelectedStudents } from '@member/students/store/selectors/students.selectors'
import { SelectedMemberCourses } from '@member/memberCourse/store/memberCourse.selectors'
import { EnrollmentsState } from '../reducers/enrollment.reducer';

import * as fromMember from '@member/store';
import * as fromRefData from '@refData';
import * as fromStudent from '@member/students/store';
import * as fromMemberCourse from '@member/memberCourse/store';

import { searchInText } from 'app/helpers/search-in-text';
import { EnrollmentListItemForView } from '@member/enrollment';
import { sortBy } from 'sort-by-typescript';
import { EnrollmentStatus } from '@refData';
import { PermissionType } from '@auth/models/permissionType.enum';
import { EnrollmentListMode } from '@provider/enrollment/store/selectors/enrollment.selectors';

export const selectEnrollmentsState = createFeatureSelector<EnrollmentsState>('member/enrollments');

export const Enrollments = createSelector(
    selectEnrollmentsState,
    (state) => state.enrollments
)

export const EnrollmentsForExport = createSelector(
    Enrollments,
    fromMember.Selectors.HasMemberPermission(PermissionType.CanSubmitGradeDispute),
    (enrollments, canViewGrades) => {
        return {
            enrollments,
            canViewGrades
        }
    }
)
export const SelectorForEnrollments = createSelector(
    fromMember.Selectors.MemberId,
    selectEnrollmentsState,
    (memberId, state) => {
        return {
            currentMemberId: memberId,
            currentStartDate: state.startDate,
            currentEndDate: state.endDate
        }
    }
)

export const RequestedChanges = createSelector(
    Enrollments,
    (enrollments) => {
        var result = enrollments.filter(x => x.requestedStatus != null);
        return result;
    }
)
export const StudentsAndCoursesForEffect = createSelector(
    fromStudent.Selectors.SelectedStudents,
    fromMemberCourse.Selectors.SelectedMemberCourses,
    fromMemberCourse.Selectors.MemberCourseListItems,
    (students, selectedMemberCourses, memberCourses) => {
        return {
            students,
            selectedMemberCourses,
            memberCourses
        }
    }
)

export const IsSubmittingRequestChanges = createSelector(
    selectEnrollmentsState,
    ({ isSubmittingChanges }) => isSubmittingChanges
)
export const CanSubmitChanges = createSelector(
    RequestedChanges,
    IsSubmittingRequestChanges,
    (requestedChanges, isSubmittingChanges) => requestedChanges.length > 0 && !isSubmittingChanges 
)
export const RequestedChangesForEffects = createSelector(
    RequestedChanges,
    fromMember.Selectors.MemberId,
    fromRefData.enrollmentStatuses,
    (requestedChanges, memberId, enrollmentStatuses) => {
        return {
            memberId,
            command: {
                commands: requestedChanges.map(x => {
                    return {
                        enrollmentId: x.enrollmentId,
                        requestedStatus: x.requestedStatus,
                        fromMember: true,
                        fromProvider: false
                    }
                })
            },
            enrollmentStatuses
        }
    }
)

export const Orders = createSelector(
    selectEnrollmentsState,
    (state) => state.orders
)
export const CurrentOrder = createSelector(
    selectEnrollmentsState,
    (state) => state.currentOrder
)
export const Sorts = createSelector(
    selectEnrollmentsState,
    (state) => state.sorts
)
export const CurrentSort = createSelector(
    selectEnrollmentsState,
    (state) => state.currentSort
)
export const SearchText = createSelector(
    selectEnrollmentsState,
    (state) => state.searchText
)
export const StatusFilters = createSelector(
    selectEnrollmentsState,
    ({ enrollments, statusFilters }) => {
        return statusFilters.filter(x => enrollments.find(e => e.status.id == x.id)).sort(sortBy('description')).map(statusFilter => {
            const count = !!enrollments ? enrollments.filter(enrollment => enrollment.status.id === statusFilter.id).length : 0;
            return {
                ...statusFilter,
                descriptionForView: `${statusFilter.description} (${count})`
            }
        })
    }
)
export const SelectedStatusFilters = createSelector(
    StatusFilters,
    (filters) => !!filters ? filters.filter(x => x.selected) : []
)
export const StatusFilterCount = createSelector(
    SelectedStatusFilters,
    (filters) => filters.length > 0 ? filters.length : null
)

export const ProviderFilters = createSelector(
    selectEnrollmentsState,
    (state) => state.providerFilters
)
export const SelectedProviderFilters = createSelector(
    ProviderFilters,
    (filters) => filters.filter(x => x.selected)
)

export const ProviderFilterCount = createSelector(
    SelectedProviderFilters,
    (filters) => filters.length > 0 ? filters.length : null
)
export const ProviderFiltersForView = createSelector(
    Enrollments,
    ProviderFilters,
    (enrollments, providers) => {
        return providers.filter(x => enrollments.find(e => e.providerId == x.id)).sort(sortBy('description')).map(filter => {
            const count = !!enrollments ? enrollments.filter(x => x.providerId === filter.id).length : 0;
            return {
                ...filter,
                descriptionForView: `${filter.description} (${count})`
            }
        })
    }
)

export const TagFilters = createSelector(
    selectEnrollmentsState,
    (state) => state.tagFilters
)
export const SelectedTagFilters = createSelector(
    TagFilters,
    (filters) => filters.filter(x => x.selected)
)
export const TagFilterCount = createSelector(
    SelectedTagFilters,
    (filters) => filters.length > 0 ? filters.length : null
)
export const TagFiltersForView = createSelector(
    Enrollments,
    TagFilters,
    (enrollments, enrollmentTags) => {
        return enrollmentTags.filter(x => enrollments.find(y =>!!y.tags &&  y.tags.find(t => t.id === x.id))).sort(sortBy('description')).map(filter => {
            const count = !!enrollments ? enrollments.filter(x =>!!x.tags && x.tags.find(tag => tag.id === filter.id) != undefined).length : 0;
            return {
                ...filter,
                descriptionForView: `${filter.description} (${count})`
            }
        })
    }
)
export const CategoryFilters = createSelector(
    selectEnrollmentsState,
    (state) => state.categoryFilters
)

export const SelectedCategoryFilters = createSelector(
    CategoryFilters,
    (filters) => filters.filter(x => x.selected)
)

export const CategoryFilterCount = createSelector(
    SelectedCategoryFilters,
    (filters) => filters.length > 0 ? filters.length : null
)

export const SubCategoryFilters = createSelector(
    selectEnrollmentsState,
    (state) => state.subCategoryFilters
)

export const SelectedSubCategoryFilters = createSelector(
    SubCategoryFilters,
    (filters) => filters.filter(x => x.selected)
)

export const SubCategoryFilterCount = createSelector(
    SelectedSubCategoryFilters,
    (filters) => filters.length > 0 ? filters.length : null
)

export const CategoryFiltersForView = createSelector(
    Enrollments,
    CategoryFilters,
    (enrollments, enrollmentsCategories) => {

        return enrollmentsCategories.filter(x => enrollments.find(e =>!!e.category && e.category.id == x.id)).sort(sortBy('description')).map(filter => {
            const count = !!enrollments ? enrollments.filter(x =>!!x.category && x.category.id === filter.id).length : 0;
            return {
                ...filter,
                descriptionForView: `${filter.description} (${count})`
            }
        })
    }
)

export const SubCategoryFiltersForView = createSelector(
    Enrollments,
    SubCategoryFilters,
    (enrollments, enrollmentsSubCategories) => {

        return enrollmentsSubCategories.filter(x => enrollments.find(e =>!!e.subCategory && e.subCategory.id == x.id)).sort(sortBy('description')).map(filter => {
            const count = !!enrollments ? enrollments.filter(x =>!!x.subCategory && x.subCategory.id === filter.id).length : 0;
            return {
                ...filter,
                descriptionForView: `${filter.description} (${count})`
            }
        })
    }
)

export const SelectedFilters = createSelector(
    SelectedStatusFilters,
    SelectedProviderFilters,
    SelectedTagFilters,
    SelectedCategoryFilters,
    SelectedSubCategoryFilters,
    (statusFilters, providerFilters, tagFilters, categoryFilters, subCategoryFilters) =>
        [...statusFilters, ...providerFilters, ...tagFilters, ...categoryFilters, ...subCategoryFilters]
)
export const SelectedFilterList = createSelector(
    SelectedStatusFilters,
    SelectedProviderFilters,
    SelectedTagFilters,
    SelectedCategoryFilters,
    SelectedSubCategoryFilters,
    (statusFilters, providerFilters, tagFilters, categoryFilters, subCategoryFilters) => {
        return {
            statusFilters,
            providerFilters,
            tagFilters,
            categoryFilters,
            subCategoryFilters
        }
    }
)


export const FilteredEnrollments = createSelector(
    Enrollments,
    CurrentOrder,
    CurrentSort,
    SearchText,
    SelectedFilterList,
    EnrollmentListMode,
    (enrollments, currentOrder, currentSort, searchText, selectedFilters, listMode) => {
        let result = [...enrollments];

        const { statusFilters, providerFilters, tagFilters, categoryFilters, subCategoryFilters } = selectedFilters;

        if (statusFilters.length) {
            result = result.filter(enrollment => {
                const index = statusFilters.findIndex(item => enrollment.status.id === item.id);
                return (index !== -1);
            })
        }

        if (providerFilters.length) {
            result = result.filter(enrollment => {
                const index = providerFilters.findIndex(item => item.id === enrollment.providerId);
                return (index !== -1);
            })
        }

        if (tagFilters.length) {
            result = result.filter(enrollment => {
                const index = tagFilters.findIndex(item =>!!enrollment.tags && enrollment.tags.find(tag => tag.id === item.id) != undefined);
                return (index !== -1);
            })
        }
        if (categoryFilters.length) {
            result = result.filter(enrollment => {
                const index = categoryFilters.findIndex(item =>!!enrollment.category && item.id === enrollment.category.id);
                return (index !== -1);
            })
        }

        if (subCategoryFilters.length) {
            result = result.filter(enrollment => {
                const index = subCategoryFilters.findIndex(item =>!!enrollment.subCategory && item.id === enrollment.subCategory.id);
                return (index !== -1);
            })
        }
        result = searchInText<EnrollmentListItemForView>(searchText, result, [
            'studentFullName.firstName',
            'studentFullName.middleName',
            'studentFullName.lastName',
            'studentNumber',
            'studentEmailAddress',
            'studentPhoneNumber',
            'memberName',
            'memberCourseCode',
            'providerName',
            'providerCourseCode',
            'courseTitle',
            'sessionCode',
            'status.description'
        ]);

        const sort = currentSort.value;
        const order = currentOrder.value;
        
        result = result.sort(sortBy(`${order}${sort}`));

        return result;
    }
)

export const SelectedEnrollments = createSelector(
    FilteredEnrollments,
    (enrollments) => !!enrollments ? enrollments.filter(x => x.selected) : []
)

export const CanExportToExcel = createSelector(
    SelectedEnrollments,
    (selected) => selected.length > 0
)

export const CommonAllowedStatuses = createSelector(
    SelectedEnrollments,
    fromRefData.enrollmentStatuses,
    (enrollments, statuses) => {
        let result = new Array<EnrollmentStatus>();
        statuses.forEach(status => {
            if (enrollments.every(e => e.status.memberAllowedStatuses.find(x => x.id === status.id) != undefined)) {
                result.push(status);
            }
        });
        return result;
    }
)

export const SelectedStatus = createSelector(
    FilteredEnrollments,
    (filtered) => `${filtered.filter(x => x.selected).length} of ${filtered.length}`
)

export const IsAllSelected = createSelector(
    FilteredEnrollments,
    (filtered) => filtered.length == 0 ? false : filtered.every(x => x.selected)
);

export const IndeterminateSelected = createSelector(
    IsAllSelected,
    FilteredEnrollments,
    (isAllSelected, students) => isAllSelected ? false : students.some(x => x.selected)
)

export const EnrollmentsForResolver = createSelector(
    selectEnrollmentsState,
    ({ memberId, enrollments }) => {
        return {
            memberId,
            enrollments
        }
    }
)

export const CanCreateEnrollment = createSelector(
    SelectedStudents,
    SelectedMemberCourses,
    (students, courses) => students.length > 0 && courses.length > 0
)

export const IsEnrollmentsLoading = createSelector(
    selectEnrollmentsState,
    (state) => state.isEnrollmentsLoading
)