import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fromComponents from './components';
import * as fromServices from './services';
import { MaterialModule } from 'app/core/material/material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '@core/core.module';
import {DirectivesModule} from '../../directives/directives.module';
import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
    declarations: [...fromComponents.COMPONENTS],
    exports: [...fromComponents.COMPONENTS],
    providers: [...fromServices.SERVICES],
    imports: [
        CommonModule,
        CoreModule,
        MaterialModule,
        ReactiveFormsModule,
        DirectivesModule,
        ScrollingModule
    ]
})
export class UserSharedModule { }
