import {createAction, props} from '@ngrx/store';
import { ProviderDashboardViewModel } from '@provider/dashboard/models';

export const LoadDashboardData = createAction(
  '[Provider Dashboard Effects] - Load Dashboard Data',
  props<{
    providerId: string;
    academicYearId: string;
  }>()
)
export const LoadDashboardDataSuccess = createAction(
  '[Provider Dashboard Resolver] - Load Dashboard Data Success',
  props<{
    data: ProviderDashboardViewModel;
  }>()
);

export const LoadDashboardDataError = createAction(
  '[Provider Dashboard Resolver] - Load Dashboard Data Error',
  props<{
    message: string
  }>()
);