import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { Store, select } from "@ngrx/store";
import { first, finalize, filter, map, withLatestFrom, concatMap } from "rxjs/operators";
import { IAppState } from 'app/store/state/app.state';
import * as fromInstitution from '@institution/store';
import * as fromEnrollment from '@member/enrollment/store';
import * as fromMember from '@member/store';
import { EnrollmentListItem } from '@member/enrollment/models/enrollmentListItem.viewModel';
import * as moment from 'moment';
@Injectable()
export class EnrollmentsForMemberResolver implements Resolve<EnrollmentListItem[]>{
    isLoading = false;
    constructor(private store: Store<IAppState>){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
        const memberId = route.params["memberId"];
        let startDate = moment().toDate();
        let endDate = moment().toDate();
        return this.store 
            .pipe(
                select(fromMember.Selectors.DateRangeArgs),
                concatMap((action) => of(action).pipe(
                    withLatestFrom(this.store.pipe(select(fromEnrollment.Selectors.SelectorForEnrollments))
                ))),
                map(([dateRangeArs, {currentMemberId, currentStartDate, currentEndDate}])=>{
                    startDate = dateRangeArs.startDate;
                    endDate = dateRangeArs.endDate;
                    var result = currentMemberId === memberId && 
                                 currentStartDate===startDate && 
                                 currentEndDate===endDate
                    return result;
                }),
                map((enrollmentsLoaded)=>{
                    if(!this.isLoading && !enrollmentsLoaded){
                        this.isLoading = true;
                        this.store.dispatch(fromEnrollment.Actions.LoadEnrollmentByDateRange({memberId,startDate,endDate}));
                    }
                    return true;
                }),
                filter(enrollmentsLoaded => enrollmentsLoaded),
                first(),
                finalize(()=>this.isLoading=false)
            )
    }
}