import {
    createReducer,
    on
  } from '@ngrx/store';
import * as fromAuth from '@auth/store';
import * as fromMember from '..';
import { Member } from 'app/shared/member/models';
 
  export interface MemberState {
      member: Member
  };
  
  export const initialMemberState: MemberState = {
      member: undefined
  };
  
  export const memberReducer = createReducer(
    initialMemberState,
    on(fromAuth.Actions.selectCourseExchangeId, (state,action)=>{
      if(!!state.member && state.member.courseExchangeId == action.courseExchangeId) return state;
      return initialMemberState
    }),
    on(fromMember.Actions.LoadMemberSuccess, (state, action) =>{
    const member = action.member;
    return {
        ...state,
        member
      };
    }),
    on(fromMember.Actions.ClearState, (state, action)=>{
      return initialMemberState
    }),
    on(fromMember.Actions.MemberUpdated, (state, {member})=>{
      if(!state.member || member.id!=state.member.id) return state;
      return{
        ...state,
        member
      }
    })
    

);
  
  
  