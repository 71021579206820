

import {createFeatureSelector, createSelector} from '@ngrx/store';
import { MemberBillingState } from '../reducers';

export const selectMemberBillingState = createFeatureSelector<MemberBillingState>('member/billing');

export const MemberId = createSelector(
    selectMemberBillingState,
    (state) => state.memberId
)

export const MonthlyStatements = createSelector(
    selectMemberBillingState,
    (state) => state.monthlyStatements
)

export const areMemberFeeMonthlySummariesLoaded = createSelector(
    selectMemberBillingState,
    (state) => state.areMonthlyStatementsLoaded
)
export const isMemberFeeMonthlySummariesLoading = createSelector(
    selectMemberBillingState,
    (state) => state.isMonthlyStatementsLoading
)

export const CurrentMemberMonthlyBill = createSelector(
    selectMemberBillingState,
    (state) => state.currentMemberMonthlyBill
)

export const IsMemberMonthlyBillLoading = createSelector(
    selectMemberBillingState,
    (state)=> state.isMemberMonthlyBillLoading
)