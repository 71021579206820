import { InstitutionState } from "@institution/store"
import { AcademicYear, Semester } from "@shared/calendar/models"

export default (academicYears:AcademicYear[], semesterId: string): Semester => {
    var result = undefined;
    academicYears.forEach((academicYear)=>{
      academicYear.semesters.forEach((semester)=>{
        if(semester.id===semesterId){
          result = semester;
        }
      })
    })
    return result;  
}