import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'wizard-logo-uploader',
  templateUrl: './wizard-logo-uploader.component.html',
  styleUrls: ['./wizard-logo-uploader.component.scss']
})
export class WizardLogoUploaderComponent {
  @Input() title: string;
  @Input() logoUrl: string;
  @Input() logoSize: [number, number];
  @Input() isSending: boolean;
  @Output() uploadLogo: EventEmitter<File> = new EventEmitter<File>();

  fileInputChange = (event: Event) => {
    const file = (event.target as HTMLInputElement).files[0];
    this.uploadLogo.emit(file);
  }

  get xSize(): number {
    return this.logoSize[0]
  }

  get ySize(): number {
    return this.logoSize[1]
  }
}
