import { Injectable } from "@angular/core";
import * as fromCourseExchangeUsers from '@courseExchange/users/store';
import { IAppState } from 'app/store/state/app.state';
import { Store } from '@ngrx/store';
import { RealTimeService } from "@core/services/realTime.service";
import { UserListItem } from "@shared/user/models";
import { HubConnection } from "@microsoft/signalr";
@Injectable()
export class CourseExchangeUsersRealtime {
    constructor(private store: Store<IAppState>, private realTimeService: RealTimeService) {
            this.initSignalRMessages();
    }

    private initSignalRMessages(){
        this.realTimeService.hubConnection$.subscribe(hubConnection=>{
          if(!hubConnection) return;
          this.userListItemAdded(hubConnection);
          this.userListItemUpdated(hubConnection);
        })
      }
    private userListItemAdded(hubConnection:HubConnection){
        hubConnection.on('UserListItemAdded', (userListItem: UserListItem) => {
            this.store.dispatch(fromCourseExchangeUsers.Actions.UserListItemAdded({userListItem}));
          });
    }
    private userListItemUpdated(hubConnection:HubConnection){
        hubConnection.on('UserListItemUpdated', (userListItem: UserListItem) => {
            this.store.dispatch(fromCourseExchangeUsers.Actions.UserListItemUpdated({userListItem}));
          });
    }
}