import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Observable} from 'rxjs';
import {UserSummary} from 'app/authentication/models/userSummary.model';
import {AccessRoleForView} from 'app/authentication/models/accessRole';
import {IAppState} from 'app/store/state/app.state';
import {Store, select} from '@ngrx/store';
import * as fromAuth from '@auth/store';
import { CourseExchangeListItem } from '@shared/courseExchange/models';

@Component({
  selector: 'toolbar-user-info',
  templateUrl: './toolbar-user-info.component.html',
  styleUrls: ['./toolbar-user-info.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ToolbarUserInfoComponent implements OnInit {
  currentUser$: Observable<UserSummary>;
  currentAccessRoleId$: Observable<string>;
  accessRoles$: Observable<AccessRoleForView[]>;
  currentCourseExchange$: Observable<CourseExchangeListItem>;
  courseExchanges$: Observable<CourseExchangeListItem[]>;
  constructor(
    private store: Store<IAppState>
  ) {
  }

  ngOnInit(): void {
    this.currentUser$ = this.store.pipe(select(fromAuth.Selectors.CurrentUser));
    this.accessRoles$ = this.store.pipe(select(fromAuth.Selectors.CurrentAccessRoles));
    this.currentAccessRoleId$ = this.store.pipe(select(fromAuth.Selectors.currentAccessRoleId));
    this.currentCourseExchange$ = this.store.pipe(select(fromAuth.Selectors.CurrentCourseExchange));
    this.courseExchanges$ = this.store.pipe(select(fromAuth.Selectors.CourseExchanges));
  }

  changeAccessRole(accessRoleId: string): void {
    this.store.dispatch(fromAuth.Actions.selectAccessRole({accessRoleId}));
    this.store.dispatch(fromAuth.Actions.navigateToDashboard({accessRoleId}));
  }
}
