import { CountriesResolver } from './countries.resolver';
import { AccreditationBodiesResolver } from './accreditationBodies.resolver';
import { RegionsResolver } from './regions.resolver';
import { InstitutionRolesResolver } from './institutionRoles.resolver';
import { CourseExchangeRolesResolver } from './courseExchangeRoles.resolver';
import { GendersResolver } from './genders.resolver';
import { CourseCategoriesResolver } from './courseCategories.resolver';
import { CourseLevelsResolver } from './courseLevels.resolver';
import { CourseSubCategoriesResolver } from './courseSubCategories.resolver';
import { CourseTagsResolver } from './courseTags.resolver';
import { ProviderPolicyTemplatesResolver } from './providerPolicyTemplates.resolver';
import { CourseExchangePolicyTemplatesResolver } from './courseExchangePolicyTemplates.resolver';
import { EnrollmentStatusesResolver } from './enrollmentStatuses.resolver';
import { LetterGradesResolver } from './letterGrades.resolver';
import { CourseExchangesResolver } from './courseExchanges.resolver';
import { InstitutionsResolver } from './institutions.resolver';
import { PermissionDisplaysResolver } from './permissionDisplays.resolver';
import { StudentTypesResolver } from './studentTypes.resolver';

export * from './accreditationBodies.resolver';
export * from "./countries.resolver";
export * from './courseCategories.resolver';
export * from './courseExchangePolicyTemplates.resolver';
export * from './courseLevels.resolver';
export * from './courseSubCategories.resolver';
export * from './courseTags.resolver';
export * from './courseExchangeRoles.resolver';
export * from './genders.resolver';
export * from './institutionRoles.resolver';
export * from './regions.resolver';
export * from './providerPolicyTemplates.resolver';
export * from './enrollmentStatuses.resolver';
export * from './letterGrades.resolver';
export * from './courseExchanges.resolver';
export * from './institutions.resolver';
export * from './permissionDisplays.resolver';
export * from './studentTypes.resolver';

export const RESOLVERS = [AccreditationBodiesResolver, CountriesResolver, CourseCategoriesResolver, CourseExchangesResolver,
                            CourseLevelsResolver, CourseSubCategoriesResolver, CourseTagsResolver, StudentTypesResolver,
                            CourseExchangeRolesResolver, GendersResolver, InstitutionRolesResolver, 
                            RegionsResolver,ProviderPolicyTemplatesResolver, CourseExchangePolicyTemplatesResolver,
                            EnrollmentStatusesResolver, LetterGradesResolver, InstitutionsResolver, PermissionDisplaysResolver];