import { createEffect, Actions, ofType } from "@ngrx/effects";
import { Injectable } from "@angular/core";
import { tap } from "rxjs/operators";
import * as fromCore from '@core/store';
import { PopupService } from '@core/services/popup.service';

@Injectable()
export class CoreEffects{
    constructor(private actions$: Actions, private popupService: PopupService){
    }

    handleResponse$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromCore.Actions.HandleResponse),
                tap((action)=> {
                    if(action.response.message!=""){
                        this.popupService.handleBaseResponse(action.response)
                    }
                })    
            );
    },{dispatch: false});


    showMessage$ = createEffect(() => {
        return this.actions$.pipe(
                ofType(fromCore.Actions.ShowMessage),
                tap((action)=> this.popupService.openSnackBar(action.message,'Ok'))    
            );
    },{dispatch: false});

}