import {NgModule} from '@angular/core';
import {StoreModule} from '@ngrx/store';
import {EffectsModule} from '@ngrx/effects';
import * as fromCalendar from '@shared/calendar/store';

@NgModule({
  imports: [
    StoreModule.forFeature('institution/calendar', fromCalendar.courseCalendarReducer),
    EffectsModule.forFeature([...fromCalendar.EFFECTS]),
  ]
})
export class CalendarStoreModule {
}
