import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {IAppState} from '../../../../store/state/app.state';
import {Location} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import {
  CourseDescriptionDialogComponent,
  DeSelectCourseDialogConfirmComponent, ICourseDescriptionDialogData} from '..';
import { MemberCourseListItem } from '@member/memberCourse/models';
import * as fromStore from '@member/memberCourse/store';
import * as fromMemberDashboard from '@member/dashboard/store';
import { CourseSessionDateItem } from '@shared/provider/models/courseSessionDateItem';
import { CreditHourType } from '@core/models';
import { FacultyCV } from '@shared/institution/models';
import * as fromFacultyCV from '@provider/facultyCV/store';


@Component({
  selector: 'member-courses-detail',
  templateUrl: './memberCourse-detail.component.html',
  styleUrls: ['./memberCourse-detail.component.scss'],
})
export class CourseDetailComponent implements OnInit, OnDestroy {

  pageSubTitle = '';
  pageTitle = 'Course Information';

  memberCourse$: Observable<MemberCourseListItem>;
  memberCourseSessions$ : Observable<CourseSessionDateItem[]>;
  facultyCVs$: Observable<FacultyCV[]>;
  isLoading = true;
  isSending = false;

  constructor(
    private store: Store<IAppState>,
    public dialog: MatDialog,
    public location: Location
  ) {
  }

  ngOnInit(): void {
    this.memberCourse$ = this.store.pipe(select(fromStore.Selectors.CurrentMemberCourseListItem));
    this.memberCourseSessions$ = this.store.pipe(select(fromStore.Selectors.MemberCourseSessions('')));
    this.facultyCVs$ = this.store.pipe(select(fromStore.Selectors.CurrentFacultyCVs));
  }

  ngOnDestroy(): void {
  }

  preSelectCourse(memberCourse: MemberCourseListItem): void {
    this.store.dispatch(fromStore.Actions.PreSelectSingleCourse({
      providerCourseId: memberCourse.providerCourseId
    }));
  }

  confirmDeselectCourse(memberCourse: MemberCourseListItem): void {
    const confirmDialogRef = this.dialog.open(DeSelectCourseDialogConfirmComponent);

    confirmDialogRef.afterClosed().subscribe(isConfirmed => {
      if (isConfirmed) {
        this.deSelectCourses(memberCourse);
      }
    });
  }

  deSelectCourses(memberCourse: MemberCourseListItem): void {
    const memberCourseIds = [memberCourse.memberCourseId];
    this.store.dispatch(fromStore.Actions.DeselectCourses({memberCourseIds}));
  }

  managementMemberCourseCodes(memberCourse: MemberCourseListItem): void{
    const providerCourseId = memberCourse.providerCourseId;
    const memberCourseId = memberCourse.memberCourseId;
    this.store.dispatch(fromStore.Actions.ManageMemberCourseCodes({providerCourseId, memberCourseId}));
  }

  goBack(): void {
    this.location.back();
  }

  goToMemberDashboard(): void {
    this.store.dispatch(fromMemberDashboard.Navigate.Dashboard({}))
  }

  onReadMore(memberCourse: MemberCourseListItem): void {
  
    this.dialog.open<
      CourseDescriptionDialogComponent,
      ICourseDescriptionDialogData
    >(CourseDescriptionDialogComponent, {
        data: {
          description: memberCourse.description,
          title: memberCourse.title
        }
    })
  }

  downloadFacultyCV(facultyCV: FacultyCV): void{
    this.store.dispatch(fromFacultyCV.Actions.DownloadFacultyCV({facultyCV}));
  }

  onSyllabus(memberCourse: MemberCourseListItem): void{
    const courseId = memberCourse.courseId;
    const syllabus = memberCourse.syllabus;
    this.store.dispatch(fromStore.Actions.DownloadCourseSyllabus({courseId, syllabus}));
  }

  getCreditHourType(creditHourType: CreditHourType): string{
    if(creditHourType == CreditHourType.SEM) return "SEM";
    if(creditHourType == CreditHourType.QR) return "QR";
    return "";
  }
}
