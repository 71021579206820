import {Component, OnDestroy, OnInit, ViewEncapsulation, ElementRef, ViewChild, ChangeDetectionStrategy, AfterViewInit} from '@angular/core';
import {Observable, Subscription, fromEvent} from 'rxjs';
import {MemberCourseListItem} from '../../models/memberCourseListItem.model';
import {fuseAnimations} from '../../../../../@fuse/animations';
import {Order, Sort} from '../../../../core/models';
import {Store, select} from '@ngrx/store';
import {IAppState} from '../../../../store/state/app.state';
import {MatSelectionListChange} from '@angular/material/list';
import {Location} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import {SingleFilter, Filter, SemesterFilter } from '@shared/common/models';
import DefaultOrderList from '@shared/common/helpers/DefaultOrderList';
import * as fromMemberCourses from '@member/memberCourse/store';
import * as fromMemberDashboard from '@member/dashboard/store';
import { map, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import * as fromEnrollment from '@member/enrollment/store';
import * as fromMember from '@member/store';
import * as fromInstitution from '@institution/store';
import * as fromAuth from '@auth/store';
import {ENewEnrollmentTypeFrom} from '@member/enrollment/constants/shared';
import { MatRadioChange } from '@angular/material/radio';
import { PermissionType } from '@auth/models/permissionType.enum';
import { AcademicYear } from '@shared/calendar/models';
import * as fromStudent from '@member/students/store';
@Component({
  selector: 'memberCourse-list',
  templateUrl: './memberCourse-list.component.html',
  styleUrls: ['./memberCourse-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: fuseAnimations,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MemberCoursesListComponent implements OnInit, OnDestroy, AfterViewInit {
  pageSubTitle = '';
  pageTitle = 'Courses';
  @ViewChild('filter') filter: ElementRef;

  academicYears$: Observable<AcademicYear[]>;
  currentAcademicYear$: Observable<AcademicYear>;
  currentAcademicYearId$: Observable<string>;
  semesterFilters$: Observable<SemesterFilter[]>;
  levelFilters$: Observable<Filter[]>;
  levelFilterCount$: Observable<number>;
  providerFilters$: Observable<Filter[]>;
  providerFilterCount$: Observable<number>;
  categoryFilters$: Observable<Filter[]>;
  categoryFilterCount$: Observable<number>;
  subCategoryFilters$: Observable<Filter[]>;
  subCategoryFilterCount$: Observable<number>;
  tagFilters$: Observable<Filter[]>;
  tagFilterCount$: Observable<number>;
  selectedFilters$: Observable<Filter[]>;
  memberCourses$: Observable<MemberCourseListItem[]>;
  presetFilterType$: Observable<SingleFilter>;
  selectedCourses$: Observable<MemberCourseListItem[]>;
  selectedFavouriteCourses$: Observable<MemberCourseListItem[]>;
  selectedNonFavouriteCourses$: Observable<MemberCourseListItem[]>;
  currentSort$: Observable<Sort>;
  currentOrder$: Observable<Order>;
  orders: Order[] = DefaultOrderList();
  sorts: Sort[] = fromMemberCourses.Sorts;
  presetFilterTypes: SingleFilter[] = fromMemberCourses.MemberCourseTypes;
  searchTextSubscription$: Subscription;
  isAllSelected$: Observable<boolean>
  isAllSelected: boolean;
  indeterminateSelected$: Observable<boolean>;
  selectedStatus$: Observable<string>;
  canCompareCourses$: Observable<boolean>;
  canCompareCourses: boolean;
  canExportToExcel$: Observable<boolean>;
  canEnrollStudents$: Observable<boolean>;
  IsMemberCoursesLoading$: Observable<boolean>;
  sorts$: Observable<Sort[]>;
  startDate$:Observable<Date>;
  endDate$:Observable<Date>;
  constructor(
    private store: Store<IAppState>,
    public dialog: MatDialog,
    public location: Location
  ) {
  }

  ngOnInit(): void {
    this.academicYears$ = this.store.pipe(select(fromInstitution.Selectors.AcademicYears));
    this.currentAcademicYear$ = this.store.pipe(select(fromInstitution.Selectors.CurrentAcademicYear));
    this.currentAcademicYearId$ = this.store.pipe(select(fromInstitution.Selectors.CurrentAcademicYearId));
    this.semesterFilters$ = this.store.pipe(select(fromMemberCourses.Selectors.SemesterFilters));
    this.levelFilters$ = this.store.pipe(select(fromMemberCourses.Selectors.CourseLevelFiltersForView));
    this.levelFilterCount$ = this.store.pipe(select(fromMemberCourses.Selectors.CourseLevelFilterCount));
    this.providerFilters$ = this.store.pipe(select(fromMemberCourses.Selectors.ProviderFilters));
    this.providerFilterCount$ = this.store.pipe(select(fromMemberCourses.Selectors.ProviderFilterCount));
    this.categoryFilters$ = this.store.pipe(select(fromMemberCourses.Selectors.CategoryFilters));
    this.categoryFilterCount$ = this.store.pipe(select(fromMemberCourses.Selectors.CategoryFilterCount));
    this.subCategoryFilters$ = this.store.pipe(select(fromMemberCourses.Selectors.SubCategoryFilters));
    this.subCategoryFilterCount$ = this.store.pipe(select(fromMemberCourses.Selectors.SubCategoryFilterCount));
    this.tagFilters$ = this.store.pipe(select(fromMemberCourses.Selectors.TagFilters));
    this.tagFilterCount$ = this.store.pipe(select(fromMemberCourses.Selectors.TagFilterCount));
    this.selectedFilters$ = this.store.pipe(select(fromMemberCourses.Selectors.SelectedFilters));
    this.memberCourses$ = this.store.pipe(select(fromMemberCourses.Selectors.FilteredMemberCourses));
    this.selectedCourses$ = this.store.pipe(select(fromMemberCourses.Selectors.SelectedMemberCourses));
    this.selectedFavouriteCourses$ = this.store.pipe(select(fromMemberCourses.Selectors.SelectedFavouritedCourses));
    this.selectedNonFavouriteCourses$ = this.store.pipe(select(fromMemberCourses.Selectors.SelectedNonFavouritedCourses));
    this.presetFilterType$ = this.store.pipe(select(fromMemberCourses.Selectors.PresetFilterType));
    this.currentOrder$ = this.store.pipe(select(fromMemberCourses.Selectors.CurrentOrder));
    this.currentSort$ = this.store.pipe(select(fromMemberCourses.Selectors.CurrentSort));
    this.isAllSelected$ = this.store.pipe(select(fromMemberCourses.Selectors.IsAllSelected));
    this.isAllSelected$.subscribe(result=>this.isAllSelected=result);
    this.indeterminateSelected$ = this.store.pipe(select(fromMemberCourses.Selectors.IndeterminateSelected));
    this.selectedStatus$ = this.store.pipe(select(fromMemberCourses.Selectors.SelectedStatus));
    this.sorts$ = this.store.pipe(select(fromMemberCourses.Selectors.Sorts));
    this.IsMemberCoursesLoading$ = this.store.pipe(select(fromMemberCourses.Selectors.IsMemberCoursesLoading));
    this.startDate$ = this.store.pipe(select(fromAuth.Selectors.StartDate));
    this.endDate$ = this.store.pipe(select(fromAuth.Selectors.EndDate));
    this.store.dispatch(fromMemberCourses.Actions.LoadSorts({sorts: this.sorts}));
    this.store.dispatch(fromMemberCourses.Actions.ChangeOrder({currentOrder: this.orders.find(x=>x.isDefault)}));
    this.store.dispatch(fromMemberCourses.Actions.ChangeSort({currentSort: this.sorts.find(x=>x.isDefault)}));
    this.store.dispatch(fromMemberCourses.Actions.ChangeSearchText({searchText:''}));
    this.store.dispatch(fromMemberCourses.Actions.ClearSelected());
    this.store.dispatch(fromStudent.Actions.ClearSelected());
    this.canExportToExcel$ = this.store.pipe(select(fromMemberCourses.Selectors.CanExportToExcel));
    this.canEnrollStudents$ = this.store.pipe(select(fromMember.Selectors.HasInstitutionPermission(PermissionType.ReadStudentInformation)));
  }
  ngOnDestroy(): void{
    this.searchTextSubscription$.unsubscribe();
  }
  ngAfterViewInit(): void{
    this.setupTextSearch();
  }

  trackById(index:number,item: any): string {
    return item.id;
  }
  goToMemberDashboard() : void{
    this.store.dispatch(fromMemberDashboard.Navigate.Dashboard({}))
  }
  onChangePresetFilter(change: MatRadioChange){
    const presetFilterType = change.value;
    this.store.dispatch(fromMemberCourses.Actions.ChangePresetFilterType({presetFilterType}));
  }
  onToggleSemesterFilter(event:MatSelectionListChange): void{
    const id = event.options[0].value
    this.store.dispatch(fromMemberCourses.Actions.ToggleSemesterFilter({id}));
  }
  onToggleCourseLevelFilter(event:MatSelectionListChange): void{
    const id = event.options[0].value
    this.store.dispatch(fromMemberCourses.Actions.ToggleCourseLevelFilter({id}));
  }
  onToggleProviderFilter(event:MatSelectionListChange): void{
    const id = event.options[0].value
    this.store.dispatch(fromMemberCourses.Actions.ToggleProviderFilter({id}));
  }
  onToggleCategoryFilter(event:MatSelectionListChange): void{
    const id = event.options[0].value
    this.store.dispatch(fromMemberCourses.Actions.ToggleCategoryFilter({id}));
  }
  onToggleSubCategoryFilter(event:MatSelectionListChange): void{
    const id = event.options[0].value
    this.store.dispatch(fromMemberCourses.Actions.ToggleSubCategoryFilter({id}));
  }
  onToggleTagFilter(event:MatSelectionListChange): void{
    const id = event.options[0].value
    this.store.dispatch(fromMemberCourses.Actions.ToggleTagFilter({id}));
  }
  onToggleMemberCourse(id:string): void{
    this.store.dispatch(fromMemberCourses.Actions.ToggleMemberCourse({id}));
  }
  setupTextSearch(): void {
    this.searchTextSubscription$ = fromEvent(this.filter.nativeElement, 'keyup').pipe(
      map((event: any) => event.target.value),
      debounceTime(300),
      distinctUntilChanged(),
      tap((searchText: string) =>
        this.store.dispatch(fromMemberCourses.Actions.ChangeSearchText({searchText})))
    ).subscribe()
  }
  changeOrderBy(currentOrder:Order): void {
    this.store.dispatch(fromMemberCourses.Actions.ChangeOrder({currentOrder}));
  }
  changeSort(currentSort: Sort): void {
    this.store.dispatch(fromMemberCourses.Actions.ChangeSort({currentSort}));
  }
  masterToggle(): void {
    this.store.dispatch(fromMemberCourses.Actions.MasterToggle({}));
  }
  removeFilter(filter: Filter) {
    this.store.dispatch(fromMemberCourses.Actions.RemoveFilter({filter}));
  }
  goToMemberCourseDetail(memberCourse: MemberCourseListItem): void{
    this.store.dispatch(fromMemberCourses.Navigate.MemberCourseDetail({currentMemberCourseListItem: memberCourse}));
  }
  exportToExcel(): void{
    this.store.dispatch(fromMemberCourses.Actions.ExportToExcel());
  }
  compareCourses(): void{
    this.store.dispatch(fromMemberCourses.Navigate.CompareCourses());
  }
  preselectCourses(): void{
    this.store.dispatch(fromMemberCourses.Actions.PreSelectCourses({}))
  }
  deselectCourses(): void{
    this.store.dispatch(fromMemberCourses.Actions.DeselectCourses({}));
  }
  goBack(): void{
    this.location.back();
  }

  enrollStudents(): void {
    this.store.dispatch(fromEnrollment.Actions.EnrollStudent({fromType: ENewEnrollmentTypeFrom.fromCourses}));
  }

  enrollSingleCourse(id: string): void{
    this.store.dispatch(fromMemberCourses.Actions.MasterToggle({selected:false}));
    this.onToggleMemberCourse(id);
    this.enrollStudents();
  }

  changeStartDate(e: any){
    if(e.target.value===null)return;
    const startDate =e.target.value.toDate();
    this.store.dispatch(fromAuth.Actions.ChangeStartDate({startDate}));
    this.store.dispatch(fromMemberCourses.Actions.LoadMemberCourseListItems({startDate}));
  }

  changeEndDate(e: any){
    if(e.target.value===null) return;
    const endDate =e.target.value.toDate();
    this.store.dispatch(fromAuth.Actions.ChangeEndDate({endDate}));
    this.store.dispatch(fromMemberCourses.Actions.LoadMemberCourseListItems({endDate}));
  }
}
