import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromCourseExchangeDashboard from '@courseExchange/dashboard/store';
@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature('courseExchange/dashboard', fromCourseExchangeDashboard.courseExchangeDashboardReducer),
    EffectsModule.forFeature([...fromCourseExchangeDashboard.EFFECTS])
  ],
  providers: [...fromCourseExchangeDashboard.RESOLVERS]
})
export class CourseExchangeDashboardStoreModule { }
