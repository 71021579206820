import { UserSummary } from '../models/userSummary.model'
import { ViewContext } from '../models/viewContext.enum'
import { PermissionType } from '../models/permissionType.enum';
import isSuperAdmin from './isSuperAdmin';

export default function (user: UserSummary, institutionId: string, permissionType: PermissionType): boolean {
    if(!user) return false;
    if(isSuperAdmin(user)) return true;
    if(!institutionId) institutionId = user.institutionId;
    var accessRole = user.accessRoles.find(x=>x.institutionId===institutionId && x.viewContext===ViewContext.Institution);
    if(!accessRole) return false;
    if(!accessRole.permissions) return false;
    return accessRole.permissions.find(x=>x===permissionType) !== undefined;
}