import {createAction, props} from '@ngrx/store';
import { SuperAdminDashboardViewModel } from '@admin/dashboard/models';

export const LoadDashboardData = createAction(
  '[Super Admin Dashboard Effects] - Load Dashboard Data'
)
export const LoadDashboardDataSuccess = createAction(
  '[Super Admin Dashboard Resolver] - Load Dashboard Data Success',
  props<{
    data: SuperAdminDashboardViewModel;
  }>()
);

export const LoadDashboardDataError = createAction(
  '[Super Admin Dashboard Resolver] - Load Dashboard Data Error',
  props<{
    message: string
  }>()
);