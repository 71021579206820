import { createAction, props } from '@ngrx/store';
import { ENewEnrollmentTypeFrom } from '@member/enrollment/constants/shared';
import { EnrollmentListItem, EnrollmentListItemForView } from '@member/enrollment/models/enrollmentListItem.viewModel';
import { Sort, Order, BaseListItem } from '@core/models';
import { Filter } from '@shared/common/models';
import { AcademicYear } from '@shared/calendar/models';
import { ChangeEnrollmentStatusBulkCommand } from '@shared/member/models/commands/changeEnrollmentStatus.command';
import { EnrollmentStatus } from '@core/referenceData';

export const LoadActiveEnrollmentsSuccess = createAction(
    '[Enrollments Effects] - Load Active Enrollments Success',
    props<{
        enrollments: EnrollmentListItemForView[]
    }>()
)

export const LoadActiveEnrollmentsError = createAction(
    '[Enrollments Effects] - Load Active Enrollments Error',
    props<{
        message: string
    }>()
)

export const ExportToExcel = createAction(
    '[Member Enrollment list] - Export to Excel',
    props<{
        enrollments?: EnrollmentListItem[]
    }>()
)

export const EnrollStudent = createAction(
    '[Enroll Student Button] - Enroll Students',
    props<{
        fromType: ENewEnrollmentTypeFrom
    }>()
)

export const SubmitEnrollments = createAction(
    '[Enrollment List] - Submit Enrollments'
)

export const CreateEnrollments = createAction(
    '[Enrollment List] - Create Ernollment',
    props<{
        providerCourseSessionIds: string[],
        studentIds: string[]
    }>()
)

export const CreateEnrollmentSuccess = createAction(
    '[Enrollment Effects] - Create Enrollment Success'
)

export const EnrollmentListItemAdded = createAction(
    '[Member Realtime] - Enrollment List Item Added',
    props<{
        enrollment: EnrollmentListItem
    }>()
)

export const CreateEnrollmentError = createAction(
    '[Enrollment Effects] - Create Enrollment Error',
    props<{
        message: string
    }>()
)

export const EditStatus = createAction(
    '[Enrollment List] - Edit Status',
    props<{
        enrollmentId: string,
        requestedStatus: BaseListItem
    }>()
)

export const SubmitStatusChanges = createAction(
    '[Enrollment List] - Submit Status Changes'
)

export const ChangeStatuses = createAction(
    '[Member Enrollment Effects] - Changes Statuses',
    props<{
        command: ChangeEnrollmentStatusBulkCommand,
        enrollmentStatuses: EnrollmentStatus[]
    }>()
)
export const EnrollmentListItemUpdated = createAction(
    '[Enrollment Realtime] - Member EnrollmentListItem Updated',
    props<{
        enrollment: EnrollmentListItem
    }>()
)
export const MasterToggled = createAction(
    '[Enrollment] - Member Master Toggled',
    props<{
        enrollments: EnrollmentListItemForView[]
    }>()
)
export const SubmitStatusChangesError = createAction(
    '[Enrollment Effects] - Submit Status Changes Error',
    props<{
        message: string
    }>()
)

export const LoadOrders = createAction(
    '[Enrollment List] - Load Orders',
    props<{
        orders: Order[]
    }>()
)
export const ChangeOrder = createAction(
    '[Enrollment List] - Change Order',
    props<{
        currentOrder: Order
    }>()
)
export const LoadSorts = createAction(
    '[Enrollment List] - Load Sorts',
    props<{
        sorts: Sort[]
    }>()
)
export const ChangeSort = createAction(
    '[Enrollment List] - Change Sort',
    props<{
        currentSort: Sort
    }>()
)
export const ChangeSearchText = createAction(
    '[Enrollment List] - Change Search Text',
    props<{
        searchText: string
    }>()
)
export const MasterToggle = createAction(
    '[Enrollment List] - Master Toggle'
)
export const ToggleEnrollment = createAction(
    '[Enrollment List] - Toggle Enrollment',
    props<{
        enrollmentId: string
    }>()
)
export const ToggleSemester = createAction(
    '[Member Enrollment List] - Toggle Enrollment',
    props<{
        semesterId: string
    }>()
)
export const ToggleStatusFilter = createAction(
    '[Enrollment List] - Toggle Status Filter',
    props<{
        id: string
    }>()
)
export const RemoveFilter = createAction(
    '[Enrollment List] - Remove Filter',
    props<{
        filter: Filter
    }>()
)

export const LoadEnrollmentByDateRange = createAction(
    '[Member Enrollment Effects] - Load Enrollments By Date Range',
    props<{
        memberId?: string,
        startDate?: Date,
        endDate?: Date
    }>()
)

export const LoadProviderFilters = createAction(
    '[Member Enrollment List] - Load Provider Filters',
    props<{
      providerFilters: Filter[]
    }>()
  )
  export const ToggleProviderFilter = createAction(
    '[Member Enrollment List] - Toggle Provider Filter',
    props<{
        id: string
    }>()
  )
  export const LoadTagFilters = createAction(
    '[Member Course Exchange Summary Resolver] - Load Tag Filters',
    props<{
      tagFilters: Filter[]
    }>()
  )
  export const ToggleTagFilter = createAction(
    '[Member Enrollment List] - Toggle Tag Filter',
    props<{
        id: string
    }>()
  )
  export const LoadCategoryFilters = createAction(
    '[Member Course Exchange Summary Resolver] - Load Category Filters',
    props<{
        categoryFilters: Filter[]
    }>()
  )
  
  export const LoadSubCategoryFilters = createAction(
    '[Member Course Exchange Summary Resolver] - Load Sub Category Filters',
    props<{
        subCategoryFilters: Filter[]
    }>()
  )

  export const ToggleCategoryFilter = createAction(
    '[Member Enrollment List] - Toggle Category Filter',
    props<{
        id: string
    }>()
  )
  
  
  export const ToggleSubCategoryFilter = createAction(
    '[Member Enrollment List] - Toggle Sub Category Filter',
    props<{
        id: string
    }>()
  )

  export const NoOp = createAction(
    '[Member Enrollment List] - No Op'
  )
