import { InstitutionState } from "@institution/store"
import { AcademicYear } from "@shared/calendar/models"

export default (academicYears:AcademicYear[], academicYearId: string): AcademicYear => {
  var result = undefined;
  academicYears.forEach((academicYear)=>{
    if(academicYear.id===academicYearId){
      result = academicYear;
    }
  })
  return result;  
}