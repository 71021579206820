import {Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {CourseSessionDateItem} from '../../../../shared/provider/models/courseSessionDateItem';
import {compareFnToSortDates} from '../../../../helpers/compare-fn-to-sort-dates';
import * as moment from 'moment';

@Component({
  selector: 'app-course-sessions',
  templateUrl: './course-sessions.component.html',
  styleUrls: ['./course-sessions.component.scss'],
})
export class CourseSessionsComponent{
  @Input() sessions: CourseSessionDateItem[];
  @Input() showCode: boolean = false;
  @Input() showSeats: boolean = true;
  @Input() showMemberFee: boolean = true;
  constructor() {
  }

  getStartDate(item: CourseSessionDateItem): string{
    var startDate = new Date(item.startDate2.year, item.startDate2.month-1, item.startDate2.day);
    return moment(startDate).format("MM/DD/YYYY");
  }

  getEndDate(item: CourseSessionDateItem): string{
    var endDate = new Date(item.endDate2.year, item.endDate2.month-1, item.endDate2.day);
    return moment(endDate).format("MM/DD/YYYY");
  }
}
