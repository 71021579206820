import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'elb-page-list-main-body',
  templateUrl: './page-list-main-body.component.html',
  styleUrls: ['./page-list-main-body.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PageListMainBodyComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }
}
