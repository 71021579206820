import {EnrollmentShortStudentInfoComponent} from '@provider/enrollment/components/enrollment-short-student-info/enrollment-short-student-info.component';
import {EnrollmentShortCourseInfoComponent} from '@provider/enrollment/components/enrollment-short-course-info/enrollment-short-course-info.component';
import {EnrollmentShortDetailsInfoComponent} from '@provider/enrollment/components/enrollment-short-details-info/enrollment-short-details-info.component';
import {EnrollmentStudentsInfoComponent} from '@provider/enrollment/components/enrollment-students-info/enrollment-students-info.component';
import {EnrollmentListComponent} from '@provider/enrollment/components/enrollment-list/enrollment-list.component';
import { UpdateEnrollmentStatusDialogComponent } from '@provider/enrollment/components/update-enrollment-status-dialog/update-enrollment-status-dialog.component';
import {StudentDetailComponent} from '@provider/enrollment/components/student-detail/student-detail.component';
import { UpdateEnrollmentLetterGradeDialogComponent } from '@provider/enrollment/components/update-enrollment-letter-grade-dialog/update-enrollment-letter-grade-dialog.component';

export * from '@provider/enrollment/components/enrollment-short-student-info/enrollment-short-student-info.component';
export * from '@provider/enrollment/components/enrollment-short-course-info/enrollment-short-course-info.component';
export * from '@provider/enrollment/components/enrollment-short-details-info/enrollment-short-details-info.component';
export * from '@provider/enrollment/components/enrollment-students-info/enrollment-students-info.component';
export * from '@provider/enrollment/components/enrollment-list/enrollment-list.component';
export * from '@provider/enrollment/components/student-detail/student-detail.component';

export const COMPONENTS = [
  EnrollmentShortStudentInfoComponent,
  EnrollmentShortCourseInfoComponent,
  EnrollmentShortDetailsInfoComponent,
  EnrollmentStudentsInfoComponent,
  EnrollmentListComponent,
  UpdateEnrollmentStatusDialogComponent,
  UpdateEnrollmentLetterGradeDialogComponent,
  StudentDetailComponent
];

export const ENTRY_COMPONENTS = [
  UpdateEnrollmentStatusDialogComponent,
  UpdateEnrollmentLetterGradeDialogComponent
];
