import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Observable} from 'rxjs';
import {AuthenticationService} from 'app/authentication/services/authentication.service';
import {IAppState} from 'app/store/state/app.state';
import {Store, select} from '@ngrx/store';
import {
  isLoggedIn,
} from 'app/authentication/store/auth.selectors';
import {AccessRole} from '@auth/models/accessRole';

@Component({
  selector: 'toolbar-not-authenticated',
  templateUrl: './toolbar-not-authenticated.component.html',
  styleUrls: ['./toolbar-not-authenticated.component.scss', '../styles/common-toolbar.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ToolbarNotAuthenticatedComponent implements OnInit {
  @Input() currentAccessRole: AccessRole;

  isAuthenticated$: Observable<boolean>;

  constructor(
    private authorizeService: AuthenticationService,
    private store: Store<IAppState>
  ) {
  }

  ngOnInit(): void {
    this.isAuthenticated$ = this.store.pipe(select(isLoggedIn));
  }

  logout(): void {
    this.authorizeService.logout();
  }
}
