import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { Store, select } from "@ngrx/store";
import { first, finalize, filter, map, concatMap, withLatestFrom } from "rxjs/operators";
import { IAppState } from 'app/store/state/app.state';
import * as fromUsers from '@shared/user/store';
import { UserListItem } from '@shared/user/models';
import { getInstitutionIdFromRoute } from 'app/helpers/routeParamHelper';
@Injectable()
export class UsersResolver implements Resolve<UserListItem[]>{
    isLoading = false;
    constructor(private store: Store<IAppState>){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
        const institutionId = getInstitutionIdFromRoute(route);
        return this.store 
            .pipe(
                select(fromUsers.Selectors.UsersForResolver),
                map(({currentInstitutionId, users}) => {
                    var result = currentInstitutionId === institutionId;
                    if(result) this.store.dispatch(fromUsers.Actions.LoadUsersSuccess({users}));
                    return result;
                }),
                map((areUsersLoaded)=>{
                    if(!this.isLoading && !areUsersLoaded){
                        this.isLoading = true;
                        this.store.dispatch(fromUsers.Actions.LoadUsers({institutionId}));
                    }
                    return true;
                }),
                filter(usersLoaded => usersLoaded),
                first(),
                finalize(()=>this.isLoading=false)
            )
    }
}