<div class="new-enrollment-list-of-selected">
  <ngx-datatable
    class="material"
    [rows]="sortedList$ | async"
    [columnMode]="ColumnMode.standard"
    [headerHeight]="50"
    [footerHeight]="0"
    rowHeight="50"
  >
    <ng-container *ngFor="let tableItem of filledTableScheme$ | async">
      <ngx-datatable-column [name]="tableItem.title" [sortable]="false">

        <ng-template ngx-datatable-header-template>
          <div class="sort-cell" (click)="tableItem.isDate ? sortByDate(tableItem) : sortByText(tableItem)">
            <span>{{ tableItem.title }}</span>
            <mat-icon *ngIf="tableItem.sortFlag">arrow_downward</mat-icon>
            <mat-icon *ngIf="!tableItem.sortFlag">arrow_upward</mat-icon>
          </div>
        </ng-template>

        <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
          <ng-container *ngIf="!tableItem.isDate">{{extractValue(row, tableItem.accessKey)}}</ng-container>
          <ng-container *ngIf="tableItem.isDate">{{extractValue(row, tableItem.accessKey) | date: 'MM/dd/yyyy'}}</ng-container>
        </ng-template>

      </ngx-datatable-column>
    </ng-container>

    <ngx-datatable-column [name]="'Remove'">

      <ng-template let-column="column" ngx-datatable-header-template></ng-template>

      <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
        <button mat-icon-button (click)="onRemove(row)">
          <mat-icon>close</mat-icon>
        </button>
      </ng-template>

    </ngx-datatable-column>
  </ngx-datatable>
</div>
