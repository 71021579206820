    export enum PermissionType
    {
        CreateCourseExchange = 'CreateCourseExchange',
        ReadCourseExchange = 'ReadCourseExchange',
        UpdateCourseExchange = 'UpdateCourseExchange',
        DeleteCourseExchange = 'DeleteCourseExchange',
        CreateSuperAdmin = 'CreateSuperAdmin',
        ReadSuperAdmin = 'ReadSuperAdmin',
        UpdateSuperAdmin = 'UpdateSuperAdmin',
        DeleteSuperAdmin = 'DeleteSuperAdmin',
        CreateCourseExchangeAdmin = 'CreateCourseExchangeAdmin',
        ReadCourseExchangeAdmin = 'ReadCourseExchangeAdmin',
        UpdateCourseExchangeAdmin = 'UpdateCourseExchangeAdmin',
        DeleteCourseExchangeAdmin = 'DeleteCourseExchangeAdmin',
        ViewSuperAdminDashboard = 'ViewSuperAdminDashboard',

        CreateInstitution = 'CreateInstitution',
        ReadInstitution = 'ReadInstitution',
        UpdateInstitution = 'UpdateInstitution',
        DeleteInstitution = 'DeleteInstitution',
        CreateInstitutionAdmin = 'CreateInstitutionAdmin',
        ReadInstitutionAdmin = 'ReadInstitutionAdmin',
        UpdateInstitutionAdmin = 'UpdateInstitutionAdmin',
        DeleteInstitutionAdmin = 'DeleteInstitutionAdmin',
        SetCourseExchangeCalendar = 'SetCourseExchangeCalendar',
        SetCourseExchangeFees = 'SetCourseExchangeFees',
        CreateCourseCategory = 'CreateCourseCategory',
        ReadCourseCategory = 'ReadCourseCategory',
        UpdateCourseCategory = 'UpdateCourseCategory',
        DeleteCourseCategory = 'DeleteCourseCategory',
        CreateCourseExchangePolicy = 'CreateCourseExchangePolicy',
        ReadCourseExchangePolicy = 'ReadCourseExchangePolicy',
        UpdateCourseExchangePolicy = 'UpdateCourseExchangePolicy',
        DeleteCourseExchangePolicy = 'DeleteCourseExchangePolicy',
        ViewCourseExchangeAdminDashboard = 'ViewCourseExchangeAdminDashboard',
        ManageCourseExchangeUserPermissions = 'ManageCourseExchangeUserPermissions',
        CreateAcademicYear = 'CreateAcademicYear',
        ReadAcademicYear = 'ReadAcademicYear',
        UpdateAcademicYear = 'UpdateAcademicYear',
        DeleteAcademicYear = 'DeleteAcademicYear',
        CanApproveProviders = 'CanApproveProviders',
        CanApproveMembers = 'CanApproveMembers',

        CanModifyInstitutionalPolicy = 'CanModifyInstitutionalPolicy',
        CreateFee = 'CreateFee',
        ReadFee = 'ReadFee',
        UpdateFee = 'UpdateFee',
        DeleteFee = 'DeleteFee',
        CanReadProviderEnrollmentRequests = 'CanReadProviderEnrollmentRequests',
        CanApproveEnrollmentRequests = 'CanApproveEnrollmentRequests',
        CanWaitlistEnrollmentRequest = 'CanWaitlistEnrollmentRequest',
        CanMarkEnrollmentCourseFull = 'CanMarkEnrollmentCourseFull',
        CanApproveEnrollmentRequestDrop = 'CanApproveEnrollmentRequestDrop',
        CanApproveEnrollmentRequestWithdrawal = 'CanApproveEnrollmentRequestWithdrawal',
        CanWaiveEnrollmentRequestWithdrawalFee = 'CanWaiveEnrollmentRequestWithdrawalFee',
        ViewProviderCourseExchangeReports = 'ViewProviderCourseExchangeReports',
        ViewProviderCourseExchangePolicies = 'ViewProviderCourseExchangePolicies',
        CanModifyCourseSchedule = 'CanModifyCourseSchedule',
        CreateFinalGrade = 'CreateFinalGrade',
        ReadFinalGrade = 'ReadFinalGrade',
        UpdateFinalGrade = 'UpdateFinalGrade',
        CanResolveGradeDispute = 'CanResolveGradeDispute',
        CanApproveInvoices = 'CanApproveInvoices',
        CanApproveDisbursements = 'CanApproveDisbursements',
        CanResolveInvoiceDispute = 'CanResolveInvoiceDispute',
        CanResolveDisbursementDispute = 'CanResolveDisbursementDispute',
        ViewProviderDashboard = 'ViewProviderDashboard',
        ViewProviderBilling = 'ViewProviderBilling',

        CanSubmitEnrollmentRequest = 'CanSubmitEnrollmentRequest',
        CanRequestDropEnrollmentRequest = 'CanRequestDropEnrollmentRequest',
        CanRequestWidthdrawalEnrollmentRequest = 'CanRequestWidthdrawalEnrollmentRequest',
        ReadInstitutionEnrollmentRequest = 'ReadInstitutionEnrollmentRequest',
        ViewMemberCourseExchangeReports = 'ViewMemberCourseExchangeReports',
        ViewMemberCourseExchangePolicies = 'ViewMemberCourseExchangePolicies',
        ReadApprovedCourse = 'ReadApprovedCourse',
        SelectApprovedCourse = 'SelectApprovedCourse',
        CanViewCourseExchangeFees = 'CanViewCourseExchangeFees',
        CanSubmitGradeDispute = 'CanSubmitGradeDispute',
        CanViewInvoiceReports = 'CanViewInvoiceReports',
        CanViewDisbursementReports = 'CanViewDisbursementReports',
        CanSubmitInvoiceDispute = 'CanSubmitInvoiceDispute',
        ViewMemberDashboard = 'ViewMemberDashboard',
        ViewMemberBilling = 'ViewMemberBilling',

        CreateUser = 'CreateUser',
        ReadUser = 'ReadUser',
        UpdateUser = 'UpdateUser',
        DeleteUser = 'DeleteUser',
        CreateInstitutionalPolicy = 'CreateInstitutionalPolicy',
        ReadInstitutionalPolicy = 'ReadInstitutionalPolicy',
        UpdateInstitutionalPolicy = 'UpdateInstitutionalPolicy',
        DeleteInstitutionalPolicy = 'DeleteInstitutionalPolicy',
        CreateCalendar = 'CreateCalendar',
        ReadCalendar = 'ReadCalendar',
        UpdateCalendar = 'UpdateCalendar',
        DeleteCalendar = 'DeleteCalendar',
        CreateCourse = 'CreateCourse',
        ReadCourse = 'ReadCourse',
        UpdateCourse = 'UpdateCourse',
        DeleteCourse = 'DeleteCourse',
        CreateSchedule = 'CreateSchedule',
        ReadSchedule = 'ReadSchedule',
        UpdateSchedule = 'UpdateSchedule',
        DeleteSchedule = 'DeleteSchedule',
        CreateStudentInformation = 'CreateStudentInformation',
        ReadStudentInformation = 'ReadStudentInformation',
        UpdateStudentInformation = 'UpdateStudentInformation',
        DeleteStudentInformation = 'DeleteStudentInformation',
        CanEditInstitutionProfile = 'CanEditInstitutionProfile',
        CanViewInstitutionProfile = 'CanViewInstitutionProfile',
        ManageInstitutionBankingInformation = 'ManageInstitutionBankingInformation',
        ViewInstitutionDashboard = 'ViewInstitutionDashboard',
        CanActivateUser = 'CanActivateUser',
        CanDeactivateUser = 'CanDeactivateUser',
        ReadCourseExchangeUsers = 'ReadCourseExchangeUsers',
        ManageCourseExchangeBankingInformation = 'ManageCourseExchangeBankingInformation',
        ReadCourseExchangeContacts = 'ReadCourseExchangeContacts',
        MaintainCourseExchangeCourses='MaintainCourseExchangeCourses',

        Impersonate = 'Impersonate',
        ImpersonateKeepOwnPermissions = 'ImpersonateKeepOwnPermissions',

        AccessAll = 'AccessAll'
    }
