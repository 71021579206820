import {Injectable} from "@angular/core";
import {Store} from '@ngrx/store';
import {IAppState} from 'app/store/state/app.state';
import * as fromProviderBilling from '@provider/billing/store';
import { RealTimeService } from "@core/services/realTime.service";
import { HubConnection } from "@microsoft/signalr";
import { ProviderMonthlyPayoutStatement } from "@provider/billing/models";

@Injectable()
export class ProviderBillingRealtime {
  constructor(private store: Store<IAppState>, private realTimeService: RealTimeService) {
    this.initSignalRMessages();
}

private initSignalRMessages() {
    this.realTimeService.hubConnection$.subscribe(hubConnection => {
            if (!hubConnection) return;
            this.providerMonthlyPayoutStatementUpdated(hubConnection);
            this.providerMonthlyPayoutStatementAdded(hubConnection);
    })
}
private providerMonthlyPayoutStatementUpdated(hubConnection: HubConnection) {
    hubConnection.on('ProviderMonthlyPayoutStatementUpdated', (providerMonthlyPayoutStatement: ProviderMonthlyPayoutStatement) => {
            this.store.dispatch(fromProviderBilling.Actions.ProviderMonthlyPayoutStatementUpdated({ providerMonthlyPayoutStatement }));
    });
}private providerMonthlyPayoutStatementAdded(hubConnection: HubConnection) {
  hubConnection.on('ProviderMonthlyPayoutStatementAdded', (providerMonthlyPayoutStatement: ProviderMonthlyPayoutStatement) => {
          this.store.dispatch(fromProviderBilling.Actions.ProviderMonthlyPayoutStatementAdded({ providerMonthlyPayoutStatement }));
  });
}
}
