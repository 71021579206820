import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { BaseResponse } from 'app/core/models';
import { Provider } from '../models';
import { AcademicYear, Session } from 'app/shared/calendar/models';
import { AddSessionCommand, EditSessionCommand } from 'app/shared/calendar/commands';
import { ProviderPolicy, Course } from 'app/shared/institution/models';
import { Institution } from 'app/admin/institution';
import { map } from 'rxjs/operators';
import { PopupService } from 'app/core/services/popup.service';
import { saveAs } from 'file-saver';
import { CourseCalendarViewModel } from '../models/courseCalendar.viewModel';
import { CopyCourseCalendarCommand, AddNewFacultyCVToCourseCommand, AddExistingFacultyCVCommand, UpdateSyllabusCommand, RemoveFacultyCVFromCourseCommand } from '../commands';
import { ElbertFile } from 'app/core/models/elbertFile.model';
import { CourseExchangeSummary } from '@shared/courseExchange/models';
import { EnrollmentListItem } from '@member/enrollment';
import { ChangeEnrollmentStatusBulkCommand } from '@shared/member/models/commands/changeEnrollmentStatus.command';
import { ChangeLetterGradeBulkCommand } from '@shared/member/models/commands/changeLetterGrade.command';
import { ProviderMonthlyPayoutStatement } from '@provider/billing/models';
import { ConfigService } from '@core/services/config.service';
import { ProviderDashboardViewModel } from '@provider/dashboard/models';
import { MonthlyStatement } from '@core/models/monthlyStatement.viewModel';
import { EnrollmentMigrationCommand } from '@shared/member/models/commands/enrollmentMigrationCommand';
import * as fromCore from '@core/store';
@Injectable()
export class ProviderService {
  private apiUrl:string='';
  private migrationUrl:string='';
  constructor(private http: HttpClient, private popupService: PopupService, private config:ConfigService) {
    this.config.apiUrl$.subscribe(result=>{
      this.apiUrl=`${result}/provider`;
      this.migrationUrl=`${result}/migrationProvider`;
    });
  }

  public getProviderById(providerId: string): Observable<Provider>{
    return this.http.get<Provider>(`${this.apiUrl}/${providerId}`);
  }
  public getInstitution(providerId: string): Observable<Institution>{
    return this.http.get<Institution>(`${this.apiUrl}/${providerId}/institution`)
  }
  public getCourseExchangeSummary(providerId: string): Observable<CourseExchangeSummary>{
    return this.http.get<CourseExchangeSummary>(`${this.apiUrl}/${providerId}/courseExchange`);
  }
  public getAcademicYears(providerId: string): Observable<AcademicYear[]>{
    return this.http.get<AcademicYear[]>(`${this.apiUrl}/${providerId}`);
  }
  public addSession(command: AddSessionCommand): Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.apiUrl}/session`,command);
  }
  public editSession(command: EditSessionCommand): Observable<BaseResponse>{
    return this.http.put<BaseResponse>(`${this.apiUrl}/session`,command);
  }
  public getFutureSessions(providerId: string): Observable<Session[]>{
    return this.http.get<Session[]>(`${this.apiUrl}/session/future/${providerId}`);
  }
  public getSessions(providerId: string, semesterId: string): Observable<Session[]>{
    return this.http.get<Session[]>(`${this.apiUrl}/session/${providerId}/${semesterId}`);
  }
  public getProviderPolicies(institutionId: string): Observable<ProviderPolicy[]>{
    return this.http.get<ProviderPolicy[]>(`${this.apiUrl}/${institutionId}/providerPolicies`);
  }
  public uploadProviderPolicy(institutionId: string, providerPolicyId: string, policyFile: File):Observable<BaseResponse>{
    const formData = new FormData();
    formData.append('policyFile', policyFile, policyFile.name);
    formData.append('providerPolicyId', providerPolicyId);
    return this.http.post<BaseResponse>(`${this.apiUrl}/${institutionId}/providerPolicy`, formData);
  }
  public completeProviderSetup(providerId: string): Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.apiUrl}/${providerId}/completeProviderSetup`,{providerId});
  }

  public downloadProviderPolicy(providerPolicy: ProviderPolicy) {    
    return this.http.get(`${this.apiUrl}/providerPolicy/download/${providerPolicy.id}`, { responseType: "blob" })  
      .subscribe((result: any) => {  
        if (result.type != 'text/plain') {  
          var blob = new Blob([result]);  
            
          saveAs(blob, providerPolicy.policyFile.fileName);  
        }  
      }  
      );  
  }  

  public getCourseCalendar(providerId: string, academicYearId: string, semesterId: string, providerCourseIds: Array<string>):Observable<CourseCalendarViewModel>{
    var joinedProviderCourseIds = providerCourseIds.join(",");
    if(joinedProviderCourseIds.trim().length===0){
      return this.http.get<CourseCalendarViewModel>(`${this.apiUrl}/${providerId}/courseCalendar/${academicYearId}/${semesterId}`);
    }else{
      return this.http.get<CourseCalendarViewModel>(`${this.apiUrl}/${providerId}/courseCalendar/${academicYearId}/${semesterId}/${joinedProviderCourseIds}`);
    }
  }
  public saveCourseCalendar(viewModel: CourseCalendarViewModel): Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.apiUrl}/${viewModel.providerId}/courseCalendar`,{viewModel});
  }

  public postCourseCalendar(viewModel: CourseCalendarViewModel): Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.apiUrl}/${viewModel.providerId}/courseCalendar/post`,{viewModel});
  } 
  
  public copyCourseCalendar(command: CopyCourseCalendarCommand): Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.apiUrl}/${command.providerId}/courseCalendar/copy`,command);
  }

  public addNewFacultyCVToCourse(institutionId: string, command: AddNewFacultyCVToCourseCommand): Observable<BaseResponse>{
    const formData = new FormData();
    formData.append('file', command.file, command.file.name);
    formData.append('institutionId', institutionId);
    formData.append('courseId', command.courseId);
    formData.append('description', command.description);
    return this.http.post<BaseResponse>(`${this.apiUrl}/${institutionId}/course/facultyCV/new`,formData);
  }

  public addExistingFacultyCVToCourse(institutionId: string, command: AddExistingFacultyCVCommand): Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.apiUrl}/${institutionId}/course/facultyCV/existing`,command);
  }

  public removeFacultyCVFromCourse(institutionId: string, command: RemoveFacultyCVFromCourseCommand): Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.apiUrl}/${institutionId}/course/facultyCV/remove`,command);
  }

  public updateSyllabus(institutionId: string, command: UpdateSyllabusCommand): Observable<ElbertFile>{
    const formData = new FormData();
    formData.append('file', command.file, command.file.name);
    formData.append('courseId', command.courseId);
    formData.append('description', command.description);
    return this.http.post<ElbertFile>(`${this.apiUrl}/${institutionId}/course/syllabus`, formData);
  }

  public downloadSyllabus(institutionId: string, courseId: string, file: ElbertFile) {    
    return this.http.get(`${this.apiUrl}/${institutionId}/course/syllabus/download/${courseId}`, { responseType: "blob" })  
      .subscribe((result: any) => {  
        if (result.type != 'text/plain') {  
          var blob = new Blob([result]);  
            
          saveAs(blob, file.fileName);  
        }  
      });  
  }  

  public getActiveEnrollments(providerId: string): Observable<EnrollmentListItem[]>{
    return this.http.get<EnrollmentListItem[]>(`${this.apiUrl}/${providerId}/enrollments`);
  }

  public getEnrollmentByAcademicYear(providerId: string, academicYearId: string): Observable<EnrollmentListItem[]>{
    return this.http.get<EnrollmentListItem[]>(`${this.apiUrl}/${providerId}/enrollmentsByAcademicYear/${academicYearId}`);
  }

  public submitStatusChanges(providerId: string, command: ChangeEnrollmentStatusBulkCommand): Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.apiUrl}/${providerId}/enrollments/changeStatuses`,command);
  }

  public submitGrading(providerId: string, command: ChangeLetterGradeBulkCommand): Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.apiUrl}/${providerId}/enrollments/grading`,command);
  }

  public getMonthlyStatements(providerId: string): Observable<ProviderMonthlyPayoutStatement[]>{
    return this.http.get<ProviderMonthlyPayoutStatement[]>(`${this.apiUrl}/${providerId}/billing/monthlyStatements/12`);
  }

  public getMonthlyPayoutStatement(providerId: string, year: number, month: number): Observable<ProviderMonthlyPayoutStatement>{
    return this.http.get<ProviderMonthlyPayoutStatement>(`${this.apiUrl}/${providerId}/billing/monthlyPayout/${year}/${month}`);
  }

  public getDashboardData(providerId: string, academicYearId: string): Observable<ProviderDashboardViewModel>{
    academicYearId = academicYearId==="" ? 'empty' : academicYearId;
    return this.http.get<ProviderDashboardViewModel>(`${this.apiUrl}/${providerId}/dashboard/${academicYearId}`);
  }

  public downloadMonthlyStatement(monthlyStatement: MonthlyStatement) {
    return this.http.get(`${this.apiUrl}/${monthlyStatement.ledgerId}/monthlyStatement/download/${monthlyStatement.id}`, { responseType: "blob" })  
      .subscribe((result: any) => {  
        if (result.type != 'text/plain') {  
          var blob = new Blob([result]);  
            
          saveAs(blob, monthlyStatement.statement.fileName);  
        }  
      }  
      );  
  }

  public enrollmentMigrationData(providerId: string,institutionId:string, commands: EnrollmentMigrationCommand[]): Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.migrationUrl}/${providerId}/${institutionId}/enrollments`,commands);
  }

  public getEnrollmentMigrationData(providerId: string, institutionId: string): Observable<any>{
    return this.http.get<any>(`${this.migrationUrl}/${providerId}/${institutionId}/enrollments`);
  }

  public getGradeData(providerId: string, institutionId: string): Observable<any>{
    return this.http.get<any>(`${this.migrationUrl}/${providerId}/${institutionId}/grades`);
  }

  public letterGradeMigrationData(providerId: string,institutionId:string, commands: EnrollmentMigrationCommand[]): Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.migrationUrl}/${providerId}/${institutionId}/grades`,commands);
  }
}
