import {Component, Input, OnInit} from '@angular/core';
import {StudentListItem} from '@shared/student/models';

@Component({
  selector: 'student-details-for-dialog',
  templateUrl: './student-details-for-dialog.component.html',
  styleUrls: ['./student-details-for-dialog.component.scss'],
})
export class StudentDetailsForDialogComponent implements OnInit {
  @Input() student: StudentListItem;

  isAccordionShow = true;

  constructor() {
  }

  ngOnInit(): void {

  }
}
