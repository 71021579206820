<elb-page-header [pageTitle]="pageTitle" [pageSubTitle]="''"
                 (goBackClicked)="cancel()">
  <button *ngIf="!isNew" [disabled]="!userForm.valid || !userForm.dirty" (click)="saveUser()"
          class="header-nav-link" mat-button>
    <mat-icon>save</mat-icon>Save User
  </button>

  <button *ngIf="isNew" [disabled]="!userForm.valid" (click)="onAddUser()" class="header-nav-link" mat-button>
    <mat-icon>add</mat-icon>Save New User
  </button>
</elb-page-header>
<!-- / FORM PAGE TITLE -->

<!-- CONTENT CARD -->
<div class="content-card p-20">

  <!-- CONTENT -->
  <div class="content" fusePerfectScrollbar>
    <div class="content-form-container">
      <!-- FORM -->
      <form id="userForm" [formGroup]="userForm">
        <div class="c_form_wrap" fusePerfectScrollbar fxLayout="column">
          <div fxLayout="row" fxLayoutGap="32px">
            <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
              <mat-label>Department</mat-label>
              <input matInput placeholder="Dept" name="department" formControlName="department">
            </mat-form-field>

            <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
              <mat-label>Title</mat-label>
              <input matInput placeholder="Title" name="title" formControlName="title">
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutGap="32px">
            <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
              <mat-label>First Name</mat-label>
              <input matInput placeholder="First Name" name="firstName" formControlName="firstName">
            </mat-form-field>

            <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
              <mat-label>Last Name</mat-label>
              <input matInput placeholder="Last Name" name="lastName" formControlName="lastName">
            </mat-form-field>
          </div>
          <div fxLayout="row" fxLayoutGap="32px">
            <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
              <mat-label>Email</mat-label>
              <input matInput
                     placeholder="Email"
                     name="emailAddress"
                     uniqueEmail
                     [userId]="matDialogData.userId"
                     formControlName="emailAddress">
              <mat-error *ngIf="hasError('emailAddress', 'emailExist')">Email already exists</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
              <input matInput [textMask]="{mask: phoneMask}" placeholder="Phone" name="phoneNumber"
                     formControlName="phoneNumber">
            </mat-form-field>
            <mat-form-field appearance="outline" floatLabel="always" *ngIf="isNew && (institutionRoles$ | async) as institutionRoles">
              <mat-label>User Role</mat-label>
              <mat-select class="w-100" mat-select formControlName="institutionRole" name="institutionRole">
                <mat-option *ngFor="let role of institutionRoles" [value]="role.description">
                  {{role.description}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </form>

      <div class="c_dialog-btn-row" fxLayout="row" fxLayoutAlign="space-between">
        <button type="button" mat-raised-button (click)="cancel()" class="c_default-btn gray">Return to User
          List</button>
        <button type="button" mat-raised-button *ngIf="isNew" [disabled]="!userForm.valid || isLoading" (click)="onAddUser()"
                class="c_default-btn ">Add</button>
        <button type="button" mat-raised-button *ngIf="!isNew" [disabled]="!userForm.valid || !userForm.dirty || isLoading"
                (click)="saveUser()" class="c_default-btn ">Save</button>
      </div>
    </div>
  </div>
</div>
