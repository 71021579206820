import { createAction, props } from '@ngrx/store';
import { MemberCourseType } from '../enums/memberCourseType';

export const Calendar = createAction(
    '[Navigation] - Calendar',
    props<{
        memberId?: string,
        institutionId?: string
    }>()
)

export const Fees = createAction(
    '[Navigation] - Fees',
    props<{
        memberId?: string,
        institutionId?: string
    }>()
)
export const MemberCourses = createAction(
    '[Navigation] - Member Course List',
    props<{
        memberId?: string,
        institutionId?: string,
        memberCourseType?: MemberCourseType
    }>()
)

export const UserList = createAction(
    '[Navigation] - Member User List',
    props<{
        institutionId?:string,
        memberId?:string
    }>()
)
