<div class="enrollment-students-info">
  <div class="table">
    <div class="table-head">
      <div class="table-row">
        <div class="table-cell table-cell-sorts"
             *ngFor="let tableItem of tableScheme"
             (click)="sortByText(tableItem)"
        >
          <div class="table-cell-sorts-title">{{tableItem.title}}</div>
          <mat-icon *ngIf="tableItem.sortFlag">arrow_downward</mat-icon>
          <mat-icon *ngIf="!tableItem.sortFlag">arrow_upward</mat-icon>
        </div>
      </div>
    </div>

    <div class="table-body">
      <div class="table-row" *ngFor="let item of sortedStudents$ | async">
        <div *ngFor="let tableItem of tableScheme" class="table-cell">
          <span>{{getValue(item, tableItem.accessKeys)}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
