import {NgModule} from '@angular/core';
import {MaterialModule} from 'app/core/material/material.module';
import {RouterModule} from '@angular/router';
import {InstitutionAdminDashboardNavComponent} from './institution-admin-dashboard-nav.component';
import {AvatarSharedModule} from '@shared/avatar/avatar-shared.module';

@NgModule({
  declarations: [InstitutionAdminDashboardNavComponent],
  imports: [
    MaterialModule,
    RouterModule,
    AvatarSharedModule
  ],
  exports: [
    InstitutionAdminDashboardNavComponent
  ]
})
export class InstitutionAdminDashboardNavModule {
}
