import {Injectable} from '@angular/core';
import {Actions, ofType, createEffect} from '@ngrx/effects';
import * as fromSuperAdminDashboard from '@admin/dashboard/store';
import { tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable()
export class SuperAdminDashboardNavigateEffects {
  constructor(private actions$: Actions,private router: Router) 
  {
  }

  superAdminDashboard$ = createEffect(() => {
    return this.actions$.pipe(
            ofType(fromSuperAdminDashboard.Navigate.Dashboard),
            /** An EMPTY observable only emits completion. Replace with your own observable stream */
            tap(() => {
              this.router.navigate([`/admin/dashboard`]);
            })
      );
},{dispatch:false});
}