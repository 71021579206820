<div class="toolbar" [ngClass]="currentAccessRole ? currentAccessRole.viewContext : ''">
  <div class="toolbar-part">
    <toolbar-logo></toolbar-logo>
  </div>

  <div class="toolbar-part">
    <div class="toolbar-icons-group">
      <div class="toolbar-icon">
        <button mat-icon-button
                (click)="logout()"
                class="toolbar-icon-button">
          <mat-icon>exit_to_app</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
