import { createEffect, Actions, ofType } from "@ngrx/effects";
import { Injectable } from "@angular/core";
import { tap } from "rxjs/operators";
import { Store } from '@ngrx/store';
import { IAppState } from 'app/store/state/app.state';
import { Router } from '@angular/router';
import * as fromAdminUsers from '@admin/users/store';

@Injectable()
export class AdminUsersNavigate{
    constructor(private actions$: Actions, private store:Store<IAppState>, private router: Router){
            }
    UserList$ = createEffect(() => {
                return this.actions$.pipe(
                        ofType(fromAdminUsers.Navigate.UserList),
                        tap((action) => {  
                           this.router.navigate([`/admin/users`])
                        })
                )
            },{dispatch : false});
}