import { Component, OnInit, ViewEncapsulation, ChangeDetectionStrategy, ViewChild, ElementRef } from '@angular/core';
import { Observable, of, fromEvent } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { Order, Sort, BaseListItem } from 'app/core/models';
import { EnrollmentListItemForView, EnrollmentListItem } from '@member/enrollment/models';
import { MatDialog } from '@angular/material/dialog';
import { IAppState } from 'app/store/state/app.state';
import { Store, select } from '@ngrx/store';
import * as fromEnrollment from '@member/enrollment/store';
import * as fromMember from '@member/store';
import * as fromMemberDashboard from '@member/dashboard/store';
import * as fromInstitution from '@institution/store';
import * as fromAuth from '@auth/store';
import { switchMap, tap, map, debounceTime, distinctUntilChanged, withLatestFrom } from 'rxjs/operators';
import { Filter, FilterType, SemesterFilter } from '@shared/common/models';
import { EnrollmentStatus, EnrollmentStatusType } from '@refData';
import { EnrollmentListItemDetailDialogComponent, EnrollmentListItemDetailDialogData } from '../enrollment-list-item-detail-dialog/enrollment-list-item-detail-dialog.component';
import { UpdateEnrollmentStatusDialogComponent } from '../update-enrollment-status-dialog/update-enrollment-status-dialog.component';
import { MatSelectionListChange } from '@angular/material/list';
import { PermissionType } from '@auth/models/permissionType.enum';
import { AcademicYear } from '@shared/calendar/models';
import { Moment } from 'moment';
import * as moment from 'moment';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';

@Component({
  selector: 'app-enrollment-list',
  templateUrl: './enrollment-list.component.html',
  styleUrls: ['./enrollment-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EnrollmentListComponent implements OnInit {
  pageTitle = 'Enrollments';
  pageSubTitle = '';
  panelOpenState = false;

  filteredEnrollments$: Observable<EnrollmentListItemForView[]>;
  selectedEnrollments$: Observable<EnrollmentListItemForView[]>;
  commonAllowedStatuses$: Observable<EnrollmentStatus[]>;
  selectedStatus$: Observable<string>;
  currentOrder$: Observable<Order>;
  currentSort$: Observable<Sort>;
  canExportToExcel$: Observable<boolean>;
  canSubmitChanges$: Observable<boolean>;
  isAllSelected$: Observable<boolean>;
  indeterminateSelected$: Observable<boolean>;
  sorts$: Observable<Sort[]>;
  orders$: Observable<Order[]>;
  selectedFilters$: Observable<Filter[]>;
  statusFilters$: Observable<Filter[]>;
  statusFiltersCount$: Observable<number>;
  canViewGrades$: Observable<boolean>;
  IsEnrollmentsLoading$: Observable<boolean>;
  providerFilters$: Observable<Filter[]>;
  providerFilterCount$: Observable<number>;
  tagFilters$: Observable<Filter[]>;
  tagFilterCount$: Observable<number>;
  categoryFilters$: Observable<Filter[]>;
  categoryFilterCount$: Observable<number>;
  subCategoryFilters$: Observable<Filter[]>;
  subCategoryFilterCount$: Observable<number>;
  startDate$: Observable<Date>;
  endDate$: Observable<Date>;
  dateRange$: Observable<any>;
  selected: any = {startDate: moment().add(-6,'month'), endDate: moment().add(3, 'month')};
  @ViewChild("filter") filter: ElementRef;
  @ViewChild('picker') picker: any;
  constructor(public dialog: MatDialog, private store: Store<IAppState>) { }

  ngOnInit(): void {
    this.canExportToExcel$ = this.store.pipe(select(fromEnrollment.Selectors.CanExportToExcel));
    this.canSubmitChanges$ = this.store.pipe(select(fromEnrollment.Selectors.CanSubmitChanges));
    this.currentOrder$ = this.store.pipe(select(fromEnrollment.Selectors.CurrentOrder));
    this.currentSort$ = this.store.pipe(select(fromEnrollment.Selectors.CurrentSort));
    this.filteredEnrollments$ = this.store.pipe(select(fromEnrollment.Selectors.FilteredEnrollments));
    this.indeterminateSelected$ = this.store.pipe(select(fromEnrollment.Selectors.IndeterminateSelected));
    this.isAllSelected$ = this.store.pipe(select(fromEnrollment.Selectors.IsAllSelected));
    this.orders$ = this.store.pipe(select(fromEnrollment.Selectors.Orders));
    this.selectedEnrollments$ = this.store.pipe(select(fromEnrollment.Selectors.SelectedEnrollments));
    this.commonAllowedStatuses$ = this.store.pipe(select(fromEnrollment.Selectors.CommonAllowedStatuses));
    this.selectedFilters$ = this.store.pipe(select(fromEnrollment.Selectors.SelectedFilters));
    this.selectedStatus$ = this.store.pipe(select(fromEnrollment.Selectors.SelectedStatus));
    this.sorts$ = this.store.pipe(select(fromEnrollment.Selectors.Sorts));
    this.statusFilters$ = this.store.pipe(select(fromEnrollment.Selectors.StatusFilters));
    this.statusFiltersCount$ = this.store.pipe(select(fromEnrollment.Selectors.StatusFilterCount));
    this.canViewGrades$ = this.store.pipe(select(fromMember.Selectors.HasMemberPermission(PermissionType.CanSubmitGradeDispute)));
    this.IsEnrollmentsLoading$ = this.store.pipe(select(fromEnrollment.Selectors.IsEnrollmentsLoading));
    this.tagFilters$ = this.store.pipe(select(fromEnrollment.Selectors.TagFiltersForView));
    this.tagFilterCount$ = this.store.pipe(select(fromEnrollment.Selectors.TagFilterCount));
    this.providerFilters$ = this.store.pipe(select(fromEnrollment.Selectors.ProviderFiltersForView));
    this.providerFilterCount$ = this.store.pipe(select(fromEnrollment.Selectors.ProviderFilterCount));
    this.categoryFilters$ = this.store.pipe(select(fromEnrollment.Selectors.CategoryFiltersForView));
    this.categoryFilterCount$ = this.store.pipe(select(fromEnrollment.Selectors.CategoryFilterCount));
    this.subCategoryFilters$ = this.store.pipe(select(fromEnrollment.Selectors.SubCategoryFiltersForView));
    this.subCategoryFilterCount$ = this.store.pipe(select(fromEnrollment.Selectors.SubCategoryFilterCount));
    this.dateRange$ = this.store.pipe(select(fromAuth.Selectors.DateRange));
    this.startDate$ = this.store.pipe(select(fromAuth.Selectors.StartDate));
    this.endDate$ = this.store.pipe(select(fromAuth.Selectors.EndDate));
    this.store.dispatch(fromEnrollment.Actions.LoadSorts({ sorts: fromEnrollment.sorts }));
    this.store.dispatch(fromEnrollment.Actions.LoadOrders({ orders: fromEnrollment.orders }));
  }

  ngAfterViewInit() {
    this.setupTextSearch();
  }

  goToDashboard(): void {
    this.store.dispatch(fromMemberDashboard.Navigate.Dashboard({}));
  }
  masterToggle(): void {
    this.store.dispatch(fromEnrollment.Actions.MasterToggle());
  }
  removeFilter(filter: Filter): void {
    const id = filter.id;
    switch (filter.type) {
      case FilterType.EnrollmentStatus:
        this.store.dispatch(fromEnrollment.Actions.ToggleStatusFilter({ id }));
        break;
      case FilterType.Provider:
        this.store.dispatch(fromEnrollment.Actions.ToggleProviderFilter({ id }));
        break;
      case FilterType.Tag:
        this.store.dispatch(fromEnrollment.Actions.ToggleTagFilter({ id }));
        break;
      case FilterType.CourseCategory:
        this.store.dispatch(fromEnrollment.Actions.ToggleCategoryFilter({ id }));
        break;
      case FilterType.CourseSubCategory:
        this.store.dispatch(fromEnrollment.Actions.ToggleSubCategoryFilter({ id }));
        break;
    }
  }
  setupTextSearch(): void {
    fromEvent(this.filter.nativeElement, "keyup").pipe(
      map((event: any) => event.target.value),
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe((searchText: string) => this.store.dispatch(fromEnrollment.Actions.ChangeSearchText({ searchText })))
  }
  onToggleEnrollment(enrollmentId: string): void {
    this.store.dispatch(fromEnrollment.Actions.ToggleEnrollment({ enrollmentId }));
  }
  enrollmentById(index: number, enrollment: EnrollmentListItemForView) {
    return enrollment.id;
  }
  onToggleStatusFilter(event: MatSelectionListChange): void {
    const id = event.options[0].value;
    this.store.dispatch(fromEnrollment.Actions.ToggleStatusFilter({ id }));
  }
  onToggleSemesterFilter(event: MatSelectionListChange): void {
    const semesterId = event.options[0].value;
    this.store.dispatch(fromEnrollment.Actions.ToggleSemester({ semesterId }));
  }
  changeOrderBy(order: Order): void {
    this.store.dispatch(fromEnrollment.Actions.ChangeOrder({ currentOrder: order }))
  }
  changeSort(sort: Sort): void {
    this.store.dispatch(fromEnrollment.Actions.ChangeSort({ currentSort: sort }))

  }
  editStatus(requestedStatus: BaseListItem, enrollmentId: string): void {
    this.store.dispatch(fromEnrollment.Actions.EditStatus({ enrollmentId, requestedStatus }));
  }
  trackById(item: EnrollmentListItem): string {
    return item.id;
  }
  submitStatusChanges(): void {
    this.store.dispatch(fromEnrollment.Actions.SubmitStatusChanges());
  }
  getEnrollmentStatusDescription$(enrollment: EnrollmentListItem): Observable<string> {
    return of(enrollment)
      .pipe(
        withLatestFrom(this.canViewGrades$),
        map(([enrollment, canViewGrade]) => {
          if (enrollment.status.statusType == EnrollmentStatusType.Graded && canViewGrade) {
            return `${enrollment.status.description} - ${enrollment.letterGrade.description}`;
          } else {
            return `${enrollment.status.description}`;
          }
        })
      );
  }

  getCourseTitle(enrollment: EnrollmentListItem): string {
    if (enrollment.memberCourseCode != "") {
      return `${enrollment.memberCourseCode}, ${enrollment.courseTitle}`;
    }
    return enrollment.courseTitle;
  }

  enrollmentDetails(enrollment: EnrollmentListItem): void {
    this.dialog.open<
      EnrollmentListItemDetailDialogComponent,
      EnrollmentListItemDetailDialogData
    >(EnrollmentListItemDetailDialogComponent, {
      data: {
        enrollment
      }
    })
  }

  updateEnrollments(): void {
    this.dialog.open(UpdateEnrollmentStatusDialogComponent);
  }

  compare(o1: any, o2: any): boolean {
    if (!o1 || !o2) return false;
    return o1.id === o2.id;
  }

  showEditStatus(enrollment: EnrollmentListItem): boolean {
    if (enrollment.status.statusType === EnrollmentStatusType.Graded) return false;
    if (enrollment.status.statusType === EnrollmentStatusType.Incomplete) return false;
    if (enrollment.status.memberAllowedStatuses.length === 0) return false;
    return true;
  }

  showAppealGrade$(enrollment: EnrollmentListItem): Observable<boolean> {
    return of(false);
    // return of(enrollment)
    //   .pipe(
    //     withLatestFrom(this.canViewGrades$),
    //     map(([enrollment, canViewGrade]) => {
    //       if (!canViewGrade) {
    //         return false;
    //       } else {
    //         if (enrollment.status.statusType === EnrollmentStatusType.Graded) return true;
    //         if (enrollment.status.statusType === EnrollmentStatusType.Incomplete) return true;
    //         return false;
    //       }
    //     })
    //   );
  }

  exportToExcel(enrollment?: EnrollmentListItem): void {
    const enrollments = [enrollment];
    this.store.dispatch(fromEnrollment.Actions.ExportToExcel({
      enrollments
    }))
  }

  onToggleProviderFilter(event: MatSelectionListChange): void {
    const id = event.options[0].value
    this.store.dispatch(fromEnrollment.Actions.ToggleProviderFilter({ id }));
  }
  onToggleTagFilter(event: MatSelectionListChange): void {
    const id = event.options[0].value
    this.store.dispatch(fromEnrollment.Actions.ToggleTagFilter({ id }));
  }
  onToggleCategoryFilter(event: MatSelectionListChange): void {
    const id = event.options[0].value
    this.store.dispatch(fromEnrollment.Actions.ToggleCategoryFilter({ id }));
  }
  onToggleSubCategoryFilter(event: MatSelectionListChange): void {
    const id = event.options[0].value
    this.store.dispatch(fromEnrollment.Actions.ToggleSubCategoryFilter({ id }));
  }

  changeStartDate(e: any){
    if(e.target.value===null)return;
    const startDate =e.target.value.toDate();
    this.store.dispatch(fromAuth.Actions.ChangeStartDate({startDate}));
    this.store.dispatch(fromEnrollment.Actions.LoadEnrollmentByDateRange({}));
  }

  changeEndDate(e: any){
    if(e.target.value===null) return;
    const endDate =e.target.value.toDate();
    this.store.dispatch(fromAuth.Actions.ChangeEndDate({endDate}));
    this.store.dispatch(fromEnrollment.Actions.LoadEnrollmentByDateRange({}));
  }
}
