import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { Store, select } from "@ngrx/store";
import { first, finalize, filter, map, concatMap, withLatestFrom } from "rxjs/operators";
import { IAppState } from 'app/store/state/app.state';
import * as fromStudent from '@member/students';
import * as fromMember from '@member/store';
import { StudentListItem } from '@shared/student/models';
import { PermissionType } from '@auth/models/permissionType.enum';
@Injectable()
export class StudentListResolver implements Resolve<StudentListItem[]>{
    isLoading = false;
    constructor(private store: Store<IAppState>){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
        const institutionId = route.params["institutionId"];
        return this.store 
            .pipe(
                select(fromStudent.Selectors.Students),
                concatMap((students)=> of(students).pipe(
                    withLatestFrom(this.store.pipe(select(fromMember.Selectors.HasInstitutionPermission(PermissionType.ReadStudentInformation))))
                )),
                map(([students,hasReadPermission]) => {
                    if(!hasReadPermission) return false;
                    if(students.length>0 && students[0].institutionId===institutionId) return false;
                    return true;
                }),
                map((loadStudents)=>{
                    if(!this.isLoading && loadStudents){
                        this.isLoading = true;
                        this.store.dispatch(fromStudent.Actions.LoadStudents({institutionId}));
                    }
                    return true;
                }),
                filter(studentListLoaded => studentListLoaded),
                first(),
                finalize(()=>this.isLoading=false)
            )
    }
}