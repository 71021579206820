import { MatCheckboxChange } from '@angular/material/checkbox';
import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { IAppState } from 'app/store/state/app.state';
import { Store } from '@ngrx/store';
import * as fromCourseExchangeBilling from '@courseExchange/billing/store';
export interface CourseExchangeBillingReportDialogData {
  year: number;
  month: number;
}

@Component({
  selector: 'courseExchange-billingReport-dialog',
  templateUrl: './courseExchange-billingReport-dialog.component.html',
  styleUrls: ['./courseExchange-billingReport-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {'class': 'courseExchange-billingReport-dialog'}
})
export class CourseExchangeBillingReportDialogComponent {
  courseExchangeId: string;
  year: number;
  month: number;
  exchangeSummaryReport: boolean;
  memberReport: boolean;
  providerReport: boolean;
  constructor(
    public matDialogRef: MatDialogRef<CourseExchangeBillingReportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public matDialogData: CourseExchangeBillingReportDialogData,
    public store: Store<IAppState>
  ) {
    this.matDialogRef.addPanelClass('data-migration-dialog');
  }

  close(): void {
    this.matDialogRef.close();
  }

  exportReports(): void{
    const {year, month} = this.matDialogData;
    if(this.exchangeSummaryReport){
      this.store.dispatch(fromCourseExchangeBilling.Actions.ExportCourseExchangeBillingReport({year,month}));
    }
    if(this.memberReport){
      this.store.dispatch(fromCourseExchangeBilling.Actions.ExportMemberDetailedReport({year,month}));
    }
    if(this.providerReport){
      this.store.dispatch(fromCourseExchangeBilling.Actions.ExportProviderDetailedReport({year,month}));
    }
    this.close();
  }

  updateExchangeSummaryReport(e:MatCheckboxChange){
    this.exchangeSummaryReport = e.checked;
  }

  updateMemberReport(e:MatCheckboxChange){
    this.memberReport = e.checked;
  }

  updateProviderReport(e:MatCheckboxChange){
    this.providerReport = e.checked;
  }

  canExport(): boolean{
    return this.exchangeSummaryReport || this.memberReport || this.providerReport;
  }
}
