import { MonthlyStatement } from '@core/models/monthlyStatement.viewModel';
import { props, createAction } from '@ngrx/store';
import { ProviderMonthlyPayoutStatement } from '@provider/billing/models/providerMonthlyPayoutStatement.viewModel';

export const LoadMonthlyStatements = createAction(
    '[Provider Monthly Bill Resolver] - Load Provider Monthly Statements',
    props<{
        providerId?: string;
    }>()
)

export const LoadMonthlyStatementsSuccess = createAction(
    '[Provider Monthly Bill Effects] - Load Provider Monthly Statements Success',
    props<{
        monthlyStatements: Array<ProviderMonthlyPayoutStatement>
    }>()
)

export const LoadMonthlyStatementsError = createAction(
    '[Provider Monthly Bill Effects] - Load Provider Monthly Statements Error',
    props<{
        message: string
    }>()
)

export const DownloadMonthlyStatement = createAction(
    '[Provider Monthly Billing Summary] - Download Monthly Statement',
    props<{
        monthlyStatement: MonthlyStatement;
    }>()
)

export const ProviderMonthlyPayoutStatementUpdated = createAction(
    '[Provider Monthly Billing Realtime] - Provider Monthly Payout Statement Updated',
    props<{
        providerMonthlyPayoutStatement: ProviderMonthlyPayoutStatement
    }>()
)

export const ProviderMonthlyPayoutStatementAdded = createAction(
    '[Provider Monthly Billing Realtime] - Provider Monthly Payout Statement Added',
    props<{
        providerMonthlyPayoutStatement: ProviderMonthlyPayoutStatement
    }>()
)

export const ExportProviderDetailedReport = createAction(
    '[Provider Billing] - Provider Detailed Report',
    props<{
        year: number,
        month: number
    }>()
)
