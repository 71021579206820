<div class="enrollment-short-student-info">
  <div class="row">
    <div class="col">
      <div class="info-box">
        <div class="title">Student ID:</div>
        <div class="text">0012345678</div>
      </div>
    </div>

    <div class="col">
      <div class="info-box">
        <div class="title">Student:</div>
        <div class="text">Cyrus, Miley</div>
      </div>
    </div>
  </div>
</div>
