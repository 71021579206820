import { Session } from "@shared/calendar/models";

export default (session:Session): Session => {
    return {
        ...session,
        startDate: new Date(session.startDate2.year, session.startDate2.month-1, session.startDate2.day),
        endDate: new Date(session.endDate2.year, session.endDate2.month-1, session.endDate2.day),
        registrationStartDate: new Date(session.registrationStartDate2.year, session.registrationStartDate2.month-1, session.registrationStartDate2.day),
        registrationDeadlineDate: new Date(session.registrationDeadlineDate2.year, session.registrationDeadlineDate2.month-1, session.registrationDeadlineDate2.day),
        dropDeadlineDate: new Date(session.dropDeadlineDate2.year, session.dropDeadlineDate2.month-1, session.dropDeadlineDate2.day),
        feeEarnedDate: new Date(session.feeEarnedDate2.year, session.feeEarnedDate2.month-1, session.feeEarnedDate2. day),
        withdrawalDeadlineDate: new Date(session.withdrawalDeadlineDate2.year, session.withdrawalDeadlineDate2.month-1, session.withdrawalDeadlineDate2. day)
    }
  }
