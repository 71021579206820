<div class="header page-header" fxLayout="row" fxLayoutAlign="space-between center">
    <!-- PAGE TITLE -->
    <div class="header-title-container" fxLayout="row" fxLayoutAlign="start center">

      <button mat-icon-button class="mr-0 mr-sm-16 header-return-icon" (click)="returnToDashboardClicked.emit(); goBackClicked.emit();">
        <mat-icon>arrow_back</mat-icon>
      </button>

      <div class="header-title" fxLayout="column" fxLayoutAlign="start start">
        <div class="h2">
          {{ pageTitle }}
        </div>
        <!-- <div class="subtitle secondary-text">
          <span>{{pageSubTitle}}</span>
        </div> -->
      </div>

    </div>
    
    <!-- PAGE HEADER NAVIGATION -->
    <div class="header-nav-container" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
      <ng-content></ng-content>
    </div>
</div>