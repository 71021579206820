import {Injectable} from '@angular/core';
import {Actions, ofType, createEffect} from '@ngrx/effects';
import { switchMap, map, catchError, concatMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import * as fromMemberDashboard from '@member/dashboard/store';
import { MemberService } from '@shared/member/services';
import * as fromMember from '@member/store';
import * as fromInstitution from '@institution/store';
import { select, Store } from '@ngrx/store';
import { IAppState } from 'app/store/state/app.state';
import { MemberDashboardViewModel } from '@shared/member/models';
@Injectable()
export class MemberDashboardEffects {
  constructor(private actions$: Actions, private memberService: MemberService, private store: Store<IAppState>) 
  {
  }

  loadMemberDashboardData$ = createEffect(() => {
    let memberId ='';
    return this.actions$.pipe(
        ofType(fromMemberDashboard.Actions.LoadDashboardData), concatMap(action => of(action).pipe(
          withLatestFrom(this.store.pipe(select(fromMember.Selectors.DateRangeArgs)))
        )),
        /** An EMPTY observable only emits completion. Replace with your own observable stream */
        switchMap(([action, dateArgs]) => {
          const {memberId} = action;
          if(memberId===''){
            return of({
              memberId,
              availableCourses: 0,
              totalEnrollments: 0,
              updatedEnrollments: 0,
              totalStudents: 0
            })
          }
          return this.memberService.getDashboardData(memberId,dateArgs.startDate,dateArgs.endDate);
        }),
        map((data) => {
          return fromMemberDashboard.Actions.LoadDashboardDataSuccess({data});
        }),
        catchError((err) => of(fromMemberDashboard.Actions.LoadDashboardDataError({message: err.message})))
    );
  });
}