<div class="content">
  <div class="title">
    YOU ARE CHOOSING TO SKIP A STEP!
  </div>

  <div [ngSwitch]="matDialogData.itemType">
    <ng-container *ngSwitchCase="'Calendar_AddYear'">
      <p><span class="warning">YOU HAVE CHOSEN TO SKIP ADDING YOUR CALENDAR YEAR</span></p>

      <p>
        You will not be able access your course exchange dashboard until course exchange set up is complete.
      </p>

      <p>
        You may complete adding your calendar year at any time by clicking the <mat-icon>calendar_today</mat-icon>
        in the stepper above OR by navigating back to your <span class="link" (click)="goToHome()">home page checklist</span>
      </p>

      <p>Would you like to skip this step now?</p>
    </ng-container>
    <ng-container *ngSwitchCase="'Calendar_AddSemesters'">
      <p><span class="warning">YOU HAVE CHOSEN TO SKIP ADDING YOUR SEMESTER</span></p>

      <p>
        You will not be able access your course exchange dashboard until course exchange set up is complete.
      </p>

      <p>
        You may complete adding your semester at any time by clicking the <mat-icon>calendar_today</mat-icon>
        in the stepper above OR by navigating back to your <span class="link" (click)="goToHome()">home page checklist</span>
      </p>

      <p>Would you like to skip this step now?</p>
    </ng-container>
    <ng-container *ngSwitchCase="'Calendar_AddSessions'">
      <p><span class="warning">YOU HAVE CHOSEN TO SKIP ADDING YOUR SESSION</span></p>

      <p>
        You will not be able access your course exchange dashboard until course exchange set up is complete.
      </p>

      <p>
        You may complete adding your session at any time by clicking the <mat-icon>calendar_today</mat-icon>
        in the stepper above OR by navigating back to your <span class="link" (click)="goToHome()">home page checklist</span>
      </p>

      <p>Would you like to skip this step now?</p>
    </ng-container>
    <ng-container *ngSwitchCase="'Calendar'">
      <p>You have skipped adding Contacts and Users.</p>
    </ng-container>
    <ng-container *ngSwitchCase="'BankInformation'">
      <p><span class="warning">YOU HAVE CHOSEN TO SKIP ADDING YOUR BANK INFORMATION</span></p>

      <p>
        You will not be able access your course exchange dashboard until course exchange set up is complete.
      </p>

      <p>
        You may complete adding your bank information at any time by clicking the <mat-icon>attach_money</mat-icon>
        in the stepper above OR by navigating back to your <span class="link" (click)="goToHome()">home page checklist</span>
      </p>

      <p>Would you like to skip this step now?</p>
    </ng-container>
  </div>
</div>

<div class="actions">
  <button mat-stroked-button
          (click)="close()">
    {{closeButtonName}}
  </button>
  <button mat-flat-button
          (click)="toNextStep()">
    {{translateButtonName}}
  </button>
</div>
