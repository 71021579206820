import { createAction, props } from '@ngrx/store';
import { UserListItem, UserListItemForView } from '@shared/user/models';
import { Sort, Order, EntityStatus } from '@core/models';
import { Filter } from '@shared/common/models';
import { AccessRoleSummary } from '@auth/models/accessRoleSummary';
import { CourseExchangeListItem } from '@shared/courseExchange/models';
import { InstitutionSummary } from '@shared/institution/models';
import { AccessRole } from '@auth/models/accessRole';
import { CourseExchangeRole, InstitutionRole } from '@core/referenceData';
import { AddUserCommand, UpdateUserCommand } from '@shared/user/commands';

export const LoadUsers = createAction(
    '[User List Input] - Load Users',
    props<{
        users: UserListItem[];
    }>()
);

export const LoadSorts = createAction(
    'User List Input - Load Sorts',
    props<{
        sorts: Sort[]
    }>()
)

export const ChangeSort = createAction(
    'User List - Change Sort',
    props<{
        currentSort: Sort
    }>()
)

export const ChangeOrder = createAction(
    'User List - Change Order',
    props<{
        currentOrder: Order
    }>()
)

export const LoadCourseExchanges = createAction(
    '[User list Input] - Load Course Exchanges',
    props<{
        courseExchanges: CourseExchangeListItem[]
    }>()
)
export const ToggleCourseExchangeFilter = createAction(
    '[User List] - Toggle Course Exchange Filter',
    props<{
        id: string
    }>()
)
export const LoadInstitutions = createAction(
    '[User list Input] - Load Institutions',
    props<{
        institutions: InstitutionSummary[]
    }>()
)
export const ToggleInstitutionFilter = createAction(
    '[User List] - Toggle Institution Filter',
    props<{
        id: string
    }>()
)
export const LoadUserStatuses = createAction(
    '[User list] - Load User Statuses',
    props<{
        statuses: EntityStatus[]
    }>()
)
export const LoadInstitutionRoles = createAction(
    '[User list] - Load Institution Roles',
    props<{
        institutionRoles: InstitutionRole[]
    }>()
)
export const ToggleStatusFilter = createAction(
    '[User List] - Toggle Status Filter',
    props<{
        id: string
    }>()
)
export const ToggleInstitutionRoleFilter = createAction(
    '[User List] - Toggle Institution Role Filter',
    props<{
        id: string
    }>()
)
export const RemoveFilter = createAction(
    '[User List] - Remove Filter',
    props<{
        filter: Filter
    }>()
)
export const ChangeSearchText = createAction(
    '[User List] - Change Search Text',
    props<{
        searchText: string
    }>()
)
export const MasterToggle = createAction(
    '[User List] Master Toggle',
    props<{
        isAllSelected: boolean
    }>()
);

export const ToggleUser = createAction(
    '[User list] - Toggle User',
    props<{
        user: UserListItemForView
    }>()
)

export const ManagePermissions = createAction(
    '[User List] - Manage Permissions',
    props<{
        user: UserListItem,
        accessRoleSummary: AccessRoleSummary
    }>()
)

export const SaveInstitutionAccessRole = createAction(
    '[User List Effects] - Save instititionAccessRole',
    props<{
        accessRole: AccessRole,
        changeAllRoles: boolean,
        courseExchangeRoles: CourseExchangeRole[],
        institutionRoles: InstitutionRole[]
    }>()
)

export const SaveAccessRole = createAction(
    '[User List Effects] - Save AccessRole',
    props<{
        accessRole: AccessRole
    }>()
)

export const SendInviteEmail = createAction(
    '[User List] - Send Invite Email',
    props<{
        user: UserListItem
    }>()
)

export const ActivateUser = createAction(
    '[User List] - Activate User',
    props<{
        institutionId: string,
        userId: string
    }>()
)

export const DeactivateUser = createAction(
    '[User List] - Deactivate User',
    props<{
        institutionId: string,
        userId: string
    }>()
)

export const DeleteUser = createAction(
    '[User List] - Delete User',
    props<{
        user: UserListItem
    }>()
)

export const Clear = createAction(
    '[User List] - Clear'
)
export const exportToExcel = createAction(
    '[User List] - exportToExcel',
    props<{
        exportToExcel: boolean
    }>()
)

export const ConfirmDeleteUser = createAction(
    '[User List] - Confirm User Delete',
    props<{
        user: UserListItem
    }>()
)

export const ConfirmActivateUser = createAction(
    '[User List] - Confirm User Activate',
    props<{
        user: UserListItem
    }>()
)

export const ConfirmDeactivateUser = createAction(
    '[User List] - Confirm User Deactivate',
    props<{
        user: UserListItem
    }>()
)

export const AddUser = createAction(
    '[Various Places] - Add User',
    props<{
        command: AddUserCommand
    }>()
)

export const UpdateUser = createAction(
    '[Various Places] - Update User',
    props<{
        command: UpdateUserCommand
    }>()
)
