<div class="wizard-step wizard-institution-profile">
  <div class="title">
    Create Institution Profile
  </div>

  <form class="form" [formGroup]="form" (keydown)="onKeyDownForm($event)">
    <div class="form-columns">
      <div class="col col-left">
        <div class="fields">
          <div class="field">
            <mat-form-field appearance="outline" class="mt-8" fxFill>
              <mat-label>Institution Name</mat-label>
              <input
                matInput
                name="institutionName"
                formControlName="institutionName">
              <mat-error *ngIf="hasError('institutionName', 'required')">This field is required</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="fields">
          <div class="field">
            <mat-form-field appearance="outline" class="mt-8" fxFill>
              <mat-label>URL</mat-label>
              <input
                matInput
                name="websiteUrl"
                formControlName="websiteUrl">
              <mat-error *ngIf="hasError('websiteUrl', 'required')">This field is required</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="fields">
          <div class="field">
            <mat-form-field appearance="outline" class="mt-8" fxFill>
              <mat-label>Address Line 1</mat-label>
              <input
                matInput
                name="addressLine1"
                formControlName="addressLine1">
              <mat-error *ngIf="hasError('addressLine1', 'required')">This field is required</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="fields">
          <div class="field">
            <mat-form-field appearance="outline" class="mt-8" fxFill>
              <mat-label>Address Line 2</mat-label>
              <input
                matInput
                name="addressLine2"
                formControlName="addressLine2">
              <mat-error *ngIf="hasError('addressLine2', 'required')">This field is required</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="fields">
          <div class="field">
            <mat-form-field appearance="outline" class="mt-8" fxFill>
              <mat-label>City</mat-label>
              <input
                matInput
                name="city"
                formControlName="city">
              <mat-error *ngIf="hasError('city', 'required')">This field is required</mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="fields">
          <div class="field field-50">
            <mat-form-field appearance="outline" class="mt-8" fxFill>
              <mat-label>State</mat-label>
              <mat-select formControlName="regionId">
                <ng-container>
                  <mat-option *ngFor="let region of regions" [value]="region.id">
                    {{region.shortDescription}}
                  </mat-option>
                </ng-container>
              </mat-select>
              <mat-error *ngIf="hasError('regionId', 'required')">This field is required</mat-error>
            </mat-form-field>
          </div>

          <div class="field field-50">
            <mat-form-field appearance="outline" class="mt-8" fxFill>
              <mat-label>Zip Code</mat-label>
              <input
                matInput
                name="postalCode"
                formControlName="postalCode">
              <mat-error *ngIf="hasError('postalCode', 'required')">This field is required</mat-error>
            </mat-form-field>
          </div>
          <div class="field field-50">
            <mat-form-field appearance="outline" class="mt-8" fxFill>
              <mat-label>Accreditation Body</mat-label>
              <mat-select formControlName="accreditationBodyId">
                <ng-container>
                  <mat-option *ngFor="let accreditationBody of accreditationBodies" [value]="accreditationBody.id">
                    {{accreditationBody.shortDescription}}
                  </mat-option>
                </ng-container>
              </mat-select>
              <mat-error *ngIf="hasError('accreditationBodyId', 'required')">This field is required</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="col col-right">
        
        <div class="form-file-uploader-wrap">
          <div class="form-file-uploader">
            <wizard-logo-uploader [logoUrl]="logoUrl"
                                  [isSending]="isSendingLogo"
                                  [title]="'Add Logo'"
                                  (uploadLogo)="onFileSelected($event)"
                                  [logoSize]="[64, 64]"></wizard-logo-uploader>

            <mat-error *ngIf="hasError('logoUrl', 'required')" class="file-error">Logo is required</mat-error>
          </div>
        </div>
        <div class="logo-requirements"><p>Logo should be square. Max logo display size is 64px by 64px. A PNG or JPG file will work best.</p></div>
      </div>
    </div>

    <div class="form-actions-continue">
      <span class="skip" (click)="skip()">skip this step</span>
    </div>

    <div class="form-actions">
      <button mat-stroked-button
              (click)="goBack()">
        Back
      </button>

      <button mat-flat-button
              [disabled]="submitDisable()"
              (click)="onSubmit()"
              type="submit">
        Next Step
      </button>
    </div>
  </form>
</div>
