import {Injectable} from '@angular/core';
import { NotificationService } from '@core/notifications/services/notifications.service';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {IAppState} from '../../../../store/state/app.state';
import * as fromNotification from '@core/notifications/store';
import { map, switchMap } from 'rxjs/operators';
import { RealTimeService } from '@core/services/realTime.service';
import { UserNotification } from '@shared/common/models';
@Injectable()
export class NotificationEffects {
  

  constructor(
    private actions$: Actions,
    private store: Store<IAppState>,
    private notificationService: NotificationService,
    private realTimeService: RealTimeService
  ) {
    this.initSignalRMessages();
  }

  private initSignalRMessages(){
    this.realTimeService.hubConnection$.subscribe(hubConnection=>{
      if(!hubConnection) return;
      hubConnection.on('NotificationAdded', (notification: UserNotification) => {
        this.store.dispatch(fromNotification.Actions.NotificationAdded({notification}));
      });
      hubConnection.on('NotificationEdited', (notification: UserNotification) => {
        this.store.dispatch(fromNotification.Actions.NotificationEdited({notification}));
      });
    })
  }

  loadNotifications$ = createEffect(() => {
    return this.actions$.pipe(
        ofType(fromNotification.Actions.LoadNotifications),
        /** An EMPTY observable only emits completion. Replace with your own observable stream */
        switchMap(() => this.notificationService.getUserNotifications()),
        map((notifications)=>fromNotification.Actions.LoadNotificationsSuccess({notifications}))
    );
  });

  markAsRead$ = createEffect(() => {
    return this.actions$.pipe(
        ofType(fromNotification.Actions.MarkNotificationsAsRead),
        /** An EMPTY observable only emits completion. Replace with your own observable stream */
        switchMap(({notification}) => this.notificationService.markAsRead(notification)));
  },{dispatch: false});

  markAllAsRead$ = createEffect(() => {
    return this.actions$.pipe(
        ofType(fromNotification.Actions.MarkAllNotificationsAsRead),
        /** An EMPTY observable only emits completion. Replace with your own observable stream */
        switchMap(() => this.notificationService.markAllAsRead()));
  },{dispatch: false});

  
}
