import {Injectable} from '@angular/core';
import {Actions, ofType, createEffect} from '@ngrx/effects';
import { of } from 'rxjs';
import * as fromProviderDashboard from '@provider/dashboard/store';
import * as fromProvider from '@provider/store';
import { ProviderService } from '@shared/provider/services';
import { concatMap, withLatestFrom, tap } from 'rxjs/operators';
import { IAppState } from 'app/store/state/app.state';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';

@Injectable()
export class ProviderDashboardNavigateEffects {
  constructor(private actions$: Actions,private store:Store<IAppState>, private router: Router) 
  {
  }

  providerDashboard$ = createEffect(() => {
    return this.actions$.pipe(
            ofType(fromProviderDashboard.Navigate.Dashboard),
            concatMap((action) => of(action).pipe(
              withLatestFrom(this.store.pipe(select(fromProvider.Selectors.Provider)))
            )),
            /** An EMPTY observable only emits completion. Replace with your own observable stream */
            tap(([action,currentProvider]) => {
              const providerId = !!action.providerId ? action.providerId : currentProvider.id;
              const institutionId = !!action.institutionId ? action.institutionId : currentProvider.institutionId;
              this.router.navigate([`/provider/${providerId}/${institutionId}/dashboard`]);
            })
      );
},{dispatch:false});
}