import {Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, FormGroupDirective, Validators} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {NavigationType} from '@shared/wizard/constants/navigateType.enum';
import {ChecklistItemType} from '@core/models/checklistItemType.enum';
import {
  WizardSkipDialogContainerComponent,
  IWizardSkipDialogData
} from '@shared/wizard/components/wizard-skip-dialog-container/wizard-skip-dialog-container.component';
import {IControlsConfig} from '../../../../models/shared';
import {hasErrorForm} from '../../../../helpers/has-error-form';
import {Region, AccreditationBody} from '@refData';
import {fillControlsConfig} from '../../../../helpers/fill-controls-config';
import { Institution, UpdateInstitutionCommand } from '@admin/institution';
import { UploadInstitutionLogoCommand } from '@admin/institution/models/commands/UploadInstitutionLogoCommand';

type IFormFields = Pick<UpdateInstitutionCommand,
  'institutionName' |
  'websiteUrl' |
  'logoUrl' |
  'addressLine1' |
  'addressLine2' |
  'city' |
  'regionId' |
  'postalCode' |
  'accreditationBodyId'>;

type ILocalControlsConfig = IControlsConfig<IFormFields>;

@Component({
  selector: 'wizard-institution-profile',
  templateUrl: './wizard-institution-profile-step.component.html',
  styleUrls: ['../../styles/common-wizard-step.scss', './wizard-institution-profile-step.component.scss']
})
export class WizardInstitutionProfileComponent implements OnInit, OnChanges {
  @ViewChild(FormGroupDirective) formDirective;
  form: UntypedFormGroup;
  isSending: boolean;
  @Input() institution: Institution;
  @Input() regions: Region[];
  @Input() accreditationBodies: AccreditationBody[];
  @Input() isSendingLogo: boolean;
  @Output() updateInstitution: EventEmitter<UpdateInstitutionCommand> = new EventEmitter<UpdateInstitutionCommand>();
  @Output() uploadLogo: EventEmitter<UploadInstitutionLogoCommand> = new EventEmitter<UploadInstitutionLogoCommand>();
  @Output() navigate: EventEmitter<string> = new EventEmitter<string>();

  _logoUrl: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
  ) {
    this.form = this.formBuilder.group(this.shapeControlsConfig());
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (propName === 'institution' && changes[propName].currentValue != null) {
        this.form = this.formBuilder.group(this.shapeControlsConfig());
      }
    }
  }

  private shapeControlsConfig(): ILocalControlsConfig {
    const initialControlsConfig: ILocalControlsConfig = {
      institutionName: ['', Validators.required],
      websiteUrl: ['',Validators.required],
      logoUrl: ['', Validators.required],
      addressLine1: ['',Validators.required],
      addressLine2: [''],
      city: ['',Validators.required],
      regionId: ['',Validators.required],
      postalCode: ['',Validators.required],
      accreditationBodyId: ['',Validators.required]
    }

    if (this.institution) {
      const {institutionName, websiteUrl, logoUrl,  address, accreditationBody} = this.institution;
      const accreditationBodyId = !!accreditationBody ? accreditationBody.id : "";
      const dataForFill: Partial<IFormFields> = {
        institutionName,
        logoUrl,
        websiteUrl,
        accreditationBodyId
      }

      if (address) {
        dataForFill.addressLine1 = this.institution.address.addressLine1;
        dataForFill.addressLine2 = this.institution.address.addressLine2;
        dataForFill.city = this.institution.address.city;
        dataForFill.regionId = this.institution.address.region !=null ? this.institution.address.region.id: "";
        dataForFill.postalCode = this.institution.address.postalCode;
      }

      return fillControlsConfig<IFormFields>(initialControlsConfig, dataForFill);
    }

    return initialControlsConfig;
  }

  hasError = (controlName: keyof IFormFields, errorName: string) => {
    if (this.formDirective && this.formDirective.submitted) {
      return hasErrorForm(controlName, errorName, this.form)
    }

    return false;
  };

  onKeyDownForm(event): void {
    if (event.keyCode === 13) {
      this.form.valid ? this.onSubmit() : event.preventDefault();
    }
  }

  submitDisable(): boolean {
    return !this.form.valid || this.isSendingLogo
  }

  onSubmit = () => {
    this.saveInstitution(true);
  }

  onBlur(event): void{
    this.saveInstitution(false);
  }

  getUpdateCommand():UpdateInstitutionCommand{
    const id = this.institution.id;
      const {institutionName, websiteUrl, logoUrl, addressLine1, addressLine2, city, regionId, postalCode,accreditationBodyId} = this.form.value;

      const command: UpdateInstitutionCommand = {
        id,
       institutionName,
        websiteUrl,
        addressLine1,
        addressLine2,
        regionId,
        postalCode,
        city,
        logoUrl,
        accreditationBodyId
      }
      return command;
  }
  saveInstitution = (toNextStep: boolean) => {

      var command = this.getUpdateCommand();
      this.updateInstitution.emit(command);

      if (toNextStep) {
        this.navigate.emit(NavigationType.Forward)
      }

  }

  public onFileSelected(image: File): void {
    const institutionId = this.institution.id;
    const UpdateInstitutionCommand = this.getUpdateCommand();
    var command: UploadInstitutionLogoCommand ={
      image,
      institutionId,
      UpdateInstitutionCommand
    };
    this.uploadLogo.emit(command);
  }

  get logoUrl(): string {
    return !!this.institution ? this.institution.logoUrl : this._logoUrl;
  }

  goBack = () => {
    this.navigate.emit(ChecklistItemType.Root)
  }

  skip = () => {
    this.dialog.open<WizardSkipDialogContainerComponent, IWizardSkipDialogData>(WizardSkipDialogContainerComponent, {
      data: {
        itemType: ChecklistItemType.ContactAndUserSetup,
        toNextStepCb: this.toNextStep
      }
    });
  }

  toNextStep = () => {
    this.navigate.emit(NavigationType.Skip);
  }
}
