import { CourseExchangeCoursesResolver } from './courseExchangeCourses.resolver';
import { CourseExchangeSummaryResolver } from './courseExchangeSummary.resolver';
import { CourseResolver } from './course.resolver';
import { FacultyCVListResolver } from './facultyCVList.resolver';

export * from './courseExchangeCourses.resolver';
export * from './courseExchangeSummary.resolver';
export * from './course.resolver';
export * from './facultyCVList.resolver';

export const RESOLVERS = [CourseExchangeCoursesResolver, CourseExchangeSummaryResolver, CourseResolver, FacultyCVListResolver];