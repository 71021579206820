import * as fromNotification from '@core/notifications/store';

import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
@NgModule({
    imports: [
        StoreModule.forFeature(fromNotification.notificationsFeatureKey, fromNotification.notificationsReducer),
        EffectsModule.forFeature([...fromNotification.EFFECTS])
    ],
    providers: []
})
export class NotificationStoreModule { }
