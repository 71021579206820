<mat-toolbar>
  <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <span class="title dialog-title">Update Enrollment Fee</span>
    <button mat-icon-button (click)="close()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div class="mat-dialog-container">
  <div class="content">
    <ng-container *ngIf="enrollmentForm" [formGroup]="enrollmentForm" class="fee">
      <mat-form-field class="fee-item">
        <input matInput placeholder="Member Fee" formControlName="memberFeeAmount">
      </mat-form-field>

      <mat-form-field class="fee-item">
        <input matInput placeholder="Provider Fee" formControlName="providerFeeAmount">
      </mat-form-field>

      <mat-form-field class="fee-item">
        <input matInput placeholder="Management Fee" formControlName="managementFeeAmount">
      </mat-form-field>
    </ng-container>
  </div>
</div>
<div class="actions">
  <button mat-raised-button class="button" (click)="saveEnrollmentFee()" [disabled]="!enrollmentForm?.valid || !validForSave()" *ngIf="!isNew">
    <mat-icon>save</mat-icon>Save
  </button>
  <button mat-raised-button color="warn" class="button" (click)="close()">
    <mat-icon>close</mat-icon>Cancel
  </button>
</div>