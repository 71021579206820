import {Component, OnDestroy, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {Subject} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {IAppState} from '../../../../store/state/app.state';
import {takeUntil} from 'rxjs/operators';
import { Semester, AcademicYear, Session, SessionDateRules } from '@shared/calendar/models';
import { AddAcademicYearCommand, EditAcademicYearCommand, EditSemesterCommand, AddSemesterCommand, AddSessionCommand, EditSessionCommand } from '@shared/calendar/commands';

@Component({
  selector: 'wizard-calendar-step',
  templateUrl: './wizard-calendar-step.component.html',
  styleUrls: ['../../styles/common-wizard-step.scss', './wizard-calendar-step.component.scss']
})
export class WizardCalendarStepComponent {
  @Input() institutionId: string;
  @Input() academicYear: AcademicYear;
  @Input() semester: Semester;
  @Input() session: Session;
  @Input() showSessions: boolean;
  @Input() currentStepId: string;
  @Input() sessionDateRules: SessionDateRules[];
  @Output() addAcademicYear: EventEmitter<AddAcademicYearCommand> = new EventEmitter<AddAcademicYearCommand>();
  @Output() editAcademicYear: EventEmitter<EditAcademicYearCommand> = new EventEmitter<EditAcademicYearCommand>();
  @Output() addSemester: EventEmitter<AddSemesterCommand> = new EventEmitter<AddSemesterCommand>();
  @Output() editSemester: EventEmitter<EditSemesterCommand> = new EventEmitter<EditSemesterCommand>();
  @Output() addSession: EventEmitter<AddSessionCommand> = new EventEmitter<AddSessionCommand>();
  @Output() editSession: EventEmitter<EditSessionCommand> = new EventEmitter<EditSessionCommand>();
  @Output() navigate: EventEmitter<string> = new EventEmitter<string>();
  constructor() {
  }
}
