<mat-toolbar>
    <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">POST Bulk {{ dialogTitle }} dialog</span>
      <button mat-icon-button (click)="close()" aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
  
  <div class="content">
    <form [formGroup]="form">
        <div class="col-xs-10">
      <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
        <mat-label>Enter Path</mat-label>
        <input matInput placeholder="Enter Path" name="path" formControlName="path">
        <mat-hint>Hint</mat-hint>
      </mat-form-field>
    </div>
    
      <div class="actions">
        <button mat-raised-button color="warn" (click)="close()">
          <mat-icon>not_interested</mat-icon>
          Cancel
        </button>
  
        <button mat-raised-button [disabled]="!form.valid" (click)="save()" type="button">
          <mat-icon>save</mat-icon>
          Save
        </button>
      </div>
  </form>
  </div>
  
  