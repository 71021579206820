import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { InstitutionListItem } from '../../../admin/institution/models/institutionListItem.model';
import { Institution } from '../../../admin/institution/models/institution.model';
import { AddInstitutionCommand } from '../../../admin/institution/models/commands/AddInstitutionCommand';
import { UpdateInstitutionCommand } from '../../../admin/institution/models/commands/UpdateInstitutionCommand';
import { CourseExchangeListItem } from 'app/shared/courseExchange/models';
import { SessionDateRules } from 'app/shared/calendar/models';
import { BaseResponse, ElbertFile } from 'app/core/models';
import { FacultyCV, InstitutionSummary } from '../models';
import { saveAs } from 'file-saver';
import { AddFacultyCVCommand, UpdateFacultyCVCommand } from 'app/shared/provider/commands';
import { DeactivateById, ActivateById } from '@shared/common/models/commands';
import { ConfigService } from '@core/services/config.service';

@Injectable()
export class InstitutionService {
  private apiUrl:string='';
  constructor(private http: HttpClient, private config:ConfigService) { 
    this.config.apiUrl$.subscribe(result=>this.apiUrl=`${result}/institution`);
  }

  public getInstitutionList(): Observable<Array<InstitutionListItem>> {
    return this.http.get<any>(`${this.apiUrl}`)
      .pipe(
        map(data => data.institutions)
      );
  }

  public getInstitutionById(id: string): Observable<Institution> {
    return this.http.get<Institution>(`${this.apiUrl}/${id}`);
  }
  public getInstitutionSummaryById(institutionId: string): Observable<InstitutionSummary> {
    return this.http.get<InstitutionSummary>(`${this.apiUrl}/${institutionId}/summary`);
  }
  public updateInstitution(command: UpdateInstitutionCommand): Observable<BaseResponse> {
    return this.http.put<BaseResponse>(`${this.apiUrl}`, command);
  }

  public addInstitution(command: AddInstitutionCommand): Observable<Institution> {
    return this.http.post<Institution>(`${this.apiUrl}`, command);
  }

  public getCourseExchanges(institutionId: string): Observable<CourseExchangeListItem[]> {
    return this.http.get<CourseExchangeListItem[]>(`${this.apiUrl}/${institutionId}/courseExchanges`);
  }

  public getSessionDateRules(institutionId: string): Observable<SessionDateRules[]> {
    return this.http.get<SessionDateRules[]>(`${this.apiUrl}/${institutionId}/sessionDateRules`);
  }

  uploadBankInfo(bankInfo: File, institutionId: string): Observable<BaseResponse> {
    const formData = new FormData();
    formData.append('bankInfo', bankInfo, bankInfo.name);
    formData.append('institutionId', institutionId);
    return this.http.post<BaseResponse>(`${this.apiUrl}/${institutionId}/uploadBankInfo`, formData);
  }

  public downloadBankInfo(institutionId: string, bankIfo:ElbertFile) {
    return this.http.get(`${this.apiUrl}/${institutionId}/downloadBankInfo`, { responseType: "blob" })
      .subscribe((result: any) => {
        if (result.type != 'text/plain') {
          var blob = new Blob([result]);

          saveAs(blob, bankIfo.fileName);
        }
    });
  }

  public getInstitutionFacultyCVs(institutionId: string): Observable<FacultyCV[]> {
    return this.http.get<FacultyCV[]>(`${this.apiUrl}/${institutionId}/facultyCVs`);
  }

  public getCourseFacultyCVs(institutionId: string, courseId: string): Observable<FacultyCV[]> {
    return this.http.get<FacultyCV[]>(`${this.apiUrl}/${institutionId}/course/facultyCVs/${courseId}`);
  }

  public downloadFacultyCV(institutionId: string, facultyCV: FacultyCV) {
    return this.http.get(`${this.apiUrl}/${institutionId}/facultyCVs/download/${facultyCV.id}`, { responseType: "blob" })
      .subscribe((result: any) => {
        if (result.type != 'text/plain') {
          var blob = new Blob([result]);

          saveAs(blob, facultyCV.file.fileName);
        }
    });
  }
  public addFacultyCV(institutionId: string, command: AddFacultyCVCommand): Observable<BaseResponse>{
    const formData = new FormData();
    formData.append('file', command.file, command.file.name);
    formData.append('institutionId', institutionId);
    formData.append('description', command.description);
    return this.http.post<BaseResponse>(`${this.apiUrl}/${institutionId}/facultyCVs`,formData);
  }

  public updateFacultyCV(institutionId: string, command: UpdateFacultyCVCommand): Observable<BaseResponse>{
    const formData = new FormData();
    formData.append('file', command.file, command.file.name);
    formData.append('id', command.id);
    formData.append('description', command.description);
    return this.http.put<BaseResponse>(`${this.apiUrl}/${institutionId}/facultyCVs`,formData);
  }

  public activateFacultyCV(institutionId: string, command:ActivateById<FacultyCV>): Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.apiUrl}/${institutionId}/facultyCVs/activate`,command);
  }

  public deactivateFacultyCV(institutionId: string, command:DeactivateById<FacultyCV>): Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.apiUrl}/${institutionId}/facultyCVs/deactivate`,command);
  }

  public institutionNameExists(institutionId: string,institutionName: string): Observable<boolean>{
    if(institutionName.trim()==="") return of(false);
    return this.http.get<boolean>(`${this.apiUrl}/${institutionId}/institutionNameExists/${institutionName}`);
  }
}
