import {NgModule} from '@angular/core';
import {AdminProviderDashboardNavComponent} from './admin-provider-dashboard-nav.component';
import {MaterialModule} from 'app/core/material/material.module';
import {RouterModule} from '@angular/router';
import {ProviderSharedModule} from 'app/shared/provider/provider-shared.module';
import {AvatarSharedModule} from '@shared/avatar/avatar-shared.module';

@NgModule({
  declarations: [AdminProviderDashboardNavComponent],
  imports: [
    MaterialModule,
    RouterModule,
    ProviderSharedModule,
    AvatarSharedModule
  ],
  exports: [
    AdminProviderDashboardNavComponent
  ]
})
export class AdminProviderDashboardNavModule {
}
