import { ConfirmDialogData, ConfirmDialogComponent } from './../../../../core/components/confirm-dialog/confirm-dialog.component';
import { BaseResponse } from './../../../../core/models/baseResponse.model';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { IAppState } from 'app/store/state/app.state';
import { concatMap, withLatestFrom, switchMap, map, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as fromProviderCourses from '@provider/courses/store';
import * as fromProvider from '@provider/store';
import * as fromCore from '@core/store';
import * as fromRefData from '@refData';
import * as fromCourseExchange from '@courseExchange/store';
import { FilterType } from '@shared/common/models';
import { CourseService } from '@shared/provider/services/course.service';
import { CourseDescriptionDialogComponent, ICourseDescriptionDialogData } from '@member/memberCourse/components';
import { MatDialog } from '@angular/material/dialog';
import { ExcelColumn, ExcelService } from '@core/services/excel.service';
import { ProviderService } from '@shared/provider/services';
import { ProviderCourseListItemForView } from '@provider/courses/models/providerCourseListItem.model';
import { CreditHourType, SummaryItem } from '@core/models';
import { ProviderCourseStatus } from '@provider/courses/models/providerCourseStatus.enum';
import { InstitutionService } from '@shared/institution/services/institution.service';

@Injectable()
export class CoursesEffects {
  constructor(private actions$: Actions, private store: Store<IAppState>, private dialog: MatDialog,
    private courseService: CourseService, private excelService: ExcelService, private providerService: ProviderService,
    private institutionService: InstitutionService) {
  }

  loadCourses$ = createEffect(() => {
    let providerId = '';
    return this.actions$.pipe(
      ofType(fromProviderCourses.Actions.LoadCourses),
      /** An EMPTY observable only emits completion. Replace with your own observable stream */
      switchMap((action) => {
        providerId = action.providerId;
        return this.courseService.getCourses(providerId)
      }),
      map((result) => {
        const courses = result.map(course => { return { ...course, selected: false, canModify: course.providerId === providerId } });
        return fromProviderCourses.Actions.LoadCoursesSuccess({ courses })
      }),
      catchError((err) => of(fromProviderCourses.Actions.LoadCoursesError({ message: err.message })))
    );
  });

  loadCoursesError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromProviderCourses.Actions.LoadCoursesError),
      /** An EMPTY observable only emits completion. Replace with your own observable stream */
      map(({ message }) => fromCore.Actions.ShowMessage({ message })));
  });

  loadCourse$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromProviderCourses.Actions.LoadCourse),
      /** An EMPTY observable only emits completion. Replace with your own observable stream */
      switchMap(({ institutionId, courseId }) => this.courseService.getCourseById(institutionId, courseId)),
      map((currentCourse) => {
        return fromProviderCourses.Actions.LoadCourseSuccess({ currentCourse })
      }),
      catchError((err) => of(fromProviderCourses.Actions.LoadCourseError({ message: err.message })))
    );
  });

  loadFacultyCVs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromProviderCourses.Actions.LoadFacultyCVList),
      switchMap(({ institutionId, courseId }) => this.institutionService.getCourseFacultyCVs(institutionId, courseId)),
      map((currentFacultyCVs) => {
        return fromProviderCourses.Actions.LoadFacultyCVListSuccess({ currentFacultyCVs })
      }),
      catchError((err) => of(fromProviderCourses.Actions.LoadCourseError({ message: err.message })))
    );
  });

  loadCourseError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromProviderCourses.Actions.LoadCourseError),
      map(({ message }) => fromCore.Actions.ShowMessage({ message })));
  });

  addCourse$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromProviderCourses.Actions.AddCourse),
      concatMap((action) => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromProviderCourses.Selectors.AddCourseArgs)))
      )),
      map(([action, {providerId,uploadedSyllabus}]) => {
        const command = { ...action.command, providerId, syllabus: uploadedSyllabus };
        return command;
      }),
      switchMap((command) => this.courseService.addCourse(command)),
      map((response) => fromCore.Actions.HandleResponse({response}))
    );
  });

  addCourseToStore$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromProviderCourses.Actions.AddCourse),
      concatMap((action) => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromProviderCourses.Selectors.AddCourseForEffects)))
      )),
      map(([{command}, args]) => {
        const {levels, categories, subCategories, institution, provider} = args;
        const institutionSummary:SummaryItem = {id:institution.id, description: institution.institutionName };
        const logoUrl = institution.logoUrl;
        const {id, levelId, categoryId, subCategoryId, tags, code, title, description, requirements, notes, creditHours,creditHourType, sessions, syllabus } =command;
        const level = levels.find(x=>x.id==levelId);
        const categoryItem = categories.find(x=>x.id==categoryId);
        const category= {id: categoryItem.id, description: categoryItem.description};
        const subCategory = subCategories.find(x=>x.id===subCategoryId);
        const providerId = provider.id;
        const courseExchangeId = provider.courseExchangeId;
        const course:ProviderCourseListItemForView = {id: '', courseId: id, providerId, courseExchangeId, institution:institutionSummary, logoUrl,level,category,subCategory,tags:[],
                                                              code, title, requirements, description, notes, creditHours2: creditHours, creditHourType, sessions:[], isActive:true, providerCourseId:'', status:ProviderCourseStatus.Pending,
                                                             isFavourited:false, syllabus, canDeactivate: true, selected: false, canModify:true, courseExchangeApproved: false};
        return fromProviderCourses.Actions.ProviderCourseListItemAdded({course});                                                     
      })
    );
  });
  
  addCourses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromProviderCourses.Actions.AddCourses),
      concatMap((action) => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromProvider.Selectors.Provider)))
      )),
      map(([action, { institutionId, id }]) => {
        const commands = action.commands.map((command => { return { ...command, providerId: id } }));
        return commands;
      }),
      switchMap((commands) => this.courseService.addCourses(commands)),
      map((response) => fromCore.Actions.HandleResponse({response})))
  });
  
  addCourseError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromProviderCourses.Actions.AddCourseError),
      map(({ message }) => fromCore.Actions.ShowMessage({ message })));
  });

  updateCourse$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromProviderCourses.Actions.UpdateCourse),
      concatMap((action) => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromProvider.Selectors.Provider)))
      )),
      switchMap(([action, provider]) => {
        const providerId = provider.id;
        const institutionId = provider.institutionId;
        const command = { ...action.command, providerId };
        return this.courseService.editCourse(institutionId, command);
      }),
      map((response) => fromCore.Actions.HandleResponse({response}))
    );
  });

  updateCourseInStore$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromProviderCourses.Actions.UpdateCourse),
      concatMap((action) => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromProviderCourses.Selectors.AddCourseForEffects)))
      )),
      map(([{command}, args]) => {
        const {categories, subCategories, levels, courseTags, provider, courses} = args;
        const idx = courses.findIndex(x=>x.id==command.id);
        const level = levels.find(x=>x.id==command.levelId);
        var category = categories.find(x=>x.id==command.categoryId);
        var subCategory = subCategories.find(x=>x.id==command.subCategoryId);
        var tags = courseTags.filter(x=>command.tags.findIndex(y=>y==x.id) !== -1);
        const {code, title, creditHours, requirements, description, notes, syllabus, facultyCVIds} = command;
        const course:ProviderCourseListItemForView = {...courses[idx], level, category, subCategory, tags, code, title, creditHours2: creditHours, requirements, description, notes, syllabus };
        return fromProviderCourses.Actions.ProviderCourseListItemUpdated({course});
      })
    )
  });

  loadLevelFilters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromCourseExchange.Actions.LoadCourseExchangeSummarySuccess),
      concatMap((action) => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromRefData.courseLevels)))
      )),
      /** An EMPTY observable only emits completion. Replace with your own observable stream */
      map(([{ courseExchangeSummary }, courseLevels]) => {
        const levelFilters = courseExchangeSummary.courseLevels.map(id => {
          const courseLevel = courseLevels.find(x => x.id === id);
          return {
            id: id,
            type: FilterType.CourseLevel,
            description: !!courseLevel ? courseLevel.description : '',
            descriptionForView: !!courseLevel ? courseLevel.description : '',
            selected: false
          }
        });
        return fromProviderCourses.Actions.LoadCourseLevelFilters({ levelFilters });
      })
    );
  });

  loadCategoryFilters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromCourseExchange.Actions.LoadCourseExchangeSummarySuccess),
      concatMap((action) => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromRefData.courseCategories)))
      )),
      /** An EMPTY observable only emits completion. Replace with your own observable stream */
      map(([{ courseExchangeSummary }, categories]) => {
        const courseCategoryFilters = courseExchangeSummary.courseCategories.map(id => {
          const category = categories.find(x => x.id === id);
          return {
            id: id,
            type: FilterType.CourseCategory,
            description: !!category ? category.description : '',
            descriptionForView: !!category ? category.description : '',
            selected: false
          }
        });
        return fromProviderCourses.Actions.LoadCourseCategoryFilters({ courseCategoryFilters });
      })
    );
  });

  loadSubCategoryFilters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromCourseExchange.Actions.LoadCourseExchangeSummarySuccess),
      concatMap((action) => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromRefData.courseSubCategories)))
      )),
      /** An EMPTY observable only emits completion. Replace with your own observable stream */
      map(([{ courseExchangeSummary }, subCategories]) => {
        const courseSubCategoryFilters = courseExchangeSummary.courseSubCategories.map(id => {
          const subCategory = subCategories.find(x => x.id === id);
          return {
            id: id,
            type: FilterType.CourseSubCategory,
            description: !!subCategory ? subCategory.description : '',
            descriptionForView: !!subCategory ? subCategory.description : '',
            selected: false
          }
        });
        return fromProviderCourses.Actions.LoadCourseSubCategoryFilters({ courseSubCategoryFilters });
      })
    );
  });

  loadTagFilters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromCourseExchange.Actions.LoadCourseExchangeSummarySuccess),
      concatMap((action) => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromRefData.courseTags)))
      )),
      /** An EMPTY observable only emits completion. Replace with your own observable stream */
      map(([{ courseExchangeSummary }, tags]) => {
        const tagFilters = courseExchangeSummary.courseTags.map(id => {
          const tag = tags.find(x => x.id === id);
          return {
            id: id,
            type: FilterType.Tag,
            description: !!tag ? tag.description : '',
            descriptionForView: !!tag ? tag.description : '',
            selected: false
          }
        });
        return fromProviderCourses.Actions.LoadTagFilters({ tagFilters });
      })
    );
  });

  loadProviderFilters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromCourseExchange.Actions.LoadCourseExchangeSummarySuccess),
      /** An EMPTY observable only emits completion. Replace with your own observable stream */
      map(({ courseExchangeSummary }) => {
        const providerFilters = courseExchangeSummary.providers.map(provider => {
          return {
            id: provider.id,
            type: FilterType.Provider,
            description: !!provider ? provider.description : '',
            descriptionForView: !!provider ? provider.description : '',
            selected: false
          }
        });
        return fromProviderCourses.Actions.LoadProviderFilters({ providerFilters });
      })
    );
  });

  masterToggle$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromProviderCourses.Actions.MasterToggle),
      concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromProviderCourses.Selectors.FilteredCourses)))
      )),
      map(([action, courses]) => {
        const isAllSelected = courses.every(x => x.selected);
        return courses.map(x => {
          return {
            ...x,
            selected: !isAllSelected
          }
        })
      }),
      map((courses) => fromProviderCourses.Actions.MasterToggled({ courses }))
    )
  });

  readMore$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromProviderCourses.Actions.ReadMore),
      concatMap((action) => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromProviderCourses.Selectors.CurrentCourse)))
      )),
      tap(([action, course]) => {
        this.dialog.open<CourseDescriptionDialogComponent, ICourseDescriptionDialogData>
          (CourseDescriptionDialogComponent, {
            data: {
              description: course.description,
              title: course.title
            }
          })
      })
    )
  }, { dispatch: false });

  importCourses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromProviderCourses.Actions.ImportCourses),
      concatMap((action) => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromProvider.Selectors.Provider)))
      )),
      /** An EMPTY observable only emits completion. Replace with your own observable stream */
      tap(([action, provider]) =>
        this.excelService.importCourses(provider.institutionId, provider.id, action.file)));
  }, { dispatch: false });

  downloadBulkImport$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromProviderCourses.Actions.DownloadBulkImport),
      concatMap((action) => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromProvider.Selectors.Provider)))
      )),
      switchMap(([action, provider]) => {
        const institutionId = !!action.institutionId ? action.institutionId : provider.institutionId;
        const providerId = !!action.providerId ? action.providerId : provider.id;
        return this.excelService.createCourseBulkImportTemplate(institutionId, providerId);
      }),
      tap((data) => {
        const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        const linkDomObject: HTMLAnchorElement = document.createElement('a');
        linkDomObject.href = URL.createObjectURL(blob);
        linkDomObject.download = `CourseBulkImportTemplate_${Date.now().toString()}.xlsx`;
        document.body.appendChild(linkDomObject);
        linkDomObject.click();
        linkDomObject.remove();
      })
    )
  }, { dispatch: false });

  exportExcel$ = createEffect(() => {
    return this.actions$.pipe(
        ofType(fromProviderCourses.Actions.ExportToExcel),
        concatMap(action => of(action).pipe(
          withLatestFrom(this.store.pipe(select(fromProviderCourses.Selectors.SelectedCourses)))
        )),
        /** An EMPTY observable only emits completion. Replace with your own observable stream */
        tap(([action, courses]) => {
          
          const excelColumns: ExcelColumn[] = new Array<ExcelColumn>(
            {header: 'Provider Name', fieldName: 'institutionName'},
            {header: 'Course Code', fieldName: 'code'},
            {header: 'Course Title', fieldName: 'title'},
            {header: 'Course Level', fieldName: 'courseLevel'},
            {header: 'Course Credits', fieldName: 'creditHours'},
            {header: 'Category', fieldName: 'category'},
            {header: 'Sub Category', fieldName: 'subCategory'},
            {header: 'Course Requirements', fieldName: 'requirements'},
            {header: 'Course Description', fieldName: 'description'},
            {header: 'Notes', fieldName: 'notes'}
          );

          const result = [];

          courses.forEach(course => {
            const excelObject = {
              institutionName: course.institution.description,
              code: course.code,
              title: course.title,
              courseLevel: course.level.description,
              creditHours: `${course.creditHours2} ${this.getCreditHourType(course.creditHourType)} Credit Hours`,
              category: course.category.description,
              subCategory: course.subCategory.description,
              requirements: course.requirements,
              description: course.description,
              notes: course.notes
            };

            result.push(excelObject);
          });

          this.excelService.exportAsExcelFile(result, excelColumns, 'Courses');
        }))
  },{dispatch:false});


  downloadCourseSyllabus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromProviderCourses.Actions.DownloadCourseSyllabus),
      concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromProviderCourses.Selectors.CurrentProviderCourse)))
      )),
      tap(([action, currentProviderCourse]) => {
        if(currentProviderCourse == null){
          this.store.dispatch(fromCore.Actions.ShowMessage({message:'Syllabus can be downloaded after Course is created'}));
        }else{
          const providerId = !!action.providerId ? action.providerId : currentProviderCourse.providerId;
          const courseId = !!action.courseId ? action.courseId : currentProviderCourse.courseId;
          const syllabus = !!action.syllabus ? action.syllabus : currentProviderCourse.syllabus;
          if (!!syllabus) {
            this.courseService.downloadSyllabus(providerId, courseId, syllabus)
          }
        }
      }
      )
    );
  }, { dispatch: false });

  activateCourse$ = createEffect(() => {
    return this.actions$.pipe(
        ofType(fromProviderCourses.Actions.ActivateCourse),
        concatMap((action) => of(action).pipe(
          withLatestFrom(this.store.pipe(select(fromProvider.Selectors.Provider)))
        )),
        switchMap(([action, provider]) => this.courseService.activateCourse(provider.institutionId,action.providerCourseId)),
        map((response)=>fromCore.Actions.HandleResponse({response}))
    )
  });

  deactivateCourse$ = createEffect(() => {
    return this.actions$.pipe(
        ofType(fromProviderCourses.Actions.DeactivateCourse),
        concatMap((action) => of(action).pipe(
          withLatestFrom(this.store.pipe(select(fromProvider.Selectors.Provider)))
        )),
        switchMap(([{providerCourse}, provider]) => {
          if(providerCourse.canDeactivate){
            return this.courseService.deactivateCourse(provider.institutionId,providerCourse.providerCourseId);
          }else{
            const data = new ConfirmDialogData();
            data.title = 'Cannot Deactivate'
            data.message = "You have active enrollments or scheduled this course. You must move all enrollments to 'Closed' status and remove the course from any current or future schedules before deactivating this course";
            data.okButtonText= 'Ok';
            data.showCancelButton = false;

            this.dialog.open(ConfirmDialogComponent, {data})
              .afterClosed()
              .subscribe((result) => {
                if(result){
                  const response:BaseResponse = {isSuccess: true, message: '', validationIssues:[]};
                  return of(response); 
                }
              });
            }
        }),
        map((response)=>fromCore.Actions.HandleResponse({response}))
    )
  });

  updateCourseSyllabus$ = createEffect(() => {
    let courseId = '';
    return this.actions$.pipe(
        ofType(fromProviderCourses.Actions.UpdateCourseSyllabus),
        concatMap((action) => of(action).pipe(
          withLatestFrom(this.store.pipe(select(fromProvider.Selectors.Provider)))
        )),
        switchMap(([{command}, provider]) => {
          courseId = command.courseId;
          return this.providerService.updateSyllabus(provider.institutionId,command);
        }),
        map((elbertFile)=>{
          if(elbertFile){
            return fromProviderCourses.Actions.UpdateCourseSyllabusSuccessful({courseId, elbertFile})
          }else{
            const response: BaseResponse = {
              isSuccess: false,
              message: 'There was an error updating the Syllabus',
              validationIssues: []
            };
            return fromCore.Actions.HandleResponse({response});
          }
        })
    )
  });

  getCreditHourType(creditHourType: CreditHourType): string{
    if(creditHourType == CreditHourType.SEM) return "SEM";
    if(creditHourType == CreditHourType.QR) return "QR";
    return "";
  }
}
