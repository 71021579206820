import {Injectable} from '@angular/core';
import {Actions, ofType, createEffect} from '@ngrx/effects';
import { switchMap, map, catchError, withLatestFrom, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as fromProviderDashboard from '@provider/dashboard/store';
import * as fromProvider from '@provider/store';
import { ProviderService } from '@shared/provider/services';
import * as fromInstitution from '@institution/store';
import { select, Store } from '@ngrx/store';
import { IAppState } from 'app/store/state/app.state';
import { CourseExchangeDashboardViewModel } from '@shared/courseExchange/models';
import { ProviderDashboardViewModel } from '@provider/dashboard/models';
@Injectable()
export class ProviderDashboardEffects {
  constructor(private actions$: Actions, private providerService: ProviderService, private store: Store<IAppState>) 
  {
  }

  loadProviderDashboardData$ = createEffect(() => {
    let providerId ='';
    return this.actions$.pipe(
        ofType(fromProviderDashboard.Actions.LoadDashboardData),
        /** An EMPTY observable only emits completion. Replace with your own observable stream */
        switchMap((action) => {
          const {providerId, academicYearId} = action;
          if(providerId===''){
            return of({
                providerId,
                totalCoursesOffered: 0,
                pendingCourses: 0,
                approvedCourses: 0,
                totalEnrollments: 0,
                newEnrollments: 0,
              })
          }
          return this.providerService.getDashboardData(providerId,academicYearId)
        }),
        map((data) => {
          return fromProviderDashboard.Actions.LoadDashboardDataSuccess({data});
        }),
        catchError((err) => of(fromProviderDashboard.Actions.LoadDashboardDataError({message: err.message})))
    );
  });
}