import { ICourseSchedule } from '@provider/models/course-schedule.models';
import { EAlphabetSort } from 'app/constants/shared';


export const initCourseCalendarViewModelSorts = (): ICourseSchedule.CourseCalendarViewModelSorts => {
  return {
    searchValue: '',
    sortByCode: EAlphabetSort.ascending,
    sortByTitle: EAlphabetSort.ascending,
    currentSortType: 'code'
  }
};
