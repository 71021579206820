<div class="page-layout fullwidth inner-scroll provider-page student-detail-page">
  <!-- <div class="top-bg provider-top-bg"></div> -->
  <div class="center">  
  <elb-page-header [pageTitle]="'Student Details'" [pageSubTitle]="''" (goBackClicked)="goToEnrollments()">
    </elb-page-header>
   <div class="scroll-wrap">
    <div class="content" *ngIf="(student$ | async) as student">
      <ng-container>
        <div class="student-detail-container">
          <!-- Student Main Info -->
          <div class="student-detail-main-info">
            <div class="row">
              <div class="col col-50">
                <div class="box">
                  <div class="box-title">Student ID:</div>
                  <ng-container *ngIf="student.studentNumber; else notExist">
                    <div class="box-info">{{student.studentNumber}}</div>
                  </ng-container>
                </div>
              </div>

              <div class="col col-50">
                <div class="box" *ngIf="(studentDetail$ | async) as studentDetail">
                  <div class="box-title">Student Type:</div>
                  <ng-container *ngIf="studentDetail.studentType; else notExist">
                    <div class="box-info">{{studentDetail.studentType}}</div>
                  </ng-container>
                </div>
              </div>
            </div>
            
            <div class="row main-info">
              <div class="col col-50">
                <div class="row name">
                  <div class="col col-50">
                    <div class="box">
                      <div class="box-title">Last Name:</div>
                      <ng-container *ngIf="student.fullName.lastName; else notExist">
                        <div class="box-info">{{student.fullName.lastName}}</div>
                      </ng-container>
                    </div>
                  </div>
    
                  <div class="col col-50">
                    <div class="box">
                      <div class="box-title">First Name:</div>
                      <ng-container *ngIf="student.fullName.firstName; else notExist">
                        <div class="box-info">{{student.fullName.firstName}}</div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col col-50">
                <div class="row name-extras">
                  <div class="col col-33">
                  <div class="box">
                    <div class="box-title">Middle Initial or Name:</div>
                    <ng-container *ngIf="student.fullName.middleName; else notExist">
                      <div class="box-info">{{student.fullName.middleName}}</div>
                    </ng-container>
                  </div>
                </div>
                <div class="col col-33"  *ngIf="(studentDetail$ | async) as studentDetail">
                  <div class="box">
                    <div class="box-title box-title-with-icon">
                      <span class="box-icon">
                        <mat-icon>calendar_today</mat-icon>
                      </span>
                      DOB:
                    </div>
                    <ng-container *ngIf="student.dateOfBirth; else notExist">
                      <div class="box-info">{{studentDetail.dateOfBirth | date:'shortDate'}}</div>
                    </ng-container>
                  </div>
                </div>
                <div class="col col-50" *ngIf="(studentDetail$ | async) as studentDetail">
                  <div class="box">
                    <div class="box-title">Gender:</div>
                    <ng-container *ngIf="studentDetail.gender; else notExist">
                      <div class="box-info">{{studentDetail.gender}}</div>
                    </ng-container>
                  </div>
                </div>
                </div>
              </div>
              

              
            </div>


          </div>
          <!-- Student Address -->
          <div class="student-detail-address">
            <div class="row address">
              <div class="col col-50">
                <div class="box">
                  <div class="box-title">Address 1:</div>
                  <ng-container *ngIf="student.address.addressLine1; else notExist">
                    <div class="box-info">{{student.address.addressLine1}}</div>
                  </ng-container>
                </div>
              </div>
              <div class="col col-50">
                <div class="box">
                  <div class="box-title">Address 2:</div>
                  <ng-container *ngIf="student.address.addressLine2; else notExist">
                    <div class="box-info">{{student.address.addressLine2}}</div>
                  </ng-container>
                </div>
              </div>
            </div>

            <div class="row address">
              <div class="col col-33">
                <div class="box">
                  <div class="box-title">City:</div>
                  <ng-container *ngIf="student.address.city; else notExist">
                    <div class="box-info">{{student.address.city}}</div>
                  </ng-container>
                </div>
              </div>
              <div class="col col-33">
                <div class="box">
                  <div class="box-title">State:</div>
                  <ng-container *ngIf="student.address.region; else notExist">
                    <div class="box-info">{{student.address.region.description}}</div>
                  </ng-container>
                </div>
              </div>
              <div class="col col-33">
                <div class="box">
                  <div class="box-title">Zip Code:</div>
                  <ng-container *ngIf="student.address.postalCode; else notExist">
                    <div class="box-info">{{student.address.postalCode}}</div>
                  </ng-container>
                </div>
              </div>             
            </div>

          </div>
          <!-- Student Contact -->
          <div class="student-detail-contact">
            <div class="row contact">
              <div class="col col-33">
                <div class="box">
                  <div class="box-title box-title-with-icon">
                  <span class="box-icon">
                    <mat-icon>mail_outline</mat-icon>
                  </span>
                    Email 1:
                  </div>
                  <ng-container *ngIf="student.emailAddress; else notExist">
                    <a [href]="'mailto:' + student.emailAddress.value" class="box-info">{{student.emailAddress.value}}</a>
                  </ng-container>
                </div>
              </div>
              <div class="col col-33">
                <div class="box">
                  <div class="box-title box-title-with-icon">
                  <span class="box-icon">
                    <mat-icon>mail_outline</mat-icon>
                  </span>
                    Email 2:
                  </div>
                  <ng-container *ngIf="student.emailAddress2; else notExist">
                      <a [href]="'mailto:' + student.emailAddress2.value" class="box-info">{{student.emailAddress2.value}}</a>
                  </ng-container>
                </div>
              </div>
              <div class="col col-33">
                <div class="box">
                  <div class="box-title box-title-with-icon">
                  <span class="box-icon">
                    <mat-icon>phone</mat-icon>
                  </span>
                    Phone:
                  </div>
                  <ng-container *ngIf="student.phoneNumber; else notExist">
                    <a [href]="'tel:' + student.phoneNumber.value" class="box-info">{{student.phoneNumber.value}}</a>
                  </ng-container>
                </div>
              </div>

            </div>

            <div class="row contact">

              <div class="col col-33">
                <div class="row nested">

                </div>
              </div>
            </div>
            
          </div>
        </div>

        <ng-template #notExist>
          <div class="box-info">-</div>
        </ng-template>

        <div class="student-profile-history-dropdown dropdown" *ngIf="(studentDetail$ | async) as studentDetail">
          <div class="dropdown-header" (click)="isOpenStudentProfile = !isOpenStudentProfile">
            <div *ngIf="!isOpenStudentProfile" class="icon">
              <mat-icon>add</mat-icon>
            </div>
            <div class="title">Student Profile History</div>
          </div>

          <ng-container *ngIf="isOpenStudentProfile">
            <div class="student-profile-wrap dropdown-body">
              <div class="student-profile" *ngFor="let profile of studentDetail.profileHistory">
                <div class="row">
                  <div class="col col-100">
                    <div class="box">
                      <div class="box-title">Last Updated:</div>
                      <div class="box-info">{{profile.updateDt | date: 'shortDate'}}</div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col col-50">
                    <div class="box">
                      <div class="box-title">Student ID:</div>
                      <ng-container *ngIf="student.studentNumber; else notExist">
                        <div class="box-info">{{profile.studentNumber}}</div>
                      </ng-container>
                    </div>
                  </div>
                  <div class="col col-50">
                    <div class="box">
                      <div class="box-title">Student Type:</div>
                      <ng-container *ngIf="profile.studentType; else notExist">
                        <div class="box-info">{{profile.studentType}}</div>
                      </ng-container>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col col-33">
                    <div class="box">
                      <div class="box-title">Last Name:</div>
                      <ng-container *ngIf="profile.lastName; else notExist">
                        <div class="box-info">{{profile.lastName}}</div>
                      </ng-container>
                    </div>
                  </div>

                  <div class="col col-33">
                    <div class="box">
                      <div class="box-title">First Name:</div>
                      <ng-container *ngIf="profile.firstName; else notExist">
                        <div class="box-info">{{profile.firstName}}</div>
                      </ng-container>
                    </div>
                  </div>

                  <div class="col col-33">
                    <div class="box">
                      <div class="box-title">Middle Initial or Name:</div>
                      <ng-container *ngIf="profile.middleName; else notExist">
                        <div class="box-info">{{profile.middleName}}</div>
                      </ng-container>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col col-100">
                    <div class="box">
                      <div class="box-title">Address 1:</div>
                      <ng-container *ngIf="profile.addressLine1; else notExist">
                        <div class="box-info">{{profile.addressLine1}}</div>
                      </ng-container>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col col-33">
                    <div class="box">
                      <div class="box-title">Address 2:</div>
                      <ng-container *ngIf="profile.addressLine2; else notExist">
                        <div class="box-info">{{profile.addressLine2}}</div>
                      </ng-container>
                    </div>
                  </div>

                  <div class="col col-33">
                    <div class="box">
                      <div class="box-title">City:</div>
                      <ng-container *ngIf="profile.city; else notExist">
                        <div class="box-info">{{profile.city}}</div>
                      </ng-container>
                    </div>
                  </div>

                  <div class="col col-33">
                    <div class="row">
                      <div class="col col-50">
                        <div class="box">
                          <div class="box-title">State:</div>
                          <ng-container *ngIf="profile.region; else notExist">
                            <div class="box-info">{{profile.region}}</div>
                          </ng-container>
                        </div>
                      </div>
                      <div class="col col-50">
                        <div class="box">
                          <div class="box-title">Zip Code:</div>
                          <ng-container *ngIf="profile.postalCode; else notExist">
                            <div class="box-info">{{profile.postalCode}}</div>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col col-66">
                    <div class="box">
                      <div class="box-title box-title-with-icon">
                <span class="box-icon">
                  <mat-icon>mail_outline</mat-icon>
                </span>
                        Email 1:
                      </div>
                      <ng-container *ngIf="profile.emailAddress; else notExist">
                        <div class="box-info">{{profile.emailAddress1}}</div>
                      </ng-container>
                    </div>
                  </div>
                  <div class="col col-33">
                    <div class="box">
                      <div class="box-title box-title-with-icon">
                <span class="box-icon">
                  <mat-icon>phone</mat-icon>
                </span>
                        Phone:
                      </div>
                      <ng-container *ngIf="profile.phoneNumber; else notExist">
                        <div class="box-info">{{profile.phoneNumber}}</div>
                      </ng-container>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="col col-66">
                    <div class="box">
                      <div class="box-title box-title-with-icon">
                <span class="box-icon">
                  <mat-icon>mail_outline</mat-icon>
                </span>
                        Email 2:
                      </div>
                      <ng-container *ngIf="profile.emailAddress2; else notExist">
                        <div class="box-info">{{profile.emailAddress2}}</div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>

        <div class="enrollment-history-dropdown dropdown" *ngIf="(enrollments$ | async) as enrollments">
          <div class="dropdown-header" (click)="isOpenEnrollment = !isOpenEnrollment">
            <div class="icon">
              <mat-icon>add</mat-icon>
            </div>
            <div class="title">Enrollment History</div>
          </div>

          <ng-container *ngIf="isOpenEnrollment">
            <div class="enrollment-wrap dropdown-body">
              <div class="student-enrollment" *ngFor="let enrollment of enrollments">
            
                <div class="row">
                  <div class="col col-15">
                    <div class="box">
                      <div class="box-title">
                        Session:
                      </div>
                      <div class="box-info"> {{formatSessionDates(enrollment)}}</div>
                    </div>
                    
                  </div>
                  <div class="col col-10">
                    <div class="box">
                      <div class="box-title">Course Code
                      </div>
                      <div class="box-info">{{enrollment.providerCourseCode}}
                      </div>
                    </div>
  
                  </div>
                  <div class="col col-25">
                    <div class="box">
                      <div class="box-title">
                        Course Title
                      </div>
                      <div class="box-info">{{enrollment.courseTitle}}</div>
                    </div>
  
                  </div>
                  <div class="col col-50">
                    <div class="row nested">
                      <div class="col col-33">
                        <div class="box">
                          <div class="box-title">
                            Status:
                          </div>
                          <div class="box-info">{{getEnrollmentStatus(enrollment)}}</div>
                        </div>
                      </div>
                      <div class="col col-66">
                        <div class="row nested">
                          <div class="col col-50">
                            <mat-form-field class="full-list-sort" *ngIf="showEditStatus(enrollment)">
                              <mat-select placeholder="Edit Status" [compareWith]="compare" [value]="enrollment.requestedStatus"
                                          (selectionChange)="editStatus($event.value,enrollment.id)">
                                <mat-select-trigger>
                                  <mat-icon>edit</mat-icon>&nbsp;{{enrollment.requestedStatus?.description}}
                                </mat-select-trigger>
                                <mat-option *ngFor="let edit of enrollment.status.providerAllowedStatuses" [value]="edit">
                                  {{edit.description}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                            <mat-form-field class="full-list-sort" *ngIf="showLetterGrade(enrollment)">
                              <mat-select placeholder="Edit Letter Grade" [compareWith]="compare" [value]="enrollment.requestedLetterGrade"
                                          (selectionChange)="editLetterGrade($event.value,enrollment.id)">
                                <mat-select-trigger>
                                  <mat-icon>edit</mat-icon>&nbsp;{{enrollment.requestedLetterGrade?.description}}
                                </mat-select-trigger>
                                <mat-option *ngFor="let edit of letterGrades$ | async" [value]="edit">
                                  {{edit.description}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </div>    
                          <div class="col col-50">
                            <button type="button" class="submit-button icon-btn" mat-button (click)="submitStatusChanges()"
                              [disabled]="!(canSubmitChanges$ | async)" *ngIf="showEditStatus(enrollment)">
                          <mat-icon>save</mat-icon>Submit Changes
                          </button>
                          <button type="button" class="submit-button icon-btn" mat-button (click)="submitLetterGradeChanges()"
                              [disabled]="!(canSubmitLetterGrades$ | async)" *ngIf="showLetterGrade(enrollment)">
                          <mat-icon>save</mat-icon>Submit Grading
                          </button>
                          </div> 
  
                        </div>
                              
  
                    </div>
                    </div>
  
                  </div>
                </div>
              </div>
            </div>
        


              <!-- <table class="enrollment-table">
                <tr *ngFor="let enrollment of enrollments">
                  <th>Session: {{enrollment.sessionStartDate | date: 'shortDate'}} - {{enrollment.sessionEndDate}}</th>
                  <th>{{enrollment.providerCourseCode}}</th>
                  <th>{{enrollment.courseTitle}}</th>
                  <th><b>Status: </b>{{enrollment.status.description}}</th>
                  <th>
                    <mat-form-field class="full-list-sort">
                      <mat-select placeholder="Edit Status" [compareWith]="compare" [value]="enrollment.requestedStatus"
                                  (selectionChange)="editStatus($event.value,enrollment.id)">
                        <mat-select-trigger>
                          <mat-icon>edit</mat-icon>&nbsp;{{enrollment.requestedStatus?.description}}
                        </mat-select-trigger>
                        <mat-option *ngFor="let edit of enrollment.status.providerAllowedStatuses" [value]="edit">
                          {{edit.description}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </th>
                </tr>
              </table> -->
            
            <!-- <button type="button" class="submit-button icon-btn" mat-button (click)="submitStatusChanges()"
                  [disabled]="!(canSubmitChanges$ | async)">
              <mat-icon>save</mat-icon>Submit Changes
            </button> -->
          </ng-container>
        </div>
      </ng-container>
    </div>

   </div>

  </div>
</div>
