import { createAction, props } from '@ngrx/store';
import { FacultyCV } from '@shared/institution/models';
import { AddFacultyCVCommand, UpdateFacultyCVCommand } from '@shared/provider/commands';
import { Sort, Order } from '@core/models';
import { Filter } from '@shared/common/models';
import { ActivateById, DeactivateById } from '@shared/common/models/commands';

export const LoadFacultyCVs = createAction(
    '[Faculty CV List Resolver] - Load Faculty CVs',
    props<{
        institutionId: string;
    }>()
);

export const LoadFacultyCVsSuccess = createAction(
    '[Faculty CV Effects] - Load Faculty CVs Success',
    props<{
        facultyCVs: FacultyCV[]
    }>()
)

export const AddFacultyCV = createAction(
    '[Faculty CV List] - Add Faculty CV',
    props<{
        command: AddFacultyCVCommand
    }>()
)

export const UpdateFacultyCV = createAction(
    '[Faculty CV List] - Update Faculty CV',
    props<{
        command: UpdateFacultyCVCommand
    }>()
)

export const ActivateFacultyCV = createAction(
    '[Faculty CV List] - Activate Faculty CV',
    props<{
        command: ActivateById<FacultyCV>
    }>()
)
export const DeactivateFacultyCV = createAction(
    '[Faculty CV List] - Deactivate Faculty CV',
    props<{
        command: DeactivateById<FacultyCV>
    }>()
)

export const DownloadFacultyCV = createAction(
    '[Various Places] - Download Faculty CV',
    props<{
        facultyCV: FacultyCV
    }>()
);


export const LoadSorts = createAction(
    '[Faculty CV List] - Load Sorts',
    props<{
        sorts: Sort[];
    }>()
)

export const ChangeSort = createAction(
    '[Faculty CV List] - Change Sort',
    props<{
        currentSort: Sort
    }>()
)

export const LoadOrders = createAction(
    '[Faculty CV List] - Load Orders',
    props<{
        orders: Order[];
    }>()
)

export const ChangeOrder = createAction(
    '[Faculty CV List] - Change Order',
    props<{
        currentOrder: Order
    }>()
)

export const ChangeSearchText = createAction(
    '[Faculty CV List] - Change Search Text',
    props<{
        searchText: string
    }>()
)

export const AddFilter = createAction(
    '[Faculty CV List] - Add Filter',
    props<{
        filter: Filter
    }>()
)

export const RemoveFilter = createAction(
    '[Faculty CV List] - Add Filter',
    props<{
        filter: Filter
    }>()
)

export const ToggleStatusFilter = createAction(
    '[Faculty CV List] - Toggle Status Filter',
    props<{
        id: string
    }>()
)

export const FacultyCVAdded = createAction(
    '[Faculty CV Effects] - FacultyCV Added',
    props<{
        facultyCV: FacultyCV
    }>()
)

export const FacultyCVUpdated = createAction(
    '[Faculty CV Effects] - FacultyCV Updated',
    props<{
        facultyCV: FacultyCV
    }>()
)
