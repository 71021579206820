import { ElbertFile } from './../../../../core/models/elbertFile.model';
import { createReducer, on } from '@ngrx/store';
import { ProviderCourseListItemForView, ProviderCourseListItem, getDefaultProvivderCourseListItem } from '@provider/courses/models/providerCourseListItem.model';
import { Filter, SingleFilter, FilterType } from '@shared/common/models';
import { Sort, Order } from '@core/models';
import { ProviderCourseStatus } from '@provider/courses/models/providerCourseStatus.enum';
import { Course, FacultyCV, getDefaultCourse } from '@shared/institution/models';
import { PresetFilterType } from '@provider/courses/store';
import toggleItem from '@shared/common/helpers/toggleItem';
import * as fromAuth from '@auth/store';
import * as fromProviderCourses from '@provider/courses/store';
import * as fromProvider from '@provider/store';
import * as fromStatic from '@provider/courses/store/Static';

export interface ProviderCoursesState {
  courseExchangeId: string;
  providerId: string;
  institutionId: string;
  courses: ProviderCourseListItemForView[];
  areCoursesLoaded: boolean;
  isCoursesLoading: boolean;
  currentCourse: Course;
  currentFacultyCVs: FacultyCV[];
  currentProviderCourse: ProviderCourseListItem;
  isCourseLoading: boolean;
  levelFilters: Filter[];
  courseCategoryFilters: Filter[];
  courseSubCategoryFilters: Filter[];
  tagFilters: Filter[];
  providerFilters: Filter[];
  statusFilters: Filter[];
  sorts: Sort[];
  currentSort: Sort;
  orders: Order[];
  currentOrder: Order;
  searchText: string;
  presetFilterType: SingleFilter;
  uploadedSyllabus: ElbertFile;
}

export const initialCoursesState: ProviderCoursesState = {
  courseExchangeId: '',
  providerId: '',
  institutionId: '',
  courses: [],
  areCoursesLoaded: false,
  isCoursesLoading: false,
  currentCourse: getDefaultCourse(),
  currentFacultyCVs: new Array<FacultyCV>(),
  currentProviderCourse: getDefaultProvivderCourseListItem(),
  isCourseLoading: false,
  levelFilters: [],
  courseCategoryFilters: [],
  courseSubCategoryFilters: [],
  tagFilters: [],
  providerFilters: [],
  statusFilters: [],
  sorts: [],
  currentSort: fromStatic.Sorts.find(x=>x.isDefault) ,
  orders: [],
  currentOrder: fromStatic.Orders.find(x=>x.isDefault),
  searchText: '',
  presetFilterType: { id: PresetFilterType.AllCourses, description: 'All Courses' },
  uploadedSyllabus: undefined
};

export const providerCoursesReducer = createReducer(
  initialCoursesState,
  on(fromAuth.Actions.selectCourseExchangeId, (state,action)=>{
    return initialCoursesState
  }),
  on(fromProvider.Actions.LoadProviderSuccess, (state, action)=>{
    const providerId = action.provider.id;
    const institutionId = action.provider.institutionId;
    const courseExchangeId = action.provider.courseExchangeId;
    return {
      ...state,
      providerId,
      institutionId,
      courseExchangeId
    }
  }),
  on(fromProviderCourses.Actions.LoadCourseLevelFilters, (state, { levelFilters }) => {
    return {
      ...state,
      levelFilters
    }
  }),
  on(fromProviderCourses.Actions.LoadCourseCategoryFilters, (state, { courseCategoryFilters }) => {
    return {
      ...state,
      courseCategoryFilters
    }
  }),
  on(fromProviderCourses.Actions.LoadCourseSubCategoryFilters, (state, { courseSubCategoryFilters }) => {
    return {
      ...state,
      courseSubCategoryFilters
    }
  }),
  on(fromProviderCourses.Actions.LoadTagFilters, (state, { tagFilters }) => {
    return {
      ...state,
      tagFilters
    }
  }),
  on(fromProviderCourses.Actions.LoadProviderFilters, (state, { providerFilters }) => {
    return {
      ...state,
      providerFilters
    }
  }),
  on(fromProviderCourses.Actions.LoadStatusFilters, (state, { statusFilters }) => {
    return {
      ...state,
      statusFilters
    }
  }),
  on(fromProviderCourses.Actions.LoadCourses, (state, { providerId }) => {
    return {
      ...state,
      providerId,
      isCoursesLoading: true
    }
  }),
  on(fromProviderCourses.Actions.LoadCoursesSuccess, (state, { courses }) => {
    return {
      ...state,
      courses,
      isCoursesLoading: false,
      areCoursesLoaded: true
    };
  }),
  on(fromProviderCourses.Actions.LoadCoursesError, (state, action) => {
    return {
      ...state,
      courseExchangeId: '',
      isCoursesLoading: false
    }
  }),
  on(fromProviderCourses.Actions.AddCourseSuccess, (state, action)=>{
    const providerCourse = {...action.providerCourse, selected: false, canModify: true};
    const courses = [...state.courses, providerCourse];
    return {
      ...state,
      courses,
      uploadedSyllabus: undefined
    }
  }),
  on(fromProviderCourses.Actions.ActivateCourse, (state, {providerCourseId})=>{
    const idx = state.courses.findIndex(x=>x.providerCourseId==providerCourseId);
    if(idx===-1) return state;
    const oldProviderCourse = state.courses[idx];
    const status = oldProviderCourse.courseExchangeApproved ? ProviderCourseStatus.Approved : ProviderCourseStatus.Pending;
    const newProviderCourse = {...state.courses[idx], isActive: true, status};
    const courses = [...state.courses.slice(0,idx), newProviderCourse, ...state.courses.slice(idx+1)];
    return{
      ...state,
      courses
    }
  }),
  on(fromProviderCourses.Actions.DeactivateCourse, (state, {providerCourse})=>{
    if(!providerCourse.canDeactivate) return state;
    const idx = state.courses.findIndex(x=>x.providerCourseId==providerCourse.providerCourseId);
    if(idx===-1) return state;
    const newProviderCourse = {...state.courses[idx], isActive: false, status: ProviderCourseStatus.Deactivated};
    const courses = [...state.courses.slice(0,idx), newProviderCourse, ...state.courses.slice(idx+1)];
    return{
      ...state,
      courses
    }
  }),
  on(fromProviderCourses.Actions.UpdateCourseSyllabusSuccessful, (state, {courseId, elbertFile})=>{
    const idx = state.courses.findIndex(x=>x.courseId==courseId);
    const uploadedSyllabus = elbertFile;
    if(idx===-1) return {
      ...state,
      uploadedSyllabus
    };
    const newProviderCourse = {...state.courses[idx], syllabus: elbertFile};
    const courses = [...state.courses.slice(0,idx), newProviderCourse, ...state.courses.slice(idx+1)];
    return{
      ...state,
      courses
    }
  }),
  on(fromProviderCourses.Actions.ProviderCourseListItemAdded, (state, {course})=>{
    if(course.courseExchangeId != state.courseExchangeId) return state;
    const providerCourse = {...course, selected: false, canModify: true};
    const idx = state.courses.findIndex(x=>x.courseId===providerCourse.courseId);
    let courses = new Array<ProviderCourseListItemForView>();
    if(idx===-1){
      courses = [...state.courses, providerCourse];
    }else{
      courses = [...state.courses.slice(0,idx),{...providerCourse, selected:false, canModify: true}, ...state.courses.slice(idx+1)];
    }
    return {
      ...state,
      courses
    }
  }),
  on(fromProviderCourses.Actions.ProviderCourseListItemsDeleted, (state, {courseIds})=>{
    const courses = state.courses.filter(x=>courseIds.findIndex(y=>x.id)!==-1)
    return {
      ...state,
      courses
    }
  }),
  on(fromProviderCourses.Actions.ProviderCourseListItemUpdated, (state, {course})=>{
    if(state.courseExchangeId != course.courseExchangeId) return state;
    const providerCourse = {...course, selected: false, canModify: true};
    const idx = !!state.courses ? state.courses.findIndex(x=>x.id===providerCourse.id): -1;
    if(idx===-1) return state;
    const courses = [...state.courses.slice(0,idx),providerCourse, ...state.courses.slice(idx+1)];
    return {
      ...state,
      courses
    }
  }),
  on(fromProviderCourses.Actions.MasterToggled, (state, action)=>{
    const courses = state.courses.map((course)=>{
      const idx = action.courses.findIndex(x=>x.id===course.id);
      if(idx===-1){
        return course;
      }else{
        return action.courses[idx];
      }
    });
    
    return {
      ...state,
      courses
    }
  }),
  on(fromProviderCourses.Navigate.CourseDetail, (state, {providerCourse})=>{
    return {
      ...state,
      currentProviderCourse: providerCourse
    }
  }),
  on(fromProviderCourses.Navigate.CourseList, (state, action)=>{
    return {
      ...state,
      currentProviderCourse: getDefaultProvivderCourseListItem(),
      currentCourse: getDefaultCourse(),
      searchText: ''
    }
  }),
  on(fromProviderCourses.Navigate.CourseAdd, (state, action)=>{
    return {
      ...state,
      currentProviderCourse: getDefaultProvivderCourseListItem(),
      currentCourse: getDefaultCourse()
    }
  }),
  on(fromProviderCourses.Actions.LoadCourseSuccess, (state, { currentCourse }) => {
    return {
      ...state,
      currentCourse,
      isCourseLoading: false
    };
  }),
  on(fromProviderCourses.Actions.LoadCoursesError, (state, action) => {
    return {
      ...state,
      courseExchangeId: '',
      isCourseLoading: false
    }
  }),
  on(fromProviderCourses.Actions.LoadFacultyCVListSuccess, (state, { currentFacultyCVs }) => {
    return {
      ...state,
      currentFacultyCVs
    };
  }),
  on(fromProviderCourses.Actions.LoadSorts, (state, { sorts }) => {
    return {
      ...state,
      sorts
    }
  }),
  on(fromProviderCourses.Actions.ChangeSort, (state, {currentSort})=>{
    return {
      ...state,
      currentSort
    }
  }),
  on(fromProviderCourses.Actions.LoadOrders, (state, { orders }) => {
    return {
      ...state,
      orders
    }
  }),
  on(fromProviderCourses.Actions.ChangeOrder, (state, {currentOrder})=>{
    return {
      ...state,
      currentOrder
    }
  }),
  on(fromProviderCourses.Actions.ChangeSearchText, (state, {searchText})=>{
    return {
      ...state,
      searchText
    }
  }),
  on(fromProviderCourses.Actions.ChangePresetFilterType, (state, action)=>{
    const presetFilterType = action.presetFilterType;
    let statusFilters = state.statusFilters.map(x=>{return {...x,selected: false}});
    let providerFilters = state.providerFilters.map(x=>{return {...x, selected: false}});
    switch(presetFilterType){
      case PresetFilterType.AllCourses:
        statusFilters = statusFilters.map(x=>{ return{...x, selected: false}});
        break;
      case PresetFilterType.ApprovedCourses:
        statusFilters = toggleItem(statusFilters, ProviderCourseStatus.Approved);
        providerFilters = toggleItem(providerFilters, state.providerId);
        break;
      case PresetFilterType.PendingCourses:
        statusFilters = toggleItem(statusFilters, ProviderCourseStatus.Pending);
        providerFilters = toggleItem(providerFilters, state.providerId);
        break;
    }
    return {
      ...state,
      statusFilters,
      providerFilters
    }
  }),
  on(fromProviderCourses.Actions.ToggleCourseLevelFilter, (state, { id }) => {
    const levelFilters = toggleItem(state.levelFilters, id);
    return {
      ...state,
      levelFilters
    }
  }),
  on(fromProviderCourses.Actions.ToggleCategoryFilter, (state, { id }) => {
    const courseCategoryFilters = toggleItem(state.courseCategoryFilters, id);
    return {
      ...state,
      courseCategoryFilters
    }
  }),
  on(fromProviderCourses.Actions.ToggleSubCategoryFilter, (state, { id }) => {
    const courseSubCategoryFilters = toggleItem(state.courseSubCategoryFilters, id);
    return {
      ...state,
      courseSubCategoryFilters
    }
  }),
  on(fromProviderCourses.Actions.ToggleTagFilter, (state, { id }) => {
    const tagFilters = toggleItem(state.tagFilters, id);
    return {
      ...state,
      tagFilters
    }
  }),
  on(fromProviderCourses.Actions.ToggleProviderFilter, (state, { id }) => {
    const providerFilters = toggleItem(state.providerFilters, id);
    return {
      ...state,
      providerFilters
    }
  }),
  on(fromProviderCourses.Actions.ToggleStatusFilter, (state, { id }) => {
    const statusFilters = toggleItem(state.statusFilters, id);
    return {
      ...state,
      statusFilters
    }
  }),
  on(fromProviderCourses.Actions.RemoveFilter, (state, { filter }) => {
    let levelFilters = state.levelFilters;
    let courseCategoryFilters = state.courseCategoryFilters;
    let courseSubCategoryFilters = state.courseSubCategoryFilters;
    let tagFilters = state.tagFilters;
    let providerFilters = state.providerFilters;
    let statusFilters = state.statusFilters;
    switch (filter.type) {
      case FilterType.CourseLevel:
        levelFilters = toggleItem(levelFilters, filter.id);
        break;
      case FilterType.CourseCategory:
        courseCategoryFilters = toggleItem(courseCategoryFilters, filter.id);
        break;
      case FilterType.CourseSubCategory:
        courseSubCategoryFilters = toggleItem(courseSubCategoryFilters, filter.id);
        break;
      case FilterType.Tag:
        tagFilters = toggleItem(tagFilters, filter.id);
        break;
      case FilterType.Provider:
        providerFilters = toggleItem(providerFilters, filter.id);
        break;
      case FilterType.ProviderCourseStatus:
        statusFilters = toggleItem(statusFilters, filter.id);
        break;
    }
    return {
      ...state,
      levelFilters,
      courseCategoryFilters,
      courseSubCategoryFilters,
      tagFilters,
      providerFilters,
      statusFilters
    }
  }),
  on (fromProviderCourses.Actions.ToggleCourse, (state, {id})=>{
    const courses = toggleItem(state.courses, id);
    return {
      ...state,
      courses
    }
  }),
  on(fromProvider.Actions.ClearState, (state, action)=>{
    return initialCoursesState
  })
)
