import { AcademicYear } from "@shared/calendar/models";
import convertAcademicYearDates from "./convertAcademicYearDates";
import convertSemesterDates from "./convertSemesterDates";

export default (academicYears:AcademicYear[]): AcademicYear[] => {
    return academicYears.map((academicYear)=>{
        return convertAcademicYearDates(academicYear);
      });
  }

  