import { createEffect, Actions, ofType } from "@ngrx/effects";
import { Injectable } from "@angular/core";
import { map, switchMap, withLatestFrom, concatMap, tap } from "rxjs/operators";
import * as fromProvider from '@provider/store';
import * as fromCourseExchange from '@courseExchange/store';
import { ProviderService } from '@shared/provider/services';
import { of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { IAppState } from 'app/store/state/app.state';
import { RemoveFacultyCVFromCourseCommand, AddNewFacultyCVToCourseCommand } from '@shared/provider/commands';
import * as fromCore from '@core/store';
import { CourseService } from '@shared/provider/services/course.service';
import * as fromInstitution from '@institution/store';
import * as fromWizard from '@shared/wizard/store';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogData, ConfirmDialogComponent } from '@core/components/confirm-dialog/confirm-dialog.component';
@Injectable()
export class ProviderEffects {
        constructor(private actions$: Actions, private providerService: ProviderService,
                private courseService: CourseService, private store: Store<IAppState>, 
         private matDialog: MatDialog) {
        }

        loadPolicies$ = createEffect(() => {
                return this.actions$.pipe(
                        ofType(fromProvider.Actions.LoadPolicies),
                        switchMap((action) => this.providerService.getProviderPolicies(action.institutionId)),
                        map((providerPolicies) =>
                                fromProvider.Actions.LoadPoliciesSuccess({ providerPolicies }))
                );
        });

        loadProvider$ = createEffect(() => {
                return this.actions$.pipe(
                        ofType(fromProvider.Actions.LoadProvider),
                        switchMap((action) => this.providerService.getProviderById(action.providerId)),
                        map((provider) =>
                                fromProvider.Actions.LoadProviderSuccess({ provider }))
                );
        });

        uploadProviderPolicy$ = createEffect(() => {
                let providerPolicyId = '';
                return this.actions$.pipe(
                        ofType(fromProvider.Actions.uploadProviderPolicy),
                        tap((action) => providerPolicyId = action.providerPolicyId),
                        concatMap(action => of(action).pipe(
                                withLatestFrom(this.store.pipe(select(fromProvider.Selectors.InstitutionId)))
                        )),
                        switchMap(([action, institutionId]) => this.providerService.uploadProviderPolicy(institutionId, action.providerPolicyId, action.policyFile)),
                        map((response) => fromCore.Actions.HandleResponse({response}))
                );
        });

        downloadProviderPolicy$ = createEffect(() => {
                return this.actions$.pipe(
                        ofType(fromProvider.Actions.downloadProviderPolicy),
                        tap((action) => this.providerService.downloadProviderPolicy(action.providerPolicy))
                );
        }, { dispatch: false });

        completeProviderSetup$ = createEffect(() => {
                return this.actions$.pipe(
                        ofType(fromProvider.Actions.completeProviderSetup),
                        concatMap((action) => of(action).pipe(
                                withLatestFrom(this.store.pipe(select(fromProvider.Selectors.ProviderId)))
                        )),
                        switchMap(([, providerId]) => this.providerService.completeProviderSetup(providerId)),
                        map((response) => fromCore.Actions.HandleResponse({response}))
                )
        });

        removeFacultyCVFromCourse$ = createEffect(() => {
                return this.actions$.pipe(
                        ofType(fromProvider.Actions.RemoveFacultyCVFromCourse),
                        concatMap(action => of(action).pipe(
                                withLatestFrom(this.store.pipe(select(fromProvider.Selectors.InstitutionId)))
                        )),
                        switchMap(([action, institutionId]) => {
                                const command: RemoveFacultyCVFromCourseCommand = { facultyCVId: action.facultyCVId, courseId: action.courseId };
                                return this.providerService.removeFacultyCVFromCourse(institutionId, command)
                        }),
                        map((response) => fromCore.Actions.HandleResponse({response}))
                )
        });
        addNewFacultyCVFromCourse$ = createEffect(() => {
                return this.actions$.pipe(
                        ofType(fromProvider.Actions.AddNewFacultyCVToCourse),
                        concatMap(action => of(action).pipe(
                                withLatestFrom(this.store.pipe(select(fromProvider.Selectors.InstitutionId)))
                        )),
                        switchMap(([action, institutionId]) => {
                                const command: AddNewFacultyCVToCourseCommand = {
                                        institutionId,
                                        courseId: action.courseId,
                                        description: action.description,
                                        file: action.file
                                };
                                return this.providerService.addNewFacultyCVToCourse(institutionId, command);
                        }),
                        map((response) => fromCore.Actions.HandleResponse({response})))
        });

        notifyNotComplete$ = createEffect(() => {
                return this.actions$.pipe(
                        ofType(fromProvider.Actions.NotifyNotComplete),
                        concatMap((action) => of(action).pipe(
                                withLatestFrom(this.store.pipe(select(fromInstitution.Selectors.FirstIncompleteProviderChecklistItem)))
                        )),
                        /** An EMPTY observable only emits completion. Replace with your own observable stream */
                        tap(([, firstIncompleteType]) => {
                                const data = new ConfirmDialogData();
                                data.title = 'Setup Incomplete'
                                data.message = 'Your Provider Wizard is incomplete.  An icon will appear on your wizard dashboard to indicate which items have not been completed.  Once all  required items of the Provider Wizard are complete, the Administrator of the Course Exchange will be notified.'
                                data.okButtonText = 'Ok';
                                data.showCancelButton = false;


                                this.matDialog.open(ConfirmDialogComponent, { data })
                                        .afterClosed()
                                        .subscribe(() => this.store.dispatch(fromWizard.Actions.changeCurrentStepId({ stepId: firstIncompleteType.itemType })));
                        }
                        ));
        }, { dispatch: false });

        loadCourseExchangeSummary$ = createEffect(() => {
                return this.actions$.pipe(
                        ofType(fromProvider.Actions.LoadCourseExchangeSummary),
                        /** An EMPTY observable only emits completion. Replace with your own observable stream */
                        switchMap(({providerId}) => this.providerService.getCourseExchangeSummary(providerId)),
                        map((courseExchangeSummary)=> fromCourseExchange.Actions.LoadCourseExchangeSummarySuccess({courseExchangeSummary}))
                );
            });
}
