import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'elbert-file',
  templateUrl: './elbert-file.component.html',
  styleUrls: ['./elbert-file.component.scss']
})
export class ElbertFileComponent implements OnInit {
  @Input() elbertFile: UntypedFormGroup;
  @Input() descriptionLabel: string = 'File Name'
  @Output() fileSelected: EventEmitter<File> = new EventEmitter<File>();
  uploading: boolean;
  constructor() { }

  ngOnInit(): void {
  }

  onFileSelected(event:any): void {
    var file = event.target.files[0];
    this.fileSelected.emit(file);
  }


}
