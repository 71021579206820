import {
    createReducer, on  } from '@ngrx/store';
import { User, UserListItemForView } from '@shared/user/models';
import * as fromStore from '..'
import { Sort, Order } from '@core/models';
import { Filter, FilterType } from '@shared/common/models';
import * as fromAuth from '@auth/store';
import toggleItem from '@shared/common/helpers/toggleItem';
import initStatusFIlters from '@shared/common/helpers/DefaultStatusList';
import baseListItemListToFilterArray from '@shared/common/helpers/baseListItemListToFilterArray';
import stringListToFilterArray from '@shared/common/helpers/stringListToFilterArray';
import { ViewContext } from '@auth/models/viewContext.enum';

  export interface UserListState {
      users: UserListItemForView[],
      currentUser: UserListItemForView,
      sorts: Sort[],
      currentSort: Sort,
      currentOrder: Order,
      courseExchangeFilters: Filter[],
      institutionFilters: Filter[],
      institutionRoleFilters: Filter[],
      statusFilters: Filter[],
      searchText: string,
      exportToExcel:boolean
  };
  
  export const initialUserListState: UserListState = {
      users: [],
      currentUser: undefined,
      sorts: [],
      currentSort: undefined,
      currentOrder: undefined,
      courseExchangeFilters: [],
      institutionFilters: [],
      institutionRoleFilters: [],
      statusFilters: initStatusFIlters(),
      searchText: '',
      exportToExcel:false,
  };
  
  export const userListReducer = createReducer(
    initialUserListState,
    on(fromAuth.Actions.selectCourseExchangeId, (state,action)=>{
        return initialUserListState
    }),
    on(fromStore.Actions.LoadUsers, (state, action)=>{
        const users = action.users.map(x=>{
            return {
                ...x,
                accessRoles: x.accessRoles.filter(x=>x.viewContext===ViewContext.Member || x.viewContext===ViewContext.Provider || x.viewContext===ViewContext.Institution),
                selected: false,
                accordianFlag: false
            }
        });
        return{
            ...state,
            users
        }
    }),
    on(fromStore.Actions.LoadSorts, (state, {sorts})=>{
        let currentSort = sorts.find(x=>x.isDefault);
        return {
            ...state,
            sorts,
            currentSort
        }
    }),
    on(fromStore.Actions.ChangeOrder, (state, {currentOrder})=>{
        return {
            ...state,
            currentOrder
        }
    }),
    on(fromStore.Actions.ChangeSort, (state, {currentSort})=>{
        return {
            ...state,
            currentSort
        }
    }),
    on(fromStore.Actions.LoadCourseExchanges, (state, {courseExchanges})=>{
        const courseExchangeFilters = courseExchanges.map(courseExchange =>{
            return {
                id: courseExchange.courseExchangeId,
                description: courseExchange.name,
                descriptionForView: courseExchange.name,
                selected: false,
                type: FilterType.CourseExchange
            }
        });

        return {
            ...state,
            courseExchangeFilters
        }
    }),
    on(fromStore.Actions.ToggleCourseExchangeFilter, (state, {id}) =>{
        const courseExchangeFilters = toggleItem(state.courseExchangeFilters,id);
        return {
            ...state,
            courseExchangeFilters
        }
    }),
    on(fromStore.Actions.LoadInstitutions, (state, {institutions}) =>{
        const institutionFilters = institutions.map(institution =>{
            return {
                id: institution.id,
                description: institution.institutionName,
                descriptionForView: institution.institutionName,
                selected: false,
                type: FilterType.Institution
            }
        });
        return {
            ...state,
            institutionFilters
        }
    }),
    on(fromStore.Actions.ToggleInstitutionFilter, (state, {id}) =>{
        const institutionFilters = toggleItem(state.institutionFilters,id);
        return {
            ...state,
            institutionFilters
        }
    }),
    on(fromStore.Actions.ToggleInstitutionRoleFilter, (state, {id}) =>{
        const institutionRoleFilters = toggleItem(state.institutionRoleFilters,id);
        return {
            ...state,
            institutionRoleFilters
        }
    }),
    on(fromStore.Actions.LoadUserStatuses, (state, {statuses}) =>{
        const statusFilters = stringListToFilterArray(statuses, FilterType.EntityStatus);
        return {
            ...state,
            statusFilters
        }
    }),
    on(fromStore.Actions.LoadInstitutionRoles, (state, {institutionRoles})=>{
        const institutionRoleFilters = baseListItemListToFilterArray(institutionRoles, FilterType.InstitutionRole);
        return {
            ...state,
            institutionRoleFilters
        }
    }),
    on(fromStore.Actions.ToggleStatusFilter, (state, {id}) =>{
        const statusFilters = toggleItem(state.statusFilters,id);
        return {
            ...state,
            statusFilters
        }
    }),
    on(fromStore.Actions.RemoveFilter, (state, {filter})=>{
        let courseExchangeFilters = state.courseExchangeFilters;
        let institutionFilters = state.institutionFilters;
        let institutionRoleFilters = state.institutionRoleFilters;
        let statusFilters = state.statusFilters;
        switch(filter.type){
            case FilterType.CourseExchange:
                courseExchangeFilters = toggleItem(courseExchangeFilters,filter.id);
                break;
            case FilterType.Institution:
                institutionFilters = toggleItem(institutionFilters,filter.id);
                break;
            case FilterType.InstitutionRole:
                institutionRoleFilters = toggleItem(institutionRoleFilters,filter.id);
                break;
            case FilterType.Status:
                statusFilters = toggleItem(statusFilters,filter.id);
                break;
        }
        return {
            ...state,
            courseExchangeFilters,
            institutionFilters,
            institutionRoleFilters,
            statusFilters
        }
    }),
    on(fromStore.Actions.ChangeSearchText, (state, {searchText})=>{
        return {
            ...state,
            searchText
        }
    }),
    on(fromStore.Actions.MasterToggle, (state, action) => {
        const users = [...state.users.map(x=> {
            return {
                ...x,
                selected: !action.isAllSelected
            }
        })];
        return {
            ...state,
            users
        };
    }),
    on(fromStore.Actions.ToggleUser, (state, action)=>{
        const users = toggleItem(state.users,action.user.id);
        return {
            ...state,
            users
        }
    }),
    on(fromStore.Actions.Clear, (state, action)=>{
        return initialUserListState
    }),
    on(fromStore.Actions.exportToExcel, (state, action)=>{
        return {
            ...state,
            exportToExcel:action.exportToExcel
        }
    }),
    on(fromStore.Actions.SaveInstitutionAccessRole, (state, {accessRole,courseExchangeRoles, institutionRoles})=>{
        const userIdx = state.users.findIndex(x=>x.id==accessRole.userId);
        const oldUser = state.users[userIdx];
        const institutionRole = institutionRoles.find(x=>x.id==accessRole.roleId);
        const accessRoles = oldUser.accessRoles.map((item)=>{
            const courseExchangeRole = courseExchangeRoles.find(x=>x.shortDescription==institutionRole.description);
            switch(item.viewContext){
                case ViewContext.Institution:
                    return {...item, roleDescription: institutionRole.description, roleId: institutionRole.id};
                    break;
                case ViewContext.Provider:
                    return {...item, roleDescription: `Provider - ${courseExchangeRole.shortDescription}`, roleId: courseExchangeRole.id};
                    break;
                case ViewContext.Member:
                    return {...item, roleDescription: `Member - ${courseExchangeRole.shortDescription}`, roleId: courseExchangeRole.id};
                    break;
            }
        });
        const newUser = {...state.users[userIdx], institutionRoleId: institutionRole.id, institutionRole: institutionRole.description, accessRoles};

        const users = [...state.users.slice(0,userIdx), newUser, ...state.users.slice(userIdx+1)];

        return{
            ...state,
            users
        }
    })
);
  
  
  
