import { createEffect, Actions, ofType } from "@ngrx/effects";
import { Injectable } from "@angular/core";
import { map, switchMap, tap } from "rxjs/operators";
import { MemberService } from 'app/shared/member/services';
import { MatDialog } from "@angular/material/dialog";
import * as fromStore from '@shared/member/store';
import * as fromCore from '@core/store';
import { StudentMergeDataDialogComponent, StudentMergeDataDialogData } from "@shared/member/components/dialogs/student-merge-data-dialog/student-merge-data-dialog.component";
import * as FileSaver from 'file-saver';
import { BulkDataMigrationDialogComponent, BulkMergeDataDialogData } from "@shared/member/components/dialogs/bulk-data-migration-dialog/bulk-data-migration-dialog.component";
import { EnrollmentMigrationDialogComponent, EnrollmentMigrationDialogData } from "@shared/member/components/dialogs/enrollment-migration-dialog/enrollment-migration-dialog.component";
import { DataMigrationDialogComponent, DataMigrationDialogData } from "@shared/member/components/dialogs/data-migration-dialog/data-migration-dialog.component";
import { BaseResponse } from "@core/models";
@Injectable()
export class MemberSharedEffects {
  constructor(private actions$: Actions, private dialog: MatDialog, private memberService: MemberService) {
  }

  showMigrateData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromStore.Actions.ShowMigrateData),
      tap((action) => this.dialog.open<DataMigrationDialogComponent, DataMigrationDialogData>(DataMigrationDialogComponent, {
        data: {
          memberId : action.memberId,
          institutionId: action.institutionId
        }
      })));
  }, { dispatch: false });

  selectStudentMergeData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromStore.Actions.SelectStudentMergeDataFile),
      tap((action) => this.dialog.open<StudentMergeDataDialogComponent, StudentMergeDataDialogData>(StudentMergeDataDialogComponent, {
        data: {
          memberId : action.memberId,
          institutionId: action.institutionId
        }
      })));
  }, { dispatch: false });

  studentMergeData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromStore.Actions.StudentMergeData),
      switchMap(({ memberId, studentMergeData, institutionId }) => {
        return this.memberService.mergeStudentData(memberId, institutionId, studentMergeData);
      }),
      map(({message}) => {
        return fromCore.Actions.ShowMessage({ message })
      })
    )
  })

  getstudentMergeData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromStore.Actions.GetStudentMergeData),
      switchMap(({ memberId, institutionId }) => {
        return this.memberService.getStudentData(memberId, institutionId);
      }),
      map((data: any) => {
        var json = JSON.stringify(data);
        var blob = new Blob([json], { type: "application/json" });
        FileSaver.saveAs(blob, `Student Data - ${Date.now().toString()}.json`)
      })
    )
  }, { dispatch: false });

  getMemberCourseMergeData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromStore.Actions.GetMemberCourseMergeData),
      switchMap(({ memberId, institutionId }) => {
        return this.memberService.getMemberCourseData(memberId,institutionId);
      }),
      map((data: any) => {
        var json = JSON.stringify(data);
        var blob = new Blob([json], { type: "application/json" });
        FileSaver.saveAs(blob, `Member Course Data - ${Date.now().toString()}.json`)
      })
    )
  }, { dispatch: false });

  selectEnrollmentMigrationData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromStore.Actions.SelectEnrollmentMigrationDataFile),
      tap((action) => this.dialog.open<EnrollmentMigrationDialogComponent, EnrollmentMigrationDialogData>(EnrollmentMigrationDialogComponent, {
        data: {
          memberId : action.memberId,
          institutionId: action.institutionId
        }
      })));
  }, { dispatch: false });

  memberEnrollmentMigrationData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromStore.Actions.EnrollmentMigrationData),
      switchMap(({ memberId,institutionId, enrollmentMigrationData }) => {
        return this.memberService.enrollmentMigrationData(memberId,institutionId, enrollmentMigrationData);
      }),
      map((message: any) => {
        return fromCore.Actions.ShowMessage({ message })
      })
    )
  })

  getMemberEnrollmentMigrationData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromStore.Actions.GetEnrollmentMigrationData),
      switchMap(({ memberId, institutionId }) => {
        return this.memberService.getEnrollmentMigrationData(memberId, institutionId);
      }),
      map((data: any) => {
        var json = JSON.stringify(data);
        var blob = new Blob([json], { type: "application/json" });
        FileSaver.saveAs(blob, `Enrollment Data - ${Date.now().toString()}.json`)
      })
    )
  }, { dispatch: false });

  memberEnrollmentMigrationDataSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromStore.Actions.EnrollmentMigrationDataSuccess),
      /** An EMPTY observable only emits completion. Replace with your own observable stream */
      map(() => fromCore.Actions.ShowMessage({ message: "Enrollment Data Uploaded Successfully" })));
  });

  getMemberGradeData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromStore.Actions.GetGradeData),
      switchMap(({ memberId, institutionId }) => {
        return this.memberService.getGradeData(memberId, institutionId);
      }),
      map((data: any) => {
        var json = JSON.stringify(data);
        var blob = new Blob([json], { type: "application/json" });
        FileSaver.saveAs(blob, `Grade Data - ${Date.now().toString()}.json`)
      })
    )
  }, { dispatch: false });

              BulkMergeData$ = createEffect(() => {
                return this.actions$.pipe(
                  ofType(fromStore.Actions.BulkMergeData),
                  tap((action) => this.dialog.open<BulkDataMigrationDialogComponent, BulkMergeDataDialogData>(BulkDataMigrationDialogComponent, {
                    data: {
                      typename: action.typename
                    }
                  })));
              }, { dispatch: false });

}