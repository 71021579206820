import { createEffect, Actions, ofType } from "@ngrx/effects";
import { Injectable } from "@angular/core";
import { map, switchMap, tap } from "rxjs/operators";
import * as fromUsers from '@shared/user/store';
import { UserService } from '@shared/user/services';
import { IAppState } from 'app/store/state/app.state';
import { Store } from '@ngrx/store';
import { RealTimeService } from "@core/services/realTime.service";
import { UserListItem } from "@shared/user/models";
import { MatDialog } from "@angular/material/dialog";
import { UserDetailComponent } from "@shared/user/components";
import { AccessRoleSummary } from "@auth/models/accessRoleSummary";

@Injectable()
export class UsersEffects {
        constructor(private actions$: Actions, private userService: UserService, private store: Store<IAppState>,
            private realTimeService: RealTimeService, private dialog: MatDialog) {
                this.initSignalRMessages();
        }

        private initSignalRMessages() {
            this.realTimeService.hubConnection$.subscribe(hubConnection => {
                    if (!hubConnection) return;
                    hubConnection.on('UserListItemUpdated', (user: UserListItem) => {
                            this.store.dispatch(fromUsers.Actions.UserUpdated({ user }));
                    });
                    hubConnection.on('UserListItemAdded', (user: UserListItem) => {
                        this.store.dispatch(fromUsers.Actions.UserAdded({ user }));
                    });
                    hubConnection.on('UserListItemDeleted', (user:UserListItem)=>{
                        this.store.dispatch(fromUsers.Actions.UserDeleted({user}));
                    });
                    hubConnection.on('AccessRoleSummaryUpdated', (accessRole: AccessRoleSummary) => {
                        this.store.dispatch(fromUsers.Actions.AccessRoleUpdated({ accessRole }));
                    });
                    hubConnection.on('AccessRoleSummaryAdded', (accessRole: AccessRoleSummary) => {
                        this.store.dispatch(fromUsers.Actions.AccessRoleAdded({ accessRole }));
                    });
                    hubConnection.on('AccessRoleSummaryDeleted', (accessRole: AccessRoleSummary)=>{
                        this.store.dispatch(fromUsers.Actions.AccessRoleDeleted({accessRole}));
                    });
            })
        }


        loadUsers$ = createEffect(() => {
                return this.actions$.pipe(
                        ofType(fromUsers.Actions.LoadUsers),
                        switchMap((action) => this.userService.getUserList(action.institutionId)),
                        map((users) => fromUsers.Actions.LoadUsersSuccess({ users }))
                );
        });

        loadUser$ = createEffect(() => {
                return this.actions$.pipe(
                        ofType(fromUsers.Actions.LoadUser),
                        switchMap(({institutionId,userId}) => this.userService.getUserById(institutionId,userId)),
                        map((currentUser) => fromUsers.Actions.LoadUserSuccess({ currentUser }))
                );
        });

        effectName$ = createEffect(() => {
                return this.actions$.pipe(
                        ofType(fromUsers.Navigate.UserDetail),
                        /** An EMPTY observable only emits completion. Replace with your own observable stream */
                        tap(({institutionId, userId})=>{
                            if(userId==='new'){
                                return null;
                            }else{
                                this.store.dispatch(fromUsers.Actions.LoadUser({institutionId,userId}));
                            }
                        }),
                        tap(({institutionId, userId})=>this.dialog.open(UserDetailComponent,{
                                width: '1250px',
                                height: '500px',
                                data: {
                                  userId,
                                  institutionId      
                                }
                        }))
                )
            },{dispatch: false});
}
