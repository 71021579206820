import { createFeatureSelector, createSelector } from "@ngrx/store";
import { SuperAdminDashboardState } from '../reducers';
import * as fromAdmin from '@admin/store';

const selectSuperAdminDashboardState = createFeatureSelector<SuperAdminDashboardState>(fromAdmin.adminDashboardFeatureKey);

export const TotalInstitutions = createSelector(
  selectSuperAdminDashboardState,
  (state) => state.totalInstitutions
)
export const TotalCourseExchanges = createSelector(
  selectSuperAdminDashboardState,
  (state) => state.totalCourseExchanges
)
export const PendingCourseExchanges = createSelector(
  selectSuperAdminDashboardState,
  (state) => state.pendingCourseExchanges
)
export const  IsLoadDashboardData  = createSelector(
  selectSuperAdminDashboardState,
  (state) => state.isLoadDashboardData
)
