import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { featureKey } from '.';
import { authReducer } from './reducers';
import { AuthEffects } from './auth.effects';

@NgModule({
  imports: [
    StoreModule.forFeature(featureKey, authReducer),
    EffectsModule.forFeature([AuthEffects])
  ],
  declarations: [],
  exports: [],
  
})
export class AuthenticationStoreModule { }
