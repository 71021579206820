import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {select, Store} from '@ngrx/store';
import {IAppState} from '../../../store/state/app.state';
import {AccessRole} from '../../../authentication/models/accessRole';
import {Observable, of, Subject} from 'rxjs';
import {map, switchMap, takeUntil, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import { UserNotification } from 'app/shared/common/models';
import * as fromNotification from '@core/notifications/store';
import { AuthenticationService } from '@auth/services/authentication.service';
import { ViewContext } from '@auth/models/viewContext.enum';
import * as fromAuth from '@auth/store';
import { UserSummary } from '@auth/models/userSummary.model';

@Component({
  selector: 'notification-bar',
  templateUrl: './notification-bar.component.html',
  styleUrls: ['./notification-bar.component.scss']
})
export class NotificationBarComponent implements OnInit {
  notifications$: Observable<UserNotification[]>;
  notificationCount$: Observable<number>;
  isNotificationsOpen$: Observable<boolean>;
  currentUser$: Observable<UserSummary>;
  @Input() accessRole: AccessRole;

  constructor(
    private store: Store<IAppState>,
    private router: Router,
    private authService: AuthenticationService
  ) {
    this.store.dispatch(fromNotification.Actions.LoadNotifications());
    this.notifications$ = this.store.pipe(select(fromNotification.Selectors.Notifications));
    this.notificationCount$ = this.store.pipe(select(fromNotification.Selectors.NotificationsCount));
    this.isNotificationsOpen$ = this.store.pipe(select(fromNotification.Selectors.IsNotificationsOpen));
    this.currentUser$ = this.store.pipe(select(fromAuth.Selectors.CurrentUser));
  }

  ngOnInit(): void {}

  navigateTo(notification:UserNotification): void {
    const {courseExchangeId, entityId, viewContext} = notification; 
    this.markAsRead(notification);
    
    this.currentUser$.pipe(
      tap((currentUser)=>{
        var accessRole = currentUser.accessRoles.find(x=>x.entityId===entityId && x.viewContext===viewContext);
        if(courseExchangeId != null) this.store.dispatch(fromAuth.Actions.selectCourseExchangeId({courseExchangeId}));
        this.store.dispatch(fromAuth.Actions.selectAccessRole({accessRoleId: accessRole.id}));
        this.router.navigate(notification.routerArgs);
      })
    ).subscribe();
  }

  markAsRead(notification: UserNotification): void {
    this.store.dispatch(fromNotification.Actions.MarkNotificationsAsRead({notification}));
  }

  markAsReadAll(): void {
    this.store.dispatch(fromNotification.Actions.MarkAllNotificationsAsRead());
  }
}
