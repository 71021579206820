export interface idEntity{
  id: any,
  selected: boolean
}
export default  <T extends idEntity>(arr: T[], id: string): T[] => {
  return arr.map(item => {
    if (item.id === id) {
      return {
        ...item,
        selected: !item.selected
      }
    }

    return item;
  });
}
