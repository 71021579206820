

import {createFeatureSelector, createSelector} from '@ngrx/store';
import { CourseExchangeState } from '../reducers/course-exchange.reducer';
import { BaseListItem, BaseListItemForView, EntityStatus } from 'app/core/models';
import { CourseCategoryForView, CourseSubCategory, CourseSubCategoryForView } from '@refData';
import { getStatusComplete } from '@shared/wizard/store/reducers/helpers/get-status-complete';
import { ChecklistItemType } from '@core/models/checklistItemType.enum';
import * as fromRefData from '@refData';
import * as fromAuth from '@auth/store';
import { PermissionType } from '@auth/models/permissionType.enum';
import { ViewContext } from '@auth/models/viewContext.enum';
export const selectCourseExchangeState = createFeatureSelector<CourseExchangeState>('courseExchange');

export const CourseExchange = createSelector(
    selectCourseExchangeState,
    (state) => !!state ? state.courseExchange : undefined
);

export const IsSendingPrimaryLogo = createSelector(
  selectCourseExchangeState,
  (state) => state.isSendingPrimaryLogo
);

export const IsSendingSecondaryLogo = createSelector(
  selectCourseExchangeState,
  (state) => state.isSendingSecondaryLogo
);

export const IsSendingPolicyIds = createSelector(
  selectCourseExchangeState,
  (state) => state.isSendingPolicyIds
);

export const CourseExchangeSummary = createSelector(
    selectCourseExchangeState,
    (state) => !!state ? state.courseExchangeSummary: undefined
)

export const CourseExchangeId = createSelector(
    selectCourseExchangeState,
    (state) => state.courseExchangeId
);

export const CourseExchangeName = createSelector(
    CourseExchange,
    (courseExchange) => !!courseExchange ? courseExchange.name : ""
);

export const SessionDateRules = createSelector(
    CourseExchangeSummary,
    (courseExchangeSummary) => !!courseExchangeSummary ? courseExchangeSummary.sessionDateRules : undefined
);

export const InstitutionsWidgetViewModel = createSelector(
    selectCourseExchangeState,
    (state) => !!state ? state.institutionsWidgetData : undefined
)

export const CourseExchangeCoursesWidgetViewModel = createSelector(
    selectCourseExchangeState,
    (state) => !!state ? state.courseExchangeCoursesWidgetData : undefined
)

export const CourseExchangeCheckList = createSelector(
    CourseExchange,
    (courseExchange) => {
        return !!courseExchange ?
         {
            ...courseExchange.courseExchangeChecklist,
            accordianFlag: false,
            statusComplete: getStatusComplete(courseExchange.courseExchangeChecklist),
            items: courseExchange.courseExchangeChecklist.items.map(x=>{
                return {
                    ...x,
                    accordionFlag: false,
                    statusComplete: getStatusComplete(x),
                    subCheckListsProgress: x.items.length ?
                                            {
                                                countCompleted: x.items.filter(x=>x.isComplete).length,
                                                all: x.items.length
                                            }:undefined
                }
            })
        }:undefined
    }
)

export const CourseExchangeChecklistCompleted = createSelector(
    CourseExchangeCheckList,
    (checklist) => !!checklist ? checklist.items.every(x=>x.isComplete) : false
);
export const FirstIncompleteCourseExchangeChecklistItem = createSelector(
    CourseExchangeCheckList,
    (checklist) => !!checklist ? checklist.items.find(x=>x.isComplete===false): undefined
);
export const CourseExchangeFeeSteps = createSelector(
    CourseExchange,
    (courseExchange) => !!courseExchange ?
    [...courseExchange.courseLevels.map(x=> `${ChecklistItemType.Fees_Item}_${x}`)]:
    []
)
export const ChecklistOrder = createSelector(
    CourseExchangeFeeSteps,
    (feeSteps) => [
            ChecklistItemType.EntityProfile,
            ChecklistItemType.ContactAndUserSetup_Accounting,
            ChecklistItemType.ContactAndUserSetup_President,
            ChecklistItemType.ContactAndUserSetup_ProvostOrVicePresident,
            ChecklistItemType.Calendar_AddYear,
            ChecklistItemType.Calendar_AddSemesters,
            ChecklistItemType.CourseInformation_Levels,
            ChecklistItemType.CourseInformation_Categories,
            ChecklistItemType.CourseInformation_SubCategories,
            ChecklistItemType.CourseInformation_Tags,
            ...feeSteps,
            ChecklistItemType.BankInformation,
            ChecklistItemType.Policies
        ]
)

export const ManagingInstitutionId = createSelector(
    CourseExchange,
    (courseExchange) => !!courseExchange ? courseExchange.managingInstitutionId : ""
)

export const CourseExchangeStatus = createSelector(
    CourseExchange,
    (courseExchange) => !!courseExchange ? courseExchange.status : EntityStatus.None
)
export const managingInstitutionLoaded = createSelector(
    selectCourseExchangeState,
    (state) => !!state ? state.managingInstitutionLoaded : false
)
export const CourseCategories = createSelector(
    CourseExchangeSummary,
    fromRefData.Selectors.courseCategories,
    (courseExchange, courseCategories) => !!courseExchange ? courseCategories.filter(x=>courseExchange.courseCategories.includes(x.id)): new Array<fromRefData.CourseCategory>()
);

export const CourseExchangeCategoriesForView = createSelector(
    CourseExchangeSummary,
    fromRefData.Selectors.courseCategories,
    (courseExchange, courseCategories) => !!courseExchange ?
                                            courseCategories.map(x=>{
                                                return {
                                                    ...x,
                                                    selected: courseExchange.courseCategories.includes(x.id)
                                                }
                                            }): new Array<CourseCategoryForView>()
);

export const AllCourseCategoriesSelected = createSelector(
    CourseExchangeCategoriesForView,
    (subCategories)=> subCategories.every(x=>x.selected)
)

export const CourseLevels = createSelector(
    CourseExchangeSummary,
    fromRefData.Selectors.courseLevels,
    (courseExchange, courseLevels) => !!courseExchange ? courseLevels.filter(x=>courseExchange.courseLevels.includes(x.id)): new Array<BaseListItem>()
);

export const CourseExchangeLevelsForView = createSelector(
    CourseExchangeSummary,
    fromRefData.Selectors.courseLevels,
    (courseExchange, courseLevels) => !!courseExchange ?
                                    courseLevels.map(x=>{
                                        return {
                                            ...x,
                                            selected: courseExchange.courseLevels.includes(x.id)
                                        }
                                    }) : new Array<BaseListItemForView>()
);

export const AllCourseLevelsSelected = createSelector(
    CourseExchangeLevelsForView,
    (levels)=> levels.every(x=>x.selected)
)


export const CourseSubCategories = createSelector(
    CourseExchangeSummary,
    fromRefData.Selectors.courseSubCategories,
    (courseExchange, courseSubCategories) => !!courseExchange ? courseSubCategories.filter(x=>courseExchange.courseSubCategories.includes(x.id)): new Array<CourseSubCategory>()
);

export const CourseExchangeSubCategoriesForView = createSelector(
    CourseExchangeSummary,
    fromRefData.Selectors.courseSubCategories,
    (courseExchange, courseSubCategories) => !!courseExchange ?
                                            courseSubCategories.map(x=>{
                                                return {
                                                    ...x,
                                                    selected: courseExchange.courseSubCategories.includes(x.id)
                                                }
                                                }): new Array<CourseSubCategoryForView>()
);


export const AllCourseSubCategoriesSelected = createSelector(
    CourseExchangeSubCategoriesForView,
    (subCategories)=> subCategories.every(x=>x.selected)
)

export const CourseTags = createSelector(
    CourseExchangeSummary,
    fromRefData.Selectors.courseTags,
    (courseExchange, courseTags) => !!courseExchange ?  courseTags.filter(x=>courseExchange.courseTags.includes(x.id)) : new Array<BaseListItem>()
);

export const CourseExchangeTagsForView = createSelector(
    CourseExchangeSummary,
    fromRefData.Selectors.courseTags,
    (courseExchange, courseTags) => !!courseExchange ?
                                    courseTags.map(x=>{
                                        return {
                                            ...x,
                                            selected: courseExchange.courseTags.includes(x.id)
                                        }
                                    }) : new Array<BaseListItemForView>()
);


export const AllCourseTagsSelected = createSelector(
    CourseExchangeTagsForView,
    (tags)=> tags.every(x=>x.selected)
)

export const StudentTypes = createSelector(
    CourseExchangeSummary,
    fromRefData.Selectors.studentTypes,
    (courseExchange, studentTypes) => !!courseExchange ?  studentTypes.filter(x=>courseExchange.studentTypes.includes(x.id)) : new Array<BaseListItem>()
);

export const CourseExchangeStudentTypesForView = createSelector(
    CourseExchangeSummary,
    fromRefData.Selectors.studentTypes,
    (courseExchange, studentTypes) => !!courseExchange ?
                                    studentTypes.map(x=>{
                                        return {
                                            ...x,
                                            selected: courseExchange.studentTypes.includes(x.id)
                                        }
                                    }) : new Array<BaseListItemForView>()
);


export const AllStudentTypesSelected = createSelector(
    CourseExchangeStudentTypesForView,
    (studentTypes)=> studentTypes.every(x=>x.selected)
)

export const Fees = createSelector(
    selectCourseExchangeState,
    (state) => !!state ? state.fees : undefined
)
export const areFeesLoaded = createSelector(
    selectCourseExchangeState,
    (state) => !!state ? state.areFeesLoaded :false
)

export const Policies = createSelector(
    selectCourseExchangeState,
    (state) => !!state ? state.policies : undefined
)

export const arePoliciesLoaded = createSelector(
    selectCourseExchangeState,
    (state) => !!state ? state.arePoliciesLoaded: false
)

export const BankInfo = createSelector(
    CourseExchange,
    (courseExchange) => !!courseExchange ? courseExchange.bankInfo : undefined
)

export const HasCourseExchangePermission = (permissionType: PermissionType)=> createSelector(
    fromAuth.Selectors.CurrentUser,
    fromAuth.Selectors.IsSuperAdmin,
    CourseExchangeId,
    (user, isSuperAdmin, courseExchangeId) => {
      if(isSuperAdmin) return true;
      var accessRole = !!user ? user.accessRoles.find(x=>x.entityId===courseExchangeId && x.viewContext===ViewContext.CourseExchangeAdmin):null;
      if(!accessRole) return false;
      if(!accessRole.permissions) return false;
      return accessRole.permissions.find(x=>x===permissionType) !== undefined;
    }
  )

  export const HasInstitutionPermission = (permissionType: PermissionType)=> createSelector(
    fromAuth.Selectors.CurrentUser,
    fromAuth.Selectors.IsSuperAdmin,
    ManagingInstitutionId,
    (user, isSuperAdmin, institutionId) => {
      if(!user) return false;
      if(isSuperAdmin) return true;
      var accessRole = user.accessRoles.find(x=>x.entityId===institutionId && x.viewContext===ViewContext.Institution);
      if(!accessRole) return false;
      if(!accessRole.permissions) return false;
      return accessRole.permissions.find(x=>x===permissionType) !== undefined;
    }
  )

  export const ShowToolbarButtons = createSelector(
    selectCourseExchangeState,
    (state) => {
        return !!state.courseExchange && 
               state.courseExchange.status == EntityStatus.Approved 
    })

  export const DateRangeArgs = createSelector(
    CourseExchange,
    fromAuth.Selectors.DateRange,
    (courseExchange, dateRange) =>{
        return{
            courseExchangeId: !!courseExchange ? courseExchange.id : '',
            startDate: dateRange.startDate,
            endDate: dateRange.endDate
        }
    }
)

