import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { first, finalize, filter, map } from "rxjs/operators";
import { IAppState } from 'app/store/state/app.state';
import { UserListItem } from '@shared/user/models';
import * as fromCourseExchangeUsers from '@courseExchange/users/store';
import { getCourseExchangeIdFromRoute } from "app/helpers/routeParamHelper";

@Injectable()
export class CourseExchangeUsersResolver implements Resolve<UserListItem[]>{
    isLoading = false;
    constructor(private store: Store<IAppState>){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
        const courseExchangeId = getCourseExchangeIdFromRoute(route);
        return this.store 
            .pipe(
                map(()=>{
                    if(!this.isLoading){
                        this.isLoading = true;
                        this.store.dispatch(fromCourseExchangeUsers.Actions.LoadUsers({courseExchangeId}));
                    }
                    return true;
                }),
                filter(isLoading => isLoading),
                first(),
                finalize(()=>this.isLoading=false)
            )
    }
}