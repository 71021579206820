import { EnrollmentListItemForView } from '@member/enrollment/models/enrollmentListItem.viewModel';
import { Sort, Order } from '@core/models';
import { Filter, FilterType, SemesterFilter } from '@shared/common/models';
import { on, createReducer } from '@ngrx/store';
import toggleItem from '@shared/common/helpers/toggleItem';
import * as fromAuth from '@auth/store';
import * as fromEnrollment from '@courseExchange/enrollment/store';
import * as fromProviderEnrollment from '@provider/enrollment/store';
import * as fromRefData from '@refData';
import * as fromInstitution from '@institution/store';
import * as fromCourseExchange from '@courseExchange/store';
import { Enrollment } from '@member/enrollment/models/enrollment.model';
import convertEnrollmentDates from '@shared/common/helpers/convertEnrollmentDates';
import getCurrentAcademicYear from '@shared/common/helpers/getCurrentAcademicYear';
import getSemesterFilters from '@shared/common/helpers/getSemesterFilters';
import toggleSemesterFilter from '@shared/common/helpers/toggleSemesterFilter';
import convertEnrollmentsDates from '@shared/common/helpers/convertEnrollmentsDates';

export interface EnrollmentsState {
    courseExchangeId: string;
    enrollments: EnrollmentListItemForView[];
    currentEnrollment: Enrollment;
    sorts: Sort[];
    currentSort: Sort;
    orders: Order[];
    currentOrder: Order;
    statusFilters: Filter[];
    courseLevelFilters: Filter[];
    providerFilters: Filter[];
    memberFilters: Filter[];
    searchText: string;
    semesterFilters: SemesterFilter[];
    currentAcademicYearId: string;
    IsEnrollmentsLoading:boolean;
    tagFilters: Filter[];
    courseCategoryFilters: Filter[];
    courseSubCategoryFilters: Filter[];
    startDate: Date;
    endDate: Date;
    isSubmitting: boolean;
};

export const initialEnrollmentsState: EnrollmentsState = {
    courseExchangeId: undefined,
    enrollments: new Array<EnrollmentListItemForView>(),
    currentEnrollment: undefined,
    sorts: new Array<Sort>(),
    currentSort: new Sort(),
    orders: new Array<Order>(),
    currentOrder: new Order(),
    statusFilters: new Array<Filter>(),
    courseLevelFilters: new Array<Filter>(),
    providerFilters: new Array<Filter>(),
    memberFilters: new Array<Filter>(),
    searchText: '',
    semesterFilters: new Array<SemesterFilter>(),
    currentAcademicYearId: "empty",
    IsEnrollmentsLoading:false,
    tagFilters: [],
    courseCategoryFilters: [],
    courseSubCategoryFilters: [],
    startDate: undefined,
    endDate: undefined,
    isSubmitting: false
};

export const enrollmentsReducer = createReducer(
    initialEnrollmentsState,
    on(fromAuth.Actions.selectCourseExchangeId, (state, action) => {
        return initialEnrollmentsState
    }),
    on(fromEnrollment.Actions.LoadEnrollmentByDateRange, (state, {courseExchangeId, startDate,endDate}) => {
      return {
        ...state,
        isEnrollmentsLoading: true,
        courseExchangeId,
        startDate,
        endDate
      }
    }),
    on(fromEnrollment.Actions.LoadActiveEnrollmentsSuccess, (state, action) => {
        const enrollments = convertEnrollmentsDates(action.enrollments);
        return {
            ...state,
            enrollments,
            IsEnrollmentsLoading:false
        }
    }),
    on(fromEnrollment.Actions.LoadSorts, (state, { sorts }) => {
        const currentSort = sorts.find(x => x.isDefault);
        return {
            ...state,
            sorts,
            currentSort
        }
    }),
    on(fromEnrollment.Actions.ChangeSort, (state, { currentSort }) => {
        return {
            ...state,
            currentSort
        }
    }),
    on(fromEnrollment.Actions.LoadOrders, (state, { orders }) => {
        const currentOrder = orders.find(x => x.isDefault);
        return {
            ...state,
            orders,
            currentOrder
        }
    }),
    on(fromEnrollment.Actions.ChangeOrder, (state, { currentOrder }) => {
        return {
            ...state,
            currentOrder
        }
    }),
    on(fromEnrollment.Actions.ChangeSearchText, (state, { searchText }) => {
        return {
            ...state,
            searchText
        }
    }),
    on(fromEnrollment.Actions.ToggleEnrollment, (state, { enrollmentId }) => {
        const enrollments = toggleItem(state.enrollments, enrollmentId);
        return {
            ...state,
            enrollments
        }
    }),
    on(fromEnrollment.Actions.ToggleStatusFilter, (state, { id }) => {
        const statusFilters = toggleItem(state.statusFilters, id);
        return {
            ...state,
            statusFilters
        }
    }),
    on(fromEnrollment.Actions.ToggleSemesterFilter, (state, { semesterId }) => {
        const semesterFilters = toggleSemesterFilter(state.semesterFilters, semesterId);

        return {
            ...state,
            semesterFilters
        }
    }),
    on(fromCourseExchange.Actions.ViewEnrollments, (state, action) => {
        const searchText = '';
        const statusFilters = state.statusFilters.map((filter) => { return { ...filter, selected: false } });
        return {
            ...state,
            searchText,
            statusFilters
        }
    }),
    on(fromRefData.Actions.LoadEnrollmentStatusesSuccess, (state, action) => {
        const statusFilters = action.enrollmentStatuses.map(x => {
            return {
                id: x.id,
                description: x.description,
                descriptionForView: x.description,
                type: FilterType.EnrollmentStatus,
                selected: false
            }
        });

        return {
            ...state,
            statusFilters
        }
    }),
    on(fromInstitution.Actions.LoadCalendarSuccess, (state, action) => {
        const academicYears = action.academicYears;
        const currentAcademicYear = getCurrentAcademicYear(academicYears);
        const semesterFilters = getSemesterFilters(currentAcademicYear);
        return {
            ...state,
            academicYears,
            semesterFilters
        }
    }),
    on(fromInstitution.Actions.ChangeCurrentAcademicYear, (state, { academicYear }) => {
        const semesterFilters = getSemesterFilters(academicYear);
        return {
            ...state,
            semesterFilters
        }
    }),
    on(fromEnrollment.Actions.LoadMemberFilters, (state, { memberFilters }) => {
        return {
          ...state,
          memberFilters
        }
      }) ,
    
    on(fromEnrollment.Actions.ToggleMemberFilter, (state, { id }) => {
    const memberFilters = toggleItem(state.memberFilters, id);
    return {
      ...state,
      memberFilters
    }
    }),
    on(fromEnrollment.Actions.LoadProviderFilters, (state, { providerFilters }) => {
        return {
          ...state,
          providerFilters
        }
      }) ,

    on(fromEnrollment.Actions.ToggleProviderFilter, (state, { id }) => {
    const providerFilters = toggleItem(state.providerFilters, id);
    return {
      ...state,
      providerFilters
    }
  }),
  on(fromEnrollment.Actions.LoadTagFilters, (state, { tagFilters }) => {
    return {
      ...state,
      tagFilters
    }
  }),
  on(fromEnrollment.Actions.ToggleTagFilter, (state, { id }) => {
    const tagFilters = toggleItem(state.tagFilters, id);
    return {
      ...state,
      tagFilters
    }
  }),


  on(fromEnrollment.Actions.LoadCategoryFilters, (state, { courseCategoryFilters }) => {
    return {
      ...state,
      courseCategoryFilters
    }
  }),
  on(fromEnrollment.Actions.LoadSubCategoryFilters, (state, { courseSubCategoryFilters }) => {
    return {
      ...state,
      courseSubCategoryFilters
    }
  }),
  on(fromEnrollment.Actions.ToggleCategoryFilter, (state, { id }) => {
    const courseCategoryFilters = toggleItem(state.courseCategoryFilters, id);
    return {
      ...state,
      courseCategoryFilters
    }
  }),
  on(fromEnrollment.Actions.ToggleSubCategoryFilter, (state, { id }) => {
    const courseSubCategoryFilters = toggleItem(state.courseSubCategoryFilters, id);
    return {
      ...state,
      courseSubCategoryFilters
    }
  }),
  on(fromEnrollment.Actions.SubmitEnrollmentFeeChanges, (state, {command})=>{
    const idx = state.enrollments.findIndex(x=>x.enrollmentId===command.enrollmentId);
    if(idx===-1) return state;
    const oldEnrollment = state.enrollments[idx];
    const memberFeeAmount = oldEnrollment.memberFeeAmount + command.managementFeeAmountChange;
    const providerFeeAmount = oldEnrollment.providerFeeAmount + command.providerFeeAmountChange;
    const managementFeeAmount = oldEnrollment.managementFeeAmount + command.managementFeeAmountChange;
    const newEnrollment = {...oldEnrollment, memberFeeAmount, providerFeeAmount, managementFeeAmount, reason: command.reason};
    const enrollments = [...state.enrollments.slice(0,idx),newEnrollment, ...state.enrollments.slice(idx+1)];
    return{
      ...state,
      enrollments
    }
  }),
  on(fromProviderEnrollment.Actions.EnrollmentListItemUpdated, (state, action) => {
      const item = convertEnrollmentDates(action.enrollment);
      var idx = state.enrollments.findIndex(x => x.id === item.id);
      if (idx != -1) {
          const enrollmentForView = {...item, selected: false, requestedStatus: null, requestedLetterGrade: null, letterGradeDescription: !!item.letterGrade ? item.letterGrade.description : "zz"};
          const enrollments = [...state. enrollments.slice(0, idx), enrollmentForView, ...state.enrollments.slice(idx + 1)];
          return {
              ...state,
              enrollments
          }
      }else{
          return state;
      }
  }),
  on(fromEnrollment.Actions.MasterToggled, (state, action) => {
    const enrollments = state.enrollments.map((enrollment)=>{
      const idx = action.enrollments.findIndex(x=>x.id===enrollment.id);
      if(idx===-1){
        return enrollment;
      }else{
        return action.enrollments[idx];
      }
    })

    return {
      ...state,
      enrollments
    };
  }),
  on(fromProviderEnrollment.Actions.EnrollmentListItemAdded, (state, action) => {
      const item = convertEnrollmentDates(action.enrollment);
      const idx = state.enrollments.findIndex(x=>x.id===item.id);
      if(idx!=-1) return;
      const enrollmentForView = { ...item, selected: false, requestedStatus: null, requestedLetterGrade: null, letterGradeDescription: !!item.letterGrade ? item.letterGrade.description : "zz" };
      const enrollments = [...state.enrollments, enrollmentForView];

      return {
          ...state,
          enrollments
      };
  }),
  on(fromEnrollment.Actions.EditStatus, (state, {enrollmentId, requestedStatus}) => {
    const idx = state.enrollments.findIndex(x => x.id === enrollmentId);
    const enrollment = {...state.enrollments[idx], requestedStatus};
    const enrollments = [...state.enrollments.slice(0, idx), enrollment, ...state.enrollments.slice(idx + 1)];
    return {
      ...state,
      enrollments
    };
  }),
  on(fromEnrollment.Actions.ChangeStatuses, (state, action)=>{
    const commands = action.command.commands;
    const enrollmentStatuses = action.enrollmentStatuses;
    const enrollments = state.enrollments.map((enrollment=>{
      const command = commands.find(x=>x.enrollmentId===enrollment.enrollmentId);
      if(!command) return enrollment;
      var status = enrollmentStatuses.find(x=>x.id===command.requestedStatus.id);
      return {
        ...enrollment,
        status,
        requestedStatus: null
      }
    }));

    return {
      ...state,
      enrollments
    }
  }),
  on(fromEnrollment.Actions.SubmitStatusChangesSuccess, (state, action)=>{
    return{
      ...state,
      isSubmitting: false
    }
  }),
  on(fromEnrollment.Actions.SubmitStatusChangesError, (state, action) => {
    return {
      ...state,
      isSubmitting: false
    }
  }),
)