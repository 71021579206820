import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import { IAppState } from 'app/store/state/app.state';
import { Store } from '@ngrx/store';
import * as fromProviderShared from '@shared/provider/store';
export interface LetterGradeMigrationDialogData {
  providerId: string;
  institutionId: string;
}

@Component({
  selector: 'letter-grade-migration-data-dialog',
  templateUrl: './letter-grade-migration-dialog.component.html',
  styleUrls: ['./letter-grade-migration-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LetterGradeMigrationDialogComponent {
  constructor(
    public matDialogRef: MatDialogRef<LetterGradeMigrationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public matDialogData: LetterGradeMigrationDialogData,
    public store: Store<IAppState>
  ) {
    this.matDialogRef.addPanelClass('letter-grade-migration-data-dialog');
  }

  close(): void {
    this.matDialogRef.close();
  }

  fileInputChange(event: Event): void{
    var file = (event.target as HTMLInputElement).files[0];
    const fileReader = new FileReader();
    fileReader.readAsText(file);
    fileReader.onload = (e) => {
      var result = e.target.result as string;
      const enrollmentMigrationData = JSON.parse(result);
      const {providerId, institutionId} = this.matDialogData;
      this.store.dispatch(fromProviderShared.Actions.LetterGradeMigrationData({providerId, institutionId, enrollmentMigrationData}));
      this.close();
    }
  }
}
