import { createAction, props } from '@ngrx/store';
import { EnrollmentListItem, EnrollmentListItemForView } from '@member/enrollment/models/enrollmentListItem.viewModel';
import { Sort, Order, BaseListItem } from '@core/models';
import { Filter } from '@shared/common/models';
import { ChangeEnrollmentStatusBulkCommand } from '@shared/member/models/commands/changeEnrollmentStatus.command';
import { EnrollmentListMode } from '../enrollmentListMode.enum';
import { StudentDetailsViewModel } from '@shared/student/models/studentDetails.viewModel';
import { Student } from '@shared/student/models';
import { EnrollmentStatus } from '@core/referenceData';
import { ChangeLetterGradeBulkCommand } from '@shared/member/models/commands/changeLetterGrade.command';

export const LoadActiveEnrollmentsSuccess = createAction(
    '[Provider Enrollments Effects] - Load Active Enrollments Success',
    props<{
        enrollments: EnrollmentListItemForView[]
    }>()
)

export const LoadActiveEnrollmentsError = createAction(
  '[Provider Enrollments Effects] - Load Active Enrollments Error',
  props<{
    message: string
  }>()
)

export const EditStatus = createAction(
    '[Provider Enrollment List] - Edit Status',
    props<{
        enrollmentId: string,
        requestedStatus: BaseListItem
    }>()
)
export const EditLetterGrade = createAction(
    '[Provider Enrollment List] - Edit Letter Grade',
    props<{
        enrollmentId: string,
        requestedLetterGrade: BaseListItem
    }>()
)

export const SubmitStatusChanges = createAction(
    '[Provider Enrollment List] - Submit Status Changes'
)

export const ChangeStatuses = createAction(
    '[Provider Enrollment Effects] - Change Statuses',
    props<{
        command: ChangeEnrollmentStatusBulkCommand,
        enrollmentStatuses: EnrollmentStatus[]
    }>()
)

export const ChangeLetterGrades = createAction(
    '[Provider Enrollment Effects] - Change Letter Grades',
    props<{
        command: ChangeLetterGradeBulkCommand,
        enrollmentStatuses: EnrollmentStatus[]
    }>()
)


export const SubmitStatusChangesSuccess = createAction(
    '[Provider Enrollment List] - Submit Status Changes Success'
)

export const EnrollmentListItemUpdated = createAction(
    '[Provider Enrollment Realtime] - Enrollment List Item Updated',
    props<{
        enrollment: EnrollmentListItem
    }>()
)

export const MasterToggled = createAction(
    '[Provider Enrollments] - Master Toggled',
    props<{
        enrollments: EnrollmentListItemForView[]
    }>()
)

export const EnrollmentListItemAdded = createAction(
    '[Provider Enrollment Realtime] - Enrollment List Item Added',
    props<{
        enrollment: EnrollmentListItem
    }>()
)

export const SubmitStatusChangesError = createAction(
    '[Provider Enrollment Effects] - Submit Status Changes Error',
    props<{
        message: string
    }>()
)

export const SubmitGrading = createAction(
    '[Provider Enrollment List] - Submit Grading'
)

export const SubmitGradingSuccess = createAction(
    '[Provider Enrollment Effects] - Submit Status Changes Success'
)

export const SubmitGradingError = createAction(
    '[Provider Enrollment Effects] - Submit Grading Error',
    props<{
        message: string
    }>()
)
export const LoadOrders = createAction(
    '[Provider Enrollment List] - Load Orders',
    props<{
        orders: Order[]
    }>()
)
export const ChangeOrder = createAction(
    '[Provider Enrollment List] - Change Order',
    props<{
        currentOrder: Order
    }>()
)

export const ChangeEnrollmentListMode = createAction(
    '[Provider Enrollment List] - Change Enrollment List Mode',
    props<{
        enrollmentListMode: EnrollmentListMode
    }>()
)
export const LoadSorts = createAction(
    '[Provider Enrollment List] - Load Sorts',
    props<{
        sorts: Sort[]
    }>()
)
export const ChangeSort = createAction(
    '[Provider Enrollment List] - Change Sort',
    props<{
        currentSort: Sort
    }>()
)
export const ChangeSearchText = createAction(
    '[Provider Enrollment List] - Change Search Text',
    props<{
        searchText: string
    }>()
)
export const MasterToggle = createAction(
    '[Provider Enrollment List] - Master Toggle'
)
export const ToggleEnrollment = createAction(
    '[Provider Enrollment List] - Toggle Enrollment',
    props<{
        enrollmentId: string
    }>()
)
export const ToggleSemester = createAction(
    '[Provider Enrollment List] - Toggle Semester',
    props<{
        semesterId: string
    }>()
)
export const ToggleSemesterExpanded = createAction(
    '[Provider Enrollment list] - Toggle Semester Expanded',
    props<{
        semesterFilterId: string
    }>()
)
export const ToggleSession = createAction(
    '[Provider Enrollment List] - Toggle Session',
    props<{
        sessionId: string
    }>()
)
export const ToggleStatusFilter = createAction(
    '[Provider Enrollment List] - Toggle Status Filter',
    props<{
        id: string
    }>()
  )
export const RemoveFilter = createAction(
    '[Provider Enrollment List] - Remove Filter',
    props<{
        filter: Filter
    }>()
)

export const ExportToExcel = createAction(
    '[Provider Enrollment list] - Export to Excel',
    props<{
      enrollments?: EnrollmentListItem[]
    }>()
  )

  export const LoadStudent = createAction(
    '[Provider Student Resolver] - Load Student',
    props<{
        studentId: string
    }>()
)

export const LoadStudentSuccess = createAction(
    '[Provider Enrollments Effect] - Load Student Success',
    props<{
        currentStudent: Student
    }>()
)

export const LoadStudentError = createAction(
  '[Provider Enrollments Effect] - Load Student Error',
  props<{
    message: string
  }>()
)

export const LoadStudentDetails = createAction(
    '[Provider Student Details Resolver] - Load Student Details',
    props<{
        studentId: string
    }>()
)

export const LoadStudentDetailsSuccess = createAction(
    '[Provider Enrollments Effect] - Load Student Details Success',
    props<{
        currentStudentDetails: StudentDetailsViewModel
    }>()
)

export const LoadStudentDetailsError = createAction(
  '[Provider Enrollments Effect] - Load Student Details Error',
  props<{
    message: string
  }>()
)

export const LoadEnrollmentsByAcademicYear = createAction(
    '[Provider Enrollment Effects] - Load Enrollments By Academic Year',
    props<{
        academicYearId: string,
        providerId?: string
    }>()
)

export const LoadMemberFilters = createAction(
    '[Provider Enrollment List] - Load Member Filters',
    props<{
        memberFilters: Filter[]
    }>()
  )
  export const ToggleMemberFilter = createAction(
    '[Provider Enrollment List] - Toggle Member Filter',
    props<{
        id: string
    }>()
  )
  export const LoadTagFilters = createAction(
    '[Provider Course Exchange Summary Resolver] - Load Tag Filters',
    props<{
      tagFilters: Filter[]
    }>()
  )
  export const ToggleTagFilter = createAction(
    '[Provider Enrollment List] - Toggle Tag Filter',
    props<{
        id: string
    }>()
  )
  export const LoadCategoryFilters = createAction(
    '[Provider Course Exchange Summary Resolver] - Load Category Filters',
    props<{
        courseCategoryFilters: Filter[]
    }>()
  )
  
  export const LoadSubCategoryFilters = createAction(
    '[Provider Course Exchange Summary Resolver] - Load Sub Category Filters',
    props<{
        courseSubCategoryFilters: Filter[]
    }>()
  )

  export const ToggleCategoryFilter = createAction(
    '[Provider Enrollment List] - Toggle Category Filter',
    props<{
        id: string
    }>()
  )
  
  
  export const ToggleSubCategoryFilter = createAction(
    '[Provider Enrollment List] - Toggle Sub Category Filter',
    props<{
        id: string
    }>()
  )
