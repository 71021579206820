import { createEffect, Actions, ofType } from "@ngrx/effects";
import { Injectable } from "@angular/core";
import { map, switchMap, withLatestFrom, concatMap, tap } from "rxjs/operators";
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { IAppState } from 'app/store/state/app.state';
import { Router } from '@angular/router';
import * as fromMember from '@member/store';
import * as fromStudent from '@member/students';

@Injectable()
export class StudentsNavigateEffects{
    constructor(private actions$: Actions, private store: Store<IAppState>,
        private router: Router){
    }

    StudentNew$ = createEffect(() => {
        return this.actions$.pipe(
          ofType(fromStudent.Navigate.StudentNew),
          concatMap(action => of(action).pipe(
            withLatestFrom(this.store.pipe(select(fromMember.Selectors.Member)))
          )),
          map(([action, member]) => {
            const memberId = !!action.memberId ? action.memberId : member.id;
            const institutionId = !!action.memberId ? action.memberId : member.institutionId;
    
            return this.router.navigate(['member',
              memberId,
              institutionId,
              'students',
              'new']);
          }
          ))
      }, { dispatch: false });

    StudentDetail$ = createEffect(() => {
        return this.actions$.pipe(
          ofType(fromStudent.Navigate.StudentDetail),
          concatMap(action => of(action).pipe(
            withLatestFrom(this.store.pipe(select(fromStudent.Selectors.CurrentStudentForEffects)))
          )),
          map(([action, {currentStudent,memberId, institutionId}]) => {
            const studentId = !!action.studentId ? action.studentId : currentStudent.id;
            return this.router.navigate(['member',
              memberId,
              institutionId,
              'students',
              'detail',
              studentId]);
          }
          ))
      }, { dispatch: false });

    StudentEdit$ = createEffect(() => {
        return this.actions$.pipe(
          ofType(fromStudent.Navigate.StudentEdit),
          concatMap(action => of(action).pipe(
            withLatestFrom(this.store.pipe(select(fromStudent.Selectors.CurrentStudentForEffects)))
          )),
          map(([action, {currentStudent,memberId, institutionId}]) => {
            const studentId = !!action.studentId ? action.studentId : currentStudent.id;
            return this.router.navigate(['member',
              memberId,
              institutionId,
              'students',
              'detail',
              studentId,
              'edit'
            ]);
          }
          ))
      }, { dispatch: false });

    Students$ = createEffect(() => {
        return this.actions$.pipe(
          ofType(fromStudent.Navigate.StudentList),
          concatMap(action => of(action).pipe(
            withLatestFrom(this.store.pipe(select(fromMember.Selectors.Member)))
          )),
          map(([action, member]) => {
            const memberId = !!action.memberId ? action.memberId : member.id;
            const institutionId = !!action.memberId ? action.memberId : member.institutionId;
    
            return this.router.navigate(['member',
              memberId,
              institutionId,
              'students',
              'list']);
          }
          ))
      }, { dispatch: false });
}