import { EditStudentTypeCommand } from 'app/admin/reference-data/commands';
import { AddStudentTypeCommand } from './../../../admin/reference-data/commands/addStudentType.command';
import { createAction, props } from '@ngrx/store';
import { Country, AccreditationBody, Region, InstitutionRole, CourseExchangeRole, CourseCategory, CourseSubCategory, ProviderPolicyTemplate, CourseExchangePolicyTemplate, EnrollmentStatus } from '..';
import { BaseListItem } from 'app/core/models';
import { PermissionDisplay } from '@auth/models/permissionDisplay';
import { InstitutionSummary } from '@shared/institution/models';
import { CourseExchangeListItem } from '@shared/courseExchange/models';

export const LoadAccreditationBodies = createAction(
    "[RefData] - Load AccreditationBodies",
    props<{
        forceReload: boolean
    }>()
);

export const LoadAccreditationBodiesSuccess = createAction(
    "[RefData] - Load AccreditationBodie Success",
    props<{
        accreditationBodies: AccreditationBody[]
    }>()
)

export const LoadCountries = createAction(
    "[RefData] - Load Countries",
    props<{
        forceReload: boolean
    }>()
);

export const LoadCountriesSuccess = createAction(
    "[RefData] - Load Countries Success",
    props<{
        countries: Country[]
    }>()
)

export const LoadCourseExchanges = createAction(
    "[RefData] - Load Course Exchanges",
    props<{
        forceReload: boolean
    }>()
);

export const CourseExchangeListItemUpdated = createAction(
    "[RefData] - Course Exchange List Item Updated",
    props<{
        courseExchangeListItem: CourseExchangeListItem
    }>()
)
export const CourseExchangeListItemAdded = createAction(
    "[RefData] - Course Exchange List Item Added",
    props<{
        courseExchangeListItem: CourseExchangeListItem
    }>()
)
export const CourseExchangeDeleted = createAction(
    "[RefData] - Course Exchange Deleted",
    props<{
        courseExchangeId: string
    }>()
)

export const LoadCourseExchangesSuccess = createAction(
    "[RefData] - Load Course Exchanges Success",
    props<{
        courseExchanges: CourseExchangeListItem[]
    }>()
)

export const LoadInstitutions = createAction(
    "[RefData] - Load Institutions",
    props<{
        forceReload: boolean
    }>()
);

export const LoadInstitutionsSuccess = createAction(
    "[RefData] - Load Institutions Success",
    props<{
        institutions: InstitutionSummary[]
    }>()
)

export const LoadCourseCategories = createAction(
    "[RefData] - Load Course Categories",
    props<{
        forceReload: boolean
    }>()
);

export const loadCourseCategoriesSuccess = createAction(
    "[RefData] - Load Course Categories Success",
    props<{
        courseCategories: CourseCategory[]
    }>()
)

export const LoadCourseLevels = createAction(
    "[RefData] - Load Course Levels",
    props<{
        forceReload: boolean
    }>()
);

export const LoadCourseLevelsSuccess = createAction(
    "[RefData] - Load Course Levels Success",
    props<{
        courseLevels: BaseListItem[]
    }>()
)

export const LoadCourseSubCategories = createAction(
    "[RefData] - Load Course Sub Categories",
    props<{
        forceReload: boolean
    }>()
);

export const LoadCourseSubCategoriesSuccess = createAction(
    "[RefData] - Load Course Sub Categories Success",
    props<{
        courseSubCategories: CourseSubCategory[]
    }>()
)

export const LoadCourseTags = createAction(
    "[RefData] - Load Course Tags",
    props<{
        forceReload: boolean
    }>()
);

export const LoadCourseTagsSuccess = createAction(
    "[RefData] - Load Course Tags Success",
    props<{
        courseTags: BaseListItem[]
    }>()
)

export const LoadStudentTypes = createAction(
    "[RefData] - Load Student Types",
    props<{
        forceReload: boolean
    }>()
);

export const LoadStudentTypesSuccess = createAction(
    "[RefData] - Load Student Types Success",
    props<{
        studentTypes: BaseListItem[]
    }>()
)

export const ToggleStudentType = createAction(
    "[RefData] - Toggle Student Type",
    props<{
        id: string
    }>()
)

export const AddStudentType = createAction(
    "[RefData] - Add Student Type",
    props<{
        command: AddStudentTypeCommand
    }>()
)

export const EditStudentType = createAction(
    "[RefData] - Edit Student Type",
    props<{
        command: EditStudentTypeCommand
    }>()
)

export const ToggleStudentTypeSuccess = createAction(
    "[RefData] - Toggle Student Type Success",
    props<{
        studentTypeId: string
    }>()
)

export const DeleteStudentType = createAction(
    "[RefData] - Delete Student Type",
    props<{
        id: string
    }>()
)

export const DeleteStudentTypeSuccess = createAction(
    "[RefData] - Delete Student Type Success",
    props<{
        studentTypeId: string
    }>()
)

export const LoadCourseExchangeRoles = createAction(
    "[RefData] - Load Course Exchange Roles",
    props<{
        forceReload: boolean
    }>()
);

export const LoadCourseExchangeRolesSuccess = createAction(
    "[RefData] - Load Course Exchange Roles Success",
    props<{
        courseExchangeRoles: CourseExchangeRole[]
    }>()
)

export const LoadInstitutionRoles = createAction(
    "[RefData] - Load Institution Roles",
    props<{
        forceReload: boolean
    }>()
);

export const LoadInstitutionRolesSuccess = createAction(
    "[RefData] - Load Institution Roles Success",
    props<{
        institutionRoles: InstitutionRole[]
    }>()
)

export const LoadGenders = createAction(
    "[RefData] - Load Genders",
    props<{
        forceReload: boolean
    }>()
);

export const LoadGendersSuccess = createAction(
    "[RefData] - Load Genders Success",
    props<{
        genders: BaseListItem[]
    }>()
)

export const LoadRegions = createAction(
    "[RefData] - Load Regions",
    props<{
        forceReload: boolean
    }>()
);

export const LoadRegionsSuccess = createAction(
    "[RefData] - Load Regions Success",
    props<{
        regions: Region[]
    }>()
)

export const LoadProviderPolicyTemplates = createAction(
    "[RefData] - Load Provider Policy Templates",
    props<{
        forceReload: boolean
    }>()
);

export const LoadProviderPolicyTempaltesSuccess = createAction(
    "[RefData] - Load Provider Policy Templates Success",
    props<{
        providerPolicyTemplates: ProviderPolicyTemplate[]
    }>()
)

export const LoadCourseExchangePolicyTemplates = createAction(
    "[RefData] - Load Course Exchange Policy Templates",
    props<{
        forceReload: boolean
    }>()
);

export const LoadCourseExchangePolicyTemplatesSuccess = createAction(
    "[RefData] - Load Course Exchange Policy Templates Success",
    props<{
        courseExchangePolicyTemplates: CourseExchangePolicyTemplate[]
    }>()
)

export const DownloadBankInfoTemplate = createAction('[Various Places] Download BankInfo Template');
export const DownloadCourseExchangePolicyTemplate = createAction(
    '[Various Places] Download Course Exchange Policy Template',
    props<{
        courseExchangePolicyTemplate: CourseExchangePolicyTemplate
    }>()
);
export const DownloadProviderPolicyTemplate = createAction(
    '[Various Places] Download Provider Policy Template',
    props<{
        providerPolicyTemplate: ProviderPolicyTemplate
    }>()
);
export const LoadPermissionDisplays = createAction(
    '[RefData] - Load Permission Displays',
    props<{
        forceReload: boolean
    }>()
)

export const LoadPermissionDisplaysSuccess = createAction(
    "[RefData] - Load Permission Displays Success",
    props<{
        permissionDisplays: PermissionDisplay[]
    }>()
)

export const LoadEnrollmentStatuses = createAction(
    '[RefData] - Load Enrollment Statuses',
    props<{
        forceReload: boolean
    }>()
)

export const LoadEnrollmentStatusesSuccess = createAction(
    "[RefData] - Load Enrollment Statuses Success",
    props<{
        enrollmentStatuses: EnrollmentStatus[]
    }>()
)

export const LoadLetterGrades = createAction(
    '[RefData] - Load Letter Grades',
    props<{
        forceReload: boolean
    }>()
)

export const LoadLetterGradesSuccess = createAction(
    "[RefData] - Load Letter Grades Success",
    props<{
        letterGrades: BaseListItem[]
    }>()
)
