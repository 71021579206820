import {Component, Inject, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {IAppState} from '../../../../store/state/app.state';
import {select, Store} from '@ngrx/store';
import {ICourseScheduleDialogData} from '../../models/shared';
import {ECourseScheduleTypeCreateActions} from '../../../constants';
import {Subject, Observable} from 'rxjs';
import { AcademicYear } from '@shared/calendar/models';
import * as fromProvider from '@provider/store';
import * as fromCourseSchedule from '@provider/course-schedule/store';
import * as fromInstituion from '@institution/store';

@Component({
  selector: 'course-schedule-dialog-container',
  templateUrl: './course-schedule-dialog-container.component.html',
  styleUrls: ['./course-schedule-dialog-container.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CourseScheduleDialogContainerComponent implements OnInit {
  academicYears$: Observable<AcademicYear[]>;
  selectedToAcademicYear$: Observable<AcademicYear>;
  selectedFromAcademicYear$: Observable<AcademicYear>;
  selectedAcademicYear$: Observable<AcademicYear>;
  canTransformToSecondPart$: Observable<boolean>; 
  typeCreateAction: ECourseScheduleTypeCreateActions;
  canSubmitCopy$: Observable<boolean>;
  canSubmitNew$: Observable<boolean>;
  isShowStart = true;

  constructor(
    public matDialogRef: MatDialogRef<CourseScheduleDialogContainerComponent>,
    @Inject(MAT_DIALOG_DATA) public matDialogData: ICourseScheduleDialogData,
    private store: Store<IAppState>,
  ) {
    this.matDialogRef.addPanelClass('course-schedule-dialog-container');
  }

  ngOnInit(): void {
    this.store.dispatch(fromCourseSchedule.Actions.changeSelectedProviderCourses({payload:this.matDialogData.providerCourseIds}));
    this.academicYears$ = this.store.pipe(select(fromInstituion.Selectors.AcademicYears));
    this.selectedToAcademicYear$ = this.store.pipe(select(fromCourseSchedule.Selectors.selectSelectedToAcademicYear));
    this.selectedFromAcademicYear$ = this.store.pipe(select(fromCourseSchedule.Selectors.selectSelectedFromAcademicYear));
    this.selectedAcademicYear$ = this.store.pipe(select(fromCourseSchedule.Selectors.selectSelectedAcademicYear));
    this.canTransformToSecondPart$ = this.store.pipe(select(fromCourseSchedule.Selectors.CanTransformToSecondPartCopy));
    this.canSubmitCopy$ = this.store.pipe(select(fromCourseSchedule.Selectors.CanSubmitCopy));
    this.canSubmitNew$ = this.store.pipe(select(fromCourseSchedule.Selectors.CanSubmitNew));
  }

  changeCreateAction(isNew: boolean): void {
    this.typeCreateAction = isNew ? ECourseScheduleTypeCreateActions.new : ECourseScheduleTypeCreateActions.copy;

    this.store.dispatch(fromCourseSchedule.Actions.changeTypeCreateAction({
      payload: this.typeCreateAction
    }));

    this.isShowStart = false;
  }

  close(): void {
    this.matDialogRef.close();
  }
}
