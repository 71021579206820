import {createFeatureSelector, createSelector} from '@ngrx/store';
import {searchInText} from 'app/helpers/search-in-text';
import {sortBy} from 'sort-by-typescript';
import { CourseExchangeInstitutionType } from '@courseExchange/store/enums';
import {CourseExchangeInstitutionState} from '@courseExchange/institution';
import { FilterType } from '@shared/common/models';
import { CourseExchangeInstitutionListMode } from '../courseExchangeInstitutionListMode.enum';

export const selectCourseExchangeInstitutionState = createFeatureSelector<CourseExchangeInstitutionState>('courseExchange/institutions');

export const CourseExchangeInstitutions = createSelector(
  selectCourseExchangeInstitutionState,
  (state) => !!state ? state.institutions : []
)

export const CurrentInstitutionUsers = createSelector(
  selectCourseExchangeInstitutionState,
  (state) => !!state ? state.currentInstitutionUsers : []
)

export const CourseExchangeInstitution = (institutionId: string) => createSelector(
  selectCourseExchangeInstitutionState,
  (state) => !!state ? state.institutions.find(x=>x.institutionId===institutionId) : undefined
)

export const CurrentInstitution = createSelector(
  selectCourseExchangeInstitutionState,
  (state) => !!state ? state.currentInstitution : undefined
)

export const CourseExchangeId = createSelector(
  selectCourseExchangeInstitutionState,
  (state) => !!state ? state.courseExchangeId : ""
)

export const StatusFilters = createSelector(
  selectCourseExchangeInstitutionState,
  (state) =>{
    const {institutions, statusFilters} = state;
    if (!institutions || !statusFilters ) return [];
    
    return statusFilters.filter(x=>institutions.find(i=>i.memberStatus == x.id || i.providerStatus==x.id)).sort(sortBy('description')).map(filter => {
      const count = institutions.filter(institution => {
        return (institution.memberStatus === filter.id || institution.providerStatus === filter.id);
      }).length;

      return {
        ...filter,
        descriptionForView: `${filter.description} (${count})`
      }
    })
  }
)

export const SelectedStatusFilters = createSelector(
  StatusFilters,
  (statusFilters) => statusFilters.filter(x => x.selected)
)

export const StatusFiltersCount = createSelector(
  SelectedStatusFilters,
  (statusFilters) => statusFilters.length > 0 ? statusFilters.length : null
)

export const AccreditationBodyFilters = createSelector(
  selectCourseExchangeInstitutionState,
  ({institutions, accreditationBodyFilters, allInstitutions, courseExchangeInstitutionListMode}) => {
    if (!institutions || !accreditationBodyFilters) return null;
    if(courseExchangeInstitutionListMode == CourseExchangeInstitutionListMode.CourseExchange){
      return accreditationBodyFilters.filter(x=>institutions.find(i=>!!i.accreditationBody && i.accreditationBody.id==x.id)).sort(sortBy('description')).map(filter => {
        const count = institutions.filter(institution => {
          return institution.accreditationBody && institution.accreditationBody.id === filter.id;
        }).length;
  
        return {
          ...filter,
          descriptionForView: `${filter.description} (${count})`
        }
      })
    }else{
      return accreditationBodyFilters.filter(x=>institutions.find(i=>!!i.accreditationBody && i.accreditationBody.id==x.id)).sort(sortBy('description')).map(filter => {
        const count = allInstitutions.filter(institution => {
          return !!institution.accreditationBody && institution.accreditationBody.id === filter.id;
        }).length;
  
        return {
          ...filter,
          descriptionForView: `${filter.description} (${count})`
        }
      })
    }
  }
)
export const SelectedAccreditationBodyFilters = createSelector(
  AccreditationBodyFilters,
  (filters) => filters.filter(x => x.selected)
)
export const AccreditationBodyFiltersCount = createSelector(
  SelectedAccreditationBodyFilters,
  (filters) => filters.length > 0 ? filters.length : null
)

export const RegionFilters = createSelector(
  selectCourseExchangeInstitutionState,
  ({institutions, regionFilters, allInstitutions, courseExchangeInstitutionListMode}) => {
    if (!institutions || !regionFilters || !allInstitutions) return [];

    if(courseExchangeInstitutionListMode == CourseExchangeInstitutionListMode.CourseExchange){
      return regionFilters.filter(x=>institutions.find(i=>!!i.address && !!i.address.region && i.address.region.id==x.id)).sort(sortBy('description')).map(filter => {
        const count = institutions.filter(institution => {
          return institution.address && institution.address.region && institution.address.region.id === filter.id;
        }).length;
  
        return {
          ...filter,
          descriptionForView: `${filter.description} (${count})`
        }
      })
    }else{
      return regionFilters.filter(x=>institutions.find(i=>!!i.address && !!i.address.region && i.address.region.id==x.id)).sort(sortBy('description')).map(filter => {
        const count = allInstitutions.filter(institution => {
          return institution.address && institution.address.region && institution.address.region.id === filter.id;
        }).length;
  
        return {
          ...filter,
          descriptionForView: `${filter.description} (${count})`
        }
      })
    }
  }
)
export const ListMode = createSelector(
  selectCourseExchangeInstitutionState,
  (state) => !!state ? state.courseExchangeInstitutionListMode : ListMode.CourseExchange
)

export const CurrentTypeFilter = createSelector(
  selectCourseExchangeInstitutionState,
  (state) => !!state ? state.currentType : CourseExchangeInstitutionType.Both
)

export const SelectedRegionFilters = createSelector(
  RegionFilters,
  (filters) => filters.filter(x => x.selected)
)
export const RegionFiltersCount = createSelector(
  SelectedRegionFilters,
  (filters) => filters.length > 0 ? filters.length : null
)

export const SelectedFilters = createSelector(
  SelectedStatusFilters,
  SelectedRegionFilters,
  SelectedAccreditationBodyFilters,
  (statusFilter, regionFilters, accreditationBodyFilters) =>
     [...statusFilter, ...regionFilters, ...accreditationBodyFilters]
)

export const SearchText = createSelector(
  selectCourseExchangeInstitutionState,
  (state) => state.searchText
)

export const CurrentSort = createSelector(
  selectCourseExchangeInstitutionState,
  (state) => state.currentSort
)

export const CurrentOrder = createSelector(
  selectCourseExchangeInstitutionState,
  (state) => state.currentOrder
)

export const AllInstitutions = createSelector(
  selectCourseExchangeInstitutionState,
  (state) => state.allInstitutions
)

export const FilteredCourseExchangeInstitutions = createSelector(
  selectCourseExchangeInstitutionState,
  CourseExchangeInstitutions,
  AllInstitutions,
  SelectedFilters,
  CurrentTypeFilter,
  SearchText,
  CurrentSort,
  CurrentOrder,
  (
    state,
    institutions,
    allInstitutions,
    selectedFilters,
    currentType,
    searchText,
    currentSort,
    currentOrder
  ) => {
    let filteredInstitutions = [];
    if(state.courseExchangeInstitutionListMode==CourseExchangeInstitutionListMode.CourseExchange){
      filteredInstitutions = [...institutions];
    }else{
      filteredInstitutions = [...allInstitutions];
    }
    
    const statusFilters = selectedFilters.filter(x=>x.type === FilterType.EntityStatus);
    if (statusFilters.length) {
      filteredInstitutions = filteredInstitutions.filter(institution => {
        const index = statusFilters.findIndex(item => institution.memberStatus === item.id || institution.providerStatus === item.id);
        return (index !== -1);
      })
    }
    const regionFilters = selectedFilters.filter(x=>x.type===FilterType.Region);
    if (regionFilters.length) {
      filteredInstitutions = filteredInstitutions.filter(institution => {
        if(institution.address === null || institution.address.region === null) return false;
        const index = regionFilters.findIndex(item => institution.address.region.id === item.id);
        return (index !== -1);
      })
    }
    const accreditationBodyFilters = selectedFilters.filter(x=>x.type===FilterType.AccreditationBody);
    if (accreditationBodyFilters.length) {
      filteredInstitutions = filteredInstitutions.filter(institution => {
        const index = accreditationBodyFilters.findIndex(item => !!institution.accreditationBody && institution.accreditationBody.id==item.id);
        return (index !== -1);
      })
    }

    switch (currentType) {
      case CourseExchangeInstitutionType.Member: {
        filteredInstitutions = filteredInstitutions.filter(institution => institution.isMember);
        break;
      }

      case CourseExchangeInstitutionType.Provider: {
        filteredInstitutions = filteredInstitutions.filter(institution => institution.isProvider)
        break;
      }
      default: {
        break;
      }
    }

    if (searchText) {
      filteredInstitutions = searchInText(searchText, filteredInstitutions, [
        'institutionName',
        'memberStatus',
        'providerStatus'
      ]);
    }

    const sort = currentSort.value;
    const order = currentOrder.value;

    filteredInstitutions = filteredInstitutions.sort(sortBy(`${order}${sort}`));

    return filteredInstitutions;
  }
)

export const AddNewInstitutionList = createSelector(
  AllInstitutions,
  CourseExchangeInstitutions,
  (allInstitutions, courseExchangeInstitutions) => 
    allInstitutions.map(x=>{
      const courseExchangeInstitution = courseExchangeInstitutions.find(x=>x.institutionId===x.institutionId);
      const isMember = courseExchangeInstitution.isMember;
      const isProvider = courseExchangeInstitution.isProvider;
      const institutionType = isMember && isProvider ? "Member, Provider" :
            isMember ? "Member" : "Provider";
      return {
        institutionId: x.institutionId,
        institutionName: x.institutionName,
        isMember,
        isProvider,
        institutionType,
        status: x.isActive ? "Active" : "Inactive",
        selected: false
      };
    })
)
export const  IsInstitutionLoading  = createSelector(
  selectCourseExchangeInstitutionState,
  (state) => state.IsLoadingInstitutions
)
