import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { Store, select } from "@ngrx/store";
import { tap, first, finalize, filter, map, concatMap, withLatestFrom } from "rxjs/operators";
import { IAppState } from 'app/store/state/app.state';
import { CourseExchange, CourseExchangePolicy } from 'app/shared/courseExchange/models';
import * as fromCourseExchange from '@courseExchange/store';
import { CourseExchangePolicyTemplate } from '@core/referenceData';

@Injectable()
export class CourseExchangePoliciesResolver implements Resolve<CourseExchangePolicy[]>{
    isLoading = false;
    constructor(private store: Store<IAppState>){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
        const courseExchangeId = route.params["courseExchangeId"];
        return this.store 
            .pipe(
                select(fromCourseExchange.Selectors.arePoliciesLoaded),
                concatMap(action => of(action).pipe(
                    withLatestFrom(this.store.pipe(select(fromCourseExchange.Selectors.CourseExchangeId)))
                )),
                map(([arePoliciesLoaded, currentCourseExchangeId]) => !!arePoliciesLoaded && currentCourseExchangeId && courseExchangeId),
                map((policyTemplatesLoaded)=>{
                    if(!this.isLoading && !policyTemplatesLoaded){
                        this.isLoading = true;
                        this.store.dispatch(fromCourseExchange.Actions.LoadPolicies({courseExchangeId}));
                    }
                    return true;
                }),
                filter(policyTemplatesLoaded => policyTemplatesLoaded),
                first(),
                finalize(()=>this.isLoading=false)
            )
    }
}