<mat-toolbar>
  <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <span class="title dialog-title">Select Student File</span>
    <button mat-icon-button (click)="close()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div class="content">
  <input #fileInput
          hidden="true"
           type="file"
           (change)="fileInputChange($event)"
           accept="application/json"/>

  <div class="actions">
    <button mat-raised-button color="warn" (click)="close()">
      <mat-icon>close</mat-icon>
      No, Cancel
    </button>

    <button mat-raised-button (click)="fileInput.click()">
      <mat-icon>call_made</mat-icon>
      Select Student Data File
    </button>
  </div>
</div>
