import {Component, SimpleChanges, Input, EventEmitter, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {hasErrorForm} from '../../../../helpers/has-error-form';
import {IControlsConfig} from '../../../../models/shared';
import {fillControlsConfig} from '../../../../helpers/fill-controls-config';
import {UserListItem} from '../../../user/models';
import {MatDialog} from '@angular/material/dialog';
import {ChecklistItemType} from '../../../../core/models/checklistItemType.enum';
import { InstitutionRole } from '@core/referenceData';
import { AddUserCommand, UpdateUserCommand } from '@shared/user/commands';
import { WizardSkipDialogContainerComponent, IWizardSkipDialogData } from '../wizard-skip-dialog-container/wizard-skip-dialog-container.component';
import { NavigationType } from '@shared/wizard/constants/navigateType.enum';
import {v4 as uuidv4} from 'uuid';

type IFormFields = Pick<UserListItem, 'department' | 'title' | 'firstName' | 'lastName' | 'phoneNumber' | 'emailAddress' | 'institutionRole'>;

type ILocalControlsConfig = IControlsConfig<IFormFields>;

@Component({
  selector: 'wizard-contacts-step-add-provost',
  templateUrl: './wizard-contacts-step-add-provost.component.html',
  styleUrls: ['../../styles/common-wizard-step.scss', './wizard-contacts-step-add-provost.component.scss']
})
export class WizardContactsStepAddProvostComponent {

  form: UntypedFormGroup;
  isSending = false;
  @Input()institutionId: string;
  @Input()user: UserListItem | null;
  @Input()institutionRoles: InstitutionRole[];
  @Output()addUser: EventEmitter<AddUserCommand> = new EventEmitter<AddUserCommand>();
  @Output()editUser:EventEmitter<UpdateUserCommand> = new EventEmitter<UpdateUserCommand>();
  @Output() navigate: EventEmitter<string> = new EventEmitter<string>();
  addAnotherUser: ChecklistItemType.ContactAndUserSetup_AdditionalUsers;
  addYear: ChecklistItemType.Calendar_AddYear;
  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group(this.shapeControlsConfig());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.user && changes.user.currentValue) {
      this.form = this.formBuilder.group(this.shapeControlsConfig());
    }
  }
  private shapeControlsConfig(): ILocalControlsConfig {
    const initialControlsConfig: ILocalControlsConfig = {
      department: ['', Validators.required],
      title: [''],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      emailAddress: ['', Validators.required],
      institutionRole: ['Provost', Validators.required]
    };

    if (this.user) {
      return fillControlsConfig<IFormFields>(initialControlsConfig, this.user);
    } else {
      return initialControlsConfig;
    }
  }

  getInitialEmail(): string {
    return this.user && this.user.emailAddress ? this.user.emailAddress : '';
  }

  getUserId(): string {
    return this.user && this.user.id ? this.user.id : '';
  }

  hasError = (controlName: keyof IFormFields, errorName: string) => hasErrorForm(controlName, errorName, this.form);

  onKeyDownForm(event): void {
    if(event.keyCode === 13) {
      this.form.valid ? this.onSubmit(true) : event.preventDefault();
    }
  }

  onSubmit = (addAnother: boolean) => {
    if (this.form.valid) {
      const {department, title, firstName, lastName, phoneNumber, emailAddress,institutionRole} = this.form.value;
      const id = this.user ? this.user.id : '';
      const institutionId = this.institutionId;
      if(id === ''){
        var addCommand: AddUserCommand = {
          id: uuidv4(),
          institutionId,
          department,
          title,
          firstName,
          lastName,
          phoneNumber,
          emailAddress,
          middleName: '',
          institutionRole
        };
        this.addUser.emit(addCommand);
      }else{
        var editCommand: UpdateUserCommand ={
          id,
          institutionId,
          department,
          title,
          firstName,
          lastName,
          phoneNumber,
          emailAddress,
          middleName: '',
          institutionRole
        }
        this.editUser.emit(editCommand);
      }
      if(addAnother){
        this.navigate.emit(NavigationType.Forward);
      }else{
        this.navigate.emit(NavigationType.Skip);
      }
    }
  }
  goBack = () => {
    this.navigate.emit(NavigationType.Back);
  }

  skip = () => {
    this.dialog.open<WizardSkipDialogContainerComponent, IWizardSkipDialogData>(WizardSkipDialogContainerComponent, {
      data: {
        itemType: ChecklistItemType.Calendar,
        toNextStepCb: this.toNextStep
      }
    });
  }

  toNextStep = () => {
    this.navigate.emit(NavigationType.Skip);
  }
}
