export interface SummaryItem
{
    id: string;
    description: string;
}

export const getDefaultSummaryItem = () => {
    var result: SummaryItem = {
        id: '',
        description: ''
    };
    return result;
}