import { Injectable } from '@angular/core';
import * as fromStore from '@courseExchange/institution';
import { IAppState } from 'app/store/state/app.state';
import { Store } from '@ngrx/store';
import { RealTimeService } from '@core/services/realTime.service';
import { CourseExchangeInstitutionListItem } from '@shared/courseExchange/models';
import { HubConnection } from '@microsoft/signalr';
@Injectable()
export class CourseExchangeInstitutionsRealtime {
        constructor(private store:Store<IAppState>, private realTimeService: RealTimeService) {
            this.initSignalRMessages();
        }
        
        private initSignalRMessages() {
            this.realTimeService.hubConnection$.subscribe(hubConnection => {
                if (!hubConnection) return;
                this.courseExchangeInstitutionListItemUpdated(hubConnection);
                this.courseExchangeInstitutionListItemAdded(hubConnection);
                this.courseExchangeInstitutionListItemDeleted(hubConnection);
            })
        }

        private courseExchangeInstitutionListItemUpdated(hubConnection:HubConnection){
            hubConnection.on('CourseExchangeInstitutionListItemUpdated', (courseExchangeInstitutionListItem: CourseExchangeInstitutionListItem) => {
                this.store.dispatch(fromStore.Actions.CourseExchangeInstitutionListItemUpdated({ courseExchangeInstitutionListItem }));
            });
        }
        private courseExchangeInstitutionListItemAdded(hubConnection:HubConnection){
            hubConnection.on('CourseExchangeInstitutionListItemAdded', (courseExchangeInstitutionListItem: CourseExchangeInstitutionListItem) => {
                this.store.dispatch(fromStore.Actions.CourseExchangeInstitutionListItemAdded({ courseExchangeInstitutionListItem }));
            }); 
        }
        private courseExchangeInstitutionListItemDeleted(hubConnection:HubConnection){
            hubConnection.on('CourseExchangeInstitutionListItemDeleted', (courseExchangeInstitutionListItem: CourseExchangeInstitutionListItem) => {
                this.store.dispatch(fromStore.Actions.CourseExchangeInstitutionListItemDeleted({ courseExchangeInstitutionListItem }));
            }); 
        }
}