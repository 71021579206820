import {CourseExchangeResolver} from './courseExchange.resolver';
import {CourseExchangeSummaryResolver} from './courseExchangeSummary.resolver';
import {CourseExchangePoliciesResolver} from './courseExchangePolicy.resolver';
import {CourseExchangeFeesResolver} from './fees.resolver';

export * from './fees.resolver';
export * from './courseExchangePolicy.resolver';
export * from './courseExchange.resolver';
export * from './courseExchangeSummary.resolver';

export const RESOLVERS = [CourseExchangeResolver, CourseExchangeSummaryResolver, CourseExchangePoliciesResolver,
        CourseExchangeFeesResolver];
