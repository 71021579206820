import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {AccessRole} from '@auth/models/accessRole';

@Component({
  selector: 'toolbar-action',
  templateUrl: './toolbar-action.component.html',
  styleUrls: ['./toolbar-action.component.scss', '../styles/common-toolbar.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ToolbarActionComponent implements OnInit {
  @Input() icon: string;
  @Input() tooltipTitle: string;
  @Output() onClick = new EventEmitter<any>();

  constructor(
  ) {
  }

  ngOnInit(): void {

  }
}
