import { AllInstitutionsResolver, CourseExchangeInstitutionResolver, CourseExchangeInstitutionsResolver } from '..';

export * from './actions';
export * from './effects';
export * from './reducers';
export * from './resolvers';
export * from './selectors';
export * from './static';

export const RESOLVERS = [AllInstitutionsResolver, CourseExchangeInstitutionResolver, CourseExchangeInstitutionsResolver];


