export enum FilterType
{
    InstitutionRole,
    CourseExchangeRole,
    UserStatus,
    Institution,
    CourseExchange,
    Text,
    EntityStatus,
    Status,
    Region,
    AccreditationBody,
    CourseExchangeInstitutionType,
    AcademicYear,
    Semester,
    CourseLevel,
    Provider,
    CourseCategory,
    CourseSubCategory,
    Tag,
    EnrollmentStatus,
    ProviderCourseStatus,
    Session,
    Member
}