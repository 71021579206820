import {get} from 'lodash';

export const searchInText = <T>(searchStr: string, arr: T[], pathKeys: string[]): T[] => {
  if (searchStr.length < 2) return arr;

  const result = arr.filter((item) => {
    return pathKeys.some((path: string): boolean => {
      const value: string = get(item, path);
      if(value===null){
         return false;
      }
      return value.toLowerCase().includes(searchStr.toLowerCase());
    });
  });

  return result;
};
