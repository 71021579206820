import { PresetFilterType } from '.';
import { ProviderCourseStatus } from '@provider/courses/models/providerCourseStatus.enum';
import { FilterType } from '@shared/common/models';

export const Orders = [
    { name: 'Ascending', icon: 'arrow_upward', value: '', isDefault: true },
    { name: 'Descending', icon: 'arrow_downward', value: '-', isDefault: false },
  ];

export const PresetFilterTypes = [
    {
        id: PresetFilterType.PendingCourses,
        description: 'My Pending Courses'
    },
    {
        id: PresetFilterType.ApprovedCourses,
        description: 'My Approved Courses'
    },
    {
        id: PresetFilterType.AllCourses,
        description: 'All Courses'
    }
];

export const ProviderCourseStatusFilters = [
  {id: ProviderCourseStatus.Approved, type: FilterType.ProviderCourseStatus, description: ProviderCourseStatus.Approved, descriptionForView: ProviderCourseStatus.Approved, selected: false},
  {id: ProviderCourseStatus.Deactivated, type: FilterType.ProviderCourseStatus, description: ProviderCourseStatus.Deactivated, descriptionForView: ProviderCourseStatus.Deactivated, selected:false},
  {id: ProviderCourseStatus.Denied, type: FilterType.ProviderCourseStatus, description: ProviderCourseStatus.Denied, descriptionForView: ProviderCourseStatus.Denied, selected: false},
  {id: ProviderCourseStatus.Pending, type: FilterType.ProviderCourseStatus, description: ProviderCourseStatus.Pending, descriptionForView: ProviderCourseStatus.Pending, selected: false}
];

export const Sorts =  [
    {name: 'Level', icon: 'signal_cellular_alt',value: 'level.description', isDefault: false},
    {name: 'Category', icon: 'category', value: 'category.description', isDefault: false},
    {name: 'Course Name', icon: 'title', value: 'title', isDefault: false},
    {name: 'Course Code', icon: 'view_list', value: 'code', isDefault: true},
    {name: 'Provider', icon: 'school_outline', value: 'institution.description', isDefault: false},
  ];
