import {Component, ViewEncapsulation} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'enrollment-dialog-drop-course',
  templateUrl: './enrollment-dialog-drop-course.component.html',
  styleUrls: ['./enrollment-dialog-drop-course.component.scss', '../styles/enrollment-drop-detail-common.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EnrollmentDialogDropCourseComponent {
  isAccordionShow = true;

  constructor(
    public matDialogRef: MatDialogRef<EnrollmentDialogDropCourseComponent>,
  ) {
    this.matDialogRef.addPanelClass('enrollment-dialog-drop-course');
  }

  close(): void {
    this.matDialogRef.close();
  }
}
