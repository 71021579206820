import { CreditHourType } from '@core/models';
import { ElbertFile, getDefaultElbertFile } from 'app/core/models/elbertFile.model';
import { FacultyCV } from './facultyCV.model';

export interface Course{
    id: string;
    levelId: string;
    categoryId: string;
    subCategoryId: string;
    isActive: boolean;
    code: string;
    title: string;
    creditHours: number;
    creditHours2: string;
    creditHourType: CreditHourType;
    requirements: string;
    description: string;
    notes: string;
    syllabus: ElbertFile;
    facultyCVIds: Array<string>;
    availableSeats: number;
    sessions: Array<string>;
    tags: Array<string>;
};

export interface CourseForView extends Course{
    selected: boolean;
    facultCVs: Array<FacultyCV>;
}

export const getDefaultCourse = () => {
    var result: Course ={
        id: '',
        levelId: '',
        categoryId: '',
        subCategoryId: '',
        isActive: true,
        code: '',
        title: '',
        creditHours: 0,
        creditHours2: '0',
        creditHourType: CreditHourType.SEM,
        requirements: '',
        description: '',
        notes: '',
        syllabus: getDefaultElbertFile(),
        facultyCVIds: new Array<string>(),
        availableSeats: 0,
        sessions: new Array<string>(),
        tags: new Array<string>(),
    }
    return result;
}