import {Injectable} from "@angular/core";
import {Store} from '@ngrx/store';
import {IAppState} from 'app/store/state/app.state';
import * as fromMemberBilling from '@member/billing/store';
import { RealTimeService } from "@core/services/realTime.service";
import { HubConnection } from "@microsoft/signalr";
import { MemberMonthlyBill } from "@member/billing/models/memberMonthlyBill";

@Injectable()
export class MemberBillingRealtime {
  constructor(private store: Store<IAppState>, private realTimeService: RealTimeService) {
    this.initSignalRMessages();
}

private initSignalRMessages() {
    this.realTimeService.hubConnection$.subscribe(hubConnection => {
            if (!hubConnection) return;
            this.memberMonthlyBillUpdated(hubConnection);
            this.memberMonthlyBillAdded(hubConnection);
    })
}
private memberMonthlyBillUpdated(hubConnection: HubConnection) {
    hubConnection.on('MemberMonthlyBillUpdated', (memberMonthlyBill: MemberMonthlyBill) => {
            this.store.dispatch(fromMemberBilling.Actions.MemberMonthlyBillUpdated({ memberMonthlyBill }));
    });
}private memberMonthlyBillAdded(hubConnection: HubConnection) {
  hubConnection.on('MemberMonthlyBillAdded', (memberMonthlyBill: MemberMonthlyBill) => {
          this.store.dispatch(fromMemberBilling.Actions.MemberMonthlyBillAdded({ memberMonthlyBill }));
  });
}
}
