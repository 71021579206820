export const AVATAR_COLORS = {
  default: '#8D38C9',
  alphabets: {
    A: '#357EC7',
    B: '#C11B17',
    C: '#008080',
    D: '#F52887',
    E: '#0020C2',
    F: '#347C17',
    G: '#D4A017',
    H: '#7D0552',
    I: '#9F000F',
    J: '#E42217',
    K: '#728C00',
    L: '#571B7E',
    M: '#1F45FC',
    N: '#C35817',
    O: '#F87217',
    P: '#41A317',
    Q: '#4C4646',
    R: '#4CC417',
    S: '#C12869',
    T: '#15317E',
    U: '#AF7817',
    V: '#F75D59',
    W: '#FF0000',
    X: '#000000',
    Y: '#E9AB17',
    Z: '#8D38C9'
  }
}
