<form id="detailForm" [formGroup]="elbertFile">
    <div class="c_form_wrap p-20" fusePerfectScrollbar fxLayout="column">
        <div fxLayout="row" fxLayoutGap="32px">
            <mat-form-field appearance="outline" floatLabel="always" class="w-100-p">
                <input matInput placeholder="Policy Name" name="name" formControlName="name" />
            </mat-form-field>
            <input hidden type="file" (change)="onFileSelected($event)" #fileInput />
            <button mat-fab color="warn" class="add-file-button" (click)="fileInput.click()" aria-label="Add file" [disabled]="uploading">
                <mat-icon>add</mat-icon>
            </button>
        </div>
    </div>
</form>