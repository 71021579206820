import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { InstitutionRole } from '../referenceData/institutionRole';
import { BaseListItem } from '../models/baseListItem';
import { CourseSubCategory } from '../referenceData/courseSubCategory';
import { CourseCategory, AccreditationBody, CourseExchangeRole, CourseExchangePolicyTemplate, ProviderPolicyTemplate, Country, Region, EnrollmentStatus } from '../referenceData';
import { AddCourseCategoryCommand, EditCourseCategoryCommand, AddCourseSubCategoryCommand, EditCourseSubCategoryCommand, AddCourseLevelCommand, EditCourseLevelCommand, AddCourseTagCommand, EditCourseTagCommand, AddCourseExchangePolicyTemplateCommand, EditCourseExchangePolicyTemplateCommand, AddProviderPolicyTemplateCommand, EditProviderPolicyTemplateCommand, EditStudentTypeCommand, AddStudentTypeCommand } from '../../admin/reference-data/commands';
import { BaseResponse } from '../models/baseResponse.model';
import { PopupService } from './popup.service';
import { InstitutionSummary } from 'app/shared/institution/models';
import { PermissionDisplay } from 'app/authentication/models/permissionDisplay';
import { saveAs } from 'file-saver';
import { CourseExchangeListItem } from '@shared/courseExchange/models';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class RefDataService {
  private apiUrl:string ='';
  constructor(private http:HttpClient,private popupService: PopupService,private config:ConfigService) {
    this.config.apiUrl$.subscribe(result=>this.apiUrl=`${result}/refData`);
  }
  
  getAccreditationBodies(): Observable<Array<AccreditationBody>>{
      return this.http.get<Array<AccreditationBody>>(`${this.apiUrl}/accreditationBodies`);
  }

  getCountries(): Observable<Country[]>{
    return this.http.get<Country[]>(`${this.apiUrl}/countries`);
  }

  getGenders(): Observable<Array<BaseListItem>>{
    return this.http.get<Array<BaseListItem>>(`${this.apiUrl}/genders`);
  }

  getCourseExchanges(): Observable<Array<CourseExchangeListItem>>{
    return this.http.get<Array<CourseExchangeListItem>>(`${this.apiUrl}/courseExchanges`);
  }

  //#region Course Categories
  getCourseCategories$(): Observable<Array<CourseCategory>>{
    return this.http.get<Array<CourseCategory>>(`${this.apiUrl}/courseCategories`);
  }

  public addCourseCategory(command: AddCourseCategoryCommand): Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.apiUrl}/courseCategories`, command)
      .pipe(
        tap((response) => this.popupService.handleBaseResponse(response))
      );
  }

  public editCourseCategory(command: EditCourseCategoryCommand): Observable<BaseResponse>{
    return this.http.put<BaseResponse>(`${this.apiUrl}/courseCategories`, command)
      .pipe(
        tap((response) => this.popupService.handleBaseResponse(response))
      );
  }

  public toggleActiveCourseCategory(courseCategoryId: string): Observable<BaseResponse>{
    return this.http.put<BaseResponse>(`${this.apiUrl}/courseCategories/toggleActive`,{id:courseCategoryId})
      .pipe(
        tap((response)=> this.popupService.handleBaseResponse(response))
      );
  }

  public deleteCourseCategory(courseCategoryId: string): Observable<BaseResponse>{
    return this.http.delete<BaseResponse>(`${this.apiUrl}/courseCategories/${courseCategoryId}`)
      .pipe(
        tap((response)=> this.popupService.handleBaseResponse(response))
      );
  }
  //#endregion
  //#region Course SubCategories
  getCourseSubCategories(): Observable<Array<CourseSubCategory>>{
    return this.http.get<Array<CourseSubCategory>>(`${this.apiUrl}/courseSubCategories`);
  }

  public addCourseSubCategory(command: AddCourseSubCategoryCommand): Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.apiUrl}/courseSubCategories`, command)
      .pipe(
        tap((response) => this.popupService.handleBaseResponse(response))
      );
  }

  public editCourseSubCategory(command: EditCourseSubCategoryCommand): Observable<BaseResponse>{
    return this.http.put<BaseResponse>(`${this.apiUrl}/courseSubCategories`, command)
      .pipe(
        tap((response) => this.popupService.handleBaseResponse(response))
      );
  }

  public toggleActiveCourseSubCategory(courseSubCategoryId: string): Observable<BaseResponse>{
    return this.http.put<BaseResponse>(`${this.apiUrl}/courseSubCategories/toggleActive`,{id:courseSubCategoryId})
      .pipe(
        tap((response)=> this.popupService.handleBaseResponse(response))
      );
  }

  public deleteCourseSubCategory(courseSubCategoryId: string): Observable<BaseResponse>{
    return this.http.delete<BaseResponse>(`${this.apiUrl}/courseSubCategories/${courseSubCategoryId}`)
      .pipe(
        tap((response)=> this.popupService.handleBaseResponse(response))
      );
  }
  //#endregion
  //#region Course Levels
  getCourseLevels(): Observable<BaseListItem[]>{
    return this.http.get<BaseListItem[]>(`${this.apiUrl}/courseLevels`);
  }
  public addCourseLevel(command: AddCourseLevelCommand): Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.apiUrl}/courseLevels`, command)
      .pipe(
        tap((response) => this.popupService.handleBaseResponse(response))
      );
  }

  public editCourseLevel(command: EditCourseLevelCommand): Observable<BaseResponse>{
    return this.http.put<BaseResponse>(`${this.apiUrl}/courseLevels`, command)
      .pipe(
        tap((response) => this.popupService.handleBaseResponse(response))
      );
  }

  public toggleActiveCourseLevel(courseLevelId: string): Observable<BaseResponse>{
    return this.http.put<BaseResponse>(`${this.apiUrl}/courseLevels/toggleActive`,{id:courseLevelId})
      .pipe(
        tap((response)=> this.popupService.handleBaseResponse(response))
      );
  }

  public deleteCourseLevel(courseLevelId: string): Observable<BaseResponse>{
    return this.http.delete<BaseResponse>(`${this.apiUrl}/courseLevels/${courseLevelId}`)
      .pipe(
        tap((response)=> this.popupService.handleBaseResponse(response))
      );
  }
  //#endregion
  //#region Course Tags
  getCourseTags(): Observable<BaseListItem[]>{
    return this.http.get<Array<BaseListItem>>(`${this.apiUrl}/courseTags`);
  }
  public addCourseTag(command: AddCourseTagCommand): Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.apiUrl}/courseTags`, command)
      .pipe(
        tap((response) => this.popupService.handleBaseResponse(response))
      );
  }

  public editCourseTag(command: EditCourseTagCommand): Observable<BaseResponse>{
    return this.http.put<BaseResponse>(`${this.apiUrl}/courseTags`, command)
      .pipe(
        tap((response) => this.popupService.handleBaseResponse(response))
      );
  }
  public toggleActiveCourseTag(courseTagId: string): Observable<BaseResponse>{
    return this.http.put<BaseResponse>(`${this.apiUrl}/courseTags/toggleActive`,{id:courseTagId})
      .pipe(
        tap((response)=> this.popupService.handleBaseResponse(response))
      );
  }

  public deleteCourseTag(courseTagId: string): Observable<BaseResponse>{
    return this.http.delete<BaseResponse>(`${this.apiUrl}/courseTags/${courseTagId}`)
      .pipe(
        tap((response)=> this.popupService.handleBaseResponse(response))
      );
  }
  //#endregion
  //#region Student Types
  getStudentTypes(): Observable<BaseListItem[]>{
    return this.http.get<Array<BaseListItem>>(`${this.apiUrl}/studentTypes`);
  }
  public addStudentType(command: AddStudentTypeCommand): Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.apiUrl}/studentTypes`, command)
      .pipe(
        tap((response) => this.popupService.handleBaseResponse(response))
      );
  }

  public editStudentType(command: EditStudentTypeCommand): Observable<BaseResponse>{
    return this.http.put<BaseResponse>(`${this.apiUrl}/studentTypes`, command)
      .pipe(
        tap((response) => this.popupService.handleBaseResponse(response))
      );
  }
  public toggleActiveStudentType(studentTypeId: string): Observable<BaseResponse>{
    return this.http.put<BaseResponse>(`${this.apiUrl}/studentTypes/toggleActive`,{id:studentTypeId})
      .pipe(
        tap((response)=> this.popupService.handleBaseResponse(response))
      );
  }

  public deleteStudentType(studentTypeId: string): Observable<BaseResponse>{
    return this.http.delete<BaseResponse>(`${this.apiUrl}/studentTypes/${studentTypeId}`)
      .pipe(
        tap((response)=> this.popupService.handleBaseResponse(response))
      );
  }
  //#endregion
//#region Course Exchange Policy Templates
getCourseExchangePolicyTemplates(): Observable<Array<CourseExchangePolicyTemplate>>{
  return this.http.get<Array<CourseExchangePolicyTemplate>>(`${this.apiUrl}/courseExchangePolicyTemplates`);
}

public addCourseExchangePolicyTemplate(command: AddCourseExchangePolicyTemplateCommand): Observable<BaseResponse>{
  const {policyName, policyFile} = command;
    const formData = new FormData();
    formData.append('policyFile', policyFile, policyFile.name);
    formData.append('policyName', policyName);
    
  return this.http.post<BaseResponse>(`${this.apiUrl}/courseExchangePolicyTemplates`, formData)
    .pipe(
      tap((response) => this.popupService.handleBaseResponse(response))
    );
}

public editCourseExchangePolicyTemplate(command: EditCourseExchangePolicyTemplateCommand): Observable<BaseResponse>{
  const {id,policyName, policyFile} = command;
  const formData = new FormData();
  formData.append('id', id);
  formData.append('policyFile', policyFile, !!policyFile ? policyFile.name: "");
  formData.append('policyName', policyName);
  return this.http.put<BaseResponse>(`${this.apiUrl}/courseExchangePolicyTemplates`, formData)
    .pipe(
      tap((response) => this.popupService.handleBaseResponse(response))
    );
}

public toggleActiveCourseExchangePolicyTemplate(courseExchangePolicyTemplateId: string): Observable<BaseResponse>{
  return this.http.put<BaseResponse>(`${this.apiUrl}/courseExchangePolicyTemplates/toggleActive`,{id:courseExchangePolicyTemplateId})
    .pipe(
      tap((response)=> this.popupService.handleBaseResponse(response))
    );
}

public deleteCourseExchangePolicyTemplate(courseExchangePolicyTemplateId: string): Observable<BaseResponse>{
  return this.http.delete<BaseResponse>(`${this.apiUrl}/courseExchangePolicyTemplates/${courseExchangePolicyTemplateId}`)
    .pipe(
      tap((response)=> this.popupService.handleBaseResponse(response))
    );
}

public downloadCourseExchangePolicyTemplate(courseExchangePolicyTemplate: CourseExchangePolicyTemplate) {    
  return this.http.get(`${this.apiUrl}/courseExchangePolicyTemplates/download/${courseExchangePolicyTemplate.id}`, { responseType: "blob" })  
    .subscribe((result: any) => {  
      if (result.type != 'text/plain') {  
        var blob = new Blob([result]);  
          
        saveAs(blob, courseExchangePolicyTemplate.template.fileName);  
      }  
    }  
    );  
}  
//#endregion
//#region Provider Policy Templates
getProviderPolicyTemplates(): Observable<Array<ProviderPolicyTemplate>>{
  return this.http.get<Array<ProviderPolicyTemplate>>(`${this.apiUrl}/providerPolicyTemplates`);
}

public addProviderPolicyTemplate(command: AddProviderPolicyTemplateCommand): Observable<BaseResponse>{
  const {policyName, policyFile} = command;
    const formData = new FormData();
    formData.append('policyFile', policyFile, policyFile.name);
    formData.append('policyName', policyName);
    
  return this.http.post<BaseResponse>(`${this.apiUrl}/providerPolicyTemplates`, formData)
    .pipe(
      tap((response) => this.popupService.handleBaseResponse(response))
    );
}

public editProviderPolicyTemplate(command: EditProviderPolicyTemplateCommand): Observable<BaseResponse>{
  const {id,policyName, policyFile} = command;
  const formData = new FormData();
  formData.append('id', id);
  formData.append('policyFile', policyFile, !!policyFile ? policyFile.name : "");
  formData.append('policyName', policyName);
  return this.http.put<BaseResponse>(`${this.apiUrl}/providerPolicyTemplates`, formData)
    .pipe(
      tap((response) => this.popupService.handleBaseResponse(response))
    );
}

public toggleActiveProviderPolicyTemplate(providerPolicyTemplateId: string): Observable<BaseResponse>{
  return this.http.put<BaseResponse>(`${this.apiUrl}/providerPolicyTemplates/toggleActive`,{id:providerPolicyTemplateId})
    .pipe(
      tap((response)=> this.popupService.handleBaseResponse(response))
    );
}

public deleteProviderPolicyTemplate(providerPolicyTemplateId: string): Observable<BaseResponse>{
  return this.http.delete<BaseResponse>(`${this.apiUrl}/providerPolicyTemplates/${providerPolicyTemplateId}`)
    .pipe(
      tap((response)=> this.popupService.handleBaseResponse(response))
    );
}

public downloadProviderPolicyTemplate(providerPolicyTemplate: ProviderPolicyTemplate) {    
  return this.http.get(`${this.apiUrl}/providerPolicyTemplates/download/${providerPolicyTemplate.id}`, { responseType: "blob" })  
    .subscribe((result: any) => {  
      if (result.type != 'text/plain') {  
        var blob = new Blob([result]);  
          
        saveAs(blob, providerPolicyTemplate.template.fileName);  
      }  
    }  
    );  
}  

//#endregion

public downloadBankInfoTemplate():void{
  this.http.get(`${this.apiUrl}/downloadBankInfoTemplate`, { responseType: "blob" })  
    .subscribe((result: any) => {  
      if (result.type != 'text/plain') {  
        var blob = new Blob([result]);  
          
        saveAs(blob, "BankInfoTemplate.docx");  
      }  
    }  
    );    
}
getRegions(): Observable<Array<Region>>{
  return this.http.get<Array<Region>>(`${this.apiUrl}/regions`);
}

getInstitutionRoles(): Observable<Array<InstitutionRole>>{
  return this.http.get<Array<InstitutionRole>>(`${this.apiUrl}/institutionRoles`);
}

getCourseExchangeRoles(): Observable<Array<CourseExchangeRole>>{
  return this.http.get<Array<CourseExchangeRole>>(`${this.apiUrl}/courseExchangeRoles`);
}

getInstitutionSummaries(): Observable<Array<InstitutionSummary>>{
  return this.http.get<Array<InstitutionSummary>>(`${this.apiUrl}/institutions`);
}

  getPermissionDisplays(): Observable<Array<PermissionDisplay>>
  {
    return this.http.get<Array<PermissionDisplay>>(`${this.apiUrl}/permissionDisplayList`);
  }

  getEnrollmentStatuses(): Observable<Array<EnrollmentStatus>>{
    return this.http.get<Array<EnrollmentStatus>>(`${this.apiUrl}/enrollmentStatuses`);
  }

  getLetterGrades(): Observable<Array<BaseListItem>>{
    return this.http.get<Array<BaseListItem>>(`${this.apiUrl}/letterGrades`);
  }
  
}
