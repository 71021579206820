import {StudentListComponent} from './student-list/student-list.component';
import {StudentDetailComponent} from './student-detail/student-detail.component';
import {StudentAddEditComponent} from './student-add-edit/student-add-edit.component';
import {StudentAddedDialogComponent} from '@member/students/components/student-added-dialog/student-added-dialog.component';
import {StudentDetailsForDialogComponent} from '@member/students/components/student-details-for-dialog/student-details-for-dialog.component';
import {StudentDeleteDialogComponent} from '@member/students/components/student-delete-dialog/student-delete-dialog.component';
import {StudentResultActionDialogComponent} from '@member/students/components/student-result-action-dialog/student-result-action-dialog.component';

export * from './student-detail/student-detail.component';
export * from './student-list/student-list.component';
export * from './student-add-edit/student-add-edit.component';
export * from '@member/students/components/student-added-dialog/student-added-dialog.component';
export * from '@member/students/components/student-details-for-dialog/student-details-for-dialog.component';
export * from '@member/students/components/student-delete-dialog/student-delete-dialog.component';
export * from '@member/students/components/student-result-action-dialog/student-result-action-dialog.component';

export const COMPONENTS = [
  StudentListComponent,
  StudentDetailComponent,
  StudentAddEditComponent,
  StudentAddedDialogComponent,
  StudentDetailsForDialogComponent,
  StudentDeleteDialogComponent,
  StudentResultActionDialogComponent,
];

export const ENTRY_COMPONENTS = [
  StudentAddedDialogComponent,
  StudentDeleteDialogComponent,
  StudentResultActionDialogComponent,
];
