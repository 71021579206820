export enum CourseFilterType
{
    Level,
    Provider,
    SessionDate,
    Category,
    SubCategory,
    Tag,
    Status,
    Text
}