import * as fromStore from '@courseExchange/billing/store';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
@NgModule({
    imports: [
        StoreModule.forFeature('courseExchange/billing', fromStore.courseExchangeBillingReducer),
        EffectsModule.forFeature([...fromStore.EFFECTS])
    ],
    providers: [...fromStore.RESOLVERS]
})
export class CourseExchangeBillingStoreModule { }
