import { createAction, props } from '@ngrx/store';
import { Member } from 'app/shared/member/models';
import { CourseExchangeSummary, CourseExchangeFeeListItem } from '@shared/courseExchange/models';

export const LoadMember = createAction(
    '[Member Resolver] - Load Member',
    props<{
        memberId: string;
    }>()
);

export const LoadMemberSuccess = createAction(
    '[Member Effects] - Load Member Success',
    props<{
        member: Member;
    }>()
)

export const LoadInstitutionSummary = createAction(
    '[Member Institution Summary Resolver] - Load Institution Summary',
    props<{
        memberId: string;
        institutionId: string;
    }>()
);

export const CompleteMemberSetup = createAction(
    '[Member Wizard Bank Info Step] - Complete Member Setup'
);

export const ClearState = createAction(
    '[Member Reducer] - Clear State'
)

export const LoadCourseExchangeSummary = createAction(
    '[Member Course Exchange Resolver] - Load CourseExchange Summary',
    props<{
        memberId: string;
    }>()
)

export const MemberUpdated = createAction(
    '[Member Effects] - MemberUpdated',
    props<{
        member: Member
    }>()
)