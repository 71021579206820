<h1>{{dialogTitle}}</h1>
<ng-container *ngIf="courseFeeForm">
    <ng-container [formGroup]="courseFeeForm">
      <mat-form-field>
        <input matInput
               placeholder="Member Fee"
               formControlName="memberFeeAmount" (keyup)="changeMemberFee($event.target.value)"
               (blur)="formatMemberFee($event.target.value)">
      </mat-form-field>

      <mat-form-field>
        <input matInput
               placeholder="Provider Fee"
               formControlName="providerFeeAmount" (keyup)="changeProviderFee($event.target.value)"
               (blur)="formatProviderFee($event.target.value)">
      </mat-form-field>

      <mat-form-field>
        <input matInput
               placeholder="Provider Fee Split"
               formControlName="providerFeeSplit" (keyup)="changeProviderSplit($event.target.value)"
               (blur)="formatProviderSplit($event.target.value)">
      </mat-form-field>

      <mat-form-field>
        <input matInput
               placeholder="Management Fee"
               formControlName="managementFeeAmount" (keyup)="changeManagementFee($event.target.value)"
               (blur)="formatManagementFee($event.target.value)">
      </mat-form-field>

      <mat-form-field>
        <input matInput
               placeholder="Management Fee Split"
               formControlName="managementFeeSplit" (keyup)="changeManagementSplit($event.target.value)"
               (blur)="formatManagementSplit($event.target.value)">

      </mat-form-field>
      <mat-form-field *ngIf="showEffectiveDate">
        <input matInput [matDatepicker]="effectiveDatePicker" placeholder="Effective Date" formControlName="effectiveDate"
          (dateChange)="setEffectiveDate($event)">
        <mat-datepicker-toggle matSuffix [for]="effectiveDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #effectiveDatePicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field *ngIf="showExpiryDate">
        <input matInput [matDatepicker]="expiryDatePicker" placeholder="Expiry Date" formControlName="expiryDate"
          (dateChange)="setExpiryDate($event)">
        <mat-datepicker-toggle matSuffix [for]="expiryDatePicker"></mat-datepicker-toggle>
        <mat-datepicker #expiryDatePicker ></mat-datepicker>
      </mat-form-field>
    </ng-container>
</ng-container>
<button mat-button (click)="saveCourseFee()" [disabled]="!courseFeeForm?.valid" *ngIf="!isNew">Save</button>
<button mat-button (click)="addCourseFee()" [disabled]="!courseFeeForm?.valid" *ngIf="isNew">Add</button>
<button mat-button (click)="close()">Cancel</button>
