<mat-toolbar>
  <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <span class="title dialog-title">Update Enrollment Letter Grade</span>
    <button mat-icon-button (click)="close()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div class="content">
  <update-enrollment-letter-grade
    (cancel)="close()"
    (updateEnrollmentLetterGrade)="updateEnrollmentLetterGrade($event)"
    [enrollments]="enrollments$ | async"
    [letterGrades]="letterGrades$ | async"
  ></update-enrollment-letter-grade>
</div>
