import {createAction, props} from '@ngrx/store';
import { UserNotification } from 'app/shared/common/models';

export const LoadNotifications = createAction(
  '[Notification] - Load Notifications'
)

export const LoadNotificationsSuccess = createAction(
  '[Notification] - Load Notifications Success',
  props<{
    notifications: UserNotification[]
  }>()
)

export const LoadNotificationsError = createAction(
  '[Notification] - Load Notifications Error',
  props<{
    message: string
  }>()
)

export const NotificationAdded = createAction(
  '[Notification] - Notification Added',
  props<{
    notification: UserNotification
  }>()
)

export const NotificationEdited = createAction(
  '[Notification] - Notification Edited',
  props<{
    notification: UserNotification
  }>()
)

export const MarkNotificationsAsRead = createAction(
  '[Notification] - Mark Notification As Read',
  props<{
    notification: UserNotification
  }>()
)

export const MarkAllNotificationsAsRead = createAction(
  '[Notification] - Mark All Notifications as Read'
)

export const NotificationMarkedAsRead = createAction(
  '[Notification] - Notification Marked as Read',
  props<{
    notification: UserNotification
  }>()
)

export const ToggleNotificationBar = createAction(
  '[Notification] - Toggle Notification Bar',
  props<{
    toggle?: boolean
  }>()
)



