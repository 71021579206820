import {Injectable} from "@angular/core";
import {Store} from '@ngrx/store';
import {IAppState} from 'app/store/state/app.state';
import * as fromStudent from '@member/students';
import { RealTimeService } from "@core/services/realTime.service";
import { StudentListItem } from "@shared/student/models";
import { AuthenticationService } from "@auth/services/authentication.service";
import { HubConnection } from "@microsoft/signalr";

@Injectable()
export class StudentsRealtime {
  constructor(private store: Store<IAppState>,  private realTimeService: RealTimeService, private authService:AuthenticationService) {
    this.initSignalRMessages();
  }

  private initSignalRMessages(){
    this.realTimeService.hubConnection$.subscribe(hubConnection=>{
      if(!hubConnection) return;
      this.studentAdded(hubConnection);
      this.studentUpdated(hubConnection);
      this.studentDeleted(hubConnection);
    })
  }

  private studentAdded(hubConnection:HubConnection){
    hubConnection.on('StudentAdded', (student: StudentListItem) => {
      this.store.dispatch(fromStudent.Actions.StudentAdded({student}));
    });
  }
  private studentUpdated(hubConnection:HubConnection){
    hubConnection.on('StudentUpdated', (student: StudentListItem)=>{
      this.store.dispatch(fromStudent.Actions.StudentUpdated({student}));
    });
  }
  private studentDeleted(hubConnection:HubConnection){
    hubConnection.on('StudentDeleted', (id: string)=>{
      this.store.dispatch(fromStudent.Actions.DeleteStudentSuccess({id}));
    })
  }
}
