import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { Store, select } from "@ngrx/store";
import { first, finalize, filter, map, concatMap, withLatestFrom } from "rxjs/operators";
import { IAppState } from 'app/store/state/app.state';
import * as fromUsers from '@shared/user/store';
import { getInstitutionIdFromRoute } from 'app/helpers/routeParamHelper';
import { User } from "@shared/user/models";
@Injectable()
export class UserResolver implements Resolve<User>{
    isLoading = false;
    constructor(private store: Store<IAppState>){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
        const institutionId = getInstitutionIdFromRoute(route);
        const userId = route.params['userId'];
        return this.store 
            .pipe(
                select(fromUsers.Selectors.UserForResolver),
                map((args) => args.currentUser.id == args.currentUser.id),
                map((isUserLoaded)=>{
                    if(!this.isLoading && !isUserLoaded){
                        this.isLoading = true;
                        this.store.dispatch(fromUsers.Actions.LoadUser({institutionId, userId}));
                    }
                    return true;
                }),
                filter(userLoaded => userLoaded),
                first(),
                finalize(()=>this.isLoading=false)
            )
    }
}