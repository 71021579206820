import { BehaviorSubject, Subscription } from 'rxjs';
 
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
 
import { Store } from '@ngrx/store';
import * as signalR from '@microsoft/signalr';
import { IAppState } from 'app/store/state/app.state';
import { ConfigService } from './config.service';
import { UserSummary } from '@auth/models/userSummary.model';
import { environment } from 'environments/environment';
 
@Injectable({
  providedIn: 'root'
})
export class RealTimeService {
  private hubConnection: signalR.HubConnection | undefined;
  private headers: HttpHeaders | undefined;
 
  isAuthorizedSubscription: Subscription | undefined;
  isAuthorized = false;
  currentToken: string='';
  apiUrl: string = "http://localhost:4000/api";
  public hubConnection$: BehaviorSubject<signalR.HubConnection> = new BehaviorSubject<signalR.HubConnection>(null);
  constructor(
    private store: Store<IAppState>,
    private config: ConfigService
  ) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.set('Content-Type', 'application/json');
    this.headers = this.headers.set('Accept', 'application/json');
    this.config.apiUrl$.subscribe(apiUrl=>{
      if(apiUrl==='')return;
      this.apiUrl=apiUrl;
      const user:UserSummary = JSON.parse(localStorage.getItem('user'));
      if(!user || this.currentToken == user.token) return;
      if(user) this.initHub(user.token,this.apiUrl);
    });
  }
 
 
  public initHub(token: string, apiUrl: string): void {
    console.log('RealTime: initHub');
    let tokenValue = '';
    if (token !== '') {
      tokenValue = '?token=' + token;
    }
    let connectUrl = `${apiUrl}/realTime${tokenValue}`;
    var logLevel = environment.production ? signalR.LogLevel.Critical : signalR.LogLevel.Information;
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(connectUrl)
      .withAutomaticReconnect()
      .configureLogging(logLevel)
      .build();
    this.hubConnection.keepAliveIntervalInMilliseconds = 1000 * 60 * 3;
    this.hubConnection.serverTimeoutInMilliseconds = 1000 * 60 * 6;

    this.hubConnection.start()
      .then(()=>console.log("Connected to WebApi Signal R"))
      .catch((err) => console.log(err.toString()));
 
    this.hubConnection$.next(this.hubConnection);

  }

  public stopHub():void{
    if(this.hubConnection){
      this.hubConnection.stop().catch((err)=> console.error(err.toString()));
    }
    this.currentToken = '';
  }
}