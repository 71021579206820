import { SemesterFilter } from "../models";

  export default  (semesterFilters: SemesterFilter[], sessionId: string): SemesterFilter[] => {
    var semesterFilter = semesterFilters.find(x=>x.sessionFilters.find(y=>y.id===sessionId));
    var sessionFilter = semesterFilter.sessionFilters.find(x=>x.id===sessionId);
    sessionFilter.selected = !sessionFilter.selected;
    semesterFilter.selected = semesterFilter.sessionFilters.find(x=>x.selected) != undefined;
    return semesterFilters.map(semester=>{
      if(semester.id===semesterFilter.id){
        return{
          ...semesterFilter,
          sessionFiters: semesterFilter.sessionFilters.map(session=>{
            if(session.id===sessionFilter.id){
              return {
                ...sessionFilter
              }
            }
            return session;
          })
        }
      }
      return semester;
    });
    
  }