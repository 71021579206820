import { InstitutionState } from "@institution/store"
import { Session } from "@shared/calendar/models"

export default (session:Session, state: InstitutionState): InstitutionState => {
    const academicYearIdx = state.academicYears.findIndex(x=>x.id===session.academicYearId);
      if(academicYearIdx===-1) return state;
      const semesterIdx = state.academicYears[academicYearIdx].semesters.findIndex(x=>x.id==session.semesterId);
      if(semesterIdx ===-1) return state;
      const sessionIdx = state.academicYears[academicYearIdx].semesters[semesterIdx].sessions.findIndex(x=>x.id===session.id);
      if(sessionIdx===-1) return state;
      const sessions = [...state.academicYears[academicYearIdx].semesters[semesterIdx].sessions.slice(0,sessionIdx),session,...state.academicYears[academicYearIdx].semesters[semesterIdx].sessions.slice(sessionIdx+1)];
      const semester = {...state.academicYears[academicYearIdx].semesters[semesterIdx], sessions};
      const semesters = [...state.academicYears[academicYearIdx].semesters.slice(0,semesterIdx),semester, ...state.academicYears[academicYearIdx].semesters.slice(semesterIdx+1)];
      const academicYear = {...state.academicYears[academicYearIdx], semesters};
      const academicYears = [...state.academicYears.slice(0,academicYearIdx),academicYear,...state.academicYears.slice(academicYearIdx+1)];
      let currentAcademicYear = state.currentAcademicYear;
      if(currentAcademicYear.id===session.academicYearId){
          currentAcademicYear = {...academicYear}
      }
      return {
        ...state,
        academicYears,
        currentAcademicYear
      }
  }