import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromMemberDashboard from '@member/dashboard/store';
@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature('member/dashboard', fromMemberDashboard.memberDashboardReducer),
    EffectsModule.forFeature([...fromMemberDashboard.EFFECTS])
  ],
  providers: [...fromMemberDashboard.RESOLVERS]
})
export class MemberDashboardStoreModule { }
