import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {IAppState} from '../../../../store/state/app.state';
import {select, Store} from '@ngrx/store';
import {ENewEnrollmentTypeFrom} from '@member/enrollment/constants/shared';

export interface NewEnrollmentDialogContainerData {
  typeFrom: ENewEnrollmentTypeFrom
}

@Component({
  selector: 'new-enrollment-dialog-container',
  templateUrl: './new-enrollment-dialog-container.component.html',
  styleUrls: ['./new-enrollment-dialog-container.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NewEnrollmentDialogContainerComponent {
  constructor(
    public matDialogRef: MatDialogRef<NewEnrollmentDialogContainerComponent>,
    @Inject(MAT_DIALOG_DATA) public matDialogData: NewEnrollmentDialogContainerData,
  ) {
    this.matDialogRef.addPanelClass('new-enrollment-dialog-container');
  }

  close(): void {
    this.matDialogRef.close();
  }
}
