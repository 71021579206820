import {Observable, of, timer} from 'rxjs';
import {Injectable} from '@angular/core';
import {AbstractControl, AsyncValidator, ValidationErrors} from '@angular/forms';
import {UserService} from '@shared/user/services';
import {catchError, map, switchMap, take} from 'rxjs/operators';
import { InstitutionService } from '@shared/institution/services/institution.service';

@Injectable({providedIn: 'root'})
export class AsyncValidatorsService {
  constructor(private userService: UserService, private institutionService: InstitutionService) {
  }

  validateExistEmail = (userId: string, initialValue?: string) => {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return timer(300).pipe(
        take(1),
        switchMap(() => {
          return this.userService.emailExists(userId, control.value)
        }),
        catchError(() => {
          return of(false);
        }),
        map((isExist) => {
          return (initialValue !== control.value) && isExist ? {emailExists: true} : null;
        })
      )
    }
  }

  validateExistInstitutionName = (institutionId: string, initialValue?: string) => {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return timer(300).pipe(
        take(1),
        switchMap(() => {
          return this.institutionService.institutionNameExists(institutionId, control.value)
        }),
        catchError(() => {
          return of(false);
        }),
        map((isExist) => {
          return (initialValue !== control.value) && isExist ? {institutionExists: true} : null;
        })
      )
    }
  }
}
