import { Component, ElementRef, Input, Renderer2, ViewEncapsulation, OnInit } from '@angular/core';
import { AccessRole } from 'app/authentication/models/accessRole';
import { UserSummary } from 'app/authentication/models/userSummary.model';

@Component({
    selector     : 'navbar',
    templateUrl  : './navbar.component.html',
    styleUrls    : ['./navbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class NavbarComponent implements OnInit
{
    @Input() accessRole: AccessRole;
    @Input() user: UserSummary;
    constructor(){}

    ngOnInit(): void{
    }


}
