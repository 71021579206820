<div class="wizard-step wizard-home-step-check-list">
  <p>Please see the checklist below to complete the required course exchange information.</p>
  <div class="checklist">
    <div *ngFor="let item of checklist?.items" class="item">
      <div (click)="item.items.length ? item.accordionFlag = !item.accordionFlag : navigate.emit(item.itemType)"
           class="item-header is_clickable">
        <div class="item-title">
          <div class="item-status-complete" [ngSwitch]="item.statusComplete">
            <mat-icon *ngSwitchCase="'completed'">
              check
            </mat-icon>
            <mat-icon *ngSwitchCase="'notCompleted'">
              error
            </mat-icon>
            <mat-icon *ngSwitchCase="'partCompleted'">
              warning
            </mat-icon>
          </div>

          {{item.itemDescription}}

          <ng-container *ngIf="item.subCheckListsProgress">
            ({{item.subCheckListsProgress.countCompleted}}/{{item.subCheckListsProgress.all}})
          </ng-container>
        </div>

        <ng-container *ngIf="item.items.length">
          <button mat-icon-button class="expand-button">
            <mat-icon *ngIf="item.accordionFlag">keyboard_arrow_up</mat-icon>
            <mat-icon *ngIf="!item.accordionFlag">keyboard_arrow_down</mat-icon>
          </button>
        </ng-container>

      </div>
      <ng-container *ngIf="item.items.length && !item.accordionFlag">
        <div class="item-body">
          <div class="sub-checklist">
            <div *ngFor="let subItem of item.items" class="sub-item" (click)="navigate.emit(subItem.itemType)">
              <div class="sub-item-icon">
                <ng-container *ngIf="subItem.isComplete">
                  <mat-icon>check</mat-icon>
                </ng-container>
              </div>
              <div class="sub-item-title">{{subItem.itemDescription}}</div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="form-actions">
    <button mat-flat-button (click)="goToFirstNotCompleted()">
      Start Where You Left Off
    </button>
  </div>
</div>
