import {Injectable} from '@angular/core';
import {Actions, ofType, createEffect} from '@ngrx/effects';
import { of } from 'rxjs';
import * as fromCourseExchangeDashboard from '@courseExchange/dashboard/store';
import * as fromCourseExchange from '@courseExchange/store';
import { concatMap, withLatestFrom, tap } from 'rxjs/operators';
import { IAppState } from 'app/store/state/app.state';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';

@Injectable()
export class CourseExchangeDashboardNavigateEffects {
  constructor(private actions$: Actions,private store:Store<IAppState>, private router: Router) 
  {
  }

  courseExchangeDashboard$ = createEffect(() => {
    return this.actions$.pipe(
            ofType(fromCourseExchangeDashboard.Navigate.Dashboard),
            concatMap((action) => of(action).pipe(
              withLatestFrom(this.store.pipe(select(fromCourseExchange.Selectors.CourseExchange)))
            )),
            /** An EMPTY observable only emits completion. Replace with your own observable stream */
            tap(([action,currentCourseExchange]) => {
              const courseExchangeId = !!action.courseExchangeId ? action.courseExchangeId : currentCourseExchange.id;
              const institutionId = !!action.managingInstitutionId ? action.managingInstitutionId : currentCourseExchange.managingInstitutionId;
              this.router.navigate([`/course-exchange/${courseExchangeId}/${institutionId}/dashboard`]);
            })
      );
},{dispatch:false});
}