import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {hasErrorForm} from 'app/helpers/has-error-form';
import {Store} from '@ngrx/store';
import {IAppState} from '../../../../store/state/app.state';
import {ECourseCalendarEntity, ECourseCalendarTypesActionsForm} from '../../constants';
import {IEntityActionsPayload} from '../../models/shared';
import {IControlsConfig} from '../../../../models/shared';
import {fillControlsConfig} from '../../../../helpers/fill-controls-config';
import {Semester, SessionDateRules} from '../../models';
import {EditSemesterCommand, AddSemesterCommand} from '../../commands';
import {validateStartEndDates} from '../../../../helpers/validate-start-end-dates';
import * as moment from 'moment';
import {v4 as uuidv4} from 'uuid';
import * as fromInstitution from '@institution/store';

type IFormFields = Pick<Semester, 'id' | 'code' | 'startDate' | 'endDate'>;

type ILocalControlsConfig = IControlsConfig<IFormFields>;

@Component({
  selector: 'app-semester-form',
  templateUrl: './semester-form.component.html',
  styleUrls: ['./semester-form.component.scss', '../styles/calendar-entity-creator-forms.scss']
})
export class SemesterFormComponent implements OnInit {

  @Input() payload: IEntityActionsPayload.SemesterPayload;
  @Output() onClose = new EventEmitter();
  @Output() onChangePayload = new EventEmitter<IEntityActionsPayload.Types>();
  academicYearStartDate: Date;
  academicYearEndDate: Date;
  form: UntypedFormGroup;
  isSending = false;
  showSessions: boolean;
  sessionDateRules: SessionDateRules;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store<IAppState>,
  ) {
  }

  ngOnInit(): void {
    this.createForm();
    this.academicYearStartDate = this.payload.academicYearStartDate;
    this.academicYearEndDate = this.payload.academicYearEndDate;
    this.showSessions = this.payload.showSessions;
  }

  private createForm(): void {
    this.form = this.formBuilder.group(this.shapeControlsConfig(this.payload.semesterToEdit), {
      validators: this.checkDates
    });
  }

  private checkDates = (group: UntypedFormGroup): void => {
    const {endDate, startDate} = group.controls;

    const checkResult = validateStartEndDates(startDate.value, endDate.value);

    if (this.form) {
      const academicYearStartDate = this.academicYearStartDate;
      const academicYearEndDate = this.academicYearEndDate;
      const errorsForEndDate = checkResult ? null : {lessStartDate: true};
      const endDateNotWithin = !!moment(endDate.value).isSameOrAfter(academicYearStartDate) &&
                               !!moment(endDate.value).isSameOrBefore(academicYearEndDate) ? null : {endDateNotWithin: true};
      const startDateNotWithin = !!moment(startDate.value).isSameOrAfter(academicYearStartDate) &&
                                 !!moment(startDate.value).isSameOrBefore(academicYearEndDate) ? null : {startDateNotWithin: true};
      
      if(this.form.get('endDate') != null){
        this.form.get('endDate').setErrors(errorsForEndDate);
        this.form.get('endDate').setErrors(endDateNotWithin);
      }
      if(this.form.get('startDate') != null){
        this.form.get('startDate').setErrors(startDateNotWithin);
      }
    }
  }

  private shapeControlsConfig(dataForFill?: Semester | null): ILocalControlsConfig {
    const initialControlsConfig: ILocalControlsConfig = {
      id: [''],
      code: ['', Validators.required],
      startDate: [null, Validators.required],
      endDate: [null , Validators.required]
    };

    if (dataForFill) {
      return fillControlsConfig<IFormFields>(initialControlsConfig, dataForFill);
    } else {
      return initialControlsConfig;
    }
  }

  hasError = (controlName: string, errorName: string) => hasErrorForm(controlName, errorName, this.form);

  onEditSemester = () => {
    if (this.form.valid) {
      this.isSending = true;
      const semesterId = this.payload.semesterToEdit.id;
      const {code, startDate, endDate} = this.form.value;
      const command: EditSemesterCommand = {
        id: semesterId,
        code,
        startDate,
        endDate
      };
      this.store.dispatch(fromInstitution.Actions.UpdateSemester({command}));
      this.isSending = false;
      this.onClose.emit();
    }
  }

  onAddSemester = (): void => {
    this.addSemester();
  }

  addSemester = (fromContinue = false): void => {
    if (this.form.valid) {
      this.isSending = true;

      const academicYearId = this.payload.academicYearId;

      let {code, startDate, endDate} = this.form.value;
      
      var id = uuidv4();
      const command: AddSemesterCommand = {
        academicYearId,
        id,
        code,
        startDate,
        endDate
      };

      this.store.dispatch(fromInstitution.Actions.AddSemester({command}));

      this.isSending = false;

        if (fromContinue) {
          const semesterStartDate = startDate;
          const semesterEndDate = endDate;
          const sessionDateRules = this.sessionDateRules;
          this.onChangePayload.emit({
            entity: ECourseCalendarEntity.session,
            typesActionsForm: ECourseCalendarTypesActionsForm.add,
            academicYearId,
            semesterId: id,
            semesterStartDate,
            semesterEndDate,
            sessionDateRules
          });
        }else{
          this.payload.typesActionsForm = ECourseCalendarTypesActionsForm.edit;
          this.onClose.emit();
        }
    }
  }

  onContinueAddSession = (): void => {
    this.addSemester(true);
  }
}
