<div class="page-layout carded fullwidth inner-scroll list-page member-page student-add-edit">
  

  <div class="center">
    <elb-page-header [pageTitle]="isEdit ? 'Edit Student' : 'Add New Student'" [pageSubTitle]="''"
      (goBackClicked)="cancel()">
      <button class="btn--icon btn--member-admin" mat-button (click)="save()" [disabled]="!canSave()">
        <mat-icon>save</mat-icon>Save Student
      </button>
    </elb-page-header>

    <div class="content-card">
      <div class="inner-content">

        <ng-container>
          <form class="form" [formGroup]="form" (ngSubmit)="save()">
            <div class="fields">
              <div class="field">
                <mat-form-field appearance="outline" class="mt-8" fxFill>
                  <mat-label>Student ID</mat-label>
                  <input matInput formControlName="studentNumber">
                  <mat-error *ngIf="hasError('studentNumber', 'required')">This field is required</mat-error>
                  <mat-error *ngIf="hasError('studentNumber', 'studentNumberExist')">Student number already exist</mat-error>
                </mat-form-field>
              </div>
              <div class="field">
                <mat-form-field appearance="outline" class="mt-8" fxFill>
                  <mat-label>Student Type</mat-label>
                  <mat-select formControlName="studentTypeId">
                    <ng-container *ngIf="(studentTypes$ | async)">
                      <mat-option></mat-option>
                      <mat-option *ngFor="let studentType of studentTypes$ | async" [value]="studentType.id">
                        {{studentType.description}}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                  <mat-error *ngIf="hasError('studentTypeId', 'required')">This field is required</mat-error>
                </mat-form-field>
              </div>
              <div class="field"></div>
            </div>
  
            <div class="fields">
              <div class="field">
                <mat-form-field appearance="outline" class="mt-8" fxFill>
                  <mat-label>Last Name</mat-label>
                  <input matInput formControlName="lastName">
                  <mat-error *ngIf="hasError('lastName', 'required')">This field is required</mat-error>
                </mat-form-field>
              </div>
              <div class="field">
                <mat-form-field appearance="outline" class="mt-8" fxFill>
                  <mat-label>First Name</mat-label>
                  <input matInput formControlName="firstName">
                  <mat-error *ngIf="hasError('firstName', 'required')">This field is required</mat-error>
                </mat-form-field>
              </div>
              <div class="field">
                <mat-form-field appearance="outline" class="mt-8" fxFill>
                  <mat-label>Middle Name</mat-label>
                  <input matInput formControlName="middleName">
                  <mat-error *ngIf="hasError('middleName', 'required')">This field is required</mat-error>
                </mat-form-field>
              </div>
            </div>
  
            <div class="fields">
              <div class="field">
                <mat-form-field appearance="outline" class="mt-8" fxFill>
                  <mat-label>Address 1</mat-label>
                  <input matInput formControlName="addressLine1">
                  <mat-error *ngIf="hasError('addressLine1', 'required')">This field is required</mat-error>
                </mat-form-field>
              </div>
            </div>
  
            <div class="fields">
              <div class="field">
                <mat-form-field appearance="outline" class="mt-8" fxFill>
                  <mat-label>Address 2</mat-label>
                  <input matInput formControlName="addressLine2">
                </mat-form-field>
              </div>
              <div class="field">
                <mat-form-field appearance="outline" class="mt-8" fxFill>
                  <mat-label>City</mat-label>
                  <input matInput formControlName="city">
                  <mat-error *ngIf="hasError('city', 'required')">This field is required</mat-error>
                </mat-form-field>
              </div>
              <div class="field">
                <mat-form-field appearance="outline" class="mt-8" fxFill>
                  <mat-label>State</mat-label>
                  <mat-select formControlName="regionId">
                    <ng-container *ngIf="(regions$ | async)">
                      <mat-option *ngFor="let region of regions$ | async" [value]="region.id">
                        {{region.shortDescription}}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                  <mat-error *ngIf="hasError('regionId', 'required')">This field is required</mat-error>
                </mat-form-field>
              </div>
              <div class="field">
                <mat-form-field appearance="outline" class="mt-8" fxFill>
                  <mat-label>Postal Code</mat-label>
                  <input matInput formControlName="postalCode">
                  <mat-error *ngIf="hasError('postalCode', 'required')">This field is required</mat-error>
                </mat-form-field>
              </div>
            </div>
  
            <div class="fields">
              <div class="field">
                <mat-form-field appearance="outline" class="mt-8" fxFill>
                  <mat-label>Email 1</mat-label>
                  <input matInput
                         uniqueEmail
                         [userId]="getUserId()"
                         [initialValue]="getInitialEmail()"
                         formControlName="emailAddress">
                  <mat-error *ngIf="hasError('emailAddress', 'required')">This field is required</mat-error>
                  <mat-error *ngIf="hasError('emailAddress', 'email')">Please, Enter a valid email address.</mat-error>
                  <mat-error *ngIf="hasError('emailAddress', 'emailExist')">This email already exists</mat-error>
                </mat-form-field>
              </div>
  
              <div class="field">
                <mat-form-field appearance="outline" class="mt-8" fxFill>
                  <mat-label>Phone</mat-label>
                  <input [textMask]="{mask: phoneMask}" matInput formControlName="phoneNumber">
                  <mat-error *ngIf="hasError('phoneNumber', 'required')">This field is required</mat-error>
                </mat-form-field>
              </div>
            </div>
  
            <div class="fields">
              <div class="field">
                <mat-form-field appearance="outline" class="mt-8" fxFill>
                  <mat-label>Email 2</mat-label>
                  <input matInput
                         uniqueEmail
                         [userId]="getUserId()"
                         [initialValue]="getInitialEmail2()"
                         formControlName="emailAddress2">
                  <mat-error *ngIf="hasError('emailAddress2', 'emailExist')">This email already exists</mat-error>
                </mat-form-field>
              </div>
  
              <div class="field">
                <mat-form-field appearance="outline" class="mt-8" fxFlex>
                  <mat-label>DOB</mat-label>
                  <input matInput [matDatepicker]="dateOfBirthPicker" formControlName="dateOfBirth">
                  <mat-datepicker-toggle matSuffix [for]="dateOfBirthPicker"></mat-datepicker-toggle>
                  <mat-datepicker #dateOfBirthPicker></mat-datepicker>
  
                  <mat-error *ngIf="hasError('dateOfBirth', 'required')">This field is required</mat-error>
                </mat-form-field>
              </div>
  
              <div class="field">
                <mat-form-field appearance="outline" class="mt-8" fxFill>
                  <mat-label>Gender</mat-label>
                  <mat-select formControlName="genderId">
                    <ng-container *ngIf="(genders$ | async)">
                      <mat-option *ngFor="let gender of genders$ | async" [value]="gender.id">
                        {{gender.description}}
                      </mat-option>
                    </ng-container>
                  </mat-select>
                  <mat-error *ngIf="hasError('genderId', 'required')">This field is required</mat-error>
                </mat-form-field>
              </div>
            </div>
  
  
            <div class="form-actions">
              <button mat-raised-button class="btn--member-admin" type="submit" [disabled]="!canSave()">
                Save Student
              </button>
  
              <button mat-raised-button color="warn" (click)="cancel()" type="button">
                Cancel
              </button>
            </div>
          </form>
        </ng-container>
        <ng-template #loading>
          ...loading
        </ng-template>

      </div>
    </div>
  </div>
</div>
