<mat-toolbar>
  <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <span class="title dialog-title">Faculty CV's</span>
    <button mat-icon-button (click)="close()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div class="content" *ngIf="(currentMemberCourse$ | async) as memberCourse">
  <div class="files">
    <div class="file-col file-col-course">
      <div class="file-title">Course Faculty CV's</div>
      <div class="file-list" *ngIf="memberCourse.facultyCVs.length; else noCourseFiles">
        <div class="file-item" *ngFor="let file of memberCourse.facultyCVs">
          <div class="file-name">{{file.file.fileName}}</div>
          <div class="file-actions">
            <button mat-raised-button (click)="downloadFile(file)">
              <mat-icon>cloud_download</mat-icon>
            </button>
          </div>
        </div>
      </div>

      <ng-template #noCourseFiles>
        None.
      </ng-template>
    </div>
  </div>

  <ng-template #loading>
    ...loading
  </ng-template>
</div>
