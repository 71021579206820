import { ExcelService } from 'app/core/services/excel.service';
import {createEffect, Actions, ofType} from "@ngrx/effects";
import {Injectable} from "@angular/core";
import {map, switchMap, withLatestFrom, concatMap, tap, catchError} from "rxjs/operators";
import {select, Store} from '@ngrx/store';
import {of} from 'rxjs';
import {IAppState} from 'app/store/state/app.state';
import {PopupService} from 'app/core/services/popup.service';
import {MatDialog} from '@angular/material/dialog';
import * as fromCore from '@core/store';
import * as fromProviderBilling from '@provider/billing/store';
import * as fromProvider from '@provider/store';
import { ProviderService } from '@shared/provider/services';
import * as moment from 'moment';

@Injectable()
export class ProviderBillingEffects {
  constructor(private actions$: Actions, private store: Store<IAppState>, private providerService: ProviderService,
              private dialog: MatDialog, private popupService: PopupService, private excelService: ExcelService) {
  }

  loadMonthlyStatements$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromProviderBilling.Actions.LoadMonthlyStatements),
      concatMap((action)=> of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromProvider.Selectors.Provider)))
      )),
      switchMap(([action,provider]) =>{
        const providerId = !!action.providerId ? action.providerId : provider.id;
        return this.providerService.getMonthlyStatements(action.providerId)}
      ),
      map((monthlyStatements) => fromProviderBilling.Actions.LoadMonthlyStatementsSuccess({monthlyStatements})),
      catchError(() =>
        of(fromProviderBilling.Actions.LoadMonthlyStatementsError(
          {message: 'Problem retrieving Provider Fee Monthly Summaries'}
        )))
    )
  });

  loadProviderFeeMonthlySummariesError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromProviderBilling.Actions.LoadMonthlyStatementsError),
      /** An EMPTY observable only emits completion. Replace with your own observable stream */
      map(({message}) => fromCore.Actions.ShowMessage({message})));
  });

  downloadMonthlyStatement$ = createEffect(() => {
    return this.actions$.pipe(
            ofType(fromProviderBilling.Actions.DownloadMonthlyStatement),
            tap(({monthlyStatement})=> this.providerService.downloadMonthlyStatement(monthlyStatement))
            );
    },{dispatch : false});

  
    exportProviderDetailedReport$ = createEffect(() => {
      let year = 0;
      let month = 0;
      return this.actions$.pipe(
          ofType(fromProviderBilling.Actions.ExportProviderDetailedReport),
          concatMap((action)=> of(action).pipe(
            withLatestFrom(this.store.pipe(select(fromProvider.Selectors.Provider)))
          )),
          /** An EMPTY observable only emits completion. Replace with your own observable stream */
          switchMap(([action, provider]) => {
            year = action.year;
            month = action.month;
            return this.excelService.exportProviderDetailedReport(provider.id,year,month);
          }),
          tap((data) => {
            const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const linkDomObject: HTMLAnchorElement = document.createElement('a');
            linkDomObject.href = URL.createObjectURL(blob);
            linkDomObject.download = `ProviderDetailedReport-${year}-${month}.xlsx`;
            document.body.appendChild(linkDomObject);
            linkDomObject.click();
            linkDomObject.remove();
          })
      )
    },{dispatch:false});
}
