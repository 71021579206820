import {Component, Inject, OnInit, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {EnrollmentStatus} from '@refData';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {EnrollmentListItemForView} from '@member/enrollment';
import {IAppState} from '../../../../store/state/app.state';
import * as fromEnrollment from '@member/enrollment/store';

export interface UpdateEnrollmentStatusPayload {
  selectedStatus: EnrollmentStatus;
  enrollmentIds: string[];
}

@Component({
  selector: 'update-enrollment-status-dialog',
  templateUrl: './update-enrollment-status-dialog.component.html',
  styleUrls: ['./update-enrollment-status-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UpdateEnrollmentStatusDialogComponent implements OnInit {
  enrollments$: Observable<EnrollmentListItemForView[]>;
  commonAllowedStatuses$: Observable<EnrollmentStatus[]>;

  constructor(
    public matDialogRef: MatDialogRef<UpdateEnrollmentStatusDialogComponent>,
    private store: Store<IAppState>
  ) {
    this.matDialogRef.addPanelClass('update-enrollment-status-dialog');
  }

  ngOnInit(): void {
    this.enrollments$ = this.store.pipe(select(fromEnrollment.Selectors.SelectedEnrollments));
    this.commonAllowedStatuses$ = this.store.pipe(select(fromEnrollment.Selectors.CommonAllowedStatuses));
  }

  close(): void {
    this.matDialogRef.close();
  }

  updateEnrollmentStatus(payload: UpdateEnrollmentStatusPayload): void {
    payload.enrollmentIds.forEach(enrollmentId =>{
      this.store.dispatch(fromEnrollment.Actions.EditStatus({enrollmentId, requestedStatus: payload.selectedStatus}));
    })
    this.close();
  }
}
