import { User, UserListItem } from "@shared/user/models";
import * as fromUsers from '@shared/user/store';
import { createReducer, on } from "@ngrx/store";

export interface UsersState {
    institutionId: string;
    users: UserListItem[];
    isUsersLoading: boolean;
    currentUser: User;
};

export const initialUsersState: UsersState = {
    institutionId: '',
    users: new Array<UserListItem>(),
    isUsersLoading: false,
    currentUser: undefined
};

export const usersReducer = createReducer(
  initialUsersState,
  on(fromUsers.Actions.LoadUsers, (state,{institutionId})=>{
      return {
          ...state,
          institutionId,
          isUsersLoading: true
      }
  }),
  on(fromUsers.Actions.LoadUsersSuccess, (state, {users})=>{
      return {
          ...state,
          users,
          isUsersLoading: false
      }
  }),
  on(fromUsers.Actions.LoadUserSuccess, (state, {currentUser})=>{
      return{
          ...state,
          currentUser
      }
  }),
  on(fromUsers.Actions.UserUpdated, (state, {user})=>{
      const idx = state.users.findIndex(x=>x.id==user.id);
      if(idx -1) return state;
      const users = [...state.users.slice(0,idx),user, ...state.users.slice(idx+1)];
      return{
          ...state,
          users
      }
  }),
  on(fromUsers.Actions.UserAdded, (state, {user})=>{
      const idx = state.users.findIndex(x=>x.id==user.id);
      let users = new Array<UserListItem>();
      if(idx===-1){
        users = [...state.users, user];
      }else{
        users = [...state.users.slice(0,idx),user, ...state.users.slice(idx+1)];
      }
      return{
          ...state,
          users
      }
  }),
  on(fromUsers.Actions.UserDeleted, (state, {user})=>{
      const idx = state.users.findIndex(x=>x.id==user.id);
      if(idx===-1) return state;
      const users = [...state.users.filter(x=>x.id!=user.id)];
      return{
          ...state,
          users
      }
  }),
  on(fromUsers.Actions.AccessRoleAdded, (state, {accessRole})=>{
    const idx = state.users.findIndex(x=>x.id==accessRole.userId);
    if(idx===-1) return state;
    const accessRoleIdx = state.users[idx].accessRoles.findIndex(x=>x.id===accessRole.id);
    if(accessRoleIdx != -1) return;
    const accessRoles = [...state.users[idx].accessRoles, accessRole];
    const user = {...state.users[idx], accessRoles};
    const users = [...state.users.slice(0,idx), user , ...state.users.slice(idx+1)];
    return{
        ...state,
        users
    }
  }),
  on(fromUsers.Actions.AccessRoleUpdated, (state, {accessRole})=>{
    const userIdx = state.users.findIndex(x=>x.id==accessRole.userId);
    if(userIdx===-1) return state;
    const accessRoleIdx = state.users[userIdx].accessRoles.findIndex(x=>x.id==accessRole.id);
    if(accessRoleIdx===-1) return state;
    const accessRoles = [...state.users[userIdx].accessRoles.slice(0,accessRoleIdx),accessRole, ...state.users[userIdx].accessRoles.slice(accessRoleIdx+1)];
    const user = {...state.users[userIdx], accessRoles};
    const users = [...state.users.slice(0,userIdx), user , ...state.users.slice(userIdx+1)];
    return{
        ...state,
        users
    }
  }),
  on(fromUsers.Actions.AccessRoleDeleted, (state, {accessRole})=>{
    const userIdx = state.users.findIndex(x=>x.id==accessRole.userId);
    if(userIdx===-1) return state;
    const accessRoles = [...state.users[userIdx].accessRoles.filter(x=>x.id!=accessRole.id)];
    const user = {...state.users[userIdx], accessRoles};
    const users = [...state.users.slice(0,userIdx), user , ...state.users.slice(userIdx+1)];
    return{
        ...state,
        users
    }
  })
)