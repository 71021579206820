import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'elb-page-list-content',
  templateUrl: './page-list-content.component.html',
  styleUrls: ['./page-list-content.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PageListContentComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }
}
