import { FacultyCV } from "@shared/institution/models";
import { FacultyCVState } from "../facultyCV.reducer";

export default (facultyCV:FacultyCV, state: FacultyCVState): FacultyCVState => {
    const idx = state.facultyCVs.findIndex(x=>x.id==facultyCV.id);
    let facultyCVs = new Array<FacultyCV>();
    if(idx===-1){
        facultyCVs = [...state.facultyCVs, facultyCV];
    }else{
        facultyCVs = [...state.facultyCVs.slice(0,idx),facultyCV, ...state.facultyCVs.slice(idx+1)];
    }
    return{
        ...state,
        facultyCVs
    }
}