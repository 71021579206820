<div id="userInstitutionsPermissionsDialog" class="c_dialog-content">
        <div id="userInstitutionInfo" fxLayout="row" fxLayoutAlign="space-between" class="c_dialog-header">
                <mat-form-field appearance="outline" floatLabel="always" *ngIf="showInstitutionRole">
                        <mat-label>Institution Role</mat-label>
                        <mat-select [value]="institutionRole" (selectionChange)="changeInstitutionRole($event.value)">
                                <mat-select-trigger>
                                        {{institutionRole.description}}
                                </mat-select-trigger>
                                <mat-option *ngFor="let role of institutionRoles" [value]="role">
                                        {{role.description}}
                                </mat-option>
                        </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline" floatLabel="always" *ngIf="showCourseExchangeRole">
                        <mat-label>Course Exchange Role</mat-label>
                        <mat-select [value]="courseExchangeRole" (selectionChange)="changeCourseExchangeRole($event.value)">
                                <mat-select-trigger>
                                        {{courseExchangeRole.description}}
                                </mat-select-trigger>
                                <mat-option *ngFor="let role of courseExchangeRoles" [value]="role">
                                        {{role.description}}
                                </mat-option>
                        </mat-select>
                </mat-form-field>
        </div>
        <div class="c_dialog-body">
                <div>
                        <mat-checkbox (change)="$event ? masterToggle(): null"
                                [checked]="selection.hasValue() && isAllSelected()"
                                [indeterminate]="selection.hasValue() && !isAllSelected()">
                                Select All Permissions ({{selectedStatus}})
                        </mat-checkbox>
                </div>
                <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
                        <ng-container matColumnDef="isSelected">
                                <mat-cell *matCellDef="let permission">
                                        <mat-checkbox (click)="toggle(permission)"
                                                (change)="$event ? selection.toggle(permission) : null"
                                                [checked]="selection.isSelected(permission)">
                                        </mat-checkbox>
                                </mat-cell>
                        </ng-container>
                        <!-- Description Column -->
                        <ng-container matColumnDef="permission">
                                <td mat-cell *matCellDef="let permission"> {{permission.permissionDescription}}
                                </td>
                        </ng-container>

                        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
                <div class="c_custom-paginator-wrap">
                        <mat-paginator [pageSizeOptions]="[5, 10]" [pageSize]="10"></mat-paginator>
                </div>

        </div>

        <div class="c_dialog-btn-row" fxLayout="row" fxLayoutAlign="space-between">
                <button mat-raised-button (click)="dialogRef.close(null)" class="c_default-btn gray">Close</button>
                <button mat-raised-button (click)="saveAccessRole()" class="c_default-btn ">Save</button>
        </div>


</div>