import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'app-course-schedule-dialog-start',
  templateUrl: './course-schedule-dialog-start.component.html',
  styleUrls: ['./course-schedule-dialog-start.component.scss']
})
export class CourseScheduleDialogStartComponent {
  @Output() onChangeCreateAction = new EventEmitter<boolean>();

  constructor() {
  }
}
