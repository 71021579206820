<div class="toolbar-secondary-user-info">
  <div class="logo-wrap">
    <div class="logo">
      <img [src]="logoUrl"/>
    </div>
    <div class="avatar-wrap">
      <elb-avatar [name]="(currentUser$ | async)?.fullName"></elb-avatar>
    </div>
  </div>

  <div class="username">{{(currentUser$ | async)?.fullName}}</div>
  <div class="userGuide"><a href="https://www.notion.so/Taawun-User-Guide-0d22d7626b6c4d7e8bccee32d92af5cf" target="_blank">Taawun User Guide</a></div>

  <!-- <button mat-button [routerLink]="['/auth/profile']" routerLinkActive="router-link-active" class="action">
    <span>{{actionName}}</span>
  </button> -->
</div>
