

import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromCourseExchange from '@courseExchange/store';
import { CourseExchangeUsersState } from '@courseExchange/users/store';
export const selectCourseExchangeUsersState = createFeatureSelector<CourseExchangeUsersState>('courseExchange/users');

export const Users = createSelector(
    selectCourseExchangeUsersState,
    (state) => state.users
);
export const Institutions = createSelector(
    selectCourseExchangeUsersState,
    (state) => state.institutions
)

export const IsLoadingUsers = createSelector(
    selectCourseExchangeUsersState,
    (state) => state.isLoadingUsers
)

export const CurrentUser = createSelector(
    selectCourseExchangeUsersState,
    (state)  => state.currentUser
);

export const IsLoadingUser = createSelector(
    selectCourseExchangeUsersState,
    (state) => state.isLoadingUser
)

export const CurrentInstitutionId = createSelector(
    selectCourseExchangeUsersState,
    (state) => state.currentInstitutionId
)
