<div class="course-schedule-dialog-start">
  <!-- <div class="description">
    Would you like to
    <span class="tooltip">
      <span class="tooltip-link">copy an existing course schedule</span>

      <span class="tooltip-content">
        Copying an existing course schedule
        will duplicate the selected semester's
        course schedule. You will be able to
        edit from there.
      </span>
    </span>
    or
    <span class="tooltip">
      <span class="tooltip-link">create a new course schedule</span>

      <span class="tooltip-content">
        Creating a new course schedule
        will create a blank course calendar for the chosen semester's sessions.
        You will then be able to select your courses per session.
      </span>
    </span>
  </div> -->

  <div class="actions">
    <button mat-raised-button (click)="onChangeCreateAction.emit(false)">
      <mat-icon>arrow_forward</mat-icon>
      Copy Existing Course Schedule
    </button>

    <button mat-raised-button (click)="onChangeCreateAction.emit(true)">
      <mat-icon>arrow_forward</mat-icon>
      Create New or Edit Course Schedule
    </button>
  </div>
</div>
