import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router, RouterStateSnapshot } from '@angular/router';
import { tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { IAppState } from 'app/store/state/app.state';
import * as fromAuth from '@auth/store';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private router: Router,private store: Store<IAppState>) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        let currentUser = JSON.parse(localStorage.getItem('user'));
        if (currentUser && currentUser.token) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.token}`
                }
            });
        }

        return next.handle(request).pipe(tap(() => { },
            (err: any) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status !== 401) {
                        return;
                    }
                    var returnUrl = this.router.routerState.snapshot.url;
                    if(returnUrl.trim() != ""){
                        this.store.dispatch(fromAuth.Actions.navigateToLogin({returnUrl}));
                    }else{
                        this.store.dispatch(fromAuth.Actions.navigateToLogin({}));
                    };
                }
            }));
    }
}