import {NewEnrollmentDialogContainerComponent} from '@member/enrollment/components/new-enrollment-dialog-container/new-enrollment-dialog-container.component';
import {NewEnrollmentFromStudentsComponent} from '@member/enrollment/components/new-enrollment-from-students/new-enrollment-from-students.component';
import {NewEnrollmentListOfSelectedComponent} from '@member/enrollment/components/new-enrollment-list-of-selected/new-enrollment-list-of-selected.component';
import {NewEnrollmentFromCoursesComponent} from '@member/enrollment/components/new-enrollment-from-courses/new-enrollment-from-courses.component';
import {EnrollmentDialogDropCourseComponent} from '@member/enrollment/components/enrollment-dialog-drop-course/enrollment-dialog-drop-course.component';
import {EnrollmentShortStudentInfoComponent} from '@member/enrollment/components/enrollment-short-student-info/enrollment-short-student-info.component';
import {EnrollmentShortCourseInfoComponent} from '@member/enrollment/components/enrollment-short-course-info/enrollment-short-course-info.component';
import {EnrollmentShortDetailsInfoComponent} from '@member/enrollment/components/enrollment-short-details-info/enrollment-short-details-info.component';
import {EnrollmentsRequestedDialogComponent} from '@member/enrollment/components/enrollments-requested-dialog/enrollments-requested-dialog.component';
import {EnrollmentStudentsInfoComponent} from '@member/enrollment/components/enrollment-students-info/enrollment-students-info.component';
import {EnrollmentListComponent} from '@member/enrollment/components/enrollment-list/enrollment-list.component';
import {EnrollmentListItemDetailDialogComponent} from '@member/enrollment/components/enrollment-list-item-detail-dialog/enrollment-list-item-detail-dialog.component';
import {UpdateEnrollmentStatusDialogComponent} from '@member/enrollment/components/update-enrollment-status-dialog/update-enrollment-status-dialog.component';
import { PastStartDateDialogComponent } from './past-start-date-confirmation-dialog/past-start-date-dialog.component';
export * from '@member/enrollment/components/new-enrollment-dialog-container/new-enrollment-dialog-container.component';
export * from '@member/enrollment/components/new-enrollment-from-students/new-enrollment-from-students.component';
export * from '@member/enrollment/components/new-enrollment-list-of-selected/new-enrollment-list-of-selected.component';
export * from '@member/enrollment/components/new-enrollment-from-courses/new-enrollment-from-courses.component';
export * from '@member/enrollment/components/enrollment-dialog-drop-course/enrollment-dialog-drop-course.component';
export * from '@member/enrollment/components/enrollment-short-student-info/enrollment-short-student-info.component';
export * from '@member/enrollment/components/enrollment-short-course-info/enrollment-short-course-info.component';
export * from '@member/enrollment/components/enrollment-short-details-info/enrollment-short-details-info.component';
export * from '@member/enrollment/components/enrollments-requested-dialog/enrollments-requested-dialog.component';
export * from '@member/enrollment/components/enrollment-students-info/enrollment-students-info.component';
export * from '@member/enrollment/components/enrollment-list/enrollment-list.component';
export * from '@member/enrollment/components/enrollment-list-item-detail-dialog/enrollment-list-item-detail-dialog.component';
export * from '@member/enrollment/components/update-enrollment-status-dialog/update-enrollment-status-dialog.component';
export * from './past-start-date-confirmation-dialog/past-start-date-dialog.component';

export const COMPONENTS = [
  NewEnrollmentDialogContainerComponent,
  NewEnrollmentFromStudentsComponent,
  NewEnrollmentListOfSelectedComponent,
  NewEnrollmentFromCoursesComponent,
  EnrollmentDialogDropCourseComponent,
  EnrollmentShortStudentInfoComponent,
  EnrollmentShortCourseInfoComponent,
  EnrollmentShortDetailsInfoComponent,
  EnrollmentsRequestedDialogComponent,
  EnrollmentStudentsInfoComponent,
  EnrollmentListComponent,
  EnrollmentListItemDetailDialogComponent,
  UpdateEnrollmentStatusDialogComponent,
  PastStartDateDialogComponent
];

export const ENTRY_COMPONENTS = [
  NewEnrollmentDialogContainerComponent,
  EnrollmentDialogDropCourseComponent,
  EnrollmentsRequestedDialogComponent,
  EnrollmentListItemDetailDialogComponent,
  UpdateEnrollmentStatusDialogComponent,
  PastStartDateDialogComponent
];
