import {
    createReducer,
    on
  } from '@ngrx/store';
import { Sort, Order, ElbertFile, FileType } from 'app/core/models';
import { Filter } from '@shared/common/models';
import DefaultStatusList from '@shared/common/helpers/DefaultStatusList';
import toggleItem from '@shared/common/helpers/toggleItem';
import { FacultyCV } from '@shared/institution/models';
import * as fromFacultyCV from '@provider/facultyCV/store';
import * as fromAuth from '@auth/store';
import * as moment from 'moment';
import { FileStatus } from '@core/models/fileStatus.enum';
import addFacultyCV from './helpers/addFacultyCV';
import updateFacultyCV from './helpers/updateFacultyCV';
 
  export interface FacultyCVState {
      institutionId: string;
      facultyCVs: FacultyCV[];
      currentFacultyCV: FacultyCV;
      sorts: Sort[],
      currentSort: Sort,
      orders: Order[],
      currentOrder: Order,
      statusFilters: Filter[],
      searchText: string;
  };
  
  export const initialFacultyCVState: FacultyCVState = {
      institutionId: '',
      facultyCVs: new Array<FacultyCV>(),
      currentFacultyCV: undefined,
      sorts: new Array<Sort>(),
      currentSort: new Sort(),
      orders: new Array<Order>(),
      currentOrder: new Order(),
      statusFilters: DefaultStatusList(),
      searchText: ''
  };
  
  export const facultyCVReducer = createReducer(
    initialFacultyCVState,
    on(fromAuth.Actions.selectCourseExchangeId, (state,action)=>{
        return initialFacultyCVState
    }),
    on(fromFacultyCV.Actions.LoadFacultyCVs, (state, action) =>{
        const institutionId = action.institutionId;
        return {
            ...state,
            institutionId
        };
    }),
    on(fromFacultyCV.Actions.LoadFacultyCVsSuccess, (state, {facultyCVs}) =>{
    return {
        ...state,
        facultyCVs
      };
    }),
    on(fromFacultyCV.Actions.AddFacultyCV, (oldState, {command})=>{
        const {id, institutionId, description} = command;
        const lastUpdatedDt = moment().toDate();
        const file: ElbertFile = {fileType: FileType.FacultyCV, status: FileStatus.Empty, fileName:description};
        const facultyCV: FacultyCV = {id, institutionId, description, lastUpdatedDt, isActive: true, file};
        const state = addFacultyCV(facultyCV, oldState);
        return {
            ...state
        }
    }),
    on(fromFacultyCV.Actions.ActivateFacultyCV, (oldState, {command})=>{
        const idx = oldState.facultyCVs.findIndex(x=>x.id==command.id);
        if(idx===-1)return oldState;
        const facultyCV = {...oldState.facultyCVs[idx], isActive: true}
        const state = updateFacultyCV(facultyCV, oldState);
        return {
            ...state
        }
    }),
    on(fromFacultyCV.Actions.DeactivateFacultyCV, (oldState, {command})=>{
        const idx = oldState.facultyCVs.findIndex(x=>x.id==command.id);
        if(idx===-1)return oldState;
        const facultyCV = {...oldState.facultyCVs[idx], isActive: false}
        const state = updateFacultyCV(facultyCV, oldState);
        return {
            ...state
        }
    }),
    on(fromFacultyCV.Actions.UpdateFacultyCV, (oldState, {command})=>{
        const oldFacultyCV = oldState.facultyCVs.find(x=>x.id===command.id);
        const facultyCV:FacultyCV = {...oldFacultyCV, description: command.description};
        const state = updateFacultyCV(facultyCV, oldState);
        return{
            ...state
        }
    }),
    on(fromFacultyCV.Actions.LoadSorts, (state, action) =>{
        const sorts = action.sorts;
        const currentSort = sorts.find(x=>x.isDefault);
        return {
            ...state,
            sorts,
            currentSort
        }
    }),
    on(fromFacultyCV.Actions.ChangeSort, (state, action)=>{
        const currentSort = action.currentSort;
        return {
            ...state,
            currentSort
        }
    }),
    on(fromFacultyCV.Actions.LoadOrders, (state, action) =>{
        const orders = action.orders;
        const currentOrder = orders.find(x=>x.isDefault);
        return {
            ...state,
            orders,
            currentOrder
        }
    }),
    on(fromFacultyCV.Actions.ChangeOrder, (state, action)=>{
        const currentOrder = action.currentOrder;
        return {
            ...state,
            currentOrder
        }
    }),
    on(fromFacultyCV.Actions.RemoveFilter, (state, action) => {
        const idx = state.statusFilters.findIndex(x=>x.id==action.filter.id);
        const item = state.statusFilters[idx];
        const statusFilter = {...item, selected: false};
        const statusFilters = [...state.statusFilters.slice(0,idx),statusFilter,...state.statusFilters.slice(idx+1)]
        return {
            ...state,
            statusFilters
        }
    }),
    on(fromFacultyCV.Actions.AddFilter, (state, action) => {
        const idx = state.statusFilters.findIndex(x=>x.id==action.filter.id);
        const item = state.statusFilters[idx];
        const statusFilter = {...item, selected: true};
        const statusFilters = [...state.statusFilters.slice(0,idx),statusFilter,...state.statusFilters.slice(idx+1)]
        return {
            ...state,
            statusFilters
        }
    }),
    on(fromFacultyCV.Actions.ToggleStatusFilter, (state, action)=>{
        const statusFilters = toggleItem(state.statusFilters,action.id);

        return {
            ...state,
            statusFilters
        }
    }),
    on(fromFacultyCV.Actions.ChangeSearchText, (state, action) =>{
        const searchText = action.searchText;
        return {
            ...state,
            searchText
        };
    }),
    on(fromFacultyCV.Actions.FacultyCVAdded, (oldState, {facultyCV})=>{
        const state = addFacultyCV(facultyCV, oldState);
        return{
            ...state
        }
    }),
    on(fromFacultyCV.Actions.FacultyCVUpdated, (oldState, {facultyCV})=>{
        const state = updateFacultyCV(facultyCV, oldState);
        return {
            ...state
        }
    })
);
  
  
  