import { createReducer, on } from '@ngrx/store';
import { ProviderCourseListItemForView, ProviderCourseListItem } from '@provider/courses/models/providerCourseListItem.model';
import { Filter, SingleFilter, FilterType } from '@shared/common/models';
import { Sort, Order } from '@core/models';
import { ProviderCourseStatus } from '@provider/courses/models/providerCourseStatus.enum';
import { Course, FacultyCV } from '@shared/institution/models';
import { PresetFilterType } from '@courseExchange/courses/store';
import toggleItem from '@shared/common/helpers/toggleItem';
import * as fromAuth from '@auth/store';
import * as fromCourseExchangeCourses from '@courseExchange/courses/store';
import * as fromStatic from '@courseExchange/courses/store/Static';

export interface ICoursesState {
  courseExchangeId: string;
  courses: ProviderCourseListItemForView[];
  isCoursesLoading: boolean;
  currentCourse: Course;
  currentFacultyCVs: FacultyCV[];
  currentProviderCourse: ProviderCourseListItem;
  isCourseLoading: boolean;
  levelFilters: Filter[];
  courseCategoryFilters: Filter[];
  courseSubCategoryFilters: Filter[];
  tagFilters: Filter[];
  providerFilters: Filter[];
  statusFilters: Filter[];
  sorts: Sort[];
  currentSort: Sort;
  orders: Order[];
  currentOrder: Order;
  searchText: string;
  presetFilterType: SingleFilter

}

export const initialCoursesState: ICoursesState = {
  courseExchangeId: '',
  courses: [],
  isCoursesLoading: false,
  currentCourse: undefined,
  currentFacultyCVs: new Array<FacultyCV>(),
  currentProviderCourse: undefined,
  isCourseLoading: false,
  levelFilters: [],
  courseCategoryFilters: [],
  courseSubCategoryFilters: [],
  tagFilters: [],
  providerFilters: [],
  statusFilters: [],
  sorts: [],
  currentSort: fromStatic.Sorts.find(x=>x.isDefault) ,
  orders: [],
  currentOrder: fromStatic.Orders.find(x=>x.isDefault),
  searchText: '',
  presetFilterType: { id: PresetFilterType.AllCourses, description: 'All Courses' }
};

export const courseExchangeCoursesReducer = createReducer(
  initialCoursesState,
  on(fromAuth.Actions.selectCourseExchangeId, (state,action)=>{
    return initialCoursesState
  }),
  on(fromCourseExchangeCourses.Actions.LoadCourseLevelFilters, (state, { levelFilters }) => {
    return {
      ...state,
      levelFilters
    }
  }),
  on(fromCourseExchangeCourses.Actions.LoadCourseCategoryFilters, (state, { courseCategoryFilters }) => {
    return {
      ...state,
      courseCategoryFilters
    }
  }),
  on(fromCourseExchangeCourses.Actions.LoadCourseSubCategoryFilters, (state, { courseSubCategoryFilters }) => {
    return {
      ...state,
      courseSubCategoryFilters
    }
  }),
  on(fromCourseExchangeCourses.Actions.LoadTagFilters, (state, { tagFilters }) => {
    return {
      ...state,
      tagFilters
    }
  }),
  on(fromCourseExchangeCourses.Actions.LoadProviderFilters, (state, { providerFilters }) => {
    return {
      ...state,
      providerFilters
    }
  }),
  on(fromCourseExchangeCourses.Actions.LoadStatusFilters, (state, { statusFilters }) => {
    return {
      ...state,
      statusFilters
    }
  }),
  on(fromCourseExchangeCourses.Actions.LoadCourses, (state, { courseExchangeId }) => {
    return {
      ...state,
      courseExchangeId,
      isCoursesLoading: true
    }
  }),
  on(fromCourseExchangeCourses.Actions.LoadCoursesSuccess, (state, { courses }) => {
    return {
      ...state,
      courses,
      isCoursesLoading: false
    };
  }),
  on(fromCourseExchangeCourses.Actions.LoadCoursesError, (state) => {
    return {
      ...state,
      courseExchangeId: '',
      isCoursesLoading: false
    }
  }),
  on(fromCourseExchangeCourses.Navigate.CourseDetail, (state, {providerCourse})=>{
    return {
      ...state,
      currentProviderCourse: providerCourse
    }
  }),
  on(fromCourseExchangeCourses.Navigate.CourseList, (state)=>{
    return {
      ...state,
      currentProviderCourse: undefined,
      currentCourse: undefined,
      searchText: ''
    }
  }),
  on(fromCourseExchangeCourses.Actions.LoadCourseSuccess, (state, { currentCourse }) => {
    return {
      ...state,
      currentCourse,
      isCourseLoading: false
    };
  }),
  on(fromCourseExchangeCourses.Actions.LoadCoursesError, (state) => {
    return {
      ...state,
      courseExchangeId: '',
      isCourseLoading: false
    }
  }),
  on(fromCourseExchangeCourses.Actions.LoadFacultyListSuccess, (state, { currentFacultyCVs }) => {
    return {
      ...state,
      currentFacultyCVs
    };
  }),
  on(fromCourseExchangeCourses.Actions.LoadSorts, (state, { sorts }) => {
    return {
      ...state,
      sorts
    }
  }),
  on(fromCourseExchangeCourses.Actions.ChangeSort, (state, {currentSort})=>{
    return {
      ...state,
      currentSort
    }
  }),
  on(fromCourseExchangeCourses.Actions.LoadOrders, (state, { orders }) => {
    return {
      ...state,
      orders
    }
  }),
  on(fromCourseExchangeCourses.Actions.ChangeOrder, (state, {currentOrder})=>{
    return {
      ...state,
      currentOrder
    }
  }),
  on(fromCourseExchangeCourses.Actions.ChangeSearchText, (state, {searchText})=>{
    return {
      ...state,
      searchText
    }
  }),
  on(fromCourseExchangeCourses.Actions.ChangePresetFilterType, (state, action)=>{
    const presetFilterType = action.presetFilterType;
    let statusFilters = state.statusFilters.map(x=>{return {...x,selected: false}});
    switch(presetFilterType){
      case PresetFilterType.AllCourses:
        statusFilters = statusFilters.map(x=>{ return{...x, selected: false}});
        break;
      case PresetFilterType.ApprovedCourses:
        statusFilters = toggleItem(statusFilters, ProviderCourseStatus.Approved);
        break;
      case PresetFilterType.PendingCourses:
        statusFilters = toggleItem(statusFilters, ProviderCourseStatus.Pending);
        break;
    }
    return {
      ...state,
      statusFilters
    }
  }),
  on(fromCourseExchangeCourses.Actions.ToggleCourseLevelFilter, (state, { id }) => {
    const levelFilters = toggleItem(state.levelFilters, id);
    return {
      ...state,
      levelFilters
    }
  }),
  on(fromCourseExchangeCourses.Actions.ToggleCategoryFilter, (state, { id }) => {
    const courseCategoryFilters = toggleItem(state.courseCategoryFilters, id);
    return {
      ...state,
      courseCategoryFilters
    }
  }),
  on(fromCourseExchangeCourses.Actions.ToggleSubCategoryFilter, (state, { id }) => {
    const courseSubCategoryFilters = toggleItem(state.courseSubCategoryFilters, id);
    return {
      ...state,
      courseSubCategoryFilters
    }
  }),
  on(fromCourseExchangeCourses.Actions.ToggleTagFilter, (state, { id }) => {
    const tagFilters = toggleItem(state.tagFilters, id);
    return {
      ...state,
      tagFilters
    }
  }),
  on(fromCourseExchangeCourses.Actions.ToggleProviderFilter, (state, { id }) => {
    const providerFilters = toggleItem(state.providerFilters, id);
    return {
      ...state,
      providerFilters
    }
  }),
  on(fromCourseExchangeCourses.Actions.ToggleStatusFilter, (state, { id }) => {
    const statusFilters = toggleItem(state.statusFilters, id);
    return {
      ...state,
      statusFilters
    }
  }),
  on(fromCourseExchangeCourses.Actions.RemoveFilter, (state, { filter }) => {
    let levelFilters = state.levelFilters;
    let courseCategoryFilters = state.courseCategoryFilters;
    let courseSubCategoryFilters = state.courseSubCategoryFilters;
    let tagFilters = state.tagFilters;
    let providerFilters = state.providerFilters;
    let statusFilters = state.statusFilters;
    switch (filter.type) {
      case FilterType.CourseLevel:
        levelFilters = toggleItem(levelFilters, filter.id);
        break;
      case FilterType.CourseCategory:
        courseCategoryFilters = toggleItem(courseCategoryFilters, filter.id);
        break;
      case FilterType.CourseSubCategory:
        courseSubCategoryFilters = toggleItem(courseSubCategoryFilters, filter.id);
        break;
      case FilterType.Tag:
        tagFilters = toggleItem(tagFilters, filter.id);
        break;
      case FilterType.Provider:
        providerFilters = toggleItem(providerFilters, filter.id);
        break;
      case FilterType.ProviderCourseStatus:
        statusFilters = toggleItem(statusFilters, filter.id);
        break;
    }
    return {
      ...state,
      levelFilters,
      courseCategoryFilters,
      courseSubCategoryFilters,
      tagFilters,
      providerFilters,
      statusFilters
    }
  }),
  on (fromCourseExchangeCourses.Actions.ToggleCourse, (state, {id})=>{
    const courses = toggleItem(state.courses, id);
    return {
      ...state,
      courses
    }
  }),
  on(fromCourseExchangeCourses.Actions.ProviderCourseListItemUpdated, (state, {course})=>{
    let idx = !!state.courses ?state.courses.findIndex(x=>x.id==course.id) : -1;
    if(idx===-1) return state;
    const courses = [...state.courses.slice(0,idx), {...course, selected: false, canModify: true}, ...state.courses.slice(idx+1)];
    
    return {
      ...state,
      courses
    }
  }),
  on(fromCourseExchangeCourses.Actions.MasterToggled, (state, action)=>{
    const courses = state.courses.map((course)=>{
      const idx = action.courses.findIndex(x=>x.id===course.id);
      if(idx===-1){
        return course
      }else{
        return action.courses[idx];
      }
    });
    return {
      ...state,
      courses
    }
  }),
  on(fromCourseExchangeCourses.Actions.ProviderCourseListItemAdded, (state, action)=>{
    const course = {...action.course, selected: false, canModify: true};
    const courses = [...state.courses, course];

    return {
      ...state,
      courses
    }
  }),
  on(fromCourseExchangeCourses.Actions.ApproveCourses, (state, {courseIds})=>{
    const courses = state.courses.map((course)=>{
      if(courseIds.findIndex(x=>x===course.providerCourseId) !==-1){
        return {...course, status: ProviderCourseStatus.Approved}
      }else{
        return course;
      }
    });
    return{
      ...state,
      courses
    }
  }),
  on(fromCourseExchangeCourses.Actions.DisapproveCourses, (state, {courseIds})=>{
    const courses = state.courses.map((course)=>{
      if(courseIds.findIndex(x=>x===course.providerCourseId) !==-1){
        return {...course, status: ProviderCourseStatus.Denied};
      }
      return course;
    });
    return{
      ...state,
      courses
    }
  })
)
