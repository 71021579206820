import { ActivatedRouteSnapshot } from '@angular/router';

export const getInstitutionIdFromRoute = (route: ActivatedRouteSnapshot): string =>{
    const institutionId = route.params['institutionId'];
    const managingInstitutionId = route.params['managingInstitutionId'];

    if(institutionId) return institutionId;
    if(managingInstitutionId) return managingInstitutionId;
}

export const getCourseExchangeIdFromRoute = (route: ActivatedRouteSnapshot): string =>{
    const courseExchangeId = route.params['courseExchangeId'];

    return courseExchangeId;
}