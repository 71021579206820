import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { Store, select } from "@ngrx/store";
import { first, finalize, filter, map, concatMap, withLatestFrom } from "rxjs/operators";
import { IAppState } from 'app/store/state/app.state';
import { CourseExchangeInstitutionListItem } from 'app/shared/courseExchange/models';
import * as fromCourseExchangeInstitution from '@courseExchange/institution';


@Injectable()
export class CourseExchangeInstitutionsResolver implements Resolve<CourseExchangeInstitutionListItem[]>{
    isLoading = false;
    constructor(private store: Store<IAppState>){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
        const courseExchangeId = route.params["courseExchangeId"];
        return this.store 
            .pipe(
                select(fromCourseExchangeInstitution.Selectors.CourseExchangeId),
                map(()=>{
                    if(!this.isLoading){
                        this.isLoading = true;
                        this.store.dispatch(fromCourseExchangeInstitution.Actions.LoadCourseExchangeInstitutions({courseExchangeId}));
                    }
                    return true;
                }),
                filter(areInstitutionsLoaded => areInstitutionsLoaded),
                first(),
                finalize(()=>this.isLoading=false)
            )
    }
}