import { createEffect, Actions, ofType } from "@ngrx/effects";
import { Injectable } from "@angular/core";
import { map, concatMap, withLatestFrom, tap } from "rxjs/operators";
import { of } from 'rxjs';
import { select, Store } from '@ngrx/store';
import { IAppState } from 'app/store/state/app.state';
import { Router } from '@angular/router';
import * as fromCourseExchangeUsers from '@courseExchange/users/store';
import * as fromCourseExchange from '@courseExchange/store';

@Injectable()
export class CourseExchangeUsersNavigate{
    constructor(private actions$: Actions, private store:Store<IAppState>, private router: Router){
            }
    UserList$ = createEffect(() => {
                return this.actions$.pipe(
                        ofType(fromCourseExchangeUsers.Navigate.UserList),
                        concatMap((action)=> of(action).pipe(
                            withLatestFrom(this.store.pipe(select(fromCourseExchange.Selectors.CourseExchange)))
                        )),
                        tap(([action,courseExchange]) => {  
                           const courseExchangeId = courseExchange.id;
                           const managingInstitutionId = courseExchange.managingInstitutionId;
                           this.router.navigate([`/course-exchange/${courseExchangeId}/${managingInstitutionId}/users`])
                        })
                )
            },{dispatch : false});
}