import {createAction, props} from '@ngrx/store';
import { MemberDashboardViewModel } from '@shared/member/models';

export const LoadDashboardData = createAction(
  '[Member Dashboard Effects] - Load Dashboard Data',
  props<{
    memberId: string;
    startDate: Date;
    endDate: Date;
  }>()
)
export const LoadDashboardDataSuccess = createAction(
  '[Member Dashboard Resolver] - Load Dashboard Data Success',
  props<{
    data: MemberDashboardViewModel;
  }>()
);

export const LoadDashboardDataError = createAction(
  '[Member Dashboard Resolver] - Load Dashboard Data Error',
  props<{
    message: string
  }>()
);