import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { Store, select } from "@ngrx/store";
import { first, finalize, filter, map } from "rxjs/operators";
import { IAppState } from 'app/store/state/app.state';
import * as fromStudent from '@member/students';
import { Student } from '@shared/student/models';
import * as fromStudents from '@member/students/store';
@Injectable()
export class StudentResolver implements Resolve<Student>{
    isLoading = false;
    constructor(private store: Store<IAppState>){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
        const studentId = route.params["studentId"];
        return this.store 
            .pipe(
                select(fromStudent.Selectors.CurrentStudent),
                map((currentStudent) => !!currentStudent && currentStudent.id ===studentId),
                map((studentLoaded)=>{
                    if(!this.isLoading && !studentLoaded){
                        this.isLoading = true;
                        this.store.dispatch(fromStudent.Actions.LoadStudent({studentId}));
                    }
                    return true;
                }),
                filter(studentLoaded => studentLoaded),
                first(),
                finalize(()=>this.isLoading=false)
            )
    }
}