import { CourseListItem } from '../../../shared/institution/models/courseListItem.model';
import { ProviderCourseStatus } from './providerCourseStatus.enum';
import { getDefaultSummaryItem, SummaryItem, ElbertFile, CreditHourType } from '@core/models';
import { CourseSessionDateItem } from '@shared/provider/models/courseSessionDateItem';

export interface ProviderCourseListItem extends CourseListItem
{
    courseExchangeId: string;
    providerCourseId: string;
    providerId: string;
    status: ProviderCourseStatus;
    isFavourited: boolean;
    syllabus: ElbertFile;
    canDeactivate: boolean;
    courseExchangeApproved: boolean;
}

export interface ProviderCourseListItemForView extends ProviderCourseListItem{
    selected: boolean;
    canModify: boolean;
}

export const getDefaultProvivderCourseListItem = () =>{
    var result: ProviderCourseListItem = {
        id: '',
        courseId: '',
        courseExchangeId: '',
        institution: getDefaultSummaryItem(),
        logoUrl: '',
        level: getDefaultSummaryItem(),
        code: '',
        title: '',
        requirements: '',
        description: '',
        notes: '',
        creditHours2: '0',
        creditHourType: CreditHourType.SEM,
        category: getDefaultSummaryItem(),
        subCategory: getDefaultSummaryItem(),
        sessions: new Array<CourseSessionDateItem>(),
        tags: new Array<SummaryItem>(),
        isActive: true,
        providerCourseId: '',
        providerId: '',
        status: ProviderCourseStatus.Pending,
        isFavourited: false,
        syllabus: new ElbertFile(),
        canDeactivate: true,
        courseExchangeApproved: false
    };
    return result;
}
