import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'enrollment-short-student-info',
  templateUrl: './enrollment-short-student-info.component.html',
  styleUrls: ['./enrollment-short-student-info.component.scss'],
})
export class EnrollmentShortStudentInfoComponent implements OnInit {
  constructor() {
  }

  ngOnInit(): void {

  }
}
