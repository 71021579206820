import { StartDate } from './../../../../authentication/store/auth.selectors';
import {createEffect, Actions, ofType} from '@ngrx/effects';
import {Injectable} from '@angular/core';
import {map, switchMap, withLatestFrom, concatMap, tap, catchError} from 'rxjs/operators';
import {select, Store} from '@ngrx/store';
import {Observable, of} from 'rxjs';
import {IAppState} from 'app/store/state/app.state';
import * as fromCore from '@core/store';
import * as fromMember from '@member/store';
import * as fromEnrollment from '@member/enrollment/store';
import * as fromStudent from '@member/students/store';
import * as fromMemberCourse from '@member/memberCourse/store';
import * as fromCourseExchange from '@courseExchange/store';
import * as fromRefData from '@refData';
import {MatDialog} from '@angular/material/dialog';
import {NewEnrollmentDialogContainerComponent, NewEnrollmentDialogContainerData, PastStartDateDialogComponent} from '@member/enrollment/components';
import {MemberService} from '@shared/member/services';
import * as moment from 'moment';
import { ExcelColumn, ExcelService } from '@core/services/excel.service';
import { EnrollmentListItem } from '@member/enrollment';
import { FilterType } from '@shared/common/models';
import { sortBy } from 'sort-by-typescript';
import { MemberCourseListItem } from '@member/memberCourse/models';
import { StudentListItem } from '@shared/student/models';

@Injectable()
export class EnrollmentEffects {
  constructor(private actions$: Actions, private store: Store<IAppState>, private dialog: MatDialog,
              private memberService: MemberService, private excelService: ExcelService) {
  }

  loadEnrollmentsError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromEnrollment.Actions.LoadActiveEnrollmentsError),
      /** An EMPTY observable only emits completion. Replace with your own observable stream */
      map(({message}) => fromCore.Actions.ShowMessage({message})));
  });

  enrollStudent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromEnrollment.Actions.EnrollStudent),
      tap((action) =>
        this.dialog.open<NewEnrollmentDialogContainerComponent, NewEnrollmentDialogContainerData>(NewEnrollmentDialogContainerComponent, {
            data: {
              typeFrom: action.fromType
            }
          }
        )))
  }, {dispatch: false});

  submitEnrollments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromEnrollment.Actions.SubmitEnrollments),
      concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromEnrollment.Selectors.StudentsAndCoursesForEffect)))
      )),
      tap(()=>{
        this.store.dispatch(fromStudent.Actions.ClearSelected());
        this.store.dispatch(fromMemberCourse.Actions.ClearSelected());
      }),
      switchMap(([action, {students, selectedMemberCourses, memberCourses}]) => {
        return this.resolvePastStartDates(students, selectedMemberCourses, memberCourses);
      }),
      map(({studentIds, providerCourseSessionIds}) => {
        if(studentIds.length && providerCourseSessionIds.length){
          return fromEnrollment.Actions.CreateEnrollments({studentIds, providerCourseSessionIds});
        }else{
          return fromEnrollment.Actions.NoOp();
        }
      })
    )
  });
  async resolvePastStartDates(students: StudentListItem[], selectedMemberCourses: MemberCourseListItem[], memberCourses: MemberCourseListItem[]): Promise<{studentIds: string[], providerCourseSessionIds: string[]}>{
    let promises = [];
    const studentIds = students.map(x=>x.id);
    selectedMemberCourses.forEach(async(selected)=>{
      const startOfToday = moment().startOf('day').toDate();
      if(selected.startDate>=startOfToday){
        promises.push(Promise.resolve(selected.providerCourseSessionId));
      } else{
        const futureMemberCourses = memberCourses.filter(mc=>mc.providerCourseId==selected.providerCourseId && moment(mc.startDate).isAfter(startOfToday)).sort(sortBy('startDate'));
        const nextScheduledCourse = futureMemberCourses.length>0 ? futureMemberCourses[0] : null;
        promises.push(this.showPastStartDateDialog(selected, nextScheduledCourse));
      }
    })
    const result = await Promise.all(promises);
    const providerCourseSessionIds = result.filter(x=>x !== '');
    return {studentIds, providerCourseSessionIds};
  }

  showPastStartDateDialog(memberCourse: MemberCourseListItem, nextScheduledCourse: MemberCourseListItem): Promise<string>{
    const dialogRef = this.dialog.open(PastStartDateDialogComponent,
      {
          disableClose: true,
          data: {
              pastStartDateCourse: memberCourse,
              nextScheduledCourse
          }
      });

      return dialogRef.afterClosed().toPromise().then(result=>result);
  }

  createEnrollments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromEnrollment.Actions.CreateEnrollments),
      concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromMember.Selectors.MemberId)))
      )),
      map(([{studentIds, providerCourseSessionIds}, memberId]) => {
        return {
          studentIds,
          providerCourseSessionIds,
          memberId
        }
      }),
      switchMap((command) => this.memberService.createEnrollments(command.memberId, command)),
      map((response) =>
        response.isSuccess ? fromEnrollment.Actions.CreateEnrollmentSuccess() :
          fromEnrollment.Actions.CreateEnrollmentError({message: response.message}))
    )
  });

  createEnrollmentSuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromEnrollment.Actions.CreateEnrollmentSuccess),
      /** An EMPTY observable only emits completion. Replace with your own observable stream */
      map(() => fromCore.Actions.ShowMessage({message:"New Request Submitted"})));
  });

  createEnrollmentsError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromEnrollment.Actions.CreateEnrollmentError),
      /** An EMPTY observable only emits completion. Replace with your own observable stream */
      map(({message}) => fromCore.Actions.ShowMessage({message})));
  });

  submitChanges$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromEnrollment.Actions.SubmitStatusChanges),
      concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromEnrollment.Selectors.RequestedChangesForEffects)))
      )),
      tap(([action,{command,enrollmentStatuses}])=>this.store.dispatch(fromEnrollment.Actions.ChangeStatuses({command,enrollmentStatuses}))),
      switchMap(([action,{memberId, command}]) => this.memberService.submitStatusChanges(memberId, command)),
      map((response) => {
        const message = response.message;
        if (!response.isSuccess) {
          this.store.dispatch(fromEnrollment.Actions.SubmitStatusChangesError({message}));
        }
        return fromCore.Actions.HandleResponse({response});
      })
    )
  });


  masterToggle$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromEnrollment.Actions.MasterToggle),
      concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromEnrollment.Selectors.FilteredEnrollments)))
      )),
      map(([action, enrollments]) => {
        const isAllSelected = enrollments.every(x => x.selected);
        return enrollments.map(x => {
          return {
            ...x,
            selected: !isAllSelected
          }
        })
      }),
      map((enrollments) => fromEnrollment.Actions.MasterToggled({enrollments}))
    )
  });

  exportToExcel$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromEnrollment.Actions.ExportToExcel),
      concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromEnrollment.Selectors.EnrollmentsForExport)))
      )),
      tap(([action, {enrollments,canViewGrades}]) => {
        if (enrollments.some(x => x.selected)) {
          enrollments = [...enrollments.filter(x => x.selected)];
        }
        var result = enrollments.map(enrollment => {
          return {
            memberStudentId: enrollment.studentNumber,
            studentFirstName: enrollment.studentFullName.firstName,
            studentLastName: enrollment.studentFullName.lastName,
            studentType: enrollment.studentType,
            dates: `${moment(enrollment.sessionStartDate).format('MM/DD/YYYY')} - ${moment(enrollment.sessionEndDate).format('MM/DD/YYYY')}`,
            providerName: enrollment.providerName,
            courseCode: enrollment.memberCourseCode !='' ? enrollment.memberCourseCode : enrollment.providerCourseCode,
            title: enrollment.courseTitle,
            status: enrollment.status.description,
            letterGrade: !!enrollment.letterGrade ? enrollment.letterGrade.description : ''
          };
        });

        if(canViewGrades){
          var columns: ExcelColumn[] = new Array<ExcelColumn>(
            {header: 'Member Student ID', fieldName: 'memberStudentId'},
            {header: 'Student Type', fieldName: 'studentType'},
            {header: 'Student First', fieldName: 'studentFirstName'},
            {header: 'Student Last', fieldName: 'studentLastName'},
            {header: 'Session Start-Session End Dates', fieldName:'dates'},
            {header: 'Provider', fieldName: 'providerName'},
            {header: 'Course Code', fieldName: 'courseCode'},
            {header: 'Course Title', fieldName: 'title'},
            {header: 'Status', fieldName: 'status'},
            {header: 'Final Grade', fieldName: 'letterGrade'}
          );
        }else{
          var columns: ExcelColumn[] = new Array<ExcelColumn>(
            {header: 'Student ID', fieldName: 'memberStudentId'},
            {header: 'Student First', fieldName: 'studentFirstName'},
            {header: 'Student Last', fieldName: 'studentLastName'},
            {header: 'Session Start-Session End Dates', fieldName:'dates'},
            {header: 'Provider', fieldName: 'providerName'},
            {header: 'Course Code', fieldName: 'courseCode'},
            {header: 'Course Title', fieldName: 'title'},
            {header: 'Status', fieldName: 'status'}
          );
        }
      
        this.excelService.exportAsExcelFile(result, columns, `Enrollments - ${moment().format('YYYY-MM-DD')}`);
      })
    )
  }, {dispatch: false});

  loadEnrollmentsByDateRange$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromEnrollment.Actions.LoadEnrollmentByDateRange), concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromMember.Selectors.DateRangeArgs)))
      )),
      switchMap(([action, dateArgs]) => {
        const memberId = !!action.memberId ? action.memberId : dateArgs.memberId;
        const startDate = dateArgs.startDate;
        const endDate = dateArgs.endDate;

        if(memberId==='') return of(new Array<EnrollmentListItem>());
        return this.memberService.getEnrollmentByDateRange(memberId, startDate,endDate);
      }),
      map((response) => {
        const enrollments = response.map(x => {
          return {
            ...x,
            selected: false,
            requestedStatus: null,
            letterGradeDescription: !!x.letterGrade ? x.letterGrade.description : "zz" 
          }
        });
        return fromEnrollment.Actions.LoadActiveEnrollmentsSuccess({ enrollments })
      }),
      catchError((error) =>{
        console.log(error);
        return of(fromEnrollment.Actions.LoadActiveEnrollmentsError(
          {message: 'Problem retrieving Enrollments'}
        ));
      })
    )
  });

  loadProviderFilters$ = createEffect(() => {
    return this.actions$.pipe(
        ofType(fromCourseExchange.Actions.LoadCourseExchangeSummarySuccess),
        /** An EMPTY observable only emits completion. Replace with your own observable stream */
        map(({courseExchangeSummary}) => {
          const providerFilters = courseExchangeSummary.providers.map(provider => {
            return {
              id: provider.id,
              type: FilterType.Provider,
              description: !!provider ? provider.description : '',
              descriptionForView: !!provider ? provider.description : '',
              selected: false
            }
          });
          return fromEnrollment.Actions.LoadProviderFilters({providerFilters});
        })
    );
  });

  loadTagFilters$ = createEffect(() => {
    return this.actions$.pipe(
        ofType(fromCourseExchange.Actions.LoadCourseExchangeSummarySuccess),
        concatMap((action) => of(action).pipe(
          withLatestFrom(this.store.pipe(select(fromRefData.courseTags)))
        )),
        /** An EMPTY observable only emits completion. Replace with your own observable stream */
        map(([{courseExchangeSummary}, tags]) => {
          const tagFilters = courseExchangeSummary.courseTags.map(id => {
            const tag = tags.find(x => x.id === id);
            return {
              id: id,
              type: FilterType.Tag,
              description: !!tag ? tag.description : '',
              descriptionForView: !!tag ? tag.description : '',
              selected: false
            }
          });
          return fromEnrollment.Actions.LoadTagFilters({tagFilters});
        })
    );
  });

  loadCategoryFilters$ = createEffect(() => {
    return this.actions$.pipe(
        ofType(fromCourseExchange.Actions.LoadCourseExchangeSummarySuccess),
        concatMap((action) => of(action).pipe(
          withLatestFrom(this.store.pipe(select(fromRefData.courseCategories)))
        )),
        /** An EMPTY observable only emits completion. Replace with your own observable stream */
        map(([{courseExchangeSummary}, categories]) => {
          const categoryFilters = courseExchangeSummary.courseCategories.map(id => {
            const category = categories.find(x => x.id === id);
            return {
              id: id,
              type: FilterType.CourseCategory,
              description: !!category ? category.description : '',
              descriptionForView: !!category ? category.description : '',
              selected: false
            }
          });
          return fromEnrollment.Actions.LoadCategoryFilters({categoryFilters});
        })
    );
  });

  loadSubCategoryFilters$ = createEffect(() => {
    return this.actions$.pipe(
        ofType(fromCourseExchange.Actions.LoadCourseExchangeSummarySuccess),
        concatMap((action) => of(action).pipe(
          withLatestFrom(this.store.pipe(select(fromRefData.courseSubCategories)))
        )),
        /** An EMPTY observable only emits completion. Replace with your own observable stream */
        map(([{courseExchangeSummary}, subCategories]) => {
          const subCategoryFilters = courseExchangeSummary.courseSubCategories.map(id => {
            const subCategory = subCategories.find(x => x.id === id);
            return {
              id: id,
              type: FilterType.CourseSubCategory,
              description: !!subCategory ? subCategory.description : '',
              descriptionForView: !!subCategory ? subCategory.description : '',
              selected: false
            }
          });
          return fromEnrollment.Actions.LoadSubCategoryFilters({subCategoryFilters});
        })
    );
  });
}
