import { NgModule } from '@angular/core';
import * as fromProvider from '@provider/store';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { InstitutionSharedModule } from '@shared/institution/institution-shared.module';
import { UserSharedModule } from '@shared/user/user-shared.module';
import { CalendarSharedModule } from '@shared/calendar/calendar.module';
import { FacultyCVStoreModule } from '@provider/facultyCV/store/facultyCVStore.module';
import { EnrollmentStoreModule } from '@provider/enrollment/store/enrollmentStore.module';
import { ProviderCoursesStoreModule } from '@provider/courses/store/providerCoursesStore.module';
import { ProviderCourseScheduleStoreModule } from '@provider/course-schedule/store/providerCourseSchedule.module';
import { ProviderBillingStoreModule } from '@provider/billing/store/providerBillingStore.module';
import { ProviderDashboardStoreModule } from '@provider/dashboard/store/providerDashboardStore.module';

@NgModule({
  declarations: [],
  imports: [
    InstitutionSharedModule,
    UserSharedModule,
    CalendarSharedModule,
    EnrollmentStoreModule,
    FacultyCVStoreModule,
    ProviderCoursesStoreModule,
    ProviderCourseScheduleStoreModule,
    ProviderBillingStoreModule,
    ProviderDashboardStoreModule,
    StoreModule.forFeature('provider', fromProvider.providerReducer),
    EffectsModule.forFeature([...fromProvider.EFFECTS])
  ],
  providers: [...fromProvider.RESOLVERS]
})
export class ProviderStoreModule { }
