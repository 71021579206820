import { FacultyCV } from "@shared/institution/models";
import { FacultyCVState } from "../facultyCV.reducer";

export default (facultyCV:FacultyCV, state: FacultyCVState): FacultyCVState => {
    const idx = state.facultyCVs.findIndex(x=>x.id==facultyCV.id);
    if(idx===-1) return state
    const facultyCVs = [...state.facultyCVs.slice(0,idx),facultyCV, ...state.facultyCVs.slice(idx+1)];
    return{
        ...state,
        facultyCVs
    }
}