import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {EnrollmentListItemForView} from '@member/enrollment';
import {IAppState} from '../../../../store/state/app.state';
import * as fromEnrollment from '@provider/enrollment/store';
import * as fromRefData from '@refData';
import { BaseListItem } from '@core/models';

export interface UpdateEnrollmentLetterGradePayload {
  selectedLetterGrade: BaseListItem;
  enrollmentIds: string[];
}

@Component({
  selector: 'update-enrollment-letter-grade-dialog',
  templateUrl: './update-enrollment-letter-grade-dialog.component.html',
  styleUrls: ['./update-enrollment-letter-grade-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UpdateEnrollmentLetterGradeDialogComponent implements OnInit {
  enrollments$: Observable<EnrollmentListItemForView[]>;
  letterGrades$: Observable<BaseListItem[]>;
  constructor(
    public matDialogRef: MatDialogRef<UpdateEnrollmentLetterGradeDialogComponent>,
    private store: Store<IAppState>
  ) {
    this.matDialogRef.addPanelClass('update-enrollment-letter-grade-dialog');
  }

  ngOnInit(): void {
    this.enrollments$ = this.store.pipe(select(fromEnrollment.Selectors.SelectedEnrollmentsForGrading));
    this.letterGrades$ = this.store.pipe(select(fromRefData.Selectors.letterGrades));
  }

  close(): void {
    this.matDialogRef.close();
  }

  updateEnrollmentLetterGrade(payload: UpdateEnrollmentLetterGradePayload): void {
    payload.enrollmentIds.forEach(enrollmentId =>{
      this.store.dispatch(fromEnrollment.Actions.EditLetterGrade({enrollmentId, requestedLetterGrade: payload.selectedLetterGrade}));
    })
    this.close();
  }
}
