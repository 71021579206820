import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of, fromEvent, Subscription } from 'rxjs';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { UserListItem, UserListItemForView } from 'app/shared/user/models';
import { Sort, Order } from 'app/core/models';
import { MatSelectionListChange } from '@angular/material/list';
import { map, tap, debounceTime, distinctUntilChanged, withLatestFrom } from 'rxjs/operators';
import { CourseExchangeRole, InstitutionRole } from 'app/core/referenceData';
import { CourseExchangeListItem } from 'app/shared/courseExchange/models/courseExchangeListItem';
import { InstitutionSummary } from 'app/shared/institution/models';
import { UserSummary } from 'app/authentication/models/userSummary.model';
import { ConfirmDialogData, ConfirmDialogComponent } from 'app/core/components/confirm-dialog/confirm-dialog.component';
import { UserService } from '../../services';
import { PopupService } from 'app/core/services/popup.service';
import hasInstitutionPermission from 'app/authentication/permissions/hasInstitutionPermission';
import { PermissionType } from 'app/authentication/models/permissionType.enum';
import { ViewContext } from 'app/authentication/models/viewContext.enum';
import { AccessRoleSummary } from 'app/authentication/models/accessRoleSummary';
import { ExcelService, ExcelColumn } from 'app/core/services/excel.service';
import { Filter } from 'app/shared/common/models';
import * as fromStore from './store';
import * as fromAuth from '@auth/store';
import { IAppState } from 'app/store/state/app.state';
import { Store, select } from '@ngrx/store';
import hasCourseExchangeAdminPermission from '@auth/permissions/hasCourseExchangeAdminPermission';
import isSuperAdmin from '@auth/permissions/isSuperAdmin';
@Component({
  selector: 'elb-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
  encapsulation: ViewEncapsulation.None,

})
export class UserListComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input() kind: string;
  @Input() courseExchanges: CourseExchangeListItem[];
  @Input() institutions: InstitutionSummary[];
  orders: Order[] = fromStore.Static.Orders;
  @Input() sorts: Sort[];
  @Input() showDelete: boolean = false;
  @Input() users: UserListItem[];
  @Input() institutionRoles:InstitutionRole[];
  @Input() courseExchangeRoles: CourseExchangeRole[];

  @Output() addClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() editClicked: EventEmitter<UserListItem> = new EventEmitter<UserListItem>();
  @Output() goToDashboardClicked: EventEmitter<void> = new EventEmitter<void>();

  users$: Observable<UserListItemForView[]>;
  selectedUsers$: Observable<UserListItemForView[]>;
  isAllSelected$: Observable<boolean>;
  indeterminateSelected$: Observable<boolean>;
  selectedFilters$: Observable<Filter[]>;
  courseExchangeFilters$: Observable<Filter[]>;
  courseExchangeFiltersCount$: Observable<number>;
  institutionRoleFilters$: Observable<Filter[]>;
  institutionRoleFiltersCount$: Observable<number>;
  institutionFilters$: Observable<Filter[]>;
  institutionFiltersCount$: Observable<number>;
  statusFilters$: Observable<Filter[]>;
  statusFiltersCount$: Observable<number>;
  currentSort$: Observable<Sort>;
  currentOrder$: Observable<Order>;
  currentUser$: Observable<UserSummary>;
  sorts$: Observable<Sort[]>;
  searchTextSubscription$: Subscription;
  selectedStatus$: Observable<string>;
  canExportToExcel$: Observable<boolean>;
  isAllSelected: boolean;
  @ViewChild('filter') filter: ElementRef;

  constructor(
    public dialog: MatDialog,
    private excelService: ExcelService,
    private location: Location,
    private popupService: PopupService,
    private userService: UserService,
    private store: Store<IAppState>) { }

    ngOnInit(): void {
      this.users$= this.store.pipe(select(fromStore.Selectors.FilteredUsers));
      this.selectedUsers$ = this.store.pipe(select(fromStore.Selectors.SelectedUsers));
      this.selectedStatus$ = this.store.pipe(select(fromStore.Selectors.SelectedStatus));
      this.selectedFilters$ = this.store.pipe(select(fromStore.Selectors.SelectedFilters));
      this.isAllSelected$ = this.store.pipe(select(fromStore.Selectors.IsAllSelected));
      this.isAllSelected$.subscribe(result=>this.isAllSelected=result);
      this.indeterminateSelected$ = this.store.pipe(select(fromStore.Selectors.IndeterminateSelected));
      this.courseExchangeFilters$ = this.store.pipe(select(fromStore.Selectors.CourseExchangeFilters));
      this.courseExchangeFiltersCount$ = this.store.pipe(select(fromStore.Selectors.CourseExchangeFilterCount));
      this.institutionFilters$ = this.store.pipe(select(fromStore.Selectors.InstitutionFilters));
      this.institutionFiltersCount$ = this.store.pipe(select(fromStore.Selectors.InstitutionFilterCount));
      this.institutionRoleFilters$ = this.store.pipe(select(fromStore.Selectors.InstitutionRoleFilters));
      this.institutionRoleFiltersCount$ = this.store.pipe(select(fromStore.Selectors.InstitutionRoleFilterCount));
      this.statusFilters$ = this.store.pipe(select(fromStore.Selectors.StatusFilters));
      this.statusFiltersCount$ = this.store.pipe(select(fromStore.Selectors.StatusFilterCount));
      this.sorts$ = this.store.pipe(select(fromStore.Selectors.Sorts));
      this.currentSort$ = this.store.pipe(select(fromStore.Selectors.CurrentSort));
      this.currentOrder$ = this.store.pipe(select(fromStore.Selectors.CurrentOrder));
      this.currentUser$ = this.store.pipe(select(fromAuth.Selectors.CurrentUser));
      this.canExportToExcel$ = this.store.pipe(select(fromStore.Selectors.CanExportToExcel));
      const currentOrder = this.orders.find(x=>x.isDefault);
      this.store.dispatch(fromStore.Actions.ChangeOrder({currentOrder}));
    }
    ngOnChanges(changes: SimpleChanges) {
      if (changes.courseExchanges && changes.courseExchanges.currentValue) {
        const courseExchanges = changes.courseExchanges.currentValue;
        this.store.dispatch(fromStore.Actions.LoadCourseExchanges({courseExchanges}));
      }
      if (changes.institutions && changes.institutions.currentValue) {
        const institutions = changes.institutions.currentValue;
        this.store.dispatch(fromStore.Actions.LoadInstitutions({institutions}));
      }
      if (changes.sorts && changes.sorts.currentValue) {
        const sorts:Sort[] = changes.sorts.currentValue;
        this.store.dispatch(fromStore.Actions.LoadSorts({sorts}));
        const currentSort = sorts.find(x=>x.isDefault);
        this.store.dispatch(fromStore.Actions.ChangeSort({currentSort}));
      }
      if(changes.users && changes.users.currentValue){
        const users = changes.users.currentValue;
        this.store.dispatch(fromStore.Actions.LoadUsers({users}));
      }
      if(changes.institutionRoles && changes.institutionRoles.currentValue){
        const institutionRoles = changes.institutionRoles.currentValue;
        this.store.dispatch(fromStore.Actions.LoadInstitutionRoles({institutionRoles}));
      }
    }
    ngOnDestroy(){
      this.searchTextSubscription$.unsubscribe();
    }
    ngAfterViewInit(){
      this.setupTextSearch();
    }

  activateUser(user: UserListItem): void {
    const dialogData: ConfirmDialogData = {
      title: "Confirm Activation",
      message: `You are restoring this user’s access to their previous user role(s).  Do you want to continue?`,
      cancelButtonText: "No",
      okButtonText: "Yes"
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px;",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        const userId = user.id;
        const institutionId = user.institutionId;
        this.store.dispatch(fromStore.Actions.ActivateUser({institutionId, userId}));
      }
    })
  }
  canActivate(currentUser:UserSummary, user: UserListItem): boolean {
    return !user.isActive && user.id !== currentUser.userId && hasInstitutionPermission(currentUser, user.institutionId, PermissionType.CanActivateUser);
  }
  canDeactivate(currentUser:UserSummary, user: UserListItem): boolean {
    return user.isActive && user.id !== currentUser.userId && hasInstitutionPermission(currentUser, user.institutionId, PermissionType.CanDeactivateUser)
  }
  canDelete(currentUser:UserSummary, user:UserListItem): boolean {
    return user.isActive && user.id !== currentUser.userId && isSuperAdmin(currentUser)
  }
  canEditUser(currentUser:UserSummary, user: UserListItem): boolean {
    return !!currentUser ? user.id !== currentUser.userId && (hasInstitutionPermission(currentUser, user.institutionId, PermissionType.UpdateUser)):false;
  }
  canSendInvite(currentUser:UserSummary, user: UserListItem): boolean {
    return user.id !== currentUser.userId && hasInstitutionPermission(currentUser, user.institutionId, PermissionType.UpdateUser);
  }
  
  changeOrderBy(currentOrder:Order): void {
    this.store.dispatch(fromStore.Actions.ChangeOrder({currentOrder}));
  }
  changeSort(currentSort: Sort): void {
    this.store.dispatch(fromStore.Actions.ChangeSort({currentSort}));
  }
  onToggleCourseExchangeFilter(event:MatSelectionListChange): void{
    const id = event.options[0].value
    this.store.dispatch(fromStore.Actions.ToggleCourseExchangeFilter({id}));
  }
  onToggleInstitutionFilter(event:MatSelectionListChange): void{
    const id = event.options[0].value
    this.store.dispatch(fromStore.Actions.ToggleInstitutionFilter({id}));
  }
  onToggleInstitutionRoleFilter(event:MatSelectionListChange): void{
    const id = event.options[0].value
    this.store.dispatch(fromStore.Actions.ToggleInstitutionRoleFilter({id}));
  }
  onToggleStatusFilter(event:MatSelectionListChange): void{
    const id = event.options[0].value
    this.store.dispatch(fromStore.Actions.ToggleStatusFilter({id}));
  }

  deactivateUser(user: UserListItem): void {
    const dialogData: ConfirmDialogData = {
      title: "Confirm Deactivation",
      message: `You are removing this user’s access to the system.  This change will be applied to all roles this user may have.  Do you want to continue?`,
      cancelButtonText: "No",
      okButtonText: "Yes"
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px;",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        const userId = user.id;
        const institutionId = user.institutionId;
        this.store.dispatch(fromStore.Actions.DeactivateUser({institutionId, userId}));
      }
    })
  }
  deleteUser(user: UserListItem): void {
    this.store.dispatch(fromStore.Actions.ConfirmDeleteUser({user}));
  }
  private exportToExcel(users: UserListItem[]): void{
    var excelColumns: ExcelColumn[] = new Array<ExcelColumn>(
      {header: 'Exchange', fieldName: 'courseExchangeName'},
      {header: 'Institution', fieldName: 'institutionName'},
      {header: 'Institution Role', fieldName: 'institutionRole'},
      {header: 'Exchange Role', fieldName: 'exchangeRole'},
      {header: 'Status', fieldName: 'status'},
      {header: 'Department', fieldName: 'department'},
      {header: 'Title', fieldName: 'title'},
      {header: 'First Name', fieldName: 'firstName'},
      {header: 'Last Name', fieldName: 'lastName'},
      {header: 'Email Address', fieldName: 'emailAddress'},
      {header: 'Phone Number', fieldName: 'phoneNumber'},
    );
    var result = [];
    users.forEach(user =>{
      user.accessRoles.forEach(role=>{
        var courseExchangeName = role.viewContext==ViewContext.Member || role.viewContext === ViewContext.Provider ? role.entityDescription : "";
        var status = user.isActive ? "Active" : "Inactive";
        var excelObject = {
          courseExchangeName: courseExchangeName,
          institutionName: user.institutionName,
          institutionRole: user.institutionRole,
          exchangeRole: role.roleDescription,
          status: status,
          department: user.department,
          title: user.title,
          firstName: user.firstName,
          lastName: user.lastName,
          emailAddress: user.emailAddress,
          phoneNumber: user.phoneNumber
        };
        result.push(excelObject);
      });
    });
    this.excelService.exportAsExcelFile(result, excelColumns, "Users");

  }
  exportSelectedToExcel(evt:any): void{
    of(evt).pipe(
      withLatestFrom(this.selectedUsers$),
      tap(([obj,users])=>{
        this.exportToExcel(users)
      })
    ).subscribe();
  }
  exportUserToExcel(user: UserListItem): void{
    this.exportToExcel([user]);
  }
  setupTextSearch(): void {
    this.searchTextSubscription$ = fromEvent(this.filter.nativeElement, 'keyup').pipe(
      map((event: any) => event.target.value),
      debounceTime(300),
      distinctUntilChanged(),
      tap((searchText: string) =>
        this.store.dispatch(fromStore.Actions.ChangeSearchText({searchText})))
    ).subscribe()
  }
  goBack(): void {
    this.location.back();
  }
  editUser(user: UserListItem): void {
    this.editClicked.emit(user);
  }
  managePermissions(user: UserListItem, accessRoleSummary: AccessRoleSummary): void {
    this.store.dispatch(fromStore.Actions.ManagePermissions({user, accessRoleSummary}));
  }
  masterToggle(isAllSelected: boolean): void {
    this.store.dispatch(fromStore.Actions.MasterToggle({isAllSelected}));
  }
  removeFilter(filter: Filter) {
    this.store.dispatch(fromStore.Actions.RemoveFilter({filter}));
  }
  sendInviteEmail(user: UserListItem): void
  {
    this.store.dispatch(fromStore.Actions.SendInviteEmail({user}));
  }
  showEllipsis(currentUser: UserSummary, user: UserListItemForView): boolean{
    return this.canEditUser(currentUser,user) || this.canActivate(currentUser,user) ||
          this.canDeactivate(currentUser,user) || this.canDelete(currentUser,user);
  }
  toggle(user: UserListItemForView): void{
    this.store.dispatch(fromStore.Actions.ToggleUser({user}));
  }
  trackBy(filter: any): string {
    return filter.id;
  }

}


