<elb-page-list-container [kind]="'member'" class="page-list-member-course-list listPage member">


  <elb-page-list-content>

    <elb-page-list-sidebar>
      <mat-form-field class="dateRange">
        <mat-label>Date Range</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate [value]="startDate$ |async" placeholder="Start date" (dateChange)="changeStartDate($event)" #startDate>
          <input matEndDate [value]="endDate$ | async" placeholder="End date" (dateChange)="changeEndDate($event)" #endDate>
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
      <div class="section">
        <div class="section-title">Course List:</div>

        <div class="radios">
          <mat-radio-group aria-label="Select an option" (change)="onChangePresetFilter($event)">
            <mat-radio-button *ngFor="let type of presetFilterTypes; trackBy: trackById"
                              [checked]="type.id === (presetFilterType$ | async)?.id" [value]="type">
              {{type.description}}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
      <mat-accordion>
        <mat-expansion-panel *ngIf="(levelFilters$ | async)?.length>0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 [matBadge]="levelFilterCount$ | async" matBadgeOverlap="false">Course Level</h3>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="side-panel-list">
            <mat-selection-list (selectionChange)="onToggleCourseLevelFilter($event)">
              <mat-list-option *ngFor="let filter of levelFilters$ | async; trackBy: trackById"
                               [value]="filter.id" [selected]="filter.selected">
                {{filter.descriptionForView}}
              </mat-list-option>
            </mat-selection-list>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="(providerFilters$ | async)?.length>0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 [matBadge]="providerFilterCount$ | async" matBadgeOverlap="false">Provider</h3>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="side-panel-list">
            <mat-selection-list (selectionChange)="onToggleProviderFilter($event)">
              <mat-list-option *ngFor="let filter of providerFilters$ | async; trackBy: trackById"
                               [value]="filter.id" [selected]="filter.selected">
                {{filter.descriptionForView}}
              </mat-list-option>
            </mat-selection-list>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="(categoryFilters$ | async)?.length>0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 [matBadge]="categoryFilterCount$ | async" matBadgeOverlap="false">Category</h3>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="side-panel-list">
            <mat-selection-list (selectionChange)="onToggleCategoryFilter($event)">
              <mat-list-option *ngFor="let filter of categoryFilters$ | async; trackBy: trackById"
                               [value]="filter.id" [selected]="filter.selected">
                {{filter.descriptionForView}}
              </mat-list-option>
            </mat-selection-list>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="(subCategoryFilters$ | async)?.length>0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 [matBadge]="subCategoryFilterCount$ | async" matBadgeOverlap="false">Sub Category</h3>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="side-panel-list">
            <mat-selection-list (selectionChange)="onToggleSubCategoryFilter($event)">
              <mat-list-option *ngFor="let filter of subCategoryFilters$ | async; trackBy: trackById"
                               [value]="filter.id" [selected]="filter.selected">
                {{filter.descriptionForView}}
              </mat-list-option>
            </mat-selection-list>
          </div>
        </mat-expansion-panel>

        <mat-expansion-panel *ngIf="(tagFilters$ | async)?.length>0">
          <mat-expansion-panel-header>
            <mat-panel-title>
              <h3 [matBadge]="tagFilterCount$ | async" matBadgeOverlap="false">Tag</h3>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="side-panel-list">
            <mat-selection-list (selectionChange)="onToggleTagFilter($event)">
              <mat-list-option *ngFor="let filter of tagFilters$ | async; trackBy: trackById"
                               [value]="filter.id" [selected]="filter.isSelected">
                {{filter.descriptionForView}}
              </mat-list-option>
            </mat-selection-list>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </elb-page-list-sidebar>

    <elb-page-list-main>

      <elb-page-header [pageTitle]="pageTitle"
      (returnToDashboardClicked)="goToMemberDashboard()"
      [pageSubTitle]="pageSubTitle">
      </elb-page-header>

      <elb-page-list-main-header>
        <div class="search-and-sort-list" fxLayout="row" fxLayoutAlign="space-between center">
          <!-- SEARCH -->
          <div class="search-wrapper">
            <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
              <mat-icon>search</mat-icon>
              <input #filter placeholder="Search Courses">
            </div>
          </div>
          <!-- / END SEARCH -->
          <!-- SORT MENU -->
          <div class="sort-wrapper" *ngIf="!(IsMemberCoursesLoading$ | async)">
            <mat-form-field class="full-list-sort">
              <mat-select placeholder="Sort By" [value]="currentSort$ | async"
                          (selectionChange)="changeSort($event.value)">
                <mat-select-trigger>
                  <mat-icon>{{(currentSort$ | async)?.icon}}</mat-icon>&nbsp;{{(currentSort$ | async)?.name}}
                </mat-select-trigger>
                <mat-option *ngFor="let sort of sorts$ | async" [value]="sort">
                  <mat-icon>{{sort.icon}}</mat-icon>{{sort.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="full-list-sort">
              <mat-select placeholder="Order By" [value]="currentOrder$ | async"
                          (selectionChange)="changeOrderBy($event.value)">
                <mat-select-trigger>
                  <mat-icon>{{(currentOrder$ | async)?.icon}}</mat-icon>&nbsp;{{(currentOrder$ | async)?.name}}
                </mat-select-trigger>
                <mat-option *ngFor="let order of orders" [value]="order">
                  <mat-icon>{{order.icon}}</mat-icon>{{order.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <!-- /END SORT -->
        </div>
        <div class="filters-wrapper" fxLayout="row" fxLayoutAlign="space-between center">
          <div class="list-select-all">
            <mat-checkbox (change)="masterToggle()" [checked]="isAllSelected$ | async"
                          [indeterminate]="indeterminateSelected$ | async">
              Select All Courses ({{selectedStatus$ | async}})
            </mat-checkbox>
          </div>
          <div class="tab-filters-chosen">
            <mat-chip-list>
              <mat-chip *ngFor="let filter of selectedFilters$ | async; trackBy: trackById" [selectable]="false"
                        [removable]="true" (removed)="removeFilter(filter)">
                {{filter.description}}
                <mat-icon matChipRemove>cancel</mat-icon>
              </mat-chip>
            </mat-chip-list>
          </div>
        </div>
      </elb-page-list-main-header>

      <elb-page-list-main-body>
        <div class="list-wrap" *ngIf="(memberCourses$ | async) as memberCourses">
          <cdk-virtual-scroll-viewport itemSize="50" class="list">
            <member-course-item
              *cdkVirtualFor="let memberCourse of memberCourses; trackBy: trackById"
              [course]="memberCourse"
              [canEnrollStudent]="canEnrollStudents$ | async" 
              (toggleMemberCourse)="onToggleMemberCourse($event)"
              (goToCourseDetail)="goToMemberCourseDetail($event)"
              (enroll)="enrollSingleCourse($event)"></member-course-item>
          </cdk-virtual-scroll-viewport>
        </div>
      </elb-page-list-main-body>
      <app-loading-spinner  *ngIf="(IsMemberCoursesLoading$ | async)"></app-loading-spinner>
      <elb-page-list-main-footer>
        <button class="btn--member-admin btn-footer"
                [disabled]="!((selectedCourses$ | async)?.length>1)" (click)="compareCourses()" mat-button>
          <mat-icon>compare_arrows</mat-icon>Compare Selected Courses
        </button>

        <button class="btn--member-admin btn-footer" mat-button
                [disabled]="!(canExportToExcel$ | async)" (click)="exportToExcel()">
          <mat-icon>save_alt</mat-icon>Export Courses to Excel
        </button>

        <button class="btn--member-admin btn-footer"
                [disabled]="!(selectedNonFavouriteCourses$ | async)?.length" (click)="preselectCourses()" mat-button>
          <mat-icon>start</mat-icon>Pre-Select Course(s)
        </button>


        <button class="btn--member-admin btn-footer"
                [disabled]="!(selectedFavouriteCourses$ | async)?.length" (click)="deselectCourses()" mat-button>
          <mat-icon>start_border</mat-icon>Remove Course(s)
        </button>

        <button class="btn--member-admin btn-footer"
                *ngIf="canEnrollStudents$ | async"
                [disabled]="!(selectedCourses$ | async)?.length" (click)="enrollStudents()" mat-button>
          <mat-icon>person_add</mat-icon>Enroll Student
        </button>
      </elb-page-list-main-footer>

    </elb-page-list-main>
  </elb-page-list-content>
</elb-page-list-container>
