import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { User, UserListItem } from '../models';
import { AddUserCommand, UpdateUserCommand, ActivateUserCommand, DeactivateUserCommand, DeleteUserCommand } from 'app/shared/user/commands';
import { BaseResponse } from 'app/core/models';
import { UpdateAccessRoleCommand } from '../commands/UpdateAccessRoleCommand';
import { UpdateInstitutionAccessRoleCommand } from '../commands/UpdateInstitutionAccessRoleCommand';
import { AccessRole } from 'app/authentication/models/accessRole';
import { SendAccountSetupEmailCommand } from '../commands/SendAccountSetupEmailCommand';
import { ConfigService } from '@core/services/config.service';
import { map, tap } from 'rxjs/operators';
import { PopupService } from '@core/services/popup.service';
import * as fromCore from '@core/store';
@Injectable({providedIn:'root'})
export class UserService {
  private apiUrl:string='';
  constructor(private http: HttpClient, private config: ConfigService,private popupService: PopupService) { 
    this.config.apiUrl$.subscribe(result=>this.apiUrl=`${result}/user`);
  }

  public getUserList(institutionId: string): Observable<UserListItem[]>{
    return this.http.get<UserListItem[]>(`${this.apiUrl}/getUsersByInstitution/${institutionId}`);
  }

  public getAllUsers(): Observable<UserListItem[]>
  {
    return this.http.get<UserListItem[]>(`${this.apiUrl}/getAllUsers`);
  }

  public getUserById(institutionId:string, userId: string): Observable<User>{
    return this.http.get<User>(`${this.apiUrl}/${institutionId}/${userId}`);
  }

  public getUserAccessRole(institutionId: string, userId: string, accessRoleId: string):Observable<AccessRole>{
    return this.http.get<AccessRole>(`${this.apiUrl}/${institutionId}/${userId}/accessRole/${accessRoleId}`);
  }

  public addUser(institutionId: string, command: AddUserCommand): Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.apiUrl}/${institutionId}`, command);
  }

  public updateUser(institutionId: string, command: UpdateUserCommand): Observable<BaseResponse>{
    return this.http.put<BaseResponse>(`${this.apiUrl}/${institutionId}`, command);
  }

  public deleteUser(institutionId: string, command: DeleteUserCommand): Observable<BaseResponse>{
    return this.http.put<BaseResponse>(`${this.apiUrl}/${institutionId}/delete`,command);
  }

  public activateUser(institutionId: string, command: ActivateUserCommand): Observable<BaseResponse>
  {
    return this.http.put<BaseResponse>(`${this.apiUrl}/${institutionId}/activate`,command);
  }

  public deactivateUser(institutionId: string, command: DeactivateUserCommand): Observable<BaseResponse>
  {
    return this.http.put<BaseResponse>(`${this.apiUrl}/${institutionId}/deactivate`,command);
  }

  public updateAccessRole(institutionId: string, command: UpdateAccessRoleCommand): Observable<BaseResponse>
  {
    return this.http.put<BaseResponse>(`${this.apiUrl}/${institutionId}/updateAccessRole`, command);
  }

  public updateInstitutionAccessRole(institutionId: string, command: UpdateInstitutionAccessRoleCommand): Observable<BaseResponse>
  {
    return this.http.put<BaseResponse>(`${this.apiUrl}/${institutionId}/updateInstitutionAccessRole`, command);
  }

  public getAccessRole(institutionId: string, userId: string, accessRoleId: string): Observable<AccessRole>
  {
    return this.http.get<AccessRole>(`${this.apiUrl}/accessRole/${institutionId}/${userId}/${accessRoleId}`);
  }

  public sendInvite(institutionId: string, command: SendAccountSetupEmailCommand): Observable<BaseResponse>
  {
    return this.http.post<BaseResponse>(`${this.apiUrl}/${institutionId}/sendInvite`,command);
  }

  public emailExists(userId: string,emailAddress: string): Observable<boolean>{
    if(emailAddress.trim()==="") return of(false);
    if(userId.trim()==="") userId = 'new';
    return this.http.get<boolean>(`${this.apiUrl}/${userId}/emailExists/${emailAddress}`);
  }
}
