import { MonthlyStatement } from '@core/models/monthlyStatement.viewModel';
import { MonthlyEarningsStatement } from '@courseExchange/billing/models';
import { props, createAction } from '@ngrx/store';

export const LoadMonthlyStatements = createAction(
    '[Course Exchange Monthly Bill Resolver] - Load Course Exchange Monthly Statements',
    props<{
        courseExchangeId?: string;
    }>()
)

export const LoadMonthlyStatementsSuccess = createAction(
    '[Course Exchange Monthly Bill Effects] - Load Course Exchange Monthly Statements Success',
    props<{
        monthlyStatements: Array<MonthlyEarningsStatement>
    }>()
)

export const LoadMonthlyStatementsError = createAction(
    '[Course Exchange Monthly Bill Effects] - Load Course Exchange Monthly Statements Error',
    props<{
        message: string
    }>()
)

export const DownloadMonthlyStatement = createAction(
    '[Course Exchange Monthly Billing Summary] - Download Monthly Statement',
    props<{
        monthlyStatement: MonthlyStatement;
    }>()
)

export const MonthlyEarningStatementUpdated = createAction(
    '[Course Exchange Monthly Billing Realtime] - Monthly Earning Statement Updated',
    props<{
        monthlyEarningsStatement: MonthlyEarningsStatement
    }>()
)

export const MonthlyEarningStatementAdded = createAction(
    '[Course Exchange Monthly Billing Realtime] - Monthly Earning Statement Added',
    props<{
        monthlyEarningsStatement: MonthlyEarningsStatement
    }>()
)

export const ExportCourseExchangeBillingReport = createAction(
    '[Course Exchange Billing] - Export Course Exchange Billing Report',
    props<{
        year: number,
        month: number
    }>()
)

export const ExportMemberDetailedReport = createAction(
    '[Course Exchange Billing] - Export Member Detailed Report',
    props<{
        year: number,
        month: number
    }>()
)

export const ExportProviderDetailedReport = createAction(
    '[Course Exchange Billing] - Export Provider Detailed Report',
    props<{
        year: number,
        month: number
    }>()
)

export const ShowCourseExchangeBillingReportDialog = createAction(
    '[Course Exchange Billing] - Show Course Exchnage Billing Report Dialog',
    props<{
        year: number,
        month: number
    }>()
)