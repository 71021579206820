import {NgModule} from '@angular/core';

import {SuperAdminDashboardNavComponent} from './super-admin-dashboard-nav.component';
import {MaterialModule} from 'app/core/material/material.module';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../../../../../shared/shared.module';
import {AvatarSharedModule} from '@shared/avatar/avatar-shared.module';

@NgModule({
  declarations: [
    SuperAdminDashboardNavComponent
  ],
  imports: [
    MaterialModule,
    RouterModule,
    SharedModule,
    AvatarSharedModule
  ],
  exports: [
    SuperAdminDashboardNavComponent
  ]
})
export class SuperAdminDashboardNavModule {
}
