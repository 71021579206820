import {NgModule} from '@angular/core';
import {ExchangeAdminDashboardNavComponent} from 'app/layout/components/navbar/vertical/exchange-admin-dashboard-nav/exchange-admin-dashboard-nav.component';
import {RouterModule} from '@angular/router';
import {MaterialModule} from 'app/core/material/material.module';
import {CourseExchangeSharedModule} from 'app/shared/courseExchange/courseExchange-shared.module';
import {AvatarSharedModule} from '@shared/avatar/avatar-shared.module';

@NgModule({
  declarations: [
    ExchangeAdminDashboardNavComponent
  ],
  imports: [
    MaterialModule,
    RouterModule,
    CourseExchangeSharedModule,
    AvatarSharedModule
  ],
  exports: [
    ExchangeAdminDashboardNavComponent
  ]
})
export class ExchangeAdminDashboardNavModule {
}
