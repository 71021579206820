import {createReducer, on} from '@ngrx/store';

import {initCourseCalendarViewModelSorts} from '../helpers/init-course-calendar-view-model-sorts';
import * as fromCourseSchedule from '@provider/course-schedule/store';
import { ICourseSchedule } from '@provider/models/course-schedule.models';
import { EAlphabetSort } from 'app/constants/shared';
import { ECourseScheduleTypeCreateActions } from '@provider/constants';
import { CourseCalendarViewModel } from '@shared/provider/models';
import { stat } from 'fs';

export interface ICourseScheduleState {
  dataNewSchedule: ICourseSchedule.DataNewSchedule;
  dataCopySchedule: ICourseSchedule.DataCopySchedule;
  dataChangeSchedule: ICourseSchedule.DataChangeSchedule;
  typeCreateActions: ECourseScheduleTypeCreateActions | null;
  courseCalendarViewModel: CourseCalendarViewModel | null;
  isLoadingCourseCalendar: string;
  wasEdit: boolean;
  courseCalendarViewModelSorts: ICourseSchedule.CourseCalendarViewModelSorts;
}

export const initialCourseScheduleState: ICourseScheduleState = {
  dataNewSchedule: {
    academicYearId: null,
    semesterId: null,
    providerCourseIds: new Array<string>()
  },
  dataChangeSchedule: {
    academicYearId: null,
    semesterId: null,
    providerCourseIds: new Array<string>()
  },
  dataCopySchedule: {
    fromAcademicYearId: null,
    fromSemesterId: null,
    toAcademicYearId: null,
    toSemesterId: null,
    providerCourseIds: new Array<string>()
  },
  typeCreateActions: null,
  courseCalendarViewModel: null,
  isLoadingCourseCalendar: '',
  wasEdit: false,
  courseCalendarViewModelSorts: initCourseCalendarViewModelSorts()
};

export const courseScheduleReducer = createReducer(
  initialCourseScheduleState,
  on(fromCourseSchedule.Actions.changeSelectedAcademicYear, (state, {payload}) => {
    const dataNewSchedule = {...state.dataNewSchedule, academicYearId: payload, semesterId: null};
    return {
      ...state,
      dataNewSchedule
    }
  }),
  on(fromCourseSchedule.Actions.changeSelectedSemester, (state, {payload}) => {
    return {
      ...state,
      dataNewSchedule: {
        ...state.dataNewSchedule,
        semesterId: payload
      }
    }
  }),
  on(fromCourseSchedule.Actions.changeTypeCreateAction, (state, {payload}) => {
    return {
      ...state,
      typeCreateActions: payload
    }
  }),
  on(fromCourseSchedule.Actions.changeSelectedFromAcademicYear, (state, {payload}) => {
    const newDataCopySchedule: ICourseSchedule.DataCopySchedule = {
      ...state.dataCopySchedule,
      fromAcademicYearId: payload,
      fromSemesterId: null
    };

    return {
      ...state,
      dataCopySchedule: newDataCopySchedule
    }
  }),
  on(fromCourseSchedule.Actions.changeSelectedFromSemester, (state, {payload}) => {
    const newDataCopySchedule: ICourseSchedule.DataCopySchedule = {
      ...state.dataCopySchedule,
      fromSemesterId: payload
    };

    return {
      ...state,
      dataCopySchedule: newDataCopySchedule
    }
  }),
  on(fromCourseSchedule.Actions.changeSelectedToAcademicYear, (state, {payload}) => {
    const newDataCopySchedule: ICourseSchedule.DataCopySchedule = {
      ...state.dataCopySchedule,
      toAcademicYearId: payload,
      toSemesterId: null
    };

    return {
      ...state,
      dataCopySchedule: newDataCopySchedule
    }
  }),
  on(fromCourseSchedule.Actions.changeSelectedToSemester, (state, {payload}) => {
    const newDataCopySchedule: ICourseSchedule.DataCopySchedule = {
      ...state.dataCopySchedule,
      toSemesterId: payload
    };

    return {
      ...state,
      dataCopySchedule: newDataCopySchedule
    }
  }),
  on(fromCourseSchedule.Actions.CopyCourseCalendar, (state) => {
    return {
      ...state,
      isLoadingCourseCalendar: 'Copying'
    }
  }),
  on(fromCourseSchedule.Actions.GetCourseCalendar, (state) => {
    return {
      ...state,
      isLoadingCourseCalendar: 'Loading'
    }
  }),
  on(fromCourseSchedule.Actions.fetchCourseCalendarViewModelSuccess, (state, {payload}) => {
    return {
      ...state,
      courseCalendarViewModel: payload,
      courseCalendarViewModelSorts: initCourseCalendarViewModelSorts(),
      wasEdit: false,
      isLoadingCourseCalendar: ''
    }
  }),
  on(fromCourseSchedule.Actions.changeSelectedChangeAcademicYear, (state, {payload}) => {
    return {
      ...state,
      dataChangeSchedule: {
        ...state.dataChangeSchedule,
        academicYearId: payload,
        semesterId: null
      }
    }
  }),
  on(fromCourseSchedule.Actions.changeSelectedProviderCourses, (state, {payload})=>{
    const newDataNewSchedule: ICourseSchedule.DataNewSchedule = {
      ...state.dataNewSchedule,
      providerCourseIds: payload
    };
    const newDataChangeSchedule: ICourseSchedule.DataChangeSchedule = {
      ...state.dataChangeSchedule,
      providerCourseIds: payload
    };
    const newDataCopySchedule: ICourseSchedule.DataCopySchedule = {
      ...state.dataCopySchedule,
      providerCourseIds: payload
    };

    return {
      ...state,
      dataChangeSchedule: newDataChangeSchedule,
      dataNewSchedule: newDataNewSchedule,
      dataCopySchedule: newDataCopySchedule
    }
  }),
  on(fromCourseSchedule.Actions.changeSelectedChangeSemester, (state, {payload}) => {
    return {
      ...state,
      dataChangeSchedule: {
        ...state.dataChangeSchedule,
        semesterId: payload
      }
    }
  }),
  on(fromCourseSchedule.Actions.selectAllInSession, (state, {payload}) => {
    const newItems = state.courseCalendarViewModel.items.map((item => {
      const newSession = item.sessions.map((session) => {
        if (session.sessionId === payload.sessionId) {
          return {
            ...session,
            isSelected: payload.checked
          }
        }

        return session;
      });

      return {
        ...item,
        sessions: newSession
      }
    }));

    return {
      ...state,
      wasEdit: true,
      courseCalendarViewModel: {
        ...state.courseCalendarViewModel,
        items: newItems
      }
    }
  }),
  on(fromCourseSchedule.Actions.selectSessionInProviderCourse, (state, {payload}) => {
    const newItems = state.courseCalendarViewModel.items.map((item => {
      if (item.providerCourseId === payload.providerCourseId) {
        const newSession = item.sessions.map((session) => {
          if (session.sessionId === payload.sessionId) {
            return {
              ...session,
              isSelected: session.activeEnrollments==0 ? payload.checked : true
            }
          }

          return session;
        });

        return {
          ...item,
          sessions: newSession
        }
      }

      return item;
    }));

    return {
      ...state,
      wasEdit: true,
      courseCalendarViewModel: {
        ...state.courseCalendarViewModel,
        items: newItems
      }
    }
  }),
  on(fromCourseSchedule.Actions.setSearchValue, (state, {payload}) => {
    return {
      ...state,
      courseCalendarViewModelSorts: {
        ...state.courseCalendarViewModelSorts,
        searchValue: payload
      }
    }
  }),
  on(fromCourseSchedule.Actions.setSortByCode, (state, {payload}) => {
    return {
      ...state,
      courseCalendarViewModelSorts: {
        ...state.courseCalendarViewModelSorts,
        sortByCode: payload,
        sortByTitle: EAlphabetSort.ascending,
        currentSortType: 'code' as ICourseSchedule.CurrentSortType
      }
    }
  }),
  on(fromCourseSchedule.Actions.setSortByTitle, (state, {payload}) => {
    return {
      ...state,
      courseCalendarViewModelSorts: {
        ...state.courseCalendarViewModelSorts,
        sortByCode: EAlphabetSort.ascending,
        sortByTitle: payload,
        currentSortType: 'title' as ICourseSchedule.CurrentSortType
      }
    }
  })
);