import { InstitutionSummary } from 'app/shared/institution/models';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

export interface SelectInstitutionDialogData{
    institutions: Array<InstitutionSummary>;
}
@Component({
    selector: 'select-institution',
    templateUrl: 'select-institution.component.html',
  })
  export class SelectInstitutionComponent implements OnInit {
    dialogTitle: string;
    selectedInstitution: string = '';
    constructor(@Inject(MAT_DIALOG_DATA) public data: SelectInstitutionDialogData,
                public dialogRef: MatDialogRef<SelectInstitutionComponent>){}

    ngOnInit(): void{
      
    }

    selectInstitution(item: any): void
    {
      var institution = item.value;
      this.selectedInstitution = institution;
    }
    
    cancel(): void{
      this.dialogRef.close();
    }
    
    ok(): void
    {
      if(this.selectedInstitution =='') return;
      this.dialogRef.close(this.selectedInstitution);
    }
  }
