import { createAction, props } from '@ngrx/store'
import { MemberCourseListItem } from '@member/memberCourse/models'


export const MemberCourseDetail = createAction(
  '[Member Course Detail] - Go to Member Course Detail',
  props<{
    currentMemberCourseListItem: MemberCourseListItem
  }>()
)
export const CompareCourses = createAction(
  '[Member Course List] - Compare Courses'
)
