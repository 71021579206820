<div class="wizard-step wizard-bank-info-step">
  <div class="title">Add Bank Information</div>
  <div class="description">Please download the bank information template to provide directions for payment options.
  </div>

  <div (click)="downloadBankInfoTemplate.emit()" class="file-action file-action-link">
    <span class="file-action-icon">
      <mat-icon>vertical_align_bottom</mat-icon>
    </span>
    <span class="file-action-text">Download Bank Information Template</span>
  </div>

  <div class="description">
    <p>Please upload completed bank information template.*</p>
    <p class="alert"><strong>*Bank information must be in PDF</strong></p>
  </div>

  <div class="file-actions">
    <div [ngClass]="['file-action', bankInfo?.status === FileStatus.Uploaded ? 'file-action-link' : 'is_disabled']"
         (click)="onDownloadBankInfo()">
      <span class="file-action-icon file-action-icon-simple">
        <mat-icon *ngIf="bankInfo?.status === FileStatus.Uploaded" class="success">check</mat-icon>
        <mat-icon *ngIf="bankInfo?.status !== FileStatus.Uploaded" class="error">error</mat-icon>
      </span>
      <span class="file-action-text">Bank Information</span>
    </div>

    <input #fileInput
           hidden="true"
           type="file"
           onclick="this.value=null"
           (change)="fileInputChange($event)"
           accept="application/pdf"/>

    <div [ngClass]="['file-action', bankInfo?.status === FileStatus.Uploaded ? 'is_active' : '']"
         (click)="fileInput.click()">
    <span class="file-action-icon">
      <mat-icon>vertical_align_top</mat-icon>
    </span>
      <span class="file-action-text">Upload Bank Information</span>
    </div>
  </div>



  <div class="form-actions-continue">
    <span class="skip" (click)="onSkip()">skip this step</span>
  </div>

  <div class="actions">
    <button mat-stroked-button
            (click)="goBack()">
      Back
    </button>

    <button mat-flat-button
            *ngIf="showNextStep"
            (click)="onNextStep()"
            type="submit">
      Next
    </button>
  </div>
</div>
