import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromProviderCourses from '@provider/courses/store';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature('provider/courses', fromProviderCourses.providerCoursesReducer),
    EffectsModule.forFeature([...fromProviderCourses.EFFECTS])
  ],
  providers: [...fromProviderCourses.RESOLVERS]
})
export class ProviderCoursesStoreModule { }
