import {Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter, SimpleChanges} from '@angular/core';
import {Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import {IAppState} from '../../../../store/state/app.state';
import {IControlsConfig} from '../../../../models/shared';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {hasErrorForm} from '../../../../helpers/has-error-form';
import {validateStartEndDates} from '../../../../helpers/validate-start-end-dates';
import {fillControlsConfig} from '../../../../helpers/fill-controls-config';
import {Session, Semester, SessionDateRules} from '../../../calendar/models';
import {ChecklistItemType} from '../../../../core/models/checklistItemType.enum';
import {MatDialog} from '@angular/material/dialog';
import { AddSessionCommand, EditSessionCommand } from '@shared/calendar/commands';
import { WizardSkipDialogContainerComponent, IWizardSkipDialogData } from '../wizard-skip-dialog-container/wizard-skip-dialog-container.component';
import { NavigationType } from '@shared/wizard/constants/navigateType.enum';
import * as moment from 'moment';
import { checkDateInRange } from 'app/helpers/check-date-in-range';
import { Moment } from 'moment';
import { DateRule } from '@shared/calendar/models/dateRule.model';
import { BeforeAfter } from '@shared/calendar/models/beforeAfter.enum';
import {v4 as uuidv4} from 'uuid';

type IFormFields = Pick<Session,
  'code' |
  'startDate' |
  'endDate' |
  'registrationStartDate' |
  'registrationDeadlineDate' |
  'feeEarnedDate' |
  'withdrawalDeadlineDate' |
  'dropDeadlineDate'>;

type ILocalControlsConfig = IControlsConfig<IFormFields>;

@Component({
  selector: 'wizard-calendar-step-add-session',
  templateUrl: './wizard-calendar-step-add-session.component.html',
  styleUrls: ['../../styles/common-wizard-step.scss', './wizard-calendar-step-add-session.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WizardCalendarStepAddSessionComponent implements OnInit {
  private ngDestroyed$: Subject<any>;

  form: UntypedFormGroup;
  @Input() session: Session;
  additionalSession: Session;
  @Input() institutionId: string;
  @Input() semester: Semester;
  @Input() semesterId: string;
  @Input() academicYearId: string;
  @Input() sessionDateRules: SessionDateRules[];
  @Output() addSession: EventEmitter<AddSessionCommand> = new EventEmitter<AddSessionCommand>();
  @Output() editSession: EventEmitter<EditSessionCommand> = new EventEmitter<EditSessionCommand>();
  @Output() navigate: EventEmitter<string> = new EventEmitter<string>();
  isSending: boolean;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store<IAppState>,
    public dialog: MatDialog
  ) {
    this.ngDestroyed$ = new Subject();
    this.refreshForm();
  }

  ngOnInit(): void {

    //this.refreshForm();
  }

  refreshForm(){
    this.form = this.formBuilder.group(this.shapeControlsConfig(), {
      validators: this.checkDates
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.session && changes.session.currentValue) {
      this.refreshForm();
    }
  }

  private checkDates = (group: UntypedFormGroup): void => {
    const {endDate: {value: endDate}, startDate: {value: startDate}} = group.controls;
    var checkResultEndDateInRangeSemester = false;
    var checkResultStartDateInRangeSemester = false;
    var checkResult = false;
    if (this.semester) {
      const {startDate: minDate, endDate: maxDate} = this.semester;

      checkResultStartDateInRangeSemester = this.validateStartDateInRangeSemester(minDate, maxDate, startDate);
      checkResultEndDateInRangeSemester = this.validateEndDateInRangeSemester(minDate, maxDate, endDate);

      if (checkResultStartDateInRangeSemester && checkResultEndDateInRangeSemester) {
        checkResult = validateStartEndDates(startDate, endDate);
        if (this.form) {
          const errorsForEndDate = checkResult ? null : {lessStartDate: true};
          this.form.get('endDate').setErrors(errorsForEndDate);
        }
      }
    } else {
      checkResult = validateStartEndDates(startDate, endDate);
      if (this.form) {
        const errorsForEndDate = checkResult ? null : {lessStartDate: true};
        this.form.get('endDate').setErrors(errorsForEndDate);
      }
    }
  }

  public startDateChanged(startDate: Date): void {
    if (this.sessionDateRules.length <= 0 || !this.form) return;
    var rules = this.sessionDateRules[0];
    var registrationDeadline = this.calculateDate(startDate, rules.registrationDeadline);
    var dropDeadline = this.calculateDate(startDate, rules.dropDeadline);
    var feeEarned = this.calculateDate(startDate, rules.feeEarned);

    this.form.patchValue({
      registrationDeadlineDate: registrationDeadline,
      dropDeadlineDate: dropDeadline,
      feeEarnedDate: feeEarned,
    });
  }

  private calculateDate(dateToCompare: Date, dateRule: DateRule): Date {
    var daysToChange = dateRule.beforeAfter === BeforeAfter.Before ? -1 * dateRule.numberOfDays : dateRule.numberOfDays;

    return moment(dateToCompare).add("days", daysToChange).toDate();
  }

  private validateStartDateInRangeSemester(minDate: Date, maxDate: Date, date: Moment | null): boolean {
    const checkResult = checkDateInRange(minDate, maxDate, date);

    const errors = checkResult ? null : {startDateNotWithin: true};
    this.form.get('startDate').setErrors(errors);

    return checkResult;
  }

  private validateEndDateInRangeSemester(minDate: Date, maxDate: Date, date: moment.Moment | null): boolean {
    const checkResult = checkDateInRange(minDate, maxDate, date);

    const errors = checkResult ? null : {endDateNotWithin: true};

    this.form.get('endDate').setErrors(errors);

    return checkResult;
  }

  private shapeControlsConfig(): ILocalControlsConfig {
    const currentSession = this.additionalSession ? this.additionalSession : this.session;
    const initialControlsConfig: ILocalControlsConfig = {
      code: ['', Validators.required],
      startDate: [null],
      endDate: [null],
      registrationStartDate: [null],
      registrationDeadlineDate: [null],
      dropDeadlineDate: [null],
      feeEarnedDate: [null],
      withdrawalDeadlineDate: [null]
    };

    if (currentSession) {
      const session: any = currentSession;

      return fillControlsConfig<IFormFields>(initialControlsConfig, {
        ...currentSession,
        registrationStartDate: session.registrationStartDate,
        registrationDeadlineDate: session.registrationDeadlineDate,
        dropDeadlineDate: session.dropDeadlineDate,
        feeEarnedDate: session.feeEarnedDate,
        withdrawalDeadlineDate: session.withdrawalDeadlineDate
      });
    } else {
      return initialControlsConfig;
    }
  }

  hasError = (controlName: keyof IFormFields, errorName: string) => hasErrorForm(controlName, errorName, this.form);

  onKeyDownForm(event): void {
    if(event.keyCode === 13) {
      this.form.valid ? this.onSubmit(true) : event.preventDefault();
    }
  }

  onSubmit = (toNextStep: boolean) => {
    if (this.form.valid) {
      const currentSession = this.additionalSession ? this.additionalSession : this.session;
      const { code, startDate, endDate, registrationStartDate, registrationDeadlineDate, dropDeadlineDate, feeEarnedDate, withdrawalDeadlineDate } = this.form.value;
      const id = this.session ? this.session.id : uuidv4();
      const academicYearId = this.session ? this.session.academicYearId : this.academicYearId;
      const semesterId = this.session ? this.session.semesterId : this.semesterId;
      if(this.session == null){
        var addCommand: AddSessionCommand ={
          id,
          semesterId,
          academicYearId,
          code,
          startDate,
          endDate,
          registrationStartDate,
          registrationDeadlineDate,
          dropDeadlineDate,
          feeEarnedDate,
          withdrawalDeadlineDate
        }
        this.addSession.emit(addCommand);
      }else{
        var editCommand: EditSessionCommand ={
          id,
          semesterId,
          code,
          startDate,
          endDate,
          registrationStartDate,
          registrationDeadlineDate,
          dropDeadlineDate,
          feeEarnedDate,
          withdrawalDeadlineDate
        };
        this.editSession.emit(editCommand);
      }
      if(toNextStep){
        this.navigate.emit(NavigationType.Forward)
      }else{
        const startDate = moment(currentSession.endDate).add('days',1).toDate();
        const endDate = moment(currentSession.startDate).add('weeks',8).toDate();
        const registrationDeadlineDate = moment(currentSession.registrationDeadlineDate).add('weeks',8).toDate();
        const registrationStartDate = moment(currentSession.registrationStartDate).add('weeks',8).toDate();
        const dropDeadlineDate = moment(currentSession.dropDeadlineDate).add('weeks',8).toDate();
        const feeEarnedDate = moment(currentSession.feeEarnedDate).add('weeks',8).toDate();
        const withdrawalDeadlineDate = moment(currentSession.withdrawalDeadlineDate).add('weeks',8).toDate();
        this.additionalSession = {
          ...currentSession,
          startDate,
          endDate,
          registrationDeadlineDate,
          registrationStartDate,
          dropDeadlineDate,
          feeEarnedDate,
          withdrawalDeadlineDate
        };
        this.refreshForm();
      }
    }
  };

  goBack = () => {
    this.navigate.emit(NavigationType.Back);
  }

  skip = () => {
    this.dialog.open<WizardSkipDialogContainerComponent, IWizardSkipDialogData>(WizardSkipDialogContainerComponent, {
      data: {
        itemType: ChecklistItemType.Calendar_AddSessions,
        toNextStepCb: this.toNextStep
      }
    });
  }

  toNextStep = () => {
    this.navigate.emit(NavigationType.Skip);

  }
}
