<div class="past-start-date">
  <mat-toolbar>
    <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
      <span class="title dialog-title">Course has Already Started</span>
    </mat-toolbar-row>
  </mat-toolbar>
  
  <div class="content">
    <div class="sub-title sub-title-top"><b>{{getCourseDescription(memberCourse)}}</b> has already started. Do you wish to continue with your enrollment request?</div>
  
    <div class="actions">
      <button mat-raised-button color="primary" (click)="selectMemberCourse(memberCourse)">
        <mat-icon>close</mat-icon>
        Yes, Submit Enrollment Request
      </button>
  
      <button mat-raised-button (click)="selectMemberCourse(nextMemberCourse)" *ngIf="!!nextMemberCourse">
        <mat-icon>call_made</mat-icon>
        No, Enroll Next Scheduled {{getFormattedDate(nextMemberCourse)}}
      </button>
  
      <button mat-raised-button color="warn" (click)="selectMemberCourse(null)">
        <mat-icon>cancel</mat-icon>
        Cancel
      </button>
    </div>
  </div>  
</div>
