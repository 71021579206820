import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export class ConfirmDialogData 
{
  cancelButtonText?: string;
  showCancelButton?: boolean;
  okButtonText?: string;
  title?: string;
  message?: string;
}
@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
  cancelButtonText: string = 'No';
  okButtonText: string = 'Yes';
  title: string = 'Confirm Action';
  message: string = 'Are you sure?';
  showCancelButton: boolean = true;
  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>, 
    @Inject(MAT_DIALOG_DATA) public data:ConfirmDialogData) 
  {
    if(!(data.cancelButtonText==null || data.cancelButtonText == undefined))
      this.cancelButtonText = data.cancelButtonText;
    if(!(data.okButtonText==null || data.okButtonText == undefined))
      this.okButtonText = data.okButtonText;
    if(!(data.title==null || data.title == undefined))
      this.title = data.title;
    if(!(data.message==null || data.message == undefined))
      this.message = data.message;
    if(!(data.showCancelButton==null || data.showCancelButton==undefined))
      this.showCancelButton = data.showCancelButton;
  }

  onConfirm(): void
  {
    this.dialogRef.close(true);
  }

  onDismiss(): void
  {
    this.dialogRef.close(false);
  }

}
