<!-- LIST PAGE -->
<div id="courseListPage" class="page-layout carded fullwidth inner-scroll list-page member-admin-page course-detail" *ngIf="(memberCourse$ | async) as memberCourse">

  <!-- TOP BACKGROUND -->
  <div class="top-bg provider-admin-top-bg"></div>
  <!-- / TOP BACKGROUND -->

  <!-- CENTER -->
  <div class="center">

    <!-- FORM PAGE HEADER -->
    <div class="header list-page-header provider-admin-header" fxLayout="row" fxLayoutAlign="space-between center">


      <!-- LIST PAGE TITLE -->
      <div class="header-title-container" fxLayout="row" fxLayoutAlign="start center">

        <button mat-icon-button class="mr-0 mr-sm-16 header-return-icon" (click)="goBack()">
          <mat-icon>arrow_back</mat-icon>
        </button>

        <div class="header-title" fxLayout="column" fxLayoutAlign="start start">
          <div class="h2">
            {{ pageTitle }}
          </div>
          <div class="subtitle secondary-text">
            <span>{{pageSubTitle}}</span>
          </div>
        </div>

      </div>
      <!-- / LIST PAGE TITLE -->

      <div class="header-nav-container" fxLayout="column" fxLayoutAlign="start center">
        <div class="header-navigation" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="16px">
          <button class="header-nav-link"
                  [disabled]="isSending"
                  *ngIf="!memberCourse.isFavourited"
                  (click)="preSelectCourse(memberCourse)"
                  mat-button>
            <mat-icon>start</mat-icon>Pre-Select Course
          </button>

          <button class="header-nav-link"
                  *ngIf="memberCourse.isFavourited"
                  (click)="confirmDeselectCourse(memberCourse)"
                  mat-button>
            <mat-icon>start</mat-icon>Remove Course
          </button>

          <button class="header-nav-link" mat-button>
            <mat-icon>person_add</mat-icon>Enroll Student
          </button>

          <button class="header-nav-link" mat-button (click)="goToMemberDashboard()">
            <mat-icon>dashboard</mat-icon>Return to Dashboard
          </button>
        </div>

      </div>


    </div>
    <!-- / LIST HEADER -->

    <!-- CONTENT CARD -->
    <div class="content-card">

      <ng-container>
        <div class="content">
          <div class="part part-left">
            <div class="top-panel">
              <div class="course-code">{{memberCourse.code}}</div>
              <div class="course-title-box">
                <div class="course-title-box-text">{{memberCourse.title}}</div>
                <div class="course-title-box-icon">
                  <mat-icon *ngIf="memberCourse.isFavourited" (click)="confirmDeselectCourse(memberCourse)">star</mat-icon>
                  <mat-icon *ngIf="!memberCourse.isFavourited" (click)="preSelectCourse(memberCourse)">star_border</mat-icon>
                  <mat-button *ngIf="memberCourse.isFavourited" (click)="manageMemberCourseCodes(memberCourse)">Manage Course Codes</mat-button>
                </div>
              </div>
            </div>

            <div class="additional-info">
              <div class="additional-info-item">
                <div class="icon">
                  <mat-icon>account_balance</mat-icon>
                </div>
                <div class="text">
                  {{memberCourse.level.description}}
                </div>
              </div>

              <div class="additional-info-item">
                <div class="icon">
                  <mat-icon>hourglass_full</mat-icon>
                </div>
                <div class="text">
                  {{memberCourse.creditHours2}} {{getCreditHourType(memberCourse.creditHourType)}} Credit Hours
                </div>
              </div>
            </div>

            <div class="requirements">
              <div class="title">Course Requirements:</div>
              <div class="info">{{memberCourse.requirements ? memberCourse.requirements : 'None'}}</div>
            </div>

            <div class="read-more-box">
              <div class="title">
                <mat-icon>edit</mat-icon> <span>Course Description:</span>
              </div>
              <div readMore class="read-more" [maxHeight]="75">
                <div class="read-more-text">{{memberCourse.description}}</div>
                <span class="read-more-action" (click)="onReadMore(memberCourse)">... Read More</span>
              </div>
            </div>

            <div class="notes">
              <div class="title">
                <mat-icon>edit</mat-icon> <span>Notes:</span>
              </div>
              <div class="info">{{memberCourse.notes ? memberCourse.notes : 'None'}}</div>
            </div>
            
          </div>
          <div class="part part-right">
            <div class="top-panel">
              <div class="course-logo-wrap">
                <div class="course-logo">
                  <img src="{{memberCourse.logoUrl}}">
                </div>
              </div>
            </div>

            <div class="additional-info">
              <div class="additional-info-item">
                <div class="icon">
                  <mat-icon>widgets</mat-icon>
                </div>
                <div class="text">
                  {{memberCourse.category.description}}
                </div>
              </div>

              <div class="additional-info-item">
                <div class="icon">
                  <mat-icon>view_comfy</mat-icon>
                </div>
                <div class="text">
                  {{memberCourse.subCategory.description}}
                </div>
              </div>
            </div>

            <div class="tags">
              <div class="title">Course Tags:</div>

              <ng-container *ngIf="memberCourse.tags.length; else tagsStub">
                <div class="chips">
                  <mat-chip-list>
                    <mat-chip *ngFor="let tag of memberCourse.tags">
                      {{tag.description}}
                    </mat-chip>
                  </mat-chip-list>
                </div>
              </ng-container>

              <ng-template #tagsStub>
                <div class="stub">None.</div>
              </ng-template>
            </div>

            <div class="sessions">
              <div class="title">
                <mat-icon>calendar_today</mat-icon> <span>Session Dates:</span>
              </div>

              <ng-container *ngIf="(memberCourseSessions$ | async)?.length; else stubSession">
                <app-course-sessions [sessions]="memberCourseSessions$ | async"></app-course-sessions>
              </ng-container>

              <ng-template #stubSession>
                <div class="stub">
                  None
                </div>
              </ng-template>
            </div>

            <div class="files" *ngIf="(facultyCVs$ |async) as facultyCVs">
              <div class="title">
                <mat-icon>insert_drive_file</mat-icon> <span>Faculty CVs:</span>
              </div>
              <ng-container *ngIf="facultyCVs.length; else stubFacultyCVs">
                <div *ngFor="let item of facultyCVs">
                    <div class="syllabus file-item" >
                      <div class="text" (click)="downloadFacultyCV(item)">{{item.description}}</div>
                  </div>
                </div>
              </ng-container>

              <ng-template #stubFacultyCVs>
                <div class="stub">
                  None
                </div>
              </ng-template>
            </div>
            
            <div class="files">
              <div class="title">
                <mat-icon>insert_drive_file</mat-icon> <span>Syllabus:</span>
              </div>
              <ng-container *ngIf="memberCourse.syllabus && memberCourse.syllabus.status == 'Uploaded'; else stubSyllabus">
                <div class="syllabus file-item"
                  (click)="onSyllabus(memberCourse)">
                  <div class="text">{{memberCourse.syllabus.fileName}}</div>
                </div>
              </ng-container>

              <ng-template #stubSyllabus>
                <div class="stub">
                  None
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <!-- / CONTENT CARD -->

  </div>
  <!-- / CENTER -->

</div>
