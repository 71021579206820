import {Component, Inject, ViewEncapsulation} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

export interface StudentResultActionDialogData {
  title: string;
  description: string;
  actions: [
    {
      name: string;
      cb?: () => any;
    }
  ]
}

@Component({
  selector: 'student-result-action-dialog',
  templateUrl: './student-result-action-dialog.component.html',
  styleUrls: ['./student-result-action-dialog.component..scss'],
  encapsulation: ViewEncapsulation.None
})
export class StudentResultActionDialogComponent {
  constructor(
    public matDialogRef: MatDialogRef<StudentResultActionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public matDialogData: StudentResultActionDialogData,
  ) {
    this.matDialogRef.addPanelClass('student-result-action-dialog');
  }

  close(): void {
    this.matDialogRef.close();
  }
}
