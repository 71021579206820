import {Directive, Input} from '@angular/core';
import {AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors} from '@angular/forms';
import {Observable, of, timer} from 'rxjs';
import {UserService} from '@shared/user/services';
import {catchError, map, switchMap, take} from 'rxjs/operators';

@Directive({
  selector: '[uniqueEmail]',
  providers: [{provide: NG_ASYNC_VALIDATORS, useExisting: UniqueEmailValidatorDirective, multi: true}]
})
export class UniqueEmailValidatorDirective implements AsyncValidator {
  @Input() private userId: string;
  @Input() private initialValue: string;

  constructor(private userService: UserService) {
  }

  validate = (control: AbstractControl): Observable<ValidationErrors | null> => {
    return timer(300).pipe(
      take(1),
      switchMap(() => {
        return this.userService.emailExists(this.userId, control.value)
      }),
      catchError(() => {
        return of(false);
      }),
      map((isExist) => {
        return (this.initialValue !== control.value) && isExist ? {emailExist: true} : null;
      })
    )
  }
}
