import {Component, Input, Output, EventEmitter} from '@angular/core';
import {Store} from '@ngrx/store';
import {IAppState} from '../../../../store/state/app.state';
import { ChecklistItemType } from '@core/models/checklistItemType.enum';
import { IStepperConfig, IWizardChecklistForView, Step } from '@shared/wizard/models';
import { Checklist } from '@core/models';

@Component({
  selector: 'wizard-steps',
  templateUrl: './wizard-steps.component.html',
  styleUrls: ['./wizard-steps.component.scss']
})
export class WizardStepsComponent  {
  @Input() currentBaseStep: ChecklistItemType;
  @Input() checklist: IWizardChecklistForView;
  @Input() stepperConfig: IStepperConfig;
  @Output() navigate: EventEmitter<string> = new EventEmitter<string>();

  constructor(private store: Store<IAppState>) {
  }

  isStepActive(step: Step): boolean{
    if(step.key != ChecklistItemType.Fees) return true;
    const feeStep = !!this.checklist ? this.checklist.items.find(x=>x.itemType===step.key):false;
    return !!feeStep ? !!feeStep.items.length : false;
  }

  isStepComplete(step: Step): boolean{
    if(step.key===ChecklistItemType.Root) return !!this.checklist ? this.checklist.isComplete : false;
    const item = !!this.checklist ? this.checklist.items.find(x=>x.itemType===step.key): false;
    return !!item ? item.isComplete : false;
  }

  isStepCurrent(step:Step): boolean{
    return this.currentBaseStep === step.key;
  }

  onNavigate(type: ChecklistItemType): void{
    var navigateItem = '';
    switch(type){
      case ChecklistItemType.ContactAndUserSetup:
        navigateItem = ChecklistItemType.ContactAndUserSetup_Accounting;
        break;
      case ChecklistItemType.Calendar:
        navigateItem = ChecklistItemType.Calendar_AddYear;
        break;
      case ChecklistItemType.CourseInformation:
        navigateItem = ChecklistItemType.CourseInformation_Levels;
        break;
      case ChecklistItemType.Fees:
        const feeItem = this.checklist.items.find(x=>x.itemType===type);
        const fee = feeItem.items[0];
        navigateItem = `${ChecklistItemType.Fees_Item}_${fee.identifier}`;
        break;
      default:
        navigateItem = type;
    }
    this.navigate.emit(navigateItem);
  }
}
