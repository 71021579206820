import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {IControlsConfig} from '../../../../models/shared';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {hasErrorForm} from '../../../../helpers/has-error-form';
import {UserListItem} from '../../../user/models';
import {MatDialog} from '@angular/material/dialog';
import {ChecklistItemType} from '../../../../core/models/checklistItemType.enum';
import { InstitutionRole } from '@core/referenceData';
import { AddUserCommand } from '@shared/user/commands';
import { WizardSkipDialogContainerComponent, IWizardSkipDialogData } from '../wizard-skip-dialog-container/wizard-skip-dialog-container.component';
import { NavigationType } from '@shared/wizard/constants/navigateType.enum';
import {v4 as uuidv4} from 'uuid';

type IFormFields = Pick<UserListItem, 'department' | 'title' | 'firstName' | 'lastName' | 'phoneNumber' | 'emailAddress' | 'institutionRole'>;

type ILocalControlsConfig = IControlsConfig<IFormFields>;

@Component({
  selector: 'wizard-contacts-step-add-user',
  templateUrl: './wizard-contacts-step-add-user.component.html',
  styleUrls: ['../../styles/common-wizard-step.scss', './wizard-contacts-step-add-user.component.scss']
})
export class WizardContactsStepAddUserComponent implements OnInit {

  form: UntypedFormGroup;
  isSending = false;

  @Input()institutionId: string;
  @Input()institutionRoles: InstitutionRole[];
  @Output() addUser: EventEmitter<AddUserCommand> = new EventEmitter<AddUserCommand>();
  @Output() navigate: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog
  ) {
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group(this.shapeControlsConfig());
  }

  private shapeControlsConfig(): ILocalControlsConfig {
    const initialControlsConfig: ILocalControlsConfig = {
      department: ['', Validators.required],
      title: ['', Validators.required],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      emailAddress: ['', Validators.required],
      institutionRole: ['Provost', Validators.required]
    };

    return initialControlsConfig;
  }

  getUserId(): string {
    return '';
  }

  hasError = (controlName: keyof IFormFields, errorName: string) => hasErrorForm(controlName, errorName, this.form);

  onKeyDownForm(event): void {
    if(event.keyCode === 13) {
      this.form.valid ? this.onSubmit() : event.preventDefault();
    }
  }

  onSubmit = () => {
    this.saveUser(true);
  }

  saveUser = (toNextStep: boolean) =>{
    if (this.form.valid) {
      this.isSending = true;
      const institutionId = this.institutionId;
      const {department, title, firstName, lastName, phoneNumber, emailAddress, institutionRole} = this.form.value;
      var addCommand: AddUserCommand ={
        id: uuidv4(),
        institutionId,
        department,
        title,
        firstName,
        lastName,
        middleName: '',
        emailAddress,
        phoneNumber,
        institutionRole
      }
      this.addUser.emit(addCommand);
      if(toNextStep){
        this.navigate.emit(NavigationType.Forward)
      }
    }
  }

  onAddUser = () => {
    this.saveUser(false);
    this.form = this.formBuilder.group(this.shapeControlsConfig());
  }

  goBack = () => {
    this.navigate.emit(ChecklistItemType.ContactAndUserSetup_ProvostOrVicePresident)
  }

  skip = () => {
    this.dialog.open<WizardSkipDialogContainerComponent, IWizardSkipDialogData>(WizardSkipDialogContainerComponent, {
      data: {
        itemType: ChecklistItemType.ContactAndUserSetup_AdditionalUsers,
        toNextStepCb: this.toNextStep
      }
    });
  }

  toNextStep = () => {
    this.navigate.emit(NavigationType.Skip);
  }
}
