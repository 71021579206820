<mat-toolbar>
  <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <span class="title dialog-title">Remove Pre-Selected Member Courses</span>
    <button mat-icon-button (click)="onCancel()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div class="content">
  <div class="title">Remove?</div>

  <div class="description">
    <p>You have chosen to remove Course Name from your pre-selected member courses.</p>

    <p>Is this correct?</p>
  </div>

  <div class="actions">
    <div class="button">
      <button mat-raised-button color="warn" (click)="onCancel()">
        <mat-icon>not_interested</mat-icon>
        No, Exit
      </button>
    </div>

    <div class="button">
      <button mat-raised-button (click)="onConfirm()">
        <mat-icon>check</mat-icon>
        Yes, Remove Course
      </button>
    </div>
  </div>
</div>
