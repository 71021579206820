import {Component, OnInit, Input, EventEmitter, Output} from '@angular/core';
import { Checklist } from '@core/models';

@Component({
  selector: 'wizard-home-step',
  templateUrl: './wizard-home-step.component.html',
  styleUrls: ['./wizard-home-step.component.scss']
})
export class WizardHomeStepComponent implements OnInit {

  @Input() userName: string;
  @Input()institutionName: string;
  @Input() checklist: Checklist;
  @Output() navigate: EventEmitter<string> = new EventEmitter<string>();
  constructor(
  ) {
  }

  ngOnInit(): void {
  }
}
