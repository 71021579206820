import { createAction, props } from '@ngrx/store';
import { CourseExchange, CourseExchangeFeeListItem, CourseExchangePolicy, CourseExchangeInstitutionListItem, CourseExchangeSummary } from 'app/shared/courseExchange/models';
import { AddCourseExchangeFeeCommand, EditCourseExchangeFeeCommand } from '@shared/courseExchange/commands';
import { ElbertFile } from '@core/models';
import { CourseExchangeInstitutionsWidgetViewModel } from '@courseExchange/dashboard/widgets/courseExchangeInstitutions-widget/courseExchangeInstitutions-widget.viewModel';
import { Member } from '@shared/member/models';
import { Provider } from '@shared/provider/models';
import { UserListItem } from '@shared/user/models';
import { UpdateCourseExchangeCommand } from '@admin/course-exchange/commands';
import { UploadCourseExchangeCommandLogo } from '@admin/course-exchange/commands/uploadCourseExchangeLogo.command';
import { CourseExchangeCoursesWidgetViewModel } from '@courseExchange/dashboard/widgets/courseExchangeCourses-widget/courseExchangeCourses-widget.viewModel';
import { InstitutionSummary } from '@shared/institution/models';
import { LogoType } from '@core/logoType.enum';
import { UpdateCourseExchangeCourseLevelsCommand } from '@shared/courseExchange/courses-data/commands/updateCourseExchangeCourseLevels.command';

export const LoadCourseExchange = createAction(
    '[Course Exchange Resolver] - Load Course Exchange',
    props<{
        courseExchangeId: string;
    }>()
);

export const LoadCourseExchangeSuccess = createAction(
    '[Course Exchange Effect] - Load Course Exchange Success',
    props<{
        courseExchange: CourseExchange
    }>()
)

export const LoadCourseExchangeSummary = createAction(
    '[Course Exchange Resolver] - Load Course Exchange Summary',
    props<{
        courseExchangeId: string;
    }>()
);

export const LoadCourseExchangeSummarySuccess = createAction(
    '[Course Exchange Effect] - Load Course Exchange Summary Success',
    props<{
        courseExchangeSummary: CourseExchangeSummary
    }>()
)
export const LoadCourseExchangeInstitutionsWidget = createAction(
    '[Course Exchange Institutions Widget Resolver] - Load Course Exchange Widget Resolver',
    props<{
        courseExchangeId: string;
    }>()
)

export const LoadCourseExchangeInstitutionsWidgetSuccess = createAction(
    '[Course Exchange Effects] - Load Course Exchange Widget Success',
    props<{
        institutionsWidgetData: CourseExchangeInstitutionsWidgetViewModel;
    }>()
)

export const LoadCourseExchangeCoursesWidget = createAction(
    '[Course Exchange Courses Widget Resolver] - Load Course Exchange Courses Widget Resolver',
    props<{
        courseExchangeId: string;
    }>()
)

export const LoadCourseExchangeCoursesWidgetSuccess = createAction(
    '[Course Exchange Effects] - Load Course Exchange Courses Widget Success',
    props<{
        coursesWidgetData: CourseExchangeCoursesWidgetViewModel;
    }>()
)

export const LoadFees = createAction(
    '[Fees Resolver] - Load Fees',
    props<{
        courseExchangeId: string;
    }>()
);

export const LoadFeesSuccess = createAction(
    '[Course Exchange Effect] - Load Course Exchange Fees Success',
    props<{
        fees: CourseExchangeFeeListItem[]
    }>()
)

export const LoadPolicies = createAction(
    '[Course Exchange Policy Templates Resolver] - Load Policy Templates',
    props<{
        courseExchangeId: string;
    }>()
);

export const LoadPoliciesSuccess = createAction(
    '[Course Exchange Effect] - Load Course Exchange Policy templates Success',
    props<{
        policies: CourseExchangePolicy[]
    }>()
)

export const DownloadCourseExchangePolicy = createAction(
    '[Various] - Download Course Exchange Policy',
    props<{
        courseExchangePolicy: CourseExchangePolicy
    }>()
)

export const UploadCourseExchangePolicy = createAction(
    '[Course Exchange Policy List] - Upload Course Exchange Policy',
    props<{
        courseExchangePolicyId: string,

        file: File
    }>()
)

export const UploadCourseExchangePolicySuccess = createAction(
    '[Course Exchange Effects] - Upload Course Exchange Policy Success',
    props<{
        courseExchangePolicy: CourseExchangePolicy
    }>()
)

export const UploadCourseExchangePolicyError = createAction(
  '[Course Exchange Effects] - Upload Course Exchange Policy Error',
  props<{
    courseExchangePolicyId: string;
    message: string;
  }>()
)

export const LoadCourseExchangeInstitutions = createAction(
    '[CourseExangeInstitutions Resolver] - Load CourseExchange Institutions',
    props<{
        courseExchangeId: string
    }>()
)

export const LoadCourseExchangeInstitutionsSuccess = createAction(
    '[CourseExange Effects] - Load CourseExchange Institutions Success',
    props<{
        institutions: CourseExchangeInstitutionListItem[]
    }>()
)

export const AddCourseCategories = createAction(
    '[Course Data] - Add Course Categories',
    props<{
        courseCategoryIds: string[]
    }>()
)

export const AddCourseCategoriesSuccess = createAction(
    '[Course Exchange Effects] - Add Course Categories Success',
    props<{
        courseCategoryIds: string[]
    }>()
)

export const RemoveCourseCategories = createAction(
    '[Course Data] - Remove Course Categories',
    props<{
        courseCategoryIds: string[]
    }>()
)

export const RemoveCourseCategoriesSuccess = createAction(
    '[Course Exchange Effects] - Remove Course Categories Success',
    props<{
        courseCategoryIds: string[]
    }>()
)


export const AddCourseSubCategories = createAction(
    '[Course Data] - Add Course SubCategories',
    props<{
        courseSubCategoryIds: string[]
    }>()
)

export const AddCourseSubCategoriesSuccess = createAction(
    '[Course Exchange Effects] - Add Course SubCategories Success',
    props<{
        courseSubCategoryIds: string[]
    }>()
)


export const RemoveCourseSubCategories = createAction(
    '[Course Data] - Remove Course Sub Categories',
    props<{
        courseSubCategoryIds: string[]
    }>()
)

export const RemoveCourseSubCategoriesSuccess = createAction(
    '[Course Exchange Effects] - Remove Course Sub Categories Success',
    props<{
        courseSubCategoryIds: string[]
    }>()
)

export const AddCourseLevels = createAction(
    '[Course Data] - Add Course Levels',
    props<{
        courseLevelIds: string[]
    }>()
)

export const AddCourseLevelsSuccess = createAction(
    '[Course Exchange Effects] - Add Course Levels Success',
    props<{
        courseLevelIds: string[]
    }>()
)


export const RemoveCourseLevels = createAction(
    '[Course Data] - Remove Course Levels',
    props<{
        courseLevelIds: string[]
    }>()
)

export const RemoveCourseLevelsSuccess = createAction(
    '[Course Exchange Effects] - Remove Course Levels Success',
    props<{
        courseLevelIds: string[]
    }>()
)
export const UpdateCourseLevels = createAction(
    '[Course Data] - Update Course Levels',
    props<{
        courseLevelIds: string[]
    }>()
)

export const AddCourseTags = createAction(
    '[Course Data] - Add Course Tags',
    props<{
        courseTagIds: string[]
    }>()
)

export const AddCourseTagsSuccess = createAction(
    '[Course Exchange Effects] - Add Course Tags Success',
    props<{
        courseTagIds: string[]
    }>()
)


export const RemoveCourseTags = createAction(
    '[Course Data] - Remove Course Tags',
    props<{
        courseTagIds: string[]
    }>()
)

export const RemoveCourseTagsSuccess = createAction(
    '[Course Exchange Effects] - Remove Course Tags Success',
    props<{
        courseTagIds: string[]
    }>()
)

export const AddCourseExchangeFee = createAction(
    '[Course Exchange Fee List] - Add Course Exchange Fee',
    props<{
        command: AddCourseExchangeFeeCommand
    }>()
)

export const UpdateCourseExchangeFee = createAction(
    '[Course Exchange Fee List] - Update Course Exchange Fee',
    props<{
        command: EditCourseExchangeFeeCommand
    }>()
)

export const EditCourseExchangeFeeSuccess = createAction(
    '[Course Exchange Effects] - Edit Course Exchange Fee Success',
    props<{
        fee: CourseExchangeFeeListItem
    }>()
)

export const LoadManagingInstitution = createAction(
    '[Managing Instiution Resolver] - Load Managing Institution',
    props<{
        courseExchangeId: string
    }>()
)

export const CompleteCourseExchangeSetup = createAction(
    '[CourseExchange Wizard Policies Step] - Complete CourseExchange Setup'
);

export const CompleteCourseExchangeSetupSuccess = createAction(
    '[CourseExchange Effect] - CompleteCourseExchangeSetupSuccess',
    props<{
        courseExchange: CourseExchange
    }>()
)

export const UpdateBankInfo = createAction(
    '[Bank Info Step] - Update Bank Info',
    props<{
        file: File;
    }>()
);

export const DownloadBankInfo = createAction(
    '[Various Places] - Download Course Exchange Bank Info',
    props<{
        bankInfo:ElbertFile
    }>()
);

export const UpdateCourseExchange = createAction(
    '[Course Exchange Profile] - Update Course Exchange',
    props<{
        command: UpdateCourseExchangeCommand
    }>()
);

export const UpdateCourseExchangeSuccess = createAction(
    '[Course Exchange Effects] - Update Course Exchange Success',
    props<{
        courseExchange: CourseExchange
    }>()
);

export const UploadCourseExchangeLogo = createAction(
    '[Course Exchange Profile] - Upload Course Exchange Logo',
    props<{
        command: UploadCourseExchangeCommandLogo
    }>()
)

export const UploadCourseExchangeLogoSuccess = createAction(
    '[Course Exchange Profile] - Upload Course Exchange Logo Success',
    props<{
        logoType: LogoType
    }>()
)

export const UploadCourseExchangeLogoError = createAction(
    '[Course Exchange Profile] - Upload Course Exchange Logo Error',
    props<{
        logoType: LogoType
    }>()
)

export const Wizard = createAction(
    '[Navigation] - Course Exchange Wizard',
    props<{
        courseExchangeId: string;
        institutionId: string;
    }>()
)

export const ViewCoursesData = createAction(
    '[Navigation] - Course Exchange Data Lists',
    props<{
        courseExchangeId?: string,
        institutionId?: string;
    }>()
)

export const ViewFees = createAction(
    '[Navigation] - Course Exchange View Fees',
    props<{
        courseExchangeId?: string,
        institutionId?: string;
    }>()
)

export const ViewCalendars = createAction(
    '[Navigation] - Course Exchange View Calendars',
    props<{
        courseExchangeId?: string,
        institutionId?: string;
    }>()
)

export const ViewEnrollments = createAction(
    '[Navigation] - Course Exchange View Enrollments',
    props<{
        courseExchangeId?: string,
        institutionId?: string;
    }>()
)

export const ViewDashboard = createAction(
    '[Navigation] - Course Exchange View Dashboard',
    props<{
        courseExchangeId?: string,
        institutionId?: string;
    }>()
)

export const ApproveMember = createAction(
    '[Member Readonly Wizard] - Approve Member',
    props<{
        memberId:string
    }>()
)

export const ApproveMemberSuccess = createAction(
    '[Course Exchange Effects] - Approve Member Success',
    props<{
        member: Member
    }>()
)

export const ApproveProvider = createAction(
    '[Provider Readonly Wizard] - Approve Provider',
    props<{
        providerId: string
    }>()
)

export const ApproveBothMemberAndProvider = createAction(
    '[Provider and Member Wizard] - Approve Both Member and Provider',
    props<{
        courseExchangeId: string,
        memberId: string,
        providerId: string
    }>()
)

export const ApproveBothMemberAndProviderSuccess = createAction(
    '[Provider and Member Wizard] - Approve Both Member and Provider',
    props<{
        member: Member,
        provider: Provider
    }>()
)

export const LoadUsers = createAction(
    '[Course Exchange Users Resolver] - Load Users',
    props<{
        courseExchangeId: string
    }>()
)

export const LoadUsersSuccess = createAction(
    '[Course Exchange Effects] - Load Users',
    props<{
        users: UserListItem[]
    }>()
)

export const LoadInstitutionsSuccess = createAction(
    '[Course Exchange Effects] - Load Institutions',
    props<{
        institutions: InstitutionSummary[]
    }>()
)

export const ClearState = createAction(
    '[Course Exchange Reducer] - Clear state'
)

export const NotifyNotComplete = createAction(
    '[Course Exchange Wizard] - Notify Not Complete'
)

export const CourseExchangeUpdated = createAction(
    '[Course Exchange Realtime] - Course Exchange Updated',
    props<{
        courseExchange: CourseExchange
    }>()
)

export const CourseExchangeSummaryUpdated = createAction(
    '[Course Exchange Realtime] - Course ExchangeSummary Updated',
    props<{
        courseExchangeSummary: CourseExchangeSummary
    }>()
)

export const CourseExchangePolicyUpdated = createAction(
    '[Course Exchange Realtime] - Course Exchange Policy Updated',
    props<{
        courseExchangePolicy: CourseExchangePolicy
    }>()
)

export const CourseExchangeFeesUpdated = createAction(
    '[Course Exchange Realtime] - Course Exchange Fees Updated',
    props<{
        fees: CourseExchangeFeeListItem[]
    }>()
)
