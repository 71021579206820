<div class="dialog-content-wrapper">
  <mat-toolbar>
      <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
          <span class="title dialog-title">{{ title }}</span>
          <button mat-icon-button (click)="matDialogRef.close()" aria-label="Close dialog">
              <mat-icon>close</mat-icon>
          </button>
      </mat-toolbar-row>
  </mat-toolbar>

  <div mat-dialog-content class="p-24 m-0" fusePerfectScrollbar>
    <div *ngIf="payload.entity === 'academicYear'">
      <app-academic-year-form [payload]="payload"
                              (onClose)="matDialogRef.close()"
                              (onChangePayload)="onChangePayload($event)"
      ></app-academic-year-form>
    </div>

    <div *ngIf="payload.entity === 'semester'">
      <app-semester-form [payload]="payload"
                         (onClose)="matDialogRef.close()"
                         (onChangePayload)="onChangePayload($event)"
      ></app-semester-form>
    </div>

    <div *ngIf="payload.entity === 'session'">
      <app-session-form [payload]="payload"
                        [sessionDateRules]="sessionDateRules$ | async"
                        (onClose)="matDialogRef.close()"
      ></app-session-form>
    </div>
  </div>
</div>
