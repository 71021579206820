import { courseSubCategories } from './../../../../core/referenceData/store/refData.selectors';
import { ExcelService } from './../../../../core/services/excel.service';
import { createEffect, Actions, ofType } from "@ngrx/effects";
import { Injectable } from "@angular/core";
import { map, switchMap, withLatestFrom, concatMap, tap, catchError } from "rxjs/operators";
import { select, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { IAppState } from 'app/store/state/app.state';
import * as fromCore from '@core/store';
import * as fromCourseExchange from '@courseExchange/store';
import * as fromEnrollment from '@courseExchange/enrollment/store';
import * as fromInstitution from '@institution/store';
import { MatDialog } from '@angular/material/dialog';
import { CourseExchangeService } from '@shared/courseExchange/services/course-exchange.service';
import { UpdateEnrollmentFeeComponent, UpdateEnrollmentFeeData } from '@courseExchange/enrollment/components/update-enrollment-fee/update-enrollment-fee-detail.component';
import { UpdateEnrollmentFeeCommand } from '@courseExchange/enrollment/models/updateEnrollmentFee.command';
import { EnrollmentListItem } from "@member/enrollment";
import { FilterType } from "@shared/common/models";
import * as fromRefData from '@refData';
import { AcademicYear } from "@shared/calendar/models";
import { RealTimeService } from "@core/services/realTime.service";
import * as moment from "moment";
import { ExcelColumn } from "@core/services/excel.service";
@Injectable()
export class EnrollmentEffects {
  private currentAcademicYear$: Observable<AcademicYear>;
  constructor(private actions$: Actions, private store: Store<IAppState>, private dialog: MatDialog,
    private courseExchangeService: CourseExchangeService, private realTimeService:RealTimeService, private excelService:ExcelService) {
      this.initSignalRMessages();
      this.currentAcademicYear$ = this.store.pipe(select(fromInstitution.Selectors.CurrentAcademicYear));
  }

  private initSignalRMessages(){
    this.realTimeService.hubConnection$.subscribe(hubConnection=>{
      if(!hubConnection) return;
      hubConnection.on('ProviderEnrollmentListItemUpdated', (enrollment: EnrollmentListItem) => {
        this.store.dispatch(fromEnrollment.Actions.EnrollmentListItemUpdated({enrollment}));
      });
      hubConnection.on('ProviderEnrollmentListItemAdded', (enrollment: EnrollmentListItem)=>{
        of(enrollment)
          .pipe(
            withLatestFrom(this.currentAcademicYear$),
            map(([enrollment, academicYear])=>{
              if(moment(enrollment.sessionStartDate).isBetween(academicYear.startDate,academicYear.endDate,undefined,"[]")){
                this.store.dispatch(fromEnrollment.Actions.EnrollmentListItemAdded({enrollment}));
              }
            })
          ).subscribe();
      })
    })
  }

  loadEnrollments$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromEnrollment.Actions.LoadActiveEnrollments), concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromCourseExchange.Selectors.CourseExchangeId)))
      )),
      switchMap(([action, currentCourseExchangeId]) => {
        const courseExchangeId = !!action.courseExchangeId ? action.courseExchangeId : currentCourseExchangeId;
        return this.courseExchangeService.getActiveEnrollments(courseExchangeId);
      }),
      map((response) => {
        const enrollments = response.map(x => {
          return {
            ...x,
            selected: false,
            requestedStatus: null,
            letterGradeDescription: !!x.letterGrade ? x.letterGrade.description : "" 
          }
        });
        return fromEnrollment.Actions.LoadActiveEnrollmentsSuccess({ enrollments })
      })
    )
  });

  editEnrollmentFee$ = createEffect(() => {
    return this.actions$.pipe(
        ofType(fromEnrollment.Actions.EditEnrollmentFee),
        /** An EMPTY observable only emits completion. Replace with your own observable stream */
        tap(({enrollment}) =>{
          var dialogResponse = this.dialog.open<UpdateEnrollmentFeeComponent,UpdateEnrollmentFeeData>
          (UpdateEnrollmentFeeComponent, {
            data: {
              enrollment
            }
          });
          
          dialogResponse.afterClosed().subscribe((command:UpdateEnrollmentFeeCommand)=>{
            if(command){
              this.store.dispatch(fromEnrollment.Actions.SubmitEnrollmentFeeChanges({command}));
            }
          });
        })
    );
  },{dispatch:false});


  submitEnrollmentFeeChanges$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromEnrollment.Actions.SubmitEnrollmentFeeChanges),
      concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromCourseExchange.Selectors.CourseExchangeId)))
      )),
      switchMap(([action, courseExchangeId]) => this.courseExchangeService.updateEnrollmentFee(courseExchangeId,action.command)),
      map((response) => fromCore.Actions.HandleResponse({response}))
    )
  });

  masterToggle$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromEnrollment.Actions.MasterToggle),
      concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromEnrollment.Selectors.FilteredEnrollments)))
      )),
      map(([action, enrollments]) => {
        const isAllSelected = enrollments.every(x => x.selected);
        return enrollments.map(x => {
          return {
            ...x,
            selected: !isAllSelected
          }
        })
      }),
      map((enrollments) => fromEnrollment.Actions.MasterToggled({ enrollments }))
    )
  });

  loadEnrollmentsByDateRange$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromEnrollment.Actions.LoadEnrollmentByDateRange), concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromCourseExchange.Selectors.DateRangeArgs)))
      )),
      switchMap(([action, dateArgs]) => {
        const courseExchangeId = !!action.courseExchangeId ? action.courseExchangeId : dateArgs.courseExchangeId;
        const startDate = dateArgs.startDate;
        const endDate = dateArgs.endDate;

        if(courseExchangeId==='') return of(new Array<EnrollmentListItem>());
        return this.courseExchangeService.getEnrollmentByDateRange(courseExchangeId, startDate,endDate);
      }),
      map((response) => {
        const enrollments = response.map(x => {
          return {
            ...x,
            selected: false,
            requestedStatus: null,
            letterGradeDescription: !!x.letterGrade ? x.letterGrade.description : "zz" 
          }
        });
        return fromEnrollment.Actions.LoadActiveEnrollmentsSuccess({ enrollments })
      }),
      catchError((error) =>{
        console.log(error);
        return of(fromEnrollment.Actions.LoadActiveEnrollmentsError(
          {message: 'Problem retrieving Enrollments'}
        ));
      })
    )
  });

  loadProviderFilters$ = createEffect(() => {
    return this.actions$.pipe(
        ofType(fromCourseExchange.Actions.LoadCourseExchangeSummarySuccess),
        /** An EMPTY observable only emits completion. Replace with your own observable stream */
        map(({courseExchangeSummary}) => {
          const providerFilters = courseExchangeSummary.providers.map(provider => {
            return {
              id: provider.id,
              type: FilterType.Provider,
              description: !!provider ? provider.description : '',
              descriptionForView: !!provider ? provider.description : '',
              selected: false
            }
          });
          return fromEnrollment.Actions.LoadProviderFilters({providerFilters});
        })
    );
  });
  
  loadMemberFilters$ = createEffect(() => {
    return this.actions$.pipe(
        ofType(fromCourseExchange.Actions.LoadCourseExchangeSummarySuccess),
        /** An EMPTY observable only emits completion. Replace with your own observable stream */
        map(({courseExchangeSummary}) => {
          const memberFilters = courseExchangeSummary.members.map(members => {
            return {
              id: members.id,
              type: FilterType.Member,
              description: !!members ? members.description : '',
              descriptionForView: !!members ? members.description : '',
              selected: false
            }
          });
          return fromEnrollment.Actions.LoadMemberFilters({memberFilters});
        })
    );
  });

  loadTagFilters$ = createEffect(() => {
    return this.actions$.pipe(
        ofType(fromCourseExchange.Actions.LoadCourseExchangeSummarySuccess),
        concatMap((action) => of(action).pipe(
          withLatestFrom(this.store.pipe(select(fromRefData.courseTags)))
        )),
        /** An EMPTY observable only emits completion. Replace with your own observable stream */
        map(([{courseExchangeSummary}, tags]) => {
          const tagFilters = courseExchangeSummary.courseTags.map(id => {
            const tag = tags.find(x => x.id === id);
            return {
              id: id,
              type: FilterType.Tag,
              description: !!tag ? tag.description : '',
              descriptionForView: !!tag ? tag.description : '',
              selected: false
            }
          });
          return fromEnrollment.Actions.LoadTagFilters({tagFilters});
        })
    );
  });

  loadCategoryFilters$ = createEffect(() => {
    return this.actions$.pipe(
        ofType(fromCourseExchange.Actions.LoadCourseExchangeSummarySuccess),
        concatMap((action) => of(action).pipe(
          withLatestFrom(this.store.pipe(select(fromRefData.courseCategories)))
        )),
        /** An EMPTY observable only emits completion. Replace with your own observable stream */
        map(([{courseExchangeSummary}, categories]) => {
          const courseCategoryFilters = courseExchangeSummary.courseCategories.map(id => {
            const category = categories.find(x => x.id === id);
            return {
              id: id,
              type: FilterType.CourseCategory,
              description: !!category ? category.description : '',
              descriptionForView: !!category ? category.description : '',
              selected: false
            }
          });
          return fromEnrollment.Actions.LoadCategoryFilters({courseCategoryFilters});
        })
    );
  });

  loadSubCategoryFilters$ = createEffect(() => {
    return this.actions$.pipe(
        ofType(fromCourseExchange.Actions.LoadCourseExchangeSummarySuccess),
        concatMap((action) => of(action).pipe(
          withLatestFrom(this.store.pipe(select(fromRefData.courseSubCategories)))
        )),
        /** An EMPTY observable only emits completion. Replace with your own observable stream */
        map(([{courseExchangeSummary}, subCategories]) => {
          const courseSubCategoryFilters = courseExchangeSummary.courseSubCategories.map(id => {
            const subCategory = subCategories.find(x => x.id === id);
            return {
              id: id,
              type: FilterType.CourseSubCategory,
              description: !!subCategory ? subCategory.description : '',
              descriptionForView: !!subCategory ? subCategory.description : '',
              selected: false
            }
          });
          return fromEnrollment.Actions.LoadSubCategoryFilters({courseSubCategoryFilters});
        })
    );
  });

  submitChanges$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromEnrollment.Actions.SubmitStatusChanges),
      concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromEnrollment.Selectors.RequestedChangesForEffects)))
      )),
      tap(([, {command, enrollmentStatuses }]) => this.store.dispatch(fromEnrollment.Actions.ChangeStatuses({command,enrollmentStatuses}))),
      switchMap(([, { courseExchangeId, command }]) => this.courseExchangeService.submitStatusChanges(courseExchangeId, command)),
      map((response) => {
        const message = response.message;
        if (response.isSuccess) {
          this.store.dispatch(fromEnrollment.Actions.SubmitStatusChangesSuccess());
        }
        else{
          this.store.dispatch(fromEnrollment.Actions.SubmitStatusChangesError({ message }));
        }
        return fromCore.Actions.HandleResponse({ response });
      })
  )});

  exportToExcel$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromEnrollment.Actions.ExportToExcel),
      concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromEnrollment.Selectors.FilteredEnrollments)))
      )),
      tap(([, enrollments]) => {
        if (enrollments.some(x => x.selected)) {
          enrollments = [...enrollments.filter(x => x.selected)];
        }
        var result = enrollments.map(enrollment => {
          const instructors = enrollment.cvFile.map(x=>x.description);
          return {
            memberName: enrollment.memberName,
            memberStudentId: enrollment.studentNumber,
            studentType: enrollment.studentType,
            studentFirstName: enrollment.studentFullName.firstName,
            studentLastName: enrollment.studentFullName.lastName,
            providerName: enrollment.providerName,
            sessionStartEnd: `${enrollment.sessionStartDate2.month}/${enrollment.sessionStartDate2.day}/${enrollment.sessionStartDate2.year}-${enrollment.sessionEndDate2.month}/${enrollment.sessionEndDate2.day}/${enrollment.sessionEndDate2.year}`,
            courseCode: enrollment.providerCourseCode,
            courseTitle: enrollment.courseTitle,
            courseCategory: enrollment.category? enrollment.category.description : '',
            courseSubCategory: enrollment.subCategory ? enrollment.subCategory.description: '',
            credits: enrollment.creditHours,
            cvFile: instructors.join(', '),
            status: enrollment.status.statusType,
            letterGrade: !!enrollment.letterGrade ? enrollment.letterGrade.description : "",
            addressLine1: enrollment.studentAddress.addressLine1,
            addressLine2: enrollment.studentAddress.addressLine2,
            city: enrollment.studentAddress.city,
            region: !!enrollment.studentAddress.region ? enrollment.studentAddress.region.description: "",
            postalCode: enrollment.studentAddress.postalCode,
            phoneNumber: enrollment.studentPhoneNumber,
            emailAddress1: enrollment.studentEmailAddress,
            emailAddress2: enrollment.studentEmailAddress2,
            dateOfBirth: moment(enrollment.studentDateOfBirth).format("l"),
            gender: enrollment.studentGender
          };
        });

        var columns: ExcelColumn[] = new Array<ExcelColumn>(
          {header: 'Member', fieldName: "memberName"},
          {header: 'Member Student ID', fieldName: 'memberStudentId'},
          {header: 'Student Type', fieldName: 'studentType'},
          {header: 'Student First', fieldName: 'studentFirstName'},
          {header: 'Student Last', fieldName: 'studentLastName'},
          {header: 'Provider', fieldName: 'providerName'},
          {header: 'Session Start & End Dates', fieldName: 'sessionStartEnd'},
          {header: 'Course Code', fieldName: 'courseCode'},
          {header: 'Course Title', fieldName: 'courseTitle'},
          {header: 'Course Category', fieldName: 'courseCategory'},
          {header: 'Course SubCategory', fieldName: 'courseSubCategory'},
          {header: 'Credits', fieldName: 'credits'},
          {header: 'Instructor', fieldName: 'cvFile'},
          {header: 'Status', fieldName: 'status'},
          {header: 'Letter Grade', fieldName: 'letterGrade'},
          {header: 'Address 1', fieldName: 'addressLine1'},
          {header: 'Address 2', fieldName: 'addressLine2'},
          {header: 'City', fieldName: 'city'},
          {header: 'State', fieldName: 'region'},
          {header: 'Zip Code', fieldName: 'postalCode'},
          {header: 'Phone', fieldName: 'phoneNumber'},
          {header: 'Email 1', fieldName: 'emailAddress1'},
          {header: 'Email 2', fieldName: 'emailAddress2'},
          {header: 'Date of Birth', fieldName: 'dateOfBirth'},
          {header: 'Gender', fieldName: 'gender'}
        );
      
        
        this.excelService.exportAsExcelFile(result, columns, `Enrollments - ${moment().format('YYYY-MM-DD')}`);
      })
    )
  }, {dispatch: false});

}
