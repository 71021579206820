import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthenticationService } from './services/authentication.service';
import { IAppState } from 'app/store/state/app.state';
import { Store, select } from '@ngrx/store';
import { isLoggedIn } from './store/auth.selectors';
import * as fromAuth from '@auth/store';
@Injectable({
  providedIn: 'root'
})
export class AuthorizeGuard implements CanActivate {
  constructor(private store: Store<IAppState>, private authService: AuthenticationService) {
  }
  canActivate(
    _next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      return this.store.pipe(
        select(isLoggedIn),
        tap(isAuthenticated => this.handleAuthorization(isAuthenticated, state))
      );
  }

  private handleAuthorization(isAuthenticated: boolean, state: RouterStateSnapshot) {
    if (!isAuthenticated) {
      this.store.dispatch(fromAuth.Actions.navigateToLogin({returnUrl: state.url}));
    }
  }
}
