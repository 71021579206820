import {Injectable} from '@angular/core';
import {Actions, ofType, createEffect} from '@ngrx/effects';
import { of } from 'rxjs';
import * as fromMemberDashboard from '@member/dashboard/store';
import * as fromMember from '@member/store';
import { MemberService } from '@shared/member/services';
import { concatMap, withLatestFrom, tap } from 'rxjs/operators';
import { IAppState } from 'app/store/state/app.state';
import { Store, select } from '@ngrx/store';
import { Router } from '@angular/router';

@Injectable()
export class MemberDashboardNavigateEffects {
  constructor(private actions$: Actions,private store:Store<IAppState>, private router: Router) 
  {
  }

  memberDashboard$ = createEffect(() => {
    return this.actions$.pipe(
            ofType(fromMemberDashboard.Navigate.Dashboard),
            concatMap((action) => of(action).pipe(
              withLatestFrom(this.store.pipe(select(fromMember.Selectors.Member)))
            )),
            /** An EMPTY observable only emits completion. Replace with your own observable stream */
            tap(([action,currentMember]) => {
              const memberId = !!action.memberId ? action.memberId : currentMember.id;
              const institutionId = !!action.institutionId ? action.institutionId : currentMember.institutionId;
              this.router.navigate([`/member/${memberId}/${institutionId}/dashboard`]);
            })
      );
},{dispatch:false});
}