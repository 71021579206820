import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {sortBy} from 'lodash';
import {StudentListItem} from '@shared/student/models';

type AccessKeys = (keyof StudentListItem)[];

export interface TableScheme {
  title: string;
  accessKeys: AccessKeys;
  sortFlag: boolean;
}

@Component({
  selector: 'enrollment-students-info',
  templateUrl: './enrollment-students-info.component.html',
  styleUrls: ['./enrollment-students-info.component.scss'],
})
export class EnrollmentStudentsInfoComponent implements OnInit, OnDestroy {
  @Input() students: StudentListItem[];

  tableScheme: TableScheme[] = [
    {
      title: 'Student ID',
      accessKeys: ['id'],
      sortFlag: false
    },
    {
      title: 'Name (Last, First)',
      accessKeys: ['firstName', 'lastName'],
      sortFlag: false
    }
  ]

  sortedStudents$: BehaviorSubject<StudentListItem[]> = new BehaviorSubject<StudentListItem[]>([]);

  constructor() {
  }

  ngOnInit(): void {
    this.sortedStudents$.next(this.students);
  }

  ngOnDestroy(): void {
    this.sortedStudents$.complete();
  }

  sortByText(item: TableScheme): void {
    const newSortedList = sortBy(this.sortedStudents$.value, ...item.accessKeys)

    this.sortedStudents$.next(
      !item.sortFlag ? newSortedList : newSortedList.reverse()
    )

    this.updateSortFlag(item);
  }

  private updateSortFlag(item: TableScheme): void {
    this.tableScheme = this.tableScheme.map(scheme => {
      if (item.title === scheme.title) {
        return {
          ...scheme,
          sortFlag: !scheme.sortFlag
        }
      }

      return {
        ...scheme,
        sortFlag: false
      };
    });
  }

  getValue(student: StudentListItem, accessKeys: AccessKeys): string {
    let result = '';

    accessKeys.forEach((key, index) => {
      result += student[key]

      if (index + 1 !== accessKeys.length) {
        result += ', '
      }
    })

    return result;
  }
}
