import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import * as fromComponents from './components';
import * as fromServices from './services';
import { MaterialModule } from 'app/core/material/material.module';
import { CoreModule } from '@core/core.module';
import { UserSharedModule } from '@shared/user/user-shared.module';

@NgModule({
    declarations: [...fromComponents.COMPONENTS],
    exports: [...fromComponents.COMPONENTS],
    imports: [
        CommonModule,
        CoreModule,
        UserSharedModule,
        MaterialModule,
    ],
    providers: [...fromServices.SERVICES]
})
export class InstitutionSharedModule { }
