<ng-container [ngSwitch]="accessRole?.viewContext">
    <app-super-admin-dashboard-nav *ngSwitchCase="'SuperAdmin'"
        [user]="user" class="dashboard-nav"></app-super-admin-dashboard-nav>
    <app-exchange-admin-dashboard-nav *ngSwitchCase="'CourseExchangeAdmin'"
        [courseExchangeId]="accessRole.entityId"
        [user]="user" class="dashboard-nav">
    </app-exchange-admin-dashboard-nav>
    <app-admin-member-dashboard-nav *ngSwitchCase="'Member'" 
        [memberId]="accessRole.entityId"
        [user]="user" class="dashboard-nav"></app-admin-member-dashboard-nav>
    <app-admin-provider-dashboard-nav *ngSwitchCase="'Provider'" 
            [providerId]="accessRole.entityId"
            [user]="user" class="dashboard-nav"></app-admin-provider-dashboard-nav>
    <app-institution-admin-dashboard-nav *ngSwitchCase="'Institution'" 
        [institutionId]="accessRole.entityId"
        [user]="user" class="dashboard-nav"></app-institution-admin-dashboard-nav>
</ng-container>