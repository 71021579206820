<div class="wizard-step wizard-calendar-step-add-year">
  <ng-container>
    <div class="title">
      Add Calendar: Semester
    </div>

    <form class="form" [formGroup]="form" (keydown)="onKeyDownForm($event)">
      <div class="fields">
        <div class="field">
          <mat-form-field appearance="outline" class="mt-8" fxFill>
            <mat-label>Code</mat-label>
            <input
              matInput
              name="code"
              required
              formControlName="code">
            <mat-error *ngIf="hasError('code', 'required')">This field is required</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="fields">
        <div class="field">
          <mat-form-field appearance="outline" class="mr-sm-12" fxFlex>
            <mat-label>Start Date</mat-label>
            <input matInput [matDatepicker]="startDatePicker" formControlName="startDate">
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker></mat-datepicker>
            <mat-error *ngIf="hasError('startDate', 'startDateNotWithin')">{{errorMessageValidateDatesInRangeAcademicYear('start date')}}</mat-error>
          </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field appearance="outline" class="ml-sm-12" fxFlex>
            <mat-label>End Date</mat-label>
            <input matInput [matDatepicker]="endDatePicker" formControlName="endDate" placeholder="End Date">
            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker></mat-datepicker>
            <mat-error *ngIf="hasError('endDate', 'lessStartDate')">Is less or equal than the start date</mat-error>
            <mat-error *ngIf="hasError('endDate', 'endDateNotWithin')">{{errorMessageValidateDatesInRangeAcademicYear('end date')}}</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="form-actions-continue">
        <span class="skip" (click)="skip()">skip this step</span>

        <!-- This will go into a future version
          <button mat-button
                (click)="onSubmit(false)"
                [disabled]="!form.valid"
                type="submit">
          Add Additional Semester
        </button> -->
      </div>

      <div class="form-actions">
        <button mat-stroked-button
                (click)="goBack()">
          Back
        </button>

        <button mat-flat-button
                [disabled]="!form.valid"
                (click)="onSubmit(true)"
                type="submit">
          Next
        </button>
      </div>
    </form>
  </ng-container>

  <ng-template #loading>
    ...loading
  </ng-template>
</div>
