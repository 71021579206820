

import {createFeatureSelector, createSelector} from '@ngrx/store';
import { CourseExchangeBillingState } from '../reducers';

export const selectCourseExchangeBillingState = createFeatureSelector<CourseExchangeBillingState>('courseExchange/billing');

export const CourseExchangeId = createSelector(
    selectCourseExchangeBillingState,
    (state) => state.courseExchangeId
)

export const CourseExchangeMonthlyStatements = createSelector(
    selectCourseExchangeBillingState,
    (state) => state.monthlyStatements
)

export const areCourseExchangeMonthlySummariesLoaded = createSelector(
    selectCourseExchangeBillingState,
    (state) => state.areMonthlyStatementsLoaded
)
export const isCourseExchangeMonthlySummariesLoading = createSelector(
    selectCourseExchangeBillingState,
    (state) => state.isMonthlyStatementsLoading
)

export const CurrentMonthlyPayoutStatement = createSelector(
    selectCourseExchangeBillingState,
    (state) => state.currentMonthlyStatement
)

export const IsCourseExchangeMonthlyStatementLoading = createSelector(
    selectCourseExchangeBillingState,
    (state)=> state.isMonthlyStatementsLoading
)