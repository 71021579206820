import { InstitutionService } from 'app/shared/institution/services/institution.service';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import * as fromMemberCourse from '@member/memberCourse/store';
import * as fromMember from '@member/store';
import * as fromCore from '@core/store';
import * as fromRefData from '@refData';
import * as fromInstitution from '@institution/store';
import * as fromCourseExchange from '@courseExchange/store';
import * as moment from 'moment';
import {switchMap, map, concatMap, withLatestFrom, tap, catchError} from 'rxjs/operators';
import {MemberService} from '@shared/member/services';
import {Observable, of} from 'rxjs';
import {select, Store} from '@ngrx/store';
import {IAppState} from 'app/store/state/app.state';
import {
  PreSelectCoursesCommand,
  AddInstitutionalCourseCodeCommand,
  RemoveInstitutionalCourseCodeCommand,
  DeSelectCoursesCommand,
  MemberCourse
} from '@shared/member/models';
import {AddedToPreSelectCourseDialogComponent} from '../../components';
import {MatDialog} from '@angular/material/dialog';
import {FilterType} from '@shared/common/models';
import { ExcelColumn, ExcelService } from '@core/services/excel.service';
import { MemberCourseListItem } from '@member/memberCourse/models';
import { AcademicYear } from '@shared/calendar/models';
import {v4 as uuidv4} from 'uuid';
import { CreditHourType } from '@core/models';
@Injectable()
export class MemberCourseEffects {
  currentAcademicYear$: Observable<AcademicYear>;
  constructor(
    private actions$: Actions,
    private store: Store<IAppState>,
    private memberService: MemberService,
    private dialog: MatDialog,
    private excelService: ExcelService,
    private institutionService:InstitutionService
  ) {
    this.currentAcademicYear$ = this.store.pipe(select(fromInstitution.Selectors.CurrentAcademicYear));
  }

  loadCurrentMemberCourse$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromMemberCourse.Actions.LoadCurrentMemberCourseListItem),
      switchMap(({memberId, memberCourseListItemId}) => this.memberService.getMemberCourseListItem(memberId, memberCourseListItemId)),
      map((currentMemberCourse) =>
        fromMemberCourse.Actions.LoadCurrentMemberCourseListItemSuccess({currentMemberCourseListItem: currentMemberCourse})),
      catchError(() =>
        of(fromMemberCourse.Actions.LoadCurrentMemberCourseListItemError(
          {message: 'Problem retrieving Current Member Course'}
        )))
    );
  });

  loadCurrentMemberCourseError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromMemberCourse.Actions.LoadCurrentMemberCourseListItemError),
      /** An EMPTY observable only emits completion. Replace with your own observable stream */
      map(({message}) => fromCore.Actions.ShowMessage({message})));
  });

  loadMemberCourseListItems$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromMemberCourse.Actions.LoadMemberCourseListItems),
      concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromMember.Selectors.DateRangeArgs)))
      )),
      switchMap(([action,dateRangeArgs]) => {
        const memberId = !!action.memberId ? action.memberId : dateRangeArgs.memberId;
        const startDate = dateRangeArgs.startDate;
        const endDate = dateRangeArgs.endDate;
        if(memberId==='') return of(new Array<MemberCourseListItem>());
        return this.memberService.getMemberCourseListItems(memberId, startDate, endDate);
      }),
      map((result) => {
        const memberCourses = result.map(x => {
          return {
            ...x,
            selected: false
          }
        });
        return fromMemberCourse.Actions.LoadMemberCourseListItemsSuccess({memberCourseListItems: memberCourses});
      }),
      catchError((ex) => {
        console.log(ex.message);
        return of(fromMemberCourse.Actions.LoadMemberCourseListItemsError({message: 'Problem retrieving Member Course list'}))
      })
    )
  });

  loadMemberCourses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromMemberCourse.Actions.LoadMemberCourses),
      switchMap(({memberId}) => {
        if(memberId==='') return of(new Array<MemberCourse>());
        return this.memberService.getMemberCourses(memberId);
      }),
      map((memberCourses) => {
        return fromMemberCourse.Actions.LoadMemberCoursesSuccess({memberCourses});
      }),
      catchError((ex) => {
        console.log(ex.message);
        return of(fromMemberCourse.Actions.LoadMemberCourseListItemsError({message: 'Problem retrieving Member Course list'}))
      })
    )
  });

  loadMemberCourseError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromMemberCourse.Actions.LoadMemberCourseListItemsError),
      /** An EMPTY observable only emits completion. Replace with your own observable stream */
      map(({message}) => fromCore.Actions.ShowMessage({message})));
  });

  preSelectMultipleMemberCourse$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromMemberCourse.Actions.PreSelectCourses),
      concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromMemberCourse.Selectors.SelectedNonFavouritedCoursesForEffect)))
      )),
      switchMap(([action, {selectedMemberCourseListItems, memberId}]) => {
        var providerCourseIds = !!action.providerCourseIds ? action.providerCourseIds : memberCourseListItems.map(x => x.providerCourseId);
        var memberCourseIds = providerCourseIds.map((item)=>uuidv4());
        var memberCourseListItems = new Array<MemberCourseListItem>();
        for(var i=0; i<=selectedMemberCourseListItems.length; i++){
          memberCourseListItems.push({...selectedMemberCourseListItems[i], isFavourited: true, memberCourseId: memberCourseIds[i]});
        }
        this.store.dispatch(fromMemberCourse.Actions.PreSelectCoursesSuccess({memberCourseListItems}));
        const command: PreSelectCoursesCommand = {memberId, providerCourseIds, memberCourseIds};
        return this.memberService.preSelectCourses(memberId, command);
      }),
      map((response) => fromCore.Actions.HandleResponse({response}))
    )
  });

  preSelectMultipleMemberCourseError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromMemberCourse.Actions.PreSelectCoursesError),
      /** An EMPTY observable only emits completion. Replace with your own observable stream */
      map(({message}) => fromCore.Actions.ShowMessage({message})));
  });

  deSelectMultipleMemberCourse$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromMemberCourse.Actions.DeselectCourses),
      concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromMemberCourse.Selectors.SelectedFavouritedCoursesForEffect)))
      )),
      concatMap(([action, {memberCourseListItems, memberId}]) => {
        const memberCourseIds = !!action.memberCourseIds ? action.memberCourseIds : memberCourseListItems.map(x => x.memberCourseId);
        const command: DeSelectCoursesCommand = {memberId, memberCourseIds};
        this.store.dispatch(fromMemberCourse.Actions.DeselectCoursesSuccess({memberCourseListItems}));
        return this.memberService.deSelectCourses(memberId, command)
      }),
      map((response) => fromCore.Actions.HandleResponse({response}))
    )
  });

  deSelectMultipleMemberCourseError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromMemberCourse.Actions.DeselectCoursesError),
      /** An EMPTY observable only emits completion. Replace with your own observable stream */
      map(({message}) => fromCore.Actions.ShowMessage({message})));
  });

  preSelectSingleMemberCourse$ = createEffect(() => {
    let providerCourseId = "";
    let memberCourseId = "";
    return this.actions$.pipe(
      ofType(fromMemberCourse.Actions.PreSelectSingleCourse),
      concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromMember.Selectors.MemberId)))
      )),
      switchMap(([action, memberId]) => {
        providerCourseId = action.providerCourseId;
        memberCourseId = uuidv4();
        const command: PreSelectCoursesCommand = {memberId, providerCourseIds: [providerCourseId],memberCourseIds:[memberCourseId]};
        return this.memberService.preSelectCourses(memberId, command);
      }),
      map((response) => {
        if(response.isSuccess){
          this.store.dispatch(fromMemberCourse.Actions.ManageMemberCourseCodes({providerCourseId, memberCourseId}))
        }
        return fromCore.Actions.HandleResponse({response})})
    )
  });

  preSelectSingleMemberCourseError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromMemberCourse.Actions.PreSelectSingleCourseError),
      /** An EMPTY observable only emits completion. Replace with your own observable stream */
      map(({message}) => fromCore.Actions.ShowMessage({message})));
  });

  manageInstitutionCourseCode$ = createEffect(() => {
    return this.actions$.pipe(
        ofType(fromMemberCourse.Actions.ManageMemberCourseCodes),
        /** An EMPTY observable only emits completion. Replace with your own observable stream */
        tap(() => this.dialog.open(AddedToPreSelectCourseDialogComponent,{})));
  }, {dispatch: false});

  addInstitutionCourseCode$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromMemberCourse.Actions.AddInstitutionalCode),
      concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromMember.Selectors.MemberId)))
      )),
      switchMap(([{memberCourseId, institutionalCourseCode}, memberId]) => {

        const command: AddInstitutionalCourseCodeCommand = {memberCourseId, institutionalCourseCode};
        return this.memberService.addInstitutionalCode(memberId, command);
      }),
      map((response) => fromCore.Actions.HandleResponse({response}))
    )
  });

  addInstitutionCourseCodeError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromMemberCourse.Actions.AddInstitutionalCodeError),
      /** An EMPTY observable only emits completion. Replace with your own observable stream */
      map(({message}) => fromCore.Actions.ShowMessage({message})));
  });

  removeInstitutionCourseCode$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromMemberCourse.Actions.RemoveInstitutionalCode),
      concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromMember.Selectors.MemberId)))
      )),
      switchMap(([{memberCourseId, institutionCourseCode}, memberId]) => {

        const command: RemoveInstitutionalCourseCodeCommand = {memberCourseId, institutionCourseCode};
        return this.memberService.removeInstitutionalCode(memberId, command);
      }),
      map((response) => fromCore.Actions.HandleResponse({response}))
    )
  });

  removeInstitutionCourseCodeError$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromMemberCourse.Actions.RemoveInstitutionalCodeError),
      /** An EMPTY observable only emits completion. Replace with your own observable stream */
      map(({message}) => fromCore.Actions.ShowMessage({message})));
  });

  loadCourseLevelFilters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromCourseExchange.Actions.LoadCourseExchangeSummarySuccess),
      concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromRefData.Selectors.courseLevels)))
      )),
      map(([action, courseLevels]) => {
        const courseLevelFilters = action.courseExchangeSummary.courseLevels.map(id => {
          const courseLevel = courseLevels.find(x => x.id === id);
          return {
            id: id,
            type: FilterType.CourseLevel,
            description: !!courseLevel ? courseLevel.description : '',
            descriptionForView: !!courseLevel ? courseLevel.description : '',
            selected: false
          }
        });
        return fromMemberCourse.Actions.LoadCourseLevelFilters({courseLevelFilters});
      })
    )
  });
  loadCourseCategoryFilters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromCourseExchange.Actions.LoadCourseExchangeSummarySuccess),
      concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromRefData.Selectors.courseCategories)))
      )),
      map(([action, courseCategories]) => {
        const courseCategoryFilters = action.courseExchangeSummary.courseCategories.map(id => {
          const courseCategory = courseCategories.find(x => x.id === id);
          return {
            id: id,
            type: FilterType.CourseCategory,
            description: !!courseCategory ? courseCategory.description : '',
            descriptionForView: !!courseCategory ? courseCategory.description : '',
            selected: false
          }
        });
        return fromMemberCourse.Actions.LoadCourseCategoryFilters({courseCategoryFilters});
      })
    )
  });
  loadCourseSubCategoryFilters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromCourseExchange.Actions.LoadCourseExchangeSummarySuccess),
      concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromRefData.Selectors.courseSubCategories)))
      )),
      map(([action, courseSubCategories]) => {
        const subCategoryFilters = action.courseExchangeSummary.courseSubCategories.map(id => {
          const courseSubCategory = courseSubCategories.find(x => x.id === id);
          return {
            id: id,
            type: FilterType.CourseSubCategory,
            description: !!courseSubCategory ? courseSubCategory.description : '',
            descriptionForView: !!courseSubCategory ? courseSubCategory.description : '',
            selected: false
          }
        });
        return fromMemberCourse.Actions.LoadCourseSubCategoryFilters({subCategoryFilters});
      })
    )
  });
  loadCourseTagFilters$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromCourseExchange.Actions.LoadCourseExchangeSummarySuccess),
      concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromRefData.Selectors.courseTags)))
      )),
      map(([action, courseTags]) => {
        const courseTagFilters = action.courseExchangeSummary.courseTags.map(id => {
          const courseTag = courseTags.find(x => x.id === id);
          return {
            id: id,
            type: FilterType.Tag,
            description: !!courseTag ? courseTag.description : '',
            descriptionForView: !!courseTag ? courseTag.description : '',
            selected: false
          }
        });
        return fromMemberCourse.Actions.LoadCourseTagFilters({courseTagFilters});
      })
    )
  });

  masterToggle$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(fromMemberCourse.Actions.MasterToggle),
      concatMap(action => of(action).pipe(
        withLatestFrom(this.store.pipe(select(fromMemberCourse.Selectors.FilteredMemberCourses)))
      )),
      map(([action, memberCourses]) => {
        const isAllSelected = action.selected != undefined ? !action.selected : memberCourses.every(x => x.selected);
        return memberCourses.map(x => {
          return {
            ...x,
            selected: !isAllSelected
          }
        })
      }),
      map((memberCourses) => fromMemberCourse.Actions.MasterToggled({memberCourseListItems: memberCourses}))
    )
  });

  downloadMemberCourseSyllabus$ = createEffect(() => {
    return this.actions$.pipe(
            ofType(fromMemberCourse.Actions.DownloadCourseSyllabus),
            concatMap(action => of(action).pipe(
                    withLatestFrom(this.store.pipe(select(fromMember.Selectors.MemberId)))
            )),
            tap(([{courseId,syllabus}, memberId]) => this.memberService.downloadSyllabus(memberId,courseId,syllabus))
    );
}, { dispatch: false });

exportToExcel$ = createEffect(() => {
  return this.actions$.pipe(
    ofType(fromMemberCourse.Actions.ExportToExcel),
    concatMap(action => of(action).pipe(
      withLatestFrom(this.store.pipe(select(fromMemberCourse.Selectors.MemberCoursesForExport)))
    )),
    tap(([action, {memberCourses, academicYears, semesters, memberName}]) => {
      if (memberCourses.some(x => x.selected)) {
        memberCourses = [...memberCourses.filter(x => x.selected)];
      }
      var result = memberCourses.map(memberCourse => {
        const academicYear = academicYears.find(x=> moment(memberCourse.startDate).isBetween(x.startDate,x.endDate,null,'[]'));
        const semester = semesters.find(x=>moment(memberCourse.startDate).isBetween(x.startDate,x.endDate, null, '[]'));
        let facultyCVs = '';
        memberCourse.facultyCVs.forEach(facultyCV=>{
          facultyCVs = facultyCVs == '' ? facultyCV.description : facultyCVs + ', ' + facultyCV.description;
        });
        let tags ='';
        memberCourse.tags.forEach(tag=>{
          tags = tags == '' ? tag.description : tags + ', ' + tag.description;
        })
        return {
          providerCourseCode: memberCourse.code,
          title: memberCourse.title,
          credits: `${memberCourse.creditHours2} ${this.getCreditHourType(memberCourse.creditHourType)} Credit Hours`,
          instructor: facultyCVs,
          dates: `${memberCourse.startDate2.month}/${memberCourse.startDate2.day}/${memberCourse.startDate2.year} - ${memberCourse.endDate2.month}/${memberCourse.endDate2.day}/${memberCourse.endDate2.year}`,
          providerName: memberCourse.institution.description,
          level: memberCourse.level.description,
          category: memberCourse.category.description,
          subcategory: memberCourse.subCategory.description,
          courseDescription: memberCourse.description,
          courseRequirements: memberCourse.requirements,
          courseNotes: memberCourse.notes,
          tags
        }
      });

      var columns: ExcelColumn[] = new Array<ExcelColumn>(
        {header: 'Provider Course Code', fieldName: 'providerCourseCode'},
        {header: 'Course Title', fieldName: 'title'},
        {header: 'Credits', fieldName: 'credits'},
        {header: 'Instructor', fieldName: 'instructor'}, 
        {header: 'Dates', fieldName: 'dates'},
        {header: 'Provider', fieldName: "providerName"},
        {header: 'Level', fieldName: 'level'},
        {header: 'Course Category', fieldName: 'category'},
        {header: 'Course Subcategory', fieldName: 'subcategory'},
        {header: 'Course Description', fieldName: 'courseDescription'},
        {header: 'Course Requirements', fieldName: 'courseRequirements'},
        {header: 'Course Notes', fieldName: 'courseNotes'},
        {header: 'Course Tags', fieldName: 'tags'}
      );
      
      this.excelService.exportAsExcelFile(result, columns, `Course Schedule - ${memberName}`);
    })
  )
}, {dispatch: false});

loadFacultyCVs$ = createEffect(() => {
  return this.actions$.pipe(
    ofType(fromMemberCourse.Actions.LoadFacultyCVList),
    concatMap(action => of(action).pipe(
      withLatestFrom(this.store.pipe(select(fromMemberCourse.Selectors.CurrentMemberCourseListItem)))
    )),
    switchMap(([action,currentMemberCourseListItem]) => this.institutionService.getCourseFacultyCVs(currentMemberCourseListItem.institution.id, currentMemberCourseListItem.courseId)),
    map((currentFacultyCVs) => {
      return fromMemberCourse.Actions.LoadFacultyCVListSuccess({ currentFacultyCVs })
    })
  );
});

  getCreditHourType(creditHourType: CreditHourType): string{
    if(creditHourType == CreditHourType.SEM) return "SEM";
    if(creditHourType == CreditHourType.QR) return "QR";
    return "";
  }
}
