import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AddCourseExchangeCourseLevelCommand, RemoveCourseExchangeCourseLevelCommand, AddCourseExchangeCourseCategoryCommand, RemoveCourseExchangeCourseCategoryCommand, AddCourseExchangeCourseSubCategoryCommand, RemoveCourseExchangeCourseSubCategoryCommand, AddCourseExchangeCourseTagCommand, RemoveCourseExchangeCourseTagCommand, AddCourseExchangeStudentTypeCommand, RemoveCourseExchangeStudentTypeCommand } from '../commands';
import { BaseResponse } from 'app/core/models';
import { ConfigService } from '@core/services/config.service';
import { UpdateCourseExchangeCourseLevelsCommand } from '../commands/updateCourseExchangeCourseLevels.command';


@Injectable()
export class CoursesDataListsService {
  private apiUrl:string='';
  constructor(private http:HttpClient,private config:ConfigService) { 
    this.config.apiUrl$.subscribe(result=>this.apiUrl=`${result}/courseExchange`);
  }

  addCourseCategories(command: AddCourseExchangeCourseCategoryCommand):Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.apiUrl}/${command.courseExchangeId}/courseCategories`, command);
  }

  removeCourseCategories(command: RemoveCourseExchangeCourseCategoryCommand):Observable<BaseResponse>{
    return this.http.put<BaseResponse>(`${this.apiUrl}/${command.courseExchangeId}/courseCategories`, command);
  }

  addCourseLevels(command: AddCourseExchangeCourseLevelCommand):Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.apiUrl}/${command.courseExchangeId}/courseLevels`, command);
  }

  removeCourseLevels(command: RemoveCourseExchangeCourseLevelCommand):Observable<BaseResponse>{
    return this.http.put<BaseResponse>(`${this.apiUrl}/${command.courseExchangeId}/courseLevels`, command);
  }

  updateCourseLevels(courseExchangeId: string, courseLevelIds: string[],hardReset:boolean):Observable<BaseResponse>{
    const command: UpdateCourseExchangeCourseLevelsCommand = {courseExchangeId,courseLevelIds, hardReset};
    return this.http.put<BaseResponse>(`${this.apiUrl}/${command.courseExchangeId}/courseLevels/update`, command);
  }

  addCourseSubCategories(command: AddCourseExchangeCourseSubCategoryCommand):Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.apiUrl}/${command.courseExchangeId}/courseSubCategories`, command);
  }

  removeCourseSubCategories(command: RemoveCourseExchangeCourseSubCategoryCommand):Observable<BaseResponse>{
    return this.http.put<BaseResponse>(`${this.apiUrl}/${command.courseExchangeId}/courseSubCategories`, command);
  }

  addCourseTags(command: AddCourseExchangeCourseTagCommand):Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.apiUrl}/${command.courseExchangeId}/courseTags`, command);
  }

  removeCourseTags(command: RemoveCourseExchangeCourseTagCommand):Observable<BaseResponse>{
    return this.http.put<BaseResponse>(`${this.apiUrl}/${command.courseExchangeId}/courseTags`, command);
  }

  addStudentTypes(command: AddCourseExchangeStudentTypeCommand):Observable<BaseResponse>{
    return this.http.post<BaseResponse>(`${this.apiUrl}/${command.courseExchangeId}/studentTypes`, command);
  }

  removeStudentTypes(command: RemoveCourseExchangeStudentTypeCommand):Observable<BaseResponse>{
    return this.http.put<BaseResponse>(`${this.apiUrl}/${command.courseExchangeId}/studentTypes`, command);
  }
}
