import { StudentListItem } from "@shared/student/models";
import convertStudentDates from "./convertStudentDates";

export default <T extends StudentListItem>(studentsToConvert:T[]): T[] => {
    return studentsToConvert.map((student)=>{
        return {
            ...student,
            dateOfBirth: new Date(student.dateOfBirth2.year, student.dateOfBirth2.month-1, student.dateOfBirth2.day)
        }
    })
  }
  