import {Component, OnDestroy, OnInit, Input, OnChanges, SimpleChanges, EventEmitter, Output} from '@angular/core';
import {Subject} from 'rxjs';
import {Store} from '@ngrx/store';
import {IAppState} from '../../../../store/state/app.state';
import {IControlsConfig} from '../../../../models/shared';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {hasErrorForm} from '../../../../helpers/has-error-form';
import {fillControlsConfig} from '../../../../helpers/fill-controls-config';
import {UserListItem} from '../../../user/models';
import {MatDialog} from '@angular/material/dialog';
import {ChecklistItemType} from '../../../../core/models/checklistItemType.enum';
import { UpdateUserCommand, AddUserCommand } from '@shared/user/commands';
import { WizardSkipDialogContainerComponent, IWizardSkipDialogData } from '../wizard-skip-dialog-container/wizard-skip-dialog-container.component';
import { NavigationType } from '@shared/wizard/constants/navigateType.enum';
import {v4 as uuidv4} from 'uuid';

type IFormFields = Pick<UserListItem, 'department' | 'title' | 'firstName' | 'lastName' | 'phoneNumber' | 'emailAddress'>;

type ILocalControlsConfig = IControlsConfig<IFormFields>;

@Component({
  selector: 'wizard-contacts-step-add-president',
  templateUrl: './wizard-contacts-step-add-president.component.html',
  styleUrls: ['../../styles/common-wizard-step.scss', './wizard-contacts-step-add-president.component.scss']
})
export class WizardContactsStepAddPresidentComponent implements OnInit, OnChanges {
  private ngDestroyed$: Subject<any>;

  form: UntypedFormGroup;
  isLoading = true;
  isSending = false;
  @Input()institutionId: string;
  @Input() user: UserListItem;
  @Input() role: string;
  @Output() addUser: EventEmitter<AddUserCommand> = new EventEmitter<AddUserCommand>();
  @Output() editUser: EventEmitter<UpdateUserCommand> = new EventEmitter<UpdateUserCommand>();
  @Output() navigate: EventEmitter<string> = new EventEmitter<string>();
  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store<IAppState>,
    public dialog: MatDialog
  ) {
    this.ngDestroyed$ = new Subject();
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group(this.shapeControlsConfig());
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.user && changes.user.currentValue) {
      this.form = this.formBuilder.group(this.shapeControlsConfig());
    }
  }

  private shapeControlsConfig(): ILocalControlsConfig {
    const initialControlsConfig: ILocalControlsConfig = {
      department: ['', Validators.required],
      title: [''],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      emailAddress: ['', Validators.required],
    };

    if (this.user) {
      return fillControlsConfig<IFormFields>(initialControlsConfig, this.user);
    }

    return initialControlsConfig;
  }

  getInitialEmail(): string {
    return this.user && this.user.emailAddress ? this.user.emailAddress : '';
  }

  getUserId(): string {
    return this.user && this.user.id ? this.user.id : '';
  }

  hasError = (controlName: keyof IFormFields, errorName: string) => hasErrorForm(controlName, errorName, this.form);

  onKeyDownForm(event): void {
    if(event.keyCode === 13) {
      this.form.valid ? this.onSubmit() : event.preventDefault();
    }
  }

  onSubmit = () => {
    if (this.form.valid) {
      const {department, title, firstName, lastName, phoneNumber, emailAddress} = this.form.value;
      const id = this.user ? this.user.id : '';
      const institutionId = this.institutionId;
      if(id === ''){
        var addCommand: AddUserCommand = {
          id: uuidv4(),
          institutionId,
          department,
          title,
          firstName,
          lastName,
          phoneNumber,
          emailAddress,
          middleName: '',
          institutionRole: this.role
        };
        this.addUser.emit(addCommand);
      }else{
        var editCommand: UpdateUserCommand ={
          id,
          institutionId,
          department,
          title,
          firstName,
          lastName,
          phoneNumber,
          emailAddress,
          middleName: '',
          institutionRole: this.role
        }
        this.editUser.emit(editCommand);
      }
      this.navigate.emit(NavigationType.Forward);
    }
  }

  goBack = () => {
    this.navigate.emit(NavigationType.Back);
  }

  skip = () => {
    this.dialog.open<WizardSkipDialogContainerComponent, IWizardSkipDialogData>(WizardSkipDialogContainerComponent, {
      data: {
        itemType: ChecklistItemType.Calendar,
        toNextStepCb: this.toNextStep
      }
    });
  }

  toNextStep = () => {
    this.navigate.emit(NavigationType.Skip);
  }
}
