

import {createFeatureSelector, createSelector} from '@ngrx/store';
import { ProviderBillingState } from '../reducers';

export const selectProviderBillingState = createFeatureSelector<ProviderBillingState>('provider/billing');

export const ProviderId = createSelector(
    selectProviderBillingState,
    (state) => state.providerId
)

export const ProviderMonthlyStatements = createSelector(
    selectProviderBillingState,
    (state) => state.monthlyStatements
)

export const areProviderFeeMonthlySummariesLoaded = createSelector(
    selectProviderBillingState,
    (state) => state.areMonthlyStatementsLoaded
)
export const isProviderFeeMonthlySummariesLoading = createSelector(
    selectProviderBillingState,
    (state) => state.isMonthlyStatementsLoading
)

export const CurrentProviderMonthlyPayoutStatement = createSelector(
    selectProviderBillingState,
    (state) => state.currentProviderMonthlyPayoutStatement
)

export const IsProviderMonthlyPayoutStatementLoading = createSelector(
    selectProviderBillingState,
    (state)=> state.isProviderMonthlyPayoutStatementLoading
)