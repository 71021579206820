import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ProviderState} from '@provider/store';
import { EntityStatus } from '@core/models';
import { PermissionType } from '@auth/models/permissionType.enum';
import { ViewContext } from '@auth/models/viewContext.enum';
import * as fromAuth from '@auth/store';

export const selectProviderState = createFeatureSelector<ProviderState>('provider');

export const Provider = createSelector(
    selectProviderState,
    (state) => state.provider
);

export const ProviderId = createSelector(
    Provider,
    (provider) => !!provider ? provider.id : ""
);

export const IsSendingPolicyIds = createSelector(
  selectProviderState,
  (state) => state.isSendingPolicyIds
);

export const InstitutionId = createSelector(
    Provider,
    (provider) => !!provider ? provider.institutionId : ""
);

export const CourseExchngeId = createSelector(
    Provider,
    (provider) => !!provider ? provider.courseExchangeId : ""
);

export const ProviderStatus = createSelector(
    Provider,
    (provider) => !!provider ? provider.status : EntityStatus.None
)

export const Policies = createSelector(
    selectProviderState,
    (state) => state.policies
)

export const arePoliciesLoaded = createSelector(
    selectProviderState,
    (state) => state.arePoliciesLoaded
)

export const areFeesLoaded = createSelector(
    selectProviderState,
    (state) => state.areFeesLoaded
)

export const HasInstitutionPermission = (permissionType: PermissionType)=> createSelector(
    fromAuth.Selectors.CurrentUser,
    fromAuth.Selectors.IsSuperAdmin,
    InstitutionId,
    (user, isSuperAdmin, institutionId) => {
      if(isSuperAdmin) return true;
      var accessRole = !!user && !!user.accessRoles ? user.accessRoles.find(x=>x.entityId===institutionId && x.viewContext===ViewContext.Institution):null;
      if(!accessRole) return false;
      if(!accessRole.permissions) return false;
      return accessRole.permissions.find(x=>x===permissionType) !== undefined;
    }
  )
  export const HasProviderPermission = (permissionType: PermissionType)=> createSelector(
    fromAuth.Selectors.CurrentUser,
    fromAuth.Selectors.IsSuperAdmin,
    ProviderId,
    (user, isSuperAdmin, providerId) => {
      if(isSuperAdmin) return true;
      var accessRole = !!user && !!user.accessRoles ? user.accessRoles.find(x=>x.entityId===providerId && x.viewContext===ViewContext.Provider) : null;
      if(!accessRole) return false;
      if(!accessRole.permissions) return false;
      return accessRole.permissions.find(x=>x===permissionType) !== undefined;
    }
  )
export const ShowToolbarButtons = createSelector(
    Provider,
    (provider) => !!provider && provider.status == EntityStatus.Approved
)

