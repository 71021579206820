import {createFeatureSelector, createSelector} from '@ngrx/store';
import * as fromNotification from '@core/notifications/store';
import { INotificationState } from '@core/notifications/store';

export const selectNotificationsState = createFeatureSelector<INotificationState>('notifications');

export const Notifications = createSelector(
  selectNotificationsState,
  (state) => state.notifications.filter(x=>!x.viewed)
);

export const NotificationsCount = createSelector(
  Notifications,
  (notifications) => {
    const result = notifications.length;
    if(result==0) return null;
    return result;
  }
)

export const IsNotificationsOpen = createSelector(
  selectNotificationsState,
  (state) => state.isNotificationsOpen
)
