import {CompareCoursesDialogContainerComponent} from './compare-courses-dialog-container/compare-courses-dialog-container.component';
import {DeSelectCourseDialogConfirmComponent} from './de-select-course-dialog-confirm/de-select-course-dialog-confirm.component';
import {AddedToPreSelectCourseDialogComponent} from './added-to-pre-select-course-dialog/added-to-pre-select-course-dialog.component';
import {CourseDetailComponent} from './memberCourse-detail/memberCourse-detail.component';
import {CourseDescriptionDialogComponent} from './course-description-dialog/course-description-dialog.component';
import {FacultyCvDialogComponent} from './faculty-cv-dialog/faculty-cv-dialog.component';
import { MemberCoursesListComponent } from './memberCourse-list/memberCourse-list.component';

export * from './memberCourse-list/memberCourse-list.component';
export * from './compare-courses-dialog-container/compare-courses-dialog-container.component';
export * from './de-select-course-dialog-confirm/de-select-course-dialog-confirm.component';
export * from './added-to-pre-select-course-dialog/added-to-pre-select-course-dialog.component';
export * from './memberCourse-detail/memberCourse-detail.component';
export * from './course-description-dialog/course-description-dialog.component';
export * from './faculty-cv-dialog/faculty-cv-dialog.component';

export const COMPONENTS = [
  MemberCoursesListComponent,
  CompareCoursesDialogContainerComponent,
  DeSelectCourseDialogConfirmComponent,
  AddedToPreSelectCourseDialogComponent,
  CourseDetailComponent,
  CourseDescriptionDialogComponent,
  FacultyCvDialogComponent
];

export const ENTRY_COMPONENTS = [
  CompareCoursesDialogContainerComponent,
  DeSelectCourseDialogConfirmComponent,
  AddedToPreSelectCourseDialogComponent,
  CourseDescriptionDialogComponent,
  FacultyCvDialogComponent
];
