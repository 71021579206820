<mat-toolbar>
  <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <span class="title dialog-title">Delete Student</span>
    <button mat-icon-button (click)="close()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div class="content">
  <div class="sub-title sub-title-top">You are permanently deleteing the following student:</div>

  <student-details-for-dialog [student]="matDialogData.student"></student-details-for-dialog>

  <div class="sub-title sub-title-bottom">This cannot be undone. Are you sure you would like to proceed?</div>

  <div class="actions">
    <button mat-raised-button color="warn" (click)="close()">
      <mat-icon>close</mat-icon>
      No, Cancel
    </button>

    <button mat-raised-button (click)="delete()">
      <mat-icon>delete</mat-icon>
      Yes, Delete Student
    </button>
  </div>
</div>
