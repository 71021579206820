<mat-toolbar>
  <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <span class="title dialog-title">Enrollment Details</span>
    <button mat-icon-button (click)="close()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div class="content">
  <enrollment-list-item-detail
    (cancel)="close()"
    (requestedStatus)="changeRequestedStatus($event)"
    [enrollment]="enrollment"
    [allowedStatuses]="enrollment.status.memberAllowedStatuses"
  ></enrollment-list-item-detail>
</div>
