<div [ngClass]="['page-list-sidebar', isShow ? 'is_show' : 'is_hidden']">
  <div class="page-list-sidebar-inner" (mouseenter)="hover()">
    <div class="panel">
      <div class="text">Filters</div>
      <div class="toggle">
        <button mat-icon-button
                (click)="toggleSidebar()">
          <mat-icon>filter_alt</mat-icon>
        </button>
      </div>
    </div>
    <ng-container *ngIf="isShow">
      <ng-content></ng-content>
    </ng-container>
  </div>
</div>
