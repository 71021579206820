import {createAction, props} from '@ngrx/store';
import {EntityStatus, Sort, Order} from 'app/core/models';
import { CourseExchangeInstitutionListItem } from '@shared/courseExchange/models';
import { CourseExchangeInstitutionType } from '@courseExchange/store/enums';
import { Filter } from '@shared/common/models';
import { AddInstitutionCommand, InstitutionListItem } from '@admin/institution';
import { UserListItem } from '@shared/user/models';
import { CourseExchangeInstitutionListMode } from '../courseExchangeInstitutionListMode.enum';
import { AddMemberCommand } from '@shared/member/models';
import { AddProviderCommand } from '@shared/provider/commands';

export const LoadCourseExchangeInstitutions = createAction(
    '[Course Exchange Dashboard] - Course Exchange Institution List',
    props<{
        courseExchangeId: string
    }>()
)

export const LoadCourseExchangeInstitutionsSuccess = createAction(
    '[Course Exchange Institution List Effects] - Course Exchange Institution List',
    props<{
        institutions: CourseExchangeInstitutionListItem[]
    }>()
)

export const ChangeCourseExchangeInstitutionType = createAction(
    '[Course Exchange Institution list] - Change Type Filters',
    props<{
      currentType: CourseExchangeInstitutionType
    }>()
)
  
export const ToggleStatusFilter = createAction(
  '[Course Exchange Institution list] - Toggle Status Filter',
  props<{
    id: string
  }>()
);

export const ToggleAccreditationBodyFilter = createAction(
  '[Course Exchange Institution list] - Toggle Accreditation Body Filter',
  props<{
    id: string
  }>()
);

export const ToggleRegionFilter = createAction(
  '[Course Exchange Institution list] - Toggle Region Filter',
  props<{
    id : string
  }>()
);

export const RemoveFilter = createAction(
  '[Course Exchange Institution List] - Remove Filter',
  props<{
    filter: Filter
  }>()
)


export const ChangeSearchText = createAction(
  '[Student List] - Change Search Text',
  props<{
    searchText: string
  }>()
)

export const ChangeSort = createAction(
    '[Course Exchange Institution List] - Change Sort',
    props<{
      currentSort: Sort
    }>()
  )
  
  export const ChangeOrder = createAction(
    '[Course Exchange Institution List] - Change Order',
    props<{
      currentOrder: Order
    }>()
  )

  export const InstitutionList = createAction(
    '[Navigation] - Course Exchange Institution List',
    props<{
        courseExchangeId?: string,
        managingInstitutionId?: string,
        institutionType?: CourseExchangeInstitutionType,
        status?: EntityStatus
    }>()
)

  export const MemberReadOnlyWizard = createAction(
    '[Course Exchange Institution List] - Navigate to Member ReadOnlyWizard',
    props<{
      institution: CourseExchangeInstitutionListItem
    }>()
  )

  export const ProviderReadOnlyWizard = createAction(
    '[Course Exchange Institution List] - Navigate to Provider ReadOnly Wizard',
    props<{
      institution: CourseExchangeInstitutionListItem
    }>()
  )

  export const InstitutionDetail = createAction(
    '[Course Exchange Institution List] - Navigate to Institution Detail',
    props<{
      institution: CourseExchangeInstitutionListItem
    }>()
  )

  export const LoadAllInstitutions = createAction(
    '[Course Exchange Institution List] - Load All Institution',
    props<{
      allInstitutions: InstitutionListItem[]
    }>()
  )

  export const LoadCurrentInstitution = createAction(
    '[Course Exchange Institution Resolver] - Load Current Institution',
    props<{
      courseExchangeId: string,
      institutionId: string
    }>()
  )

  export const LoadCurrentInstitutionSuccess = createAction(
    '[Course Exchange Institutions Effects] - Load Current Institution Success',
    props<{
      currentInstitution: CourseExchangeInstitutionListItem
    }>()
  )

  export const BeginAddNewInstitutionWizard = createAction(
    '[Course Exchange Institution List] - Begin Add New Institution to Course Exchange'
  )

  export const ChangeInstitutionListMode = createAction(
    '[Course Exchange Institution List] - Change Institution List Mode',
    props<{
        courseExchangeInstitutionListMode: CourseExchangeInstitutionListMode
    }>()
  )

  export const InviteToCourseExchange = createAction(
    '[Course Exchange Institution List] - Invite To Course Exchange',
    props<{
        institution: CourseExchangeInstitutionListItem
    }>()
  )
  export const AddInstitution = createAction(
    '[Course Exchange Institution List] - Add new Institution',
    props<{
      command: AddInstitutionCommand
    }>()
  )
  export const AddMember = createAction(
    '[Course Exchange Institution List] - Add Member',
    props<{
      command: AddMemberCommand
    }>()
  )
  export const AddProvider = createAction(
    '[Course Exchange Institution List] - Add Provider',
    props<{
      command: AddProviderCommand
    }>()
  )

  export const UpdateInstitutionSuccess = createAction(
    '[Course Exchange Institution Effects] - Update Institution Success',
    props<{
      institution: CourseExchangeInstitutionListItem
    }>()
  )

  export const LoadInstitutionUsers = createAction(
    '[Course Exchange Institutions User Resolver] - User Resolver',
    props<{
      institutionId: string
    }>()
  )

  export const LoadInstitutionUsersSuccess = createAction(
    '[Course Exchange Institutions Effects] - Load Institution Users Success',
    props<{
      currentInstitutionUsers: UserListItem[]
    }>()
  )

  export const ConfirmDeactivateCourseExchangeInstitution = createAction(
    '[Course Exchange Institution List] - Confirm Deactivate',
    props<{
      courseExchangeInstitutionListItem: CourseExchangeInstitutionListItem
    }>()
  )

  export const DeactivateCourseExchangeInstitution = createAction(
    '[Course Exchange Institution List] - Deactivate',
    props<{
      courseExchangeInstitutionListItem: CourseExchangeInstitutionListItem
    }>()
  )
  
  export const ConfirmActivateCourseExchangeInstitution = createAction(
    '[Course Exchange Institution List] - Confirm Activate',
    props<{
      courseExchangeInstitutionListItem: CourseExchangeInstitutionListItem
    }>()
  )

  export const ActivateCourseExchangeInstitution = createAction(
    '[Course Exchange Institution List] - Activate',
    props<{
      courseExchangeInstitutionListItem: CourseExchangeInstitutionListItem
    }>()
  )

  export const ConfirmDeleteCourseExchangeInstitution = createAction(
    '[Course Exchange Institution List] - Confirm Delete',
    props<{
      courseExchangeInstitutionListItem: CourseExchangeInstitutionListItem
    }>()
  )

  export const DeleteCourseExchangeInstitution = createAction(
    '[Course Exchange Institution List] - Delete',
    props<{
      courseExchangeInstitutionListItem: CourseExchangeInstitutionListItem
    }>()
  )

  export const CourseExchangeInstitutionListItemUpdated = createAction(
    '[Course Exchange Institution Effects] - CourseExchangeInstitutionListItemUpdated',
    props<{
      courseExchangeInstitutionListItem: CourseExchangeInstitutionListItem
    }>()
  )

  export const CourseExchangeInstitutionListItemAdded = createAction(
    '[Course Exchange Institution Effects] - CourseExchangeInstitutionListItemAdded',
    props<{
      courseExchangeInstitutionListItem: CourseExchangeInstitutionListItem
    }>()
  )

  export const CourseExchangeInstitutionListItemDeleted = createAction(
    '[Course Exchange Institution Effects] - CourseExchangeInstitutionListItemDeleted',
    props<{
      courseExchangeInstitutionListItem: CourseExchangeInstitutionListItem
    }>()
  )






