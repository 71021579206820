import {
    createReducer,
    on
  } from '@ngrx/store';
import { CourseExchange, CourseExchangeFeeListItem, CourseExchangePolicy, CourseExchangeListItem, CourseExchangeInstitutionListItem, CourseExchangeSummary } from 'app/shared/courseExchange/models';
import * as fromCourseExchange from '@courseExchange/store';
import { ChecklistItemType } from '@core/models/checklistItemType.enum';
import { completeChecklistItem, incompleteChecklistItem, completeChecklistItemByIdenitifer, incompleteChecklistItemByIdenitifer } from '@shared/wizard/store/reducers/helpers/completeChecklistItem';
import { CourseExchangeInstitutionsWidgetViewModel } from '@courseExchange/dashboard/widgets/courseExchangeInstitutions-widget/courseExchangeInstitutions-widget.viewModel';
import {CourseExchangeCoursesWidgetViewModel} from '@courseExchange/dashboard/widgets/courseExchangeCourses-widget/courseExchangeCourses-widget.viewModel';
import { InstitutionSummary } from '@shared/institution/models';
import {LogoType} from '@core/logoType.enum';

export interface CourseExchangeState {
  courseExchange: CourseExchange;
  courseExchangeSummary: CourseExchangeSummary,
  courseExchangeId: string,
  fees: CourseExchangeFeeListItem[],
  areFeesLoaded: boolean,
  policies: CourseExchangePolicy[],
  arePoliciesLoaded: boolean,
  areInstitutionsLoaded: boolean,
  managingInstitutionLoaded: boolean,
  institutionsWidgetData: CourseExchangeInstitutionsWidgetViewModel,
  courseExchangeCoursesWidgetData: CourseExchangeCoursesWidgetViewModel,
  institutions: InstitutionSummary[],
  isSendingSecondaryLogo: boolean;
  isSendingPrimaryLogo: boolean;
  isSendingPolicyIds: string[]
}

export const initialCourseExchangeState: CourseExchangeState = {
  courseExchange: undefined,
  courseExchangeSummary: undefined,
  courseExchangeId: '',
  fees: undefined,
  areFeesLoaded: false,
  policies: undefined,
  arePoliciesLoaded: false,
  areInstitutionsLoaded: false,
  managingInstitutionLoaded: false,
  institutionsWidgetData: undefined,
  courseExchangeCoursesWidgetData: undefined,
  institutions: new Array<InstitutionSummary>(),
  isSendingSecondaryLogo: false,
  isSendingPrimaryLogo: false,
  isSendingPolicyIds: []
};

  export const courseExchangeReducer = createReducer(
    initialCourseExchangeState,
    on(fromCourseExchange.Actions.LoadCourseExchangeSuccess, (state, {courseExchange}) =>{
    return {
        ...state,
        courseExchange,
        courseExchangeId: courseExchange.id,
        isSendingSecondaryLogo: false,
        isSendingPrimaryLogo: false
      };
    }),
    on(fromCourseExchange.Actions.LoadCourseExchangeSummarySuccess, (state, {courseExchangeSummary})=>{
      return {
        ...state,
        courseExchangeSummary,
        courseExchangeId: courseExchangeSummary.id
      }
    }),
    on(fromCourseExchange.Actions.CourseExchangeSummaryUpdated, (state, {courseExchangeSummary})=>{
      if(state.courseExchangeId != courseExchangeSummary.id) return state;
      return {
        ...state,
        courseExchangeSummary
      }
    }),
    on(fromCourseExchange.Actions.LoadInstitutionsSuccess, (state, {institutions})=>{
      return {
        ...state,
        institutions
      }
    }),
    on(fromCourseExchange.Actions.LoadFeesSuccess, (state, {fees}) =>{
      return{
        ...state,
        fees,
        areFeesLoaded: true
      }
    }),
    on(fromCourseExchange.Actions.LoadPoliciesSuccess, (state, {policies}) =>{
      return {
        ...state,
        policies,
        arePoliciesLoaded: true
      }
    }),
    on(fromCourseExchange.Actions.RemoveCourseCategories, (state, {courseCategoryIds})=>{
      const courseCategories = state.courseExchange.courseCategories.filter(x=>!courseCategoryIds.find(y=>x==y));
      const courseExchangeChecklist = courseCategories.length ? completeChecklistItem(state.courseExchange.courseExchangeChecklist,ChecklistItemType.CourseInformation_Categories):
          incompleteChecklistItem(state.courseExchange.courseExchangeChecklist,ChecklistItemType.CourseInformation_Categories)

      const courseExchange = {...state.courseExchange,courseCategories,courseExchangeChecklist};
      return {
        ...state,
        courseExchange
      }
    }),
    on(fromCourseExchange.Actions.AddCourseSubCategoriesSuccess, (state, {courseSubCategoryIds})=>{
      let courseSubCategories = [...state.courseExchange.courseSubCategories, ...courseSubCategoryIds];
      courseSubCategories = [...new Set(courseSubCategories)];
      const courseExchangeChecklist = courseSubCategories.length ? completeChecklistItem(state.courseExchange.courseExchangeChecklist,ChecklistItemType.CourseInformation_SubCategories):
        incompleteChecklistItem(state.courseExchange.courseExchangeChecklist,ChecklistItemType.CourseInformation_SubCategories)
      const courseExchange = {...state.courseExchange,courseSubCategories, courseExchangeChecklist};
      return {
        ...state,
        courseExchange
      }
    }),
    on(fromCourseExchange.Actions.RemoveCourseSubCategories, (state, {courseSubCategoryIds})=>{
      const courseSubCategories = state.courseExchange.courseSubCategories.filter(x=>!courseSubCategoryIds.find(y=>x==y));
      const courseExchangeChecklist = courseSubCategories.length ? completeChecklistItem(state.courseExchange.courseExchangeChecklist,ChecklistItemType.CourseInformation_SubCategories):
                      incompleteChecklistItem(state.courseExchange.courseExchangeChecklist,ChecklistItemType.CourseInformation_SubCategories)

      const courseExchange = {...state.courseExchange,courseSubCategories, courseExchangeChecklist};
      return {
        ...state,
        courseExchange
      }
    }),
    on(fromCourseExchange.Actions.AddCourseLevelsSuccess, (state, {courseLevelIds})=>{
      let courseLevels = [...state.courseExchange.courseLevels, ...courseLevelIds];
      courseLevels = [...new Set(courseLevels)];
      const courseExchangeChecklist = courseLevels.length ? completeChecklistItem(state.courseExchange.courseExchangeChecklist,ChecklistItemType.CourseInformation_Levels):
        incompleteChecklistItem(state.courseExchange.courseExchangeChecklist,ChecklistItemType.CourseInformation_Levels)
      const courseExchange = {...state.courseExchange,courseLevels, courseExchangeChecklist};
      return {
        ...state,
        courseExchange
      }
    }),
    on(fromCourseExchange.Actions.RemoveCourseLevels, (state, {courseLevelIds})=>{
      const courseLevels = state.courseExchange.courseLevels.filter(x=>!courseLevelIds.find(y=>x==y));
      const courseExchangeChecklist = courseLevels.length ? completeChecklistItem(state.courseExchange.courseExchangeChecklist,ChecklistItemType.CourseInformation_Levels):
                      incompleteChecklistItem(state.courseExchange.courseExchangeChecklist,ChecklistItemType.CourseInformation_Levels)
      const courseExchange = {...state.courseExchange,courseLevels, courseExchangeChecklist};
      return {
        ...state,
        courseExchange
      }
    }),
    on(fromCourseExchange.Actions.AddCourseTagsSuccess, (state, {courseTagIds})=>{
      let courseTags = [...state.courseExchange.courseTags, ...courseTagIds];
      courseTags = [...new Set(courseTags)];
      const courseExchangeChecklist = courseTags.length ? completeChecklistItem(state.courseExchange.courseExchangeChecklist,ChecklistItemType.CourseInformation_Tags):
                                                        incompleteChecklistItem(state.courseExchange.courseExchangeChecklist,ChecklistItemType.CourseInformation_Tags)
      const courseExchange = {...state.courseExchange,courseTags, courseExchangeChecklist};
      return {
        ...state,
        courseExchange
      }
    }),
    on(fromCourseExchange.Actions.RemoveCourseTags, (state, {courseTagIds})=>{
      const courseTags = state.courseExchange.courseTags.filter(x=>!courseTagIds.find(y=>x==y));
      const courseExchangeChecklist = courseTags.length ? completeChecklistItem(state.courseExchange.courseExchangeChecklist,ChecklistItemType.CourseInformation_Tags):
                                                        incompleteChecklistItem(state.courseExchange.courseExchangeChecklist,ChecklistItemType.CourseInformation_Tags)
      const courseExchange = {...state.courseExchange,courseTags,courseExchangeChecklist};
      return {
        ...state,
        courseExchange
      }
    }),
    on(fromCourseExchange.Actions.UploadCourseExchangePolicy, (state, {courseExchangePolicyId})=>{
      return {
        ...state,
        isSendingPolicyIds: [...state.isSendingPolicyIds.slice(), courseExchangePolicyId]
      }
    }),
    on(fromCourseExchange.Actions.LoadCourseExchangeInstitutionsWidgetSuccess, (state,{institutionsWidgetData})=>{
      return {
        ...state,
        institutionsWidgetData
      }
    }),
    on(fromCourseExchange.Actions.ClearState, (state, action)=>{
      return initialCourseExchangeState
    }),
    on(fromCourseExchange.Actions.UploadCourseExchangeLogo, (state, action)=>{
      const newIsSendingPrimaryLogo = action.command.logoType === LogoType.Primary;
      const newIsSendingSecondaryLogo = action.command.logoType === LogoType.Secondary;
      return {
        ...state,
        isSendingPrimaryLogo: newIsSendingPrimaryLogo,
        isSendingSecondaryLogo: newIsSendingSecondaryLogo,
      }
    }),
    on(fromCourseExchange.Actions.UploadCourseExchangeLogoSuccess, (state, {logoType})=>{
      const isSendingPrimaryLogo = logoType==LogoType.Primary ? false : state.isSendingPrimaryLogo;
      const isSendingSecondaryLogo = logoType==LogoType.Secondary ? false : state.isSendingPrimaryLogo;
      return {
        ...state,
        isSendingPrimaryLogo,
        isSendingSecondaryLogo
      }
    }),
    on(fromCourseExchange.Actions.UploadCourseExchangeLogoError, (state, {logoType})=>{
      const isSendingPrimaryLogo = logoType==LogoType.Primary ? false : state.isSendingPrimaryLogo;
      const isSendingSecondaryLogo = logoType==LogoType.Secondary ? false : state.isSendingPrimaryLogo;
      return {
        ...state,
        isSendingPrimaryLogo,
        isSendingSecondaryLogo
      }
    }),
    on(fromCourseExchange.Actions.CourseExchangeUpdated, (state, {courseExchange})=>{
      if(state.courseExchangeId != courseExchange.id) return state;
      return{
        ...state,
        courseExchange
      }
    }),
    on(fromCourseExchange.Actions.CourseExchangeSummaryUpdated, (state, {courseExchangeSummary})=>{
      if(state.courseExchangeId != courseExchangeSummary.id) return state;
      return {
        ...state,
        courseExchangeSummary
      }
    }),
    on(fromCourseExchange.Actions.CourseExchangePolicyUpdated, (state, {courseExchangePolicy})=>{
      var idx = state.policies.findIndex(x=>x.id===courseExchangePolicy.id);
      if(idx===-1) return state;
      const policies = [...state.policies.slice(0,idx),courseExchangePolicy, ...state.policies.slice(idx+1)];
      return {
        ...state,
        policies,
        isSendingPolicyIds: [...state.isSendingPolicyIds.filter(x=>x!=courseExchangePolicy.id)]
      }
    }),
    on(fromCourseExchange.Actions.CourseExchangeFeesUpdated, (state, {fees})=>{
      return {
        ...state,
        fees
      }
    })
);


