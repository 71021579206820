import {CourseExchangeSummaryResolver} from './courseExchangeSummary.resolver';
import { ProviderCoursesResolver } from './providerCourses.resolver';
import { ProviderFeesResolver } from './providerFees.resolver';
import { ProviderPoliciesResolver } from './providerPolicy.resolver';
import { ProviderResolver } from '@shared/provider/resolvers';
export * from './courseExchangeSummary.resolver';
export * from './providerCourses.resolver';
export * from './providerFees.resolver';
export * from './providerPolicy.resolver';
export * from '@shared/provider/resolvers/provider.resolver';

export const RESOLVERS = [CourseExchangeSummaryResolver, ProviderResolver,ProviderCoursesResolver,
            ProviderFeesResolver, ProviderPoliciesResolver]
