import { EWizardTypesStatusComplete } from '@shared/wizard/models';
import { Checklist } from '@core/models';

export const getStatusComplete = (checkList: Checklist): EWizardTypesStatusComplete => {
  if (checkList.isComplete) {
    return EWizardTypesStatusComplete.completed;
  }

  if (!checkList.items.length) {
    return EWizardTypesStatusComplete.notCompleted;
  }

  const oneOfTheSubCheckListIsCompleted = checkList.items.find(item => item.isComplete === true);

  if (oneOfTheSubCheckListIsCompleted) {
    return EWizardTypesStatusComplete.partCompleted;
  }

  return EWizardTypesStatusComplete.notCompleted;
};

