import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { Store, select } from "@ngrx/store";
import { first, finalize, filter, map, tap, concatMap, withLatestFrom, switchMap } from "rxjs/operators";
import { IAppState } from 'app/store/state/app.state';
import * as fromMemberCourses from '@member/memberCourse/store';
import * as fromMember from '@member/store';
import { Member } from 'app/shared/member/models';
import * as moment from "moment";

@Injectable()
export class MemberCoursesResolver implements Resolve<Member>{
    isLoading = false;
    constructor(private store: Store<IAppState>){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
        const memberId = route.params["memberId"];
        return this.store 
            .pipe(
                select(fromMemberCourses.Selectors.AreMemberCoursesLoaded),
                map((coursesLoaded)=>{
                    if(!this.isLoading && !coursesLoaded){
                        this.isLoading = true;
                        this.store.dispatch(fromMemberCourses.Actions.LoadMemberCourses({memberId}));                        
                    }
                    return true;
                }),
                filter(coursesLoaded => coursesLoaded),
                first(),
                finalize(()=>this.isLoading=false)
            )
    }
}