import {Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation, Input} from '@angular/core';

import {NavigationEnd, Router} from '@angular/router';
import {Subject, Observable, BehaviorSubject} from 'rxjs';
import {delay, filter, take, takeUntil, map, tap} from 'rxjs/operators';

import {FuseConfigService} from '@fuse/services/config.service';
import {FuseNavigationService} from '@fuse/components/navigation/navigation.service';
import {FusePerfectScrollbarDirective} from '@fuse/directives/fuse-perfect-scrollbar/fuse-perfect-scrollbar.directive';
import {FuseSidebarService} from '@fuse/components/sidebar/sidebar.service';
import {faCog, faQuestionCircle} from '@fortawesome/free-solid-svg-icons';
import {UserSummary} from 'app/authentication/models/userSummary.model';
import hasInstitutionPermission from 'app/authentication/permissions/hasInstitutionPermission';
import {PermissionType} from 'app/authentication/models/permissionType.enum';
import {MemberService} from 'app/shared/member/services';
import {Member} from 'app/shared/member/models';
import {Store, select} from '@ngrx/store';
import {IAppState} from 'app/store/state/app.state';
import * as fromAuth from '@auth/store';
import * as fromMember from '@member/store';
import * as fromEnrollment from '@member/enrollment/store';
import * as fromMemberBilling from '@member/billing/store';
import { CourseExchangeListItem } from '@shared/courseExchange/models';
import { AccessRoleForView } from '@auth/models/accessRole';

export interface Role {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-admin-member-dashboard-nav',
  templateUrl: './admin-member-dashboard-nav.component.html',
  styleUrls: ['./admin-member-dashboard-nav.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdminMemberDashboardNavComponent implements OnInit, OnDestroy {
  fuseConfig: any;
  navigation: any;

  faCog = faCog;
  faQuestionCircle = faQuestionCircle;
  showInstitutionalPolicies$: Observable<boolean>;
  showUsers$: Observable<boolean>;
  showCalendar$: Observable<boolean>;
  showFees$: Observable<boolean>;
  member$: BehaviorSubject<Member> = new BehaviorSubject<Member>(null);

  userRoles: Role[] = [
    {value: 'admin', viewValue: 'Admin'},
    {value: 'provider', viewValue: 'Provider'},
  ];

  @Input() memberId: string;
  @Input() user: UserSummary;
  institutionId: string = '';
  // Private
  private _fusePerfectScrollbar: FusePerfectScrollbarDirective;
  private _unsubscribeAll: Subject<any>;
  currentUser$: Observable<UserSummary>;
  courseExchanges$: Observable<CourseExchangeListItem[]>;
  currentCourseExchangeId$: Observable<string>;
  accessRoles$: Observable<AccessRoleForView[]>;
  currentAccessRoleId$: Observable<string>;
  /**
   * Constructor
   *
   * @param {FuseConfigService} _fuseConfigService
   * @param {FuseNavigationService} _fuseNavigationService
   * @param {FuseSidebarService} _fuseSidebarService
   * @param {Router} _router
   */
  constructor(
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    private _router: Router,
    private memberService: MemberService,
    private store: Store<IAppState>
  ) {

    // Set the private defaults
    this._unsubscribeAll = new Subject();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  // Directive
  @ViewChild(FusePerfectScrollbarDirective, {static: true})
  set directive(theDirective: FusePerfectScrollbarDirective) {
    if (!theDirective) {
      return;
    }

    this._fusePerfectScrollbar = theDirective;

    // Update the scrollbar on collapsable item toggle
    this._fuseNavigationService.onItemCollapseToggled
      .pipe(
        delay(500),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(() => {
        this._fusePerfectScrollbar.update();
      });

    // Scroll to the active item position
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        take(1)
      )
      .subscribe(() => {
          setTimeout(() => {
            this._fusePerfectScrollbar.scrollToElement('navbar .nav-link.active', -120);
          });
        }
      );
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    this.currentUser$ = this.store.pipe(select(fromAuth.Selectors.CurrentUser));
    this.courseExchanges$ = this.store.pipe(select(fromAuth.Selectors.CourseExchanges));
    this.accessRoles$ = this.store.pipe(select(fromAuth.Selectors.CurrentAccessRoles));
    this.currentCourseExchangeId$ = this.store.pipe(select(fromAuth.Selectors.CurrentCourseExchangeId));
    this.currentAccessRoleId$ = this.store.pipe(select(fromAuth.Selectors.currentAccessRoleId));
    
    this._router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(() => {
          if (this._fuseSidebarService.getSidebar('navbar')) {
            this._fuseSidebarService.getSidebar('navbar').close();
          }
        }
      );

    // Subscribe to the config changes
    this._fuseConfigService.config
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((config) => {
        this.fuseConfig = config;
      });

    // Get current navigation
    this._fuseNavigationService.onNavigationChanged
      .pipe(
        filter(value => value !== null),
        takeUntil(this._unsubscribeAll)
      )
      .subscribe(() => {
        this.navigation = this._fuseNavigationService.getCurrentNavigation();
      });

    this.memberService.getMemberById(this.memberId)
      .pipe(
        take(1),
        tap(member => this.setupPermissions(member)),
        tap(member => {
          this.institutionId = member.institutionId;
          this.member$.next(member)
        })
      ).subscribe();

  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle sidebar opened status
   */
  toggleSidebarOpened(): void {
    this._fuseSidebarService.getSidebar('navbar').toggleOpen();
  }

  /**
   * Toggle sidebar folded status
   */
  toggleSidebarFolded(): void {
    this._fuseSidebarService.getSidebar('navbar').toggleFold();
  }

  logout(): void {
    this.store.dispatch(fromAuth.Actions.logout());
  }

  setupPermissions(member: Member): void {
    this.showInstitutionalPolicies$ = this.store
      .pipe(
        select(fromAuth.Selectors.CurrentUser),
        map(user =>
          hasInstitutionPermission(user, member.institutionId, PermissionType.ReadInstitutionalPolicy))
      );
    this.showUsers$ = this.store
      .pipe(
        select(fromAuth.Selectors.CurrentUser),
        map(user =>
          hasInstitutionPermission(user, member.institutionId, PermissionType.ReadUser))
      );
    this.showCalendar$ = this.store
      .pipe(
        select(fromAuth.Selectors.CurrentUser),
        map(user =>
          hasInstitutionPermission(user, member.institutionId, PermissionType.ReadCalendar))
      );
    this.showFees$ = this.store
      .pipe(
        select(fromAuth.Selectors.CurrentUser),
        map(user =>
          hasInstitutionPermission(user, member.institutionId, PermissionType.ReadFee))
      );
  }

  goToUserList(): boolean {
    this.store.dispatch(fromMember.Navigate.UserList({}));
    return false;
  }

  goToEnrollments(): boolean {
    this.store.dispatch(fromEnrollment.Navigate.Enrollments({}));
    return false;
  }

  goToFees(): boolean{
    this.store.dispatch(fromMember.Navigate.Fees({}));
    return false;
  }

  goToCalendar(): boolean{
    this.store.dispatch(fromMember.Navigate.Calendar({}));
    return false;
  }

  changeCourseExchange(courseExchangeId: string): void{
    this.store.dispatch(fromAuth.Actions.selectCourseExchangeId({courseExchangeId}));
  }

  changeAccessRole(accessRoleId: string): void{
    this.store.dispatch(fromAuth.Actions.selectAccessRole({accessRoleId}));
    this.store.dispatch(fromAuth.Actions.navigateToDashboard({accessRoleId}));
  }

  goToBillingSummary(): boolean{
    this.store.dispatch(fromMemberBilling.Navigate.MemberBillingSummary({}));
    return false;
  }
}
