import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {FuseSearchBarModule, FuseShortcutsModule} from '@fuse/components';
import {FuseSharedModule} from '@fuse/shared.module';
import {MaterialModule} from 'app/core/material/material.module';
import {NotificationBarModule} from '../notification-bar/notification-bar.module';
import {AvatarSharedModule} from '@shared/avatar/avatar-shared.module';
import * as fromComponents from './components';
import {MemberSharedModule} from '@shared/member/member-shared.module';
import {CourseExchangeSharedModule} from '@shared/courseExchange/courseExchange-shared.module';
import {ProviderSharedModule} from '@shared/provider/provider-shared.module';

@NgModule({
  declarations: [
    ...fromComponents.COMPONENTS
  ],
  imports: [
    RouterModule,
    MaterialModule,
    FuseSharedModule,
    FuseSearchBarModule,
    FuseShortcutsModule,
    NotificationBarModule,
    AvatarSharedModule,
    MemberSharedModule,
    ProviderSharedModule,
    CourseExchangeSharedModule
  ],
  exports: [
    ...fromComponents.COMPONENTS
  ]
})
export class ToolbarModule {
}
