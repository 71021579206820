<div class="wizard-step wizard-calendar-step-add-session">
  <ng-container>
    <div class="title">
      Add Calendar: Session
    </div>

    <form class="form" [formGroup]="form"(keydown)="onKeyDownForm($event)">
      <div class="fields">
        <div class="field">
          <mat-form-field appearance="outline" class="mt-8" fxFill>
            <mat-label>Session Code</mat-label>
            <input
              matInput
              name="code"
              required
              formControlName="code">
            <mat-error *ngIf="hasError('code', 'required')">This field is required</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="fields">
        <div class="field">
          <mat-form-field appearance="outline" class="mt-8" fxFlex>
            <mat-label>Start Date</mat-label>
            <input matInput [matDatepicker]="startDatePicker" formControlName="startDate" (dateChange)="startDateChanged($event.value)">
            <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #startDatePicker></mat-datepicker>
            <mat-error *ngIf="hasError('startDate', 'startDateNotWithin')">Start date needs to be between {{semester.startDate | date: 'MM/dd/yyyy'}} and {{semester.endDate | date: 'MM/dd/yyyy'}}</mat-error>
          </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field appearance="outline" class="mt-8" fxFlex>
            <mat-label>End Date</mat-label>
            <input matInput [matDatepicker]="endDatePicker" formControlName="endDate" placeholder="End Date">
            <mat-datepicker-toggle matSuffix [for]="endDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #endDatePicker></mat-datepicker>
            <mat-error *ngIf="hasError('endDate', 'lessStartDate')">Is less or equal than the start date</mat-error>
            <mat-error *ngIf="hasError('endDate', 'endDateNotWithin')">End date needs to be between {{semester.startDate | date: 'MM/dd/yyyy'}} and {{semester.endDate | date: 'MM/dd/yyyy'}}</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="fields">
        <div class="field">
          <mat-form-field appearance="outline" class="mt-8" fxFlex>
            <mat-label>Registration Start Date</mat-label>
            <input matInput [matDatepicker]="registrationStartPicker" formControlName="registrationStartDate">
            <mat-datepicker-toggle matSuffix [for]="registrationStartPicker"></mat-datepicker-toggle>
            <mat-datepicker #registrationStartPicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field appearance="outline" class="mt-8" fxFlex>
            <mat-label>Registration End Date</mat-label>
            <input matInput [matDatepicker]="registrationDeadlinePicker" formControlName="registrationDeadlineDate">
            <mat-datepicker-toggle matSuffix [for]="registrationDeadlinePicker"></mat-datepicker-toggle>
            <mat-datepicker #registrationDeadlinePicker></mat-datepicker>
            <mat-error *ngIf="hasError('registrationDeadlineDate', 'lessStartRegistrationDate')">Is less or equal than the start date</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="fields">
        <div class="field">
          <mat-form-field appearance="outline" class="mt-8" fxFlex>
            <mat-label>Drop Deadline</mat-label>
            <input matInput [matDatepicker]="dropDeadlinePicker" formControlName="dropDeadlineDate">
            <mat-datepicker-toggle matSuffix [for]="dropDeadlinePicker"></mat-datepicker-toggle>
            <mat-datepicker #dropDeadlinePicker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="field">
          <mat-form-field appearance="outline" class="mt-8" fxFlex>
            <mat-label>Fee Earned</mat-label>
            <input matInput [matDatepicker]="feeEarnedDatePicker" formControlName="feeEarnedDate">
            <mat-datepicker-toggle matSuffix [for]="feeEarnedDatePicker"></mat-datepicker-toggle>
            <mat-datepicker #feeEarnedDatePicker></mat-datepicker>
          </mat-form-field>

        </div>
        <div class="field">
          <mat-form-field appearance="outline" class="mt-8" fxFlex>
            <mat-label>Withdrawal deadline</mat-label>
            <input matInput [matDatepicker]="withdrawalDeadlinePicker" formControlName="withdrawalDeadlineDate">
            <mat-datepicker-toggle matSuffix [for]="withdrawalDeadlinePicker"></mat-datepicker-toggle>
            <mat-datepicker #withdrawalDeadlinePicker></mat-datepicker>
          </mat-form-field>
        </div>
      </div>

      <div class="form-actions-continue">
        <span class="skip" (click)="skip()">skip this step</span>

        <!--
          Will be in a later version 
          <button mat-raised-button
                (click)="onSubmit(false)"
                [disabled]="isSending"
                type="submit">
          Add Additional Session
        </button> -->
      </div>

      <div class="form-actions">
        <button mat-stroked-button
                (click)="goBack()">
          Back
        </button>

        <button mat-flat-button
                (click)="onSubmit(true)"
                [disabled]="isSending"
                type="submit">
          Next
        </button>
      </div>
    </form>
  </ng-container>

  <ng-template #loading>
    ...loading
  </ng-template>
</div>
