import { Injectable } from "@angular/core";
import * as fromProvider from '@provider/store';
import { Store } from '@ngrx/store';
import { IAppState } from 'app/store/state/app.state';
import { RealTimeService } from "@core/services/realTime.service";
import { ProviderPolicy } from "@shared/institution/models";
import { Provider } from "@shared/provider/models";
import { ProviderCourseListItem } from "@provider/courses/models/providerCourseListItem.model";
import { HubConnection } from "@microsoft/signalr";
@Injectable()
export class ProviderRealtime {
        constructor(private store: Store<IAppState>, private realTimeService: RealTimeService) {
                this.initSignalRMessages();
        }


        private initSignalRMessages() {
                this.realTimeService.hubConnection$.subscribe(hubConnection => {
                        if (!hubConnection) return;
                        this.providerUpdated(hubConnection);
                        this.providerCourseListItemAdded(hubConnection);
                        this.providerCourseListItemUpdated(hubConnection);
                        this.providerPolicyUpdated(hubConnection);
                });
        }
        private providerUpdated(hubConnection: HubConnection) {
                hubConnection.on('ProviderUpdated', (provider: Provider) => {
                        this.store.dispatch(fromProvider.Actions.ProviderUpdated({ provider }));
                });
        }
        private providerCourseListItemAdded(hubConnection: HubConnection) {
                hubConnection.on('ProviderCourseListItemAdded', (providerCourseListItem: ProviderCourseListItem) => {
                        this.store.dispatch(fromProvider.Actions.ProviderCourseListItemAdded({ providerCourseListItem }));
                });
        }
        private providerCourseListItemUpdated(hubConnection: HubConnection) {
                hubConnection.on('ProviderCourseListItemUpdated', (providerCourseListItem: ProviderCourseListItem) => {
                        this.store.dispatch(fromProvider.Actions.ProviderCourseListItemUpdated({ providerCourseListItem }));
                });
        }
        private providerPolicyUpdated(hubConnection: HubConnection) {
                hubConnection.on('ProviderPolicyUpdated', (providerPolicy: ProviderPolicy) => {
                        this.store.dispatch(fromProvider.Actions.ProviderPolicyUpdated({ providerPolicy }));
                });
        }

}
