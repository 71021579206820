import { PermissionTypeSelectItem } from './permissionTypeSelectItem';
import { PermissionType } from './permissionType.enum';
import { PermissionDisplay } from './permissionDisplay';

export class PermissionTypeSelectList{
    permissionTypeSelectItems: PermissionTypeSelectItem[];

    constructor(basePermissions: PermissionDisplay[], accessRolePermissions:PermissionType[]){
        this.permissionTypeSelectItems = new Array<PermissionTypeSelectItem>();
        basePermissions.forEach(permissionType =>{
            var item = new PermissionTypeSelectItem(permissionType, accessRolePermissions.find(x=>x === permissionType.permission) != undefined);
            this.permissionTypeSelectItems.push(item);
        });
    }
}