import { Sort, Order } from '@core/models';

export const sorts: Sort[] = [
    { name: 'Last Name', icon: 'person_pin', value:'studentFullName.lastName', isDefault: true},
    { name: 'First Name', icon: 'person_pin', value: 'studentFullName.firstName', isDefault: false },
    { name: 'Student Number', icon: 'import_export', value: 'studentNumber', isDefault: false },
    { name: 'Status', icon: 'poll', value: 'status.description', isDefault: false },
    { name: 'Letter Grade', icon: 'school', value: 'letterGradeDescription', isDefault: false },
  ];

export const orders: Order[] = [
    { name: 'Ascending', icon: 'arrow_upward', value: '', isDefault: true },
    { name: 'Descending', icon: 'arrow_downward', value: '-', isDefault: false },
  ];
