import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { Store, select } from "@ngrx/store";
import { tap, first, finalize, filter, map, concatMap, withLatestFrom, switchMap } from "rxjs/operators";
import { IAppState } from 'app/store/state/app.state';
import * as fromProviderCourses from '@provider/courses/store';
import { Course } from '@shared/institution/models';
import { getInstitutionIdFromRoute } from 'app/helpers/routeParamHelper';

@Injectable()
export class CourseResolver implements Resolve<Course>{
    isLoading = false;
    constructor(private store: Store<IAppState>){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
        const courseId = route.params["courseId"];
        const institutionId = getInstitutionIdFromRoute(route);
        return this.store 
            .pipe(
                map(() => {
                    if(!this.isLoading){
                        this.isLoading = true;
                        this.store.dispatch(fromProviderCourses.Actions.LoadCourse({institutionId,courseId}));
                    }
                    return true;
                }),
                filter(courseLoaded => courseLoaded),
                first(),
                finalize(()=>this.isLoading=false)
            )
    }
}