import { createEffect, Actions, ofType } from "@ngrx/effects";
import { Injectable } from "@angular/core";
import { map, switchMap, withLatestFrom, concatMap, tap } from "rxjs/operators";
import { select, Store } from '@ngrx/store';
import { of } from 'rxjs';
import { IAppState } from 'app/store/state/app.state';
import { Router } from '@angular/router';
import * as fromMember from '@member/store';
import * as fromMemberBilling from '@member/billing/store';

@Injectable()
export class MemberBillingNavigateEffects{
    constructor(private actions$: Actions, private store: Store<IAppState>,
        private router: Router){
    }

    MemberBillingSummary$ = createEffect(() => {
        return this.actions$.pipe(
          ofType(fromMemberBilling.Navigate.MemberBillingSummary),
          concatMap(action => of(action).pipe(
            withLatestFrom(this.store.pipe(select(fromMember.Selectors.Member)))
          )),
          map(([action, member]) => {
            const memberId = !!action.memberId ? action.memberId : member.id;
            const institutionId = !!action.memberId ? action.memberId : member.institutionId;
    
            return this.router.navigate(['member',
              memberId,
              institutionId,
              'billing',
              'summary']);
          }))
      }, { dispatch: false });
}