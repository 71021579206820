import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from 'app/store/state/app.state';
import * as fromCourseExchangeCourses from '@courseExchange/courses/store';
import { RealTimeService } from '@core/services/realTime.service';
import { ProviderCourseListItem } from '@provider/courses/models/providerCourseListItem.model';
import { HubConnection } from '@microsoft/signalr';

@Injectable()
export class CoursesRealtime {
  constructor(private store: Store<IAppState>, private realTimeService: RealTimeService) {
    this.initSignalRMessages();
  }

  private initSignalRMessages() {
    this.realTimeService.hubConnection$.subscribe(hubConnection => {
      if (!hubConnection) return;
      this.providerCourseListItemAdded(hubConnection);
      this.providerCourseListItemUpdated(hubConnection);      
    })
  }
  private providerCourseListItemAdded(hubConnection:HubConnection){
    hubConnection.on('ProviderCourseListItemAdded', (course: ProviderCourseListItem) => {
      this.store.dispatch(fromCourseExchangeCourses.Actions.ProviderCourseListItemAdded({ course }));
    })
  }
  private providerCourseListItemUpdated(hubConnection:HubConnection){
    hubConnection.on('ProviderCourseListItemUpdated', (course: ProviderCourseListItem) => {
        this.store.dispatch(fromCourseExchangeCourses.Actions.ProviderCourseListItemUpdated({ course }));
      });
  }
}
