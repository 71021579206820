import { MemberCourseType } from '@member/store/enums/memberCourseType';

export const Orders = [
    { name: 'Ascending', icon: 'arrow_upward', value: '', isDefault: true },
    { name: 'Descending', icon: 'arrow_downward', value: '-', isDefault: false },
  ];

export const MemberCourseTypes = [
    {
        id: MemberCourseType.MyCourses,
        description: 'My Courses'
    },
    {
        id: MemberCourseType.AllCourses,
        description: 'All Courses'
    }
]

export const Sorts =  [
    {name: 'Level', icon: 'signal_cellular_alt',value: 'level.description', isDefault: false},
    {name: 'Category', icon: 'category', value: 'category.description', isDefault: false},
    {name: 'Course Name', icon: 'title', value: 'title', isDefault: false},
    {name: 'Provider', icon: 'school_outline', value: 'institution.description', isDefault: false},
    {name: 'Session', icon: 'date_range', value: 'startDate', isDefault: true},
  ];
