<mat-toolbar>
  <mat-toolbar-row fxFlex fxLayout="row" fxLayoutAlign="space-between center">
    <span class="title dialog-title">New Enrollment: Enrollments Requested</span>
    <button mat-icon-button (click)="close()" aria-label="Close dialog">
      <mat-icon>close</mat-icon>
    </button>
  </mat-toolbar-row>
</mat-toolbar>

<div class="content">
  <div class="main-title">Success! These enrollments have been submitted to the provider for review:</div>

  <div class="enrollments-requested-list" *ngIf="courses$ | async; let courses">
    <div class="enrollments-requested-item" *ngFor="let course of courses">
      <div class="col col-left">
        <div class="sub-title">Course Information:</div>

        <div class="course-content">
          <div class="top-panel">
            <div class="logo">
              <img src="{{course.logoUrl}}" alt="logo">
            </div>

            <div class="top-panel-info">
              <div class="name">{{course.code}} - {{course.title}}</div>
              <div class="offered">{{course.institution.description}}</div>
            </div>
          </div>

          <div class="session" *ngIf="course.sessions.length">
            Session 1:
            {{course.sessions[0].startDate | date: 'MM/dd/yyyy'}}
             -
            {{course.sessions[0].endDate | date: 'MM/dd/yyyy'}}
          </div>
        </div>
      </div>
      <div class="col col-right" *ngIf="students$ | async; let students">
        <div class="sub-title">Student Information:</div>
        <enrollment-students-info [students]="students"></enrollment-students-info>
      </div>
    </div>
  </div>
</div>
