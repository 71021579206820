import {createFeatureSelector, createSelector} from '@ngrx/store';
import {ICalendarState} from '../state/calendar.state';
import {IAppState} from '../../../../store/state/app.state';
import {AcademicYear, Semester} from '../../models';
import { checkDateInRange } from 'app/helpers/check-date-in-range';
import * as moment from 'moment';


const selectCalendarState = createFeatureSelector<ICalendarState>('institution/calendar');

export const Calendar = createSelector(
  selectCalendarState,
  (state: ICalendarState) => state.calendar
);

export const EffectiveAcademicYear = createSelector(
  Calendar,
  (calendar) => calendar.find(x=>checkDateInRange(x.startDate, x.endDate,moment()))
)

export const EffectiveSemester = createSelector(
  EffectiveAcademicYear,
  (academicYear) => !!academicYear ? academicYear.semesters.find(x=>checkDateInRange(x.startDate,x.endDate,moment())): undefined
)

export const EffectiveForResolvers = createSelector(
  EffectiveAcademicYear,
  EffectiveSemester,
  (academicYear, semester) => {
    return {
      academicYear,
      semester
    }
  }
)


export const selectLoadedCalendarError = createSelector(
  selectCalendarState,
  (state: ICalendarState) => state.loadedCalendarsError
);

export const selectSessionDateRules = createSelector(
  selectCalendarState,
  (state: ICalendarState) => state.sessionDateRules
);

export const selectLoadedSessionDateRulesError = createSelector(
  selectCalendarState,
  (state: ICalendarState) => state.loadedSessionDateRulesError
);

export const selectSelectedAcademicYearId = createSelector(
  selectCalendarState,
  (state: ICalendarState) => state.selectedAcademicYearId
);

export const selectParentId = createSelector(
  selectCalendarState,
  (state: ICalendarState) => state.parentId
);

export const selectShowSessions = createSelector(
  selectCalendarState,
  (state: ICalendarState) => state.showSessions
);

export const selectCurrentAcademicYear = createSelector(
  Calendar,
  selectSelectedAcademicYearId,
  (calendar: AcademicYear[], selectedAcademicYearId: string) => {

    const indexAcademicYear = calendar.findIndex(item => (item.id === selectedAcademicYearId));

    if (indexAcademicYear !== -1) {
      return calendar[indexAcademicYear];
    }
    return null;
  }
);

export interface IPropsSelectAcademicYearById {
  id: string;
}

export const selectAcademicYearById = createSelector(
  Calendar,
  (calendar: AcademicYear[], props: IPropsSelectAcademicYearById) => {

    const indexAcademicYear = calendar.findIndex(item => item.id === props.id);

    if (indexAcademicYear !== -1) {
      return calendar[indexAcademicYear];
    }

    return null;
  }
);

export interface IPropsSelectSemesterById {
  id: string;
  academicYearId: string;
}

export const selectSemesterById = createSelector(
  (state: IAppState, props: IPropsSelectSemesterById) => selectAcademicYearById(state, {
    id: props.academicYearId
  }),
  (academicYear: AcademicYear, props: IPropsSelectSemesterById) => {
    const indexSemester = academicYear.semesters.findIndex(item => item.id === props.id);

    if (indexSemester !== -1) {
      return academicYear.semesters[indexSemester];
    }

    return null;
  }
);

export interface IPropsSelectSessionById {
  id: string;
  academicYearId: string;
  semesterId: string;
}

export const selectSessionById = createSelector(
  (state: IAppState, props: IPropsSelectSessionById) => selectSemesterById(state, {
    id: props.semesterId,
    academicYearId: props.academicYearId
  }),
  (semester: Semester, props: IPropsSelectSessionById) => {
    const indexSession = semester.sessions.findIndex(item => item.id === props.id);

    if (indexSession !== -1) {
      return semester.sessions[indexSession];
    }

    return null;
  }
);

export const selectSetValuesFromCourseCalendarState = createSelector(
  Calendar,
  selectLoadedCalendarError,
  selectSelectedAcademicYearId,
  selectCurrentAcademicYear,
  selectParentId,
  (
    calendar: AcademicYear[],
    loadedCourseCalendarsError: boolean,
    selectedAcademicYearId: string,
    currentAcademicYear: AcademicYear,
    parentId: string
  ) => ({
    calendar,
    loadedCourseCalendarsError,
    selectedAcademicYearId,
    currentAcademicYear,
    parentId
  })
);
