import { NgModule } from '@angular/core';
import * as fromCourseExchange from '@courseExchange/store';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { CourseExchangeInstitutionStoreModule } from '@courseExchange/institution/store/courseExchangeInstitutionStore.module';
import { CourseExchangeUsersStoreModule } from '@courseExchange/users/store/courseExchangeUsers.store.module';
import { CourseExchangeCoursesStoreModule } from '@courseExchange/courses/store/courseExchangeCoursesStore.module';
import { CourseExchangeEnrollmentStoreModule } from '@courseExchange/enrollment/store/enrollmentStore.module';
import { CourseExchangeDashboardStoreModule } from '@courseExchange/dashboard/store/courseExchangeDashboardStore.module';
import { CourseExchangeBillingStoreModule } from '@courseExchange/billing/store/courseExchangeBillingStore.module';

@NgModule({
  declarations: [],
  imports: [
    CourseExchangeInstitutionStoreModule,
    CourseExchangeUsersStoreModule,
    CourseExchangeEnrollmentStoreModule,
    CourseExchangeDashboardStoreModule,
    CourseExchangeBillingStoreModule,
    CourseExchangeCoursesStoreModule,
    StoreModule.forFeature('courseExchange', fromCourseExchange.courseExchangeReducer),
    EffectsModule.forFeature([...fromCourseExchange.EFFECTS])
  ],
  providers: [...fromCourseExchange.RESOLVERS]
})
export class CourseExchangeStoreModule { }
