import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { Store, select } from "@ngrx/store";
import { tap, first, finalize, filter, map } from "rxjs/operators";
import { IAppState } from 'app/store/state/app.state';
import { BaseListItem } from 'app/core/models';
import * as fromRefData from '@refData';

@Injectable()
export class StudentTypesResolver implements Resolve<BaseListItem[]>{
    isLoading = false;
    constructor(private store: Store<IAppState>){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
        return this.store 
            .pipe(
                select(fromRefData.Selectors.studentTypes),
                map((studentTypes)=>{
                    if(!!studentTypes && studentTypes.length>0){
                        this.store.dispatch(fromRefData.Actions.LoadStudentTypesSuccess({studentTypes}));
                    }else if(!this.isLoading){
                        this.isLoading = true;
                        this.store.dispatch(fromRefData.Actions.LoadStudentTypes({forceReload:false}));
                    }
                    return true;
                }),
                filter(studentTypes => studentTypes),
                first(),
                finalize(()=>this.isLoading=false)
            )
    }
}