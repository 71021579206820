import { CourseExchangeInstitutionType } from '@courseExchange/store/enums';

export const CourseExchangeInstitutionsOrders = [
    { name: 'Ascending', icon: 'arrow_upward', value: '', isDefault: true },
    { name: 'Descending', icon: 'arrow_downward', value: '-', isDefault: false },
];
export const CourseExchangeInstitutionsSorts = [
    { name: 'Name', icon: 'person_pin', value: 'institutionName', isDefault: true },
    { name: 'Type', icon: 'import_export', value: 'isMember', isDefault: false },
    { name: 'Accreditation', icon: 'person_pin', value: 'accreditationBody.description', isDefault: false },
    { name: 'State', icon: 'import_export', value: 'address.region.description', isDefault: false }
];


export const CourseExchangeInstitutionsTypes = [
    {
        id: CourseExchangeInstitutionType.Both,
        description: 'All Institutions'
    },
    {
        id: CourseExchangeInstitutionType.Member,
        description: 'Member Institutions',
    },
    {
        id: CourseExchangeInstitutionType.Provider,
        description: 'Provider Institutions',
    }
]