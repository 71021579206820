import { createEffect, Actions, ofType } from "@ngrx/effects";
import { Injectable } from "@angular/core";
import { map, switchMap, catchError, concatMap, withLatestFrom, tap } from "rxjs/operators";
import { of } from 'rxjs';
import { UserService } from '@shared/user/services';
import * as fromAdminUsers from '@admin/users/store';
import * as fromCore from '@core/store';
import { IAppState } from 'app/store/state/app.state';
import { Store, select } from '@ngrx/store';
import * as fromRefData from '@refData';
import { MatDialog } from '@angular/material/dialog';
import { SelectInstitutionComponent } from '@shared/institution/components/dialogs';
import { PopupService } from "@core/services/popup.service";
import { RealTimeService } from "@core/services/realTime.service";
import { UserListItem } from "@shared/user/models";
import * as fromUserList from '@shared/user/components/user-list/store';
import * as fromUsers from '@shared/user/store';
import { AccessRoleSummary } from "@auth/models/accessRoleSummary";
@Injectable()
export class AdminUsersEffects {
    constructor(private actions$: Actions, private store: Store<IAppState>, private userService: UserService,
        private dialog: MatDialog,private popupService: PopupService,private realTimeService:RealTimeService) {
            this.initSignalRMessages();
    }
    private initSignalRMessages(){
        this.realTimeService.hubConnection$.subscribe(hubConnection=>{
          if(!hubConnection) return;
          hubConnection.on('UserListItemAdded', (userListItem: UserListItem) => {
            this.store.dispatch(fromAdminUsers.Actions.UserListItemAdded({userListItem}));
          });
          hubConnection.on('UserListItemUpdated', (userListItem: UserListItem) => {
            this.store.dispatch(fromAdminUsers.Actions.UserListItemUpdated({userListItem}));
          });
          hubConnection.on('AccessRoleSummaryAdded', (accessRole:AccessRoleSummary) => {
            this.store.dispatch(fromAdminUsers.Actions.AccessRoleAdded({accessRole}));
          });
          hubConnection.on('AccessRoleSummaryUpdated', (accessRole:AccessRoleSummary) => {
            this.store.dispatch(fromAdminUsers.Actions.AccessRoleUpdated({accessRole}));
          });
          hubConnection.on('AccessRoleSummaryDeleted', (accessRole:AccessRoleSummary) => {
            this.store.dispatch(fromAdminUsers.Actions.AccessRoleDeleted({accessRole}));
          });
        })
      }
    LoadUsers$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromAdminUsers.Actions.LoadUsers),
            switchMap(() => this.userService.getAllUsers()),
            map((users) => fromAdminUsers.Actions.LoadUsersSuccess({ users })),
            catchError((error) => of(fromAdminUsers.Actions.LoadUsersError({ message: error.message })))
        )
    });

    loadUsersError$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromAdminUsers.Actions.LoadUsersError),
            /** An EMPTY observable only emits completion. Replace with your own observable stream */
            map(({ message }) => fromCore.Actions.ShowMessage({ message })));
    });

    selectInstitution$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromAdminUsers.Actions.SelectInstitution),
            concatMap((action) => of(action).pipe(
                withLatestFrom(this.store.pipe(select(fromRefData.institutions)))
            )),
            /** An EMPTY observable only emits completion. Replace with your own observable stream */
            tap(([action, institutions]) => {
                const dialogRef = this.dialog.open(SelectInstitutionComponent,
                    {
                        disableClose: true,
                        data: {
                            institutions
                        }
                    });

                dialogRef.afterClosed().subscribe(
                    institutionId => {
                        if (institutionId != null) {
                            this.store.dispatch(fromUsers.Navigate.UserDetail({ institutionId, userId: 'new' }))
                        }
                    }
                )
            })
        );
    }, { dispatch: false });
}