import {CourseScheduleDialogContainerComponent} from './course-schedule-dialog-container/course-schedule-dialog-container.component';
import {CourseScheduleDialogStartComponent} from './course-schedule-dialog-start/course-schedule-dialog-start.component';
import {CourseScheduleDialogNewComponent} from './course-schedule-dialog-new/course-schedule-dialog-new.component';
import {CourseScheduleDialogCopyComponent} from './course-schedule-dialog-copy/course-schedule-dialog-copy.component';

export * from './course-schedule-dialog-container/course-schedule-dialog-container.component';
export * from './course-schedule-dialog-start/course-schedule-dialog-start.component';
export * from './course-schedule-dialog-new/course-schedule-dialog-new.component';
export * from './course-schedule-dialog-copy/course-schedule-dialog-copy.component';

export const COMPONENTS = [
  CourseScheduleDialogContainerComponent,
  CourseScheduleDialogStartComponent,
  CourseScheduleDialogNewComponent,
  CourseScheduleDialogCopyComponent
];

export const ENTRY_COMPONENTS = [CourseScheduleDialogContainerComponent];
