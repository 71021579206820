import { NgModule } from '@angular/core';

import { FuseSharedModule } from '@fuse/shared.module';

import { NavbarComponent } from 'app/layout/components/navbar/navbar.component';
import { RouterModule } from '@angular/router';
import { SuperAdminDashboardNavModule } from './vertical/super-admin-dashboard-nav/super-admin-dashboard-nav.module';
import { ExchangeAdminDashboardNavModule } from './vertical/exchange-admin-dashboard-nav/exchange-admin-dashboard-nav.module';

import { AdminMemberDashboardNavModule } from './vertical/admin-member-dashboard-nav/admin-member-dashboard-nav.module';
import { AdminProviderDashboardNavModule } from './vertical/admin-provider-dashboard-nav/admin-provider-dashboard-nav.module';
import { InstitutionAdminDashboardNavModule } from './vertical/institution-admin-dashboard-nav/institution-admin-dashboard-nav.module';
// import { AdminDashboardNavModule } from './vertical/admin-dashboard-nav/admin-dashboard-nav.module';


@NgModule({
    declarations: [
        NavbarComponent,
    
    ],
    imports     : [
        FuseSharedModule,
        // AdminDashboardNavModule,
        ExchangeAdminDashboardNavModule,
        SuperAdminDashboardNavModule,
        AdminProviderDashboardNavModule,
        AdminMemberDashboardNavModule,
        InstitutionAdminDashboardNavModule,
        RouterModule
    ],
    exports     : [
        NavbarComponent
    ]
})
export class NavbarModule
{
}
