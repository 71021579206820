import { Injectable } from "@angular/core";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { first, finalize, filter, map } from "rxjs/operators";
import { IAppState } from 'app/store/state/app.state';
import * as fromSuperAdminDashboard from '@admin/dashboard/store';
import { SuperAdminDashboardViewModel } from '@admin/dashboard/models';

@Injectable()
export class SuperAdminDashboardResolver implements Resolve<SuperAdminDashboardViewModel>{
    isLoading = false;
    constructor(private store: Store<IAppState>){}
    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any>{
        return this.store 
            .pipe(
                map(() => {
                    if(!this.isLoading){
                        this.isLoading = true;
                        this.store.dispatch(fromSuperAdminDashboard.Actions.LoadDashboardData());
                    }
                    return true;
                }),
                filter(dashboardLoaded => dashboardLoaded),
                first(),
                finalize(()=>this.isLoading=false)
            )
    }
}