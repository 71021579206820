import {toNumber} from 'lodash';
import {isNumber} from './is-number';

export const takeNumber = (value: string | number | null): number | null => {
  if (typeof value === 'string') {
    const stringOfNumbers = value.replace(/[^0-9.-]+/g, '');

    if (isNumber(stringOfNumbers)) {
      return toNumber(stringOfNumbers);
    }
  }

  if (typeof value === 'number') {
    return value;
  }

  return null;
};
