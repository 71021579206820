import {Injectable} from '@angular/core';
import {Actions, ofType, createEffect} from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { IAppState } from 'app/store/state/app.state';
import { concatMap, withLatestFrom, switchMap, map, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as fromProviderCourses from '@provider/courses/store';
import * as fromProvider from '@provider/store';
import { Router } from '@angular/router';
import { CurrentProviderCourse } from '../selectors/courses.selectors';
import { MatDialog } from '@angular/material/dialog';
import { CourseScheduleDialogContainerComponent } from '@provider/course-schedule/components';
import { ICourseScheduleDialogData } from '@provider/course-schedule/models/shared';

@Injectable()
export class CoursesNavigateEffects {
  constructor(private actions$: Actions, private store: Store<IAppState>, private router: Router, private dialog:MatDialog) 
  {
  }

  goToCourseList$ = createEffect(() => {
      return this.actions$.pipe(
              ofType(fromProviderCourses.Navigate.CourseList),
              concatMap((action) => of(action).pipe(
                withLatestFrom(this.store.pipe(select(fromProvider.Selectors.Provider)))
              )),
              /** An EMPTY observable only emits completion. Replace with your own observable stream */
              tap(([action,currentProvider]) => {
                const providerId = !!action.providerId ? action.providerId : currentProvider.id;
                const institutionId = !!action.institutionId ? action.institutionId : currentProvider.institutionId;
                this.router.navigate([`/provider/${providerId}/${institutionId}/courses`]);
              })
        );
  },{dispatch:false});

  goToCourseDetail$ = createEffect(() => {
    return this.actions$.pipe(
            ofType(fromProviderCourses.Navigate.CourseDetail),
            concatMap((action) => of(action).pipe(
              withLatestFrom(this.store.pipe(select(fromProvider.Selectors.Provider)))
            )),
            /** An EMPTY observable only emits completion. Replace with your own observable stream */
            tap(([action,provider]) => {
              const courseId = action.providerCourse.courseId;
              const institutionId = !!action.institutionId ? action.institutionId : provider.institutionId;
              const providerId = !!action.providerId ? action.providerId : provider.id;
              this.router.navigate([`/provider/${providerId}/${institutionId}/courses/detail/${courseId}`]);
            })
      );
},{dispatch:false});

editCourse$ = createEffect(() => {
  return this.actions$.pipe(
          ofType(fromProviderCourses.Navigate.CourseEdit),
          concatMap((action) => of(action).pipe(
            withLatestFrom(this.store.pipe(select(fromProvider.Selectors.Provider)))
          )),
          /** An EMPTY observable only emits completion. Replace with your own observable stream */
          tap(([action,provider]) => {
            const courseId = action.providerCourse.courseId;
            const institutionId = !!action.institutionId ? action.institutionId : provider.institutionId;
            const providerId = !!action.providerId ? action.providerId : provider.id;
            this.router.navigate([`/provider/${providerId}/${institutionId}/courses/detail/${courseId}/edit`]);
          })
    );
},{dispatch:false});

addCourse$ = createEffect(() => {
  return this.actions$.pipe(
          ofType(fromProviderCourses.Navigate.CourseAdd),
          concatMap((action) => of(action).pipe(
            withLatestFrom(this.store.pipe(select(fromProvider.Selectors.Provider)))
          )),
          /** An EMPTY observable only emits completion. Replace with your own observable stream */
          tap(([action,provider]) => {
            const institutionId = !!action.institutionId ? action.institutionId : provider.institutionId;
            const providerId = !!action.providerId ? action.providerId : provider.id;
            this.router.navigate([`/provider/${providerId}/${institutionId}/courses/new`]);
          })
    );
},{dispatch:false});

createCourseSchedule$ = createEffect(() => {
  return this.actions$.pipe(
          ofType(fromProviderCourses.Navigate.CreateCourseSchedule),
          concatMap((action) => of(action).pipe(
            withLatestFrom(this.store.pipe(select(fromProviderCourses.Selectors.MyProviderSelectedCourses)))
          )),
          /** An EMPTY observable only emits completion. Replace with your own observable stream */
          tap(([action,selectedProviderCourses]) => {
            const providerCourseIds = selectedProviderCourses.map(x=>x.providerCourseId);
            this.dialog.open<CourseScheduleDialogContainerComponent, ICourseScheduleDialogData>(CourseScheduleDialogContainerComponent, {
              data: {
                providerCourseIds
              }
            });
          })
    );
},{dispatch:false});
}
