import { ImageStorageService } from './../../services/image-storage.service';
import { saveAs } from 'file-saver';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-save-file',
  templateUrl: './save-file.component.html',
  styleUrls: ['./save-file.component.scss']
})
export class SaveFileComponent {

  constructor() {}
  selectedFile: File = null;

  onFileSelected(file: any): void{

  }
}
