import { NgModule } from '@angular/core';
import {ReadMoreDirective} from './read-more.directive';
import {UniqueEmailValidatorDirective} from './unique-email-validator.directive';

@NgModule({
  declarations: [
    ReadMoreDirective,
    UniqueEmailValidatorDirective
  ],
  imports: [],
  exports: [
    ReadMoreDirective,
    UniqueEmailValidatorDirective
  ]
})
export class DirectivesModule {}
