import { NgModule } from '@angular/core';
import { CoursesDataListsService } from './courses-data/services/courses-data-lists.service';
import { CourseExchangeService } from './services/course-exchange.service';
import { CourseService } from './services/course.service';

import * as fromComponents from './components';
import * as fromStore from './store';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from 'app/core/material/material.module';
import { PercentPipe, CurrencyPipe } from '@angular/common';

@NgModule({
    declarations: [...fromComponents.COMPONENTS],
    exports: [...fromComponents.COMPONENTS],
    imports: [
        MaterialModule,
        ReactiveFormsModule
    ],
    providers: [CoursesDataListsService, CourseExchangeService, CurrencyPipe, PercentPipe, CourseService, ...fromStore.RESOLVERS]
})
export class CourseExchangeSharedModule { }
