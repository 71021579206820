import {
    createReducer,
    on
  } from '@ngrx/store';
  
import { AuthActions } from '../action-types';
import { UserSummary } from 'app/authentication/models/userSummary.model';
import { AccessRole } from 'app/authentication/models/accessRole';
import * as moment from 'moment';
import { Moment } from 'moment';
  
  export interface AuthState {
    user: UserSummary;
    returnUrl: string;
    currentAccessRole: AccessRole,
    currentCourseExchangeId: string,
    startDate: Date,
    endDate: Date
  }
  
  export const initialAuthState: AuthState = {
    user: undefined,
    returnUrl: '',
    currentAccessRole: undefined,
    currentCourseExchangeId: undefined,
    startDate: moment().add(-6, 'month').startOf('day').toDate(),
    endDate: moment().add(3, 'month').endOf('day').toDate()
  };
  
  export const authReducer = createReducer(
    initialAuthState,
    on(AuthActions.login, (state, action) => {
        return {
            ...state,
            user: action.user,
            returnUrl: action.returnUrl
        }
    }),
    on(AuthActions.logout, (state, action) => {
      return {
        ...state,
        user: undefined,
        returnUrl: ''
      }
    }),
    on(AuthActions.selectAccessRole, (state, action) =>{
        const accessRoleId = action.accessRoleId;
        const currentAccessRole = state.user.accessRoles.find(x=>x.id===accessRoleId);
        return {
            ...state,
            currentAccessRole
        };
    }),
    on(AuthActions.selectCourseExchangeId, (state, action)=>{
      const currentCourseExchangeId = action.courseExchangeId;
      return {
        ...state,
        currentCourseExchangeId
      }
    }),
    on(AuthActions.UserSummaryUpdated, (state, action)=>{
      const token = !! state.user ? state.user.token: '';
      const user = {...action.userSummary, token};
      return{
        ...state,
        user
      }
    }),
    on(AuthActions.AccessRoleUpdated, (state, {accessRole})=>{
      const oldUser = state.user;
      const token = !!oldUser ? oldUser.token: '';
      if(!accessRole) return state;
      const idx = !!oldUser ? oldUser.accessRoles.findIndex(x=>x.id===accessRole.id): -1;
      if(idx===-1)return state;
      const accessRoles = [...oldUser.accessRoles.slice(0,idx), accessRole, ...oldUser.accessRoles.slice(idx+1)];
      const user = {...oldUser, accessRoles, token};
      localStorage.setItem('user',JSON.stringify(user));
      return{
        ...state,
        user
      }
    }),
    on(AuthActions.AccessRoleAdded, (state, {accessRole})=>{
      const oldUser = state.user;
      const token = !!oldUser ? oldUser.token: '';
      if(oldUser.userId != accessRole.userId) return state;
      const accessRoles = [...oldUser.accessRoles, accessRole];
      const user = {...oldUser, accessRoles, token};
      localStorage.setItem('user',JSON.stringify(user));
      return{
        ...state,
        user
      }
    }),
    on(AuthActions.AccessRoleDeleted, (state, {accessRole})=>{
      const oldUser = state.user;
      const token = !!oldUser ? oldUser.token: '';
      if(oldUser.userId != accessRole.userId) return state;
      const accessRoles = [...oldUser.accessRoles.filter(x=>x.id!=accessRole.id)];
      const user = {...oldUser, accessRoles, token};
      localStorage.setItem('user',JSON.stringify(user));
      return{
        ...state,
        user
      }
    }),
    on(AuthActions.ChangeStartDate, (state, action)=>{
      const startDate = moment(action.startDate).startOf('day').toDate();
      return {
        ...state,
        startDate
      }
    }),
    on(AuthActions.ChangeEndDate, (state, action)=>{
      const endDate = moment(action.endDate).endOf('day').toDate();
      return {
        ...state,
        endDate
      }
    })
);
  
  
  