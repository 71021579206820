import { Location } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AddUserCommand, UpdateUserCommand } from 'app/shared/user/commands';
import { ConfirmDialogComponent, ConfirmDialogData } from 'app/core/components/confirm-dialog/confirm-dialog.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from '@shared/user/models';
import {hasErrorForm} from '../../../../helpers/has-error-form';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { IAppState } from 'app/store';
import * as fromSharedUsers from '@shared/user/components/user-list/store';
import { Observable } from 'rxjs';
import * as fromUsers from '@shared/user/store';
import { tap } from 'rxjs/operators';
import { InstitutionRole } from '@core/referenceData';
import * as fromRefData from '@refData';
import {v4 as uuidv4} from 'uuid';
export interface UserDetailDialogData {
  userId: string;
  institutionId: string;
}
@Component({
  selector: 'elb-user-detail',
  templateUrl: 'user-detail.html',
  styleUrls: ['./user-detail.component.scss'],

})
export class UserDetailComponent implements OnInit {
  isNew: boolean = true;
  userForm: UntypedFormGroup;
  pageTitle: string = 'Add New User';
  pageSubTitle: string = '';
  phoneMask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  currentUser$: Observable<User>;
  institutionRoles$: Observable<InstitutionRole[]>;
  constructor(private activatedRoute: ActivatedRoute,private dialog: MatDialog,
    public matDialogRef: MatDialogRef<UserDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public matDialogData: UserDetailDialogData,
    private formBuilder: UntypedFormBuilder,
    private location: Location,
    private store: Store<IAppState>) {
    this.resetForm();

  }

  ngOnInit(): void {
    if(this.matDialogData.userId == "new")
    {
      this.isNew = true;
    }
    else
    {
      this.currentUser$ = this.store.pipe(select(fromUsers.Selectors.CurrentUser));
      this.currentUser$
      .pipe(
        tap((user)=>this.fillUserData(user))
      ).subscribe();
    } 
    this.institutionRoles$ = this.store.pipe(select(fromRefData.Selectors.institutionRoles));
  }

  resetForm(): void {
        this.userForm = this.formBuilder.group({
        institutionId: [this.matDialogData.institutionId],
        id: [''],
        department: ['', [Validators.required, Validators.maxLength(100)]],
        title: ['', Validators.maxLength(150)],
        firstName: ['', [Validators.required, Validators.maxLength(50)]],
        middleName: ['', [Validators.maxLength(50)]],
        lastName: ['', [Validators.required, Validators.maxLength(50)]],
        emailAddress: ['', [Validators.email, Validators.required]],
        phoneNumber: ['', Validators.required],
        institutionRole: ['', Validators.required]
      });
  }

  hasError = (controlName: any, errorName: string) => hasErrorForm(controlName, errorName, this.userForm);

  fillUserData(user: User): void {
    if(!user) return;
    this.isNew = false;
    this.userForm.get('institutionRole').clearValidators();
    this.pageTitle = 'Edit User';
    this.userForm.patchValue({
      institutionId: user.institutionId,
      id: user.id,
      department: user.department,
      title: user.fullName.title,
      firstName: user.fullName.firstName,
      middleName: user.fullName.middleName,
      lastName: user.fullName.lastName,
      emailAddress: user.emailAddress.value,
      phoneNumber: user.phoneNumber.value,
      institutionRole: user.institutionRole
    });
  }

  onAddUser(): void {
    if (this.userForm.valid) {
      const data = this.userForm.value;
      const command: AddUserCommand = {
        id: uuidv4(),
        institutionId: this.matDialogData.institutionId,
        department: data.department,
        title: data.title,
        firstName: data.firstName,
        middleName: data.middleName,
        lastName: data.lastName,
        emailAddress: data.emailAddress,
        phoneNumber: data.phoneNumber,
        institutionRole: data.institutionRole
      };
      this.store.dispatch(fromSharedUsers.Actions.AddUser({command}));
      this.matDialogRef.close();
    }
  }

  cancel(): void {
    this.matDialogRef.close();
  }

  saveUser(): void {
    const dialogData: ConfirmDialogData = {
      title: "Confirm Edit",
      message: 'You are changing this profile.  Do you want to continue?',
      cancelButtonText: "No",
      okButtonText: "Yes"
    };

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px;",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.updateUser();
      }
    })
  }

  private updateUser(): void {
    if (this.userForm.valid) {
      const data = this.userForm.value;
      const command: UpdateUserCommand = {
        id: data.id,
        institutionId: data.institutionId,
        department: data.department,
        title: data.title,
        firstName: data.firstName,
        middleName: '',
        lastName: data.lastName,
        emailAddress: data.emailAddress,
        phoneNumber: data.phoneNumber,
        institutionRole: data.institutionRole
      };
      this.store.dispatch(fromSharedUsers.Actions.UpdateUser({command}));
      this.matDialogRef.close();
    }
  }
}
