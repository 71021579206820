import { createAction, props } from '@ngrx/store';

export const ProviderEnrollments = createAction(
    '[Navigation] - Provider Enrollment List',
    props<{
        providerId?: string,
        institutionId?: string
    }>()
)

export const StudentDetail = createAction(
    '[Navigation] - Provider Student Detail',
    props<{
        studentId?: string
    }>()
)